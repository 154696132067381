import { Injectable, Injector } from '@angular/core';
import { IClientModel } from 'app/common/models/client.model';
import { IClientSubscriptionPlan } from 'app/common/models/subscription.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class ClientsService extends TableListService<IClientModel> {

  getItemList() {
    return this.apiService.clients.getList(this.contextService.getRoutingParams());
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.clients.getClient(req);
  }

  getClientAbonements() {
    return this.apiService.clients.getClientAbonements(this.contextService.getRoutingParams());
  }

  getClientAbonement(id) {
    return this.apiService.clients.getClientAbonement(this.contextService.getRoutingParams(), id);
  }

  sellClientAbonement(model, clientId) {
    return this.apiService.clients.sellClientAbonement(this.contextService.getRoutingParams(), model, clientId);
  }

  suspendClientAbonement(model, planId) {
    return this.apiService.clients.suspendClientAbonement(this.contextService.getRoutingParams(), model, planId);
  }

  reactivateClientSubscription(subClientId, planId) {
    return this.apiService.clients.reactivateClientSubscription(this.contextService.getRoutingParams(), subClientId, planId);
  }

  getClientSubscriptionSuspendHistory(subClientId, planId) {
    return this.apiService.clients.getClientSubscriptionSuspendHistory(this.contextService.getRoutingParams(), subClientId, planId);
  }

  saveClientAbonement(model, clientId) {
    return this.apiService.clients.saveClientAbonement(this.contextService.getRoutingParams(), model, clientId);
  }

  cancelClientSubscription(planId) {
    return this.apiService.clients.cancelClientSubscription(this.contextService.getRoutingParams(), planId);
  }

  searchClients(pattern) {
    return this.apiService.clients.searchClients(this.contextService.getRoutingParams(), pattern);
  }

  getMergeGroups() {
    return this.apiService.clients.getMergeGroups(this.contextService.getRoutingParams().clubId);
  }

  merge(receiveClientId: string, sourceClientIds: string[]) {
    return this.apiService.clients.merge(receiveClientId, sourceClientIds);
  }

  ignoreMergeGroups(clientId: string) {
    return this.apiService.clients.ignoreMergeGroups(clientId);
  }

  history(clientId) {
    return this.apiService.clients.history(this.contextService.getRoutingParams(), clientId);
  }

  getByClientGroup(clientId) {
    return this.apiService.clients.clientGroup(this.contextService.getRoutingParams(), clientId);
  }

  searchAbonements(clientId) {
    return this.apiService.clients.searchAbonements(this.contextService.getRoutingParams(), clientId);
  }

  save(model: IClientModel) {
    return this.apiService.clients.saveItem(this.contextService.getRoutingParams(), model);
  }

  create(model: IClientModel) {
    return this.save(model);
  }

  prolong(model: IClientSubscriptionPlan) {
    return this.apiV2Service.clients.prolong(model.id, model.fromDate, model.toDate, model.purchasedAt, model.visitCount);
  }

  filterAndSort(filterPattern: string, orderBy: string, sortMode: string, pageIndex: number, pageSize: number) {
    return this.apiService.clients.filterAndSort(this.contextService.getRoutingParams().clubId, filterPattern, orderBy, sortMode, pageIndex, pageSize);
  }

  getByGroup(groupId: string) {
    return this.apiV2Service.clients.getByGroup(groupId);
  }

  getClientOrder(planId: string) {
    return this.apiV2Service.clientOrders.getClientOrder(planId);
  }

  createPayment(model) {
    return this.apiV2Service.clientOrders.createPayment(model);
  }

  returnSubscriptionFromArchive(model: IClientSubscriptionPlan) {
    return this.apiV2Service.clients.returnSubscriptionFromArchive(model.id);
  }

  deletedClientSubscription(planId) {
    return this.apiService.clients.deletedClientSubscription(this.contextService.getRoutingParams(), planId);
  }

  constructor(injector: Injector) {
    super(injector);
  }
}
