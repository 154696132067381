<div class="cf" appClickOutside (clickOutside)="onClickOutside($event)">
    <div class="title-wrap">
        <div class="box-title">
            Тип тега инстаграма
            <span class="box-edit" [hidden]="enableEdit" (click)="onBeginEdit()" *ngIf="!isReadonly">
                <span class="sp sp-pen"></span>
            </span>
        </div>
        <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
            <span class="row__save_save" (click)="onSave()">
                <span class="icon-check icon-check_color">
                    <span class="icon-check__left"></span>
                    <span class="icon-check__right"></span>
                </span>
                <span class="title">Сохранить</span>
            </span>
            <span class="row__save_cancel" (click)="onCancel()">
                <span class="icon-cross icon-cross_color">
                    <span class="icon-cross__left"></span>
                    <span class="icon-cross__right"></span>
                </span>
                <span class="title">Отменить</span>
            </span>
        </div>
    </div>
    <div class="row__field" (click)="onBeginEdit()">
        <select [(ngModel)]="tempData" class="select select__long" [class.inactive]="!enableEdit" id="instagramMarkerType" name="instagramMarkerType">
            <option value="location">Место</option>
            <option value="hashtag">Тег</option>
            <option value="user">Пользователь</option>
        </select>
    </div>
</div>
