<div class="resetWrap">
  <div class="resetForm">
    <header class="settings__header">
      <span class="title">Установка пароля</span>
    </header>
    <form [formGroup]="form">
      <div class="form-group">
        <input type="password" class="form-control" name="password" formControlName="Model.password" formControlName="password" placeholder="Пароль"
          class="input__text input__text" />
        <label class="field-validation-error" for="password" *ngIf="fieldErrors.password && isSubmited">{{ fieldErrors.password }}</label>
        <label class="field-validation-error" for="passwordConfirm" *ngIf="formErrors.passwordMatch  && !fieldErrors.password && isSubmited">{{formErrors.passwordMatch }}</label>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" name="passwordConfirm" formControlName="Model.password" formControlName="passwordConfirm"
          placeholder="Еще раз" class="input__text input__text" />
        <label class="field-validation-error" for="passwordConfirm" *ngIf="fieldErrors.passwordConfirm && isSubmited">{{ fieldErrors.passwordConfirm }}</label>
        <label class="field-validation-error" for="passwordConfirm" *ngIf="formErrors.passwordMatch && !fieldErrors.password && isSubmited">{{formErrors.passwordMatch }}</label>
      </div>
      <div class="form-group">
        <button [disabled]="form.invalid && isSubmited" class="btn btn-primary" (click)="reset()">
          <span class="btn-primary__desc">Установить пароль</span>
        </button>
      </div>
      <div class="form-group">
        <button class="btn btn-primary" (click)="register()">
          <span class="btn-primary__desc">Установить пароль позже</span>
        </button>
      </div>
    </form>
  </div>
</div>
