<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
   <div class="content__back" (click)="close()">
     <span class="left-arrow">
       <span class="left-arrow__top"></span>
       <span class="left-arrow__bottom"></span>
     </span>
     <span *ngSwitchCase="null" class="content__header-title-value">Создание сети</span>
     <span *ngSwitchDefault class="content__header-title-value">Редактирование сети</span>
   </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Название</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" formControlName="name"
                   [ngModel]="Model.name" maxlength="500">
            <label for="name" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <ng-container *ngIf="isEditAvailable">
        <div class="col-12 col-xs-12 col-sm-6 col-lg-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

  </form>
</div>
