import { Injectable } from '@angular/core';

import { ApiService } from 'app/common/services/api.service';
import { ContextService } from 'app/common/services/context.service';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class ClubsprofileService {

  public context: IRoutingParams;

  constructor(
    private api: ApiService,
    private contextService: ContextService
  ) {
    this.context = this.contextService.getRoutingParams();
  }

  getData(context: IRoutingParams) {
    return this.api.clubProfile.getData(context);
  }

  postData(context: IRoutingParams, model) {
    return this.api.clubProfile.postData(context, model);
  }

}
