import { Injectable } from '@angular/core';
import { tipsStrings } from './data/tips-strings';

@Injectable()
export class TipsService {
  private strings = tipsStrings;

  private getPropertyByKeyPath(targetObj, keyPath) {
    let keys = keyPath.split('.');
    if (keys.length === 0) {
      return undefined;
    }
    keys = keys.reverse();
    let subObject = targetObj;
    while (keys.length) {
      const k = keys.pop();
      if (!subObject.hasOwnProperty(k)) {
        return undefined;
      } else {
        subObject = subObject[k];
      }
    }
    return subObject;
  }

  public getStringByKey(key: string) {
    return this.getPropertyByKeyPath(this.strings, key) || '[неверный ключ]';
  }
}
