import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numEndingPipe' })
export class NumEndingPipe implements PipeTransform {
  transform(number: number, for1: string, for4: string, for5: string) {
    var ending = for5;
    number = number % 100;
    if (number >= 11 && number <= 19) {
      ending = for5;
    }
    else {
      var i = number % 10;
      switch (i) {
        case 1:
          ending = for1;
          break;

        case 2:
        case 3:
        case 4:
          ending = for4;
          break;

        default:
          ending = for5;
          break;
      }
    }

    return ending;
  }
}
/*  /// <summary>
    /// Возвращает правильно склонение слова связанного с числительным.
    /// </summary>
    /// <param name="number">Число</param>
    /// <param name="for1">Вариант слова для числа = 1</param>
    /// <param name="for4">Вариант слова для числа = 4</param>
    /// <param name="for5">Вариант слова для числа = 5</param>
    public static string GetNumEnding(int number, string for1, string for4, string for5)
    {
        var ending = for5;
        number = number % 100;
        if (number >= 11 && number <= 19)
        {
            ending = for5;
        }
        else
        {
            var i = number % 10;
            switch (i)
            {
                case 1:
                ending = for1;
                break;

                case 2:
                case 3:
                case 4:
                ending = for4;
                break;

                default:
                ending = for5;
                break;
            }
        }

        return ending;
    }    */
