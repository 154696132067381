import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {Registry} from '../../common/models/registry.model';
import {AlertsService} from '../../common/components/alerts/services/alerts.service';
import * as moment from 'moment';

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {

  public registry: Registry[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private apiV2: ApiV2Service) { }

  async ngOnInit(): Promise<void> {
    await this.apiV2.registry.get().then(async (registry) => {
      this.registry = registry.sort((a, b) => { return b.createdAt - a.createdAt });
    });
  }

  public create(type: 'summaryReport' | 'detailedRegister' = null): void {
    this.router.navigate([!type ? `create` : `create/${type}`], { relativeTo: this.route });
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public data(data: string, field: string): string {
    try {
      return JSON.parse(data)[field];
    } catch (err) {
      console.error(err);
    }
  }

  public async remove(id: string): Promise<void> {
    this.alertsService.showConfirm({
      header: 'Удалить реестр?',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.delete(id);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public onEditRegistry(registry: Registry, event: Event) {
    console.log(event);
    this.router.navigate(['edit'], {relativeTo: this.route, queryParams: { id: registry.id }});
  }

  private async delete(id: string): Promise<void> {
    await this.apiV2.registry.remove(id).then(async (remove) => {
      if (remove) {
        this.registry = await this.registry.filter(x => x.id !== id);
      } else {
        console.error(remove);
      }
    });
  }
}
