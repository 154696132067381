import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { EditImageComponent } from 'app/shared-components/edit-image/edit-image.component';

export const imageRoute: Route = {
  component: EditImageComponent,
  path: ':cityId/:clubNetId/:clubId/image/:id',
  canActivate: [AuthGuard],
  data: { title: 'Изображение' },
};
