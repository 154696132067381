import { Validators } from '@angular/forms';
import { Injector, Component } from '@angular/core';

import { ClubroomsService } from 'app/components/clubrooms/services/clubrooms.service';
import { numberValidator } from 'app/common/validators/number.validator';
import { EditItemComponent } from 'app/common/edit-item.component';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { MediaService } from 'app/common/services/media.service';

@Component({
  selector: 'app-clubroom-edit',
  templateUrl: './clubroom-edit.component.html',
  styleUrls: ['./clubroom-edit.component.scss']
})
export class ClubroomEditComponent extends EditItemComponent<IClubroomModel> {
  public static readonly componentName: string = 'ClubroomEditComponent';

  fieldMessages = {
    name: {
      NotEmpty: 'Это поле обязательно'
    },
    width: {
      numberValidator: 'Это поле должно быть числом от 0 до 100000'
    },
    length: {
      numberValidator: 'Это поле должно быть числом от 0 до 100000'
    },
    square: {
      numberValidator: 'Это поле должно быть числом от 1 до 100000'
    },
    color: {
      required: 'Необходимо указать цвет'
    },
    geoLat: {
      numberValidator: 'Это поле должно быть числом от 1 до 100000'
    },
    geoLon: {
      numberValidator: 'Это поле должно быть числом от 1 до 100000'
    }
  };

  protected mediaService: MediaService;

  constructor(
    injector: Injector,
    protected service: ClubroomsService
  ) {
    super(injector, service);
    this.mediaService = injector.get(MediaService);
  }

  modelTemplate(): Promise<IClubroomModel> {
    const result: IClubroomModel = {
      id: null,
      entityStatus: EntityStatus.draft,
      name: null,
      square: null,
      length: null,
      width: null,
      color: '#c9aea5',
      imageUrl: '',
      medias: {
        images: [],
        videos: []
      },
      address: null,
      geoLat: null,
      geoLon: null
    };

    return Promise.resolve(result);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      width: [this.Model.width, [numberValidator(0, 100000)]],
      length: [this.Model.length, [numberValidator(0, 100000)]],
      square: [this.Model.square, [numberValidator(0, 100000)]],
      color: [this.Model.color, [Validators.required]],
      entityStatus: [this.Model.entityStatus, [Validators.required]],
      medias: [this.Model.medias, []],
      address: [this.Model.address, []],
      geoLat: [this.Model.geoLat, [numberValidator(0, 100000)]],
      geoLon: [this.Model.geoLon, [numberValidator(0, 100000)]]
    });
    if (this.Model.address !== null) {
      this.Model.address = this.Model.address.trim()
    }
    if (this.Model.name !== null) {
      this.Model.name = this.Model.name.trim()
    }
  }

  openImage(event: any) {
    this.mediaService.saveRedirectUrl();
    var url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);
  }
}
