import {AfterContentChecked, AfterViewChecked, Component, DoCheck, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-queued-dashboard',
  templateUrl: './queued-dashboard.component.html',
  styleUrls: ['./queued-dashboard.component.scss']
})
export class QueuedDashboardComponent implements OnInit {
  @Input() classes: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  isShowClasses() {
    return this.classes.filter(x => x.maxCapacity > (x.numberOfVisits - x.numberOfQueued) && x.numberOfQueued > 0)?.length > 0;
  }

  goToClass(classItem) {
    if (classItem.id === '00000000000000000000000000000000') {
      this.router.navigate([`../clubschedule/${classItem.startDate}/virtual/${classItem.prototypeId}/${classItem.startDate}`], { relativeTo: this.route })
    } else {
      this.router.navigate([`../clubschedule/${classItem.startDate}/${classItem.id}`], { relativeTo: this.route });
    }
  }
}
