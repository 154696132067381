import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {ElectronicQueueComponent} from '../../components/electronic-queue/electronic-queue.component';

export const electronicQueueRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/queue',
  component: ElectronicQueueComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Электронная очередь'}
};
