import { ClubNetsComponent } from 'app/components/clubnets/clubnets.component';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { ClubnetEditComponent } from 'app/components/clubnets/clubnet-edit/clubnet-edit.component';

export const clubnetsRoute: Route = {
  path: ':cityId/clubnets',
  component: ClubNetsComponent,
  canActivate: [AuthGuard],
  data: { title: 'Сети клубов' }
};


export const clubnetEditRoute: Route = {
  path: ':cityId/clubnets/:id',
  component: ClubnetEditComponent,
  canActivate: [AuthGuard],
  data: { title: 'Редактирование сети клубов' }
};
