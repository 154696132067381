import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/services/api.service';
import { IPasswordChangeRequest } from 'app/common/models/password-change-request.model';

@Injectable()
export class SettingsService {

  constructor(
    private apiService: ApiService
  ) { }

  changePassword ( model: IPasswordChangeRequest ): Promise<{}> {
    return this.apiService.accounts.changePassword( model );
  }
}
