<div class="support cf" myScrollTo>
  <div class="links">
    <h2 class="support__header">Помогите стать лучше!</h2>
  </div>

  <form [formGroup]="form" class="support__form">

    <div class="row">
      <div class="col-xs-12 col-md-8">
        <div class="form-group">
          <textarea class="support__textarea" id="msg" formControlName="message" placeholder="Ваши пожелания :)" maxlength="10000"></textarea>
          <label for="msg" class="field-validation-error" *ngIf="fieldErrors.message && isSubmited">{{fieldErrors.message}}</label>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="form-group">
          <button class="btn btn-primary" (click)="submit()" [disabled]="isSubmited && form.invalid">
            <span class="btn-primary__desc">
              Отправить
            </span>
          </button>
        </div>
      </div>
    </div>

  </form>
</div>
