<div class="content__body">
  <div class="row">
    <div class="row__field form-group col-xs-12 col-sm-6 col-md-4 mb-5">
      <div class="box-title small">Способ расчёта</div>
      <select [(ngModel)]="selectedAlias" class="select select__long" (change)="changeAlias($event.target.value)">
        <option *ngFor="let item of aliases" value="{{item}}">
          {{coachSalaryCalculators[item].aliasDisplayName}}
        </option>
      </select>
    </div>
  </div>

  <form [formGroup]="form">
    <fieldset>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted" *ngFor="let param of aliasParams">
          <div class="form-group">
            <div class="box-title small">{{param.displayName}} <span class="star ng-star-inserted">*</span></div>
            <div class="row__field">
              <input class="form-control input__text input__text--long" name={{param.name}} type="{{param.type}}"
                formControlName={{param.formControlName}} value={{param.value}} />
              <label class="field-validation-error"
                *ngIf="form.get(param.name).touched && form.get(param.name).invalid">Это поле обязательно</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row form-buttons">
        <div class="col-xs-12 col-sm-4 col-md-3">
          <button class="btn btn-primary btn-small" (click)="save()" [disabled]="form.invalid">
            <span class="btn-primary__desc">Сохранить</span>
          </button>
        </div>

        <a class="btn-link cancel-add col-xs-12 col-sm-4 col-md-3" (click)="closeAdd()">
          Отмена
        </a>
      </div>
    </fieldset>
  </form>
</div>
