import {EntityStatus} from './entity-status.model';

export interface Collections {
  id: string;
  cityId: string;
  entityStatus: EntityStatus;
  type: CollectionsTypes;
  name: string;
  data: string;
  partnerAlias: string;
  isTitleVisibility: boolean;
}

export interface ViewTableCollections {
  id: string;
  partnerAlias: string;
  cityName: string;
  entityStatus: string;
  type: string;
  name: string;
  countData: number;
}

export enum CollectionsTypes {
  clubs = 'clubs',
  courses = 'courses',
  subscriptions = 'subscriptions',
  conditionSearch = 'conditionSearch',
  conditionImages = 'conditionImages',
  conditionButtons = 'conditionButtons',
  slide = 'slide',
  buttonCards = 'buttonCards'
}

export interface EntityStatusSetReq {
  id: string;
  entityStatus: EntityStatus;
}


