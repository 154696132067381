import { OnInit, Input, Injector, OnDestroy, Directive } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { ApiService } from 'app/common/services/api.service';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { CityService } from 'app/common/services/city.service';
import { Subject } from 'rxjs';
import { ICityModel } from 'app/common/models/city-model';
import { IRoutingParams } from 'app/common/models/context.model';
import { ContextService } from '../../common/services/context.service';
import { debounceTime, takeUntil, take, filter } from 'rxjs/operators';

@Directive()
export abstract class EntitySearchComponent<T extends { id: string }> implements ControlValueAccessor, OnInit, OnDestroy {
    public data: T;
    public suggestionList = [];
    public searchTerm = new FormControl();
    public isEditing = false;
    public placeholder = 'Начните писать';
    protected api: ApiService;
    protected apiV2Service: ApiV2Service;
    protected cityService: CityService;
    protected contextService: ContextService;
    protected ngUnsubscribe: Subject<void> = new Subject();
    protected currentCity: ICityModel = null;
    public routingParams: IRoutingParams = null;

    @Input() description: string;

    constructor(
        injector: Injector
    ) {
        this.api = injector.get(ApiService);
        this.apiV2Service = injector.get(ApiV2Service);
        this.cityService = injector.get(CityService);
        this.contextService = injector.get(ContextService);
    }

    public abstract niceItemName(item: T): string;
    public abstract findItems(query: string): Promise<Array<T>>;

    private propagateChange: (any: any) => void = () => { };
    public registerOnTouched() { }

    public writeValue(obj: T) {
        this.data = obj;
        this.propagateChange(obj ? obj.id : null);
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public ngOnInit() {
        this.contextService.routingParams.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(Boolean),
            take(1))
            .subscribe(params => this.routingParams = params);

        this.searchTerm.valueChanges.pipe(
            debounceTime(400))
            .subscribe(query => this.search(query));

        this.cityService.currentCity.pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(100),
            filter(Boolean),
            take(1))
            .subscribe(city => {
                this.currentCity = city as ICityModel;
            })
    }

    public search(query) {
        const pattern = query ? query.trim() : '';
        if (pattern.length < 2) {
            this.suggestionList = [];
            this.writeValue(null);
        } else {
            this.isEditing = true;
            this.findItems(pattern).then(res => this.suggestionList = res);
        }
    }

    public choose(item: T) {
        setTimeout(() => {
            this.writeValue(item);
            this.searchTerm.setValue(this.niceItemName(item), { emitEvent: false });
            this.suggestionList = [];
            this.isEditing = false;
        });
    }

    public onClickOutside(event) {
        if (event.isInside === false && this.isEditing) {
            this.cancel(!this.data || (this.suggestionList && this.suggestionList.length === 0));
        }
    }

    public cancel(clear: boolean) {
        setTimeout(() => {
            if (clear) {
                this.writeValue(null);
                this.searchTerm.setValue(null);
            } else {
                this.data && this.searchTerm.setValue(this.niceItemName(this.data), { emitEvent: false });
            }
            this.suggestionList = [];
            this.isEditing = false;
        });
    }
}
