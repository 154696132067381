import { Component, OnInit } from '@angular/core';
import {Accounting} from '../../common/models/accounting.model';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {IRoutingParams} from '../../common/models/context.model';
import {Subject} from 'rxjs';
import {ContextService} from '../../common/services/context.service';
import {RegistryElement} from '../../common/models/registry.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {ApiService} from '../../common/services/api.service';
import {ClubSetting} from '../../common/models/club-setting.model';
import {Location} from '@angular/common';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class AccountingComponent implements OnInit {
  public static readonly componentName: string = 'AccountingComponent';

  protected ngUnsubscribe: Subject<void> = new Subject();
  public context: IRoutingParams;
  public accounting: Accounting[] = [];
  public clubSettings: ClubSetting[] = [];
  public isEnableAdd = false;
  public isShowColumnTargetInAccounting = false;
  public isLoading = false;

  constructor(
    private contextService: ContextService,
    private apiV2: ApiV2Service,
    public _location: Location,
    private api: ApiService
  ) { }

  async ngOnInit(): Promise<any> {
    this.context = await this.contextService.getRoutingParams();
    if (this.context) {
      this.clubSettings = await this.api.clubSettings.getByClub(this.context.clubId);
      if (this.clubSettings.find(x => x.alias === 'IsShowColumnTargetInAccounting'))
        this.isShowColumnTargetInAccounting = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowColumnTargetInAccounting')?.value) ?? false;
      await this.apiV2.accounting.getByClubId(this.context.clubId).then(async (accounting) => {
        this.accounting = accounting;
        this.sortingAccounting();
        this.isLoading = true;
      });
    }
  }

  async addElement(newElement: Accounting): Promise<any> {
    if (newElement == null) {
      this.isEnableAdd = false;
    } else {
      this.accounting.push(newElement);
      this.sortingAccounting();
    }
  }

  async removeElement(oldElement: Accounting): Promise<any> {
    if (oldElement) {
      this.accounting = this.accounting.filter(x => x.id !== oldElement.id);
      this.sortingAccounting();
    }
  }

  edit(editElement: Accounting) {
    if (editElement) {
      for (const element of this.accounting) {
        if (element.id === editElement.id) {
          element.displayDateAt = editElement.displayDateAt;
          element.peoplePerDay = editElement.peoplePerDay;
          element.receivedCash = editElement.receivedCash;
          element.receivedNonCash = editElement.receivedNonCash;
          element.target = editElement.target;
        }
      }
      this.sortingAccounting();
    }
  }

  private sortingAccounting() {
    this.accounting = this.accounting.sort((a, b) => {
      if (a.displayDateAt < b.displayDateAt) { return 1; }
      if (a.displayDateAt > b.displayDateAt) { return -1; }
    });
  }

  public close() { this._location.back(); }
}
