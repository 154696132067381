import { Route } from '@angular/router';
import { ClubAnalyticsComponent } from 'app/components/clubanalytics/club-analytics.component';
import { ClubAnalyticsDetailComponent } from 'app/components/clubanalytics/clubanalytics-detail/club-analytics-detail.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { adminAnalyticsPath, adminReportPath } from 'app/routing/routes/_constants';



export const adminAnalytics: Route = {
  path: adminAnalyticsPath,
  component: ClubAnalyticsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Аналитика' }
};


export const adminReport: Route = {
  path: adminReportPath,
  component: ClubAnalyticsDetailComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Отчет' }
};


export const clubanalytics: Route = {
  path: ':cityId/:clubNetId/:clubId/clubanalytics',
  component: ClubAnalyticsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Аналитика' }
};

export const report: Route = {
  path: ':cityId/:clubNetId/:clubId/clubanalytics/:name',
  component: ClubAnalyticsDetailComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Отчет' }
};

