import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Accounting} from '../../../common/models/accounting.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {RegistryElementStatus} from '../../../common/models/registry.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {EntityStatus} from '../../../common/models/entity-status.model';
import {animate, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'app-accounting-element',
  templateUrl: './accounting-element.component.html',
  styleUrls: ['./accounting-element.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class AccountingElementComponent implements OnInit {
  @Input() element: Accounting;
  @Input() listAccounting: Accounting[] = [];
  @Input() isShowColumnTargetInAccounting: boolean;
  @Output() resultRemove = new EventEmitter<Accounting>();
  @Output() resultEdit = new EventEmitter<Accounting>();
  public form: FormGroup;
  public IsEdit = false;

  constructor(
    private apiV2: ApiV2Service,
    private alertsService: AlertsService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      displayDateAt: new FormControl(new Date(this.element.displayDateAt).toISOString().split('T')[0], Validators.required),
      receivedCash: new FormControl(this.element.receivedCash, [Validators.required, Validators.min(0)]),
      receivedNonCash: new FormControl(this.element.receivedNonCash, [Validators.required, Validators.min(0)]),
      peoplePerDay: new FormControl(this.element.peoplePerDay, [Validators.required, Validators.min(0)]),
      target: new FormControl(this.element.target, this.isShowColumnTargetInAccounting ? [Validators.required, Validators.min(0)] : [])
    });
  }

  remove(element: Accounting) {
    this.alertsService.showConfirm({
      header: 'Удалить учет?',
      message: 'Эта операция изменит статус учета на "Удалено"',
      buttons: [{
        title: 'Удалить',
        callback: async () => {
          await this.delete(element);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public IsDuplicate(): boolean {
    if (this.form.get('displayDateAt').invalid) { return false; } else {
      return this.listAccounting.filter(x => moment(x.displayDateAt).format('YYYYMMDD') === moment(this.form.get('displayDateAt').value).format('YYYYMMDD')).length > 1;
    }
  }

  private async delete(element: Accounting): Promise<any> {
    await this.apiV2.accounting.remove(element.id).then(async (remove) => {
      if (remove) {
        this.resultRemove.emit(element);
      } else {
        console.error('Ошибка удаление учета: ', remove);
      }
    });
  }

  edit() {
    this.IsEdit = true;
  }

  cancel() {
    this.IsEdit = false;
  }

  async confirmSave(element: Accounting): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    if (this.IsDuplicate()) {
      this.alertsService.showConfirm({
        header: 'Дубликат',
        message: 'На выбранную дату уже внесены данные.<br/>Всё равно сохранить?',
        buttons: [{
          title: 'Сохранить',
          callback: async () => {
            await this.save(element);
          }
        }, {
          title: 'Отменить'
        }]
      });
    } else {
      await this.save(element);
    }
  }

  async save(element: Accounting): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    if (this.form.valid) {
      element.displayDateAt = new Date(this.form.get('displayDateAt').value);
      element.peoplePerDay = Math.round(this.form.get('peoplePerDay').value);
      element.receivedCash = this.form.get('receivedCash').value;
      element.receivedNonCash = this.form.get('receivedNonCash').value;
      element.target = this.form.get('target').value;
      await this.apiV2.accounting.save(element).then(async (e) => {
        if (e) {
          e.IsNew = true;
          this.resultEdit.emit(e);
          this.IsEdit = false;
          this.alertsService.alert.next({
            type: 'success',
            message: 'Учет обновлен',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }
}
