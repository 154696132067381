<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Реестры расчета ЗП
    </span>
    <a class="content__header-link" target="_blank" href="https://sportpriority.com/istoriya-izmenenii/crmupdate/novyi-funkcional-rascheta-zarplat-trenerov">
      <span class="sp sp-information"></span>&nbsp;<span class="content__header-link_text">Инструкция</span>
    </a>
  </div>
</header>

<div class="content__body">

  <ng-container>
    <div class="clubnets-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container>
            <a class="box-link" (click)="create()">Создать
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Всего начислено</td>
              <td class="table-cell table-title-cell">Всего выплачено</td>
              <td class="table-cell table-title-cell">Осталось выплатить</td>
              <td class="table-cell table-title-cell">Количество тренеров</td>
              <td class="table-cell table-title-cell">Статус</td>
              <td class="table-cell table-title-cell">Удалить</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let r of registries'>
              <td class="table-cell" (mousedown)="onEditRegistry(r)" (click)="onEditRegistry(r)">
                <div class="desc field">
                  <span> {{ r.title }} </span>
                </div>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r)" (click)="onEditRegistry(r)">
                <div class="desc field">
                  <span>{{ r.total.toFixed(2) }}</span>
                </div>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r)" (click)="onEditRegistry(r)">
                <div class="desc field">
                  <span>{{ r.totalPayout.toFixed(2) }}</span>
                </div>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r)" (click)="onEditRegistry(r)">
                <div class="desc field">
                  <span>{{ (r.total - r.totalPayout).toFixed(2) }}</span>
                </div>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r)" (click)="onEditRegistry(r)">
                <div class="desc field">
                  <span>{{ r.coachCount }}</span>
                </div>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r,$event)" (click)="onEditRegistry(r)">
                <div class="desc field">
                  <span *ngIf="r.status.toLowerCase() == 'draft'" style="color: red;">Черновик</span>
                  <span *ngIf="r.status.toLowerCase() == 'created'" style="color: #dede00;">Создан</span>
                  <span *ngIf="r.status.toLowerCase() == 'confirmed'" style="color: yellowgreen;">Подтвержденный</span>
                  <span *ngIf="r.status.toLowerCase() == 'completed'" style="color: #01e001;">Завершенный</span>
                </div>
              </td>
              <td class="table-cell table-cell_ignore" style="text-align: center;">
                <button class="btn btn-clear active" (click)="remove(r.id)">
                  <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

</div>
