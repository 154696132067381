import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { SubscriptionsComponent } from 'app/components/subscriptions/subscriptions.component';
import { SubscriptionEditComponent } from 'app/components/subscriptions/subscription-edit/subscription-edit.component';
import { SubscriptionSaleComponent } from 'app/components/subscriptions/subscription-sale/subscription-sale.component';

export const subscriptionsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubplans',
  component: SubscriptionsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Абонементы' }
};

export const subscriptionRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubplans/:id',
  component: SubscriptionEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование абонемента' }
};

export const subscriptionSaleRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubplans-sale',
  component: SubscriptionSaleComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Продажа абонемента' }
};
