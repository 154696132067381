import { WorktimeService } from 'app/common/components/working-time/services/worktime.service';

export class WorkingTimeComponentCommon {
  public tempData: any = null;
  public days: Array<any> = [];
  public hours: Array<any>;
  public data: any;

  constructor(
    public service: WorktimeService
  ) { }

  public onChange(day) {
    const index = this.days.indexOf(day);
    this.tempData[day.short].isSelected = true;

    for (let d = index + 1; d < this.days.length; d++) {
      const key = this.days[d].short;

      if (!(this.tempData[key].isSelected)) {
        this.tempData[key].hourFrom = this.tempData[day.short].hourFrom;
        this.tempData[key].hourTo = this.tempData[day.short].hourTo;
      } else {
        break;
      }
    }
  }

  public isDefault() {
    let res = true;
    if (this.tempData && this.days) {
      for (let d = 0; d < this.days.length; d++) {
        const key = this.days[d].short;
        if (this.tempData[key].isSelected) {
          res = false;
        }
      }
    }
    return res;
  }

  modelTransformIn() {
    return this.service.modelTransformIn(this.data);
  }

  modelTransformOut() {
    return this.service.modelTransformOut(this.tempData);
  }

  ngOnInitCommon() {
    this.days = this.service.days();
    this.hours = this.service.hours();
  }
}
