<header class="content__header" *ngIf="Model">

  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание виджета</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование виджета</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <div class="row">
    <div class="col-xs-12">
      <form class="row" [formGroup]="form" novalidate>
        <div class="col-xs-12 col-md-4">
          <div class="form-group" myScrollTo>
            <div class="box-title">Название</div>
            <div class="row__field">
              <input type="text" class="input__text input__text--long" formControlName="name" id="name" [(ngModel)]="Model.name" maxlength="500">
              <label for="name" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group" myScrollTo>
            <div class="box-title">Тип виджета</div>
            <div class="row__field">
              <select [attr.disabled]="!!Model.id ? '' : null" class="select select__long" formControlName="widgetType" id="widgetType"
                [ngModel]="Model.widgetType" (ngModelChange)="onWidgetTypeChange($event)">
                <option *ngFor="let widgetType of widgetTypes|keys" [value]="widgetType">{{widgetType | widgetType}}</option>
              </select>
              <label for="widgetType" class="field-validation-error" *ngIf="fieldErrors.widgetType && isSubmited">{{fieldErrors.widgetType}}</label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group" myScrollTo>
            <div class="box-title">Тема</div>
            <div class="row__field">
              <select class="select select__long" id="theme" formControlName="widgetThemeId"
                [(ngModel)]="currentTheme.widgetThemeId" (change)="onThemeChages($event.target.value)">
                <option *ngIf="isThemeNotSuitable(Model.widgetThemeId)" [value]="Model.widgetThemeId" disabled="true">несовместимая тема !</option>
                <option *ngFor="let theme of themes" value="{{theme.id}}" [ngValue]="theme.id">{{theme.name}}</option>
              </select>
              <label for="theme" class="field-validation-error" *ngIf="fieldErrors.theme && isSubmited">{{fieldErrors.theme}}</label>
              <div class="form__link-box">
                <a class="form-link" href="#" (click)="onEditThemeClick(Model.widgetThemeId, $event)" [class.disabled]="isThemeNotSuitable(Model.widgetThemeId)">
                  редактировать</a>
                <a class="form-link" href="#" (click)="onNewThemeClick($event)">создать</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="Model.widgetType != 'rent'">
          <div class="form-group" myScrollTo>
            <div class="row__field">
              <input type="checkbox" class="checkbox__slide" id="useForVk" formControlName="useForVk" [(ngModel)]="Model.useForVk">
              <label for="useForVk" class="checkbox__slide_label">Использовать для группы ВКонтакте</label>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-xs-12">
      <ng-container *ngIf="Model.settings">
        <hr />
        <ng-container [ngSwitch]="Model.widgetType">
          <ng-container *ngSwitchCase="widgetTypes.schedule">
            <div class="form-group">
              <label class="box-title">Внешнее оформление:</label>
              <div class="panel panel_five">
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="isHideCellsWithoutClasses" [(ngModel)]="Model.settings.isHideCellsWithoutClasses">
                  <label for="isHideCellsWithoutClasses" class="checkbox__slide_label">Скрыть ячейки без занятий</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="box-title">Отображать фильтры:</label>
              <div class="panel panel_five">
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showCoachesFilter" [(ngModel)]="Model.settings.showCoachesFilter">
                  <label for="showCoachesFilter" class="checkbox__slide_label">Тренеров</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showRoomFilter" [(ngModel)]="Model.settings.showRoomFilter">
                  <label for="showRoomFilter" class="checkbox__slide_label">Залов</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showGroupFilter" [(ngModel)]="Model.settings.showGroupFilter">
                  <label for="showGroupFilter" class="checkbox__slide_label">Направлений</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showCoursesFilter" [(ngModel)]="Model.settings.showCoursesFilter">
                  <label for="showCoursesFilter" class="checkbox__slide_label">Видов занятий</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showTrainingCategoryFilter" [(ngModel)]="Model.settings.showTrainingCategoryFilter">
                  <label for="showTrainingCategoryFilter" class="checkbox__slide_label">Видов спорта</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showLevelFilter" [(ngModel)]="Model.settings.showLevelFilter">
                  <label for="showLevelFilter" class="checkbox__slide_label">Уровней</label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="box-title">Способы авторизации:</label>
              <div class="panel panel_five">
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthGoogle" [(ngModel)]="Model.settings.showAuthGoogle">
                  <label for="showAuthGoogle" class="checkbox__slide_label">Google</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthFacebook" [(ngModel)]="Model.settings.showAuthFacebook">
                  <label for="showAuthFacebook" class="checkbox__slide_label">Facebook</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthVK" [(ngModel)]="Model.settings.showAuthVK">
                  <label for="showAuthVK" class="checkbox__slide_label">ВКонтакте</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPhone" [(ngModel)]="Model.settings.showAuthPhone">
                  <label for="showAuthPhone" class="checkbox__slide_label">По номеру телефона</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthEmail" [(ngModel)]="Model.settings.showAuthEmail">
                  <label for="showAuthEmail" class="checkbox__slide_label">По email</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPush" [(ngModel)]="Model.settings.showAuthPush">
                  <label for="showAuthPush" class="checkbox__slide_label">По push</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthLoginAndPassword" [(ngModel)]="Model.settings.showAuthLoginAndPassword">
                  <label for="showAuthLoginAndPassword" class="checkbox__slide_label">По логину и паролю</label>
                </div>
              </div>
            </div>

            <div class="form-group" myScrollTo>
              <div class="panel panel_five">
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="disableSubscribeOnClass" [(ngModel)]="Model.settings.disableSubscribeOnClass">
                  <label for="disableSubscribeOnClass" class="checkbox__slide_label">Отключить запись на занятия</label>
                </div>
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showOccupationLevel" [(ngModel)]="Model.settings.showOccupationLevel">
                  <label for="showOccupationLevel" class="checkbox__slide_label">Показывать уровень занятия</label>
                </div>
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showComments" [(ngModel)]="Model.settings.showComments">
                  <label for="showComments" class="checkbox__slide_label">Отображать комментарии к занятиям</label>
                </div>
<!--                <div class="panel__row">-->
<!--                  <input type="checkbox" class="checkbox__slide" id="showBirthday" [(ngModel)]="Model.settings.showBirthday">-->
<!--                  <label for="showBirthday" class="checkbox__slide_label">Запрашивать дату рождения</label>-->
<!--                </div>-->
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="disableRoom" [(ngModel)]="Model.settings.disableRoom">
                  <label for="disableRoom" class="checkbox__slide_label">Скрыть зал</label>
                </div>
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="surnameIsRequired" [(ngModel)]="Model.settings.surnameIsRequired">
                  <label for="surnameIsRequired" class="checkbox__slide_label">Поле фамилия обязательно при записи</label>
                </div>
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="singleEntryForm" [(ngModel)]="Model.settings.singleEntryForm">
                  <label for="singleEntryForm" class="checkbox__slide_label">Отключить форму разовой записи</label>
                </div>
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showInApp" [(ngModel)]="Model.settings.showInApp">
                  <label for="showInApp" class="checkbox__slide_label">Отображать этот виджет в приложении</label>
                </div>
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="isYouCanQueue" [(ngModel)]="Model.settings.isYouCanQueue">
                  <label for="isYouCanQueue" class="checkbox__slide_label">Можно встать в очередь</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="box-title">Показывать число записавшихся</div>
              <div class="row__field">
                <select class="select select__long" id="showBookedFree" [(ngModel)]="Model.settings.showBookedFree">
                  <option value="dontShow">Не показывать</option>
                  <option value="showFree">Показывать количество свободных мест</option>
                  <option value="showBookedFree">Показывать в формате (занято)/(свободно)</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <div class="box-title">Вид отображения занятий по их занятости</div>
              <div class="row__field">
                <select class="select select__long" id="activeDisplayType"  [(ngModel)]="Model.settings.activeDisplayType">
                  <option value="default">По умолчанию</option>
                  <option value="allocate">Показывать незаполненные занятия отогнутым уголком</option>
                  <option value="empty">Показывать незаполненные занятия пустыми в рамке</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <div class="box-title">Выберите, если нужно показать конкретный зал</div>
              <div class="row__field">
                <select class="select select__long" id="roomId" [(ngModel)]="Model.settings.roomId">
                  <option *ngFor="let room of rooms" value="{{room.id}}">{{room.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group" *ngIf="dictionaries && dictionaries.length">
              <div class="box-title">Словарь</div>
              <div class="row__field">
                <select class="select select__long" [(ngModel)]="Model.settings.dictionaryAlias">
                  <option [value]="null" selected>Не выбран</option>
                  <option *ngFor="let dic of dictionaries" value="{{dic.alias}}" [ngValue]="dic.alias">{{dic.title}}</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <div class="box-title">Культурные настройки</div>
              <div class="row__field">
                <select class="select select__long" [(ngModel)]="Model.settings.culture">
                  <option [value]="null" selected>Не выбраны</option>
                  <option [value]="'en'">en</option>
                </select>
              </div>
            </div>

            </ng-container>
          <ng-container *ngSwitchCase="widgetTypes.scheduleFilter">
              <div class="form-group">

                <div class="form-group">
                  <div class="box-title">Id виджета расписания</div>
                  <div class="row__field">
                    <input type="text" class="input__text input__text--long" [(ngModel)]="Model.settings.scheduleWidgetId">
                  </div>
                </div>

                <label class="box-title">Отображать фильтр:</label>
                <div class="panel panel_five" (click)="onFilterClick($event)">

                  <div class="panel__row">
                    <input type="checkbox" class="checkbox__slide" id="showCoachesFilter" [(ngModel)]="Model.settings.showCoachesFilter" (change)="changeFilterScheduleFilter('showCoachesFilter')">
                    <label for="showCoachesFilter" class="checkbox__slide_label">Тренеров</label>
                  </div>

                  <div class="panel__row">
                    <input type="checkbox" class="checkbox__slide" id="showRoomFilter" [(ngModel)]="Model.settings.showRoomFilter" (change)="changeFilterScheduleFilter('showRoomFilter')">
                    <label for="showRoomFilter" class="checkbox__slide_label">Залов</label>
                  </div>

                  <div class="panel__row">
                    <input type="checkbox" class="checkbox__slide" id="showGroupFilter" [(ngModel)]="Model.settings.showGroupFilter" (change)="changeFilterScheduleFilter('showGroupFilter')">
                    <label for="showGroupFilter" class="checkbox__slide_label">Направлений</label>
                  </div>

                  <div class="panel__row">
                    <input type="checkbox" class="checkbox__slide" id="showCoursesFilter" [(ngModel)]="Model.settings.showCoursesFilter" (change)="changeFilterScheduleFilter('showCoursesFilter')">
                    <label for="showCoursesFilter" class="checkbox__slide_label">Видов занятий</label>
                  </div>

                  <div class="panel__row">
                    <input type="checkbox" class="checkbox__slide" id="showTrainingCategoryFilter" [(ngModel)]="Model.settings.showTrainingCategoryFilter" (change)="changeFilterScheduleFilter('showTrainingCategoryFilter')">
                    <label for="showTrainingCategoryFilter" class="checkbox__slide_label">Видов спорта</label>
                  </div>

                  <div class="panel__row">
                    <input type="checkbox" class="checkbox__slide" id="showClubNet" [(ngModel)]="Model.settings.showClubNet" (change)="changeFilterScheduleFilter('showClubNet')">
                    <label for="showClubNet" class="checkbox__slide_label">Филиалы</label>
                  </div>

                </div>

                  <div class="form-group" *ngIf="filters[selectFilter]?.length">
                    <label class="box-title">Дополнительно:</label>
                    <div class="panel panel_five">
                        <ng-container *ngFor="let f of filters[selectFilter]">
                          <div class="panel__row">
                              <input type="checkbox" [ngModel]="f.check" class="checkbox__slide" [id]="'f' + f.id" (change)="changeAdvancedFilter(f)">
                              <label [for]="'f' + f.id" class="checkbox__slide_label">{{f.name}}</label>
                          </div>
                        </ng-container>
                    </div>
                  </div>
                </div>
            </ng-container>

          <ng-container *ngSwitchCase="widgetTypes.payment">
            <div class="form-group">
              <app-text-edit myScrollTo [(ngModel)]="Model.settings.description" [(data)]="Model.settings.description" [title]="'Краткое описание'" [isReadonly]="!isEditAvailable"
                             [type]="'textarea'">
              </app-text-edit>
              <div class="clearfix"></div>
            </div>
            <div class="form-group">
              <div class="form-group">
                <div class="box-title">Id виджета сертификата</div>
                <div class="row__field">
                  <input type="text" class="input__text input__text--long" [(ngModel)]="Model.dependenceWidgetId">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="box-title">Способы авторизации:</label>
              <div class="panel panel_five">
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthGoogle" [(ngModel)]="Model.settings.showAuthGoogle">
                  <label for="showAuthGoogle" class="checkbox__slide_label">Google</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthFacebook" [(ngModel)]="Model.settings.showAuthFacebook">
                  <label for="showAuthFacebook" class="checkbox__slide_label">Facebook</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthVK" [(ngModel)]="Model.settings.showAuthVK">
                  <label for="showAuthVK" class="checkbox__slide_label">ВКонтакте</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPhone" [(ngModel)]="Model.settings.showAuthPhone">
                  <label for="showAuthPhone" class="checkbox__slide_label">По номеру телефона</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthEmail" [(ngModel)]="Model.settings.showAuthEmail">
                  <label for="showAuthEmail" class="checkbox__slide_label">По email</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPush" [(ngModel)]="Model.settings.showAuthPush">
                  <label for="showAuthPush" class="checkbox__slide_label">По push</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthLoginAndPassword" [(ngModel)]="Model.settings.showAuthLoginAndPassword">
                  <label for="showAuthLoginAndPassword" class="checkbox__slide_label">По логину и паролю</label>
                </div>
              </div>
            </div>
            <div class="panel__four">
              <div class="panel__row" *ngIf="Model.dependenceWidgetId">
                <input type="checkbox" class="checkbox__slide" id="hidePaymentForm" [(ngModel)]="Model.settings.hidePaymentForm">
                <label for="hidePaymentForm" class="checkbox__slide_label">Скрыть форму оплаты</label>
              </div>
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="hideSbp" [(ngModel)]="Model.settings.hideSbp">
                <label for="hideSbp" class="checkbox__slide_label">Скрыть систему быстрых платежей</label>
              </div>
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="showBranch" [(ngModel)]="Model.settings.showBranch">
                <label for="showBranch" class="checkbox__slide_label">Показать выбор филиалов</label>
              </div>
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="isFillComment" [(ngModel)]="Model.settings.isFillComment">
                <label for="isFillComment" class="checkbox__slide_label">Обязательно запрашивать поле «Комментарий»</label>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="box-title">Placeholder для поле «Комментарий»</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" [(ngModel)]="Model.settings.placeholderComment">
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="widgetTypes.purchaseSubscription">

            <div class="form-group">
              <app-text-edit myScrollTo [(ngModel)]="Model.settings.description" [(data)]="Model.settings.description" [title]="'Краткое описание'" [isReadonly]="!isEditAvailable"
                             [type]="'textarea'">
              </app-text-edit>
              <div class="clearfix"></div>
            </div>

            <div class="form-group">
              <label class="box-title">Способы авторизации:</label>
              <div class="panel panel_five">

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthGoogle" [(ngModel)]="Model.settings.showAuthGoogle">
                  <label for="showAuthGoogle" class="checkbox__slide_label">Google</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthFacebook" [(ngModel)]="Model.settings.showAuthFacebook">
                  <label for="showAuthFacebook" class="checkbox__slide_label">Facebook</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthVK" [(ngModel)]="Model.settings.showAuthVK">
                  <label for="showAuthVK" class="checkbox__slide_label">ВКонтакте</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPhone" [(ngModel)]="Model.settings.showAuthPhone">
                  <label for="showAuthPhone" class="checkbox__slide_label">По номеру телефона</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthEmail" [(ngModel)]="Model.settings.showAuthEmail">
                  <label for="showAuthEmail" class="checkbox__slide_label">По email</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPush" [(ngModel)]="Model.settings.showAuthPush">
                  <label for="showAuthPush" class="checkbox__slide_label">По push</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthLoginAndPassword" [(ngModel)]="Model.settings.showAuthLoginAndPassword">
                  <label for="showAuthLoginAndPassword" class="checkbox__slide_label">По логину и паролю</label>
                </div>

              </div>
            </div>

            <div class="panel__four">
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="hideSbp" [(ngModel)]="Model.settings.hideSbp">
                <label for="hideSbp" class="checkbox__slide_label">Скрыть систему быстрых платежей</label>
              </div>
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="isFillComment" [(ngModel)]="Model.settings.isFillComment">
                <label for="isFillComment" class="checkbox__slide_label">Обязательно запрашивать поле «Комментарий»</label>
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="box-title">Placeholder для поле «Комментарий»</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" [(ngModel)]="Model.settings.placeholderComment">
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="Model.widgetType == 'certificates' || Model.widgetType == 'payment'">
            <div class="form-group col-md-12">
              <div class="box-title">Политика конфиденциальности</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" [(ngModel)]="Model.settings.privacyPolicy">
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="Model.widgetType == 'certificates'">
            <div class="form-group">
              <label class="box-title">Способы авторизации:</label>
              <div class="panel panel_five">
                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthGoogle" [(ngModel)]="Model.settings.showAuthGoogle">
                  <label for="showAuthGoogle" class="checkbox__slide_label">Google</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthFacebook" [(ngModel)]="Model.settings.showAuthFacebook">
                  <label for="showAuthFacebook" class="checkbox__slide_label">Facebook</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthVK" [(ngModel)]="Model.settings.showAuthVK">
                  <label for="showAuthVK" class="checkbox__slide_label">ВКонтакте</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPhone" [(ngModel)]="Model.settings.showAuthPhone">
                  <label for="showAuthPhone" class="checkbox__slide_label">По номеру телефона</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthEmail" [(ngModel)]="Model.settings.showAuthEmail">
                  <label for="showAuthEmail" class="checkbox__slide_label">По email</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthPush" [(ngModel)]="Model.settings.showAuthPush">
                  <label for="showAuthPush" class="checkbox__slide_label">По push</label>
                </div>

                <div class="panel__row">
                  <input type="checkbox" class="checkbox__slide" id="showAuthLoginAndPassword" [(ngModel)]="Model.settings.showAuthLoginAndPassword">
                  <label for="showAuthLoginAndPassword" class="checkbox__slide_label">По логину и паролю</label>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="Model.widgetType == 'payment' || Model.widgetType == 'certificates'">
            <div class="form-group col-md-12" *ngIf="dictionaries && dictionaries.length">
              <div class="box-title">Словарь</div>
              <div class="row__field">
                <select class="select select__long" [(ngModel)]="Model.settings.dictionaryAlias">
                  <option [value]="null" selected>Не выбран</option>
                  <option *ngFor="let dic of dictionaries" value="{{dic.alias}}" [ngValue]="dic.alias">{{dic.title}}</option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="box-title">Описание абонементов</div>
              <div class="row__field">
                <select class="select select__long" [(ngModel)]="Model.settings.visibilityDescSub">
                  <option value="0">Всегда скрывать описание</option>
                  <option value="1">Показывать только в десктопной версии</option>
                  <option value="2">Показывать только в мобильных устройствах</option>
                  <option value="3">Всегда показывать описание</option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="box-title">Настройки авторизации</div>
              <div class="row__field">
                <select class="select select__long" [(ngModel)]="Model.settings.availableInfo">
                  <option value="0">Разрешить любые оплаты</option>
                  <option value="1">Требовать авторизацию</option>
                  <option value="2">Запретить авторизацию</option>
                </select>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="Model.widgetType == 'payment' || Model.widgetType == 'certificates'">
            <div class="form-group col-md-12">
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="isProhibitChangingTheSubscriptionPrice" [(ngModel)]="Model.settings.isProhibitChangingTheSubscriptionPrice">
                <label for="isProhibitChangingTheSubscriptionPrice" class="checkbox__slide_label">Запретить изменение цены абонемента</label>
              </div>
              <div class="panel__row">
                <input type="checkbox" class="checkbox__slide" id="isRemoveCustomPayment" [(ngModel)]="Model.settings.isRemoveCustomPayment">
                <label for="isRemoveCustomPayment" class="checkbox__slide_label">Убрать оплату произвольной суммы</label>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="widgetTypes.rent">
            <div class="form-group col-md-4">
              <div class="box-title">Вид занятия</div>
              <div class="row__field">
                <select class="select select__long" [(ngModel)]="Model.settings.courseId">
                  <option *ngFor="let course of courses" [value]="course.id">{{course.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-12">
              <input type="checkbox" class="checkbox__slide" id="showInAppRent" [(ngModel)]="Model.settings.showInApp">
              <label for="showInAppRent" class="checkbox__slide_label">Отображать этот виджет в приложении</label>
            </div>
            <div class="form-group col-md-12">
              <input type="checkbox" class="checkbox__slide" id="split30Minutes" [(ngModel)]="Model.settings.split30Minutes">
              <label for="split30Minutes" class="checkbox__slide_label">Разбить на 30 минут</label>
            </div>
            <div class="form-group col-md-6">
              <div class="box-title">Минимальное количество выбранных ячеек</div>
              <div class="row__field">
                <input type="number" class="input__text input__text--long" id="minS" [(ngModel)]="Model.settings.minS">
              </div>
            </div>
            <div class="form-group col-md-6">
              <div class="box-title">Максимально количество выбранных ячеек</div>
              <div class="row__field">
                <input type="number" class="input__text input__text--long" id="maxS" [(ngModel)]="Model.settings.maxS">
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="box-title">Информационное сообщение</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" [(ngModel)]="Model.settings.message">
              </div>
            </div>
            <div class="form-group col-md-12">
              <input type="checkbox" class="checkbox__slide" id="isOnlyAuth" [(ngModel)]="Model.settings.isOnlyAuth">
              <label for="isOnlyAuth" class="checkbox__slide_label">Показывать расписание только авторизованным пользователям</label>
            </div>
            <div class="form-group col-md-12">
              <div class="box-title">Залы</div>
              <div class="row__field">
                <app-multiple-checkbox [options]="rooms" [(ngModel)]="Model.settings.rooms">
                </app-multiple-checkbox>
              </div>
            </div>
            <ng-container>
              <div class="form-group col-md-12">
                <div class="row__field">
                  <div class="table-responsive">
                    <table class="table rowHover">
                      <thead>
                        <tr>
                          <td class="table-cell table-title-cell" *ngFor="let m of rentMatrix">{{m.dayWeek|DayWeek}}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let h of showTime; let r = index">
                          <td class="table-cell table-cell-matrix" *ngFor="let d of rentMatrix">
                            <ng-container *ngFor="let t of d.time">
                              <ng-container *ngIf="h === t.time">
                                <div class="selectSubMatrix">
                                  <span>{{t.time}}</span>
                                  <select [(ngModel)]="t.subId" (change)="changeMatrix()">
                                    <optgroup label="{{s.name}}" *ngFor="let s of allSubscription">
                                      <ng-container *ngFor="let v of s.variants">
                                        <option [value]="v.id" *ngIf="v.visitCount == 1">{{v.title}}</option>
                                      </ng-container>
                                    </optgroup>
                                  </select>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!isShowDay(h, d)"><span class="table-cell-matrix-nan">&mdash;</span></ng-container>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </ng-container>
        <br>
        <br>
      </ng-container>
    </div>

  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="row" *ngIf="isEditAvailable">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <ng-container *ngIf="Model.id">
                    <span>Сохранить</span>
                    <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                  </ng-container>
                  <ng-container *ngIf="!Model.id">
                    <span>Получить код виджета</span>
                    <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                  </ng-container>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="code">
        <div class="col-xs-12">
          <br>
          <br>
          <br>
          <div class="form-group" myScrollTo>
            <div class="box-title">Код для вставки на сайт</div>
            <div class="row__field">
              <textarea [(ngModel)]="code" readonly="readonly" style="min-height: 110px; resize:none; margin-bottom:20px"></textarea>
              <button class="btn btn-outline btn-small btn-narrow" (click)="onCopyCodeClick($event)">
                <span class="btn-outline__desc">
                  Скопировать в буфер
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="code && Model.widgetType != 'rent'">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="box-title">Установить в группу ВКонтакте</div>
            <div class="row__field">
              <button class="btn btn-outline btn-small btn-narrow" (click)="onInstallVkClick()">
                <span class="btn-outline__desc">
                  Установить
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
