import {Component, Input, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'app/common/services/context.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  protected ngUnsubscribe: Subject<void> = new Subject();
  @Input() isThemePartner: boolean;
  public supportUrl: string = null;

  constructor(
    public contextService: ContextService,
    public router: Router
  ) {
    this.contextService.routingParams.subscribe(params =>
      this.supportUrl = params.isInClubContext ? this.contextService.makeContextUrl('/support') : null
    );
  }
}
