import { Injectable } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { AuthService } from 'app/common/services/auth.service';
import { IUserInfo } from 'app/common/models/user-info.model';
import {
    IChatInfo,
    IChatGetMessagesReq,
    IChatSendMessageReq,
    IChatMessage,
    IChatReadMessageReq,
    IChatRequestReq
} from 'app/common/models/chat.models';
import { ITableListService } from 'app/common/table-list/table-list.service';
import { idChatPath, idClubChatPath, mailingClubPath, mailingPath } from 'app/routing/routes/_constants';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { ContextService } from 'app/common/services/context.service';
import { ICityModel } from 'app/common/models/city-model';


@Injectable()
export class ChatService implements ITableListService {
    public userInfo: IUserInfo;
    public routingParams: IRoutingParams;

    private _pendingUrl: UrlSegment[];
    get pendingUrl(): UrlSegment[] {
        return this._pendingUrl;
    }

    private _redirectUrl: string;
    get redirectUrl(): string {
        return this._redirectUrl;
    }

    delete(_model: any): any { }
    save(_model: any): any { }
    create(_model: any): any { }

    constructor(
        private apiV2Service: ApiV2Service,
        private authService: AuthService,
        private contextService: ContextService,
        private route: ActivatedRoute
    ) {
        this.authService.getUserInfo()
            .then(userInfo => this.userInfo = userInfo);
        this.contextService.pendingUrl.subscribe(url => this._pendingUrl = url);
        this.contextService.routingParams.subscribe(res => this.routingParams = res);
    }

    public saveRedirectUrl() {
        this._redirectUrl = this.pendingUrl.reduce((x, y) => x + `/${y}`, '');
    }

    public getSingleItem(req: IRoutingParams) {
        if (this.route.root.firstChild.routeConfig.path === idChatPath ||
            this.route.root.firstChild.routeConfig.path === idClubChatPath ||
            this.route.root.firstChild.routeConfig.path === mailingPath ||
            this.route.root.firstChild.routeConfig.path === mailingClubPath) {
            return this.apiV2Service.chats.get(req.id, this.routingParams.cityId, this.routingParams.clubId);
        } else {
            return this.apiV2Service.chats.request({ entityType: 'user', entityId: req.id });
        }
    }

    public getItemList(city?: ICityModel, pageIndex?: number, pageSize?: number) {
        return this.apiV2Service.chats.getAll(this.routingParams.clubId, pageIndex, pageSize, city ? city.id : '00000000000000000000000000000000')
    }

    public getRelatedChatList(city?: ICityModel, mailingId?: string, pageIndex?: number, pageSize?: number) {
        return this.apiV2Service.chats.getAll(this.routingParams.clubId, pageIndex, pageSize, city ? city.id : '00000000000000000000000000000000', mailingId)
    }

    public getActiveItemList(clubId: string, pageSize = 10) {
      return this.apiV2Service.chats.getActive(clubId, this.routingParams.cityId, pageSize);
    }

    public getMessages(chat: IChatInfo, loadAll = false, pageSize = 20, pageIndex = 0) {
        const req: IChatGetMessagesReq = {
            cityId: this.routingParams.cityId,
            clubId: this.routingParams.clubId,
            pageIndex: pageIndex,
            pageSize: pageSize,
            chatId: chat.chat.id,
            lastUpdateTime: loadAll ? 0 : chat.chat.updatedAt
        };
        return this.apiV2Service.chats.getMessages(req);
    }

    public sendMessage(chat: IChatInfo, msg: string, srConnectionId: string) {
        const req: IChatSendMessageReq = {
            srConnectionId: srConnectionId,
            cityId: this.routingParams.cityId,
            clubId: this.routingParams.clubId,
            chatId: chat.chat.id,
            text: msg
        };
        return this.apiV2Service.chats.sendMessage(req);
    }

    public readMessages(chat: IChatInfo, msg: IChatMessage) {
        const req: IChatReadMessageReq = {
            chatId: chat.chat.id,
            lastReadMessageId: msg.id
        };
        return this.apiV2Service.chats.readMessages(req);
    }

    public request(clientId: string) {
        const req: IChatRequestReq = {
            userId: this.userInfo.id,
            entityType: 'client',
            entityId: clientId,
            cityId: this.routingParams.cityId,
            clubId: this.routingParams.clubId,
        }
        return this.apiV2Service.chats.request(req);
    }

    public requestEntities() {
        return this.apiV2Service.chats.requestEntities(this.routingParams.clubId);
    }
}
