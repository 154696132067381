<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        {{title}}
      </span>
    </div>
  </div>
</header>
<div class="content__body">
  <div class="row">
    <ng-container>
      <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted" *ngFor="let param of params">
        <div class="form-group">
          <div class="box-title">{{param.displayName}} <span class="star ng-star-inserted">*</span></div>
          <div class="row__field">
            <app-date-picker-registry [(ngModel)]="param.value" (ngModelChange)="onParamChange()" [param]="param"></app-date-picker-registry>
          </div>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="!context.getRoutingParams().type">
        <div class="main-checkbox-block">
          <input type="checkbox" class="checkbox__slide" id="isDoubleReport" [(ngModel)]="isDoubleReport">
          <label for="isDoubleReport" class="checkbox__slide_label">Только для тех, кто получает реестр три раза в месяц</label>
        </div>
        <div class="main-checkbox-block">
          <input type="checkbox" class="checkbox__slide" id="isEliminateDuplicates" [(ngModel)]="isEliminateDuplicates">
          <label for="isEliminateDuplicates" class="checkbox__slide_label">Исключить уже включенные в другие реестры</label>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <br>
        <br>
        <button class="btn btn-primary btn-small" (click)="getReportResult()">
          <span class="btn-primary__desc">{{!context.getRoutingParams().type ? 'Сформировать' : 'Скачать'}}</span>
        </button>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="!isValid">
      <div class="form-group">
        <label class="field-validation-error static" [innerHtml]="validationStatus"></label>
        <br>
        <br>
      </div>
    </div>
  </div>
</div>
