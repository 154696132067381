import {Component, Injector, OnInit} from '@angular/core';
import {TableListComponent} from '../../common/table-list/table-list.component';
import {IWebhookModel} from '../../common/models/webhook.model';
import {WebhookService} from './webhook.service';
import {EntityStatus} from '../../common/models/entity-status.model';

@Component({
  selector: 'app-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.scss']
})
export class WebhookComponent extends TableListComponent<IWebhookModel> implements OnInit {
  public static readonly componentName: string = 'WebhookComponent';
  public readonly EntityStatus = EntityStatus;

  constructor(injector: Injector, protected service: WebhookService) {
    super(injector, service);
  }

  async ngOnInit() {
    super.ngOnInit();
  }
}

