<div class="" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit" *ngIf="!isReadonly">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>


  <div class="row__field ch" [hidden]="enableEdit" (click)="onBeginEdit()">
    <div class="inn legal__label" *ngIf="_isPermit('inn')">
      <span>ИНН:</span>
      <span class="legal__desc" *ngIf="tempData?.inn !== '' && tempData?.inn !== null; else noInn">{{tempData?.inn}}</span>
      <ng-template #noInn>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="legalName legal__label" *ngIf="_isPermit('legalName')">
      <span>Наименование:</span>
      <span class="legal__desc" *ngIf="tempData?.legalName !== '' && tempData?.legalName !== null; else noLegalName">{{tempData?.legalName}}</span>
      <ng-template #noLegalName>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="bikBank legal__label" *ngIf="_isPermit('bikBank')">
      <span>БИК банка:</span>
      <span class="legal__desc" *ngIf="tempData?.bikBank !== '' && tempData?.bikBank !== null; else noBikBank">{{tempData?.bikBank}}</span>
      <ng-template #noBikBank>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="nameBank legal__label" *ngIf="_isPermit('nameBank')">
      <span>Наименование банка:</span>
      <span class="legal__desc" *ngIf="tempData?.nameBank !== '' && tempData?.nameBank !== null; else noNameBank">{{tempData?.nameBank}}</span>
      <ng-template #noNameBank>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="currentAccountNumber legal__label" *ngIf="_isPermit('currentAccountNumber')">
      <span>Номер расчетного счета:</span>
      <span class="legal__desc" *ngIf="tempData?.currentAccountNumber !== '' && tempData?.currentAccountNumber !== null; else noCurrentAccountNumber">{{tempData?.currentAccountNumber}}</span>
      <ng-template #noCurrentAccountNumber>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="numberBankCorrespondentAccount legal__label" *ngIf="_isPermit('numberBankCorrespondentAccount')">
      <span>Номер корреспондентского счета банка:</span>
      <span class="legal__desc" *ngIf="tempData?.numberBankCorrespondentAccount !== '' && tempData?.numberBankCorrespondentAccount !== null; else numberBankCorrespondentAccount">{{tempData?.numberBankCorrespondentAccount}}</span>
      <ng-template #numberBankCorrespondentAccount>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="contractBasisPayment legal__label" *ngIf="_isPermit('contractBasisPayment')">
      <span>Договор-основание оплаты:</span>
      <span class="legal__desc" *ngIf="tempData?.contractBasisPayment !== '' && tempData?.contractBasisPayment !== null; else contractBasisPayment">{{tempData?.contractBasisPayment}}</span>
      <ng-template #contractBasisPayment>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="contractBasisPayment legal__label" *ngIf="_isPermit('contractBasisPayment')">
      <span>Ссылка на документы:</span>
      <span class="legal__desc" *ngIf="tempData?.documentLink !== '' && tempData?.documentLink !== null; else documentLink">{{tempData?.documentLink}}</span>
      <ng-template #documentLink>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
  </div>

  <div class="row__field" [hidden]="!enableEdit">
    <form [formGroup]="form" novalidate>
      <div class="form-group" myScrollTo *ngIf="_isPermit('inn')">
        <label class="box-title" for="inn">ИНН</label>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder="Идентификационный номер налогоплательщика"
               name="inn"
               [appRestrictInput]="{min:0, max: Infinity}"
               formControlName="inn" id="inn" />
        <label for="inn" class="field-validation-error" *ngIf="fieldErrors?.inn">Не корректно введен ИНН. Введите номер ИНН без пробелов!</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('legalName')">
        <label class="box-title" for="legalName">Наименование</label>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder="Наименование"
               name="legalName"
               formControlName="legalName" id="legalName" />
        <label for="legalName" class="field-validation-error" *ngIf="fieldErrors?.legalName">{{fieldErrors?.legalName}}</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('bikBank')">
        <label class="box-title" for="bikBank">БИК банка</label>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder="БИК банка"
               name="bikBank"
               [appRestrictInput]="{min:0, max: Infinity}"
               formControlName="bikBank" id="bikBank" />
        <label for="bikBank" class="field-validation-error" *ngIf="fieldErrors?.bikBank">Не корректно введен БИК банка. Введите номер БИК банка без пробелов!</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('nameBank')">
        <label class="box-title" for="nameBank">Наименование банка</label>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder="Наименование банка"
               name="nameBank"
               formControlName="nameBank" id="nameBank" />
        <label for="nameBank" class="field-validation-error" *ngIf="fieldErrors?.nameBank">{{fieldErrors?.nameBank}}</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('currentAccountNumber')">
        <label class="box-title" for="currentAccountNumber">Номер расчетного счета</label>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder="Номер расчетного счета"
               name="currentAccountNumber"
               [appRestrictInput]="{min:0, max: Infinity}"
               formControlName="currentAccountNumber" id="currentAccountNumber" />
        <label for="currentAccountNumber" class="field-validation-error" *ngIf="fieldErrors?.currentAccountNumber">Не корректно введен Номер расчетного счета. Введите номер Номер расчетного счета без пробелов!</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('numberBankCorrespondentAccount')">
        <label class="box-title" for="numberBankCorrespondentAccount">Номер корреспондентского счета банка</label>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder="Номер корреспондентского счета банка"
               name="numberBankCorrespondentAccount"
               [appRestrictInput]="{min:0, max: Infinity}"
               formControlName="numberBankCorrespondentAccount" id="numberBankCorrespondentAccount" />
        <label for="numberBankCorrespondentAccount" class="field-validation-error" *ngIf="fieldErrors?.numberBankCorrespondentAccount">Не корректно введен Номер корреспондентского счета.</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('contractBasisPayment')">
        <label class="box-title" for="contractBasisPayment">Договор-основание оплаты</label>
        <div class="tooltip tooltip-legal-info">Пояснение: "Договор 50/22 от 10.01.2022"</div>
        <input class="form-control input__text input__text--long"
               maxlength="500"
               placeholder='Пояснение: "Договор 50/22 от 10.01.2022"'
               name="contractBasisPayment"
               formControlName="contractBasisPayment" id="contractBasisPayment" />
        <label for="contractBasisPayment" class="field-validation-error" *ngIf="fieldErrors?.contractBasisPayment">{{fieldErrors?.contractBasisPayment}}</label>
      </div>
      <div class="form-group" myScrollTo *ngIf="_isPermit('documentLink')">
        <label class="box-title" for="documentLink">Ссылка на документы</label>
        <input class="form-control input__text input__text--long"
               placeholder='https://google.com/'
               name="documentLink"
               formControlName="documentLink" id="documentLink" />
        <label for="documentLink" class="field-validation-error" *ngIf="fieldErrors?.d">{{fieldErrors?.documentLink}}</label>
      </div>
    </form>
  </div>
</div>
