import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ICoachRegistryModel, CoachAccrual } from 'app/common/models/coach-registry.model';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { PaymentTypeAlias } from 'app/common/models/coach-rate.model';
import { IRoutingParams } from 'app/common/models/context.model';
import { ContextService } from 'app/common/services/context.service';
import { CoachesRateCalculateService } from '../../services/coaches-rate-calculate.service';

@Component({
  selector: 'app-coachregistry-element-edit',
  templateUrl: './coachregistry-element-edit.component.html',
  styleUrls: ['./coachregistry-element-edit.component.scss']
})
export class CoachRegistryElementEditComponent implements OnInit {

  @Input() element: CoachAccrual;
  @Input() registry: ICoachRegistryModel;
  @Output() result = new EventEmitter<CoachAccrual>();

  public form: FormGroup;
  public params: any = {};
  public aliasParams: any[] = [];
  public selectedAlias: any = {};
  public aliases: any[] = [];
  public coachSalaryCalculators: {
    [ key in PaymentTypeAlias | 'salary' | 'other']: {
      aliasDisplayName: string,
      func: (params: any) => number,
      params: string[]
    }
  } = {
    "class": {
      aliasDisplayName:"За занятие",
      func: this.calcSalaryClass,
      params: ["class"]
    },
    "personInGroup": {
      aliasDisplayName:"За количество занимающихся",
      func: this.calcSalaryPersonInGroup ,
      params: ["personInGroupRate", "personCount"]
    },
    "subscription": {
      aliasDisplayName:"За абонемент",
      func: this.calcAboniment ,
      params: ["subscriptions"]
    },
    "salary": {
      aliasDisplayName:"Оклад",
      func: this.calcSalary,
      params: ["salary"]
    },
    "other": {
      aliasDisplayName:"Прочее",
      func: this.calcAdditional,
      params: ["other"]
    }
  };

  private group: any = {};

  private basicParams: any = {
    "class": { paramDisplayName: "Ставка за занятие", paramType: "number" },
    "personInGroupRate": { paramDisplayName: "Ставка за занимающегося в группе", paramType: "number" },
    "personCount": { paramDisplayName: "Число занимающихся", paramType: "number" },
    "salary": { paramDisplayName: "Размер оклада", paramType: "number" },
    "duration": { paramDisplayName: "Продолжительность занятия", paramType: "number" },
    "other": { paramDisplayName: "Дополнительная сумма", paramType: "number" },
    "subscriptions": { paramDisplayName: "Сумма", paramType: "number" }
  };

  public contextService: ContextService;
  public routingParams: IRoutingParams;

  constructor(
    private coachRegistryService: CoachRegistryService,
    private alertsService: AlertsService,
    private readonly injector: Injector,
    private coachesRateCalculate: CoachesRateCalculateService
  ) {
    this.contextService = injector.get(ContextService);
    this.contextService.routingParams.subscribe(params => {
      this.routingParams = params;
    });
  }

  async ngOnInit(): Promise<any> {
    this.selectedAlias = this.element.paymentTypeAlias ?? PaymentTypeAlias.class;
    this.aliases = Object.keys(this.coachesRateCalculate.coachSalaryCalculators)
    this.params = JSON.parse(this.element.params);
    this.aliasParams.push({
      name: "title",
      displayName: "Комментарий",
      type: "text",
      formControlName: "title",
      value: this.element.title
    })
    for(let p of this.coachesRateCalculate.coachSalaryCalculators[this.selectedAlias].params) {
      this.aliasParams.push({
        name: p,
        displayName: this.basicParams[p].paramDisplayName,
        type: this.basicParams[p].paramType,
        formControlName: p,
        value: this.params[p]
      })
    }
    if(this.aliasParams.length) {
      this.group[this.aliasParams[0].name] = new FormControl(this.aliasParams[0].value.toString().replace('&lt;', '<').replace('&gt;', '>'), Validators.required);
        for(let i = 1; i < this.aliasParams.length; i++)
        {
          this.group[this.aliasParams[i].name] = new FormControl(this.aliasParams[i].value.toString(), Validators.required);
        }
      this.group['status'] = new FormControl(this.element.status, Validators.required);
      this.form = new FormGroup(this.group);
    }
  }

  public changeAlias(alias){
    this.aliasParams = []
    this.aliasParams.push({
      name: "title",
      displayName: "Комментарий",
      type: "text",
      formControlName: "title",
      value: this.element.title
    })
    for (let p of this.coachesRateCalculate.coachSalaryCalculators[alias].params) {
      this.aliasParams.push({
        name: p || "",
        displayName: this.basicParams[p].paramDisplayName || "",
        type: this.basicParams[p].paramType || "text",
        formControlName: p,
        value: this.params[p]
      })
    }

    this.group = {};
    if(this.aliasParams.length) {
      this.group[this.aliasParams[0].name] = new FormControl(this.aliasParams[0].value.toString().replace('&lt;', '<').replace('&gt;', '>'), Validators.required)
      for(let i = 1; i < this.aliasParams.length; i++) {
        this.group[this.aliasParams[i].name] = new FormControl(this.aliasParams[i].value.toString(), Validators.required)
      }
      this.group['status'] = new FormControl(this.element.status, Validators.required);
      this.form = new FormGroup(this.group);
    }
  }

  public async save(): Promise<any> {
    if (this.form.valid) {
      for (let p of this.coachesRateCalculate.coachSalaryCalculators[this.selectedAlias].params) {
        this.params[p] = isNaN(this.form.get(p).value) ? 0 : Number.parseFloat(this.form.get(p).value);
      }
      this.element.total = this.coachesRateCalculate.coachSalaryCalculators[this.selectedAlias].edit(this.params);
      this.element.paymentTypeAlias = this.selectedAlias;
      this.element.params = JSON.stringify(this.params);
      this.element.title = this.form.get('title').value;
      this.element.status = this.form.get('status').value;

      await this.coachRegistryService.saveAccrual(this.element).then(async (e) => {
        if (e) {
          this.element.isEnableMode = false;
          this.result.emit(this.element);
          this.alertsService.alert.next({
            type: 'success',
            message: 'Транзакция сохранена',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
    else
      console.log('Form is invalid!')
  }

  public paramDetails(): any {
    let res = [];
    if (this.aliasParams.length > 2){
      for(let p of this.coachesRateCalculate.coachSalaryCalculators[this.selectedAlias].params) {
        res.push({
          name: this.basicParams[p].paramDisplayName ,
          value: this.params[p]
        })
      }
    }
    return res;
  }

  public allParamsInfo(): any {
    let res = [];
    const params = Object.keys(this.params).filter(x => x !== 'details')
    for(let p of params) {
      res.push({
        name: this.basicParams[p].paramDisplayName ,
        value: this.params[p]
      })
    }
    return res;
  }

  private calcSalaryClass(params: any): number{
    return params['class'] || 0;
  }

  private calcSalaryPersonInGroup(params: any): number{
    return (params['personInGroupRate'] || 0) * (params['personCount'] || 0);
  }

  private calcAboniment(params: any): number{
    return params['subscriptions'] || 0;
  }

  private calcSalary(params:any): number{
    return params['salary'] || 0;
  }

  private calcAdditional(params: any): number{
    return params['other'] || 0;
  }

  public setTwoNumberDecimal(evt: Event) {
    const input = evt.target as HTMLInputElement;
    const regex = new RegExp(/^\d*(\.\d{0,2})?$/, 'g');

    let newVal = input.value;
    if(!regex.test(newVal)){
      let correctVal = {}
      correctVal[input.name] = input.value.slice(0, -1);

      this.form.patchValue(correctVal);
    }
  }

  public openPageClass(el: { classId: string, date: number }) {
    if (!el.classId) return;
    const date = new Date(el.date);
    const d = `0${date.getUTCDate()}`.slice(-2);
    const m = `0${date.getUTCMonth()}`.slice(-2);
    const url = `${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/clubschedule/${d}.${m}.${date.getUTCFullYear()}/${el.classId}`;
    window.open(url);
  }
}
