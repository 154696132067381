import { environment } from 'environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { Connection, Proxy, hubConnection } from 'signalr-no-jquery';

@Injectable({
  providedIn: 'root'
})
export class QueueHubService {
  private connection: Connection;
  private proxy: Proxy;
  public connectionExists: Boolean;
  public queueUpdateEvent = new EventEmitter<any>();

  constructor(
  ) {
    console.log('QueueHub Started...');
    this.connectionExists = false;
    this.connection = hubConnection(environment.api + '/signalr/', { useDefaultPath: false, logging: true });
    this.proxy = this.connection.createHubProxy('electronicQueueHub');
    this.registerOnServerEvents();
  }

  public start(clubId: string) {
    this.startConnection(clubId).then();
  }

  private async startConnection(clubId: string): Promise<void> {
    if (this.connectionExists) {
      this.connection.stop();
    }
    try {
      await this.connection.start({ transport: 'serverSentEvents' });
      await this.proxy.invoke('subscribeElectronicQueueHub', clubId);
      this.connectionExists = true;
    } catch (error) {
      console.error('*** Could not connect ' + error);
    }
  }

  private registerOnServerEvents(): void {
    this.proxy.off('ElectronicQueueUpdateEvent', (res) => this.onUpdateEvent(res));
    this.proxy.on('ElectronicQueueUpdateEvent', (res) => this.onUpdateEvent(res));
  }

  private onUpdateEvent(res) {
    this.queueUpdateEvent.emit(JSON.parse(res))
  };
}
