<div class="cpicker">
  <div class="items">
    <div class="item" *ngFor="let color of colors" [ngClass]="{'active' : !isCustomColor && (color[0] == data[0])}" (click)="writeValue(color[0])"
      [style.borderColor]="color[1]">
      <div class="mark" [style.backgroundColor]="color[0]"></div>
    </div>
  </div>

  <div class="customcolor" [ngClass]="{'active' : isCustomColor}">
    <div class="mark" [style.backgroundColor]="data[0]" *ngIf="data && data[0] && isCustomColor"></div>
    <input type="text" class="input__text" [formControl]="customControlInput" [style.borderColor]="data[1] && isCustomColor ? data[1] : undefined">
  </div>
</div>