import { Component, Input, OnInit, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';

@Component({
  selector: 'app-select-edit',
  templateUrl: './select-edit.component.html',
  styleUrls: ['./select-edit.component.scss']
})
export class SelectEditComponent extends ToggleEditComponent<string> implements OnInit, OnChanges {
  @Input() selectData: Array<{ [key: string]: any, id: string }>;
  public displayData;

  constructor(
    injector: Injector
  ) {
    super(injector)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectData']) {
      this.displayData = this.getItemById(this.tempData);
    }
  }

  onClickOutside(e) {
    if (!this.isReadonly && this.enableEdit && !e.isInside) {
      this.onSave();
    }
  }

  onSave() {
    this.displayData = this.getItemById(this.tempData);
    this.dataChange.emit(this.tempData);
    this.onFinishEdit.emit();
    this.enableEdit = false;
  }

  onCancel() {
    super.onCancel();
    this.displayData = this.getItemById(this.tempData);
  }

  getItemById(id: string) {
    return id ? this.selectData.find(item => item.id === id) || null : null;
  }
}
