import { Route } from '@angular/router';
import { ClubProfileComponent } from 'app/components/clubprofile/clubprofile.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const clubprofileRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubsportpriorityprofile',
  component: ClubProfileComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Профиль Sport Priority' }
};
