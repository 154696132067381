import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';

import { matchingPasswordsValidator } from 'app/common/validators/matching-password.validator';
import { IOnValueChanged, onValueChanged, OnValueChangeBase } from 'app/common/validators/on-value-changed.class';
import { AuthService } from 'app/common/services/auth.service';
import { isClubRole } from 'app/common/models/user-info.model';
import { PersistenceService } from 'app/common/services/persistence.service';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { debounceTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-reset',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent extends OnValueChangeBase implements OnInit, IOnValueChanged {
  private params;

  fieldMessages = {
    password: {
      required: 'Это поле обязательно',
      minlength: 'Пароль должен быть минимум 3 символа'
    },
    passwordConfirm: {
      required: 'Это поле обязательно',
      minlength: 'Пароль должен быть минимум 3 символа'
    },
  };

  formMessages = {
    passwordMatch: 'Пароли должны совпадать'
  };

  constructor(
    injector: Injector,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private apiV2Service: ApiV2Service,
    private router: Router,
    private persistenceService: PersistenceService
  ) {
    super(injector);

    this.activatedRoute.queryParams
      .subscribe(params => this.params = params);
  }

  // TODO: это то же самое, что и в login. Нужно вынести
  public async redirectAfterLogin() {
    if (this.authService.isLoggedIn) {
      const userInfo = await this.authService.getUserInfo();

      if (isClubRole(userInfo)) {
        if (!this.persistenceService.checkSheduledRedirect()) {
          const ctx = await this.apiV2Service.context.get();
          const firstClub = ctx.clubs.find(x => x.cityId !== '00000000000000000000000000000000');
          if (firstClub) {
            this.router.navigate([`/home`]);
          } else {
            this.authService.logout();
          }
        }
      } else {
        if (!this.persistenceService.checkSheduledRedirect()) {
          this.router.navigate([`/`]);
        }
      }
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'password': ['', [Validators.required, Validators.minLength(3)]],
      'passwordConfirm': ['', [Validators.required, Validators.minLength(3)]],
    }, { validator: matchingPasswordsValidator('password', 'passwordConfirm') });

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }

  reset() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    this.authService.resetPassword(this.form.value.password, this.params.t, this.params.uid)
      .then(() => this.redirectAfterLogin());
  }

  register() {
    this.authService.completeRegister(this.params.t, this.params.uid)
      .then(() => this.redirectAfterLogin());
  }
}
