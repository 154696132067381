import { OnInit, Injector, OnDestroy, HostListener, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, startWith, filter, take, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { ITableListService } from 'app/common/table-list/table-list.service';
import { AuthService } from './services/auth.service';
import { ContextService } from 'app/common/services/context.service';
import { PersistenceService } from 'app/common/services/persistence.service';
import { AlertsService } from './components/alerts/services/alerts.service';
import { IUserInfo, isAdmin } from 'app/common/models/user-info.model';
import { onValueChanged, IOnValueChanged, OnValueChangeBase } from 'app/common/validators/on-value-changed.class';
import { IRoutingParams } from 'app/common/models/context.model';
import { Obj2StringPipe } from 'app/common/pipes/object-name.pipe';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { EntityStatus } from './models/entity-status.model';
import { IWidgetModel } from './models/widgets.model';
import { PermissionService } from './services/permission.service';
import {Location} from '@angular/common';
import {RoutingHistoryService} from './services/routing-history.service';

@Directive()
export abstract class EditItemComponent<T extends { id: string, entityStatus?: string, color?: string }> extends OnValueChangeBase implements OnInit, IOnValueChanged, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject();

  public Model: T = null;
  public isSubmited = false;

  protected permissionService: PermissionService;
  public alertsService: AlertsService;
  public authService: AuthService;
  public contextService: ContextService;
  protected persistenceService: PersistenceService;
  protected hotkeysService: HotkeysService;

  public route: ActivatedRoute;
  public router: Router;
  public parentId: string;

  public locationService: Location;
  public routingHistoryService: RoutingHistoryService;

  public isBusy: boolean;
  public isEditAvailable: boolean;
  public isRemoveAvailable: boolean;
  public isAdmin: boolean;

  public userInfo: IUserInfo = null;
  public timeZone: number;
  protected currentTheme: IWidgetModel;
  public routingParams: IRoutingParams = null;

  protected hkClose: Hotkey | Hotkey[];
  protected hkSave: Hotkey | Hotkey[];
  protected hkSaveAdd: Hotkey | Hotkey[];
  protected hkDelete: Hotkey | Hotkey[];
  protected hkDelete2: Hotkey | Hotkey[];

  protected photoRedirect: boolean = false;

  protected refreshModelOnRoutingParamsChange: boolean = false;

  constructor(
    injector: Injector,
    protected service: ITableListService
  ) {
    super(injector);
    this.permissionService = injector.get(PermissionService);
    this.alertsService = injector.get(AlertsService);
    this.authService = injector.get(AuthService);
    this.contextService = injector.get(ContextService);
    this.persistenceService = injector.get(PersistenceService);
    this.hotkeysService = injector.get(HotkeysService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.locationService = injector.get(Location);
    this.routingHistoryService = injector.get(RoutingHistoryService);

    this.hkClose = this.hotkeysService.add(new Hotkey('esc', this.hkClosePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
    this.hkSave = this.hotkeysService.add(new Hotkey(['ctrl+s', 'ctrl+return'], this.hkSavePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
    this.hkSaveAdd = this.hotkeysService.add(new Hotkey(['ctrl+space', 'ctrl+shift+return'], this.hkSaveAddPress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
    this.hkDelete = this.hotkeysService.add(new Hotkey(['shift+del'], this.hkDeletePress(this), []));
    this.hkDelete2 = this.hotkeysService.add(new Hotkey(['del', '-'], this.hkDeletePress(this), []));
  }

  public afterModelInit() { }
  public abstract buildForm();
  protected abstract modelTemplate(): Promise<T>;
  public afterFinishEdit() {
    this._onEditComplete();
  }

  // @HostListener('window:keydown', ['$event'])
  // onKeydownHandler(e) {
  //   if (this.hotkeysService.isPaused()) {
  //     return;
  //   }
  //
  //   if (e.keyCode === 27  /*esc*/) {
  //     this.close();
  //   }
  // }

  public isPublished() {
    return this.Model.entityStatus === EntityStatus.published;
  }

  public isDraft() {
    return this.Model.entityStatus === EntityStatus.draft;
  }

  public isDeleted() {
    return this.Model.entityStatus === EntityStatus.deleted;
  }

  public isNotSaved() {
    return this.Model.id === null;
  }

  public hkSaveAddPress(that: this) {
    return (event: KeyboardEvent) => {
      that.finishEdit(true, true);
      event.preventDefault();
      return true;
    };
  }

  public hkSavePress(that: this) {
    return (event: KeyboardEvent) => {
      that.finishEdit();
      event.preventDefault();
      return true;
    };
  }

  public hkDeletePress(that: any) {
    return (event: KeyboardEvent) => {
      if (that.Model && that.Model.id != null) {
        that.delete();
      }

      event.preventDefault();
      return true;
    };
  }

  public hkClosePress(that: any) {
    return (event: KeyboardEvent) => {
      event.stopPropagation();
      that.close();
      return true;
    };
  }

  public _close() {
    if (this.parentId) {
      this.router.navigate(['../'], { relativeTo: this.route, queryParams: { parentId: this.parentId } });
    } else {
      if (this.routingHistoryService.navigations > 1) {
        this.locationService.back();
      } else {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    }
  }

  public close(id?: string) {
    if (this.photoRedirect) {
      if (id) localStorage.setItem("idClientEdit", id);
      this.router.navigateByUrl(this.router.url.replace("create", 'image/00000000000000000000000000000000'));
    }
    if (id) {
      if (this.Model.color && new Obj2StringPipe().transform(this.Model).length > 0) {
        this.closeAndSave(id.replace(/-/g, ''));
      } else {
        this._close();
      }
    } else {
      if (!this.persistenceService.goBack()) {
        this._close();
      }
    }
  }

  public closeAndSave(id: string) {
    if (!this.persistenceService.goBack(this.Model, id)) {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  public finishEdit(isPublished: boolean = true, createNew?: boolean) {
    setTimeout(() => {
      if (this.form.invalid) {
        document.getElementById('app_container').scrollTop = 0;
        window.scrollTo(0, 0);
        this.isSubmited = true;
        return;
      }

      this.deleteSpaces()

      if (this.Model.entityStatus && !createNew) {
        this.Model.entityStatus = isPublished ? EntityStatus.published : EntityStatus.draft;
      }
      if (createNew) {
        this.persistenceService.scheduleCreation();
      }
      this.isSubmited = false;
      this.afterFinishEdit();
    });
  }

  public copyEdit() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    this.deleteSpaces()

    this.isSubmited = false;
    this.Model.id = null;


    this.service.create(this.Model).then((resp) => {
      this.persistenceService.scheduleRedirect(`../${resp.id}`);
      this.alertsService.alert.next({
        type: 'success',
        message: 'Выполнен переход к редактированию новой копии',
        header: 'Успех',
        position: 'top',
        timeout: 2000
      });
      this.close();
    });
  }

  public _onEditComplete() {
    if (this.Model.id == null) {
      this.service
        .create(this.Model)
        .then((resp) => {
          if (typeof resp === 'string') {
            this.close(resp);
          } else if (typeof resp === 'object' && typeof resp.id === 'string') {
            if (!this.Model.id && resp?.userId) {
              this.persistenceService.scheduleRedirect(`../${resp.id}`);
              this.close();
            } else {
              if (localStorage.getItem('groupId') != null) {
                this.router.navigate([`../../../${resp.id}`], { relativeTo: this.route });
              } else {
                this.close(resp.id);
              }
            }
          } else {
            this.close();
          }
        })
        .catch(() => { });
    } else {
      this.service
        .save(this.Model)
        .then(() => this.close())
        .catch(() => { });
    }
  }

  delete() {
    this.alertsService.showConfirm({
      header: 'Вы уверены?',
      message: 'Действительно удалить?',
      buttons: [{
        title: 'Да',
        callback: () => {
          this.service
            .delete(this.Model)
            .then(() => this.close())
            .catch(() => { });
        }
      }, {
        title: 'Отмена'
      }]
    });
  }

  private _flagFastSaveMedias: boolean = false;
  fastSaveMedias() {
    if (this.Model.id && this._flagFastSaveMedias) {
      setTimeout(() => {
        if (this.form.invalid) {
          this.isSubmited = true;
          return;
        }
        for (const i in this.form.value) {
          this.Model[i] = this.form.value[i];
        }
        this.isSubmited = false;
        this.service.create(this.Model).then((resp) => {
            console.log(resp)
          })
          .catch((e) => { console.error(e) });
      });
    } else {
      this._flagFastSaveMedias = true;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.hotkeysService.remove(this.hkClose);
    this.hotkeysService.remove(this.hkSave);
    this.hotkeysService.remove(this.hkSaveAdd);
    this.hotkeysService.remove(this.hkDelete);
    this.hotkeysService.remove(this.hkDelete2);
  }

  ngOnInit() {
    let routingParamsObs = this.contextService.routingParams.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(Boolean));
    if (!this.refreshModelOnRoutingParamsChange) {
      routingParamsObs = take(1)(routingParamsObs);
    }
    routingParamsObs
      .subscribe(params => {
        this.routingParams = params;
        this.contextService.currentUrl.pipe(
          distinctUntilChanged(),
          takeUntil(this.ngUnsubscribe),
          take(1))
          .subscribe(url => {
            this.parentId = this.route.snapshot.queryParamMap.get('parentId');

            const getModelPromise = url.some(x => x.path === 'create') ?
              this.modelTemplate() :
              this.service.getSingleItem(this.routingParams, this.parentId);


            getModelPromise
              .then(model => {
                this.Model = <T>model;
                this.currentTheme = model;
                this.afterModelInit();
                this.buildForm();
                this.form.valueChanges.pipe(
                  startWith(null),
                  debounceTime(100))
                  .subscribe(() => onValueChanged(this));
              })
          });
      })

    this.authService.getUserInfo().then(res => {
      this.userInfo = res;
      this.timeZone = this.userInfo.timeZone;
      this.isAdmin = isAdmin(this.userInfo);
      this.isEditAvailable = this.permissionService.isAvailable(this, 'isEditAvailable', this.userInfo?.role);
      this.isRemoveAvailable = this.permissionService.isAvailable(this, 'isRemoveAvailable', this.userInfo?.role);
    });
  }
  deleteSpaces() {
    for (const i in this.form.value) {
      if (typeof(this.form.value[i]) == 'string') {
        this.form.value[i] = this.form.value[i].trim()
      }
      this.Model[i] = this.form.value[i];
    }
  }
}
