<header class="content__header">
    <div class="content__header-title">
        <span class="content__header-title-value content__header-title-value_default">
            {{title}}
        </span>
    </div>
</header>

<div class="content__body">
    <ng-container *ngIf="Model!=null">
        <div class="users-container">
            <div class="tableSideLinkWrap">
                <div class="clue-text">
                    <ng-container *ngIf="isEditAvailable">
                    <a class="box-link" (mousedown)="onChatClick(null)" (click)="onChatClick(null)">Добавить
                      <span class="key">
                      <span app-key-help [key]="'plus'"></span>
                    </span>
                    </a>
                  </ng-container>
            </div>
            </div>
            <div class="table-responsive">
                <table class="table rowHover">
                    <thead>
                        <td class="table-cell table-title-cell">Название</td>
                        <td class="table-cell table-title-cell" style="width:200px">Обновлено</td>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of Model; index as i" [class.table-row-notfinalized]="item.isFinalized == false" (click)="onChatClick(item, 'open')">
                            <td class="table-cell">
                              <span class="table-cell__mobile-title">Название</span>
                              <div class="chats-name">
                                <span class="img" *ngIf="item.groupType != 'notGroup'">
                                  <span class="sp" [class.sp-client]="item.groupType == 'group'" [class.sp-chats]="item.groupType == 'mailing'"></span>
                                </span>
                                {{item.title}} ({{item.messagesCount}})
                                <span>
                                    <a class="box-link" *ngIf="item.groupType != 'notGroup'" (click)="onChatClick(item, 'edit')">Редактировать</a>
                                    <a class="box-link" *ngIf="item.groupType == 'mailing'" (click)="onChatClick(item, 'read')">Написать всем</a>
                                </span>
                              </div>
                            </td>
                            <td class="table-cell">
                              <span class="table-cell__mobile-title">Обновлено</span>
                              <span>{{item.updatedAt | time2words}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="load-nextpage" *ngIf="Model && Model.length && Model.length < totalItemCount">
              <button class="btn btn-outline" (click)="showNextPage()">
                показать еще
              </button>
            </div>
        </div>
    </ng-container>
</div>
