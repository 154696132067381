import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';

import * as _ from 'lodash';

import { IClassItemModel } from 'app/common/models/class-item.model';
import { ContextService } from 'app/common/services/context.service';
import { Obj2StringPipe } from 'app/common/pipes/object-name.pipe';

/**
 * Временное хранение модели занятия и перенаправление при
 * создании новых сущностей из редактора занятия
 */
@Injectable()
export class PersistenceService {

    public pendingUrl: UrlSegment[];
    public currentEntityType: string;

    private redirectUrl: string;
    private model: IClassItemModel;

    private scheduledRedirectUrl: string;
    private isCreationPending = false;
    // TODO: возможно, тут будет нужна более общая модель


    /**
     * Устанавливает текущий route как адрес, куда нужно вернуться при вызове checkSheduledRedirect
     * @param urlDiff
     */
    public scheduleRedirect(urlDiff?: string) {
        const urlParts = this.pendingUrl.map(x => x.toString());

        if (urlDiff) {
            urlDiff.split('/').forEach(c => {
                if (c === '..') {
                    urlParts.pop();
                } else {
                    urlParts.push(c);
                }
            });
        }

        this.scheduledRedirectUrl = urlParts.reduce((x, y) => x + `/${y}`, '');
    }


    /**
     * Перенаправляет на последний сохраненный адрес, если таковой был установлен через scheduleRedirect, и очищает сохраненный адрес.
     *
     * Возвращает true, если произошел переход, иначе false
     */
    public checkSheduledRedirect() {
        if (this.scheduledRedirectUrl) {
            setTimeout(() => {
                this.router.navigate([this.scheduledRedirectUrl]);
                this.scheduledRedirectUrl = null;
            }, 100);
            return true;
        }
        return false;
    }

    /**
     * Запоминает текущую модель занятия и перенаправляет на route создания сущности
     * @param type тип создаваемой сущности (clubrooms, clubcoaches, clubcourses )
     * @param model текущая модель занятия
     */
    public addEntity(type: string, model: IClassItemModel) {
        this.currentEntityType = type;
        this.model = model;
        const url = this.contextService.makeContextUrl(`${type}/create`);
        this.redirectUrl = this.pendingUrl.reduce((x, y) => x + `/${y}`, '');
        this.router.navigate([url]);
    }

    /**
     * Устанавливает флаг необходимости создания новой сущности
     */
    public scheduleCreation() {
        this.isCreationPending = true;
    }

    /**
     * Возвращает флаг необходимости создания новой сущности и сбрасывает его
     */
    public checkCreationStatus() {
        if (this.isCreationPending) {
            this.isCreationPending = false;
            return true;
        }
        return false;
    }

    /**
     * Подставляет данные новой созданной сущности в запомненную модель занятия и перенаправляет на её редактирование
     * @param model модель созданной сущности
     * @param id идентификатор созданной сущности
     */
    public goBack(model?: any, id?: string) {
        if (this.redirectUrl) {
            if (model && id) {
                const pipe = new Obj2StringPipe();
                const mdl = _.cloneDeep(model);
                mdl.id = id;
                mdl.name = pipe.transform(mdl);
                switch (this.currentEntityType) {
                    case 'clubrooms':
                        this.model.room = mdl;
                        break;
                    case 'clubcoaches':
                        this.model.coach = mdl;
                        break;
                    case 'clubcourses':
                        this.model.course = mdl;
                        break;
                    default: break;
                }
            }

            setTimeout(() => {
                this.router.navigate([this.redirectUrl]);
                this.redirectUrl = null;
            }, 100);

            return true;
        } else {
            return false;
        };
    }


    /**
     * Возвращает запомненную модель занятия и забывает её
     */
    public getModel() {
        const res = _.cloneDeep(this.model);
        this.model = null;
        return res;
    }

    constructor(
        public router: Router,
        public contextService: ContextService
    ) {
        this.contextService.pendingUrl.subscribe(url => this.pendingUrl = url);
    }
}
