import { Component, Injector } from '@angular/core';
import { EditItemComponent } from 'app/common/edit-item.component';
import { ICityModel } from 'app/common/models/city-model';
import { CityService } from 'app/common/services/city.service';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-city-edit',
  templateUrl: './city-edit.component.html',
  styleUrls: ['./city-edit.component.scss']
})
export class CityEditComponent extends EditItemComponent<ICityModel> {
  fieldMessages = {
    name: {
      NotEmpty: 'Это поле обязательно',
    },
    urlName: {
      NotEmpty: 'Это поле обязательно',
    },
    timeZone: {
      required: 'Это поле обязательно',
    }
  };

  constructor(
    injector: Injector,
    protected service: CityService
  ) {
    super(injector, service);
  }
    hkSaveAddPress(this) {
      return this;
    }
    modelTemplate(): Promise<ICityModel> {
    const res: ICityModel = {
      entityStatus: EntityStatus.draft,
      id: null,
      name: null,
      urlName: null,
      timeZone: 0,
      geoLat: 0,
      geoLon: 0,
      isAttract: false,
    };
    return Promise.resolve(res);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      urlName: [this.Model.urlName, [NotEmpty()]],
      timeZone: [this.Model.timeZone, [Validators.required]],
      isAttract: [this.Model.isAttract],
    });
  };

}
