import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PersistenceService } from 'app/common/services/persistence.service';
import { PermissionService } from './permission.service';
import { AlertsService } from '../components/alerts/services/alerts.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private permissionService: PermissionService,
    private alertService: AlertsService,
    private persistenceService: PersistenceService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.checkLogin()
      && this.checkPermission(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route);
  }

  checkLogin() {
    if (this.authService.isLoggedIn) {
      return true;
    };
    this.persistenceService.scheduleRedirect();
    this.router.navigate(['/login']);
    return false;
  }

  checkPermission(route: ActivatedRouteSnapshot) {
    const permit = this.permissionService.isRouteAvailable(route.component);
    if (permit)
      return true;
    else {
      this.alertService.alert.next({
        type: 'danger',
        message: 'Отсутствуют необходимые права',
        header: 'Нет прав',
        position: 'top',
        timeout: 2000
      });
      return false;
    }
  }
}
