import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as helpers from 'app/common/helpers.functions';

@Injectable()
export class WorktimeService {
  private dayPairs = [];
  private daysShort = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  private daysShortRu = ['Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.', 'Вс.'];
  private daysLong = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  private _hours = ['all', 'none'];

  constructor() {
    for (const day in this.daysShort) {
      this.dayPairs.push({
        short: this.daysShort[day],
        shortRu: this.daysShortRu[day],
        long: this.daysLong[day]
      });
    }

    for (let h = 0; h < 24; h++) {
      this._hours.push(`${helpers.format2digits(h)}:00`);
      this._hours.push(`${helpers.format2digits(h)}:30`);
    }
  }

  public days() {
    return this.dayPairs;
  }

  public hours() {
    return this._hours;
  }

  public modelTransformIn(data) {
    const result = _.cloneDeep(data);

    for (const day of this.dayPairs) {
      const key = day.short;
      if (result[key].hourFrom === '00:00'
        && result[key].hourTo === '23:59') {
        result[key].hourFrom = 'all';
        result[key].hourTo = '00:00';
      } else
        if (result[key].hourFrom == null
          && result[key].hourTo == null) {
          result[key].hourFrom = 'none';
          result[key].hourTo = '00:00';
        }
    }
    return result;
  }

  public modelTransformOut(data) {
    const result = _.cloneDeep(data);

    for (const day of this.dayPairs) {
      const key = day.short;
      if (result[key].hourFrom === 'all') {
        result[key].hourFrom = '00:00';
        result[key].hourTo = '23:59';
      } else
        if (result[key].hourFrom === 'none') {
          result[key].hourFrom = null;
          result[key].hourTo = null;
        }
    }
    return result;
  }
}
