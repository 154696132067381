import { ScheduleService } from 'app/components/schedule/services/schedule.service';
import { ClientsService } from './../../clients/services/clients.service';
import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { ApiV2Service } from '../../../common/services/api-v2.service';
import { AlertsService } from '../../../common/components/alerts/services/alerts.service';
import { ContextService } from '../../../common/services/context.service';
import { Subject } from 'rxjs';
import { takeUntil, take, debounceTime } from 'rxjs/operators';
import { IRoutingParams } from '../../../common/models/context.model';
import { IClassRequest } from '../../../common/models/class-request.model';
import * as moment from 'moment';
import { DashboardHub } from 'app/common/services/dashboard.hub';
import {LocalPreloader, PreloaderService} from '../../../common/services/preloader.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-class-requests',
  templateUrl: './class-requests.component.html',
  styleUrls: ['./class-requests.component.scss']
})
export class ClassRequestsComponent implements OnInit, OnDestroy {

  protected ngUnsubscribe: Subject<void> = new Subject();
  private routingParams: IRoutingParams;
  public requests: IClassRequest[] = null;
  public clientsRoutePrefix: string;
  public hasRequestWithoutClient = false;
  public hasRequestOverCapacity = false;
  public withoutNotification: boolean;
  private buttonClick: Subject<void> = new Subject();
  public guidEmpty = '00000000000000000000000000000000';
  public isRefresh: boolean = true;
  @ViewChild('requestsRef') public requestsRef: ElementRef;

  public subsControls = this.fb.group({});

  constructor(
    private apiV2Service: ApiV2Service,
    private alertsService: AlertsService,
    private contextService: ContextService,
    private dashboardHub: DashboardHub,
    public clientService: ClientsService,
    private preloaderService: PreloaderService,
    private scheduleService: ScheduleService,
    private fb: FormBuilder
  ) { }

  async getRequests() {
    this.dashboardHub.createNewRequest
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resp) =>
        setTimeout(() => this.signalRUpdate(resp), 1000));
    this.dashboardHub.connectToDashboard(this.routingParams.clubId);
  }

  async confirmClick(req: IClassRequestPending) {
    if ((req.capacityInfo && (req.capacityInfo.bookedCount + (req.classVisitId ? 0 : 1)) >= req.capacityInfo.capacity)) {
      if (await this.confirmOverCapacity()) {
        this.confirm(req);
      }
    } else {
      this.confirm(req);
    }
  }

  private confirmOverCapacity(): Promise<boolean> {
    return new Promise<boolean>(resolve =>
      this.alertsService.showConfirm({
        header: 'Уже записано максимальное число занимающихся',
        message: 'Все равно записать?',
        callbackEsc: () => resolve(false),
        buttons: [{
          title: 'Да, записать',
          callback: () => resolve(true),
        }, {
          title: 'Отменить',
          callback: () => resolve(false),
        }]
      })
    );
  }

  private async confirm(req: IClassRequestPending) {
    req.pending = true;
    const userId = (req.userInfo && req.userInfo.userId) ? req.userInfo.userId : null;
    const subscriptionValue = this.subsControls.get(`req-${req.id}`).value;
    const subscriptionId = subscriptionValue == this.guidEmpty ? null : subscriptionValue;

    if (req.classId && userId && req.classVisitId) {
      await this.apiV2Service.classRequest.confirm(subscriptionId || null, req.id, userId, this.withoutNotification)
        .catch(() => req.pending = false);
      this.apiV2Service.classRequest.delete(req.id, true, false)
        .catch(() => req.pending = false);
      return;
    }

    await this.apiV2Service.classRequest.confirm(subscriptionId || null, req.id, userId, this.withoutNotification)
      .catch(() => req.pending = false);

    this.apiV2Service.classRequest.delete(req.id, true, false)
      .catch(() => req.pending = false);
  }

  async deleteClick(req: IClassRequestPending) {
    // Устанавливаем статус "Пропуск отмечен клубом"
    if (req.classId && req.classVisitId) {
      const model = {
        id: req.classVisitId,
        classId: req.classId
      }
      this.scheduleService.cancelBooking(model, true, false)
        .then();
    }

    req.pending = true;
    this.apiV2Service.classRequest.delete(req.id, this.withoutNotification, false)
      .catch(() => req.pending = false);
  }

  private async update(handlePreloader: boolean = false, data: any = null) {
    // if (data) {
    //   this.requests = this.requests.filter(x => x.id !== data.id);
    //   return;
    // }

    const requests = await this.apiV2Service.classRequest.getByClub(this.routingParams.clubId, handlePreloader);
    requests.forEach(x =>
      x.isOverCapacity = x.classVisitId ?
        x.capacityInfo && x.capacityInfo.capacity < x.capacityInfo.bookedCount :
        x.capacityInfo && x.capacityInfo.capacity < x.capacityInfo.bookedCount + x.capacityInfo.requestCount);

    this.requests = requests;

    this.subsControlsGenerate(this.requests);

    this.hasRequestWithoutClient = requests.some(x => !x.userInfo);
    this.hasRequestOverCapacity = requests.some(x => x.isOverCapacity);

    this.getSubscriptions();
  }


  subsControlsGenerate(requests: IClassRequest[]): void {
    requests.forEach(req => {
      this.subsControls.addControl(`req-${req.id}`, this.fb.control(''));
    });
  }

  ngOnInit() {
    this.contextService.routingParams.pipe(
      takeUntil(this.ngUnsubscribe),
      take(1))
      .subscribe(params => {
        this.routingParams = params;
        this.clientsRoutePrefix = this.contextService.makeContextUrl('clubclients');
        this.update(false);
        this.getRequests();
      });
    this.buttonClick.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(5000)
    )
      .subscribe(() => this.update(false));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.dashboardHub.disconnect();
  }

  getClassLink(request: IClassRequest): string {
    const startDateTime = moment.utc(request.startDateTime);
    const mondayDateTime = moment.utc(request.startDateTime).startOf('isoWeek');
    let path = '';
    const isVirtual = !request.classId || request.classId === '00000000000000000000000000000000';
    if (isVirtual) {
      path = 'virtual/' + request.prototypeId + '/' + startDateTime.format('DD.MM.YYYY');
    } else {
      path = request.classId;
    }
    return this.contextService.makeContextUrl('clubschedule') + '/' + mondayDateTime.format('DD.MM.YYYY') + '/' + path;
  }

  getCourseName(request: IClassRequest): string {
    if (request.classId === null && request.prototypeId === null) {
      return '';
    }
    if (request.courseInfo === null) {
      return 'Не указано';
    }
    return request.courseInfo.name;
  }

  getSubscriptionId(req: IClassRequest) {
    return this.subsControls.get(`req-${req.id}`).value;
  }

  getSubscriptions() {
    for (let req of this.requests) {
      this.subsControls.get(`req-${req.id}`).setValue(this.guidEmpty);
      // Если в заявке есть ID абонемента, то выбираем его и не ищем последний купленный.
      if (req.clubClientSubscriptionId) {
        let reqSubWithId = req.subscriptions.find(s => s.id == (req.clubClientSubscriptionId));
        reqSubWithId.isSelected = true;
        this.subsControls.get(`req-${req.id}`).setValue(reqSubWithId.id);

        continue;
      }

      // Если в заявке нет ID абонемента и она пришла с виджета,
      // то нет необходимости выбирать последний купленный абонемент.
      if (req.fromType && req.fromType == 'wg') {
        continue;
      }

      if (req.subscriptions.length) {
        let lastSub = req.subscriptions.reduce((last, s) => s.purchasedAt > last.purchasedAt ? s : last);
        lastSub.isSelected = true;
        this.subsControls.get(`req-${req.id}`).setValue(lastSub.id);
      }
    }
  }

  selectSubscriptionForClient(classVisitId: string, subscriptionId: string, reqId: string) {
    if (!classVisitId) return;
    const req = this.requests.find(m => m.id == reqId);
    const oldSubcsription = req.subscriptions.find(m => m.isSelected);
    this.scheduleService
      .changeSubscriptionPlan({
        classVisitId: classVisitId,
        clientSubscriptionId: subscriptionId
      })
      .catch(() => {
        req.subscriptions.forEach(m => {
          if (m.id == oldSubcsription.id) m.isSelected = true;
          else m.isSelected = false;
        });

        this.subsControls.get(`req-${req.id}`).setValue(oldSubcsription.id);
      });
  }

  infinity(num: number) {
    return num > 1000 ? "∞" : num;
  }

  public SubscriptionExtraInfo(subscription: any): string {
    // moment.locale('ru_RU');
    // const date = moment.utc(subscription.toDate).format('L');
    return (!subscription.toDate ? '' : ' до ' + subscription.toDate);
  }

  private tmUpdate;
  private signalRUpdate(data: any) {
    if (this.tmUpdate)
      clearTimeout(this.tmUpdate);
    this.tmUpdate = setTimeout(() => this.update(false, data), 0);
  }
}

interface IClassRequestPending extends IClassRequest {
  pending: boolean;
}
