import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

export interface IOnValueChanged {
    form: FormGroup;
    isSubmited: boolean;
    fieldErrors: {};
    formErrors: {};
    fieldMessages: {};
    formMessages: {};
}

export function onValueChanged(that: IOnValueChanged) {
    if (!that.form) { return; }
    const form = that.form;
    for (const field in that.form.controls) {
        const control = form.get(field);
        that.fieldErrors[field] = '';
        const fieldMessages = that.fieldMessages[field];

        if (control && !control.valid) {
            for (const key in control.errors) {
                that.fieldErrors[field] = ((fieldMessages && fieldMessages[key]) || `неверно заполнено поле ${field}: требуется ${key}`) + ' ';
            }
        }
    }

    const formMessages = that.formMessages;
    // TODO здесь нужно перебирать валидаторы, а не сообщения,
    // но  непонятно, как их получить из formGroup
    for (const key in that.formErrors) {
        that.formErrors[key] = '';
    }

    if (form && !form.valid && formMessages) {
        for (const key in form.errors) {
            const message = formMessages[key] || `неверно заполнена форма: требуется ${key}`;
            that.formErrors[key] += message + ' ';
        }
    }
}

export abstract class OnValueChangeBase implements IOnValueChanged {
    public form: FormGroup = null;
    public formBuilder: FormBuilder = null;
    public isSubmited = false;
    public fieldMessages: { [key: string]: { [key: string]: string } | string } = {};
    public formMessages: { [key: string]: { [key: string]: string } | string } = {};
    public fieldErrors: { [key: string]: string } = {};
    public formErrors: { [key: string]: string } = {};

    constructor(
        injector: Injector
    ) {
        this.formBuilder = injector.get(FormBuilder);
    }
}
