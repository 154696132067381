import { ITrainingCategory } from 'app/common/models/training-category.model';
import { IWorkingHours } from 'app/common/models/working-hours.model';

import { ICoachModel } from 'app/common/models/coach.model';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { IMedias } from 'app/common/models/medias.model';


export interface ICourseModel {
    name: string;
    group: string;
    sortOrder: string;
    description: string;
    shortDescription: string;
    isPopular: boolean;
    color: string;
    entityStatus: string;
    imageUrl: string;
    id: string;
    trainingCategories: Array<ITrainingCategory>;
    workingTime: IWorkingHours;
    isVoluntaryBookingAllowed: boolean;
    clubConfirmationMethod: ClubConfirmationMethod;
    isCoachForClassRequired: boolean;
    coaches: Array<ICoachModel>;
    rooms: Array<IClubroomModel>;
    medias: IMedias;
    subscriptionPlans?: any;
}

export enum ClubConfirmationMethod {
    NotRequired = 'notRequired',
    Subscription = 'subscription',
    Manual = 'manual'
}
