import { Route } from '@angular/router';

import { AuthGuard } from 'app/common/services/auth-guard.service';
import { CoursesComponent } from 'app/components/courses/courses.component';
import { CourseEditComponent } from 'app/components/courses/coourse-edit/course-edit.component';


export const coursesRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubcourses',
  component: CoursesComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Вид занятия' }
};


export const courseRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubcourses/:id',
  component: CourseEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование вида занятия' }
};
