import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker-rates',
  templateUrl: './date-picker-rates.component.html',
  styleUrls: ['./date-picker-rates.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerRatesComponent),
    multi: true
  }]
})

export class DatePickerRatesComponent implements ControlValueAccessor {

  minDate = new Date(1900, 0, 1);
  maxDate = new Date(2100,0,1);

  @Input() _dateValue: string = null;

  get dateValue() {
    return moment(this._dateValue, this.format);
  }

  set dateValue(val) {
    this._dateValue = moment(val).format(this.format);
    this.propagateChange(this._dateValue);
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.dateValue = moment(event.value, this.format);
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.dateValue = moment(value, this.format);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  @Input() public placeholder: string = null;
  @Input() private format = 'DD.MM.YYYY';

}
