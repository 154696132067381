import { EntityStatus } from './entity-status.model';
import { IMedias } from './medias.model';

export interface IClubEventModel {
  id: string;
  entityStatus: string;
  clubId: string;
  parentId: string;
  name: string;
  timeFrom: number;
  timeTo: number;
  ticketCounts?: { [index: string]: number };
  locationCityId: string;
  locationText: string;
  locationLat: number;
  locationLon: number;
  description: number;
  eventType: ClubEventType;
  specialConditions: string;
  entryFee: number;
  customEntryFeeLabel: string;
  customEntryFeeTitle: string;
  ticketPrice: number;
  customTicketPriceLabel: string;
  customTicketPriceTitle: string;
  medias: IMedias;
  isGlobal: boolean;
  shortDescription: string;
  eventReport: string;
  eventReportTitle: string;
  contacts: IClubEventContactsData;
  ticketVariants: { id: string, entityStatus: EntityStatus, price: number, title: string, label: string }[];
  saleTimeFrom?: number;
  saleTimeTo?: number;
  requiredTicketFields: string[];
}

export interface IClubEventContactsData {
  chat: boolean;
  phone?: string;
  whatsapp?: string;
  telegram?: string;
}

export enum ClubEventType {
  sport = 'sport',
  culture = 'culture',
  education = 'education'
}

export interface IClubEventBooking {
  name: string;            /// Имя, фамилия участника или зрителя
  type: string;            /// Тип бронирования
  phone: string;           /// Телефон участника или зрителя
  email: string;           /// Email участника или зрителя
  bookingDate: number;     /// Дата бронирования
  amountPaid: number;      /// Общая сумма билетов
  tickets: { id: string, participants: any[], count: number }[];
}

