<div class="loginWrap" [class.invisible]="isSubmit">
  <div class="loginForm">
    <form>
      <div class="box-title">
        Авторизация в CRM Sport&nbsp;Priority
      </div>
      <div class="info-text" *ngIf="_mode == 'pswd'">Введите e-mail и пароль</div>
      <div class="info-text" *ngIf="_mode == 'code' && !_isAuthStep">Укажите ваш e-mail, чтобы получить код для входа</div>
      <div class="info-text" *ngIf="_mode == 'code' && _isAuthStep">На адрес <i>{{user.name}}</i> отправлен временный код, используйте его для входа</div>
      <!-- Поле ввода E-mail -->
      <div class="form-group" *ngIf="_mode == 'pswd' || _mode == 'code' && !_isAuthStep">
        <input class="form-control" name="name" [(ngModel)]="user.name" #name="ngModel" required
          placeholder="E-mail" class="input__text input__text--long" />
        <label *ngIf="(name.invalid && name.touched) || (attemptToSend && name.pristine)" class="field-validation-error">Введите E-mail</label>
      </div>
      <!-- Поле ввода пароля -->
      <div class="form-group" *ngIf="_mode == 'pswd'">
        <input type="password" class="form-control" name="pass" [(ngModel)]="user.pass" #pass="ngModel" required
          placeholder="Пароль" class="input__text input__text--long" />
        <label *ngIf="(pass.invalid && pass.touched) || (attemptToSend && pass.pristine)" class="field-validation-error">Введите пароль</label>
      </div>
      <div class="form-group" *ngIf="_mode == 'pswd'">
        <button class="btn btn-primary" (click)="login()" [disabled]="attemptToSend && name.invalid || attemptToSend && pass.invalid">
          <span class="btn-primary__desc">Войти</span>
        </button>
      </div>
      <!-- Поле ввода временного кода -->
      <div class="form-group" *ngIf="_mode == 'code' && _isAuthStep">
        <input type="password" class="form-control" name="code" [(ngModel)]="user.code" #code="ngModel" required
          placeholder="Код из письма" class="input__text input__text--long" />
        <label *ngIf="(code.invalid && code.touched) || (attemptToSend && code.pristine)" class="field-validation-error">Введите код из пиьсма</label>
      </div>
      <div class="form-group" *ngIf="_mode == 'code' && !_isAuthStep">
        <button class="btn btn-primary" (click)="_beginEmailRegistration()" [disabled]="attemptToSend && name.invalid">
          <span class="btn-primary__desc">Получить код</span>
        </button>
      </div>
      <div class="form-group" *ngIf="_mode == 'code' && _isAuthStep">
        <button class="btn btn-primary" (click)="_completePhoneRegistration()" [disabled]="attemptToSend && name.invalid || attemptToSend && code.invalid">
          <span class="btn-primary__desc">Войти</span>
        </button>
      </div>

      <div class="form-group restore-link">
        <a class="form-control" (click)="setMode('pswd')" *ngIf="_mode == 'code'">
          Войти с паролем
        </a>
        <a class="form-control" (click)="setMode('code')" *ngIf="_mode == 'pswd'">
          Войти с временным кодом
        </a>
      </div>
      <div class="form-group restore-link">
        <a class="form-control" (click)="restore()">
          Я забыл пароль
        </a>
      </div>
    </form>
  </div>
  <a class="restore-link connect-club" href="https://sportpriority.com/b2b/callback">Подключить свой клуб</a>
</div>
