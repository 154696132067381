<div class="" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit" *ngIf="!isReadonly">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>

  <div class="row__field ch" [hidden]="enableEdit" (click)="onBeginEdit()">
    <div class="address contact__label">
      <span class="sp sp-pin"></span>
      <span class="contact__desc" *ngIf="tempData.address !== '' && tempData.address !== null; else noAddress">{{tempData.address}}</span>
      <ng-template #noAddress>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="phones contact__label">
      <span class="sp sp-phone"></span>
      <span class="contact__desc" *ngIf="tempData.phones.length > 0 && tempData.phones[0].length > 0; else noPhone">{{tempData.phones.toString()}}</span>
      <ng-template #noPhone>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="path contact__label">
      <span class="sp sp-road"></span>
      <span class="contact__desc" *ngIf="tempData.pathDescription !== '' && tempData.pathDescription !== null; else noPath">{{tempData.pathDescription}}</span>
      <ng-template #noPath>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="site contact__label">
      <span class="sp sp-w"></span>
      <span class="contact__desc" *ngIf="tempData.webSite !== '' && tempData.webSite !== null; else noSite">{{tempData.webSite}}</span>
      <ng-template #noSite>
        <span class="field ">[не указано]</span>
      </ng-template>
    </div>
    <div class="contact__label metro">
      <span class="sp sp-bus"></span>
      <ul class=" metro__list">
        <ng-container *ngIf="tempData.metro.length > 0; else noMetro">
          <li *ngFor="let item of tempData.metro">{{getMetroText(item)}}</li>
        </ng-container>
        <ng-template #noMetro>
          <span class="field ">[не указано]</span>
        </ng-template>
      </ul>
    </div>
  </div>

  <div class="row__field" [hidden]="!enableEdit">
    <form [formGroup]="form" novalidate>
      <div class="form-group" myScrollTo>
        <label class="box-title">Адрес</label>
        <input class="form-control input__text input__text--long" maxlength="500" placeholder="Адрес клуба" name="address" [(ngModel)]="tempData.address"
          formControlName="address" />
        <label for="address" class="field-validation-error" *ngIf="fieldErrors.address && isSubmited">{{fieldErrors.address}}</label>
      </div>

      <div class="form-group" myScrollTo>
        <label class="box-title">Телефон</label>
        <input class="form-control input__text input__text--long" maxlength="500" placeholder="Номер телефона" name="phones" [(ngModel)]="tempData.phones"
          formControlName="phones" />
        <label for="phones" class="field-validation-error" *ngIf="fieldErrors.phones && isSubmited">{{fieldErrors.phones}}</label>
      </div>

      <div class="form-group" myScrollTo>
        <label class="box-title">Как добраться?</label>
        <textarea class="form-control input__text input__text--long" maxlength="10000" placeholder="Опишите маршрут" name="pathDescription"
          [(ngModel)]="tempData.pathDescription" formControlName="pathDescription"></textarea>
        <label for="pathDescription" class="field-validation-error" *ngIf="fieldErrors.pathDescription && isSubmited">{{fieldErrors.pathDescription}}</label>
      </div>

      <div class="form-group" myScrollTo>
        <label class="box-title">Веб сайт</label>
        <input class="form-control input__text input__text--long" type="url" maxlength="500" placeholder="Адрес веб сайта" name="webSite"
          [(ngModel)]="tempData.webSite" formControlName="webSite" />
        <label for="webSite" class="field-validation-error" *ngIf="fieldErrors.webSite && isSubmited">{{fieldErrors.webSite}}</label>
      </div>

    </form>

    <div class="metro">
      <label class="box-title" *ngIf="tempData.metro.length > 0">Метро</label>
      <div *ngFor="let item of tempData.metro; let i = index">
        <hr>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <input class="input__text" type="text" placeholder="Название" readonly [(ngModel)]="item.name" myScrollTo>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <input class="input__text" type="text" placeholder="Время пешком" [(ngModel)]="item.time" myScrollTo>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <input class="input__text" type="text" placeholder="Расстояние в метрах" [(ngModel)]="item.distance" myScrollTo>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <button class="btn btn-primary btn-small" (click)="removeMetro(i)">
                <span class="btn-primary__desc">
                  Убрать
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="add">
        <div class="row">

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <app-object-picker class="select-wr" [objects]="availableMetroStations" [short]="true" [placeholder]="'Станция метро'" *ngIf="metroList!=null"
                (ngModelChange)="updateAvailableStations()" [(ngModel)]="currMetro"></app-object-picker>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <input class="input__text" type="text" placeholder="Время пешком" #time myScrollTo>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <input class="input__text" type="text" placeholder="Расстояние в метрах" #distance myScrollTo>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="form-group">
              <button class="btn btn-primary btn-small" (click)="addMetro(time, distance)">
                <span class="btn-primary__desc">
                  Добавить
                </span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
