import * as _ from 'lodash';

export class ToggleEdit {
    public enableEdit: Boolean = false;
    // то, на что биндится компонент.
    public data: any;

    // текущее состояние. Сбрасывается на data при отмене редактирования.
    public tempData: any;

    onSave: () => void;

    public copy ( data: any ) {
        return _.cloneDeep( data );
    }

    _onSave () {
        this.data = this.copy( this.tempData );
        this.enableEdit = false;
    }

    _onCancel () {
        this.tempData = this.copy( this.data );
        this.enableEdit = false;
    }

    _onBeginEdit () {
        this.enableEdit = true;
    }

    onClickOutside ( $e ) {
        if ( this.enableEdit ) {
            if ( !$e.isInside ) {
                this.onSave();
            }
        }
    }
}
