import { Component, OnInit, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements AfterViewInit, OnInit {
  @Input() isReadonly;
  @Input() title;

  _isEditMode: boolean;
  get isEditMode(): boolean { return this._isEditMode; }
  @Input('isEditMode')
  set isEditMode(value: boolean) {
    this._isEditMode = value;
    this._isEditMode || this.enableEdit && this.onCancel();
  }
  // то, на что биндится компонент.
  @Input() data: FileList;
  @Output() dataChange = new EventEmitter<FileList>();
  @Output() onFinishEdit = new EventEmitter();

  // текущее состояние. Сбрасывается на data при отмене редактирования.
  public tempData: FileList;
  public el;

  get enableEdit(): boolean {
    return this.tempData && this.tempData.length > 0;
  }

  constructor(
    private elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.tempData = this.data;
  }

  ngAfterViewInit() {
    this.el = this.elRef.nativeElement.querySelector('.fileInput');
  }

  onSelectFile(event: any) {
    this.tempData = event.target.files;
  }

  onBeginEdit() {
    if (!this.isReadonly) {
      this.tempData = this.data;
      this.el.click();
    }
  }

  onSave() {
    this.dataChange.emit(this.tempData);
    this.onFinishEdit.emit();
  }

  onCancel() {
    this.tempData = undefined;
    this.el.value = '';
  }
}
