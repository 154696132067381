<header class="content__header" *ngIf="Model">

  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание вебхука</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование вебхука</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <div class="row">
    <div class="col-xs-12">
      <form class="row" [formGroup]="form" novalidate>
        <div class="col-xs-12">
          <div class="form-group">
            <div class="box-title">Включить/выключить</div>
            <div class="row__field">
              <input type="checkbox" [(ngModel)]="Model.isActive" formControlName="isActive" [checked]="Model.isActive"
                     class="checkbox__slide" name="isActive" id="isActive-{{Model.id}}">
              <label for="isActive-{{Model.id}}" class="checkbox__slide_label"></label>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>
        <div class="col-xs-12 col-md-4">
          <div class="form-group" myScrollTo>
            <div class="box-title">Название</div>
            <div class="row__field">
              <input type="text" class="input__text input__text--long" formControlName="name" id="name" [(ngModel)]="Model.name" maxlength="500">
              <label for="name" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>
        <div class="col-xs-12 col-sm-6 col-md-12">
          <div class="form-group" myScrollTo>
            <div class="box-title">Описание</div>
            <div class="row__field pd">
              <ckeditor [(ngModel)]="Model.description" formControlName="description" id="description" maxlength="10000"></ckeditor>
              <label for="description" class="field-validation-error" *ngIf="fieldErrors.description && isSubmited">
                {{fieldErrors.description}}
              </label>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>
        <hr>
        <div class="clearfix hidden-md-up"></div>
        <div class="col-xs-12 col-md-12">
          <div class="form-group" myScrollTo>
            <div class="box-title">Url</div>
            <div class="info-text">
              <span>Полный url адрес с параметрами на который будет идти запро из crm.</span><br><br>
              <span>Пример: <span style="font-weight: bold;">https://localhost/?userEmail={{'{User.Email}'}}&courseName={{'{Course.Name}'}}</span></span>

              <p>Доступные объекты в конструкторе параметров: <span style="font-weight: bold;">Club, Classes, ClassVisit, Course, User, Client</span></p>
            </div>
            <div class="row__field">
              <input type="text" class="input__text input__text--long" formControlName="url" id="url" [(ngModel)]="Model.url" maxlength="500">
              <label for="url" class="field-validation-error" *ngIf="fieldErrors.url && isSubmited">{{fieldErrors.url}}</label>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>
        <div class="col-xs-12">
          <div class="form-group">
            <div class="box-title">Событие</div>
            <div class="info-text">
              <span>Указывает на каком событие будет выстреливать вебхук</span>
            </div>
            <div class="row__field">
              <select class="select select__long" ([ngModel])="Model.eventType" formControlName="eventType">
                <option *ngFor="let event of EventTypeList" [value]="event.value">
                  {{event.value|EventType}}
                </option>
              </select>
              <label for="url" class="field-validation-error" *ngIf="fieldErrors.eventType && isSubmited">{{fieldErrors.eventType}}</label>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>
        <div class="col-xs-12 col-md-12">
          <div class="form-group" myScrollTo>
            <div class="box-title">Bearer token</div>
            <div class="info-text">
              <span>Bearer token если в исходящий запрос необходимо проставить токен авторизации.</span>
            </div>
            <div class="row__field">
              <input type="text" class="input__text input__text--long" formControlName="token" id="token" [(ngModel)]="Model.token" maxlength="500">
              <label for="token" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.token}}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <ng-container *ngIf="isEditAvailable">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <div class="row__field">
                <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveText'"></span>
                    <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <div class="row__field">
                <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveDraft'"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="clearfix hidden-md-up"></div>

          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <div class="row__field">
                <ng-container *ngIf="Model.id != null">
                  <button class="btn btn-link" (click)="delete()">
                    <span app-key-help [key]="'deleteText'"></span>
                    <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>
