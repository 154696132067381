import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {Registry, RegistryElement, RegistryElementStatus} from '../../../common/models/registry.model';
import {ExcelService} from '../../../common/services/excel.service';
import {CsvService} from '../../../common/services/csv.service';

@Component({
  selector: 'app-finance-registry-elements',
  templateUrl: './finance-registry-elements.component.html',
  styleUrls: ['./finance-registry-elements.component.scss']
})
export class FinanceRegistryElementsComponent implements OnInit {
  public static readonly componentName: string = 'FinanceRegistryElementsComponent';

  public isLoading = false;
  public registry: Registry;
  public registryElement: RegistryElement[] = [];
  public amountTotal: number;
  public bonusesTotal: number;
  public transactionCount: number;
  public clubLegalInfo: any[] = [];
  public paramsId: any;

  constructor(
    private route: ActivatedRoute,
    private apiV2: ApiV2Service,
    private router: Router,
    private excelService: ExcelService,
    private csvService: CsvService
  ) { }

  async ngOnInit(): Promise<any> {
    this.route.params.subscribe(async params => {
      if (params.clubId) {
        await this.apiV2.registry.getById(params.id).then(async registry => {
          this.paramsId = params.clubId;
          this.registry = registry;

          await this.apiV2.registry.getElementsByClub({ clubId: params.clubId, registryId: this.registry.id }).then(registryElement => {
            this.registryElement = registryElement;

            this.registryElement = this.registryElement.filter(x => x.status !== RegistryElementStatus.deleted);
            this.registryElement = this.registryElement.sort((a, b) => (a.date < b.date ? 1 : -1));

            this.amountTotal = this.dataAmountClub(params.clubId, 'amount', false);
            this.bonusesTotal = this.dataAmountClub(params.clubId, 'bonuses', false);
            this.transactionCount = this.registryElement.length;

            this.isLoading = true;
          });
        });
      }
    });
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public data(data: string, field: string): string {
    return JSON.parse(data)[field];
  }

  public close(): void {
    this.router.navigate(['../../'], { relativeTo: this.route }).then();
  }

  public regAmoComTotal(d: any, amount: any): any {
    const e: any[] = [];
    d.forEach(m => {
      if (m.status === RegistryElementStatus.published || m.status === RegistryElementStatus.hold) {
        e.push(m.amount / 100 * m.commission);
      }
    });
    return (amount - e.reduce((a, b) => a + b, 0)).toFixed(2);
  }

  private dataAmountClub(clubId: string, type: string, total: boolean): any {
    let f =  this.registryElement.filter(x => x.clubId === clubId);
    if (total) {
      f = this.registryElement;
    }
    if (f.length === 0) {
      return 0;
    }
    if (type === 'amount') {
      return f.filter(x => x.status !== RegistryElementStatus.deleted).reduce((prev, curr) => { return prev + curr.amount }, 0);
    } else if (type === 'bonuses') {
      return f.filter(x => x.status !== RegistryElementStatus.deleted).reduce((prev, curr) => { return prev + curr.bonuses }, 0);
    }
  }

  public export(ext?: string): any {
    const _clubIds = [];
    for (const r of this.registryElement) {
      if (!_clubIds.find(x => x === r?.clubId)) { _clubIds.push(r?.clubId); }
    }
    if (_clubIds.length > 0) {
      this.apiV2.registry.getLegalInfoByClubIds(_clubIds).then(legalInfo => {
        if (legalInfo) {
          for (const _info of legalInfo) {
            this.clubLegalInfo.push({
              id: _info.clubId,
              legalInfo: JSON.parse(_info.legalInfo)
            });
          }
        }
      })
    }

    const exportA: any[] = [];
    this.registryElement.forEach((m, index) => {
      const i = ++index;
      if (ext === 'csv') {
        console.log(m.date.length)
        const e: any =  {
          'Контакт': m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
          'ФИО': m.fullName ? m.fullName?.replace(',', '').replace(';', '').trim() : '',
          'Коментарий': m.comment?.replace(',', '').replace(';', '').trim(),
          'Дата': m.date.length > 10 ? m.date.slice(0, 10) : m.date,
          'Сумма': m.amount.toString().trim(),
          'Банк': m.bankName ? m.bankName : 'Не указан',
          'Комиссия': m.commission.toString().replace('.', ',').trim() + '%',
        };
        exportA.push(e);
      } else {
        /*
        const e: any =  {
          '№': i.toString(),
          'Контакт': m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
          'ФИО': m.fullName?.replace(',', '').replace(';', '').trim(),
          'Коментарий': m.comment?.replace(',', '').replace(';', '').trim(),
          'Дата': m.date.slice(0, -8),
          'Платеж': m.amount.toString().trim() + ' руб.',
          'Комиссия': m.commission.toString().replace('.', ',').trim() + '%',
        };
        */
        const e: any =  {
          number: i.toString(),
          contact: m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
          fio: m.fullName?.replace(',', '').replace(';', '').trim(),
          comment: m.comment?.replace(',', '').replace(';', '').trim(),
          date: m.date.slice(0, -8),
          amount: m.amount.toString().trim() + ' руб.',
          comm: m.commission.toString().replace('.', ',').trim() + '%',
        };
        exportA.push(e);
      }
    });

    if (ext === 'csv') {
      this.csvService.downloadFile(exportA, `Реестр от ${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`, true);
    } else {
      const details = {
        clubName: this.registryElement[0]?.club,
        registerAmount: this.amountTotal,
        records: this.transactionCount,
        registryDate: moment().format('DD.MM.YYYY'),
        paymentDates: `${moment(this.registry.dateFrom).format('DD.MM.YYYY')} - ${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
        registerAmountTotal: parseFloat(this.regAmoComTotal(this.registryElement, this.amountTotal)),
        payer: this.getPayer(this.paramsId)
      }

      this.excelService.exportAsExcelFileReestr(
        exportA,
        `Реестр от ${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
        details
        );
    }
  }

  private getPayer(data: any) {
    const legalInfo = this.clubLegalInfo.find(x => x.id === data[0]);
    return legalInfo?.legalInfo?.LegalName?.replace('«', '"').replace('»', '"');
  }

}
