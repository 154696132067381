import { Injectable } from '@angular/core';
import * as color from 'color';
import { predefinedColorPairs } from './data/color-pairs';

@Injectable()
export class ColorService {
  private colorPairs: string[][] = predefinedColorPairs;
  private customColorPairs: string[][] = [];

  public getPrimaryColors() {
    return this.colorPairs.map(x => x[0].toLowerCase());
  }

  public getSecondaryColors() {
    return this.colorPairs.map(x => x[1].toLowerCase());
  }

  public getColors() {
    return this.colorPairs
      .map(item => item
        .map(c => {
          return c.toLowerCase()
        })
      );
  }

  public getMatchingSecondaryColor(primaryColor: string) {
    const existingColorPair = [...this.colorPairs, ...this.customColorPairs]
      .find(x => x[0].toLowerCase() === primaryColor.toLowerCase());

    if (existingColorPair) {
      return existingColorPair[1];
    } else {
      const secondaryColor = `#${color(primaryColor).darken(0.4).saturate(0.4).rgbNumber().toString(16).padStart(6, '0')}`;
      const newColorPair = [primaryColor, secondaryColor];
      this.customColorPairs.push(newColorPair);
      return newColorPair[1];
    };
  }
}
