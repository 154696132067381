import { Route } from '@angular/router';
import { WidgetComponent } from 'app/components/widget/widget.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const widget: Route = {
  path: ':cityId/:clubNetId/:clubId/widget',
  component: WidgetComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Виджет расписания'}
};
