import { Route } from '@angular/router';
import { HomeComponent } from 'app/components/home/home.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const homeRoute: Route = {
  path: 'home',
  component: HomeComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Домик' }
};
