import { Component, forwardRef, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-object-picker',
  templateUrl: './object-picker.component.html',
  styleUrls: ['./object-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectPickerComponent),
      multi: true
    }
  ]
})

export class ObjectPickerComponent implements ControlValueAccessor, OnChanges {
  @Input() inactive;
  @Input() objects: Array<any>;
  @Input() placeholder = 'Не выбрано';
  @Input() short;
  @Input() groupBy;

  form = new FormGroup({
    pickerControl: new FormControl('')
  });

  public groupedObjects = [];
  public data: any;
  private propagateChange = (_: any) => {};

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.setControlsState(this.data);
      this.propagateChange(this.data);
    }
  }

  setControlsState(data) {
      for(let i=0; i<this.objects.length; i++) {
        if(this.objects[i].id == data.id) {
          this.form.get('pickerControl').setValue(i);
          break;
        } else {
          this.form.get('pickerControl').setValue('');
        }
      }
  }


  ngOnChanges(changes: SimpleChanges) {
    const objects: SimpleChange = changes.objects;

    if (this.groupBy) {
      const notGroupedName = '[без группы]';
      this.objects.forEach((o, i) => o.index = i);

      const g = this.objects.reduce((rv, x) => {
        let normalizedGroupName = (<string>(x[this.groupBy] || '')).trim();
        if (normalizedGroupName === '') {
          normalizedGroupName = notGroupedName;
        }

        rv[normalizedGroupName] = rv[normalizedGroupName] || [];
        rv[normalizedGroupName].push(x);
        return rv;
      }, {});

      for (const key of Object.keys(g)) {
        this.groupedObjects.push({
          key: key,
          data: g[key]
        });
      }

      this.groupedObjects = this.groupedObjects
        .sort((a, b) => {
          if (a.key > b.key) {
            return 1;
          }
          if (a.key < b.key) {
            return -1;
          }
          return 0;
        })
        .sort((a, b) => {
          if (a.key === notGroupedName) {
            return 1;
          }
          if (b.key === notGroupedName) {
            return -1;
          }
          return 0;
        });
    }

    if (objects) {
      if (!objects.isFirstChange() && objects.previousValue !== objects.currentValue) {
        if (!(<Array<any>>objects.currentValue).includes(this.data)) {
          if (this.objects.length > 0) {
            this.writeValue(this.objects[0]);
          }
        }
      }
    }
  }

  onChange(event) {
    if (event !== '') {
      this.writeValue(this.objects[event]);
    } else {
      this.writeValue({ id: null });
    }
  }
}
