import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../form-controls-core/value-acessor-base';

@Component({
    selector: 'app-form-text-editor',
    templateUrl: 'form-text-editor.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormTextEditorComponent),
        multi: true
    }]
})
export class FormTextEditorComponent extends ValueAccessorBase<string> {

    @ViewChild('wrap') wrapElement;

    constructor() {
        super();
    }

    onReady(e) {
        e.editor.on('key', (e2) => {

            // Это обработка Ctrl+S - пока не понял зачем она тут.
            // if (e2.data.keyCode === 1114195) {
            //     e2.cancel();  // <- Prevent Default
            //     this.onSave();
            // }

            // Клавиша ESC
            if (e2.data.keyCode === 27) {
                const escEvent: any = new KeyboardEvent('keydown', { bubbles: true, cancelable: true });
                delete escEvent.keyCode;
                Object.defineProperty(escEvent, 'keyCode', { 'value': 27 });
                document.body.dispatchEvent(escEvent);
                // this.wrapElement.nativeElement.dispatchEvent(escEvent);
                // console.log();
                // e2.cancel();  // <- Prevent Default
                // this.onCancel();
            }
        });
    }

}
