import { Component, Injector } from '@angular/core';
import { IWidgetModel } from 'app/common/models/widgets.model';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { WidgetsService } from './widgets.service';
import { isAdmin, isManager } from 'app/common/models/user-info.model';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent extends TableListComponent<IWidgetModel> {
  public static readonly componentName: string = 'WidgetsComponent';

  public isSystemRole: boolean;

  constructor(injector: Injector, protected service: WidgetsService) {
    super(injector, service);
  };

  async ngOnInit() {
    super.ngOnInit();
    let userInfo = await this.authService.getUserInfo();
    this.isSystemRole = isAdmin(userInfo) || isManager(userInfo);
  }

    protected hkAddPress( that: this) {
      if (this.isEditAvailable && this.isSystemRole) {
        return super.hkAddPress(that);
      } else {
        return () => { return true } ;
      }
    }
}
