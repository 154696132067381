<header class="content__header">
  <div class="content__header-title content__header_title--short">
    <h1 class="content__header-title-value content__header-title-value_default">
      Аналитика
    </h1>
    <a class="content__header-link video-link" target="_blank" href="https://sportpriority.com/vse-dlya-fitnes-klubov/videospravka-kak-rabotat-v-crm/">
      <span class="sp sp-video"></span>&nbsp;<span class="content__header-link_text">Видеоуроки</span>
    </a>
    <a class="content__header-link" target="_blank" href="https://sportpriority.com/media/34351/sport_priority_-_instrukciya_po_zapolneniyu.pdf">
      <span class="sp sp-information"></span>&nbsp;<span class="content__header-link_text">Инструкция</span>
    </a>
  </div>
</header>
<hr>

<div class="content__body" *ngIf="Model != null">

  <ng-container *ngIf="IsShowGraphClients || IsShowGraphEfficiency || IsShowGraphFinance || IsShowGraphMunicipalTask">
    <div>
      <div class="row" style="margin-bottom: 50px">
<!--        <div class="info-text">-->
<!--          Графики работают в тестовом режиме. Отображаемые данные могут быть некорректными.-->
<!--        </div>-->
      </div>
      <h2 class="title2 mt-3 mb-3">Статистика за период</h2>
      <br>
      <div class="main-select-period">
        <div class="select-period">
            <a [class.active]="selectPeriod === 'today'" (click)="onSelectPeriod('today')">Сегодня</a>
            <a [class.active]="selectPeriod === 'week'" (click)="onSelectPeriod('week')">Неделя</a>
            <a [class.active]="selectPeriod === 'month'" (click)="onSelectPeriod('month')">Месяц</a>
        </div>
        <div class="block-range-date" [class.active]="selectPeriod === 'any'">
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [dateFilter]="dateFilter">
            <input matStartDate formControlName="start">
            <input matEndDate formControlName="end" (dateChange)="rangePicker($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
      </div>
      <app-summary [startDate]="start" [endDate]="end"></app-summary>

    </div>
    <h2 class="title2 mt-3 mb-3">Отчеты</h2>
  </ng-container>

    <div class="table-responsive">
      <table class="table rowHover">
        <thead>
          <td class="table-cell table-title-cell">Название</td>
          <td class="table-cell table-title-cell">Описание</td>
        </thead>

        <tbody>
          <tr *ngFor="let report of Model" (mousedown)="goToDetail(report,$event)" (click)="goToDetail(report,$event)">
            <td class="table-cell">
              <span class="table-cell__mobile-title">Название</span>
              {{report.displayName}}
            </td>
            <td class="table-cell">
              <span class="table-cell__mobile-title">Описание</span>
              {{report.description}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
