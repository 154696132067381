<header class="content__header">
  <div class="content__header-title content__header_title--short">
    <span class="content__header-title-value content__header-title-value_default">
      Рабочий стол
    </span>

    <a class="content__header-link video-link" target="_blank" href="https://sportpriority.com/vse-dlya-fitnes-klubov/videospravka-kak-rabotat-v-crm/">
      <span class="sp sp-video sp-size"></span>&nbsp;<span class="content__header-link_text">Видеоуроки</span>
    </a>

    <a class="content__header-link" target="_blank" href="https://sportpriority.com/media/34351/sport_priority_-_instrukciya_po_zapolneniyu.pdf">
      <span class="sp sp-information sp-size"></span>&nbsp;<span class="content__header-link_text">Инструкция</span>
    </a>
  </div>
</header>
<hr>
<div class="content-body">
  <div class="content-wrapper">
    <div class="box-title" #chatsRef>
      <span class="box-title__name">Чаты</span>
      <span class="checkbox-wrap">
        <input type="checkbox" class="checkbox__slide" id="notificationSoundsChats" [(ngModel)]="soundsChatsOff">
        <label for="notificationSoundsChats" class="checkbox__slide_label">Без звука</label>
      </span>

      <div>
        <a class="box-link" [routerLink]="['/'+this.context.cityId+'/'+this.context.clubNetId+'/'+this.context.clubId+'/chats']" target="_blank">
          <span class="sp sp-chats sp-size"></span>&nbsp;<span class="box-link_text">Все чаты</span>
        </a>
        <span *ngIf="totalChatsCount" class="chats-counter">{{totalChatsCount}}</span>
      </div>
    </div>

    <div class="chat-row" *ngIf="chats && chats.length">
      <div *ngFor="let chat of chats" class="chat-item" (click)="onChatStart(chat)">
        <div *ngIf="chat.id" class="chat-item__image">
          <span class="photo"><span class="img">
            <span class="sp sp-size" [class.sp-user]="chat.membersCount==1" [class.sp-client]="chat.membersCount > 1"></span>
          </span></span>
          <div class="chat-item__counter">{{chat.newsCount}}</div>
        </div>
        <div class="chat-item__data">
          <div class="chat-item__title">{{chat.title}}</div>
          <div class="chat-item__message"><span *ngIf="chat.membersCount > 1" class="chat-item__message__author">{{chat.lastAuthor}}: </span>{{chat.lastMessage}}</div>
        </div>
        <div class="chat-item__updated">{{chat.updated}}</div>
      </div>
    </div>
    <hr>

    <app-queued-dashboard *ngIf="schedule && schedule?.classes && !isAutomaticBookingQueue && isYouCanQueue" [classes]="schedule.classes"></app-queued-dashboard>

    <app-class-requests></app-class-requests>

    <div class="box-title">
      <span class="box-title__name">Отметить посещение</span>
      <a class="box-link" [routerLink]="['../addclienttoclass']">Добавить
        <span class="key">
          <span app-key-help [key]="'plus'"></span>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="info-text">
          Здесь появляются клиенты, записанные на занятия, которые начнутся в течение часа, либо начались не более трёх часов назад
      </div>
    </div>

      <div *ngIf="classVisits && classVisits.length" class="row">
        <div class="info-text icons-prompt">
          <p>
            <span class="icon-check icons-prompt">
              <span class="icon-check__left"></span>
              <span class="icon-check__right"></span>
            </span>
            <span class="icons-description">&mdash; отмечает посещение занятия и списывает одно посещение с абонемента</span>
          </p>
          <p>
            <span class="icon-cross icons-prompt">
              <span class="icon-cross__minus"></span>
            </span>
            <span class="icons-description">&mdash; отмечает прогул занятия клиентом и списывает одно посещение с абонемента</span>
          </p>
        </div>
      </div>

      <div *ngIf="classVisits && classVisits.length" class="check-visit__counter_row">
        <span class="check-visit__counter" (click)="scrollToCheckVisits(checkVisit)">{{classVisits.length}}
          <span class="check-visit__counter_triangle"><span class="sp sp-arrow-small sp-size"></span></span>
        </span>
      </div>

      <div *ngIf="classVisits && classVisits.length" class="table rowHover check-visit" #checkVisit>
        <div class="table-row_head">
          <div class="table-cell table-title-cell"><span>Пришел/Пропустил</span></div>
          <div class="table-cell table-title-cell"><span>Клиент</span></div>
          <div class="table-cell table-title-cell"><span>Занятие</span></div>
          <div class="table-cell table-title-cell"></div>
        </div>

        <div *ngFor="let client of classVisits" class="table-row">
          <div class="table-cell table-cell_action">
            <button class="btn btn-clear active check-visit_btn" (click)="confirmVisited(client)">
              <span class="icon-check">
                <span class="icon-check__left"></span>
                <span class="icon-check__right"></span>
              </span>
            </button>
            <span class="mobile__label">Пришел</span>

            <button class="btn btn-clear active check-visit_btn" (click)="confirmMissed(client)">
              <span class="icon-cross">
                <span class="icon-cross__minus"></span>
              </span>
            </button>
            <span class="mobile__label">Пропустил</span>

            <button class="btn btn-clear active btn__delete check-visit_btn" [disabled]="client.pending" (click)="cancelBooking(client)">
              <span class="icon-cross icon-delete">
                <span class="icon-cross__left"></span>
                <span class="icon-cross__right"></span>
              </span>
            </button>
            <span class="mobile__label">Отмена посещения</span>
          </div>

          <div class="table-cell">
            <div class="table-cell__client_wrap" (click)="goToClient(client)">
              <div class="table-cell__avatar">
                <div class="table-cell__avatar-default" *ngIf="!client.primaryImageUrl">
                  <span class="sp sp-user sp-size"></span>
                </div>
                <div class="table-cell__avatar-default" *ngIf="client.primaryImageUrl" [style.background]="'url('+client.primaryImageUrl+')'">
                </div>
              </div>

              <div class="table-cell__client__desc">
                <span class="table-cell__client__name">{{client.firstName}} {{client?.lastName}}</span>
                <span class="table-cell__client__phone">{{ client.phone }}</span>
                <span class="table-cell__client__visit-status" [ngClass]="{canceled: !client.currentVisitStatus === 'Booked'}">{{ client.currentVisitStatusText }}</span>
              </div>
            </div>
          </div>

          <div class="table-cell">
            <div class="row__field" [formGroup]="visitsControls">
              <mat-select setHeight formControlName="v-{{client.id}}" class="select select__long"
                (selectionChange)="selectSubscriptionForClient(client.id, $event.value, client.subscriptions)">
                <mat-option class="option" [value]="guidEmpty">Без абонемента</mat-option>
                <mat-option class="option" *ngFor="let sub of client.subscriptions" [value]="sub.id">
                  {{sub.name}} {{sub.consumedCount}}/{{infinity(sub.visitCount)}}</mat-option>
              </mat-select>
            </div>
            <span class="table-cell__client__class-name">
              {{ client.className }}
            </span>
            <span class="table-cell__client__class-date">
              {{ client.startTime }} \ {{ client.startDate }}
            </span>

          </div>
          <div class="table-cell">
            <button type="button" class="btn-special" [disabled]="client.pending" (click)="cancelBooking(client)">Отмена посещения</button>
          </div>
        </div>
      </div>

    <br />
    <br />
    <div class="col-12 col-xs-12 col-md-6 col-lg-4" *ngIf="isShowBtnElectronicQueue">
      <button class="btn btn-primary" (click)="goToQueue()">
        <span class="btn-primary__desc">Электронная очередь</span>
      </button>
    </div>
    <div class="col-12 col-xs-12 col-md-6 col-lg-4" *ngIf="isShowBtnElectronicQueue">
      <button class="btn btn-primary" (click)="goToResourceQueue()">
        <span class="btn-primary__desc">Очередь на выдачу ресурсов</span>
      </button>
    </div>
  </div>

  <div class="classes-box" #classesRef>
    <div class="box-title classes-date">
      <span class="box-title__name">
        {{ clubTimestamp | date: 'd'}}
        <span class="month">{{ clubTimestamp | date: 'MMMM': 'ru' }}</span>
      </span>
    </div>
    <a class="classes-link" [routerLink]="['/'+this.context.cityId+'/'+this.context.clubNetId+'/'+this.context.clubId+'/clubschedule/'+baseDate]">
      <span class="box-link_text">Всё расписание</span>
    </a>
    <div class="classes-list">
      <div class="classes-item" (click)="goToClass(class)" [style.backgroundColor]="class.course?.color || 'gray'" *ngFor="let class of classes">
        <div class="badge">
          <span class="letter newGroup" *ngIf="class.isNewGroup">
              Н
              <span class="help">Новый набор</span>
          </span>

          <span class="letter draft" *ngIf="class.entityStatus === 'draft'">
            Ч
            <span class="help">Черновик</span>
          </span>
        </div>

        <div class="classes-item__title">{{class.course?.name}}</div>
        <span class="classes-item__desc"><b>{{class.startTime}}</b></span>
        <span class="classes-item__desc">{{class.numberOfVisits - class.numberOfQueued}}/{{class.maxCapacity}} {{class.numberOfQueued > 0 ? '(+' + class.numberOfQueued + ')' : null}}</span>
      </div>
    </div>
  </div>

  <div class="event-box" #eventsRef>
    <div class="box-title" id="box-title-events-line">
      <span class="box-title__name">
        Лента событий
      </span>
    </div>
    <div class="event-list" *ngIf="errorEvents">
      <p style="text-align: center; padding: 50px; color: #aaaab6;">Лента событий в данный момент недоступна</p>
    </div>
    <div class="event-list" *ngIf="processedEvents && processedEvents.length">
      <div class="event-item" *ngFor="let event of processedEvents" [style.backgroundColor]="event.color">
        <div class="event-item__title">{{event.title}}
          <span class="event-item__title__date">Дата: {{event.dateTime}}</span></div>
        <span class="event-item__desc">
          <ng-container *ngFor="let token of event.tokens">
            <ng-container *ngIf="token.isComponent">
              <div *ngComponentOutlet="token.component; ndcDynamicInputs: {params: token.params}"></div>
            </ng-container>
            <ng-container *ngIf="!token.isComponent">
              {{token.text}}
            </ng-container>
          </ng-container>
        </span>
        <!-- <span class="event-item__dateTime">{{event.dateTime}}</span> -->
        <div class="event-item__link-wr" *ngIf="event.params.isClubConfirmationRequired && event.params.isClubConfirmationRequired.value === 'true'">
          <a (click)="confirmEvent(event)" class="event-item__link">Подтвердить посещение</a>
        </div>
      </div>
      <div class="add-events" *ngIf="eventsCount > processedEvents.length">
        <button class="btn btn-outline" (click)="addEvents()">
          Показать еще
        </button>
      </div>
    </div>
  </div>
</div>

