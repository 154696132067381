import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {EditItemComponent} from '../../../common/edit-item.component';
import {EventTypeList, IWebhookModel} from '../../../common/models/webhook.model';
import {WebhookService} from '../webhook.service';
import {Validators} from '@angular/forms';
import {EntityStatus} from '../../../common/models/entity-status.model';

@Component({
  selector: 'app-webhook-edit',
  templateUrl: './webhook-edit.component.html',
  styleUrls: ['./webhook-edit.component.scss']
})
export class WebhookEditComponent extends EditItemComponent<IWebhookModel> implements OnInit, OnDestroy {
  public static readonly componentName: string = 'WebhookEditComponent';
  public readonly EventTypeList = EventTypeList;

  fieldMessages = {
    name: {
      required: 'Это поле обязательно'
    },
    url: {
      required: 'Это поле обязательно'
    },
    eventType: {
      required: 'Это поле обязательно'
    }
  };

  constructor(injector: Injector,
              protected service: WebhookService) { super(injector, service); }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  afterModelInit() {
    this.Model.eventType = this.eventTypeValue(this.Model.eventType);
  }

  afterFinishEdit() {
    super.afterFinishEdit();
  }

  finishEdit() {
    super.finishEdit();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      entityStatus: [this.Model.entityStatus, []],
      name: [this.Model.name, [Validators.required]],
      description: [this.Model.description, []],
      isActive: [this.Model.isActive, []],
      url: [this.Model.url, [Validators.required]],
      eventType: [this.Model.eventType, [Validators.required]],
      token: [this.Model.token, []]
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  eventTypeValue(event: any) {
    if (!event) return null;
    return this.EventTypeList.filter(x => x.key.toString().toLowerCase() === event.toString().toLowerCase())[0].value;
  }

  protected modelTemplate(): Promise<IWebhookModel> {
    const model: IWebhookModel = {
      id: null,
      clubId: this.contextService.getRoutingParams().clubId,
      entityStatus: EntityStatus.published,
      name: '',
      description: '',
      isActive: false,
      createdAt: new Date(),
      updateAt: new Date(),
      url: '',
      eventType: undefined,
      token: ''
    };
    return Promise.resolve(model);
  }
}
