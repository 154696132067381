import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-club-event-reqfilds-table',
  templateUrl: './club-event-reqfilds-table.component.html',
  styleUrls: ['./club-event-reqfilds-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ClubEventReqfildsTableComponent),
    multi: true
  }]
})
export class ClubEventReqfildsTableComponent implements ControlValueAccessor, OnInit {

  public readonly presetFields = [
    { title: 'Имя, Фамилия', isPresent: false },
    { title: 'Телефон', isPresent: false },
    { title: 'Email', isPresent: false },
  ];

  public customFields: string[];

  constructor() {
  }

  ngOnInit(): void {
  }

  private modelTemplate = () => {
    const model = "";
    return model;
  }

  public onRemove(event: { isPresent: boolean }) {
    event.isPresent = false;
    this.writeValue(this.concatFields());
  }

  public onRecover(event: { isPresent: boolean }) {
    event.isPresent = true;
    this.writeValue(this.concatFields());
  }

  public addField() {
    const item = this.modelTemplate();
    if (!this.customFields)
      this.customFields = [ item ];
    else
      this.customFields.push(item);
  }

  public onChange(index: number, event: any) {
    this.customFields[index] = event.target.value;
    this.writeValue(this.concatFields());
  }

  public onDelete(index: number) {
    const _data = _.cloneDeep(this.customFields);
    _data.splice(index, 1);
    this.customFields = _data;
    this.writeValue(this.concatFields());
  }

  private concatFields(): string[] {
    return this.presetFields.filter(f => f.isPresent).map(f => f.title).concat(this.customFields);
  }

  private propagateChange: (any?: any) => void = () => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.presetFields.forEach(f => f.isPresent = obj.includes(f.title))
      this.customFields = obj.filter(t => !this.presetFields.some(f => f.title === t));
      this.propagateChange(obj);
    }
    else
      this.customFields = [];
  }

}
