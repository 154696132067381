import { Validators } from '@angular/forms';
import { Injector, Component } from '@angular/core';


import { ClubNetsService } from 'app/components/clubnets/services/clubnets.service';
import { IClubNetModel } from 'app/common/models/clubnet.model';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { EditItemComponent } from 'app/common/edit-item.component';
import { EntityStatus } from 'app/common/models/entity-status.model';

@Component({
  selector: 'app-clubnet-edit',
  templateUrl: './clubnet-edit.component.html',
  styleUrls: ['./clubnet-edit.component.scss']
})

export class ClubnetEditComponent extends EditItemComponent<IClubNetModel> {
  fieldMessages = {
    name: {
      NotEmpty: 'Это поле обязательно'
    }
  };

  constructor(
    injector: Injector,
    protected service: ClubNetsService
  ) {
    super(injector, service);
  }

  modelTemplate(): Promise<IClubNetModel> {
    const template: IClubNetModel = {
      id: null,
      entityStatus: EntityStatus.draft,
      name: null,
      users: []
    };
    return Promise.resolve(template);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      entityStatus: [this.Model.entityStatus, [Validators.required]]
    });
  }
}
