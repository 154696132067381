<div class="content__header-title">
  <span class="content__header-title-value content__header-title-value_default">
    Словари
  </span>
</div>
<br>

<ng-container *ngIf="isEdit">
  <div class="content__body">
    <ng-container>
      <div class="clubrooms-container">
        <div class="tableSideLinkWrap">
          <div class="clue-text">
            <a class="box-link" (click)="backToList()">Вернуться к
              списку
            </a>
            <ng-container *ngIf="dictionaryDetails.id">
              <a *ngIf="!dictionaryDetails.isDefault" class="box-link" (click)="selectDictionary()">Применить к CRM
              </a>
              <a *ngIf="dictionaryDetails.isDefault" class="isDisabled">Назначен для CRM</a>
              <a *ngIf="dictionaryDetails.isDefault" class="box-link" (click)="clearDefault()">Убрать</a>
            </ng-container>

          </div>
        </div>
        <input placeholder="Title" class="input__text input__text--long" [(ngModel)]="dictionaryDetails.title">
        <input placeholder="Alias" class="input__text input__text--long" [(ngModel)]="dictionaryDetails.alias">
        <textarea placeholder="Data" class="inputField" [(ngModel)]="dictionaryDetails.data"></textarea>
      </div>
      <br>
      <div class="col-xs-12 col-sm-6 col-md-6">
        <button class="btn btn-primary" (click)="save()">
          <span class="btn-primary__desc">
            <span>Сохранить</span>
          </span>
        </button>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-6">
        <button class="btn btn-link" (click)="remove()">
          <span app-key-help [key]="'deleteText'"></span>
        </button>
      </div>

    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!isEdit">
  <div class="content__body">
    <ng-container>
      <div class="clubrooms-container">
          <div class="clue-text">
            <span class="key" app-key-help [key]="'search'"></span>
            <span class="key" app-key-help [key]="'searchHK'"></span>
            <ng-container>
              <a class="box-link" (click)="onEditStart()">Добавить
                <span class="key">
                  <span app-key-help [key]="'plus'"></span>
                </span>
              </a>
            </ng-container>
          </div>

        <div class="table-responsive">
          <table class="table rowHover">
            <thead>
              <tr>
                <td class="table-cell">Наименование</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let dictionary of dictionaries' (click)="onEditStart(dictionary)">
                <td class="table-cell" *ngIf="!dictionary.isDefault"><b>{{dictionary.title}}</b></td>
                <td class="table-cell" *ngIf="dictionary.isDefault" class="isDefault"><b>{{dictionary.title}}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
