import { Injector, Component } from '@angular/core';
import { ICoachModel } from 'app/common/models/coach.model';
import { CoachesService } from './services/coaches.service';
import { TableListComponent } from 'app/common/table-list/table-list.component';

@Component({
  selector: 'app-coaches',
  templateUrl: './coaches.component.html',
  styleUrls: ['./coaches.component.scss']
})
export class CoachesComponent extends TableListComponent<ICoachModel> {
  public static readonly componentName: string = 'CoachesComponent';

  constructor(
    injector: Injector,
    protected service: CoachesService
  ) {
    super(injector, service);
  }
}
