import { Injectable, Injector } from '@angular/core';
import { IThemeModel } from 'app/common/models/widgets.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';


@Injectable()
export class ThemesService extends TableListService<IThemeModel> {

  public widgetType: string;

  constructor(injector: Injector) {
    super(injector);
  }

  getItemList(): Promise<IThemeModel[]> {
    return this.apiV2Service.themes.find(this.routingParams.clubId, this.widgetType);
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiV2Service.themes.get(req.id);
  }

  save(model: IThemeModel) {
    return this.apiV2Service.themes.save(model);
  }

  create(model: IThemeModel) {
    return this.save(model);
  }
}
