<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Залы
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="clubrooms-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">Добавить
              <span class="key">
                <span app-key-help [key]="'plus'"></span>
              </span>
            </a>
          </ng-container>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Площадь</td>
              <td class="table-cell table-title-cell">Активно</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let room of Model' (mousedown)="onEditStart(room,$event)" (click)="onEditStart(room)">
              <td class="table-cell table-cell_name">
                <span class="table-cell__mobile-title">Название</span>
                <div class="field desc">
                  <span class="color" [style.background]="room.color"></span>
                  <span class="clubroom-name">{{room.name}}</span>
                </div>
              </td>
              <td class="table-cell">
                <span class="table-cell__mobile-title">Площадь</span>
                <div class="desc field">
                  <span *ngIf="room.square; else noRoom">{{room.square}}</span>
                  <ng-template #noRoom>
                    <span class="gray ">[не&nbsp;указано]</span>
                  </ng-template>
                </div>
              </td>
              <td class="table-cell table-cell_publish" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
                <span class="table-cell__mobile-title">Активно</span>
                <app-status-picker [title]="null" [ngModel]="room.entityStatus" (ngModelChange)="setStatus($event, room)"></app-status-picker>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </ng-container>
</div>
