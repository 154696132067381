import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(title: string): unknown {
    title = title.replace(/\s{2,}/g, ' ').trim().replace(/[,\(\\.)«»]/g, '');
    let names = title.split(' ');

    if(names.length > 1) {
      let initialsTitle = [];
      names.forEach((name, i) => {
        if(i < 3) initialsTitle.push(name.charAt(0).toUpperCase());
      });
      return initialsTitle.join('');
    } else {
      return title.charAt(0);
    }
  }
}
