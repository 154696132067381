import { Component, OnInit, Injector, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MediaService } from 'app/common/services/media.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IImageParams } from 'app/common/models/image.model';
import { Hotkey, HotkeysService } from 'custom-modules/angular2-hotkeys';
import { WebCamComponent } from 'ack-angular-webcam';
import { AlertsService } from 'app/common/components/alerts/services/alerts.service';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss']
})
export class EditImageComponent implements OnInit {
  public static readonly componentName: string = 'EditImageComponent';

  public cameraVisible: boolean;
  public route: ActivatedRoute;
  protected router: Router;
  // protected service: MediaService;

  public Model: IImageParams;

  protected hkClose: Hotkey | Hotkey[];

  constructor(
    protected service: MediaService,
    protected hotkeysService: HotkeysService,
    public alertsService: AlertsService,
    injector: Injector,
  ) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);

    this.hkClose = this.hotkeysService.add(new Hotkey('esc', this.hkClosePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
  }

  async ngOnInit() {
    const imageId = this.route.snapshot.params['id'];
    this.Model = await this.service.get(imageId);
    this.centerX = this.Model.centerX / this.Model.width;
    this.centerY = this.Model.centerY / this.Model.height;
    this.resizeImage();

    if (this.Model.name == "Сделать фото") this.cameraVisible = true;
  }

  public centerX: number;
  public centerY: number;

  async setImageCenter(event: MouseEvent) {
    const centerX = event.offsetX / event.target['clientWidth'];
    const centerY = event.offsetY / event.target['clientHeight'];
    // this.Model.centerX = Math.ceil(this.Model.width * this.centerX);
    // this.Model.centerY = Math.ceil(this.Model.height * this.centerY);
    this.Model = await this.service.center(this.Model.id, Math.ceil(this.Model.width * centerX), Math.ceil(this.Model.height * centerY));
    this.centerX = this.Model.centerX / this.Model.width;
    this.centerY = this.Model.centerY / this.Model.height;
  }

  @ViewChild('contentbody')
  contentBodyView: ElementRef;

  @ViewChild('image')
  imageView: ElementRef;

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizeImage();
  }

  public orientation: 'portrait' | 'landscape';

  resizeImage() {
    const componentRatio = this.contentBodyView.nativeElement.offsetWidth / this.contentBodyView.nativeElement.offsetHeight;
    // this.orientation = componentRatio < 1 ? 'portrait' : 'landscape';
    const imageRatio = this.Model.width / this.Model.height;
    const portContainerRatio = componentRatio / 0.7;
    const landContainerRatio = componentRatio * 0.7;
    this.orientation = Math.abs(portContainerRatio / imageRatio - 1) < Math.abs(landContainerRatio / imageRatio - 1) ? 'portrait' : 'landscape';
  }

  public crops = ['Small9by16', 'Small9by16Vertical', 'AvatarSmall'];
  public cropNames = {
    'Small9by16': '16:9',
    'Small9by16Vertical': '9:16',
    'AvatarSmall': 'Аватар',
  };

  public getCropUrl(crop: string): string {
    return `${this.Model.baseUrl}/img/${crop}/${this.Model.id}`
      + `?x=${this.centerX.toLocaleString('en', { maximumFractionDigits: 3 })}`
      + `&y=${this.centerY.toLocaleString('en', { maximumFractionDigits: 3 })}`;
  }

  public hkClosePress(that: any) {
    return (event: KeyboardEvent) => {
      event.stopPropagation();
      that.close();
      return true;
    };
  }

  public close() {
    var url = this.service.redirectUrl || 'home';
    this.router.navigate([url]);
  }

  isLoading: boolean = true;
  base64webcam: string;

  genBase64(webcam: WebCamComponent) {
    webcam.getBase64()
      .then(base => {
        this.base64webcam = base;
        this.base64webcam = base.substr(base.indexOf(',') + 1, base.length);
        this.service.save(this.base64webcam).then((data: IImageParams) => {
          this.Model = data;
          this.cameraVisible = false;
        });
      })
      .catch(e => console.error(e))
  }

  clearImage() {
    this.Model = null;
    this.cameraVisible = true;
  }

  saveImage() {
    const clientId = localStorage.getItem("idClientEdit");
    this.service.addToClient(clientId, this.Model.id).then((data: IImageParams) => {
      this.Model = data;
      this.cameraVisible = false;
      this.router.navigateByUrl(this.router.url.replace("image/00000000000000000000000000000000", `clubclients/${clientId}`));
    });
  }

  onCamError() {
    const timeMessage = 5000;
    console.log('WORK ERROR')

    setTimeout(() => {
      this.alertsService.alert.next({
        type: 'danger',
        message: 'Подключите камеру',
        header: 'Ошибка',
        position: 'bottom',
        timeout: timeMessage
      });
      this.router.navigateByUrl("../");
    }, 2000);

  }

  onCamSuccess() { this.isLoading = false; }

  public copyToClipboard() {
    const str = this.Model.baseUrl + '/img/crmCenterLg/' + this.Model.id
    const timeArea = document.createElement('textarea');

    document.body.appendChild(timeArea);
    timeArea.value = str;
    timeArea.select();
    document.execCommand("copy");
    document.body.removeChild(timeArea);

    this.alertsService.alert.next({
      type: 'success',
      message: 'Cсылка на картинку скопирована',
      header: 'Успех',
      position: 'top',
      timeout: 2000
    });
  }
}
