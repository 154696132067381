export class CancelAllClassReq {
  baseDate: string;
  mode: string;
  isFilters: boolean;
  groupIds: string[];
  courseIds: string[];
  coachIds: string[];
  roomIds: string[];
  trainingCategoryIds: string[];
  levels: string[];
}
