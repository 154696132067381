import { Component, Injector } from '@angular/core';

import { TableListComponent } from 'app/common/table-list/table-list.component';
import { UsersService } from './services/users.service';
import { IAccount } from 'app/common/models/account.model';
import { UserRole } from 'app/common/models/roles.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent extends TableListComponent<IAccount> {
  public static readonly componentName: string = 'UsersComponent';

  public roles = { ...UserRole };

  constructor(
    protected service: UsersService,
    injector: Injector,
  ) {
    super(injector, service);
  }

  getUserName(item) {
    return `${item.firstName} ${item.lastName}`;
  }
}
