<div class="" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit" *ngIf="!isReadonly">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>

  <div class="row__field" [hidden]="enableEdit">
    <div class="field wysiwyg" (click)="onBeginEdit()" *ngIf="displayData">
      <span class="dark-gray">
        {{displayData.firstName}} {{displayData.lastName}} ({{'тел: ' + displayData.phoneNumber}} {{'email: ' + displayData.email}})
      </span>
    </div>
    <div class="field wysiwyg" (click)="onBeginEdit()" *ngIf="!displayData">
      <span class='gray' *ngIf="selectData">[не указано]</span>
      <span class='gray' *ngIf="!selectData">загрузка...</span>
    </div>
  </div>

  <div class="row__field pd" [hidden]="!enableEdit" *ngIf="!isReadonly">
    <div class="row__field-tooltip" *ngIf="tooltip && tooltip.length">{{tooltip}}</div>
    <select [(ngModel)]="tempData" class="select">
      <option [ngValue]="null">не указано</option>
      <option *ngFor="let user of selectData" [ngValue]="user.id">
        {{user.firstName}} {{user.lastName}}
      </option>
    </select>
  </div>
</div>
