import { Route } from '@angular/router';
import { DashboardComponent } from 'app/components/dashboard/dashboard.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const dashboard: Route = {
  path: ':cityId/:clubNetId/:clubId/clubdashboard',
  component: DashboardComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Рабочий стол'}
};
