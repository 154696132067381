<div *ngIf="data">
  <table class="inner">
    <tr>
      <td>
        <select #typeSelect class="select select__long" [ngModel]="currentRange" (ngModelChange)="setRange($event)">
    <option *ngFor="let o of predefinedRanges; let i = index" [ngValue]="o"  >  
      {{o.title }}
    </option>
  </select>
      </td>
      <td *ngIf="typeSelect.selectedIndex == predefinedRanges.length - 1">
        <input class="input__text input__text--long additional" type="number" min="1" [ngModel]="data.duration" (ngModelChange)="setAliasedValue($event)">
      </td>
      <td *ngIf="typeSelect.selectedIndex == predefinedRanges.length - 1">
        <select class="select select__long additional" [ngModel]="data.durationUnit" (ngModelChange)="setAlias($event)">
      <option *ngFor="let o of predifinedAliases; let i = index" [value] = "o.durationUnit"  >
        {{o.title}}
      </option>
    </select>
      </td>
    </tr>
  </table>
</div>