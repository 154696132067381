import { Injectable, Injector } from '@angular/core';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IWidgetModel } from 'app/common/models/widgets.model';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class WidgetsService extends TableListService<IWidgetModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  getItemList(): Promise<IWidgetModel[]> {
    return this.apiV2Service.widgets.getByClub(this.routingParams.clubId);
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiV2Service.widgets.get(req.id);
  }

  save(model: IWidgetModel) {
    return this.apiV2Service.widgets.save(model);
  }

  create(model: IWidgetModel) {
    return this.save(model);
  }
}
