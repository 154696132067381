import { environment } from 'environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { Connection, Proxy, hubConnection } from 'signalr-no-jquery';
import { IClassRequest } from '../models/class-request.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardHub {

  public connectionEstablished: EventEmitter<Boolean>;
  public connectionExists: Boolean;
  public createNewRequest: EventEmitter<IClassRequest>;
  private chatConnection: Connection;
  private chat: Proxy;

  constructor(
  ) {
    this.connectionEstablished = new EventEmitter<Boolean>();
    this.connectionExists = false;
    this.createNewRequest = new EventEmitter<IClassRequest>();
    this.chatConnection = hubConnection(environment.api + "/signalr/", { useDefaultPath: false, logging: true });
    this.chat = this.chatConnection.createHubProxy('hubRequestDashboard');
    this.registerOnServerEvents();
  }

  public connectToDashboard(clubId: string) {
    this.startConnection(clubId);
  }

  public disconnect() {
    this.chatConnection.stop();
  }


  private async startConnection(clubId: string): Promise<void> {
    if (this.connectionExists) {
      this.chatConnection.stop();
    }
    try {
      await this.chatConnection.start({ transport: 'serverSentEvents' });
      await this.chat.invoke("subscribeClubDashboard", { clubId });
      this.connectionEstablished.emit(true);
      this.connectionExists = true;
    }
    catch (error) {
      console.log('*** Could not connect ' + error);
      this.connectionEstablished.emit(false);
    }
  }

  private registerOnServerEvents(): void {
    this.chat.off('createNewRequest', this.onCreateRequest);
    this.chat.on('createNewRequest', this.onCreateRequest);
  }

  private onCreateRequest = (data: IClassRequest) => {
    this.createNewRequest.emit(data)
  };

}
