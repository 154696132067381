export enum IConflictType {
    /// Нет конфликта.
    None = 0,

    /// Время занятия за рамками часов работы.
    ScheduleTime = 1,

    /// Обязательный тренер не задан.
    RequiredCoach = 2,

    /// Недопустимый тренер.
    CoachLimitation = 4,

    /// Недопустимый зал.
    RoomLimitation = 8
}
