<span class="table-cell__mobile-title"><b>Кол-во занятий</b></span>
<div *ngIf="data != null" style="width: 100%;">
  <div *ngIf="!isUnlimited()">
    <input [appRestrictInput]="{min:1, max:9999}" type="text" [ngModel]="data" class="input__text input__text--long" (ngModelChange)="onChange($event)">
  </div>
  <div *ngIf="isUnlimited()">
    <span class="full-text">Безлимит</span>
    <span class="short-text">&infin;</span>
  </div>
</div>
