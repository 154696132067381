import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IClubEventContactsData } from 'app/common/models/club-events.model';

@Component({
  selector: 'app-club-event-contacts-table',
  templateUrl: './club-event-contacts-table.component.html',
  styleUrls: ['./club-event-contacts-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ClubEventContactsTableComponent),
    multi: true
  }]
})
export class ClubEventContactsTableComponent implements ControlValueAccessor, OnInit {

  constructor() { }

  public data: IClubEventContactsData = { chat: false, phone: '', whatsapp: '', telegram: '' };
  public flag: { [Property in keyof IClubEventContactsData]: boolean } = { chat: false, phone: false, whatsapp: false, telegram: false };

  ngOnInit(): void {
  }

  public onChangeHandler() {
    console.log(this.data, this.flag);
    const _data: IClubEventContactsData = {
      chat: this.flag.chat,
      phone: this.flag.phone ? this.data.phone : null,
      whatsapp: this.flag.whatsapp ? this.data.whatsapp : null,
      telegram: this.flag.telegram ? this.data.telegram : null
    };
    this.propagateChange(_data);
  }

  private propagateChange: (any?: any) => void = () => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = { chat: obj.chat, phone: obj.phone, whatsapp: obj.whatsapp, telegram: obj.telegram };
      this.propagateChange(obj);
    }
    else {
      this.data = { chat: false, phone: '', whatsapp: '', telegram: '' };
    }
    this.flag = { chat: this.data.chat, phone: !!this.data.phone, whatsapp: !!this.data.whatsapp, telegram: !!this.data.telegram };
  }

}
