import { Directive, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';



import { BrowserStateService } from 'app/common/services/browser-state.service';

@Directive( {
  selector: '[appClickOutside]'
} )

export class ClickOutsideDirective implements OnInit {

  @Output( 'clickOutside' ) clickOutside: EventEmitter<Object>;

  constructor(
    private _elRef: ElementRef,
    private browserStateService: BrowserStateService
  ) {
    this.clickOutside = new EventEmitter();
  }

  ngOnInit () {
    this.browserStateService.clickObserver
      .subscribe(( event: MouseEvent ) => {
        this.onGlobalClick( event );
      } );
  }

  onGlobalClick ( event: MouseEvent ) {
    if ( event instanceof MouseEvent ) {
      if ( this.isDescendant( this._elRef.nativeElement, event.target ) === true ) {
        this.clickOutside.emit( {
          target: ( event.target || null ),
          isInside: true
        } );
      } else {
        this.clickOutside.emit( {
          target: ( event.target || null ),
          isInside: false
        } );
      }
    }
  }

  isDescendant ( parent, child ) {
    let node = child;
    while ( node !== null ) {
      if ( node === parent ) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
}
