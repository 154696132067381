import { Component, Injector } from '@angular/core';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { ICityModel } from 'app/common/models/city-model';
import { CityService } from 'app/common/services/city.service';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent extends TableListComponent<ICityModel> {

  constructor(
    protected service: CityService,
    injector: Injector
  ) {
    super(injector, service)
  }

}
