import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../form-controls-core/value-acessor-base';

@Component({
    selector: 'app-form-select',
    templateUrl: 'form-select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormSelectComponent),
        multi: true
    }]
})
export class FormSelectComponent extends ValueAccessorBase<string> {

    @Input()
    options: { name: string, value: string }[];

    constructor() {
        super();
    }
}
