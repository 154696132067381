import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { CitiesComponent } from 'app/components/cities/cities.component';
import { CityEditComponent } from 'app/components/cities/city-edit/city-edit.component';

export const citiesRoute: Route = {
    component: CitiesComponent,
    path: ':cityId/cities',
    canActivate: [AuthGuard],
    data: { title: 'Города' },
}

export const cityEditRoute: Route = {
    path: ':cityId/cities/:id',
    component: CityEditComponent,
    canActivate: [AuthGuard],
    data: { title: 'Редактирование города' }
}
