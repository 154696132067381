import { FormControl } from '@angular/forms';

const d2 = '(\\d{2})';

export const timeValidator = () => {
    return (c: FormControl) => {
        let valid = true;
        const regex = new RegExp(`^${d2}:${d2}$`);
        // чч:мм

        const match = regex.exec(c.value);

        if (!match) {
            valid = false;
        } else {
            const h = parseInt(match[1], 10);
            const m = parseInt(match[2], 10);

            if (h < 0 || h >= 24 || m < 0 || m >= 60) {
                valid = false;
            }
        }

        return valid ? null : {
            timeValidator: {
                valid: false
            }
        };
    }
}
