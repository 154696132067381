<div style="display: contents" *ngIf="!element?.isEnableMode">
  <td class="table-cell {{element?.status.toLowerCase()}}">
    <span class="table-cell__mobile-title">Название</span>
    <div class="desc field" [class.link]="element?.classId">
      <span [innerHTML]="element?.title" (click)="openPageClass(element)"></span>
    </div>
  </td>
  <td class="table-cell {{element?.status.toLowerCase()}}">
    <span class="table-cell__mobile-title">Начислено</span>
    <div class="desc field">
      <div>
        <b><span>{{element.total.toFixed(2)}}</span></b>
        <div *ngFor="let d of paramDetails()" style="margin-top: 10px; margin-bottom: 10px;">
          {{d.name}}: {{d.value}}
        </div>
      </div>
      <div *ngIf="element?.paymentTypeAlias === 'salary'">
        <div *ngFor="let d of params.details" style="margin-top: 10px; margin-bottom: 10px;">
          с {{d.dateFrom}} по {{d.dateTo}} - {{d.total.toFixed(2)}} (оклад {{d.salary.toFixed(2)}})
        </div>
      </div>
    </div>
  </td>

  <td class="table-cell {{element?.status.toLowerCase()}}">
    <span class="table-cell__mobile-title">Способ начисления</span>
    <div class="desc field">
      <span>{{ coachSalaryCalculators[selectedAlias].aliasDisplayName}}</span>
    </div>
  </td>
</div>
<div style="display: contents;" *ngIf="element?.isEnableMode">
  <td class="table-cell" colspan="3">
    <div class="edit-container">
      <div class="row">
        <div class="edit-params col-xs-12 mt-3 mb-3">
          <span *ngFor="let p of allParamsInfo(); index as i" class="param">{{p.name}}: <b>{{p.value}}<span *ngIf="i != allParamsInfo().length - 1">, </span></b></span>
        </div>
        <div class="row__field col-xs-12 col-sm-6 col-md-4">
          <div class="box-title small">Cпособ расчета</div>
          <select [(ngModel)]="selectedAlias" class="select select__long mb-4"
            (change)="changeAlias($event.target.value)">
            <option *ngFor="let item of aliases" value="{{item}}">
              {{coachSalaryCalculators[item].aliasDisplayName}}
            </option>
          </select>
        </div>
        <hr>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <ng-container>
            <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted" *ngFor="let param of aliasParams">
              <div class="form-group">
                <div class="box-title small">{{param.displayName}} <span class="star ng-star-inserted">*</span></div>
                <div class="row__field">
                  <input class="form-control input__text input__text--long" name={{param.name}} type="{{param.type}}"
                    formControlName={{param.formControlName}} value={{param.value}} (input)="param.type === 'number' && setTwoNumberDecimal($event)" />
                  <label class="field-validation-error"
                    *ngIf="form.get(param.name).touched && form.get(param.name).invalid">Это поле обязательно</label>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
              <div class="form-group">
                <div class="box-title small">Статус</div>
                <div class="row__field">
                  <ng-container>
                    <select [ngModel]="element.status" formControlName="status" class="select select__long"
                      name="status">
                      <option [value]="'Published'">Опубликовано</option>
                      <option [value]="'Deleted'">Удалено</option>
                      <option [value]="'Hold'">Корректировка</option>
                    </select>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <button class="btn btn-primary btn-small" (click)="save()" [disabled]="form.invalid">
              <span class="btn-primary__desc">Сохранить</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </td>
</div>
