<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngClass]="{'content__header_title--short':Model.id}">
    <span class="content__header-title-value content__header-title-value_default" *ngIf="Model.id">
      <ng-container>
        О клубе
      </ng-container>
    </span>

    <ng-container *ngIf="!Model.id">
      <div class="content__back" (click)="close()">
        <span class="left-arrow">
          <span class="left-arrow__top"></span>
          <span class="left-arrow__bottom"></span>
        </span>
        <span class="content__header-title-value">Создание клуба</span>
      </div>
      <div>
        <a class="content__header-link" (click)="close()">
          Закрыть
          <span class="key">
            <span app-key-help [key]="'close'"></span>
          </span>
        </a>
      </div>
    </ng-container>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form">
    <div>
      <app-text-edit myScrollTo formControlName="name" [ngModel]="Model.name" [(data)]="Model.name" [title]="'Название'" [placeholder]="'[Название клуба]'"
        [validationMessage]="isSubmited ? fieldErrors.name : ''" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!isEditAvailable"
        [type]="'textarea'">
      </app-text-edit>
      <div class="clearfix"></div>
    </div>

    <div *ngIf="_isPermit('clubNet')">
      <app-clubnet myScrollTo [(data)]="Model.clubNet" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!_isPermit('clubNet_Editable')">
      </app-clubnet>
      <div class="clearfix"></div>
    </div>
    <div *ngIf="_isPermit('payments')">
      <!-- т.к. payments - это не одно поле в модели, а сразу несколько, приходится затаскивать в компонент целиком всю модель,
  но нужно следить, чтобы она обновлялась при изменении других полей,
  для этого нужно переопределить onBeginEdit в компоненте -->
      <app-payments myScrollTo [(data)]="Model" (onFinishEdit)="finishEditIfPublished()" [title]="'Типы оплаты'" [isReadonly]="!isEditAvailable"></app-payments>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-working-time-toggle myScrollTo [(data)]="Model.workingTime" [title]="'Часы работы'" (onFinishEdit)="finishEditIfPublished()"
        [isReadonly]="!isEditAvailable"></app-working-time-toggle>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-text-edit myScrollTo [(data)]="Model.description" [tooltip]="'Для кого? Что предлагаете? (2-3 предложения)'" [title]="'Краткое описание'"
        [placeholder]="'1-2 предложения: в чем ваша сила?'" [type]="'textarea'" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!isEditAvailable"></app-text-edit>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-text-edit myScrollTo [(data)]="Model.descriptionExtented" [tooltip]="'100 слов о том, за что ценят ваш клуб клиенты :)'"
        (onFinishEdit)="finishEditIfPublished()" [placeholder]="'100 вдохновляющих слов о клубе'" [title]="'Подробное описание'"
        [type]="'ckeditor'" maxlength="650" [isReadonly]="!isEditAvailable" [customConfigForCkeditor]="configDetailedDescription"></app-text-edit>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-text-edit myScrollTo [(data)]="Model.note" [title]="'Это важно:'" [placeholder]="" [type]="'textarea'" (onFinishEdit)="finishEditIfPublished()"
        [isReadonly]="!isEditAvailable"></app-text-edit>
      <div class="clearfix"></div>
    </div>

    <div *ngIf="isAdmin">
      <app-text-edit myScrollTo [(data)]="Model.instagramMarker" [tooltip]="'Тег для инстаграма'" (onFinishEdit)="finishEditIfPublished()"
        [title]="'Тег инстаграма'" [type]="'input'" [isReadonly]="!isEditAvailable">
      </app-text-edit>
      <div class="clearfix"></div>
    </div>

    <div *ngIf="isAdmin">
      <app-instagram-type myScrollTo [(data)]="Model.instagramMarkerType" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!isEditAvailable"></app-instagram-type>
      <div class="clearfix"></div>
    </div>

    <div>
      <!-- тоже несколько полей в модели, см. payments -->
      <app-coords-edit myScrollTo [(data)]="Model" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!isEditAvailable" [title]="'Координаты на карте'"></app-coords-edit>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-contacts [(data)]="Model.contacts" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!isEditAvailable" [title]="'Контакты'"></app-contacts>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-legal-info [(data)]="Model.legalInfo" (onFinishEdit)="finishEditIfPublished()" [isReadonly]="!isEditAvailable" [title]="'Юридическая информация для онлайн-оплат'"></app-legal-info>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-text-edit myScrollTo [(data)]="Model.offerRulesClub"
                     (onFinishEdit)="finishEditIfPublished()" [title]="'Ссылки на правила и оферту клуба для онлайн-оплат'"
                     [type]="'ckeditor'" [isReadonly]="!isEditAvailable"></app-text-edit>
      <div class="clearfix"></div>
    </div>

    <div>
      <app-gallery-toggle [(data)]="Model.medias" [multipleUpload]="true" [tooltip]="'Загрузите в альбом сразу все фотографии и выберите обложку'" (onFinishEdit)="finishEditIfPublished()"
        [text]="'[Нет фото]'" [title]="'Фотогалерея'" (onItemOpen)="openImage($event)"></app-gallery-toggle>
      <div class="clearfix"></div>
    </div>
  </form>

  <div *ngIf="Model.id === null">
    <br>
    <br>
    <br>
    <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
      <span class="btn-primary__desc">
        <span app-key-help [key]="'saveText'"></span>
        <span app-key-help mobile-hidden [key]="'saveHK'"></span>
      </span>
    </button>
  </div>
</div>
