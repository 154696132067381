import { Injector, Component } from '@angular/core';

import { TableListComponent } from 'app/common/table-list/table-list.component';
import { ClubNetsService } from './services/clubnets.service';
import { IClubNetModel } from 'app/common/models/clubnet.model';

@Component({
  selector: 'app-clubnets',
  templateUrl: './clubnets.component.html',
  styleUrls: ['./clubnets.component.scss']
})
export class ClubNetsComponent extends TableListComponent<IClubNetModel> {
  constructor(injector: Injector, protected service: ClubNetsService) {
    super(injector, service);
  }
}
