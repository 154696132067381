<div class="box-title">
    <span class="box-title__name">Заявки на занятие</span>
    <span class="checkbox-wrap">
      <input type="checkbox" class="checkbox__slide" id="withoutNotification" [(ngModel)]="withoutNotification">
      <label for="withoutNotification" class="checkbox__slide_label">Без уведомлений клиенту</label>
    </span>
</div>
<div #requestsRef></div>
<ng-container *ngIf="requests && requests.length">
  <div class="info-text" *ngIf="hasRequestWithoutClient">
      <strong>Для некоторых заявок не найден клиент.</strong>
      <br><br> Начните вводить имя или номер телефона, чтобы записать на заявку существующего клиента.
      <br> Оставьте поле пустым, чтобы создать нового клиента с данными из заявки.
  </div>

  <div class="info-text" *ngIf="hasRequestOverCapacity">
    <div class="info-text__box">
      <span class="btn btn-clear active warn legend">
        <span class="icon-check"><span class="icon-check__left"></span><span class="icon-check__right"></span></span>
      </span>
      <span style="margin-left:10px"></span>Число заявок, поступивших на эти занятия, превышает число свободных мест
    </div>
  </div>

    <div class="table rowHover class-requests">
      <div class="table-row_head">
        <div class="table-cell table-title-cell">Клиент/Заявка</div>
        <div class="table-cell table-title-cell">Абонемент</div>
        <div class="table-cell table-title-cell">Подтвердить?</div>
      </div>

        <div class="table-row" *ngFor="let request of requests" [style.backgroundColor]="request.fromType == 'app' && !request.classId ? 'rgba(240, 98, 146, 0.1)':''">
          <div class="table-cell user">
            <div class="user__data_wrap">
              <a *ngIf="request.userInfo" target="_blank" class="table-link" href="{{clientsRoutePrefix}}/{{request.userInfo.clientId}}">
                <div class="avatar">
                  <div class="avatar__default">
                    <span class="sp sp-user sp-size"></span>
                  </div>
                  <img class="avatar__img" *ngIf="request.userInfo.primaryImageUrl"
                    [src]="request.userInfo.primaryImageUrl">
                </div>

                <div class="user-info">
                  <span class="user-name">{{request.userInfo.name}}</span>
                  <span class="user-phone">{{ request.phoneNumber }}</span>

                  <ng-container *ngIf="request.fromType; else crmIcon">
                    <div class="icon-from-type">
                      <span style="font-size: 12px" *ngIf="request.fromType == 'app' && request.classId"
                        class="sp sp-sp sp-size" title="Запись из приложения"><span class="icon__label">из приложения</span></span>
                      <span *ngIf="request.fromType == 'app' && !request.classId" class="sp sp-user sp-size" title="Я в клубе"><span class="icon__label">я в клубе</span></span>
                      <span *ngIf="request.fromType == 'wg'" class="sp sp-widget sp-size" title="Запись из виджета"><span class="icon__label">из виджета</span></span>
                    </div>
                  </ng-container>
                  <ng-template #crmIcon>
                    <div class="icon-from-type">
                      <span class="sp sp-clubs sp-size" title="Запись из crm"><span class="icon__label">из расписания</span></span>
                    </div>
                  </ng-template>
                </div>
              </a>

              <div *ngIf="!request.userInfo">
                <app-client-search [(ngModel)]="request.clientId"></app-client-search>

                <ng-container *ngIf="request.fromType; else crmIcon">
                  <div class="icon-from-type">
                    <span style="font-size: 12px" *ngIf="request.fromType == 'app' && request.classId"
                      class="sp sp-sp sp-size" title="Запись из приложения"><span class="icon__label">из приложения</span></span>
                    <span *ngIf="request.fromType == 'app' && !request.classId" class="sp sp-user sp-size" title="Я в клубе"><span class="icon__label">я в клубе</span></span>
                    <span *ngIf="request.fromType == 'wg'" class="sp sp-widget sp-size" title="Запись из виджета"><span class="icon__label">из виджета</span></span>
                  </div>
                </ng-container>
                <ng-template #crmIcon>
                  <div class="icon-from-type">
                    <span class="sp sp-clubs sp-size" title="Запись из crm"><span class="icon__label">из расписания</span></span>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="user__request_wrap">
              <ng-container *ngIf="request.fromType; else crmIcon">
                <div class="icon-from-type_mob">
                  <span style="font-size: 12px" *ngIf="request.fromType == 'app' && request.classId"
                    class="sp sp-sp sp-size" title="Запись из приложения"></span>
                  <span *ngIf="request.fromType == 'app' && !request.classId" class="sp sp-user sp-size" title="Я в клубе"></span>
                  <span *ngIf="request.fromType == 'wg'" class="sp sp-widget sp-size" title="Запись из виджета"></span>
                </div>
              </ng-container>
              <ng-template #crmIcon>
                <div class="icon-from-type_mob">
                  <span class="sp sp-clubs sp-size" title="Запись из crm"></span>
                </div>
              </ng-template>


              <div class="request-info">
                <span class="request-name">{{request.firstName}} {{request.lastName}}</span>
                <span class="request-info_string">
                  <span class="request-birthday" *ngIf="request.birthday">{{ request.birthday }}</span>
                  <span class="request-email">{{ request.email }}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="table-cell">
            <div class="client-subscriptions" [formGroup]="subsControls">
              <mat-select class="select select__long select-wrap" formControlName="req-{{request.id}}" (selectionChange)="selectSubscriptionForClient(request.classVisitId, $event.value, request.id)">
                <mat-option [value]="guidEmpty">Без абонемента</mat-option>
                <mat-option *ngFor="let sub of request.subscriptions" [value]="sub.id">{{sub.name}} ({{sub.visitCounter}}/{{infinity(sub.visitCount)}}) {{SubscriptionExtraInfo(sub)}}</mat-option>
              </mat-select>
            </div>

            <div class="class__info">
              <a target="_blank" class="table-class-link" href="{{request | classLink}}">{{ getCourseName(request) }}</a>
              <a class="table-class-link" *ngIf="!request.classId && !request.prototypeId" [routerLink]="['../addclienttoclass']"
                [queryParams]="{requestId: request.id, subscriptionId: getSubscriptionId(request) }">
                Не&nbsp;указано<br>
              </a>
              <span class="class__start-time">
                <span class="sp sp-clock sp-size"></span> {{ request.startDateTime | date:'HH:mm / dd.MM.yy':'UTC' }}
              </span>
              <br *ngIf="request.room">
              <span target="_blank" class="class__start-time" *ngIf="request.room">
                <span class="sp sp-home sp-size"></span> {{request.room.name}}
              </span>
            </div>
          </div>


          <div class="table-cell table-cell_action table-cell_publish">
            <button class="btn btn-clear active class-requests_btn" [disabled]="request.pending" (click)="confirmClick(request)"
              [class.warn]="request.isOverCapacity">
              <span class="icon-check">
                <span class="icon-check__left"></span>
                <span class="icon-check__right"></span>
              </span>
            </button>
            <span class="mobile__label">Подтвердить</span>
            <button class="btn btn-clear active class-requests_btn" [disabled]="request.pending" (click)="deleteClick(request)">
              <span class="icon-cross">
                <span class="icon-cross__left"></span>
                <span class="icon-cross__right"></span>
              </span>
            </button>
            <span class="mobile__label">Отменить</span>
          </div>
        </div>
    </div>
</ng-container>

<div *ngIf="requests && !requests.length" class="content-infobox content-infobox--small">
  Пока заявок нет
</div>
