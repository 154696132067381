import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { CoachesService } from '../services/coaches.service';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import { IPayoutRatesModel, PaymentTypeAlias, PaymentTypeAliasDisplayName } from 'app/common/models/coach-rate.model';

@Component({
  selector: 'app-rate-element-add',
  templateUrl: './rate-element-add.component.html',
  styleUrls: ['./rate-element-add.component.scss']
})
export class RateElementAddComponent implements OnInit {
  public isEnableAdd: boolean = false;
  public form: FormGroup;
  @Input() coachId: string;
  @Output() result = new EventEmitter<IPayoutRatesModel>();

  constructor(
    protected coachesService: CoachesService,
    private alertsService: AlertsService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      paymentTypeAlias: new FormControl('', Validators.required),
      salary: new FormControl(0, Validators.required),
      personalClassRate: new FormControl(0, Validators.required),
      fixedClassRate: new FormControl(0, Validators.required),
      personInGroupRate: new FormControl(0, Validators.required),
      subscriptionPercentRate: new FormControl(0, Validators.required),
      forUnlimitedRate: new FormControl(0, Validators.required),
      effectiveSinceDate: new FormControl('', [Validators.required])
    });

    // --- выключаем все контролы ---
    this.form.get('fixedClassRate').disable();
    this.form.get('personInGroupRate').disable();
    this.form.get('personalClassRate').disable();
    this.form.get('subscriptionPercentRate').disable();
    this.form.get('forUnlimitedRate').disable();
  }

  public addRate() {
    this.isEnableAdd = true;
  }

  public closeAdd() {
    this.isEnableAdd = false;
  }

  public async save(): Promise<any> {
    let req: any;
    if (this.form.get('paymentTypeAlias').value == PaymentTypeAlias.subscription)
    {
      req = {
        paymentTypeAlias: this.form.get('paymentTypeAlias').value,
        salary: this.form.get('salary').value,
        fixedClassRate: this.form.get('forUnlimitedRate').value,               // ---|
        personalClassRate: this.form.get('personalClassRate').value,           // ---|--- в эти поля будут заносится данные из абонементов
        personInGroupRate: this.form.get('subscriptionPercentRate').value,
        effectiveSinceDate: this.form.get('effectiveSinceDate').value,
        coachId: this.coachId
      }
    }
    else
    {
      req = {
        paymentTypeAlias: this.form.get('paymentTypeAlias').value,
        salary: this.form.get('salary').value,
        personalClassRate: this.form.get('personalClassRate').value,
        fixedClassRate: this.form.get('fixedClassRate').value,
        personInGroupRate: this.form.get('personInGroupRate').value,
        effectiveSinceDate: this.form.get('effectiveSinceDate').value,
        coachId: this.coachId
      }
    }
    await this.coachesService.saveRate(req).then(async (e) => {
      if (e) {
        this.isEnableAdd = false;
        this.result.emit(e);
        this.alertsService.alert.next({
          type: 'success',
          message: 'Транзакция сохранена',
          header: 'Успех',
          position: 'top',
          timeout: 2000
        });
      } else {
        console.error(e);
      }
    });
  }

  public changeAlias(alias){
    this.form.get('salary').setValue(0);
    if (alias == PaymentTypeAlias.class)
    {
      this.form.get('fixedClassRate').enable();
    }
    else
    {
      this.form.get('fixedClassRate').setValue(0);
      this.form.get('fixedClassRate').disable();
    }

    if (alias == PaymentTypeAlias.personInGroup)
    {
      this.form.get('personInGroupRate').enable();
      //this.form.get('personalClassRate').enable();
    }
    else
    {
      this.form.get('personInGroupRate').disable();
      //this.form.get('personalClassRate').disable();
      this.form.get('personInGroupRate').setValue(0);
      //this.form.get('personalClassRate').setValue(0);
    }

    if (alias == PaymentTypeAlias.subscription)
    {
      this.form.get('subscriptionPercentRate').enable();
      this.form.get('forUnlimitedRate').enable();
    }
    else
    {
      this.form.get('subscriptionPercentRate').disable();
      this.form.get('forUnlimitedRate').disable();
      this.form.get('subscriptionPercentRate').setValue(0);
      this.form.get('forUnlimitedRate').setValue(0);
    }
  }

  public readonly _PaymentTypeAliases = Object.keys(PaymentTypeAlias);
  public readonly PaymentTypeAliasDisplayName = PaymentTypeAliasDisplayName;
}
