import { Route } from '@angular/router';
import { ScheduleComponent } from 'app/components/schedule/schedule.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { ClassItemEditComponent } from 'app/components/schedule/class-item-edit/class-item-edit.component';

export const timeTableRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubschedule/:startDate',
  component: ScheduleComponent,
  canActivate: [AuthGuard],
  data: { title: 'Расписание занятий' }
};

export const classItemCreateRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubschedule/:_/create/:startDate/:startTime',
  component: ClassItemEditComponent,
  canActivate: [AuthGuard],
  data: { title: 'Редактирование занятия' }
};

export const classItemRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubschedule/:_/:id',
  component: ClassItemEditComponent,
  canActivate: [AuthGuard],
  data: { title: 'Редактирование занятия' }
};

export const classItemVirtualRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubschedule/:_/virtual/:prototypeId/:startDate',
  component: ClassItemEditComponent,
  canActivate: [AuthGuard],
  data: { title: 'Редактирование занятия' }
};
