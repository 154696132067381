import { Route } from '@angular/router';
import { GroupsComponent } from 'app/components/groups/groups.component';
import { GroupEditComponent } from 'app/components/groups/group-edit/group-edit.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const groupsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubgroups',
  component: GroupsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Группы' }
};

export const groupRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubgroups/:id',
  component: GroupEditComponent,
  canActivate: [ AuthGuard ],
  pathMatch: 'full',
  data: { title: 'Редактирование группы' }
};