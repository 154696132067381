import { DicPipe } from './common/pipes/dic.pipe';
import { NumEndingPipe } from './common/pipes/num-ending.pipe';
import { DurationPipe } from './common/pipes/duration.pipe';
import {NgModule, ErrorHandler, Injectable, LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu);
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, RequestOptions, XHRBackend } from '@angular/http';
import { Router } from '@angular/router';

import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MAT_DATE_FORMATS, MatDateFormats, DateAdapter } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MatRippleModule} from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { HotkeyModule } from 'custom-modules/angular2-hotkeys';

import { AppRoutingModule } from 'app/routing/app-routing.module';
import { CKEditorModule } from 'ng2-ckeditor';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DynamicModule } from 'ng-dynamic-component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { AuthService } from 'app/common/services/auth.service';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { HttpService } from 'app/common/services/http.service';
import { ApiService } from 'app/common/services/api.service';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { CityService } from 'app/common/services/city.service';
import { ColorService } from 'app/common/services/color.service';
import { PersistenceService } from 'app/common/services/persistence.service';
import { TipsService } from 'app/common/services/tips.service';
import { BrowserStateService } from 'app/common/services/browser-state.service';
import { ContextService } from 'app/common/services/context.service';


import { KeysPipe } from 'app/common/pipes/keys.pipe';
import { Day2StringPipe } from 'app/common/pipes/day2string.pipe';
import {
  Date2StringPipe,
  DateInterval2StringPipe
} from 'app/common/pipes/date-to-string.pipe';
import { Time2StringPipe } from 'app/common/pipes/time-to-string.pipe';
import { Obj2StringPipe } from 'app/common/pipes/object-name.pipe';
import { Status2BoolPipe, Bool2StatusPipe } from 'app/common/pipes/status.pipe';
import { Level2StringPipe } from 'app/common/pipes/level.pipe';
import { Time2wordsPipe } from 'app/common/pipes/time2words';
import { WidgetTypePipe } from 'app/common/pipes/widget-type.pipe';
import { RoleInfoPipe } from './common/pipes/role.pipes';
import { CityNamePipe } from './common/pipes/city-name';

import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { HeaderComponent } from 'app/common/components/header/header.component';
import { FooterComponent } from 'app/common/components/footer/footer.component';
import { SpErrorHandler } from 'app/common/error-handler';
import { AsideComponent } from 'app/common/components/aside/aside.component';
import { WorkingTimeToggleComponent } from 'app/common/components/working-time/toggle/working-time.component';
import { WorkingTimeCVAComponent } from 'app/common/components/working-time/cva/working-time.component';
import { GalleryToggleComponent } from 'app/common/components/gallery/toggle/gallery.component';
import { GalleryCVAComponent } from 'app/common/components/gallery/cva/gallery.component';
import { ColorPickerComponent } from 'app/common/components/color-picker/color-picker.component';
import { StatusPickerComponent } from 'app/common/components/status-picker/status-picker.component';
import { SupportComponent } from 'app/common/components/support/support.component';
import { ObjectPickerComponent } from 'app/common/components/object-picker/object-picker.component';
import { AlertsComponent } from 'app/common/components/alerts/alerts.component';
import { OptionSelectorComponent } from 'app/common/components/option-selector/option-selector.component';
import { GlobalPreloaderComponent } from 'app/common/components/global-preloader/global-preloader.component';
import { MultipleCheckboxComponent } from 'app/common/components/multiple-checkbox/multiple-checkbox.component';
import { DurationPickerComponent } from 'app/common/components/duration-picker/duration-picker.component';
import { VisitCountPickerComponent } from 'app/common/components/visit-count-picker/visit-count-picker.component';
import { CategorySelectComponent } from 'app/common/components/category-select/category-select.component';
import { KeyHelpComponent } from 'app/common/components/key-help/key-help.component';
import { TextEditComponent } from 'app/common/components/text-edit/text-edit.component';
import { CoordsEditComponent } from 'app/common/components/coords-edit/coords-edit.component';
import { SelectEditComponent } from 'app/common/components/select-edit/select-edit.component';
import { SupportService } from 'app/common/components/support/support.service';

import { ScrollToDirective, ScrollToService } from 'app/common/directives/scroll-to.directive';
import { FocusDirective } from 'app/common/directives/focus.directive';
import { RestrictInputDirective } from 'app/common/directives/restrict-input.directive';
import { ClickOutsideDirective } from 'app/common/directives/click-outside.directive';
import { MultiLineSelectHeightDirective } from 'app/common/directives/multi-line-select-heigth.directive';

import { AppComponent } from 'app/app.component';
import { ClubnetComponent } from 'app/components/clubs/club-edit/clubnet/clubnet.component';
import { InstagramTypeComponent } from 'app/components/clubs/club-edit/instagram/instagram.component';
import { ClubEditComponent } from 'app/components/clubs/club-edit/club-edit.component';
import { PaymentsComponent } from 'app/components/clubs/club-edit/payments/payments.component';
import { ContactsComponent } from 'app/components/clubs/club-edit/contacts/contacts.component';
import { LoginComponent } from 'app/components/login/login.component';
import { LoginGuard } from 'app/components/login/services/login.guard.service';
import { CoachesService } from 'app/components/coaches/services/coaches.service';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { GroupsService } from 'app/components/groups/services/groups.service';
import { ClubroomsService } from 'app/components/clubrooms/services/clubrooms.service';
import { CoursesService } from 'app/components/courses/services/courses.service';
import { HomeComponent } from 'app/components/home/home.component';
import { CoursesComponent } from 'app/components/courses/courses.component';
import { CourseEditComponent } from 'app/components/courses/coourse-edit/course-edit.component';
import { CoachesComponent } from 'app/components/coaches/coaches.component';
import { CoachSalaryCalcComponent } from 'app/components/coaches/coach-salary-calc/coach-salary-calc.component';
import { CoachRegistryCreateComponent } from 'app/components/coaches/coach-salary-calc/coachregistry-create/coachregistry-create.component';
import { CoachRegistryEditComponent } from 'app/components/coaches/coach-salary-calc/coachregistry-edit/coachregistry-edit.component';
import { CoachRegistryElementEditComponent } from 'app/components/coaches/coach-salary-calc/coachregistry-element-edit/coachregistry-element-edit.component';
import { CoachRegistryElementAddComponent } from 'app/components/coaches/coach-salary-calc/coachregistry-element-add/coachregistry-element-add.component';
import { PayoutRatesComponent } from 'app/components/coaches/payout-rates/payout-rates.component';
import { RateElementAddComponent } from 'app/components/coaches/rate-element-add/rate-element-add.component';
import { RateElementEditComponent } from 'app/components/coaches/rate-element-edit/rate-element-edit.component';
import { DatePickerRatesComponent } from 'app/components/coaches/date-picker-rates/date-picker-rates.component';
import { PayoutComponent } from 'app/components/coaches/coach-salary-calc/payout/payout.component';
import { ScheduleComponent } from 'app/components/schedule/schedule.component';
import { PricesComponent } from 'app/components/prices/prices.component';
import { ClientsComponent } from 'app/components/clients/clients.component';
import { GroupsComponent } from 'app/components/groups/groups.component';
import { NewsComponent } from 'app/components/news/news.component';
import { EventsComponent } from 'app/components/events/events.component';
import { SettingsComponent } from 'app/components/settings/settings.component';
import { DocumentsComponent } from 'app/components/documents/documents.component';
import { ServicesComponent } from 'app/components/services/services.component';
import { PasswordComponent } from 'app/components/settings/password/password.component';
import { EmailComponent } from 'app/components/settings/email/email.component';
import { UsersComponent } from 'app/components/users/users.component';
import { CoachEditComponent } from 'app/components/coaches/coach-edit/coach-edit.component';
import { ClubroomsComponent } from 'app/components/clubrooms/clubrooms.component';
import { ClubroomEditComponent } from 'app/components/clubrooms/clubroom-edit/clubroom-edit.component';
import { ClassItemComponent } from 'app/components/schedule/class-item/class-item.component';
import { ClubsComponent } from 'app/components/clubs/clubs.component';
import { ResetPasswordComponent } from 'app/components/reset-password/reset-password.component';
import { ClubNetsComponent } from 'app/components/clubnets/clubnets.component';
import { ClubnetEditComponent } from 'app/components/clubnets/clubnet-edit/clubnet-edit.component';
import { UserEditComponent } from 'app/components/users/user-edit/user-edit.component';
import { ClubNetsService } from 'app/components/clubnets/services/clubnets.service';

import { ClubsService } from 'app/components/clubs/services/clubs.service';
import { SettingsService } from 'app/components/settings/services/settings.service';
import { UsersService } from 'app/components/users/services/users.service';
import { ScheduleService } from 'app/components/schedule/services/schedule.service';

import { RestorePasswordComponent } from 'app/components/restore-password/restore-password.component';
import { SubscriptionsComponent } from 'app/components/subscriptions/subscriptions.component';
import { SubscriptionEditComponent } from 'app/components/subscriptions/subscription-edit/subscription-edit.component';
import { SubscriptionService } from 'app/components/subscriptions/services/subscription.service';
import { VariantEditComponent } from 'app/components/subscriptions/variants/variant-edit/variant-edit.component';
import { VariantsComponent } from 'app/components/subscriptions/variants/variants.component';
import { ClassItemEditComponent } from 'app/components/schedule/class-item-edit/class-item-edit.component';
import { ClubProfileComponent } from 'app/components/clubprofile/clubprofile.component';
import { ClientsEditComponent } from 'app/components/clients/clients-edit/clients-edit.component';
import { GroupEditComponent } from 'app/components/groups/group-edit/group-edit.component';
import { UserSearchComponent } from 'app/shared-components/user-search/user-search.component';
import { ClientSearchComponent } from 'app/shared-components/client-search/client-search.component';
import { ClubSearchComponent } from 'app/shared-components/club-search/club-search.component';
import { ClubsprofileService } from 'app/components/clubprofile/clubprofile.service';
import { DashboardComponent } from 'app/components/dashboard/dashboard.component';
import { AbonementListComponent } from 'app/components/clients/abonement-list/abonement-list.component';
import { SubscriptionSaleComponent } from 'app/components/subscriptions/subscription-sale/subscription-sale.component';
import { WidgetComponent } from 'app/components/widget/widget.component';
import { AddClientToClassComponent } from 'app/components/dashboard/add-client-to-class/add-client-to-class.component';
import { DashboardLinkComponent } from 'app/components/dashboard/link/link.component';
import { ClubMFImportComponent } from 'app/components/club-mf-import/mf-import.component';
import { ClubAnalyticsComponent } from 'app/components/clubanalytics/club-analytics.component';
import { ClubAnalyticsDetailComponent } from 'app/components/clubanalytics/clubanalytics-detail/club-analytics-detail.component';
import { ChatsComponent } from 'app/components/chats/chats.component';
import { ChatComponent } from 'app/components/chats/chat.component';
import { ChatService } from 'app/components/chats/chats.service';
import { DatePickerReportsComponent } from 'app/components/clubanalytics/date-picker-reports/date-picker-reports.component';
import { WidgetsComponent } from 'app/components/widgets/widgets.component';
import { WidgetsService } from 'app/components/widgets/widgets.service';
import { WidgetEditComponent } from 'app/components/widgets/widget-edit/widget-edit.component';
import { ThemeEditComponent } from 'app/components/widgets/theme-edit/theme-edit.component';
import { ThemesService } from 'app/components/widgets/themes.service';
import { CitiesComponent } from './components/cities/cities.component';
import { CityEditComponent } from './components/cities/city-edit/city-edit.component';
import { ClassRequestsComponent } from './components/dashboard/class-requests/class-requests.component';
import { ClubEventsComponent } from './components/club-events/club-events.component';
import { ClubEventService } from './components/club-events/club-events.service';
import { ClubEventEditComponent } from './components/club-events/club-event-edit/club-event-edit.component';
import { FormDatePickerComponent } from './shared-components/form-date-picker/form-date-picker.component';
import { FormTextBoxComponent } from './shared-components/form-text-box/form-text-box.component';
import { FormSelectComponent } from './shared-components/form-select/form-select.component';
import { FormTextEditorComponent } from './shared-components/form-text-editor/form-text-editor.component';
import { FormImageGalleryComponent } from './shared-components/form-image-gallery/form-image-gallery.component';
import { ExtensionSubscriptionComponent } from './components/extension-subscription/extension-subscription.component';
import { DatePickerComponent } from './components/extension-subscription/date-picker/date-picker.component';
import { SurchargeComponent } from './components/surcharge/surcharge.component';
import { FileUploadComponent } from './common/components/file-upload/file-upload.component';
import { EditImageComponent } from './shared-components/edit-image/edit-image.component';
import { MediaService } from './common/services/media.service';

import { NgxFileDropModule } from 'ngx-file-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DateTimePickerComponent } from './components/extension-subscription/date-time-picker/date-time-picker.component';
import { WebCamModule } from 'ack-angular-webcam';
import { CoachSelectComponent } from './shared-components/coach-select/coach-select.component';
import { RequestClassLinkPipe } from './common/pipes/request-classlink.pipe';
import { SoundPlayService } from './common/services/sound-play.service';
import { DropdownComponent } from './shared-components/dropdown/dropdown.component';
import { PreloaderService } from './common/services/preloader.service';
import { LocalPreloaderComponent } from './common/components/local-preloader/local-preloader.component';
import { DictionaryDataService } from './common/services/dictionary-data.service';
import { DictionaryComponent } from './components/settings/dictionary/dictionary.component';
import { DicService } from './common/services/dic.service';
import { ExcelService } from './common/services/excel.service';
import { CashAndCardPipe } from './common/pipes/cash-and-card.pipe';
import { ChatEditComponent } from './components/chats/chat-edit.component';
import { ClubSettingsComponent } from './components/club-settings/club-settings.component';
import { ChatEditService } from './components/chats/services/chat-edit.service';
import { OptionSelectorRadioComponent } from './common/components/option-selector-radio/option-selector-radio.component';
import { ChatMailingComponent } from './components/chats/chat-mailing.component';
import { PluralDirective } from './common/directives/plural.directive';
import { QRCodeModule } from 'angularx-qrcode';
import { PortalModule } from '@angular/cdk/portal';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RegistryComponent } from './components/registry/registry.component';
import { RegistryCreateComponent } from './components/registry/create/registry-create.component';
import { RegistryEditComponent } from './components/registry/edit/registry-edit.component';
import { DatePickerRegistryComponent } from './components/registry/date-picker-registry/date-picker-registry.component';
import { RegistryElementEditComponent } from './components/registry/registry-element-edit/registry-element-edit.component';
import {A11yModule} from '@angular/cdk/a11y';
import { RegistryElementAddComponent } from './components/registry/registry-element-add/registry-element-add.component';
import {CsvService} from './common/services/csv.service';
import { LegalInfoComponent } from './components/clubs/club-edit/legal-info/legal-info.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { CollectionEditComponent } from './components/collections/collection-edit/collection-edit.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatSelectModule} from '@angular/material/select';
import { MechanicSelectComponent } from './common/components/mechanic-select/mechanic-select.component';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import { CollectionDataComponent } from './components/collections/collestion-data/collection-data.component';
import { VisitEditComponent } from './components/clients/visit-edit/visit-edit.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CompactScheduleModeComponent } from './components/schedule/compact-schedule-mode/compact-schedule-mode.component';
import { InitialsPipe } from './common/pipes/initials.pipe';
import { FinanceComponent } from './components/finance/finance.component';
import {FinanceRegistryComponent} from './components/finance/registry/finance-registry.component';
import { FinanceRegistryElementsComponent } from './components/finance/finance-registry-elements/finance-registry-elements.component';
import {DayWeekPipe} from './common/pipes/day-week-pipe';
import { PermissionService } from './common/services/permission.service';
import { AccountingComponent } from './components/accounting/accounting.component';
import { AccountingElementComponent } from './components/accounting/accounting-element/accounting-element.component';
import { AccountingAddComponent } from './components/accounting/accounting-add/accounting-add.component';
import { GraphsGeneralComponent } from './common/components/graphs/graphs-general/graphs-general.component';
import { GraphSummaryComponent } from './common/components/graphs/graph-summary/graph-summary.component';
import { DayScheduleModeComponent } from './components/schedule/day-schedule-mode/day-schedule-mode.component';
import { ElectronicQueueComponent } from './components/electronic-queue/electronic-queue.component';
import {TxtService} from './common/services/txt.service';
import { ClubDownTimeComponent } from './components/club-down-time/club-down-time.component';
import { ClubDownTimeAddComponent } from './components/club-down-time/club-down-time-add/club-down-time-add.component';
import { ClubDownTimeElementComponent } from './components/club-down-time/club-down-time-element/club-down-time-element.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { CertificateStatusPipe } from './common/pipes/certificate-status.pipe';
import { FilterCertificatesPipe } from './common/pipes/filter-certificates.pipe';
import { ResourceQueueComponent } from './components/resource-queue/resource-queue.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatSortModule} from '@angular/material/sort';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatPaginatorIntl} from '@angular/material/paginator';
import { CustomPaginator } from 'app/components/clubanalytics/clubanalytics-detail/CustomPaginatorConfiguration';
import { ClientMergeItemsComponent } from './components/clients/client-merge-items/client-merge-items.component';
import { QueuedDashboardComponent } from './components/dashboard/queued-dashboard/queued-dashboard.component';
import { ClubEventTicketsTableComponent } from './components/club-events/club-event-tickets-table/club-event-tickets-table.component';
import { ClubEventTicketEditComponent } from './components/club-events/club-event-tickets-table/club-event-ticket-edit/club-event-ticket-edit.component';
import { ClubEventReqfildsTableComponent } from './components/club-events/club-event-reqfilds-table/club-event-reqfilds-table.component';
import { ClubEventContactsTableComponent } from './components/club-events/club-event-contacts-table/club-event-contacts-table.component';
import { WebhookComponent } from './components/webhook/webhook.component';
import {WebhookService} from './components/webhook/webhook.service';
import { WebhookEditComponent } from './components/webhook/webhook-edit/webhook-edit.component';
import {EventTypePipe} from './common/pipes/event-type.pipe';
import { QuickRecordComponent } from './components/clients/quick-record/quick-record.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CollectionsTypesPipe} from './common/pipes/collections-types.pipe';
import { CollectionsSlideComponent } from './components/collections/collections-slide/collections-slide.component';
import { FooterLoginComponent } from './components/footer-login/footer-login.component';
import { SuspendComponent } from './components/clients/suspend/suspend.component';


export function httpFactory(
  xhrBackend: XHRBackend,
  requestOptions: RequestOptions,
  router: Router,
  alertsService: AlertsService,
) {
  return new HttpService(xhrBackend, requestOptions, alertsService, router);
}

@Injectable() export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
  options = {
    cssProps: ''
  };
}

export const MY_FORMATS: MatDateFormats = {
  parse: {
      dateInput: 'DD.MM.YYYY',
  },
  display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AsideComponent,
    HomeComponent,
    CoursesComponent,
    CourseEditComponent,
    CoachesComponent,
    CoachSalaryCalcComponent,
    CoachRegistryCreateComponent,
    CoachRegistryEditComponent,
    CoachRegistryElementEditComponent,
    CoachRegistryElementAddComponent,
    PayoutRatesComponent,
    RateElementAddComponent,
    RateElementEditComponent,
    DatePickerRatesComponent,
    PayoutComponent,
    ScheduleComponent,
    PricesComponent,
    ClientsComponent,
    GroupsComponent,
    NewsComponent,
    EventsComponent,
    SettingsComponent,
    DocumentsComponent,
    ServicesComponent,
    WorkingTimeToggleComponent,
    WorkingTimeCVAComponent,
    GalleryToggleComponent,
    GalleryCVAComponent,
    PasswordComponent,
    EmailComponent,
    ColorPickerComponent,
    LoginComponent,
    UsersComponent,
    CoachEditComponent,
    StatusPickerComponent,
    SupportComponent,
    ClubroomsComponent,
    ClubroomEditComponent,
    ClassItemComponent,
    ClubsComponent,
    ObjectPickerComponent,
    AlertsComponent,
    ResetPasswordComponent,
    OptionSelectorComponent,
    ClubnetComponent,
    InstagramTypeComponent,
    ClubNetsComponent,
    ClubnetEditComponent,
    UserEditComponent,
    TextEditComponent,
    FileUploadComponent,
    ClubEditComponent,
    GlobalPreloaderComponent,
    PaymentsComponent,
    ContactsComponent,
    RestorePasswordComponent,
    SubscriptionsComponent,
    SubscriptionEditComponent,
    MultipleCheckboxComponent,
    VariantsComponent,
    VariantEditComponent,
    DurationPickerComponent,
    VisitCountPickerComponent,
    CategorySelectComponent,
    ClassItemEditComponent,
    KeyHelpComponent,
    ClubProfileComponent,
    ClientsEditComponent,
    GroupEditComponent,
    DashboardComponent,
    CoordsEditComponent,
    AbonementListComponent,
    SubscriptionSaleComponent,
    WidgetComponent,
    AddClientToClassComponent,
    DashboardLinkComponent,
    ClubMFImportComponent,
    SelectEditComponent,
    ClubAnalyticsComponent,
    ClubAnalyticsDetailComponent,
    ChatsComponent,
    ChatComponent,
    UserSearchComponent,
    ClientSearchComponent,
    ClubSearchComponent,
    DatePickerReportsComponent,
    FormDatePickerComponent,
    FormTextBoxComponent,
    FormSelectComponent,
    FormTextEditorComponent,
    FormImageGalleryComponent,
    WidgetsComponent,
    WidgetTypePipe,
    WidgetEditComponent,
    ThemeEditComponent,

    ClickOutsideDirective,
    ScrollToDirective,
    FocusDirective,
    RestrictInputDirective,
    MultiLineSelectHeightDirective,

    RequestClassLinkPipe,
    Day2StringPipe,
    DayWeekPipe,
    Status2BoolPipe,
    Bool2StatusPipe,
    Level2StringPipe,
    Obj2StringPipe,
    RoleInfoPipe,
    Date2StringPipe,
    Time2StringPipe,
    Time2wordsPipe,
    KeysPipe,
    NumEndingPipe,
    DurationPipe,
    DateInterval2StringPipe,
    CityNamePipe,
    EventTypePipe,
    CitiesComponent,
    CityEditComponent,
    ClassRequestsComponent,
    ClubEventsComponent,
    ClubEventEditComponent,
    ExtensionSubscriptionComponent,
    DatePickerComponent,
    SurchargeComponent,
    EditImageComponent,
    DateTimePickerComponent,
    CoachSelectComponent,
    DropdownComponent,
    LocalPreloaderComponent,
    DictionaryComponent,
    DicPipe,
    CashAndCardPipe,
    ChatEditComponent,
    ClubSettingsComponent,
    OptionSelectorRadioComponent,
    ChatMailingComponent,
    PluralDirective,
    RegistryComponent,
    RegistryCreateComponent,
    RegistryEditComponent,
    DatePickerRegistryComponent,
    RegistryElementEditComponent,
    RegistryElementAddComponent,
    LegalInfoComponent,
    VisitEditComponent,
    CollectionsComponent,
    CollectionEditComponent,
    MechanicSelectComponent,
    CollectionDataComponent,
    CompactScheduleModeComponent,
    InitialsPipe,
    FinanceComponent,
    FinanceRegistryComponent,
    FinanceRegistryElementsComponent,
    AccountingComponent,
    AccountingElementComponent,
    AccountingAddComponent,
    GraphsGeneralComponent,
    GraphSummaryComponent,
    FinanceRegistryElementsComponent,
    DayScheduleModeComponent,
    ElectronicQueueComponent,
    ClubDownTimeComponent,
    ClubDownTimeAddComponent,
    ClubDownTimeElementComponent,
    CertificatesComponent,
    CertificateStatusPipe,
    FilterCertificatesPipe,
    ResourceQueueComponent,
    ClientMergeItemsComponent,
    QueuedDashboardComponent,
    ClubEventTicketsTableComponent,
    ClubEventTicketEditComponent,
    ClubEventReqfildsTableComponent,
    ClubEventContactsTableComponent,
    QueuedDashboardComponent,
    WebhookComponent,
    WebhookEditComponent,
    QuickRecordComponent,
    CollectionsTypesPipe,
    CollectionsSlideComponent,
    FooterLoginComponent,
    SuspendComponent
  ],
    imports: [
        MatDatepickerModule,
        MatTableModule,
        MatDatepickerModule,
        MatSelectModule,
        MatNativeDateModule,
        MatIconModule,
        MatInputModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        AppRoutingModule,
        HotkeyModule.forRoot(),
        CKEditorModule,
        AngularMultiSelectModule,
        DynamicModule.withComponents([DashboardLinkComponent]),
        MatMomentDateModule,
        DragDropModule,
        NgxFileDropModule,
        DpDatePickerModule,
        WebCamModule,
        QRCodeModule,
        PortalModule,
        HammerModule,
        A11yModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MatTabsModule,
        MatCardModule,
        MatListModule,
        MatSortModule,
        MatButtonModule,
        MatMenuModule,
        MatPaginatorModule,
        MatRippleModule,
        MatTooltipModule
    ],
  exports: [
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DropdownComponent
  ],
  providers: [
    BrowserStateService,
    HttpService,
    {
      provide: ErrorHandler,
      useClass: SpErrorHandler
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru-RU'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    },
    {
      provide: HttpService,
      useFactory: httpFactory,
      deps: [XHRBackend, RequestOptions, Router, AlertsService]
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    AuthService,
    PermissionService,
    ApiService,
    ApiV2Service,
    PreloaderService,
    ContextService,
    MediaService,
    CoursesService,
    ClubroomsService,
    CoachesService,
    CoachRegistryService,
    ClientsService,
    GroupsService,
    AlertsService,
    ClubNetsService,
    ClubsprofileService,
    ClubsService,
    UsersService,
    SettingsService,
    SupportService,
    ScheduleService,
    ScrollToService,
    SubscriptionService,
    TipsService,
    SoundPlayService,
    PersistenceService,
    ColorService,
    ChatService,
    ChatEditService,
    WidgetsService,
    ThemesService,
    CityService,
    ClubEventService,
    DictionaryDataService,
    AuthGuard,
    LoginGuard,
    DicService,
    ExcelService,
    CsvService,
    TxtService,
    WebhookService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
