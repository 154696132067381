<div [hidden]="isVisible">
  <ul class="category-list">
    <li *ngFor="let category of data">
      {{category.name}}
    </li>
    <li *ngIf="data.length == 0" class="field">
      [не указано]
    </li>
  </ul>
  <a class="toggle" (click)="isVisible=true">Редактировать</a>
</div>
<div [hidden]="!isVisible">
  <div class="form-group">
    <a class="toggle" (click)="isVisible=false">Закрыть</a>
  </div>

  <div *ngIf="data">
    <div class="cols">
      <div class="col" [ngClass]="'col_'+column.number" *ngFor="let column of getColumns()">
        <ul class="checkbox-list">
          <li class="checkbox-list__item" *ngFor="let category of options|slice:column.start:column.end; trackBy:category?.id ">
            <input class="radio__gradient"
                   type="checkbox"
                   [id]="'cat_'+category.id"
                   [checked]="isChecked(category.id)"
                   [name]="'cat_'+category.id"
                   (change)="onChange($event, category)">
            <label class="check-box" [for]="'cat_'+category.id">
              <span class="check-box__desc">{{category.name}}</span>
            </label>
            <ng-container *ngIf="category.children">
              <ul class="checkbox-list checkbox-list_step">
                <li class="checkbox-list__item" *ngFor="let subcategory of category.children; trackBy:subcategory?.id">
                  <input type="checkbox"
                         [id]="'subcat_' + subcategory.id"
                         [name]="'subcat_' + subcategory.id"
                         [checked]="isChecked(subcategory.id)"
                         (change)="onChange($event, category, subcategory)">
                  <label class="check-box" [for]="'subcat_' + subcategory.id">
                    <span class="check-box__desc">{{subcategory.name}}</span>
                  </label>
                </li>
              </ul>
            </ng-container>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
