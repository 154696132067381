import { Pipe, PipeTransform } from '@angular/core';
import {EventType, EventTypeList} from '../models/webhook.model';

@Pipe({
  name: 'EventType'
})
export class EventTypePipe implements PipeTransform {
  public readonly EventTypeList = EventTypeList;

  transform(value: unknown, ...args: unknown[]): unknown {
    switch ( value ) {
      /*case EventType.Created: {
        return 'Целевая сущность создана (время события равно времени создания сущности).';
      }
      case EventType.Draft: {
        return 'Статус целевой сущности изменён на "Черновик".';
      }
      case EventType.Published: {
        return 'Статус целевой сущности изменён на "Опубликовано".';
      }
      case EventType.Deleted: {
        return 'Статус целевой сущности изменён на "Удалено".';
      }
      case EventType.Updated: {
        return 'Состояние целевой сущности изменено.';
      }
      case EventType.PageView: {
        return 'Увеличение счётчика просмотров для целевой сущности.';
      }
      case EventType.Like: {
        return 'Пользователь поставил лайк для целевой сущности.';
      }
      case EventType.LikeCancel: {
        return 'Пользователь отменил поставленный лайк для целевой сущности.';
      }
      case EventType.Dislike: {
        return 'Пользователь поставил дизлайк для целевой сущности.';
      }
      case EventType.DislikeCancel: {
        return 'Пользователь отменил поставленный дизлайк для целевой сущности.';
      }
      case EventType.AddToFavorites: {
        return 'Пользователь добавил целевую сущность в избранное.';
      }
      case EventType.RemoveFromFavorites: {
        return 'Пользователь удалил целевую сущность из избранного.';
      }
      case EventType.Shared: {
        return 'Чем-то поделились.';
      }
      case EventType.AccountCreated: {
        return 'Создана учётная запись пользователя.';
      }
      case EventType.AccountUpdated: {
        return 'Обновлен аккаунт пользователя или его профиль.';
      }
      case EventType.AccountsMerged: {
        return 'Объединение учётных записей пользователя.';
      }
      case EventType.AccountReferalCodeActivated: {
        return 'Для аккаунта был активирован реферальный код.';
      }
      case EventType.AccountCityUpdated: {
        return 'Обновлен город пользователя.';
      }
      case EventType.AccountsBindRequest: {
        return 'Запрос на слияние клинта клуба с аккаунтом SP';
      }
      case EventType.SubscriptionCreated: {
        return 'Абонемент создан.';
      }
      case EventType.SubscriptionUpdated: {
        return 'Абонемент изменен.';
      }
      case EventType.SubscriptionSuspended: {
        return 'Заморозка абонемента (при этом проставляется дата заморозки у абонемента).';
      }
      case EventType.SubscriptionReactivated: {
        return 'Разморозка абонемента (при этом меняется дата окончания абонемента).';
      }
      case EventType.SubscriptionActivated: {
        return 'Абонемент активирован (проставлена или изменена дата начала или конца абонемента при первом бронировании по абонементу).';
      }
      case EventType.SubscriptionCancelled: {
        return 'Абонемент отменен (при этом проставляется дата завершения абонемента).';
      }
      case EventType.SubscriptionCompleted:
      case EventType.ClientSubscriptionCompleted: {
        return 'Абонемент завершился по естественным причинам (кончился срок действия, кончились посещения)';
      }
      case EventType.SubscriptionDeactivated: {
        return 'Переход абонемента из активного состояния, в состояние ожидания повторной активации';
      }
      case EventType.ClientCertificateCreated: {
        return 'Сертификат клиента клуба создан.';
      }
      case EventType.ClientSubscriptionCreated: {
        return 'Абонемент клиента клуба создан.';
      }
      case EventType.ClientSubscriptionSuspended: {
        return 'Заморозка абонемента клиента клуба (при этом проставляется дата заморозки у абонемента).';
      }
      case EventType.ClientSubscriptionReactivated: {
        return 'Разморозка абонемента клиента клуба (при этом меняется дата окончания абонемента).';
      }
      case EventType.ClientSubscriptionActivated: {
        return 'Абонемент клиента клуба активирован (проставлена или изменена дата начала или конца абонемента при первом бронировании по абонементу).';
      }
      case EventType.ClientSubscriptionCancelled: {
        return 'Абонемент клиента клуба отменен (при этом проставляется дата завершения абонемента).';
      }
      case EventType.ClassCancelled: {
        return 'Занятие отменено.';
      }
      case EventType.ClassMoved: {
        return 'Занятие перенесено.';
      }
      case EventType.ClassVisitRejected: {
        return 'Резервирование занятия отклонено.';
      }
      case EventType.ClassVisitBooked: {
        return 'Пользоватль записан на занятие.';
      }
      case EventType.ClassVisitCancelledByUser: {
        return 'Посещение занятия отменено пользователем.';
      }
      case EventType.ClassVisitCancelledByClub: {
        return 'Посещение занятия отменено клубом.';
      }*/
      case EventType.ClassVisitVisitedByClub: {
        return 'Посещение занятия подтверждено клубом.';
      }
      case EventType.ClassVisitMissedByClub: {
        return 'Клуб отметил пропуск занятия.';
      }
      /*case EventType.ClassVisitVisitedByUser: {
        return 'Посещение занятия подтверждено пользователем.';
      }
      case EventType.ClassVisitMissedByUser: {
        return 'Пользователь отметил пропуск занятия.';
      }
      case EventType.ClassVisitConflict: {
        return 'Клуб подтвердил посещение, а пользователь нет.';
      }
      case EventType.ClassVisitVisited: {
        return 'Посещение подтверждено клубом и клиентом.';
      }
      case EventType.ClassVisitConfirmedBySystem: {
        return 'Автоматическое подтверждение посещения в статусе VisitedByClub job-ом до статуса Visited';
      }
      case EventType.ClassVisitWaitingBookingConfirmation: {
        return 'Пользователь забронировал занятие и ожидает подтверждение бронирования.';
      }
      case EventType.ClassVisitBookingConfirmed: {
        return 'Клуб подтвердил бронирование занятия (переход занятия в статус Booked)';
      }
      case EventType.GroupVirtualClassVisitStartSoon: {
        return 'В скором времени (задается в настройках историй) начнется групповое занятие.';
      }
      case EventType.CardClosedByUser: {
        return 'Инфокарточка удалена пользователем (больше не показывать)';
      }
      case EventType.CardDeactivated: {
        return 'Инфокарточка перешла в состояние "неактивная" (Draft). Это событие выстреливается при деактивации карточки любым способом.';
      }
      case EventType.CardVisitedAndLiked: {
        return 'Для инфокарточек VisitRate. Пользователь был на занятии и ему понравилось.';
      }
      case EventType.CardVisitedAndDisliked: {
        return 'Для инфокарточек VisitRate. Пользователь был на занятии и ему НЕ понравилось.';
      }
      case EventType.CardNotVisited: {
        return 'Для инфокарточек VisitRate. Пользователь НЕ был на занятии.';
      }
      case EventType.CardCustomAction: {
        return 'Для инфокарточек. Пользователь выполнил кастомное действие.';
      }
      case EventType.ApplicationStarted: {
        return 'Пользователь зашёл в приложение.';
      }
      case EventType.CartItemAdded: {
        return 'В корзину добавлен новый элемент.';
      }
      case EventType.SearchClubs: {
        return 'Предпринята попытка поиска клубов. В данные помещается все критерии поиска и общее количество полученных результатов.';
      }
      case EventType.SearchCourses: {
        return 'Предпринята попытка поиска занятий. В данные помещается все критерии поиска и общее количество полученных результатов.';
      }
      case EventType.PaymentWired: {
        return 'Пользователь провел платеж.';
      }*/
    }
    return EventTypeList.filter(x => x.value === value)[0].key;
  }
}
