import { Route } from '@angular/router';
import { UsersComponent } from 'app/components/users/users.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { UserEditComponent } from 'app/components/users/user-edit/user-edit.component';

export const clubUsersRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubusers',
  component: UsersComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Пользователи' }
};


export const clubUserRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubusers/:id',
  component: UserEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование пользователя' }
};

export const adminUsersRoute: Route = {
  path: ':cityId/users',
  component: UsersComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Пользователи - администратор' }
};

export const adminUserRoute: Route = {
  path: ':cityId/users/:id',
  component: UserEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование пользователя' }
};
