<div class="content__body" *ngIf="isLoading" @animation>

  <form [formGroup]="form">
    <div>
      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">Нерабочие время</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="start" type="datetime-local" formControlName="start" (change)="changeDate()" />
            <label class="field-validation-error" *ngIf="form.get('start').touched && form.get('start').invalid">Некорректное значение</label>
          </div>
        </div>

        <div class="mdash">&mdash;</div>

        <div class="form-group">
          <div class="box-title">&nbsp;</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="end" type="datetime-local" formControlName="end" min="{{form.get('start').value}}" (change)="changeDate()" />
            <label class="field-validation-error" *ngIf="form.get('end').touched && form.get('end').invalid">Некорректное значение</label>
          </div>
        </div>

        <div class="form-group">
          <div class="box-title">{{'Залы'|dic:'rooms'}}</div>
          <div class="row__field main-dropdown-box">
            <app-dropdown class="dropdown-box"
                          [items]="rooms" [alias]="'Залы'|dic:'rooms'"
                          (update)="onRoomsChange()"
                          [style]="'outline: none;padding: 1rem 1.2rem;border-width: 1px;border-color: #D6DBDE;border-style: solid;border-radius: 4px;font-size: 1.1rem;line-height: 1.1rem;background-color: white;color: #464C5F;height: 45px;'">
            </app-dropdown>
          </div>
        </div>

        <div class="form-group">
          <div class="box-title">Комментарий</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="comment" type="text" formControlName="comment" />
            <label class="field-validation-error" *ngIf="form.get('comment').touched && form.get('comment').invalid">Некорректное значение</label>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <div class="col-xs-12">
        <br />
        <br />
        <div class="form-group btn-save">
          <button class="btn btn-primary btn-small" (click)="confirmSave()">
            <span class="btn-primary__desc">Сохранить</span>
          </button>
        </div>
        <div class="form-group btn-save">
          <a class="btn btn-link btn-small" (click)="closeAdd()">
            <span class="btn-primary__desc">Отмена</span>
          </a>
        </div>
      </div>
    </div>
  </form>
</div>
