import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/services/api.service';
import { IFeedback } from 'app/common/models/feedback.model';

@Injectable()
export class SupportService {
  constructor( private apiService: ApiService ) { }

  public submit ( model: IFeedback ): Promise<any> {
    return this.apiService.feedback.submit( model );
  }
}
