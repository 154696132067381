import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import {SuspendComponent} from '../../components/clients/suspend/suspend.component';


export const suspendRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubclients/:id/suspend',
  component: SuspendComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Списание занятий' }
};
