<header class="content__header" *ngIf="Model">
    <div class="content__header-title">
        <div class="content__back">
            <span class="left-arrow" (click)="close()">
                <span class="left-arrow__top"></span>
                <span class="left-arrow__bottom"></span>
            </span>
            <span class="content__header-title-value">{{Model.chat.title}}</span>
            <!-- <div *ngIf="chat.chat.entityType == 'chat'">(
                <a (click)="onOtherChatClick(chat.chat.entityId)">
                    {{Model.chat.primaryTitle}}
                </a>)
            </div> -->
        </div>
        <div class="">
            <a class="content__header-link" (click)="close()">Закрыть
                <span class="key">
                    <span app-key-help [key]="'close'"></span>
                </span>
            </a>
        </div>
    </div>
</header>

<div class="content__body" *ngIf="chat && msgList">
    <div class="message-wrap" #contentbody (scroll)="onScroll($event)" [ngClass]="{'message-wrap--full':!chat.me}">
        <ng-container *ngIf="pagingInfo && msgList.length < pagingInfo.totalItemCount">
            <div class="message-separator">
                <span class="message-separator-btn">Загружаем предыдущие сообщения</span>
                <hr>
            </div>
        </ng-container>

            <div *ngIf="greetingMessage" class="message right">
                <div class="msg-detail">
                    <div class="msg-info">
                        <p>
                            {{chat.chat.createdAt | time2words}}</p>
                    </div>
                    <div class="msg-content">
                        <span class="triangle"></span>
                        <div class="line-breaker" [innerHtml]="greetingMessage"></div>
                    </div>
                </div>
            </div>
        <ng-container *ngFor="let msg of msgList; trackBy:msg?.id">
            <div class="message-separator" *ngIf="msg.isFirstUnread">
                <span>Новые сообщения</span>
                <hr>
            </div>
            <div class="message" #message [class.pending]="msg.status=='pending'"
                [class.merged]="isMessageMerged(msg)"
                [class.left]="!msg.isAdmin"
                [class.right]="msg.isAdmin">
                <div class="msg-detail">
                    <div class="msg-info">
                        <p>
                            {{messageInfo(msg.from)}}&nbsp;&nbsp;&nbsp;{{msg.createdAt | time2words}}</p>
                    </div>
                    <div class="msg-content">
                        <span class="triangle"></span>
                        <div class="line-breaker" [innerHtml]="msg.text"></div>
                    </div>
                    <div *ngIf="msg.relatedChatId && msg.from.chatId != chat.chat.id">
                        <a class="box-link" (click)="onOtherChatClick(msg.relatedChatId)"><span>Ответить</span></a>
                    </div>
                </div>
            </div>
        </ng-container>
            <div *ngIf="finalizeMessage" class="message"
                [class.pending]="finalizeMessage.status=='pending'"
                [class.left]="!finalizeMessage.isAdmin"
                [class.right]="finalizeMessage.isAdmin">
                <div class="msg-detail">
                    <div class="msg-info">
                        <p>
                            {{finalizeMessage.from.memberName}}&nbsp;завершил чат&nbsp;&nbsp;&nbsp;{{finalizeMessage.createdAt | time2words}}</p>
                    </div>
                </div>
            </div>
    </div>
    <div class="input-wrap" *ngIf="chat.me">
        <div class="textarea-wrap">
            <textarea [placeholder]="editorPlaceholder()" [(ngModel)]="editorMsg" (keyup.enter)="onReturn()" rows="3"></textarea>
        </div>
        <div class="button-wrap" [class.button-wrap-finalize]="showFinalizeButton()">
            <button class="btn btn-primary" (click)="sendMsg()">
                <span class="btn-primary__desc">
                    <ng-container *ngIf="showFinalizeButton() else sendButton">Завершить чат</ng-container>
                    <ng-template #sendButton>
                    &gt;
                    </ng-template>
                </span>
            </button>
        </div>
    </div>
</div>