export const environment = {
    production: true,
    api: 'https://api.sportpriority.com/',
    widgetsHost: 'https://wg.sportpriority.com',
    vkAppIds: {
        payment: 7486357,
        rooms: 6445829,
        schedule: 6277910,
        subscriptions: 6445819,
        courses: 6486550,
        coaches: 6486553
    },
};
