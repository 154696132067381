import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'day2string' })
export class Day2StringPipe implements PipeTransform {
    transform(value, part) {
        switch (value) {
            case 'all': {
                return 'Круглосуточно';
            }
            case 'none': {
                return 'Выходной';
            }
        }
        return part === 0 ? `С ${value}` : `по ${value}`;
    }
}
