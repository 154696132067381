import { Component, OnInit } from '@angular/core';
import {IHistoryVisit} from '../../../common/models/history-visit.model';
import {ClientsService} from '../services/clients.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ScheduleService} from '../../schedule/services/schedule.service';
import {SubscriptionService} from '../../subscriptions/services/subscription.service';
import {ApiService} from '../../../common/services/api.service';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {FormControl, FormGroup} from '@angular/forms';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {ClubSettingService} from '../../club-settings/club-settings.service';
import {ClubSetting} from '../../../common/models/club-setting.model';

@Component({
  selector: 'app-visit-edit',
  templateUrl: './visit-edit.component.html',
  styleUrls: ['./visit-edit.component.scss']
})
export class VisitEditComponent implements OnInit {
  public static readonly componentName: string = 'VisitEditComponent';
  history: IHistoryVisit;
  client: any;
  subscriptions: any[];
  params: any;
  subId: any;
  noSubId = '00000000000000000000000000000000';
  subViewSel: any[];
  isLoading = true;
  form: FormGroup;
  private isDisableConfirmSignWithoutSubscr = false;
  public clubSettings: ClubSetting[];

  constructor(
    private router: Router,
    public alertsService: AlertsService,
    private route: ActivatedRoute,
    protected apiV1: ApiService,
    protected apiV2: ApiV2Service,
    protected clientsService: ClientsService,
    private settingsService: ClubSettingService,
    protected scheduleService: ScheduleService,
  ) { }

  ngOnInit(): void {
    this.settingsService.getClubSettingsByClub()
      .then(settings => {
        this.clubSettings = settings;
        const alias = this.clubSettings.find(s => s.alias === 'disableConfirmSignUpWithoutSubscription');
        this.isDisableConfirmSignWithoutSubscr = alias.value === 'true';
      });
    this.route.params.subscribe(async params => {
      this.params = params;
      await this.clientsService.history(params.id).then(async h => {

        this.history = h.history.filter(x => x.id === params.visitId)[0];

        this.subId = this.history.subscription.id ? this.history.subscription.id : this.noSubId;
        this.form = new FormGroup({
          subId: new FormControl(this.subId)
        });

        this.client = await this.clientsService.getSingleItem(params);
        const subscriptions = await this.apiV1.clients.getClientAbonements(params);

        this.subscriptions = subscriptions?.clientSubscriptions || [];
        await this.viewSelectSub();
        this.isLoading = false;

      });
    });
  }

  checkIsCancelled(item: any) {
    if (item.classVisitStatus == 'QueuedCancelledByUser' || item.classVisitStatus == 'QueuedCancelledByClub')
      return  true;
    return !!item.classVisitStatus?.startsWith('Cancelled');
  }
  checkIsMissed(item: any) {
    return !!item.classVisitStatus?.startsWith('Missed');
  }

  public isFree(subscriptions: any) {
    if (subscriptions) {
      if (!subscriptions.id) {
        return !this.isDisableConfirmSignWithoutSubscr;
      }
      return true
    } else {
      return false
    }
  }
  confirmVisited(client: any) {
    if (this.isFree(client.subscription)) {
      const _client = {classId: client.class.id, id: client.id}
      this.scheduleService.confirmVisited(_client)
        .then(() => {
          this.ngOnInit()
          this.removeRequest(_client)
        });
    } else {
      this.alertsService.alert.next({
        type: 'danger',
        message: 'Невозможно подтвердить посещение без выбранного абонемента.',
        header: 'Сообщение',
        position: 'bottom',
        timeout: 5000
      });
    }
  }

  confirmMissed(client: any) {
    if (this.isFree(client.subscription)) {
      const _client = {classId: client.class.id, id: client.id}
      this.scheduleService.confirmMissed(_client)
        .then(() => {
          this.ngOnInit()
          this.removeRequest(_client)
        });
    } else {
      this.alertsService.alert.next({
        type: 'info',
        message: 'Невозможно отметить занятие как пропущенное без выбранного абонемента.',
        header: 'Сообщение',
        position: 'bottom',
        timeout: 5000
      });
    }
  }

  cancelBooking(client: any) {
    const _client = {classId: client.class.id, id: client.id}
    this.scheduleService.cancelBooking(_client, true)
      .then(() => {
        this.ngOnInit();
        this.removeRequest(_client)
      });
  }

  removeRequest(_client) {
    this.apiV2.classRequest.getByClub(this.route.snapshot.paramMap.get('clubId'), false).then(reqs => {
      const reqId = reqs.find(m => m.classVisitId == _client.id)?.id;
      if (reqId) {
        // Удаляем новую заявку с рабочего стола. т.к. она уже подтверждена клубом
        this.apiV2.classRequest.delete(reqId, false, false);
      }
    });
  }

  public styleBorderLeftRow(item: any) {
    let _status = item.classVisitStatus;
    switch (_status) {
      case "Booked":
      case "WaitingBookingConfirmation":
        return { 'border-left': '5px solid #00A3E2' } // blue
      case "Queued":
        return { 'border-left': '5px solid #ffd4bc' }
      case "VisitedByClub":
      case "VisitedByClubConflict":
      case "Visited":
      case "CancelledByUserConflict":
      case "MissedByUserConflict":
        return { 'border-left': '5px solid #00C168' } // green
      case "VisitedByUser":
      case "VisitedByUserConflict":
      case "QueuedCancelledByUser":
      case "QueuedCancelledByClub":
      case "CancelledByClub":
      case "CancelledByClubConflict":
      case "CancelledByUser":
        return { 'border-left': '5px solid rgb(87 70 131 / 73%)' }
      case "MissedByClub":
      case "MissedByClubConflict":
      case "MissedByUser":
      case "Missed":
        return { 'border-left': '5px solid #FFA7B6' } // red
      default:
        return { 'border-left': '5px solid #00C168' }
    }
  }

  viewSelectSub() {
    this.subViewSel = [];
    this.subscriptions.forEach(s => {
      if ((s.status === 'Active' || s.status === 'Created') || ((s.status === 'Cancelled' || s.status === 'Suspended' || s.status === 'Expired') && s.id === this.subId)) {
        this.subViewSel.push({
          id: s.id,
          visitCount: s.visitCount,
          visitCounter: s.visitCounter,
          name: s.name,
          toDate: s.toDate,
          isWarn: s.visitCounter >= s.visitCount
        });
      }
    });

    this.subViewSel = this.subViewSel.sort((a, b) => a.isWarn > b.isWarn ? 1 : -1);
  }

  getFullName(client) {
    return (`${client.firstName || ''} ${client.lastName || ''}`).trim();
  }

  close(): void {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  selectSubscriptionForClient() {
    if (this.form.get('subId').value === 'new') {
      return this.goToBaySub();
    }
    this.scheduleService
      .changeSubscriptionPlan({
        classVisitId: this.history.id,
        clientSubscriptionId: this.form.get('subId').value
      }).then(() => {
        this.ngOnInit()
    })
      .catch(error => {
        console.error(error)
      });
  }

  goToBaySub() {
    return this.router.navigateByUrl(`/${this.params.cityId}/${this.params.clubNetId}/${this.params.clubId}/clubplans-sale?clientId=${this.params.id}`);
  }
}
