import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ColorService } from 'app/common/services/color.service';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
  }]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
  public data: Array<string>;
  public colors: Array<Array<string>>;
  public isCustomColor: boolean;
  private hexColorRegex = new RegExp('^#([0-9a-fA-F]{3}){1,2}$');
  public customControlInput = new FormControl();

  constructor(private colorService: ColorService) { }

  public onCustomColorChange(event) {
    const existingColor = this.colors.filter(item => item[0] === event);
    if (existingColor.length > 0) {
      this.writeValue(existingColor[0][0]);
    } else {
      if (this.hexColorRegex.test(event)) {
        this.data[0] = event;
        this.data[1] = this.colorService.getMatchingSecondaryColor(event);
        this.isCustomColor = true;
        this.propagateChange(event);
      } else {
        this.data[0] = null;
        this.data[1] = null;
        this.propagateChange(null);
      }
    }
  }

  public writeValue(obj: any) {
    if (obj) {
      if (typeof obj === 'string') {
        const cl = this.colors.find(item => item[0] === obj);
        if (cl) {
          obj = cl;
          this.isCustomColor = false;
        } else {
          obj = [obj, this.colorService.getMatchingSecondaryColor(obj)];
          this.isCustomColor = true;
        }
      }

      this.data = _.cloneDeep(obj);
      this.customControlInput.setValue(this.data[0], { emitEvent: false });
      this.propagateChange(this.data[0]);
    }
  }


  public ngOnInit() {
    this.colors = this.colorService.getColors();

    this.customControlInput.valueChanges.pipe(
      debounceTime(100))
      .subscribe(value => this.onCustomColorChange(value));
  }


  private propagateChange: (any?: any) => void = () => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }
}
