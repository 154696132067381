import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/common/services/api.service';
import { HotkeysService } from 'custom-modules/angular2-hotkeys';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-global-preloader',
    templateUrl: './global-preloader.component.html',
    styleUrls: ['./global-preloader.component.scss']
})
export class GlobalPreloaderComponent implements OnInit {
    public isLoading = false;
    private apiv1Busy = false;
    private apiv2Busy = false;

    constructor(
        private api: ApiService,
        private apiv2: ApiV2Service,
        protected hotkeysService: HotkeysService
    ) { }

    public createRange(begin: number, end: number) {
        const res = [];
        for (let i = begin; i <= end; i++) {
            res.push(i);
        }
        return res;
    }

    public update() {
        this.isLoading = this.apiv1Busy || this.apiv2Busy;


        if (this.isLoading) {
            this.hotkeysService.pauseAll();
        } else {
            this.hotkeysService.unpauseAll();
        }
    }

    ngOnInit() {
        this.api.isBusy.pipe(
            distinctUntilChanged())
            .subscribe(r => {
                this.apiv1Busy = r as boolean;
                this.update();
            });

        this.apiv2.isBusy.pipe(
            distinctUntilChanged())
            .subscribe(r => {
                this.apiv2Busy = r as boolean;
                this.update();
            })
    }
}
