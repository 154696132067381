import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {ResourceQueueComponent} from '../../components/resource-queue/resource-queue.component';

export const resourceQueueRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/resourcequeue',
  component: ResourceQueueComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Очередь на выдачу/прием ресурсов'}
};
