import {Component, Injector, Input, OnInit} from '@angular/core';
import {ClubAnalyticsService} from './services/club-analytics.service';
import {TableListComponent} from '../../common/table-list/table-list.component';
import {IClubAnalyticsModel} from '../../common/models/club-analytics.model';
import * as moment from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../common/services/api.service';
import {ClubSetting} from '../../common/models/club-setting.model';

@Component({
  selector: 'app-clubanalytics',
  templateUrl: './club-analytics.component.html',
  styleUrls: ['./club-analytics.component.scss'],
  providers: [ClubAnalyticsService]
})
export class ClubAnalyticsComponent extends TableListComponent<IClubAnalyticsModel> implements OnInit {
  public static readonly componentName: string = 'ClubAnalyticsComponent';

  public clubSettings: ClubSetting[] = [];
  public selectPeriod: 'today' | 'week' | 'month' | 'any' = 'today';
  public IsShowGraphEfficiency = false;
  public IsShowGraphMunicipalTask = false;
  public IsShowGraphClients = false;
  public IsShowGraphFinance = false;

  public start: Date = new Date();
  public end: Date = new Date();
  range = new FormGroup({
    start: new FormControl(this.start, [Validators.required]),
    end: new FormControl(this.end, [Validators.required]),
  });

  dateFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    return moment(date) <= moment();
  };

  constructor(
    injector: Injector,
    protected service: ClubAnalyticsService,
    private apiV1: ApiService,
  ) {
    super(injector, service)
  }

  goToDetail(report, event?: MouseEvent) {
    if (event && (event.which === 2 || event.ctrlKey)) {
      event.preventDefault();

      if (report != null) {
        const url = this.router.createUrlTree(['./' + report.name], {relativeTo: this.route});
        window.open(url.toString());
      }

      return;
    }

    this.router.navigate(['./' + report.name], {relativeTo: this.route});
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();

    if (this.contextService.getRoutingParams()?.clubId) {
      this.clubSettings = await this.apiV1.clubSettings.getByClub(this.contextService.getRoutingParams().clubId);
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphEfficiency')) { this.IsShowGraphEfficiency = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphEfficiency')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphMunicipalTask')) { this.IsShowGraphMunicipalTask = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphMunicipalTask')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphClients')) { this.IsShowGraphClients = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphClients')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphFinance')) { this.IsShowGraphFinance = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphFinance')?.value) ?? false; }
    }
  }

  public async onSelectPeriod(p: any): Promise<any> {
    this.selectPeriod = p;
    if (this.selectPeriod === 'week') {
      this.range.get('start').setValue(moment(new Date()).add(-6, 'days').toDate());
      this.range.get('end').setValue(new Date());
    } else if (this.selectPeriod === 'month') {
      this.range.get('start').setValue(new Date(new Date().setDate(1)));
      this.range.get('end').setValue(moment(new Date()).endOf('month').toDate());
    } else if (this.selectPeriod === 'today') {
      this.range.get('start').setValue(new Date());
      this.range.get('end').setValue(new Date());
    }
    if (moment(this.range.get('end').value) > moment()) { this.range.get('end').setValue(new Date()); }

    this.start = this.range.get('start').value;
    this.end = this.range.get('end').value;
  }

  rangePicker(event: any) {
    this.selectPeriod = 'any';
    if (this.range.valid) {
      this.start = this.range.get('start').value;
      this.end = this.range.get('end').value;
    }
  }
}
