<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Абонементы
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="clubrooms-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (contextmenu)="onEditStart(null, $event)" (click)="onEditStart(null, $event)">
              Добавить
              <span class="key">
                <span app-key-help [key]="'plus'"></span>
              </span>
            </a>
          </ng-container>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Описание</td>
              <td class="table-cell table-title-cell">Активно</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let subs of Model' (contextmenu)="onEditStart(subs,$event)" (click)="onEditStart(subs,$event)">
              <td class="table-cell">
                <span class="table-cell__mobile-title">Название</span>
                <b>{{subs.name}}</b>
              </td>
              <td class="table-cell">
                <span class="table-cell__mobile-title">Описание</span>
                <span [innerHtml]="subs.description || '<span class=\'gray\'>[Описание не заполнено]</span>'">
                </span>
              </td>
              <td class="table-cell table-cell_publish" (contextmenu)="$event.stopPropagation()" (click)="$event.stopPropagation()">
                <span class="table-cell__mobile-title">Активно</span>
                <app-status-picker [title]="null" [ngModel]="subs.entityStatus" (ngModelChange)="setStatus($event, subs)"></app-status-picker>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>
