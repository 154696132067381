<ng-container *ngIf="cameraVisible">
  <div class="preloader-wrap" [ngClass]="{'loading':isLoading}">
    <div class="preloader-wr">
      <div class="preloader">
        <img class="preloader__road" src="assets/preloader/route.svg" alt="preloader">
        <img class="preloader__bike" src="assets/preloader/bike_2.svg" alt="preloader">
      </div>
    </div>
  </div>

  <header class="content__header" *ngIf="!isLoading">
    <div class="content__header-title">
      <div class="content__back" (click)="close()">
        <span class="left-arrow">
          <span class="left-arrow__top"></span>
          <span class="left-arrow__bottom"></span>
        </span>
      </div>
      <div class="">
        <a class="content__header-link" (click)="close()">Закрыть
          <span class="key">
            <span app-key-help [key]="'close'"></span>
          </span>
        </a>
      </div>
    </div>
  </header>

  <div class="webcam">
    <ack-webcam #webcam (success)="onCamSuccess($event)" (catch)="onCamError($event)"></ack-webcam>
  </div>
  <div class="webcam__submit">
    <button (click)="genBase64(webcam)" class="btn btn-primary" *ngIf="!isLoading">
      <span class="btn-primary__desc">Применить</span>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="!cameraVisible">
  <header class="content__header" *ngIf="Model">
    <div class="content__header-title">
      <div class="content__back" (click)="close()">
        <span class="left-arrow">
          <span class="left-arrow__top"></span>
          <span class="left-arrow__bottom"></span>
        </span>
        <span class="content__header-title-value">{{Model.name}}</span>
      </div>
      <div class="">
        <a class="content__header-link" (click)="close()">Закрыть
          <span class="key">
            <span app-key-help [key]="'close'"></span>
          </span>
        </a>
      </div>
    </div>
  </header>

  <div #contentbody class="content__body {{orientation}}">
    <ng-container *ngIf="Model">
      <div class="content__body__image">
        <div class="content__body__copy-btn">
          <a (click)="copyToClipboard()">Скопировать ссылку на картинку</a>
        </div>
        <div class="img_wrapper">
          <img #image class="img" src="{{Model.baseUrl}}/img/crmCenterLg/{{Model.id}}" alt="{{Model.name}}"
            (mouseup)="setImageCenter($event)" />
          <div *ngIf="centerX && centerY" class="center_wrapper" [style.top]="100*centerY + '%'"
            [style.left]="100*centerX + '%'">
            <div class="icon-center"><span class="sp-gallery_addphoto"></span></div>
          </div>
        </div>
      </div>
      <div class="content__body__preview_{{orientation}}">
        <div *ngFor="let crop of crops" class="content__body__preview__item">
          <p>{{cropNames[crop]}}</p>
          <div class="imgpreview">
            <img [src]="getCropUrl(crop)">
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="cameraVisible === false" class="webcam__save">
    <button (click)="saveImage()" class="btn btn-primary">
      <span class="btn-primary__desc">Сохранить</span>
    </button>
    <button (click)="clearImage()" class="btn btn-primary">
      <span class="btn-primary__desc">Переснять</span>
    </button>
  </div>

</ng-container>
