import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMergeClientModel } from 'app/common/models/client.model';

export interface MergeGroup {
  mergeList: IMergeClientModel[],
  values: {
    firstName: { value: string, count: number }[],
    lastName:  { value: string, count: number }[],
    phone: { value: string, count: number }[],
    email: { value: string, count: number }[],
  },
  isSelected?: boolean,
  // getClass: function (prop: string, value: string): string {}
};

@Component({
  selector: 'app-client-merge-items',
  templateUrl: './client-merge-items.component.html',
  styleUrls: ['./client-merge-items.component.scss']
})
export class ClientMergeItemsComponent implements OnInit {

  @Input('mode')
  public mode: undefined | 'popup';

  private _mergeGroups: MergeGroup[];
  @Input('mergeGroups')
  public get mergeGroups(): MergeGroup[] { return this._mergeGroups; }
  public set mergeGroups(value: MergeGroup[]) { this._mergeGroups = value; }

  @Input('clientId')
  public clientId: undefined | string;

  @Output('onItemSelect')
  public onItemSelect = new EventEmitter<{ clientId: string,  mergeList: IMergeClientModel[], group?: { merging: boolean } }>();

  @Output('onEditStart')
  public onEditStart = new EventEmitter<{ item: { id?: string }, event?: MouseEvent }>();

  @Output('onAddIgnore')
  public onAddIgnore = new EventEmitter<{ clientId: string }>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public GetClassForValue(values: any, prop: string, value: string): string {
    for (var i=0; i<values[prop].length; i++) {
      if (values[prop][i].value != value) return 'value-group-diff';
    }
  }

  public itemSelect(clientId: string, mergeList: IMergeClientModel[], group?: { merging: boolean }) {
    this.onItemSelect.emit({ clientId, mergeList, group });
  }

  public editStart(item: { id?: string }, event?: MouseEvent) {
    this.onEditStart.emit({ item, event });
  }

  public async addIgnore(clientId: string) {
    this.onAddIgnore.emit({ clientId });
  }

}
