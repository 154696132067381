import { MatPaginatorIntl } from '@angular/material/paginator';

const russRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return `0 из ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} из ${length}`;
}

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Строк на странице:';
  customPaginatorIntl.nextPageLabel = 'Следующая страница';
  customPaginatorIntl.lastPageLabel = 'Последняя страница';
  customPaginatorIntl.previousPageLabel = 'Предыдущая страница';
  customPaginatorIntl.firstPageLabel = 'Первая страница';
  customPaginatorIntl.getRangeLabel = russRangeLabel;

  return customPaginatorIntl;
}
