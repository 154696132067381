<ng-container *ngIf="classes">
  <div class="box-title">
    <span class="box-title__name">Занятие с очередью</span>
  </div>
  <ng-container>
    <div class="classes-list" *ngIf="isShowClasses()">
      <ng-container *ngFor="let class of classes">
        <div (click)="goToClass(class)" *ngIf="class.maxCapacity > (class.numberOfVisits - class.numberOfQueued) && class.numberOfQueued > 0" class="classes-item" [style.backgroundColor]="class.course?.color || 'gray'">
          <div class="classes-item__title">{{class.course?.name}}</div>
          <span class="classes-item__desc"><b>{{class.startDate}} {{class.startTime}}</b></span>
          <span class="classes-item__desc">{{class.numberOfVisits - class.numberOfQueued}}/{{class.maxCapacity}} {{class.numberOfQueued > 0 ? '(+' + class.numberOfQueued + ')' : null}}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="content-infobox content-infobox--small" *ngIf="!isShowClasses()">
    Пока заявок нет
  </div>
</ng-container>
