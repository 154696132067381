import { Pipe, PipeTransform } from '@angular/core';
import { EntityStatus } from 'app/common/models/entity-status.model';

@Pipe({ name: 'status2bool' })
export class Status2BoolPipe implements PipeTransform {
    transform(value) {
        if (value === true || value === 'true') {
            return true;
        }
        if (value === false || value === 'false') {
            return false;
        }
        return value === EntityStatus.published;
    }
}

@Pipe({ name: 'bool2status' })
export class Bool2StatusPipe implements PipeTransform {
    transform(value) {
        if (value === true || value === 'true') {
            return EntityStatus.published;
        }
        if (value === false || value === 'false') {
            return EntityStatus.draft;
        }
        return value;
    }
}

