<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Список пользователей
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="users-container">

      <div class="tableSideLinkWrap">
        <ng-container *ngIf="isEditAvailable">
          <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">
            Добавить
            <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
          </a>
        </ng-container>
      </div>

      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <td class="table-cell table-title-cell">Пользователь</td>
            <!-- <td class="table-cell table-title-cell">ФИО</td>
            <td class="table-cell table-title-cell">E-mail</td>
            <td class="table-cell table-title-cell">Телефон</td> -->
            <td class="table-cell table-title-cell">Должность</td>
            <td class="table-cell table-title-cell">Роль</td>
          </thead>

          <tbody>
            <tr *ngFor="let item of Model" (mousedown)="onEditStart(item,$event)" (click)="onEditStart(item)">
              <td class="table-cell">
                <span class="table-cell__mobile-title">Пользователь</span>

                <div class="user-wrap">
                  <div>{{getUserName(item)}}</div>
                  <div>
                    <span *ngIf="item.phoneNumber; else noPhoneNumber">{{item.phoneNumber}}</span>
                    <ng-template #noPhoneNumber>
                      <span class="gray">[не&nbsp;указано]</span>
                    </ng-template>
                  </div>
                  <div>{{item.email}}</div>
                </div>
              </td>
<!--
              <td class="table-cell">{{getUserName(item)}}</td>
              <td class="table-cell">{{item.email}}</td>
              <td class="table-cell">
                <span *ngIf="item.phoneNumber; else noPhoneNumber">{{item.phoneNumber}}</span>
                <ng-template #noPhoneNumber>
                  <span class="gray">[не&nbsp;указано]</span>
                </ng-template>
              </td> -->

              <td class="table-cell">
                <span class="table-cell__mobile-title">Должность</span>
                <span *ngIf="item.position; else noPosition">{{item.position}}</span>
                <ng-template #noPosition>
                  <span class="gray">[не&nbsp;указано]</span>
                </ng-template>
              </td>

              <td class="table-cell">
                <span class="table-cell__mobile-title">Роль</span>
                <span *ngIf="!item.role || item.role === roles.DummyRole; else role" class="gray">[{{(item.role|roleinfo).title}}]</span>
                <ng-template #role>
                   {{(item.role|roleinfo).title}}
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>
