import { Injectable, Injector } from '@angular/core';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { IClubEventModel } from '../../common/models/club-events.model';
import { ICityModel } from 'app/common/models/city-model';

@Injectable()
export class ClubEventService extends TableListService<IClubEventModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  getItemList(_?: ICityModel, pageIndex?: number, pageSize?: number, parentId?: string): Promise<IClubEventModel[]> {
    return this.apiV2Service.clubEvents.getByClub(this.routingParams.clubId, pageIndex, pageSize, parentId );
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiV2Service.clubEvents.get(req.id);
  }

  save(model: IClubEventModel) {
    return this.apiV2Service.clubEvents.save(model);
  }

  create(model: IClubEventModel) {
    return this.save(model);
  }

  getBookings(model: IClubEventModel){
    return this.apiV2Service.clubEvents.getBookings(model);
  }

}
