import { TableListComponent } from 'app/common/table-list/table-list.component';
import { Component } from '@angular/core';

import { ICourseModel } from 'app/common/models/course.model';
import { Injector } from '@angular/core';
import { CoursesService } from 'app/components/courses/services/courses.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})

export class CoursesComponent extends TableListComponent<ICourseModel> {
  public static readonly componentName: string = 'CoursesComponent';

  constructor(
    injector: Injector,
    protected service: CoursesService
  ) {
    super(injector, service);
  };
}
