import { FormControl } from '@angular/forms';

export const phoneValidator = () => {
    return (c: FormControl) => {
        const regex = new RegExp(
             '(^\\s*'+'(\\+7|8)'+'\\s*-?\\s*(\\d{3}|\\(\\d{3}\\))'+'\\s*-?\\s*\\d{3}'+'\\s*-?\\s*\\d{2}'+'\\s*-?\\s*\\d{2}'+'\\s*$)'  // RU
          + '|(^\\s*'+ '\\+375' +'\\s*-?\\s*(\\d{2}|\\(\\d{2}\\))'+'\\s*-?\\s*\\d{3}'+'\\s*-?\\s*\\d{2}'+'\\s*-?\\s*\\d{2}'+'\\s*$)'  // BY
          + '|(^\\s*'+ '\\+382' +'\\s*-?\\s*(\\d{2}|\\(\\d{2}\\))'                   +'\\s*-?\\s*\\d{3}'+'\\s*-?\\s*\\d{3}'+'\\s*$)'  // ME
          + '|(^\\s*'+ '\\+995' +'\\s*-?\\s*(\\d{3}|\\(\\d{3}\\))'+'\\s*-?\\s*\\d{2}'+'\\s*-?\\s*\\d{2}'+'\\s*-?\\s*\\d{2}'+'\\s*$)'  // GE
        );
        return (c.value === '' || c.value == null || regex.test(c.value)) ? null : {
            phoneValidator: {
                valid: false
            }
        };
    }
}
