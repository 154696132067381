import { Component, Injector } from '@angular/core';

import { ThemesService } from 'app/components/widgets/themes.service';
import { EditItemComponent } from 'app/common/edit-item.component';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { IThemeModel } from 'app/common/models/widgets.model';
import { EntityStatus } from 'app/common/models/entity-status.model';

@Component({
  selector: 'app-theme-edit',
  templateUrl: './theme-edit.component.html',
  styleUrls: ['./theme-edit.component.scss']
})
export class ThemeEditComponent extends EditItemComponent<IThemeModel> {
  public static readonly componentName: string = 'ThemeEditComponent';

  newThemeState: { id: string, entityStatus: string };

  constructor(
    injector: Injector,
    protected service: ThemesService
  ) {
    super(injector, service);
    this.newThemeState = this.router.getCurrentNavigation()?.extras?.state?.newThemeState;
  }

  public close(id?: string) {
    if (this.newThemeState) {
      this.newThemeState.id = id;
      this.newThemeState.entityStatus = this.Model.entityStatus;
    }
    super.close();
  }

  public _close() {
    (history.length > 1)
      ? history.back()
      : this.router.navigate(['../../clubwidgets'], { relativeTo: this.route });
  }



  modelTemplate(): Promise<IThemeModel> {
    const result: IThemeModel = {
      id: null,
      entityStatus: EntityStatus.published,
      clubId: this.contextService.getRoutingParams().clubId,
      name: '',
      widgetType: this.route.snapshot.params.type,
      style: '',
      settings: {}
    };

    return Promise.resolve(result);
  }
    public hkDeletePress(that: this) {
        return (event: KeyboardEvent) => {
            if (that.Model && that.Model.id != null && this.isAdmin && this.Model.clubId != null) {
                this.delete();
            }
            event.preventDefault();
            return true;
        };
    }
  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      style: [this.Model.style, []],
      widgetType: [this.Model.widgetType, []]
    });
  }
}
