import {Route} from '@angular/router';
import {AuthGuard} from '../../common/services/auth-guard.service';
import {FinanceComponent} from '../../components/finance/finance.component';
import {FinanceRegistryElementsComponent} from '../../components/finance/finance-registry-elements/finance-registry-elements.component';

export const financeRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/finance',
  component: FinanceComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Финансы'}
};

export const financeRegistryElementsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/finance/registry/:id',
  component: FinanceRegistryElementsComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Реестр'}
};
