import { DicService } from './../services/dic.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dic' })
export class DicPipe implements PipeTransform {

  constructor(public dic: DicService) { }

  transform(value, alias) {
    if (!this.dic.hasOwnProperty("data")) return value;

    return this.dic.data[alias] || value;
  }
}
