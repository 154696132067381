import { Component, Injector, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take, filter } from 'rxjs/operators';
import { SubscriptionService } from 'app/components/subscriptions/services/subscription.service';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { ISellSubscriptionPlan, ISubscription } from 'app/common/models/subscription.model';
import { IClientModel } from 'app/common/models/client.model';
import { IPlanVariant } from 'app/common/models/plan-variant.model';
import { CoursesService } from 'app/components/courses/services/courses.service';
import { ContextService } from 'app/common/services/context.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { DatePickerComponent } from 'app/components/extension-subscription/date-picker/date-picker.component';
import {ClubSetting} from '../../../common/models/club-setting.model';
import {ClubSettingService} from '../../club-settings/club-settings.service';

@Component({
  selector: 'app-subscription-sale',
  templateUrl: './subscription-sale.component.html',
  styleUrls: ['./subscription-sale.component.scss'],
  providers: [ClientsService, SubscriptionService]
})
export class SubscriptionSaleComponent implements OnInit, OnDestroy {
  public static readonly componentName: string = 'SubscriptionSaleComponent';

  protected ngUnsubscribe: Subject<void> = new Subject();
  public Model: ISellSubscriptionPlan;
  public subscriptions: ISubscription[];
  public readonly _predicateSubPubl = (value: ISubscription, index: number, array: ISubscription[]) => value.entityStatus === 'published';
  public readonly _predicateSubDrft = (value: ISubscription, index: number, array: ISubscription[]) => value.entityStatus === 'draft';
  public selectedSubscription: ISubscription;
  public selectedClient: IClientModel;
  public clients: IClientModel[];
  public selectedVariant: IPlanVariant;
  public sum: number;
  public selectedSource: string;
  public isOrderDiscount = false;
  public typeDiscount = 1;
  public sumOrderDiscount = 0;
  public orderDiscount = 0;
  public clubSettings: ClubSetting[];
  public isPaymentReceipt = false;

  @ViewChild('startDatePicker') startDatePicker: DatePickerComponent;

  protected hotkeysService: HotkeysService;
  protected hkClose: Hotkey | Hotkey[];
  protected hkSave: Hotkey | Hotkey[];

  constructor(
    public _location: Location,
    public injector: Injector,
    public route: ActivatedRoute,
    public clientsService: ClientsService,
    public clubSettingService: ClubSettingService,
    public subscriptionService: SubscriptionService,
    public coursesService: CoursesService,
    public contextService: ContextService
  ) {
    this.hotkeysService = injector.get(HotkeysService);
    this.hkClose = this.hotkeysService.add(new Hotkey('esc', this.hkClosePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
    this.hkSave = this.hotkeysService.add(new Hotkey(['ctrl+s', 'ctrl+return'], this.hkSavePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.selectedSource = 'Card';

    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((params: { clientId?: string, courseId?: string }) => {
        if (params.clientId) {
          this.clientsService.getItemList()
            .then(result => {
              this.clients = result.clients;
              this.selectedClient = this.clients.filter(item => item.id === params.clientId)[0];
            });
        } else {
          this.clientsService.getItemList()
            .then(result => this.clients = result.clients);
        }

        if (params.courseId) {
          this.contextService.routingParams.pipe(
            takeUntil(this.ngUnsubscribe),
            filter<IRoutingParams>(Boolean),
            take(1))
            .subscribe(routingParams => {
              const req = Object.assign(routingParams, { id: params.courseId })
              this.coursesService.getSingleItem(req)
                .then(result => this.subscriptions = result.subscriptionPlans);
            })
        } else {
          this.subscriptionService.getItemList()
            .then(result => this.subscriptions = result);
        }
      });
    this.clubSettingService.getClubSettingsByClub()
      .then(settings => {
        this.clubSettings = settings;
        const alias = this.clubSettings.find(s => s.alias === 'enablePaymentReceipt');
        this.isPaymentReceipt = alias.value === 'true';
      });
  }

  selectSubscription(e: string): void {
    this.selectedSubscription = this.subscriptions.filter(item => item.id === e)[0];

    if (this.selectedSubscription.variants[0]) {
      this.selectedVariant = this.selectedSubscription.variants[0];
    }
  }

  selectSource(e: string): void {
    this.selectedSource = e;
  }

  addSubscription(client: IClientModel, _subscription, variant: IPlanVariant, sourse) {
    this.Model = {
      clientId: client.id,
      orderDiscount: this.isOrderDiscount ? this.orderDiscount : 0,
      subscriptionPlanVariantId: variant.id,
      startDate: this.startDatePicker.dateValue.isValid() ? this.startDatePicker.dateValue.format("DD.MM.YYYY") : undefined,
      amount: this.sum,
      source: sourse
    };
    this.clientsService.sellClientAbonement(this.Model, client.id)
      .then(() => this._location.back());
  }

  public hkSavePress(that: this) {
    if (this.selectedVariant) {
      return (event: KeyboardEvent) => {
        that.addSubscription(
          that.selectedClient,
          that.selectedSubscription,
          that.selectedVariant,
          that.selectedSource
        );
        event.preventDefault();
        return true;
      };
    }
  }

  public hkClosePress(that: any) {
    return (event: KeyboardEvent, ) => {
      event.stopPropagation();
      that._location.back();
      return true;
    };
  }

  goBack(): void {
    this._location.back();
  }

  changeOrderDiscount() {
    const variant = this.selectedVariant.price;
    if (Number(this.typeDiscount) === 1) {
      this.orderDiscount = this.sumOrderDiscount;
      return variant - this.sumOrderDiscount;
    } else if (Number(this.typeDiscount) === 2) {
      const s = variant / 100 * this.sumOrderDiscount
      this.orderDiscount = s;
      return variant - s;
    }
  }
}
