export type PermissionKey
  = 'isAvailable'
  | 'isEditAvailable'
  | 'isRemoveAvailable'

  | 'clubEdit_name'
  | 'clubEdit_clubNet'
  | 'clubEdit_workingTime'
  | 'clubEdit_description'
  | 'clubEdit_descriptionExtented'
  | 'clubEdit_note'
  | 'clubEdit_geo'
  | 'clubEdit_contacts'
  | 'clubEdit_medias'
  | 'clubEdit_legalInfo_inn'
  | 'clubEdit_legalInfo_legalName'
  | 'clubEdit_legalInfo_bikBank'
  | 'clubEdit_legalInfo_nameBank'
  | 'clubEdit_legalInfo_currentAccountNumber'
  | 'clubEdit_legalInfo_numberBankCorrespondentAccount'
  | 'clubEdit_legalInfo_contractBasisPayment'
  | 'clubEdit_legalInfo_documentLink'

  | 'clubProfile_isClubChatRequestDenied'
  | 'clubProfile_isBookingScheduleAllowed'
  | 'clubProfile_settingsBlock'
  | 'clubProfile_actualScheduleDate'
  | 'clubProfile_signingTreatyDate'
  | 'clubProfile_responsibleToRelevanceInfoUserId'
  | 'clubProfile_responsibleToWorkWithClientUserId'
  | 'clubProfile_currentTariff'
  | 'clubProfile_currentTariffSbp'
  | 'clubProfile_paymentCardBonusPercent'

  | 'clubProfileSettings_requiredEmailNumberForRegistration'
  | 'clubProfileSettings_disableSignUpWithoutSubscription'
  | 'clubProfileSettings_requiredFullNameForRegistration'
  | 'clubProfileSettings_showTextAboutTrainer'
  | 'clubProfileSettings_disableConfirmSignUpWithoutSubscription'
  | 'clubProfileSettings_requiredPhoneNumberForRegistration'
  | 'clubProfileSettings_IsSubscriptionPurchaseDateChangeable'
  | 'clubProfileSettings_IsHiddenImTheClubButton'
  | 'clubProfileSettings_IsSetSubscriptionWhenBookFromCrm'
  | 'clubProfileSettings_IsSetSubscriptionForGroupAutoCreatedVisits'
  | 'clubProfileSettings_NumberMinutesBeforeStartCannotCancelBooking'

  | 'isBtnAccountingAvailable'
  | 'isCoachRatesAvailable'
  | 'isExcelExportAvailable'
  | 'isSubscriptionAddAvailable'
  | 'isClassVisitClientAvailable'
  | 'isRequestChatAvailable'
  | 'isHeaderSearchBarAvailable';


  export const isAvailableKey: PermissionKey = 'isAvailable';
  export const isEditAvailableKey: PermissionKey = 'isEditAvailable';
  export const isRemoveAvailableKey: PermissionKey = 'isRemoveAvailable';

  export const clubProfile_isClubChatRequestDenied: PermissionKey = 'clubProfile_isClubChatRequestDenied';
  export const clubProfile_isBookingScheduleAllowed: PermissionKey = 'clubProfile_isBookingScheduleAllowed';
  export const clubProfile_settingsBlock: PermissionKey = 'clubProfile_settingsBlock';
  export const clubProfile_actualScheduleDate: PermissionKey = 'clubProfile_actualScheduleDate';
  export const clubProfile_signingTreatyDate: PermissionKey = 'clubProfile_signingTreatyDate';
  export const clubProfile_responsibleToRelevanceInfoUserId: PermissionKey = 'clubProfile_responsibleToRelevanceInfoUserId';
  export const clubProfile_responsibleToWorkWithClientUserId: PermissionKey = 'clubProfile_responsibleToWorkWithClientUserId';
  export const clubProfile_currentTariff: PermissionKey = 'clubProfile_currentTariff';
  export const clubProfile_currentTariffSbp: PermissionKey = 'clubProfile_currentTariffSbp';
  export const clubProfile_paymentCardBonusPercent: PermissionKey = 'clubProfile_paymentCardBonusPercent';

  export const clubProfileSettings_requiredEmailNumberForRegistration: PermissionKey = 'clubProfileSettings_requiredEmailNumberForRegistration';
  export const clubProfileSettings_disableSignUpWithoutSubscription: PermissionKey = 'clubProfileSettings_disableSignUpWithoutSubscription';
  export const clubProfileSettings_requiredFullNameForRegistration: PermissionKey = 'clubProfileSettings_requiredFullNameForRegistration';
  export const clubProfileSettings_showTextAboutTrainer: PermissionKey = 'clubProfileSettings_showTextAboutTrainer';
  export const clubProfileSettings_disableConfirmSignUpWithoutSubscription: PermissionKey = 'clubProfileSettings_disableConfirmSignUpWithoutSubscription';
  export const clubProfileSettings_requiredPhoneNumberForRegistration: PermissionKey = 'clubProfileSettings_requiredPhoneNumberForRegistration';
  export const clubProfileSettings_IsSubscriptionPurchaseDateChangeable: PermissionKey = 'clubProfileSettings_IsSubscriptionPurchaseDateChangeable';
  export const clubProfileSettings_IsHiddenImTheClubButton: PermissionKey = 'clubProfileSettings_IsHiddenImTheClubButton';
  export const clubProfileSettings_IsSetSubscriptionWhenBookFromCrm: PermissionKey = 'clubProfileSettings_IsSetSubscriptionWhenBookFromCrm';
  export const clubProfileSettings_IsSetSubscriptionForGroupAutoCreatedVisits: PermissionKey = 'clubProfileSettings_IsSetSubscriptionForGroupAutoCreatedVisits';
  export const clubProfileSettings_NumberMinutesBeforeStartCannotCancelBooking: PermissionKey = 'clubProfileSettings_NumberMinutesBeforeStartCannotCancelBooking';

  export const isExcelExportAvailable: PermissionKey = 'isExcelExportAvailable';
  export const isRequestChatAvailable: PermissionKey = 'isRequestChatAvailable';
