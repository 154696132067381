import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';

import { IPlanVariant } from 'app/common/models/plan-variant.model';
import { IDurationUnit } from 'app/common/models/subscription.model';
import { EntityStatus } from 'app/common/models/entity-status.model';
import {ClubSetting} from '../../../common/models/club-setting.model';
import {ApiService} from '../../../common/services/api.service';
import {ContextService} from '../../../common/services/context.service';

@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VariantsComponent),
    multi: true
  }]
})
export class VariantsComponent implements ControlValueAccessor, OnInit {

  @Input() VisiblePriceOnSaleCampain: boolean;

  public data: Array<IPlanVariant>;

  public isResource = false;
  public clubSettings: ClubSetting[] = [];

  constructor(private apiService: ApiService, private contextService: ContextService) { }

  private modelTemplate = (isUnlimited: boolean) => {
    const model: IPlanVariant = {
      visitCount: isUnlimited ? 10000 : 1,
      durationUnit: IDurationUnit[IDurationUnit.month],
      duration: 1,
      entityStatus: EntityStatus.published,
      price: 0,
      priceOnSaleCampain: 0,
      id: null,
      title: null
    };
    return model;
  }

  public addVariant(isUnlimited: boolean) {
    const item = this.modelTemplate(isUnlimited);
    this.data.push(item);
  }

  public isNotDeleted(item: IPlanVariant) {
    return item.entityStatus !== EntityStatus.deleted;
  }

  async ngOnInit(): Promise<any> {
    this.clubSettings = await this.apiService.clubSettings.getByClub(this.contextService.getRoutingParams().clubId);
    if (this.clubSettings.find(x => x.alias === 'isResource'))
    this.isResource = JSON.parse(this.clubSettings.find(x => x.alias === 'isResource')?.value) ?? false;
  }

  public onChange() { }

  public onDelete(event: IPlanVariant) {
    const index = this.data.indexOf(this.data.find(x => { return x === event; }));
    const _data: Array<IPlanVariant> = _.cloneDeep(this.data);
    if (event.id == null) {
      _data.splice(index, 1);
    } else {
      _data[index].entityStatus = EntityStatus.deleted;
    }
    this.writeValue(_data);
    this.propagateChange(this.data);
  }

  private propagateChange: (any?: any) => void = () => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.data = this.data.sort((a, b) => {
        return a.visitCount - b.visitCount || a.price - b.price;
      });
      this.propagateChange(this.data);
    }
  }
}
