<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <td class="table-title-cell">Цена</td>
        <td class="table-title-cell">Название</td>
        <td class="table-title-cell">Описание</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!data || !data.some(predicatePublished)"><td colspan="3" style="text-align:center;">Список пуст</td></tr>
      <ng-container *ngFor="let item of data">
        <tr app-club-event-ticket-edit [data]="item" (onDelete)="onDelete(item)" (onChange)="onChange($event)" *ngIf="isNotDeleted(item)"></tr>
      </ng-container>
    </tbody>
  </table>
</div>
