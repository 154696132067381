<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Реестры
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container>
    <div class="clubnets-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container>
            <a class="box-link" (click)="create()">Создать
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
            <a class="box-link" (click)="create('summaryReport')">[Сводный отчет]</a>
            <a class="box-link" (click)="create('detailedRegister')">[Детальный реестр]</a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Кол-во клубов</td>
              <td class="table-cell table-title-cell">Сумма</td>
              <td class="table-cell table-title-cell">Статус</td>
              <td class="table-cell table-title-cell">Удалить</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let r of registry'>
              <td class="table-cell" (mousedown)="onEditRegistry(r,$event)" (click)="onEditRegistry(r)">
                <span class="table-cell__mobile-title">Название</span>
                <span>Реестр от {{dateFormat(r.dateFrom)}} - {{dateFormat(r.dateTo)}}</span>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r,$event)" (click)="onEditRegistry(r)">
                <span class="table-cell__mobile-title">Кол-во клубов</span>
                <span>{{data(r.data, 'ClubCount')}}</span>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r,$event)" (click)="onEditRegistry(r)">
                <span class="table-cell__mobile-title">Сумма</span>
                <span>{{data(r.data, 'Amount')}} руб.</span>
              </td>
              <td class="table-cell" (mousedown)="onEditRegistry(r,$event)" (click)="onEditRegistry(r)">
                <span class="table-cell__mobile-title">Статус</span>
                <span *ngIf="r.status == 'draft'" style="color: red;">Черновик</span>
                <span *ngIf="r.status == 'created'" style="color: #dede00;">Создан</span>
                <span *ngIf="r.status == 'confirmed'" style="color: yellowgreen;">Подтвержденный</span>
                <span *ngIf="r.status == 'completed'" style="color: #01e001;">Завершенный</span>
              </td>
              <td class="table-cell table-cell_ignore" style="text-align: center;">
                <span class="table-cell__mobile-title">Удалить</span>
                <button class="btn btn-clear active" (click)="remove(r.id)">
                  <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>
