import { FormControl } from '@angular/forms';

export const CheckJson = () => {
    return ( c: FormControl ) => {
        if ( c.value == null || c.value === '' ) {
            return null;
        }
        try {
            const obj = JSON.parse(c.value);
            return null;
        }
        catch (error) {
            return {
                CheckJson: {
                  valid: false
                }
            };
        }
    }
}
