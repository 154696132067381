<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Настройки
    </span>
  </div>
</header>

<div class="content__body">
  <app-password></app-password>
</div>
