<form [formGroup]="form">
  <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-3 param-item ng-star-inserted">
        <div class="form-group">
          <div class="box-title small">Сумма выплат</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="payout" type="number"
              formControlName="payout" step=".01" (input)="setTwoNumberDecimal($event)">
            <label class="field-validation-error" *ngIf="form.get('payout').touched && form.get('payout').invalid">Это
              поле обязательно</label>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 param-item ng-star-inserted">
        <div class="form-group">
          <div class="box-title small">Дата</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="date" type="text"
              placeholder="В формате дд.мм.гггг" formControlName="date" />
            <label class="field-validation-error" *ngIf="form.get('date').touched && form.get('date').invalid">Это поле
              обязательно</label>
          </div>
        </div>
      </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-3">
      <button class="btn btn-primary btn-small" (click)="save()" [disabled]="form.invalid || isNotEmptyValue">
        <span class="btn-primary__desc">Сохранить</span>
      </button>
    </div>
  </div>
</form>
