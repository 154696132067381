import { Component, OnInit, HostListener } from '@angular/core';
import { IClientOrderInfo } from 'app/common/models/subscription.model';
import { Location } from '@angular/common';
import { ClientsService } from 'app/components/clients/services/clients.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { numberValidator } from 'app/common/validators/number.validator';
import { SubscriptionService } from '../subscriptions/services/subscription.service';
import { CoursesService } from '../courses/services/courses.service';
import { ContextService } from 'app/common/services/context.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {ClubSetting} from '../../common/models/club-setting.model';
import {ClubSettingService} from '../club-settings/club-settings.service';

@Component({
  selector: 'app-surcharge',
  templateUrl: './surcharge.component.html',
  styleUrls: ['./surcharge.component.scss'],
  providers: [ClientsService, SubscriptionService]
})
export class SurchargeComponent implements OnInit {
  public static readonly componentName: string = 'SurchargeComponent';

  public extension: IClientOrderInfo;
  public formsSurcharge: FormGroup = null;

  private paymentDate: string;
  public surcharge: number;
  private source: string;
  private diffSum: number;
  private answer: string;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', '-'];
  private regex: RegExp = new RegExp(/^(?:-?\d+(\.?|,?)?\d{0,2}|\-)$/g );
  public clubSettings: ClubSetting[];
  public isPaymentReceipt = false;

  get fieldMessages() {
    if (this.diffSum > 0) {
      this.answer = `Общая сумма оплат по абонементу превышает его цену`
    } else {
      this.answer = `Абонемент уже оплачен, но можно произвести доплату`
    }
    if (this.surcharge < 0) {
      this.answer = `Общая сумма оплат по абонементу уйдет в минус`
    }
    return {
      surcharge: {
        numberValidator: this.answer
      }
    }
  }

  constructor(
    public _location: Location,
    public service: ClientsService,
    private formBuilder: FormBuilder,
    public clubSettingService: ClubSettingService,
    public subscriptionService: SubscriptionService,
    public coursesService: CoursesService,
    public contextService: ContextService,
    private route: ActivatedRoute
  ) { }

  Back(): void {
    this._location.back();
  }

  buildForm() {
    this.diffSum = this.extension.sum - this.extension.paid;
    this.formsSurcharge = this.formBuilder.group({
      surcharge: [this.surcharge, [Validators.required, numberValidator(-Infinity, Math.pow(2, 53) - 1 || 0)]]
    });
  }

  toDate(date: number) {
    return moment(date).format('DD.MM.YYYY');
  }

  ngOnInit() {
    this.surcharge = 0;
    this.paymentDate = '';
    this.source = 'Card';

    this.route.queryParams.subscribe(params => {
      const subsId: string = params['subsId'];
      this.service.getClientOrder(subsId)
        .then(res => {
          this.extension = res;
          this.buildForm();
        });
    });
    this.clubSettingService.getClubSettingsByClub()
      .then(settings => {
        this.clubSettings = settings;
        const alias = this.clubSettings.find(s => s.alias === 'enablePaymentReceipt');
        this.isPaymentReceipt = alias.value === 'true';
      });
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(e) {
    if (e.keyCode === 27) {
      this._location.back();
    }
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formsSurcharge.controls[controlName];
    if (+control.value > 0) {return +control.value + this.extension.paid > this.extension.sum}
    if (+control.value < 0) {return +control.value + this.extension.paid < 0}
  }

  selectSource(e: string): void {
    this.source = e;
  }

  onSubmit() {
    const controls = this.formsSurcharge.controls;
    if (this.formsSurcharge.invalid) {
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      return;
    }
    this.service.createPayment({
      orderId: this.extension.id,
      amount: this.surcharge,
      paymentDate: this.paymentDate,
      paymentMethod: this.source
    });
    if (this.formsSurcharge.valid) {
      this._location.back();
    }
  }
  Mask(event: KeyboardEvent) {
    if ((this.specialKeys.indexOf(event.key) !== -1) || (event.code === 'KeyA' && event.ctrlKey)) {
      return;
    }
    const current: string = String(this.surcharge);
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
