import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ICityModel } from 'app/common/models/city-model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class CityService extends TableListService<ICityModel> {

  public currentCity: BehaviorSubject<ICityModel> = new BehaviorSubject(null);

  constructor(injector: Injector) {
    super(injector);
  }

  getItemList(): Promise<ICityModel[]> {
    return this.apiV2Service.cities.getAll();
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiV2Service.cities.get(req.id);
  }

  save(model: ICityModel) {
    return this.apiV2Service.cities.save(model);
  }

  create(model: ICityModel) {
    return this.save(model);
  }

  setStatus(status: string, item: ICityModel) {
    // TODO: нормальная смена статуса без пересохранения.
    return this.apiV2Service.cities.get(item.id)
      .then(res => {
        res.entityStatus = status;
        this.apiV2Service.cities.save(res);
      });
  };


  // TODO: привести это в соответствие с настоящими городами и вообще разобрать
  //#region временный хлам
  private readonly UTCoffsetClub = 3; // Сейчас по мск, потом получать с сервера

  getTimestampClub() {
    const x = new Date();
    return x.getTime() + (x.getTimezoneOffset() * 60 * 1000) + (this.UTCoffsetClub * 60 * 60 * 1000);
  }

  checkTime(timeFrom, timeTo, tm: any = false) {
    const clubTimestamp = this.getTimestampClub();
    const timestamp = typeof tm === 'number' ? tm : clubTimestamp;
    const tF = typeof timeFrom === 'number' ? clubTimestamp + (timeFrom * 60 * 60 * 1000) <= timestamp : true;
    const tT = typeof timeTo === 'number' ? new Date(timeTo).getDate() == new Date(timestamp).getDate() : true;
    return tF && tT;
  }
  //#endregion
}
