import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe( { name: 'time2string' } )
export class Time2StringPipe implements PipeTransform {
    transform ( value: Date, TimeZone = null ) {
      if (TimeZone) {
        return moment(value).utcOffset(TimeZone).format('DD.MM.YYYY HH:mm');
      }
      return moment(value).utc().format('DD.MM.YYYY HH:mm');
    }
}
