import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Injector } from '@angular/core';

import { debounceTime } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';

import { SupportService } from './support.service';
import { IOnValueChanged, onValueChanged, OnValueChangeBase } from 'app/common/validators/on-value-changed.class';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent extends OnValueChangeBase implements OnInit, IOnValueChanged {
  public static readonly componentName: string = 'SupportComponent';

  public form: FormGroup = null;
  public isSubmited = false;
  protected message: string = null;

  fieldMessages = {
    message: {
      required: 'Это поле обязательно'
    },
  };

  constructor(
    injector: Injector,
    private service: SupportService,
    public formBuilder: FormBuilder
  ) {
    super(injector);
  }

  submit() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    this.message = this.form.value.message;
    this.service.submit({ message: this.message, pageUrl: window.location.href }).
      then(() => {
        this.form.patchValue({ message: null });
        this.isSubmited = false;
      })
      .catch(e => console.log(e));
  }

  focus() {
    setTimeout(() => {
      document.getElementsByTagName('app-support')[0].scrollIntoView();
    }, 1000);

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: [this.message, [Validators.required]],
    });

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }
}
