<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="Back()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Заморозка абонемента
      </span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="Back()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body">
  <div class="preloader-wrap" [ngClass]="{'loading': isLoading}">
    <div class="preloader-wr">
      <div class="preloader">
        <img class="preloader__road" src="assets/preloader/route.svg" alt="preloader">
        <img class="preloader__bike" src="assets/preloader/bike_2.svg" alt="preloader">
      </div>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
            Абонемент:
          </div>
          <div class="row__field">
            {{extension && extension.name}}{{(extension.fromDate && extension.toDate ? ', с ' + extension.fromDate + ' до ' + extension.toDate : null)}}{{', ' + extension.visitCounter}}/{{extension.visitCount < 10000 ? extension.visitCount : '∞'}}
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
            Клиент:
          </div>
          <div class="row__field">
            {{extension.clientName}}
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-8">
        <div class="form-group">
          <div class="box-title">
            Установить дату начала:
          </div>
          <div class="row__field">
            <app-date-picker placeholder="Дата начала" format="DD.MM.YYYY" formControlName="fromDate"
            ></app-date-picker>
            <label class="field-validation-error" *ngIf="isMoreDateFrom">
              Дата начала не может быть больше даты конца
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="box-title">
            Установить дату окончания:
          </div>
          <div class="row__field">
            <app-date-picker placeholder="Дата окончания" format="DD.MM.YYYY" formControlName="toDate"
            ></app-date-picker>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-group">
          <div class="box-title">
          </div>
          <div class="row__field">
            <button class="btn btn-primary" type="submit" [disabled]="form.invalid || checkDate()">
              <span class="btn-primary__desc">Заморозить</span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">
            История заморозки:
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="table-responsive">
          <table class="table rowHover">
            <thead>
            <tr>
              <td class="table-cell table-title-cell">№</td>
              <td class="table-cell table-title-cell">Период заморозки</td>
              <td class="table-cell table-title-cell">Дата заморозки</td>
              <td class="table-cell table-title-cell">Дата разморозки</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let suspend of historySuspend; let i = index">
              <td class="table-cell"><span class="thead">№:</span>{{i+1}}</td>
              <td class="table-cell"><span class="thead">Период заморозки:</span>{{suspend.fromDate | date}} / {{suspend.toDate | date}}</td>
              <td class="table-cell"><span class="thead">Дата заморозки:</span>{{suspend.suspendDate | date}}</td>
              <td class="table-cell"><span class="thead">Дата разморозки:</span>{{suspend.reactivateDate | date}}</td>
            </tr>
            <tr *ngIf="!historySuspend.length">
              <td class="table-cell" colspan="4" style="text-align: center;">Заморозка не производилась</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
