import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IReportParameter} from '../../../common/models/reports.model';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-date-picker-registry',
  templateUrl: './date-picker-registry.component.html',
  styleUrls: ['./date-picker-registry.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerRegistryComponent),
    multi: true
  }]
})
export class DatePickerRegistryComponent implements  ControlValueAccessor, OnChanges {
  private controlValue: any;

  @Input() param: IReportParameter;
  public control: FormControl;

  public ngOnChanges(changes: SimpleChanges): any {
    if (changes['param']) {
      const _moment = moment();
      if (this.param.editor === 'PeriodSelectorFrom') {
        if (parseInt(_moment.format('D')) >= 5 && parseInt(_moment.format('D')) <= 20) {
          this.control = new FormControl(_moment.add(-1, 'month').startOf('month'));
        } else {
          if (parseInt(_moment.format('D')) > 20 && parseInt(_moment.format('D')) <= parseInt(_moment.endOf('month').format('D'))) {
            this.control = new FormControl(_moment.startOf('month'));
          } else {
            this.control = new FormControl(_moment.add(-1, 'month').startOf('month'));
          }
        }
      } else {
        if (parseInt(_moment.format('D')) >= 5 && parseInt(_moment.format('D')) <= 20) {
          this.control = new FormControl(_moment.add(-1, 'month').endOf('month'));
        } else {
          if (parseInt(_moment.format('D')) > 20 && parseInt(_moment.format('D')) <= parseInt(_moment.endOf('month').format('D'))) {
            this.control = new FormControl(_moment.startOf('month').add(14, 'day'));
          } else {
            this.control = new FormControl(_moment.add(-1, 'month').startOf('month').add(14, 'day'));
          }
        }
      }
      setTimeout(() => this.writeValue(this.control.value.format('DD.MM.YYYY')), 1);
    }
  }
  public onBlur() {
    this.control.setValue(moment(this.controlValue, 'DD.MM.YYYY'), { emitEvent: false });
  }

  public dateChange(event: MatDatepickerInputEvent<moment.Moment>): any {
    if (event.value) {
      const rgx = new RegExp(/^\d{1,2}.\d{1,2}.\d{4}$/);
      const input = event.value.creationData().input;
      if (typeof input === 'object' || (typeof input === 'string' && rgx.exec(input))) {
        this.writeValue(event.value.format('DD.MM.YYYY'));
        return;
      }
    } else {
      //alert()
      this.writeValue(null);
    }
  }

  public registerOnChange(fn: any): any {
    this.propagateChange = fn;
  }

  public writeValue(obj: any): any {
    this.controlValue = obj;
    this.propagateChange(this.controlValue);
  }

  private propagateChange = (_: any): any => { };
  public registerOnTouched(): any { }
}
