<div class="" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit" *ngIf="!isReadonly">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>

  <div class="row__field" [hidden]="enableEdit" (click)="onBeginEdit()">
    <div class="field wysiwyg">
      <span *ngIf="tempData.geoLat && tempData.geoLon ; else noCoords">{{tempData.geoLat}}, {{tempData.geoLon}}</span>
      <ng-template #noCoords><span class="gray ">[не указано]</span></ng-template>
    </div>
  </div>

  <div class="row__field pd" [hidden]="!enableEdit">
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-6">
          <a href="https://yandex.ru/map-constructor/location-tool/" target="_blank">
            <span class="btn-primary__desc">Найти координаты на карте</span>
          </a>
        </div>
      </div>
      <div class="row">

        <div class="col-xs-12 col-sm-6 col-lg-6">
          <div class="form-group" myScrollTo>
            <label class="box-title">Широта</label>
            <input class="form-control input__text input__text--long" maxlength="500" placeholder="Широта" name="geoLat" [(ngModel)]="tempData.geoLat"
              formControlName="geoLat" />
            <label for="geoLat" class="field-validation-error" *ngIf="fieldErrors.geoLat && isSubmited">{{fieldErrors.geoLat}}</label>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-6">
          <div class="form-group">
            <label class="box-title">Долгота</label>
            <input class="form-control input__text input__text--long" maxlength="500" placeholder="Долгота" name="geoLon" [(ngModel)]="tempData.geoLon"
              formControlName="geoLon" />
            <label for="geoLon" class="field-validation-error" *ngIf="fieldErrors.geoLon && isSubmited">{{fieldErrors.geoLon}}</label>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
