import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appRestrictInput]'
})
export class RestrictInputDirective {

  private regex: RegExp = new RegExp(/^\d+(\.?|,?)?(\d{0,2})?$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  @Input() appRestrictInput: { min: number, max: number };
  constructor(
    private el: ElementRef
  ) { }


  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
    const nextInt = parseInt(next, 10);

    if (nextInt > this.appRestrictInput.max || nextInt < this.appRestrictInput.min) {
      event.preventDefault();
      if (nextInt > this.appRestrictInput.max) {
        this.el.nativeElement.value = this.appRestrictInput.max;
      }
      if (nextInt < this.appRestrictInput.min) {
        this.el.nativeElement.value = this.appRestrictInput.min;
      }
    }
  }
}
