import { LegalInfoComponent } from './../../components/clubs/club-edit/legal-info/legal-info.component';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import {Registry, RegistryElementStatus} from '../models/registry.model';
import {ICityModel} from '../models/city-model';
import * as moment from 'moment';
import { WorkSheet } from 'xlsx';
import { includes } from 'lodash';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const ZIP_EXTENSION = '.zip';

@Injectable()
export class ExcelService {

  //
  headersForDayView = [];

  constructor() { }

  public async exportAsExcelFile(json: any[], excelFileName: string) {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };

    // Ширина столбцов
    myworksheet['!cols'] = [
      { wpx: 140 },
      { wpx: 140 },
      { wpx: 140 },
      { wpx: 140 },
      { wpx: 140 },
      { wpx: 140 },
      { wpx: 140 }
    ];

    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public async exportAsExcelFileInZip(json: any[], excelFileName: string, d?: any[], registry?: Registry, cities?: ICityModel[]): Promise<any> {
    json = [];
    const excelBuffer: any[] = [];
    for (let i = 0; i <= d.length; i++) {
      if (typeof d[i][3]?.isExcludeTable === 'undefined' || !d[i][3]?.isExcludeTable) {
        const e: any[] = [];
        d[i][1].forEach(m => {
          if (m.status === RegistryElementStatus.published || m.status === RegistryElementStatus.hold) {
            e.push(m.amount / 100 * m.commission);
          }
        });
        const registerAmountTotal = d[i][2].amount - e.reduce((a, b) => a + b, 0);
        const details = {
          clubName: d[i][1][0].club,
          registerAmount: d[i][2].amount.toString() + ' руб.',
          records: d[i][1].filter(x => x.status !== RegistryElementStatus.deleted).length,
          registryDate: moment().format('DD.MM.YYYY'),
          paymentDates: `${moment(registry.dateFrom).format('DD.MM.YYYY')} - ${moment(registry.dateTo).format('DD.MM.YYYY')}`,
          registerAmountTotal: registerAmountTotal.toString() + ' руб.'
        };
        const exportA: any[] = [];
        d[i][1].filter(x => x.status !== RegistryElementStatus.deleted).forEach((m, index) => {
          const city = cities.filter(x => x.id === m.cityId)[0]?.name;
          const idx = ++index;
          exportA.push({
            '№': idx.toString(),
            'Контакт': m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
            'ФИО': m.fullName?.replace(',', '').replace(';', '').trim(),
            'Коментарий': m.comment?.replace(',', '').replace(';', '').trim(),
            'Дата': m.date.slice(0, -8),
            'Платеж': m.amount.toString().trim() + ' руб.',
            'Банк': m.bankName,
            'Город': city.trim(),
            'Комиссия': m.commission.toString().replace('.', ',').trim() + '%',
          });
        });
        await excelBuffer.push({
          clubName: 'Реестр ' + d[i][1][0].club + ' (' + exportA[0]['Город'] + ')',
          table: await this.excelBuffer(exportA, details)
        });
        if (i === (d.length - 1)) {
          this.saveAsExcelFileInZip(excelBuffer, excelFileName);
        }
      }
    }
  }

  // --- --- --- Экспорт расписания
  public exportAsScheduleExcelFile1(json: any[], mainHeaders, headers: any[], settings: any, excelFileName: string){
    const Excel = require('exceljs');

    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('Расписание');

    let col1 = ws.getColumn(1);
    col1.width = 15;

    let categoryItems = settings.categoryFilters;
    for (let cInd = 2; cInd < 150; cInd++) {
      col1 = ws.getColumn(cInd);
      col1.width = 30;
    }
    let r = ws.getRow(1);

    console.log('------------------------------------------ exportAsScheduleExcelFile1 ------------------------------------------')
    console.log(json)
    console.log(settings)

    for (let i = 0; i < json.length; i++) {
      let row = json[i];
      console.log('----- row -----');
      console.log(row);
      for (let j = 0; j < row.length; j++) {
        let cellItem = row[j];
        if (cellItem != null) {
          console.log(cellItem);
          if (!(typeof cellItem === "string")) { // вывод информации в ячейке
            r = ws.getRow(i + 1);
            r.getCell(j + 1).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" }
            };
            r.getCell(j + 1).alignment = { vertical: 'middle', horizontal: 'center', indent: 2, wrapText: true };
            if (settings.exportFieldColor) {
              let color = cellItem?.course?.color || "#FFF";
              r.getCell(j + 1).fill  = { type: 'pattern', pattern:'solid', fgColor:{ argb: "FF" + color.substring(1) } };
            }
            r.getCell(j + 1).value = this.formatResult1(cellItem, settings);
          }
          else { // вывод текста
            //if (j != 0) {
            if (true) {
              r = ws.getRow(i + 1);
              r.getCell(j + 1).border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
              };
              r.getCell(j + 1).alignment = { vertical: 'middle', horizontal: 'center', indent: 2, wrapText: true };
              r.getCell(j + 1).value = cellItem == null ? '' : cellItem;
            }
            else {
              ;
            }
          }
        }
      }
    }
    let _time = json[0];
    let _ch = [];
    _ch.push(0);
    for (let k = 1; k < json.length; k++) {
      if (_time != json[k][0]) {
        _ch.push(k - 1);
        _time = json[k][0];
        _ch.push(k);
      }
    }
    /*console.log('_ch');
    console.log(_ch);
    let rrrr = 1;
    for (let t = 0; t < _ch.length - 1; t += 2) {
      let r1 = _ch[t] + 1;
      let r2 = _ch[t + 1] + 1;
      console.log('r1');console.log(r1);
      console.log('r2');console.log(r2);
      ws.mergeCells(r1, 1, r2, 1);

      r = ws.getRow(rrrr);
      rrrr++;
      r.getCell(1).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      r.getCell(1).alignment = { vertical: 'middle', horizontal: 'center', indent: 2, wrapText: true };
      r.getCell(1).value = '';
      if (_ch[t] > 0) {
        r.getCell(1).value = json[_ch[t]][0];
      }
    }*/

    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), excelFileName + '.xlsx'))
      .catch(err => console.log('Error writing excel export', err));
  }
  public exportAsScheduleExcelFile(json: any[], headers: any[], settings: any, excelFileName: string): void {
    const Excel = require('exceljs');

    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('Расписание');

    let col1 = ws.getColumn(1);
    col1.width = 15;

    if (settings.mode === "today") { /////// Экспорт по дням
      let categoryItems = settings.categoryFilters;
      let countColumns = headers.length;
      for (let cInd = 2; cInd < 150; cInd++) {
        col1 = ws.getColumn(cInd);
        col1.width = 30;
      }
      let r = ws.getRow(1);
      // for (let i = 0; i < countColumns; i++) {
      //   r.height = 30;
      //   if (i == 0){
      //     r.getCell(i + 1).border = {
      //       top: { style: "thin" },
      //       left: { style: "thin" },
      //       bottom: { style: "thin" },
      //       right: { style: "thin" }
      //     };
      //     r.getCell(i + 1).font = {
      //       size: 12,
      //       bold: true
      //     };
      //     r.getCell(i + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      //     r.getCell(i + 1).value = headers[i];
      //   }
      //   else {
      //     let _col1 = 2 + categoryItems.length * (i - 1);
      //     let _col2 = 2 + categoryItems.length * i - 1;
      //     ws.mergeCells(1, _col1, 1, _col2);
      //     r.getCell(2 + categoryItems.length * (i - 1)).border = {
      //       top: { style: "thin" },
      //       left: { style: "thin" },
      //       bottom: { style: "thin" },
      //       right: { style: "thin" }
      //     };
      //     r.getCell(2 + categoryItems.length * (i - 1)).font = {
      //       size: 12,
      //       bold: true
      //     };
      //     r.getCell(2 + categoryItems.length * (i - 1)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      //     r.getCell(2 + categoryItems.length * (i - 1)).value = headers[i];
      //   }
      //   if (i > 0) {
      //     r = ws.getRow(2);
      //     r.height = 30;
      //     for (let j = 0; j < categoryItems.length; j++) {
      //       r.getCell(j + 2 + categoryItems.length * (i - 1)).border = {
      //         top: { style: "thin" },
      //         left: { style: "thin" },
      //         bottom: { style: "thin" },
      //         right: { style: "thin" }
      //       };
      //       r.getCell(j + 2 + categoryItems.length * (i - 1)).font = {
      //         size: 12,
      //         bold: true
      //       };
      //       r.getCell(j + 2 + categoryItems.length * (i - 1)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      //       r.getCell(j + 2 + categoryItems.length * (i - 1)).value = categoryItems[j].name;
      //     }
      //   }
      //   r = ws.getRow(1);
      // }

      let _newHeaders = [];
      _newHeaders.push({
        'header': 'Время',
        'filter': settings.categoryMode == 'room' ? 'Залы' : settings.categoryMode == 'couches' ? 'Тренеры' : 'Виды занятий',
      });
      for (let h = 1; h < headers.length; h++) {
        for (let f = 0; f < categoryItems.length; f++) {
          _newHeaders.push({
            'header': headers[h],
            'filter': categoryItems[f].name
          });
        }
      }
      let table = [];
      let newRow = [];
      for (let indRow = 0; indRow < json.length; indRow++) {
        let row = json[indRow];
        for (let indHeader = 0; indHeader < _newHeaders.length; indHeader++) {
          let header = _newHeaders[indHeader];
          let item = row[header.header];
          if (header.header == "Время") {
            newRow.push(item);
          }
          if (Array.isArray(item) && item[0] != '') {
            let items = [];
            for (let y = 0; y < item.length; y++) {
              if (settings.categoryMode == 'room' && item[y].zal == header.filter) {
                items.push(item[y]);
              }
              if (settings.categoryMode == 'couches' && item[y].coachName == header.filter) {
                items.push(item[y]);
              }
              if (settings.categoryMode == 'courses' && item[y].courceName == header.filter) {
                items.push(item[y]);
              }
              if (header.filter == 'Не задано' && (item[y].coachName == "" || item[y].zal == "")) {
                items.push(item[y]);
              }
            }
            newRow.push(items);
            items = [];
          }
          else {
            newRow.push([]);
          }
        }
        table.push(newRow);
        newRow = [];
      }
      let _countArr = [];
      let _max = 0;
      for (let tr = 0; tr < table.length; tr++) {
        let row = table[tr];
        for (let i = 0; i < row.length; i++) {
          if (Array.isArray(row[i])) {
            if (row[i].length > _max) {
              _max = row[i].length;
            }
          }
        }
        if (_max == 0) {
          _max = 1;
        }
        _countArr.push(_max);
        _max = 0;
      }
      let rid = 3;
      let k = 0, s = 0;
      let indRow = 0;

      // Проверяем наличие пустых столбцов
      let isNullColumns = this.listNullColumns(table);
      let countFillColumns = isNullColumns.filter(e => e > 0 ? true : false).length;
      console.log('isNullColumns');
      console.log(isNullColumns);
      console.log('countFillColumns');
      console.log(countFillColumns);
      // Выводим данные в Эксель
      r = ws.getRow(1);
      console.log('headers');
      console.log(headers);
      for (let i = 0; i < countColumns; i++) {
        r.height = 30;
        if (i == 0){
          r.getCell(i + 1).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          r.getCell(i + 1).font = {
            size: 12,
            bold: true
          };
          r.getCell(i + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          r.getCell(i + 1).value = headers[i];
        }
        else {
          let _ = isNullColumns.length / (headers.length - 1);
          console.log('_');
          console.log(_);
          let _1 = isNullColumns.slice((i - 1) * _, i * _);
          console.log('_1');
          console.log(_1);
          let _2 = _1.filter(e => e > 0 ? true : false).length - 1;
          console.log('_2');
          console.log(_2);
          //debugger
          let _col1 = 2 + _2 * (i - 1);
          let _col2 = 2 + _2 * i;
          ws.mergeCells(1, _col1 + 1, 1, _col2);
          r.getCell(_col2).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          r.getCell(_col2).font = {
            size: 12,
            bold: true
          };
          r.getCell(_col2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          r.getCell(_col2).value = headers[i];
        }
        if (i > 0) {
          // r = ws.getRow(2);
          // r.height = 30;
          // let shift = i + 1;
          // for (let j = 0; j < categoryItems.length - 1; j++) {
          //   if (isNullColumns[j + 1] > 0) {
          //     console.log('J');
          //   console.log(j);
          //   console.log('categoryItems');
          //   console.log(categoryItems);
          //     r.getCell(shift).border = {
          //       top: { style: "thin" },
          //       left: { style: "thin" },
          //       bottom: { style: "thin" },
          //       right: { style: "thin" }
          //     };
          //     r.getCell(shift).font = {
          //       size: 12,
          //       bold: true
          //     };
          //     r.getCell(shift).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          //     r.getCell(shift).value = categoryItems[j + 1].name;
          //     shift++;
          //   }
          // }
        }
        r = ws.getRow(1);
      }

      for(let tInadex = 0; tInadex < _countArr.length; tInadex++) {
        let row = table[tInadex];
        r = ws.getRow(rid);
        row.forEach(items => {
          if (typeof items === 'string' || items instanceof String ) {
            this.formatTimeInCell(r, k, items);
          }
          let isNullColl = (isNullColumns[indRow] > 0) ? true : false;
          if (isNullColl) {
            s++;
            if (Array.isArray(items) && items && items.length > 0 ) {
              for (let f = 0; f < items.length; f++)
              {
                let x = items[f];
                r = ws.getRow(rid + f);
                r.getCell(s).border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" }
                };
                r.getCell(s).alignment = { vertical: 'middle', horizontal: 'center', indent: 2, wrapText: true };
                if (settings.exportFieldColor) {
                  r.getCell(s).fill  = { type: 'pattern', pattern:'solid', fgColor:{ argb: "FF" + x.color.substring(1) } };
                }
                if (x.isShowInSchedule) {
                  r.getCell(k + 1).font = {
                    strike: true
                  };
                }
                r.getCell(s).value = this.formatResult(x, settings);

                //////////////
                let r2 = ws.getRow(2);
                r2.getCell(s).border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" }
                };
                r2.getCell(s).font = {
                  size: 12,
                  bold: true
                };
                r2.getCell(s).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                r2.getCell(s).value = settings.categoryMode == 'room' ? x.zal : settings.categoryMode == 'couches' ? x.coachName : settings.categoryMode == 'courses' ? x.courceName : "Не задано";
              }
            }
          }
          k++;
          indRow++;
        });
        s = 0;
        indRow = 0;
        let _sh = _countArr[tInadex];
        rid += _sh;
        k = 0;
      }
    }
    if (settings.mode === "week") { /////// Экспорт по недели
      let countColumns = headers.length;
      let rowNumber = 1;
      let r = ws.getRow(rowNumber);
      ws.getColumn(1).width = 15;
      for (let cInd = 2; cInd < 9; cInd++) {
        col1 = ws.getColumn(cInd);
        col1.width = 30;
      }
      this.formatHeaders(r, headers);
      let rid = 0;
      let max = 0;
      let arr = [];
      for (let i = 0; i < json.length; i++) {
        let row = json[i];
        for (let c = 0; c < countColumns; c++) {
          let item = row[headers[c]];
          if (Array.isArray(item) && item.length > 0) {
            if (max < item.length) {
              max = item.length;
            }
          }
        }
        rid += max;
        arr.push(rid);
        max = 0;
      }

      for (let t = 0; t < arr.length; t++) {
        let item = json[t];
        let rowsCount = arr[t];
        r = ws.getRow(rowsCount+1);
        for (let k = 0; k < headers.length; k++) {
          let prop = item[headers[k]];
          if (typeof prop === 'string' || prop instanceof String) {
            let in1 = t - 1;
            if (in1 >= 0) { in1 = arr[t - 1]; } else { in1 = 0; }
            ws.mergeCells(in1 + 2, 1, rowsCount + 1, 1);
            this.formatTimeInCell(r, k, prop);
          }
          else {
            if (prop && prop.length > 0 && prop[0] != "") {
              for (let f = 0; f < prop.length; f++) {
                let x = prop[f];
                let in1 = t - 1;
                if (in1 >= 0) { in1 = arr[t - 1]; } else { in1 = 0; }
                r = ws.getRow(in1 + f + 2);
                //r.height = 150;
                r.getCell(k + 1).border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" }
                };
                if (x.isShowInSchedule) {
                  r.getCell(k + 1).font = {
                    strike: true
                  };
                }
                r.getCell(k + 1).alignment = { horizontal: 'left', vertical: 'middle', indent: 2, wrapText: true };
                if (settings.exportFieldColor) {
                  r.getCell(k + 1).fill  = { type: 'pattern', pattern:'solid', fgColor:{ argb: "FF" + x.color.substring(1) } };
                }
                r.getCell(k + 1).value = this.formatResult(x, settings);
              }
            }
          }
        }
      }
    }
    if (settings.mode === "month") { ////// Экспорт по месяцу
      let shiftRows = 0;
      for (let cInd = 1; cInd < 8; cInd++) {
        col1 = ws.getColumn(cInd);
        col1.width = 30;
      }
      ws.columns.forEach(column => {
        column.border = {
          top: { style: "medium" },
          left: { style: "medium" },
          bottom: { style: "medium" },
          right: { style: "medium" }
        };
      });
      for (let shiftIndex = 0; shiftIndex < headers.length / 7; shiftIndex++) {
        let partHeaders = [
          headers[shiftIndex * 7],
          headers[shiftIndex * 7 + 1],
          headers[shiftIndex * 7 + 2],
          headers[shiftIndex * 7 + 3],
          headers[shiftIndex * 7 + 4],
          headers[shiftIndex * 7 + 5],
          headers[shiftIndex * 7 + 6]
        ];
        shiftRows = shiftRows + 1;
        let r = ws.getRow(shiftRows);
        r.height = 30;
        ws.getColumn(1).width = 30;
        for (let i = 0; i < partHeaders.length; i++) {
          r.getCell(i + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          r.getCell(i + 1).value = partHeaders[i];
        }
        let rid = 0;
        let max = 0;
        let arr = [];
        for (let i = 0; i < json.length; i++) {
          let row = json[i];
          for (let c = 0; c < partHeaders.length; c++) {
            let item = row[partHeaders[c]];
            if (Array.isArray(item) && item.length > 0) {
              if (max < item.length) {
                max = item.length;
              }
            }
          }
          rid += max;
          arr.push(rid);
          max = 0;
        }
        for (let t = 0; t < arr.length; t++) {
          let item = json[t];
          let rowsCount = arr[t] + shiftRows;
          r = ws.getRow(rowsCount + 1);
          r.height = 150;
          for (let k = 0; k < partHeaders.length; k++) {
            let prop = item[partHeaders[k]];
            if (typeof prop === 'string' || prop instanceof String) {
              let in1 = t - 1;
              if (in1 >= 0) { in1 = arr[t - 1]; } else { in1 = 0; }
              //ws.mergeCells(in1 + 2, 1, rowsCount + 1, 1);
              r.getCell(k + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
              r.getCell(k + 1).value = prop;
            }
            else {
              if (prop && prop.length > 0 && prop[0] != "") {
                for (let f = 0; f < prop.length; f++) {
                  let x = prop[f];
                  let in1 = t - 1;
                  if (in1 >= 0) { in1 = arr[t - 1]; } else { in1 = 0; }
                  r = ws.getRow(in1 + f + 1 + shiftRows);
                  r.height = 150;
                  r.getCell(k + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                  if (settings.exportFieldColor) {
                    r.getCell(k + 1).fill  = { type: 'pattern', pattern:'solid', fgColor:{ argb: "FF" + x.color.substring(1) } };
                  }
                  if (x.isShowInSchedule) {
                    r.getCell(k + 1).font = {
                      strike: true
                    };
                  }
                  r.getCell(k + 1).value = this.formatResult(x, settings);
                }
              }
            }
          }
        }
        shiftRows += arr[0];
      }
    }

    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), excelFileName + '.xlsx'))
      .catch(err => console.log('Error writing excel export', err));
  }
  public formatResult(x: any, d: any) {
    let res = "";
    if (d.exportFieldName && x?.courceName) { res += x?.courceName; }
    if (d.exportFieldDuration && x?.duration && x?.startTime) {
      res += "\r\n" +  x?.startTime + " - " + x?.period + " (" + this.convertMinutes(x.duration) + ")";
    }
    if (d.exportFieldTrener && x?.coachName) { res += "\r\n" + x?.coachName; }
    if (d.exportFieldZal && x?.zal) { res += "\r\n" + x?.zal; }
    if (d.exportFieldLevel && x?.level) { res += "\r\n" + x?.level; }
    if (d.exportFieldComment && x?.comment) { res += "\r\n" + x?.comment; }
    if (d.exportFieldZanatie && x?.zanatost) { res += "\r\n" + x?.zanatost; }
    return res;
  }
  public formatResult1(x: any, d: any) {
    let res = "";
    if (d.exportFieldName && x?.course.name) { res += x?.course.name; }
    if (d.exportFieldDuration && x?.duration && x?.startTime) {
      let _ = moment('01.01.1997 ' + x?.startTime).add(x?.duration, 'm').toDate();
      let period = _.getHours() + ":" + _.getMinutes();
      res += "\r\n" +  x?.startTime + " - " + period + " (" + this.convertMinutes(x.duration) + ")";
    }
    if (d.exportFieldTrener && x?.coach) { res += "\r\n" + x?.coach?.name; }
    if (d.exportFieldZal && x?.room) { res += "\r\n" + x?.room?.name; }
    if (d.exportFieldLevel && x?.level) { res += "\r\n" + x?.level; }
    if (d.exportFieldComment && x?.comment) { res += "\r\n" + x?.comment; }
    if (d.exportFieldZanatie) { res += "\r\n" + x?.numberOfVisits + "/" + x?.maxCapacity; }
    return res;
  }
  public convertMinutes(minutes: any) {
    let res = '';
    let all = minutes / 60;
    let R = Math.trunc(all);
    let D = all - R;
    if (R == 0) {
      res = (D * 60).toFixed(0) + "м";
    }
    else if (D == 0 && R != 0) {
      res = R + "ч";
    }
    else {
      res = R + "ч:" + (D * 60).toFixed(0) + "м";
    }
    return res;
  }
  public formatHeaders(row: any, headers: any[]) {
    for (let i = 0; i < headers.length; i++) {
      row.getCell(i + 1).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      row.getCell(i + 1).font = {
        size: 12,
        bold: true
      };
      row.getCell(i + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      row.getCell(i + 1).value = headers[i];
    }
  }
  public formatTimeInCell(row: any, ind: any, text: any) {
    row.getCell(ind + 1).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    row.getCell(ind + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    row.getCell(ind + 1).value = text;
  }
  public listNullColumns(table: any[]) {
    let res = [];
    for (let i = 0; i < table.length; i++) {
      let row = table[i];
      let r = [];
      for (let j = 1; j < row.length; j++) {
        if (row[j].length == 0) {
          r.push(true);
        }
        else {
          r.push(false);
        }
      }
      res.push(r);
      this.headersForDayView.push('');
    }
    let isNullCols = [];
    isNullCols.push(1);
    for (let i = 0; i < res[0].length; i++) {
      let countFalse = 0;
      for (let j = 0; j < res.length; j++) {
        if (!res[j][i]) {
          countFalse++;
        }
      }
      {
        isNullCols.push(countFalse)
      }
    }
    return isNullCols;
  }
  public removeNullHeaders(mainHeaders, headers, table) {
    console.log('-------------- mainHeaders --------------');
    console.log(mainHeaders)
    console.log('-------------- headers ------------------');
    console.log(headers)
    console.log('-------------- table --------------------');
    console.log(table)

    let inidces = [];
    for (let i = 0; i < table.length; i++) {
      for (let j = 0; j < table[i].length; j++) {
        if (table[i][j]) {
          if (!inidces.includes(j)) {
            inidces.push(j)
          }
        }
      }
    }
    inidces = inidces.sort((a, b) => a - b);
    let newMainHeaders = [];
    for (let mH = 0 ; mH < inidces.length; mH++) {
      newMainHeaders.push(mainHeaders[inidces[mH]]);
    }
    let newHeaders = [];
    for (let H = 0 ; H < inidces.length; H++) {
      newHeaders.push(headers[inidces[H]]);
    }

    console.log('========= newMainHeaders')
    console.log(newMainHeaders)
    console.log('========= newHeaders')
    console.log(newHeaders)

    return [newMainHeaders, newHeaders];
  }
  public removeNullColumn(table: any) {
    console.log('---------- Table ---------')
    console.log(table)
    let res = [];
    for (let i = 0; i < table.length; i++) {
      for (let j = 0; j < table[i].length; j++) {
        if (table[i][j]) {
          if (!res.includes(j)) {
            res.push(j)
          }
        }
      }
    }
    res = res.sort((a, b) => a - b);
    console.log('111111111111 res 111111111111')
    console.log(res)

    let newTable = [];
    for (let i = 0; i < table.length; i++) {
      let row = [];
      for (let k = 0; k < res.length; k++) {
        row.push(table[i][res[k]]);
      }
      newTable.push(row);
    }

    console.log('==================== newTable ===================')
    console.log(newTable)
    return newTable;
  }
  public removeNullColumns(table: any) {
    console.log('---------- Table ---------')
    console.log(table)
    let res = [];
    for (let i = 2; i < table.length; i++) {
      for (let j = 0; j < table[i].length; j++) {
        if (table[i][j]) {
          if (!res.includes(j)) {
            res.push(j)
          }
        }
      }
    }
    res = res.sort((a, b) => a - b);
    console.log('CCCCCCCCCCCCCCCCCCCCCCCCCC res CCCCCCCCCCCCCCCCCCCCCCCCCC')
    console.log(res)

    let newTable = [];
    for (let i = 0; i < table.length; i++) {
      let row = [];
      for (let k = 0; k < res.length; k++) {
        row.push(table[i][res[k]]);
      }
      newTable.push(row);
    }

    console.log('==================== newTable ===================')
    console.log(newTable)
    return newTable;
  }

  // --- --- --- Экспорт для тренеров
  public exportAsExcelFileCoaches(json: any[], excelFileName: string, details?: any): void {
    const excelBuffer = this.excelBufferCoaches(json, details);
    this.saveAsExcelFileForCoaches(excelBuffer, excelFileName);
  }

  private saveAsExcelFileForCoaches(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private excelBufferCoaches(json: any[], details?: any): any {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    myworksheet['B1'] = { t: 's', v: 'Реестр РЗ сотрудник '};
    myworksheet['C1'] = { t: 's', v: details.name};

    myworksheet['B3'] = { t: 's', v: 'Кол-во занятий: '};
    myworksheet['C3'] = { t: 'n', v: details.count};
    myworksheet['B4'] = { t: 's', v: 'Всего начислено тренеру: '};
    myworksheet['C4'] = { t: 'n', v: details.allAmount};
    myworksheet['B5'] = { t: 's', v: 'Выплачено тренеру:'};
    myworksheet['C5'] = { t: 'n', v: details.paid};
    myworksheet['B6'] = { t: 's', v: 'Осталось выплатить тренеру:'};
    myworksheet['C6'] = { t: 'n', v: details.unpaid};

    myworksheet['D1'] = { t: 's', v: details.date};

    XLSX.utils.sheet_add_json(myworksheet, json, {origin: 7});
    let name1 = details.name.trim();
    let mvs : { [sheet: string]: WorkSheet } = {};
    mvs[name1] = myworksheet
    const myworkbook: XLSX.WorkBook = { Sheets: mvs, SheetNames: [name1] };

    myworksheet['!cols'] = [
      { wpx: 20 },
      { wpx: 300 },
      { wpx: 150 },
      { wpx: 300 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 }
    ];

    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  // --- формирование листа
  private excelWorksheetCoach(details?: any): any {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    myworksheet['B1'] = { t: 's', v: 'Реестр РЗ сотрудник '};
    myworksheet['C1'] = { t: 's', v: details.name};

    myworksheet['B3'] = { t: 's', v: 'Кол-во занятий: '};
    myworksheet['C3'] = { t: 'n', v: details.count};
    myworksheet['B4'] = { t: 's', v: 'Всего начислено тренеру: '};
    myworksheet['C4'] = { t: 'n', v: details.allAmount};
    myworksheet['B5'] = { t: 's', v: 'Выплачено тренеру:'};
    myworksheet['C5'] = { t: 'n', v: details.paid};
    myworksheet['B6'] = { t: 's', v: 'Осталось выплатить тренеру:'};
    myworksheet['C6'] = { t: 'n', v: details.unpaid};

    myworksheet['D1'] = { t: 's', v: details.date};

    XLSX.utils.sheet_add_json(myworksheet, details.arrayDetails, {origin: 7});

    myworksheet['!cols'] = [
      { wpx: 20 },
      { wpx: 300 },
      { wpx: 150 },
      { wpx: 300 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 }
    ];

    return myworksheet;
  }

  public async exportAsExcelFileWithManyCoaches(excelFileName: string, detailsArray: any[]): Promise<any> {
    let mvs : { [sheet: string]: WorkSheet } = {};
    let names : string[] = [];
    detailsArray.forEach((item, ind) => {
      let exs = this.excelWorksheetCoach(item);
      mvs[item.name] = exs
      names.push(item.name)
    })

    const myworkbook: XLSX.WorkBook = { Sheets: mvs, SheetNames: names };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFileForCoaches(excelBuffer, excelFileName);
  }
  // --- --- --- --- ---

  public exportAsExcelFileReestr(json: any[], excelFileName: string, details?: any): void {
    const Excel = require('exceljs');

    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('Реестр');

    ws.mergeCells(1, 1, 1, 7);
    ws.mergeCells(2, 1, 2, 7);
    ws.mergeCells(5, 1, 5, 7);

    ws.getRow(1).height = 38; ws.getColumn(1).width = 4;   // A1 // Номер строки
    ws.getRow(2).height = 38; ws.getColumn(2).width = 15;  // B1 // Контакт
    ws.getRow(5).height = 42;
    if (details.sityExists) ws.getColumn(3).width = 15; else ws.getColumn(3).width = 17; // ФИО
    if (details.sityExists) ws.getColumn(4).width = 15; else ws.getColumn(4).width = 17; // Коментарий
    ws.getColumn(5).width = 10; // Дата
    ws.getColumn(6).width = 10; // Сумма
    ws.getColumn(7).width = 7; // Город
    ws.getColumn(8).width = 5; // Коммисия

    ws.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    ws.getCell('B1').font = { bold: true }
    ws.getCell('B1').value = 'Отчет агента об исполнении поручения по агентскому договору 30/21 от 02.02.2022 г.'

    ws.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    ws.getCell('B2').font = { bold: true }
    ws.getCell('B2').value = 'Между ' + details.payer + ' и ООО "ЕСА"'

    ws.getCell('B3').value = 'г.Новосибирск'
    ws.getCell('C3').value = '31.12.2022'
    ws.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    ws.getCell('B5').value = 'В соответствии с условиями договора по поручению Принципала Агентом оказаны услуги по продаже и бронированию спортивно-оздоровительных услуг'

    ws.getCell('A7').value = 'Реестр платежей Sport Priority'
    ws.getCell('A8').value = details.clubName

    ws.getCell('A10').value = 'Сумма реестра:'
    ws.getCell('A11').value = 'Записей:'
    ws.getCell('A12').value = 'Дата реестра:'
    ws.getCell('A13').value = 'Даты платежей:'
    ws.getCell('C10').value = details.registerAmount.toFixed(2) + ' руб.'
    ws.getCell('C11').value = details.records.toString()
    ws.getCell('C12').value = details.registryDate
    ws.getCell('C13').value = details.paymentDates

    ws.getCell('A16').value = '№'
    ws.getCell('B16').value = 'Контакт'
    ws.getCell('C16').value = 'ФИО'
    ws.getCell('D16').value = 'Коментарий'
    ws.getCell('E16').value = 'Дата'
    ws.getCell('F16').value = 'Платеж'
    if (details.sityExists) {
      ws.getCell('G16').value = 'Город'
      ws.getCell('H16').value = 'Комиссия'
    }
    else
      ws.getCell('G16').value = 'Комиссия'

    let ind = 17
    json.forEach(i => {
      ws.getCell('A' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('B' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('C' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('D' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('E' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('F' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('G' + ind).alignment = { vertical: 'top', wrapText: true }
      ws.getCell('H' + ind).alignment = { vertical: 'top', wrapText: true }

      ws.getCell('A' + ind).value = i.number
      ws.getCell('B' + ind).value = i.contact
      ws.getCell('C' + ind).value = i.fio
      ws.getCell('D' + ind).value = i.comment
      ws.getCell('E' + ind).value = i.date
      ws.getCell('F' + ind).value = i.amount
      if (details.sityExists) {
        ws.getCell('G' + ind).value = i.city
        ws.getCell('H' + ind).value = i.comm
      }
      else
        ws.getCell('G' + ind).value = i.comm
      ind++
    })

    ind += 2
    ws.getCell('B' + ind).value = 'Итого принято платежей:'
    ws.getCell('F' + ind).value = details.registerAmount.toFixed(2) + ' руб.'
    ind++
    ws.getCell('B' + ind).value = 'Вознаграждение Агента'
    ws.getCell('F' + ind).value = (details.registerAmount - details.registerAmountTotal).toFixed(2) + ' руб.'
    ind++
    ws.getCell('B' + ind).value = 'К перечислению за вычетом вознаграждения'
    ws.getCell('F' + ind).value = details.registerAmountTotal.toFixed(2) + ' руб.'

    ind += 3
    ws.getCell('B' + ind).value = 'Агент:'
    ws.getCell('E' + ind).value = 'Принципал:'
    ind++
    ws.getCell('B' + ind).value = 'ООО «ЕДИНЫЙ СПОРТИВНЫЙ АБОНЕМЕНТ»'
    ws.getCell('E' + ind).value = details.payer

    ind += 2
    ws.getCell('B' + ind).value = '________________________'
    ws.getCell('E' + ind).value = '________________________'

    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), excelFileName + '.xlsx'))
      .catch(err => console.log('Error writing excel export', err));
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private saveAsExcelFileInZip(buffer: any[], fileName: string): void {
    const zip = new JSZip();

    for (let i = 0; i < buffer.length; i++) {
      zip.file(buffer[i].clubName + ' ' + fileName + EXCEL_EXTENSION, buffer[i].table);
    }

    zip.generateAsync({ type: 'blob' })
      .then(function(content) {
        FileSaver.saveAs(content, 'Реестр от ' + fileName + ZIP_EXTENSION);
      });
  }

  private excelBuffer(json: any[], details?: any): any {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    myworksheet['B1'] = { t: 's', v: 'Отчет агента об исполнении поручения по агентскому договору 30/21 от 02.02.2022 г.' };
    myworksheet['B2'] = { t: 's', v: 'Между ИП Труханова С.В. и ООО "ЕСА"' }
    myworksheet['B3'] = { t: 's', v: 'г.Новосибирск' }
    myworksheet['C3'] = { t: 's', v: '31.12.2022' }

    myworksheet['B5'] = { t: 's', v: 'В соответствии с условиями договора по поручению Принципала Агентом оказаны услуги по продаже и бронированию спортивно-оздоровительных услуг' }

    myworksheet['A7'] = { t: 's', v: 'Реестр платежей Sport Priority'};
    myworksheet['A8'] = { t: 's', v: details.clubName};

    myworksheet['A10'] = { t: 's', v: 'Сумма реестра:'};
    myworksheet['A11'] = { t: 's', v: 'Записей:'};
    myworksheet['A12'] = { t: 's', v: 'Дата реестра:'};
    myworksheet['A13'] = { t: 's', v: 'Даты платежей:'};

    myworksheet['C10'] = { t: 's', v: details.registerAmount};
    myworksheet['C11'] = { t: 's', v: details.records};
    myworksheet['C12'] = { t: 's', v: details.registryDate};
    myworksheet['C13'] = { t: 's', v: details.paymentDates};

    let res = details.registerAmount - details.registerAmountTotal;
    json.push({
      'Контакт': '',
      'ФИО': ''
    }, {
      'Контакт': 'Итого принято платежей:',
      'Платеж': details.registerAmount.toString() + ' руб.'
    }, {
      'Контакт': 'Вознаграждение Агента',
      'Платеж': res.toString() + ' руб.'
    }, {
      'Контакт': 'К перечислению за вычетом вознаграждения',
      'Платеж': details.registerAmountTotal.toString() + ' руб.'
    }, {
      'Город': '',
      'Комиссия': ''
    }, {
      'Контакт': 'Агент:',
      'Коментарий': 'Принципал:'
    }, {
      'Контакт': 'ООООООООООООО',
      'Коментарий': 'ИПИПИПИПИПИПИПИП'
    }, {
      'Контакт': '',
      'Коментарий': ''
    }, {
      'Контакт': '________________________',
      'Коментарий': '________________________'
    });

    XLSX.utils.sheet_add_json(myworksheet, json, { origin: 15 });
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };

    myworksheet['!cols'] = [
      { wpx: 20 },
      { wpx: 300 },
      { wpx: 150 },
      { wpx: 300 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 }
    ];

    myworksheet['!merges'] = [
      {
        s: { r: 6, c: 0 },
        e: { r: 6, c: 8 }
      },
      {
        s: { r: 7, c: 0 },
        e: { r: 7, c: 8 }
      },
      {
        s: { r: 9, c: 0 },
        e: { r: 9, c: 1 }
      },
      {
        s: { r: 10, c: 0 },
        e: { r: 10, c: 1 }
      },
      {
        s: { r: 11, c: 0 },
        e: { r: 11, c: 1 }
      },
      {
        s: { r: 12, c: 0 },
        e: { r: 12, c: 1 }
      },
    ];

    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }
}
