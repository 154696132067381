<div class="preloader-wrap" [ngClass]="{'loading':isLoading}">
  <div class="preloader-wr">
    <div class="preloader">
      <img class="preloader__road" src="assets/preloader/route.svg" alt="preloader">
      <img class="preloader__bike" src="assets/preloader/bike_2.svg" alt="preloader">
    </div>
  </div>
</div>
<div appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="addClick()" [hidden]="enableEdit || (singleImage && tempData.images.length === 1)" *ngIf="!isReadonly">
        <span class="sp sp-download"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>


  <ng-container *ngIf="tempData">
    <div class="value" hidden="hidden">
      <input #fileInput type="file" [attr.multiple]="singleImage ? undefined : true" (change)="addPhoto($event)" id="add_photo">
      <label for="add_photo" *ngIf="!singleImage || tempData.images.length === 0">Добавить фото</label>
    </div>

    <div *ngIf="isEmpty()" (click)="addClick()">
      <table class="table">
        <thead>
          <tr>
            <td>{{ text||'[Нет фото]' }}</td>
          </tr>
        </thead>
      </table>
    </div>

      <div class="tooltip" *ngIf="tooltip && tooltip.length && tempData.images.length">{{tooltip}}</div>
      <ul class="photos" cdkDropListGroup>
        <li cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate"
          (cdkDropListDropped)="dropListDropped($event)"></li>
        <li cdkDropList *ngFor="let item of tempData.images;let i = index"
          [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
          <div class="icon-menu" (click)="activeImage = activeImage == item ? undefined : item">
            <span class="sp sp-gallery_menu"></span>
          </div>
          <div *ngIf="item.isDefault" class="icon-default">
            <span class="sp-gallery_main"></span>
          </div>
          <div (click)="openImage(item)" cdkDrag [style.backgroundImage]="'url('+ (item.body || item.url) +')'" class="img" (cdkDragStarted)="dragStarted()"
            (cdkDragMoved)="dragMoved($event);"></div>
          <div class="img_menu">
            <div class="img_menu_inner">
              <a *ngIf="item.id" (click)="openImage(item)">Установить центр</a>
              <p *ngIf="!item.id">Сохраните перед установкой центра</p>
              <a (click)="setDefaultImage(item)" [style.display]="item.isDefault ? 'none' : 'block'">Сделать главным</a>
              <a (click)="deleteImage(item)">Удалить</a>
            </div>
        </div>
        </li>
      </ul>
      <div>
        <ngx-file-drop dropZoneClassName="drop-zone" dropZoneLabel="Drop files here" (onFileDrop)="droppedImages($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" multiple="true" accept=".png">
            <ng-container>
              <span class="drop-zone__btn">
              <span class="sp-gallery_addphoto" (click)="openFileSelector()"></span>
              </span>
              <label *ngIf="loadingFile;" class="drop-zone__lbl">{{loadingString}}</label>
              <label *ngIf="!loadingFile;" #addPhotoLabel class="drop-zone__lbl">Выберите или перетащите сюда фото (.jpg,
                .png)</label>
            </ng-container>
          </ng-template>
        </ngx-file-drop>
      </div>
  </ng-container>
</div>



