import {Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges} from '@angular/core';
import * as moment from 'moment';
import { IDatePickerConfig, DatePickerComponent } from 'ng2-date-picker';
import {CityService} from '../../../common/services/city.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {

  @Input() class: string;
  @Input() mode: string;
  @Input() date: number;
  @Input() isBindingTimeZone = false;
  @Output() dateChange = new EventEmitter<number>();
  @ViewChild('dayPicker') datePicker: DatePickerComponent;

  timeZone: any;
  selectedDate: moment.Moment;
  displayDate: moment.Moment;
  formatDate: string;
  dateTimeConfig: IDatePickerConfig;

  constructor(
    private readonly cityService: CityService,
  ) { }

  open() { this.datePicker.api.open(); }
  close() { this.datePicker.api.close(); }

  ngOnInit(): void {
    const city = this.cityService.currentCity.value;
    this.timeZone = city?.timeZone ? city.timeZone : 180;
    this.checkFormat();

    this.displayDate = this.isBindingTimeZone ? moment(this.date).utcOffset(this.timeZone) : moment(this.date);
    this.selectedDate = this.displayDate.clone();
  }

  checkFormat() {
    switch (this.mode) {
      case 'day': {
        this.formatDate = "DD.MM.YYYY";
        break;
      }
      case 'daytime': {
        this.formatDate = "DD.MM.YYYY HH:mm";
        break;
      }
      case 'time': {
        this.formatDate = "HH:mm";
        break;
      }
      case 'month': {
        this.formatDate = "DD.MM.YYYY HH:mm";
        break;
      }
      default: {
        this.formatDate = "DD.MM.YYYY HH:mm";
        break;
      }
    }
    this.configInit();
  }

  configInit() {
    this.dateTimeConfig = {
      locale: moment.locale('ru'),
      firstDayOfWeek: 'mo',
      showTwentyFourHours: true,
      format: this.formatDate
    }
  }

  onChangeDatePicker(date: moment.Moment) {
    if (date) {
      const convertedDate = this.isBindingTimeZone ? moment(date).add(-(this.timeZone), 'minutes').utc(true) : moment(date);
      if (!convertedDate.isSame(this.selectedDate)) {
        this.selectedDate = convertedDate;
        this.date = this.selectedDate.valueOf();
        this.dateChange.emit(this.date);
      }
    }
  }
}
