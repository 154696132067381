import { Component, OnInit } from '@angular/core';

export const localPreloaderSelector = 'app-local-preloader';

@Component({
  selector: localPreloaderSelector,
  templateUrl: './local-preloader.component.html',
  styleUrls: ['./local-preloader.component.scss']
})
export class LocalPreloaderComponent implements OnInit {
  constructor() { }


  public createRange(begin: number, end: number) {
    const res = [];
    for (let i = begin; i <= end; i++) {
        res.push(i);
    }
    return res;
  }

  ngOnInit(): void {
  }
}
