import { environment } from 'environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { IChatGetActiveResponse } from '../models/chat.models';
import { Connection, Proxy, hubConnection } from 'signalr-no-jquery';

@Injectable({
  providedIn: 'root'
})
export class ChatsActiveHub {

  public connectionEstablished: EventEmitter<Boolean>;
  public connectionExists: Boolean;
  public messageReceived: EventEmitter<IChatGetActiveResponse>;
  private chatConnection: Connection;
  private chat: Proxy;

  constructor(
  ) {
    this.connectionEstablished = new EventEmitter<Boolean>();
    this.connectionExists = false;
    this.messageReceived = new EventEmitter<IChatGetActiveResponse>();
    this.chatConnection = hubConnection(environment.api + "/signalr/", { useDefaultPath: false, logging: true });
    this.chat = this.chatConnection.createHubProxy('hubChatDashboard');
    this.registerOnServerEvents();
  }

  public connectToChatDashboard(clubId: string, pageSize: number)
  {
    this.startConnection(clubId, pageSize);
  }

  public disconnect() {
    this.chatConnection.stop();
  }


  private async startConnection(clubId: string, pageSize: number): Promise<void> {
    if (this.connectionExists) {
      this.chatConnection.stop();
    }
    try {
      await this.chatConnection.start({ transport: 'serverSentEvents' });
      await this.chat.invoke("subscribeClub", { clubId, pageSize });
      this.connectionEstablished.emit(true);
      this.connectionExists = true;
    }
    catch (error) {
      console.log('*** Could not connect ' + error);
      this.connectionEstablished.emit(false);
    }
  }

  private registerOnServerEvents(): void {
    this.chat.off('messageReceived', this.onMessageReceived);
    this.chat.on('messageReceived', this.onMessageReceived);
  }

  private onMessageReceived = (data: IChatGetActiveResponse) => this.messageReceived.emit(data);
}
