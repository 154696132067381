<header class="content__header" *ngIf="!isLoading">
  <div class="content__header-title">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Редактирование посещения</span>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="!isLoading">
  <form [formGroup]="form" novalidate>
    <div>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-12">
            <div class="info-text icons-prompt">
              <p>
                <span class="icon-check icons-prompt">
                        <span class="icon-check__left"></span>
                        <span class="icon-check__right"></span>
                      </span>
                <span class="icons-description">&mdash; отмечает посещение занятия и списывает одно посещение с
                  абонемента</span>
              </p>
              <p>
                <span class="icon-cross icons-prompt">
                        <span class="icon-cross__minus"></span>
                      </span>
                <span class="icons-description">&mdash; отмечает прогул занятия клиентом и списывает одно посещение с
                  абонемента</span>
              </p>
              <p>
                      <span class="icon-cross icon-delete">
                        <span class="icon-cross__left"></span>
                        <span class="icon-cross__right"></span>
                      </span>
                <span class="icons-description"> ("Отмена посещения") &mdash; отменяет запись клиента на занятие,
                  посещение с абонемента не списывается</span>
              </p>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table rowHover">
            <thead>
            <tr>
              <td class="table-cell table-title-cell">Пришел/Пропустил</td>
              <td class="table-cell table-title-cell">Клиент</td>
              <td class="table-cell table-title-cell">Телефон</td>
              <td class="table-cell table-title-cell">Абонемент</td>
              <td class="table-cell table-title-cell">Статус</td>
              <td class="table-cell table-title-cell"></td>
            </tr>
            </thead>
            <tbody>
              <tr [ngStyle]="styleBorderLeftRow(history)" [class.canceled-visit]="checkIsCancelled(history)" [class.missed-visit]="checkIsMissed(history)">
                <td class="table-cell table-cell_action">
                  <ng-container *ngIf="history.classVisitStatus != 'Queued'">
                    <button type="button" class="btn btn-clear active check-visit_btn" style="margin: 5px 0.8rem" (click)="confirmVisited(history)">
                        <span class="icon-check">
                          <span class="icon-check__left"></span>
                          <span class="icon-check__right"></span>
                        </span>
                    </button>
                    <span class="mobile__label">Пришел</span>
                    <button type="button" class="btn btn-clear active check-visit_btn" (click)="confirmMissed(history)">
                        <span class="icon-cross">
                          <span class="icon-cross__minus"></span>
                        </span>
                    </button>
                    <span class="mobile__label">Пропустил</span>
                  </ng-container>
                </td>
                <td class="table-cell">
                  <div class="table-cell__client_wrap">
                    <div class="table-cell__avatar">
                      <div class="table-cell__avatar-default" *ngIf="!client.primaryImageUrl">
                        <span class="sp sp-user"></span>
                      </div>
                      <div class="table-cell__avatar-img" *ngIf="client.primaryImageUrl"
                           [style.background]="'url('+client.primaryImageUrl+')'">
                      </div>
                    </div>
                    <div class="table-cell__avatar-desc">
                      {{getFullName(client)}}
                    </div>
                  </div>
                </td>
                <td class="table-cell">
                  <div class="desc field">
                    <span *ngIf="client.phone; else noPhone">{{client.phone}}</span>
                    <ng-template #noPhone>
                      <span class="gray ">[не&nbsp;указано]</span>
                    </ng-template>
                  </div>
                </td>
                <td class="table-cell">
                  <div *ngIf="subViewSel && subViewSel.length; else noSubscription">
                    <select class="select select__long" formControlName="subId"
                            (change)="selectSubscriptionForClient()">
                      <option [ngValue]="noSubId">Без абонемента</option>
                      <option
                        *ngFor="let subscription of subViewSel"
                        [ngValue]="subscription.id"
                        [class.warn]="subscription.isWarn"
                        [disabled]="subscription.isWarn"
                      >
                        {{subscription.name}} ( {{subscription.visitCounter}} / {{subscription.visitCount >= 9999 ? '∞' : subscription.visitCount}} ) до {{subscription.toDate}}
                      </option>
                      <option [ngValue]="'new'">Купить другой абонемент</option>
                    </select>
                  </div>
                  <ng-template #noSubscription>
                    <button class="btn btn-alert" (click)="goToBaySub()">Купить абонемент</button>
                  </ng-template>
                </td>
                <td class="table-cell">
                  <span>{{ history.currentStatusText }}</span>
                </td>
                <td class="table-cell row-not-mobile">
                  <button type="button" class="btn-special" (click)="cancelBooking(history)">
                    Отменить
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
