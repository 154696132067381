import { Validators } from '@angular/forms';
import { Injector, Component } from '@angular/core';

import { ICourseModel, ClubConfirmationMethod } from 'app/common/models/course.model';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { ICoachModel } from 'app/common/models/coach.model';
import { CoachesService } from 'app/components/coaches/services/coaches.service';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { ClubroomsService } from 'app/components/clubrooms/services/clubrooms.service';
import { ITrainingCategory } from 'app/common/models/training-category.model';
import { EditItemComponent } from 'app/common/edit-item.component';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { ApiService } from 'app/common/services/api.service';
import { CoursesService } from 'app/components/courses/services/courses.service';
import { MediaService } from 'app/common/services/media.service';

@Component({
  selector: 'app-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrls: ['./course-edit.component.scss'],
})

export class CourseEditComponent extends EditItemComponent<ICourseModel> {
  public static readonly componentName: string = 'CourseEditComponent';

  public clubCoaches: Array<ICoachModel> = [];
  public clubRooms: Array<IClubroomModel> = [];
  public trainingCategories: Array<ITrainingCategory> = [];
  public subscriptionPlans: any[];

  fieldMessages = {
    name: {
      NotEmpty: 'Это поле обязательно'
    },
    color: {
      required: 'Необходимо указать цвет'
    }
  };

   isShowCoaches() {
    return this.Model.coaches && this.Model.coaches.length > 0;
  }

  constructor(
    injector: Injector,
    protected mediaService: MediaService,
    protected service: CoursesService,
    protected coachService: CoachesService,
    protected clubRoomService: ClubroomsService,
    private apiService: ApiService
  ) {
    super(injector, service);
  }

  ngOnInit(){
    super.ngOnInit();

    this.coachService.getItemList()
    .then(coaches => this.clubCoaches = coaches);

    this.clubRoomService.getItemList()
      .then(clubrooms => this.clubRooms = clubrooms);

    this.apiService.trainingCategories.getList()
      .then(categories => this.trainingCategories = categories);
  }

  modelTemplate(): Promise<ICourseModel> {
    const template: ICourseModel = {
      name: null,
      group: null,
      sortOrder: null,
      description: null,
      shortDescription: null,
      isPopular: false,
      color: '#c0c0c0',
      entityStatus: EntityStatus.draft,
      imageUrl: '',
      id: null,
      trainingCategories: [],
      workingTime: {
        mon: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        tue: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        wed: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        thu: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        fri: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        sat: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        sun: { 'hourFrom': '00:00', 'hourTo': '23:59' },
      },
      isVoluntaryBookingAllowed: false,
      clubConfirmationMethod: ClubConfirmationMethod.NotRequired,
      isCoachForClassRequired: false,
      rooms: [],
      coaches: [],
      medias: {
        images: [],
        videos: []
      }
    };

    return Promise.resolve(template);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      group: [this.Model.group, []],
      sortOrder: [this.Model.sortOrder, []],
      description: [this.Model.description, []],
      shortDescription: [this.Model.shortDescription, []],
      color: [this.Model.color, [Validators.required]],
      isPopular: [this.Model.isPopular, []],
      isVoluntaryBookingAllowed: [this.Model.isVoluntaryBookingAllowed, []],
      clubConfirmationMethod: [this.Model.clubConfirmationMethod, []],
      isCoachForClassRequired: [this.Model.isCoachForClassRequired, []],
      entityStatus: [this.Model.entityStatus, [Validators.required]],
      trainingCategories: [this.Model.trainingCategories, []],
      workingTime: [this.Model.workingTime, []],
      coaches: [this.Model.coaches, []],
      rooms: [this.Model.rooms, []],
      medias: [this.Model.medias, []]
    });
    if (this.Model.name !== null) {
      this.Model.name = this.Model.name.trim()
    }
    if (this.Model.group !== null) {
      this.Model.group = this.Model.group.trim()
    }
  }

  afterModelInit() {
    if (this.Model.id) {
      delete this.Model['isClubConfirmationRequired'];

      this.service.getSubscriptions(this.Model.id)
        .then(rs => this.subscriptionPlans = rs.subscriptionPlans);
    }
  }

  openImage(event: any) {
    this.mediaService.saveRedirectUrl();
    var url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);
  }
}
