import {Component, HostListener, OnInit} from '@angular/core';
import {IClientSubscriptionPlan, IClientSubscriptionSuspendHistory} from '../../../common/models/subscription.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClubSetting} from '../../../common/models/club-setting.model';
import {Location} from '@angular/common';
import {ClientsService} from '../services/clients.service';
import {ContextService} from '../../../common/services/context.service';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-suspend',
  templateUrl: './suspend.component.html',
  styleUrls: ['./suspend.component.scss']
})
export class SuspendComponent implements OnInit {

  public static readonly componentName: string = 'SuspendComponent';

  public extension: IClientSubscriptionPlan;
  public historySuspend: IClientSubscriptionSuspendHistory[];
  public form: FormGroup = null;

  public clubSettings: ClubSetting[];
  public isMoreDateFrom = false;

  public isLoading = false;

  constructor(
    public _location: Location,
    public service: ClientsService,
    private formBuilder: FormBuilder,
    public contextService: ContextService,
    private route: ActivatedRoute
  ) { }

  Back(): void {
    this._location.back();
  }

  ngOnInit() {
    this.isLoading = true;
    this.buildForm();
    this.route.queryParams.subscribe(async params => {
      const abonementId: string = params['subsId'];
      await this.service.getClientAbonement(abonementId)
        .then(res => {
          this.extension = res;
        });
      await this.service.getClientSubscriptionSuspendHistory(this.extension.id, this.extension.spSubscriptionId)
        .then(res => {
          this.historySuspend = res;
        });
      this.isLoading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group ({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
    });
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(e) {
    if (e.keyCode === 27) {
      this._location.back();
    }
  }

  checkDate() {
    if (this.form.get('fromDate').value && this.form.get('toDate').value) {
      const toDate = moment(this.form.get('toDate').value, 'DD.MM.YYYY').format('YYYY/MM/DD 00:00:00 +0000');
      const fromDate = moment(this.form.get('fromDate').value, 'DD.MM.YYYY').format('YYYY/MM/DD 00:00:00 +0000');
      if (toDate < fromDate) {
        this.isMoreDateFrom = true;
        return true
      }
      this.isMoreDateFrom = false;
      return false;
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const fromDate = moment(this.form.get('fromDate').value, 'DD.MM.YYYY').format('YYYY/MM/DD 00:00:00 +0000');
    const toDate = moment(this.form.get('toDate').value, 'DD.MM.YYYY').format('YYYY/MM/DD 00:00:00 +0000');
    const model = {
      subClientId: this.extension.id,
      fromDate: Date.parse(fromDate).valueOf(),
      toDate: Date.parse(toDate).valueOf(),
    };

    await this.service.suspendClientAbonement(model, this.extension.spSubscriptionId);
    if (this.form.valid) {
      this._location.back();
    }
  }
}
