import { AlertsService } from './../../common/components/alerts/services/alerts.service';
import { ClubSetting, ClubSettingVariant, ClubSettingType } from './../../common/models/club-setting.model';
import { ClubSettingService } from './club-settings.service';
import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'app/common/services/permission.service';
import { PermissionKey } from 'app/common/models/permission-key.model';


@Component({
  selector: 'app-club-settings',
  templateUrl: './club-settings.component.html',
  styleUrls: ['./club-settings.component.scss']
})
export class ClubSettingsComponent implements OnInit {
  public static readonly componentName: string = 'ClubSettingsComponent';

  public readonly types = ClubSettingType;
  public clubSettings: ClubSetting[];
  public clubSettingVariants: ClubSettingVariant[];

  public isReady: boolean = false;

  constructor(
    private service: ClubSettingService,
    private permissionService: PermissionService,
    private alertsService: AlertsService
  ) { }

  async ngOnInit() {
    this.clubSettingVariants = await this.service.getClubSettingVariants();
    this.clubSettings = await this.service.getClubSettingsByClub();
    this.synchronizationSettings();
  }

  public readonly _filterParametersBool = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && !variant.category;
  public readonly _filterParamQueue = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'Queue';
  public readonly _filterParamWebhooks = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'webhooks';
  public readonly _filterParamCertificates = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'certificates';
  public readonly _filterParamAccounting = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'Accounting';
  public readonly _filterParamIsVisibleTabsSp = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'isVisibleTabsSp';
  public readonly _filterParamClubanalytics = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'Clubanalytics';
  public readonly _filterParamAvailability = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'Availability';
  public readonly _filterParamNoticeOff = (variant: ClubSettingVariant) => variant.type === ClubSettingType.Boolean && variant.category === 'NoticeOff';
  public readonly _filterParametersNotBool = (variant: ClubSettingVariant) => variant.type !== ClubSettingType.Boolean && !variant.category;
  public readonly _filterParamTariff = (variant: ClubSettingVariant) => variant.type !== ClubSettingType.Boolean && variant.category === 'Tariff';

  async synchronizationSettings() {
    this.clubSettingVariants = this.clubSettingVariants.filter(v => this._isPermit(v.alias));
    this.clubSettingVariants.forEach(v => {
      const setting = this.clubSettings.find(s => s.alias == v.alias);

      setting ?
        v.value = setting.value :
        this.clubSettings.push({
          alias: v.alias,
          value: null
        });

      if (v.type == ClubSettingType.Boolean) {
        v.value = v.value === 'true'
      }
    });

    this.isReady = true;
  }

  async saveClubSetting(v: ClubSettingVariant) {
    const item = this.service.variantToClubSetting(v);

    if (!item.alias)
      return;

    await this.service.saveClubSetting(item);
    this.successSaveMessage();
  }

  private successSaveMessage(){
    this.alertsService.alert.next({
      type: 'success',
      message: 'Настройки сохранены',
      header: 'Успех',
      position: 'top',
      timeout: 2000
    });
  }

  public _isPermit: (alias: string) => boolean = alias => {
    return this.permissionService.isAvailable(this, `clubProfileSettings_${alias}` as PermissionKey, undefined, true);
  }
}
