export interface ICertificateList {
  currentPage: number;
  totalPage: number;
  totalCount: number;
  take: number;
  result: ICertificate[];
}

export interface ICertificate {
  id: string; // Id сертификата
  status: CertificateStatus; // Статус сертификата
  type: CertificateType; // Тип сертификата
  subscriptionName: string; // Название сертификата
  certificateNumber: string; // Код сертификата
  price: number; // Номинал сертификата
  createdAt: string; // Дата и время создания
  purchaseAt: string; // Дата покупки
  endAt: string; // Дата протухание сертификата
  userInfo: CertUserInfo;
  isTouch: boolean;
}

export interface CertUserInfo {
  userId: string;
  name: string;
  phone: string;
  email: string;
}

export enum CertificateStatus {
  /// <summary>
  /// Создан, но ещё не оплачен.
  /// </summary>
  Created = 'created',

  /// <summary>
  /// Оплачен но еще не активирован.
  /// </summary>
  Suspended = 'suspended',

  /// <summary>
  /// Активирован.
  /// </summary>

  Active = 'active',
  /// <summary>
  /// Срок действия сертификата закончился.
  /// </summary>
  Expired = 'expired',
}

export enum CertificateType {
  /// <summary>
  /// На услугу или абонемент в клуб
  /// </summary>
  CertForSubscription= 1,

  /// <summary>
  /// На покупку в Sport Priority
  /// </summary>
  CertSP = 2,

  /// <summary>
  /// На сумму в клуб
  /// </summary>
  CertForAmount = 3,

  /// <summary>
  /// Универсальный
  /// </summary>
  CertUniversal = 4
}
