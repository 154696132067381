import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../common/services/api.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ISubscription} from '../../../common/models/subscription.model';

@Component({
  selector: 'app-collection-data',
  templateUrl: './collection-data.component.html',
  styleUrls: ['./collection-data.component.scss']
})
export class CollectionDataComponent implements OnInit, OnDestroy {
  subscriptions: ISubscription[] = [];
  courses: any[] = [];
  @Input() clubs: any[];
  @Input() type: string;
  @Input() city: any;
  @Input() data: any[];
  @Output() result = new EventEmitter<any[]>();

  valueFilterClubs: string;
  clubsFilter: any[] = [];

  valueFilterSubscriptions: string;
  subscriptionsFilter: ISubscription[] = [];

  valueFilterCourses: string;
  coursesFilter: any[] = [];

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.clubsFilter = this.clubs;
  }

  ngOnDestroy() {
    this.result.unsubscribe();
  }

  filterClubs() {
    this.clubsFilter = this.clubs.filter(entity => entity.name.toLowerCase().indexOf(this.valueFilterClubs.toLowerCase()) > -1)
  }

  filterCourses() {
    this.coursesFilter = this.courses.filter(entity => entity.name.toLowerCase().indexOf(this.valueFilterCourses.toLowerCase()) > -1)
  }

  filterSubscriptions() {
    this.subscriptionsFilter = this.subscriptions.filter(entity => entity.name.toLowerCase().indexOf(this.valueFilterSubscriptions.toLowerCase()) > -1)
  }

  async setClub(club: any): Promise<any> {
    this.valueFilterCourses = '';
    this.valueFilterSubscriptions = '';
    this.courses = [];
    this.coursesFilter = [];
    this.subscriptions = [];
    this.subscriptionsFilter = [];

    switch (this.type) {
      case 'clubs':
        if (this.data.filter(x => x.id === club.id).length !== 0)
          return;
        this.data.push({ id: club.id, name: club.name })
        break;
      case 'courses':
        await this.api.courses.getList({
          cityId: club.cityId,
          clubNetId: club.clubNetId,
          clubId: club.id,
          id: null,
          startDate: null,
          startTime: null,
          isInClubContext: false,
        }).then(resp => {
          resp.forEach(cur => {
            this.courses.push({ id: cur.id, name: cur.name })
          });
          this.coursesFilter = this.courses;
        })
        break;
      case 'subscriptions':
        await this.api.subscriptions.getList({
          cityId: club.cityId,
          clubNetId: club.clubNetId,
          clubId: club.id,
          id: null,
          startDate: null,
          startTime: null,
          isInClubContext: false,
        }, {  }).then(resp => {
          resp.forEach(sub => {
            this.subscriptions.push(sub)
          });
          this.subscriptionsFilter = this.subscriptions;
        })
        break;
    }

    this.result.emit(this.data);
  }

  delete(r: any) {
    this.data = this.data.filter(x => x.id !== r.id);
    this.result.emit(this.data);
  }

  public async remove(r: any): Promise<void> {
    this.delete(r);
    // this.alertsService.showConfirm({
    //   header: `Убрать ${r.name} из подборки?`,
    //   buttons: [{
    //     title: 'Да',
    //     callback: () => {
    //       this.delete(r);
    //     }
    //   }, {
    //     title: 'Нет'
    //   }]
    // });
  }

  setCurOrSub(d: any) {
    if (this.data.filter(x => x.id === d.id).length !== 0)
      return;
    this.data.push({ id: d.id, name: d.name })
    this.result.emit(this.data);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }
}


