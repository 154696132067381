import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { ClubNetsService } from 'app/components/clubnets/services/clubnets.service';
import { IClubNetModel } from 'app/common/models/clubnet.model';
import { IIdName } from 'app/common/models/id-name.model';
import { CityService } from 'app/common/services/city.service';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';
import { ICityModel } from 'app/common/models/city-model';

@Component({
  selector: 'app-clubnet',
  templateUrl: './clubnet.component.html',
  styleUrls: ['./clubnet.component.scss']
})
export class ClubnetComponent extends ToggleEditComponent<IIdName> implements OnInit, OnDestroy {
  // TODO: Открывать селект автоматически при клике на карандаш, когда будут кастомные селекты.
  // Обычные программно открыть в современных браузерах нельзя.

  protected ngUnsubscribe: Subject<void> = new Subject();
  public clubNets: IClubNetModel[] = null;

  constructor(
    private clubnetsService: ClubNetsService,
    private cityService: CityService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.cityService.currentCity.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(Boolean),
      take(1))
      .subscribe(city => {
        this.clubnetsService.getItemList(city as ICityModel)
          .then(res => this.clubNets = res.items);
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
