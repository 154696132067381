<form [formGroup]="form">
  <mat-select class="select" (selectionChange)="onChange($event.value)" [ngClass]="{'inactive':inactive, ' select__long': !short }"
  formControlName="pickerControl">
      <mat-option [value]="''">
          {{placeholder}}
      </mat-option>

      <ng-container *ngIf="!groupBy">
          <mat-option *ngFor="let o of objects; let i = index" [value]="i" >
              {{o|obj2string}}
          </mat-option>
      </ng-container>

      <ng-container *ngIf="groupBy && groupedObjects && groupedObjects.length > 1">
          <ng-container *ngFor="let optgroup of groupedObjects">
              <mat-optgroup [label]="optgroup.key">
                  <mat-option *ngFor="let o of optgroup.data" [value]="o.index" >
                      {{o|obj2string}}
                  </mat-option>
              </mat-optgroup>
          </ng-container>
      </ng-container>

      <ng-container *ngIf="groupBy && groupedObjects && groupedObjects.length == 1">
          <mat-option *ngFor="let o of groupedObjects[0].data" [value]="o.index" >
              {{o|obj2string}}
          </mat-option>
      </ng-container>
  </mat-select>
</form>





