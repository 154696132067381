import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ApiV2Service} from '../../../services/api-v2.service';
import {ContextService} from '../../../services/context.service';
import {GraphsSummary} from '../../../models/graphs-summary.model';
import {IRoutingParams} from '../../../models/context.model';
import {ApiService} from '../../../services/api.service';
import * as moment from 'moment';
import {ClubSetting} from '../../../models/club-setting.model';

@Component({
  selector: 'app-summary',
  templateUrl: './graph-summary.component.html',
  styleUrls: ['./graph-summary.component.scss']
})
export class GraphSummaryComponent implements OnInit, OnChanges {
  public graphsSummaryInfo: GraphsSummary;
  public context: IRoutingParams;
  public clubSettings: ClubSetting[] = [];

  public chartSummary: 'pie' | 'doughnut' | 'bar' = 'pie';

  public financeData: any[] = [];
  public efficiencyData: any[] = [];
  public efficiencyByCoursesData: any[] = [];
  public municipalTaskData: any[] = [];

  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();
  public IsShowGraphEfficiency = false;
  public IsShowGraphEfficiencyByCourses = false;
  public IsShowGraphMunicipalTask = false;
  public IsShowGraphMunicipalTaskText = false;
  public IsShowGraphClients = false;
  public IsShowGraphFinance = false;

  constructor(
    private apiV1: ApiService,
    private apiV2: ApiV2Service,
    private contextService: ContextService
  ) { }

  async ngOnInit(): Promise<any> {
    this.context = await this.contextService.getRoutingParams();
    if (this.context) {
      this.clubSettings = await this.apiV1.clubSettings.getByClub(this.context.clubId);
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphEfficiency')) { this.IsShowGraphEfficiency = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphEfficiency')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphEfficiencyByCourses')) { this.IsShowGraphEfficiencyByCourses = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphEfficiencyByCourses')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphMunicipalTask')) { this.IsShowGraphMunicipalTask = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphMunicipalTask')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphMunicipalTaskText')) { this.IsShowGraphMunicipalTaskText = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphMunicipalTaskText')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphClients')) { this.IsShowGraphClients = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphClients')?.value) ?? false; }
      if (this.clubSettings.find(x => x.alias === 'IsShowGraphFinance')) { this.IsShowGraphFinance = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowGraphFinance')?.value) ?? false; }
    }
    this.graphsSummaryInfo = await this.apiV2.graphs.getGraphsSummary(this.context.clubId,
      this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null);
    this.rebuildData();
  }

  public async ngOnChanges(changes: any): Promise<void> {
    await this.apiV2.graphs.getGraphsSummary(this.context.clubId,
      this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null).then(async resp => {
      this.graphsSummaryInfo = resp;
      this.rebuildData();
    });
  }

  private rebuildData(): void {
    this.financeData = [
      ...[['#88C23D', '#FD7790', ...(this.graphsSummaryInfo.finance.receipt ? ['#EEA71E'] : []), '#574683']],
      ...[['Наличными', 'Безналичными', ...(this.graphsSummaryInfo.finance.receipt ? ['Оплата по квитанции'] : []), 'Приложение']],
      ...[[this.graphsSummaryInfo.finance.cash, this.graphsSummaryInfo.finance.nonCash, ...(this.graphsSummaryInfo.finance.receipt ? [this.graphsSummaryInfo.finance.receipt] : []), this.graphsSummaryInfo.finance.app]]
    ];


    const [ colors, labels, values ] = this.graphsSummaryInfo.efficiency.classes.reduce((p, c) => {
      p[0].push(c.color); p[1].push(c.label); p[2].push(c.value); return p;
    }, [ [], [], [] ]);
    const total = values.reduce((p, c) => p - c, this.graphsSummaryInfo.efficiency.totalWork);

    this.efficiencyByCoursesData = [
      ...[[ ...colors, '#888888' ]],
      ...[[ ...labels, 'Свободно' ]],
      ...[[ ...values, total < 0 ? 0 : total ]]
    ];

    this.efficiencyData = [
      ...[[ '#93a4ad', '#888888' ]],
      ...[[ 'Занято', 'Свободно' ]],
      ...[[ values.reduce((p, c) => p + c, 0), total < 0 ? 0 : total ]]
    ];

    this.municipalTaskData = [
      ...[[ '#00A3E2', '#574683' ]],
      ...[[ 'Частники', 'Муниципальное задание' ]],
      ...[[ this.graphsSummaryInfo.municipalTask.private, this.graphsSummaryInfo.municipalTask.municipal ]]
    ];
  }
}
