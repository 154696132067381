import { Route } from '@angular/router';
import { AddClientToClassComponent } from 'app/components/dashboard/add-client-to-class/add-client-to-class.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const addClientToClassRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/addclienttoclass',
  component: AddClientToClassComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Запись клиента на занятие'}
};
