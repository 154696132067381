<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание темы</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование темы</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>

    <div class="row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" myScrollTo>
              <div class="box-title">Название</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" formControlName="name" id="name" [ngModel]="Model.name" maxlength="500">
                <label for="name" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" myScrollTo>
              <div class="box-title">Стиль</div>
              <div class="info-text" *ngIf="Model.widgetsCount > 1">
                <div class="info-text__box">
                  <span>Количество виджетов, использующих эту тему: <b>{{Model.widgetsCount}}</b>. Изменения отразятся на всех виджетах!</span>
                </div>
              </div>
              <div class="row__field">
                <textarea type="text" class="input__text input__text--long" formControlName="style" id="style" [ngModel]="Model.style" style="min-height:500px; resize:none"></textarea>
                <label for="style" class="field-validation-error" *ngIf="fieldErrors.style && isSubmited">{{fieldErrors.style}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <br>
          <br>
          <div class="info-text">
            <div [ngClass]="{'info-text__box' : !isNotSaved()}">
              <span class="icon icon-about"></span>
              <span app-key-help [key]="'draftText'"></span>
            </div>

            <div *ngIf="!isNotSaved()">
              <span class="icon icon-about"></span>
              Статус:
              <ng-container *ngIf="isDraft()">Черновик</ng-container>
              <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isEditAvailable">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveDraft'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-primary" (click)="copyEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveNew'"></span>
                  </span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isAdmin && Model.clubId !== null && Model.id">
          <div class="form-group">
            <div class="row__field">
              <ng-container>
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </form>
</div>