import { Injectable, ElementRef, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef } from '@angular/core';
import { LocalPreloaderComponent, localPreloaderSelector } from '../components/local-preloader/local-preloader.component';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) { }

  public ShowPreloader(container: ElementRef): any {
    const firstChild = container?.nativeElement?.hasChildNodes()
      && container?.nativeElement?.childNodes[0];
    if (firstChild == null)
      return;
    if (firstChild && firstChild.tagName && firstChild.tagName.toLowerCase() === localPreloaderSelector) {
      firstChild.style.display = 'block';
    }
    else {
      const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(LocalPreloaderComponent)
        .create(this.injector);
      this.appRef.attachView(componentRef.hostView);
      const preloader = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      firstChild
        ? container.nativeElement.insertBefore(preloader, firstChild)
        : container.nativeElement.appendChild(preloader);
    }
  }

  public HidePreloader(container: ElementRef): void {
    const firstChild = container && container.nativeElement.hasChildNodes()
      && container.nativeElement.childNodes[0];
    if (firstChild && firstChild.tagName && firstChild.tagName.toLowerCase() === localPreloaderSelector)
      firstChild.style.display = 'none';
  }
}

export function LocalPreloader<T>(serviceSelector: (component: T) => PreloaderService, elementSelector: (component: T) =>  ElementRef)
{
  return function (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    return {
      value: async function (...args: any[]): Promise<any> {
        // const elementRef = (elementRefProp.split('.').reduce((obj,prop) => obj[prop], this));
        serviceSelector(this).ShowPreloader(elementSelector(this));
        try {
          return await descriptor.value.apply(this, args);
        }
        finally {
          serviceSelector(this).HidePreloader(elementSelector(this));
        }
      }
    };
  };
}
