import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IConfirm, IAlert } from 'app/common/models/alerts.model';


@Injectable()
export class AlertsService {
  public alert = new Subject<IAlert>();
  public confirm = new Subject<IConfirm>();
  public exception = new Subject<any>();

  showConfirm(data: IConfirm) {
    this.confirm.next(data);
  }
}
