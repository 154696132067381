import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { ChatsComponent } from 'app/components/chats/chats.component';
import { ChatComponent } from 'app/components/chats/chat.component';
import { idChatPath, idClubChatPath, mailingClubPath, mailingPath } from 'app/routing/routes/_constants';
import { ChatEditComponent } from 'app/components/chats/chat-edit.component';
import { ChatMailingComponent } from 'app/components/chats/chat-mailing.component';

export const clubChatsRoute: Route = {
  component: ChatsComponent,
  path: ':cityId/:clubNetId/:clubId/chats',
  canActivate: [AuthGuard],
  data: { title: 'Чаты' },
};

export const idClubChatRoute: Route = {
  component: ChatComponent,
  path: idClubChatPath,
  canActivate: [AuthGuard],
  data: { title: 'Чат' },
};

export const chatsRoute: Route = {
  component: ChatsComponent,
  path: ':cityId/chats',
  canActivate: [AuthGuard],
  data: { title: 'Чаты' },
};

export const idChatRoute: Route = {
  component: ChatComponent,
  path: idChatPath,
  canActivate: [AuthGuard],
  data: { title: 'Чат' },
};

export const mailingRoute: Route = {
  component: ChatMailingComponent,
  path: mailingPath,
  canActivate: [AuthGuard],
  data: { title: 'Рассылка' },
};

export const mailingClubRoute: Route = {
  component: ChatMailingComponent,
  path: mailingClubPath,
  canActivate: [AuthGuard],
  data: { title: 'Рассылка' },
};

export const userChatRoute: Route = {
  component: ChatComponent,
  path: ':cityId/chats/user/:id',
  canActivate: [AuthGuard],
  data: { title: 'Чат' },
};

export const createClubChatRoute: Route = {
  component: ChatEditComponent,
  path: ':cityId/:clubNetId/:clubId/chats/create',
  canActivate: [AuthGuard],
  data: { title: 'Создание чата/рассылки' },
};

export const editClubChatRoute: Route = {
  component: ChatEditComponent,
  path: ':cityId/:clubNetId/:clubId/chats/edit/:id',
  canActivate: [AuthGuard],
  data: { title: 'Настройка чата/рассылки' },
};
