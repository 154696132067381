<router-outlet></router-outlet>

<header class="content__header">
  <div class="row content__header-title">
    <div class="col-sm-8 col-md-9">
      <span class="content__header-title-value content__header-title-value_default">
        Список клубов
      </span>
    </div>
    <div class="col-sm-4 col-md-3">
      <input type="checkbox" [(ngModel)]="onlyCurrentCity" (ngModelChange)="getFullClubList()" class="checkbox__slide" name="onlyCurrentCity"
        id="onlyCurrentCity">
      <label for="onlyCurrentCity" class="checkbox__slide_label">
        <ng-container *ngIf="!onlyCurrentCity">
          Все города
        </ng-container>
        <ng-container *ngIf="onlyCurrentCity && currentCity">
          Только {{currentCity.name}}
        </ng-container>
      </label>
    </div>
  </div>
</header>

<div class="clubs-container content__body">
  <div class="row">
    <div class="col-xs-12">
      <form class="form-group">
        <input class="input__text input__text--long" [formControl]="searchTerm" autocomplete="off" placeholder="Введите хотя бы два символа названия"
        />
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="tableSideLinkWrap">
        <a class="box-link" (mousedown)="onAddClick($event)" (click)="onAddClick($event)">
          Добавить
          <span class="key">
            <span app-key-help [key]="'plus'"></span>
          </span>
        </a>
        <br>
        <button class="btn btn-special btn-clublist" *ngIf="!isFullClubList" (click)="getFullClubList()">К полному списку клубов</button>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table rowHover">
      <thead>
        <td class="table-cell">Название</td>
        <td class="table-cell" *ngIf="!onlyCurrentCity && !!cities.length">Город</td>
        <td class="table-cell">Отображать в системе</td>
        <td class="table-cell">Разрешена продажа</td>
        <td class="table-cell">Проверено Sport Priority</td>
        <td class="table-cell">Краткий вид в приложении</td>
        <td class="table-cell">Подтверждено</td>
      </thead>

      <tbody>
        <tr *ngFor="let item of clubsList" (click)="onEditClick(item)">
          <td class="table-cell">
            <span class="table-cell__mobile-title">Название</span>
            <b>{{item.name}}</b>
          </td>

          <td class="table-cell" *ngIf="!onlyCurrentCity && cities.length">
            <span class="table-cell__mobile-title">Город</span>
            <span innerHtml="{{item|cityname:cities}}"></span>
          </td>

          <td class="table-cell">
            <span class="table-cell__mobile-title">Отображать в системе</span>
            <span *ngIf="item.entityStatus === 'published'">да</span>
            <span *ngIf="item.entityStatus !== 'published'">нет</span>
          </td>

          <td class="table-cell">
            <span class="table-cell__mobile-title">Разрешена продажа</span>
            <span *ngIf="item.isSubscriptionSellAllowed">да</span>
            <span *ngIf="!item.isSubscriptionSellAllowed">нет</span>
          </td>

          <td class="table-cell">
            <span class="table-cell__mobile-title">Проверено Sport Priority</span>
            <span *ngIf="item.isApproved">да</span>
            <span *ngIf="!item.isApproved">нет</span>
          </td>

          <td class="table-cell">
            <span class="table-cell__mobile-title">Краткий вид в приложении</span>
            <span *ngIf="item.isShortViewOnly">да</span>
            <span *ngIf="!item.isShortViewOnly">нет</span>
          </td>

          <td class="table-cell">
            <span class="table-cell__mobile-title">Подтверждено</span>
            <span *ngIf="item.actualScheduleDate; else noActualScheduleDate">{{item.actualScheduleDate}}</span>
            <ng-template #noActualScheduleDate>
              <span class="gray ">[не&nbsp;подтверждено]</span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="add-clubs" *ngIf="isFullClubList && clubsList && clubsList.length && clubsList.length < totalItemCount">
    <button class="btn btn-outline" (click)="showNextPage()">
      показать еще {{(pageSize
      < (totalItemCount - clubsList.length)) ? pageSize : (totalItemCount - clubsList.length) }} {{ (totalItemCount - clubsList.length)>
        pageSize? 'из ' + (totalItemCount - clubsList.length) : '' }}
    </button>
  </div>
</div>
