import { FormControl } from '@angular/forms';

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const numberValidator = (min: number, max: number) => {
    return (c: FormControl) => {
        return c.value == null || c.value === '' || (isNumeric(c.value) && c.value >= min && c.value <= max) ? null : {
            numberValidator: {
                valid: false
            }
        }
    }
}
