
<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Подборки
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container>
    <div class="clubnets-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container>
            <a class="box-link" (click)="create()">Создать
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 mt-3">
          <div class="form-group">
            <div class="row__field">
              <ng-container>
                <mat-form-field>
                  <mat-select [(ngModel)]="selectedPartner" (ngModelChange)="filterCollectionByPartner()">
                    <ng-container *ngFor="let p of partnerListCollections">
                      <mat-option *ngIf="p == SportPriority" [value]="p">{{p}}</mat-option>
                    </ng-container>
                    <ng-container *ngFor="let p of partnerListCollections">
                      <mat-option *ngIf="p != SportPriority" [value]="p">{{p}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-hint>Фильтровать подборки по партнерам</mat-hint>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
          <tr>
            <td class="table-cell table-title-cell">Название</td>
            <td class="table-cell table-title-cell">Партнёр</td>
            <td class="table-cell table-title-cell">Город</td>
            <td class="table-cell table-title-cell">Тип</td>
            <td class="table-cell table-title-cell">Кол-во</td>
            <td class="table-cell table-title-cell">Статус</td>
            <td class="table-cell table-title-cell">Удалить</td>
          </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <tr *ngFor='let collection of viewTableCollections' cdkDrag>
            <td class="table-cell" (click)="edit(collection)">
              <span class="table-cell__mobile-title">Название</span>
              <span>{{collection.name}}</span>
            </td>
            <td class="table-cell" (click)="edit(collection)">
              <span class="table-cell__mobile-title">Партнёр</span>
              <span>{{collection.partnerAlias}}</span>
            </td>
            <td class="table-cell" (click)="edit(collection)">
              <span class="table-cell__mobile-title">Город</span>
              <span>{{collection.cityName}}</span>
            </td>
            <td class="table-cell" (click)="edit(collection)">
              <span class="table-cell__mobile-title">Тип</span>
              <span>{{collection.type|collectionsTypes}}</span>
            </td>
            <td class="table-cell" (click)="edit(collection)">
              <span class="table-cell__mobile-title">Кол-во</span>
              <span>{{collection.countData}}</span>
            </td>
            <td class="table-cell" (click)="edit(collection)">
              <span class="table-cell__mobile-title">Статус</span>
              <span *ngIf="collection.entityStatus == 'draft'" style="color: red;">Черновик</span>
              <span *ngIf="collection.entityStatus == 'published'" style="color: #01e001;;">Опубликован</span>
              <span *ngIf="collection.entityStatus == 'deleted'" style="color: red;">Удален</span>
            </td>
            <td class="table-cell table-cell_ignore" style="text-align: center;">
              <span class="table-cell__mobile-title">Удалить</span>
              <button class="btn btn-clear active" (click)="remove(collection)">
                <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="newSortIndex?.length > 0">
          <div class="form-group">
            <div class="row__field">
              <ng-container>
                <br>
                <br>
                <button class="btn btn-primary btn-small" (click)="saveSort()">
                  <span class="btn-primary__desc">Сохранить сортировку</span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
