import { Route } from '@angular/router';
import { ServicesComponent } from 'app/components/services/services.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const servicesRoute: Route = {
  path: 'services',
  component: ServicesComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Услуги' }
  // TODO: или не услуги. пока неизвестно
};
