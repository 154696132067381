import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(duration: number) {
    const dur = moment.duration(duration, 'minutes');

    if (dur.hours() > 0) {
      if (dur.minutes() !== 0) {
        //часы и минуты
        return dur.hours() + this.getNumEnding(dur.hours(), ':', ':', ':') //можно так - час, часа, часов

          + dur.minutes() + this.getNumEnding(dur.minutes(), '', '', ''); //можно так - минута, минуты, минут
      } else {
        //только часы
        return dur.hours() + this.getNumEnding(dur.hours(), 'ч', 'ч', 'ч'); //можно так - час, часа, часов
      }
    } else {
      //если только минуты и 0 часов
      if (dur.minutes() !== 0) {
        return dur.minutes() + this.getNumEnding(dur.minutes(), 'м', 'м', 'м'); //можно так - минута, минуты, минут
      }
    }

    return '';
  }

  getNumEnding(num: number, for1: string, for4: string, for5: string) {
      let ending = for5;
      num = num % 100;
      if (num >= 11 && num <= 19) {
          ending = for5;
      } else {
          const i = num % 10;
          switch (i) {
              case 1:
                  ending = for1;
                  break;

              case 2:
              case 3:
              case 4:
                  ending = for4;
                  break;

              default:
                  ending = for5;
          }
      }
      return ending;
  }
}
