<header class="content__header" *ngIf="isLoading">
  <div class="content__header-title">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Реестр от {{dateFormat(registry.dateFrom)}} - {{dateFormat(registry.dateTo)}}
      </span>
    </div>
    <div class="btn-export">
      <a class="content__header-link w200">
        <button class="btn btn-primary btn-sm" type="submit" (click)="export()">
          <span class="btn-primary__desc">Отчёт агента</span>
        </button>
      </a>
      <a class="content__header-link">
        <button class="btn btn-primary btn-sm" type="submit" (click)="export('csv')">
          <span class="btn-primary__desc">В .csv</span>
        </button>
      </a>
    </div>
  </div>
  <div class="clubnets-container">
    <div class="tableSideLinkWrap">
      <div class="clue-text">
        <span class="key">Кол-во транзакций: <b class="pd">{{transactionCount}}.&nbsp;</b></span>
        <span class="key">Сумма: <b class="pd">{{amountTotal}} руб.</b>&nbsp;</span>
        <span class="key">Сумма за вычетом комиссии: <b class="pd">{{regAmoComTotal(registryElement, amountTotal)}} руб.</b>&nbsp;</span>
        <span class="key">
          Статус:
          <span *ngIf="registry.status == 'confirmed'" class="status-label pd" style="color: yellowgreen;">Подтвержденный</span>
          <span *ngIf="registry.status == 'completed'" class="status-label pd" style="color: #01e001;">Завершенный</span>
        </span>
      </div>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="isLoading">
  <div class="row">
    <ng-container>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <td class="table-cell table-title-cell">Контакт</td>
            <td class="table-cell table-title-cell">ФИО</td>
            <td class="table-cell table-title-cell">Коментарий</td>
            <td class="table-cell table-title-cell">Дата</td>
            <td class="table-cell table-title-cell" style="min-width: 160px;">Сумма</td>
            <td class="table-cell table-title-cell">Комиссия</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let element of registryElement' [ngClass]="element.status == 'deleted' ? 'remove-f' : element.status == 'hold' ? 'hold-f' : null">
            <td class="table-cell" style="max-width: 180px;">
              <div class="desc field">
                <span [innerHTML]="element.contact"></span>
              </div>
            </td>
            <td class="table-cell">
              <div class="desc field">
                <span>{{element.fullName}}</span>
              </div>
            </td>
            <td class="table-cell">
              <div class="desc field">
                <span>{{element.comment}}</span>
              </div>
            </td>
            <td class="table-cell">
              <div class="desc field">
                <span>{{element.date.length > 10 ? element.date.slice(0, 10) : element.date}}</span>
              </div>
            </td>
            <td class="table-cell">
              <div class="desc field">
                <span>{{element.amount}} руб.</span>
              </div>
            </td>
            <td class="table-cell">
              <div class="desc field">
                <span>{{element.commission}}</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
