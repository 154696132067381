import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
  interface File {
    convertToBase64: ( this: this, callback: ( ( result: string ) => void ) ) => void;
  }
}

File.prototype.convertToBase64 = function ( callback ) {
  const reader = new FileReader();
  reader.onload = () => callback(reader.result.toString());
  reader.onerror = () => callback( null );
  reader.readAsDataURL( this );
};


if ( environment.production ) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule( AppModule );
