import { Component, Injector, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';

import { EditItemComponent } from 'app/common/edit-item.component';
import { UsersService } from 'app/components/users/services/users.service';
import { ClubNetsService } from 'app/components/clubnets/services/clubnets.service';
import { ClubsService } from 'app/components/clubs/services/clubs.service';
import { AuthService } from 'app/common/services/auth.service';
import { phoneValidator } from 'app/common/validators/phone.validator';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { IAccount } from 'app/common/models/account.model';
import { userChatRoute } from 'app/routing/routes/chats.route';
import { CityService } from 'app/common/services/city.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { UserRole } from '../../../common/models/roles.model';
import { ICityModel } from '../../../common/models/city-model';
import { take, takeUntil, filter } from 'rxjs/operators';
import {ContextService} from '../../../common/services/context.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-user-edit]',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends EditItemComponent<IAccount> implements OnInit {
  public static readonly componentName: string = 'UserEditComponent';

  fieldMessages = {
    firstName: {
      NotEmpty: 'Это поле обязательно',
    },
    lastName: {
      NotEmpty: 'Это поле обязательно',
    },
    email: {
      NotEmpty: 'Это поле обязательно',
      pattern: 'Введите действительный e-mail'
    },
    phoneNumber: {
      NotEmpty: 'Это поле обязательно',
      phoneValidator: 'Введите корректный номер телефона'
    },
    role: {
      required: 'Необходимо выбрать роль',
    },
    clubNetIds: {
      clubNetValidator: 'Необходимо выбрать сеть'
    },
    clubIds: {
      clubsValidator: 'Необходимо выбрать минимум один клуб'
    },
    currentCityId: {
      NotEmpty: 'Это поле обязательно',
    }
  };

  public clubs: Array<Object> = [];
  public clubnets: Array<Object> = [];
  public cities: Array<ICityModel> = [];
  public isChatShow = false;
  public isSendInviteVisible = false;
  public moduleList: any[] = [];

  constructor(
    injector: Injector,
    protected clubNetsService: ClubNetsService,
    protected service: UsersService,
    private clubsService: ClubsService,
    private cityService: CityService,
    public authService: AuthService
  ) {
    super(injector, service);
    this.contextService.contextInfo.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(ctx => this.isChatShow = ctx && ctx.modules.includes('chats'));
  }

  public isShowClubs() {
    if (this.form) {
      const currRole = this.form.value.role;
      return currRole === UserRole.ClubManager || currRole === UserRole.ClubAdmin || currRole === UserRole.ClubCoach || currRole === UserRole.ClubCoachAssistant;
    }
    return false;
  }

  public isShowClubNets() {
    if (this.form) {
      const currRole = this.form.value.role;
      return currRole && currRole !== UserRole.Admin && currRole !== UserRole.Manager;
    }
    return false;
  }

  public isMultipleClubs() {
    if (this.form) {
      const currRole = this.form.value.role;
      return true;
    }
    return false;
  }

  public changeRole() {
    if (!this.isShowClubNets()) {
      this.form.patchValue({ clubNetId: [] });
    } else {
      if (this.routingParams.clubNetId) {
        this.Model.clubNetIds[0] = this.routingParams.clubNetId;
        this.changeClubNet(this.routingParams.clubNetId);
        this.form.patchValue({ clubNetIds: [this.routingParams.clubNetId] });
      }
    }
    if (!this.isShowClubs()) {
      this.form.patchValue({ clubIds: null });
      this.Model.clubIds = null;
      this.clubs = [];
    }
    if (!this.isMultipleClubs()) {
      this.Model.clubIds = null;
      this.form.patchValue({ clubIds: this.Model.clubIds });
    }
  }

  public changeClub(clubId: string) {
    this.form.patchValue({ clubIds: clubId ? [clubId] : [] })
  }

  private changeClubNet(clubNetId: string) {
    const ctx: IRoutingParams = Object.assign(this.routingParams, { clubNetId })
    this.clubsService.getClubsByNet(ctx)
      .then(res => {
        this.clubs = res;
        if (this.Model.clubNetIds[0] !== clubNetId) {
          this.form.patchValue({ clubIds: [] });
          this.Model.clubIds = [];
        }
        this.Model.clubNetIds[0] = clubNetId;
        this.form.patchValue({ clubNetIds: [clubNetId] });
      });
  }

  private clubsValidator = (control: FormControl) => {
    if (this.isShowClubs() && (control.value == null || control.value.length === 0)) {
      return {
        clubsValidator: true
      };
    }
    return null;
  }

  private clubNetValidator = (control: FormControl) => {
    if (this.isShowClubNets() && (control.value == null || control.value.length === 0)) {
      return {
        clubNetValidator: true
      };
    }
    return null;
  }

  buildForm() {
    console.log('buildForm');
    this.form = this.formBuilder.group({
      firstName: [this.Model.firstName, [NotEmpty()]],
      lastName: [this.Model.lastName, [NotEmpty()]],
      email: [this.Model.email, [NotEmpty(), Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      phoneNumber: [this.Model.phoneNumber, [NotEmpty(), phoneValidator()]],
      position: [this.Model.position, []],
      role: [this.Model.role, [Validators.required]],
      clubNetIds: [this.Model.clubNetIds, [this.clubNetValidator]],
      clubIds: [this.Model.clubIds, [this.clubsValidator]],
      currentCityId: [this.Model.currentCityId, [NotEmpty()]],
      startScreen: [this.Model.startScreen, []]
    });
    console.log(this.form);
  }

  modelTemplate(): Promise<IAccount> {
    return new Promise(resolve => {
      const template: IAccount = {
        id: null,
        email: null,
        emailConfirmed: false,
        phoneNumberConfirmed: false,
        firstName: null,
        lastName: null,
        position: null,
        role: null,
        clubIds: [],
        clubNetIds: [],
        currentCityId: null,
        startScreen: null
      };

      this.cityService.currentCity.pipe(
        takeUntil(this.ngUnsubscribe),
        filter<ICityModel>(Boolean),
        take(1))
        .subscribe(city => {
          // TODO: по идее, нужно очищать сеть клубов и клубы при смене города, но сейчас сети не привязаны к городу, так что пока так.
          template.currentCityId = city.id;
          resolve(template);
        })
    })
  }

  public async initChatWith(event) {
    event.preventDefault();
    const _url = userChatRoute.path
      .replace(':cityId', this.Model.currentCityId)
      .replace(':id', this.Model.id);
    this.router.navigateByUrl(_url);
  }

  public afterModelInit() {
    if (this.Model.clubNetIds && this.Model.clubNetIds.length) {
      this.changeClubNet(this.Model.clubNetIds[0]);
    }
  }

  async ngOnInit() {
    super.ngOnInit();
    this.cityService.getItemList()
      .then(cities => this.cities = cities);
    this.cityService.currentCity.pipe(
      takeUntil(this.ngUnsubscribe),
      filter<ICityModel>(Boolean),
      take(1))
      .subscribe(city => {
        this.clubNetsService.getItemList(city)
          .then(res => this.clubnets = res.items);
      })
    await this.contextService.availableModulesList().then(resp => {
      this.moduleList = resp;
    });
  }

  getModulesByRole() {
    return this.moduleList.filter(x => x.role === this.form.get('role').value)[0].modules.sort((x, y) => {
      if (x.title < y.title) { return -1; }
      if (x.title > y.title) { return 1; }
    });
  }

  sendInvite() {
    this.service.apiV2Service.accounts.sendInvite(this.Model.id);
  }
}
