import { Component, OnInit, Injector } from '@angular/core';

import { IClubNetModel } from 'app/common/models/clubnet.model';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';

@Component({
    selector: 'app-instagram-type',
    templateUrl: './instagram.component.html',
})
export class InstagramTypeComponent extends ToggleEditComponent<string> implements OnInit {
    public clubNets: IClubNetModel[];
    constructor(
        injector: Injector
    ) {
        super(injector)
    }
}
