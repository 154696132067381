import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Mixin } from 'app/common/decorators/mixin.decorator';
import { ToggleEdit } from 'app/common/toggle-edit.class';
import { WorktimeService } from 'app/common/components/working-time/services/worktime.service';
import { WorkingTimeComponentCommon } from 'app/common/components/working-time/common/working-time.component';


@Component({
  selector: 'app-working-time-cva',
  templateUrl: './working-time.component.html',
  styleUrls: ['../common/working-time.component.scss'],
  providers: [
    WorktimeService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkingTimeCVAComponent),
      multi: true
    }]
})

@Mixin([WorkingTimeComponentCommon, ToggleEdit])
export class WorkingTimeCVAComponent implements ControlValueAccessor, OnInit {
  public tempData: any;

  @Input() title: string;
  // TODO: вынести все эти объявления в интерфейсы

  ngOnInitCommon: () => void;
  modelTransformIn: () => any;
  modelTransformOut: () => any;
  onChange: (day) => void;
  _onSave: () => void;
  _onCancel: () => void;
  _onBeginEdit: () => void;
  onClickOutside: ($) => void;

  public days: Array<any>;
  public hours: Array<any>;

  isDefault: () => boolean;

  public data: any;
  public enableEdit = false;

  private propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = this.service.modelTransformIn(obj);
      this.tempData = this.modelTransformIn();
    }
  }

  public onChangeCVA(day) {
    this.onChange(day);
  }

  onSave() {
    this._onSave();
    this.propagateChange(this.modelTransformOut());
  }

  onCancel() {
    this._onCancel();
    this.propagateChange(this.modelTransformOut());
  }

  onBeginEdit() {
    this._onBeginEdit();
  }

  constructor(public service: WorktimeService) { }

  ngOnInit() {
    this.ngOnInitCommon();
  }
}
