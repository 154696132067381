import { Component, OnInit } from '@angular/core';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import { CoachesService } from 'app/components/coaches/services/coaches.service';
import { ActivatedRoute,  Router} from '@angular/router';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import { CoachAccrual, CoachPayout, ICoachRegistryModel } from 'app/common/models/coach-registry.model';
import * as moment from 'moment';
import { ContextService } from 'app/common/services/context.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { Injector } from '@angular/core';
import {Bank, Registry, RegistryElement, RegistryElementStatus, RegistryExportType} from 'app/common/models/registry.model';
import {ExcelService} from 'app/common/services/excel.service';
import {CityService} from 'app/common/services/city.service';
import {ICityModel} from 'app/common/models/city-model';
import {CsvService} from 'app/common/services/csv.service';
import {TxtService} from 'app/common/services/txt.service';
import {ApiV2Service} from 'app/common/services/api-v2.service';
import { PaymentTypeAlias, PaymentTypeAliasDisplayName } from 'app/common/models/coach-rate.model';
import { CoachesRateCalculateService } from '../../services/coaches-rate-calculate.service';

@Component({
  selector: 'app-coachregistry-edit',
  templateUrl: './coachregistry-edit.component.html',
  styleUrls: ['./coachregistry-edit.component.scss'],
  providers: [CoachRegistryService]
})

export class CoachRegistryEditComponent implements OnInit {
  public amountTotal: number;
  public commissionTotal: any = 0;
  public bonusesTotal: number;
  public clubCountTotal: number;
  public cities: ICityModel[] = [];
  public clubLegalInfo: any[] = [];
  public bankNames: any[] = [Bank.AlfaBank, Bank.Tinkoff];
  private numberOrderPayment: number;
  private localSettings = 'registryTableSettings';
  private registryEditComponent: Registry
  public static readonly componentName: string = 'CoachRegistryEditComponent';

  public registry: ICoachRegistryModel;
  public registryElement: any[] = [];
  public registryElementFilter: any = [];
  public payouts: any = [];
  public coaches: { [key: string]: string } = {};
  public isLoading = false;
  public contextService: ContextService;
  public routingParams: IRoutingParams;

  public RegistryExportType = RegistryExportType;

  constructor(
    private apiV2: ApiV2Service,
    private excelService: ExcelService,
    private csvService: CsvService,
    private txtService: TxtService,
    private cityService: CityService,

    protected service: CoachRegistryService,
    protected coachesService: CoachesService,
    private router: Router,
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private coachesRateCalculate: CoachesRateCalculateService,
    private coachRegistryService: CoachRegistryService,
    injector: Injector
  ) {
      this.contextService = injector.get(ContextService);
      this.contextService.routingParams.subscribe(params => {
        this.routingParams = params;
      });
  }

  async ngOnInit(): Promise<any> {
    try {
      this.route.queryParams.subscribe(async params => {
        if(params?.id) {
          await this.service.getRegistryById(params.id).then(e => {
            this.registry = e;
          });

          await this.service.getAccrualsByRegistryId(params.id).then(e => {
            if(e){
              this.registryElement = e;
              if (this.registry.total == 0 && this.registry.status == "Draft")
              {
                let _listToSave = []
                this.registryElement.forEach(item => {
                  let _alias = item.paymentTypeAlias ?? PaymentTypeAlias.class
                  let _params = JSON.parse(item.params)
                  let _total = this.coachesRateCalculate.coachSalaryCalculators[_alias].calc(_params)
                  item.total = _total
                  item.params = JSON.stringify(_params)
                  _listToSave.push(item)
                })
                this.coachRegistryService.saveAllAccruals(_listToSave)
              }


              const filterReg = this.registryElement.reduce(function (r, a) {
                r[a.coachId] = r[a.coachId] || [];
                r[a.coachId].push(a);
                return r;
              }, Object.create(null));

              this.registryElementFilter = Object.entries(filterReg);
            }
            else {
              console.error(e);
            }
          });

          this.coaches = this.registryElement.reduce((acc, curr) => Object.assign(acc, { [curr.coachId]: curr.coachName }), {});

          await this.service.getPayoutsByRegistryId(params.id).then(e => {
            if(e){
              this.payouts = e;
            }
            else {
              console.error(e);
            }
          });

          this.sortRegistryElementsByCoachName();

          for (let i = 0; i < this.registryElementFilter.length; i++) {
            const t = {
              isVisibility: true,
              showPayout: false,
              showPayoutsDetails: false
            };
            this.registryElementFilter[i].push(t);

          }
          this.isLoading = true;
        }
      });
    }
    catch (err){
      console.log(err)
      console.error(err)
    }
  }

  sortRegistryElementsByCoachName() {
    this.registryElementFilter.forEach(element => {
      this.getCoachName(element);
      element[1].sort((a, b) => (sortIndIsSalary(a.paymentTypeAlias) - sortIndIsSalary(b.paymentTypeAlias))
        || (sortIndIsClass(a.classId) - sortIndIsClass(b.classId))
        || (a.date - b.date));
    });
    this.registryElementFilter.sort((a, b) => a[2] > b[2] ? 1 : a[2] < b[2] ? -1 : 0);
  }

  public async remove(elementId: string, elemGroup: any): Promise<void> {
    this.alertsService.showConfirm({
      header: 'Удалить начисление?',
      message: 'Эта операция изменит статус начисления на "Удалено"',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.delete(elementId, elemGroup);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public async save(): Promise<any> {
    this.registry.total = this.totalAccrual();
    await this.service.save(this.registry).then(async (e) => {
      if (e) {
        this.alertsService.alert.next({
          type: 'success',
          message: 'Реестр сохранен',
          header: 'Успех',
          position: 'top',
          timeout: 2000
        });
        this.close();
      } else {
        console.error(e);
      }
    });
  }


  private async delete(elementId: string, elemGroup: any): Promise<any> {
    this.service.deleteAccrual(elementId).then(async (remove) => {
      if(remove) {
        const idx = elemGroup[1].findIndex(x => x.id === elementId);
        elemGroup[1][idx].status = "Deleted";
        this.alertsService.alert.next({
          type: 'success',
          message: 'Запись удалена',
          header: 'Успех',
          position: 'top',
          timeout: 2000
        });
      }
      else {
        console.error(remove)
      }
    })
  }

  public editPayout(c: any) {
    c[3].showPayoutsDetails = true;
    c[3].showPayout = false;
  }

  public deletePayout(p: CoachPayout) {
    console.debug(p);
    this.service.deletePayout(p.id).then((res) => {
      if (res) {
        p.status = "Deleted";
        this.alertsService.alert.next({
          type: 'success',
          message: 'Запись удалена',
          header: 'Успех',
          position: 'top',
          timeout: 2000
        });
      }
      else {
        console.error("Не удалось удалить оплату.")
      }
    });
  }

  public closeAdd(c: any) {
    c[3].showPayoutsDetails = false;
  }

  public close(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public edit(element: CoachAccrual) {
    element.title = element.title.replace('&lt;', '<').replace('&gt;', '>');
    element.isEnableMode = true;
  }

  public cancel(element: CoachAccrual) {
    element.title = element.title.replace('<', '&lt;').replace('>', '&gt;');
    element.isEnableMode = false;
  }

  public getCoachName(element: any) {
    element.push(this.coaches[element[0]]);
  }

  public getClassLength(accruals: CoachAccrual[]) {
    let classesSet = new Set();
    accruals.filter(acr => acr.classId != null && acr.status !== "Deleted").forEach(classAcr => classesSet.add(classAcr.classId));
    return classesSet.size;
  }

  public async removeTable(table: any): Promise<any> {
    this.alertsService.showConfirm({
      header: 'Удалить таблицу из реестра?',
      message: 'Эта операция полностью "Удалит" таблицу из реестра',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.deleteAccruals(table);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public totalCoachAccrual(table: any): number {
    return table.filter(x => x.status.toLowerCase() !== "deleted").map(item => +item.total).reduce((prev, next) => prev + next, 0);
  }

  public totalCoachPayout(coachPayouts: CoachPayout[]): number {
    return coachPayouts.filter(x => x.status.toLowerCase() !== "deleted").map(item => +item.total).reduce((prev, next) => prev + next, 0);
  }


  public totalAccrual(): number {
    return this.registryElementFilter.map(c => this.totalCoachAccrual(c[1])).reduce((prev, next) => prev + next, 0);
  }

  public totalPayout(): number {
    return this.payouts.filter(x => x.status.toLowerCase() !== "deleted").map(item => +item.total).reduce((prev, next) => prev + next, 0);
  }

  public getPayoutsByCoachId(coachId: string) {
    return this.payouts.filter(x => x.coachId === coachId).map(x => Object.assign(x, { ...payoutFunctions(x) }));
  }

  public async deleteAccruals(table: any): Promise<any> {
      this.service.deleteAccrualsByCoachId(this.registry.id, table[0]).then(async (remove) => {
      if(remove) {
        this.registryElementFilter = this.registryElementFilter.filter(x => x[0] !== table[0]);
        this.registryElement = this.registryElement.filter(x => x.coachId !== table[0])

        this.alertsService.alert.next({
          type: 'success',
          message: 'Таблица удалена',
          header: 'Успех',
          position: 'top',
          timeout: 2000
        });
      }
      else {
        console.error(remove)
      }
    })
  }

  public updatePayout(newCoachPayout: CoachPayout, c: any) {
    this.payouts.push(newCoachPayout)
    c[3].showPayoutsDetails = false;
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public addElem(table: any): void {
    table.isEnableAdd = true;
  }

  public async addElement(table: any, newElement: CoachAccrual): Promise<any> {
    this.registryElement.push(newElement);
    table[1].push(newElement);
  }

  public openPageCoach(c: any) {
    const url = `${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/clubcoaches/${c[0]}`;
    window.open(url);
  }

  public showPayoutsFormatChanged(c: any) {
    c[3].showPayout = !c[3].showPayout;
    c[3].showPayoutsDetails = false;
  }

  public actionIsVisibility(c: any) {
    c[3].isVisibility = !c[3].isVisibility;
  }

  public export(data: any[], ext: RegistryExportType, d?: any, ds?: any[]): any {
    if (ext !== RegistryExportType.PaymentOrderFile && ext !== RegistryExportType.PaymentOrderZip) {
      for (let i = 0; i < this.registryElementFilter.length; i++) {
        if (this.registryElementFilter[i][3].isExcludeTable) {
          data = data.filter(x => x.clubId !== this.registryElementFilter[i][0])
        }
      }
    }

    const exportA: any[] = [];
    let filterArr = data?.filter(x => x.status.toLowerCase() !== RegistryElementStatus.deleted);
    filterArr.forEach((m, index) => {
      //const city = this.cities.filter(x => x.id === m.cityId)[0]?.name;
      const i = ++index;
      if (ext === RegistryExportType.CSV) {
        /*
        const e: any =  {
          'Контакт': m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
          'ФИО': m.fullName?.replace(',', '').replace(';', '').trim() ?? '',
          'Коментарий': m.comment?.replace(',', '').replace(';', '').trim(),
          'Дата': m.date.slice(0, -8),
          'Сумма': m.amount.toString().trim(),
          'Бонусы': m.bonuses.toString().trim(),
          'Город': city?.trim(),
          'Клуб': m.club.replace(',', '').replace(';', '').trim(),
          'Комиссия': m.commission.toString().replace('.', ',').trim() + '%',
        };
        exportA.push(e);
        */
      }
      else if (ext === RegistryExportType.ExcelFile || ext === RegistryExportType.ExcelLists)
      {
        let listCash = this.getPayoutsByCoachId(m.coachId).filter(m => m.status.toLowerCase() !== RegistryElementStatus.deleted);
        if (listCash.length === 0) // --- формирование выплат - если выплат нет
            {
              let e: any =  {
                '№': i.toString(),
                'Название': m.title,
                'Платеж': Number(m.total.toFixed(2).toString().trim()),
                'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias]
              };
              exportA.push(e);
            }
        else if (filterArr.length < listCash.length) // --- формирование выплат - если выплат больше чем строк в таблице реестра
            {
              let e: any =  {
                '№': i.toString(),
                'Название': m.title,
                'Платеж': Number(m.total.toFixed(2).toString().trim()),
                'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias],
                '':'',
                'Выплаты': '',
                'Дата': moment(listCash[index - 1].date).format('DD.MM.YYYY'),
                'Сумма': Number(listCash[index - 1].total.toFixed(2).toString())
              };
              exportA.push(e);

              if (filterArr.length === index)
              {
                for (let varInd = filterArr.length; varInd < listCash.length; varInd++)
                {
                  e =  {
                    '№': '',
                    'Название': '',
                    'Платеж': '',
                    'Способ начисления': '',
                    '':'',
                    'Выплаты': '',
                    'Дата': moment(listCash[varInd].date).format('DD.MM.YYYY'),
                    'Сумма': Number(listCash[varInd].total.toFixed(2).toString())
                  };
                  exportA.push(e);
                }
              }
            }
        else // --- формирование выплат - если выплат меньше чем строк в таблице реестра
            {
              if (listCash.length > index - 1)
              {
                let e =  {
                  '№': i.toString(),
                  'Название': m.title,
                  'Платеж': Number(m.total.toFixed(2).toString().trim()),
                  'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias],
                  '':'',
                  'Выплаты': '',
                  'Дата': moment(listCash[index - 1].date).format('DD.MM.YYYY'),
                  'Сумма': Number(listCash[index - 1].total.toFixed(2).toString())
                };
                exportA.push(e);
              }
              else
              {
                let e =  {
                  '№': i.toString(),
                  'Название': m.title,
                  'Платеж': Number(m.total.toFixed(2).toString().trim()),
                  'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias],
                  '':'',
                  'Выплаты': '',
                  'Дата': '',
                  'Сумма': ''
                };
                exportA.push(e);
              }
            }
      }
    });

    if (ext === RegistryExportType.PaymentOrderFile || ext === RegistryExportType.PaymentOrderZip) {
      this.numberOrderPayment = 0;
      if (ext === RegistryExportType.PaymentOrderFile) {
        if (!data[3].isExcludeTable) {
          exportA.push(this.paymentOrder(data));
        }
      } else if (ext === RegistryExportType.PaymentOrderZip) {
        data = this.registryElementFilter;
        for (const _dE of data) {
          if (!_dE[3].isExcludeTable) {
            exportA.push(this.paymentOrder(_dE));
          }
        }
      }
    }

    if (exportA.length === 0) {
      this.alertsService.alert.next({
        type: 'danger',
        header: 'Реестр пустой',
        message: 'В выгрузку не попадает не один тренер',
        position: 'top',
        timeout: 3000
      });
      return;
    }

    if (ext === RegistryExportType.CSV) {
      this.csvService.downloadFile(exportA, `Реестр от ${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`);
    } else if (ext === RegistryExportType.ExcelLists) { /// ---- выгрузка всех тренеров
      try {
        let detailsArray: any[]= [];
        ds.filter(k => k[1].filter(n => n.status.toLowerCase() !== RegistryElementStatus.deleted).length > 0).forEach(item => { // --- такая большая проверка нужна для фильтрации тренеров у которых нет записей в таблице реестра
          let listCash = this.getPayoutsByCoachId(item[0]).filter(m => m.status.toLowerCase() !== RegistryElementStatus.deleted);
          let detailItems: any[] = [];
          let i = 0;
          let filterArr = item[1]?.filter(m => m.status.toLowerCase() !== RegistryElementStatus.deleted);

          filterArr.forEach((m, index) => {
            i++;
            if (listCash.length === 0) // --- формирование выплат - если выплат нет
            {
              let e: any =  {
                '№': i.toString(),
                'Название': m.title,
                'Платеж': Number(m.total.toFixed(2).toString().trim()),
                'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias]
              };
              detailItems.push(e);
            }
            else if (filterArr.length < listCash.length) // --- формирование выплат - если выплат больше чем строк в таблице реестра
            {
              let e: any =  {
                '№': i.toString(),
                'Название': m.title,
                'Платеж': Number(m.total.toFixed(2).toString().trim()),
                'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias],
                '':'',
                'Выплаты': '',
                'Дата': moment(listCash[index].date).format('DD.MM.YYYY'),
                'Сумма': Number(listCash[index].total.toFixed(2).toString())
              };
              detailItems.push(e);

              if (filterArr.length - 1 === index)
              {
                for (let varInd = filterArr.length; varInd < listCash.length; varInd++)
                {
                  e =  {
                    '№': '',
                    'Название': '',
                    'Платеж': '',
                    'Способ начисления': '',
                    '':'',
                    'Выплаты': '',
                    'Дата': moment(listCash[varInd].date).format('DD.MM.YYYY'),
                    'Сумма': Number(listCash[varInd].total.toFixed(2).toString())
                  };
                  detailItems.push(e);
                }
              }
            }
            else // --- формирование выплат - если выплат меньше чем строк в таблице реестра
            {
              if (listCash.length > index)
              {
                let e =  {
                  '№': i.toString(),
                  'Название': m.title,
                  'Платеж': Number(m.total.toFixed(2).toString().trim()),
                  'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias],
                  '':'',
                  'Выплаты': '',
                  'Дата': moment(listCash[index].date).format('DD.MM.YYYY'),
                  'Сумма': Number(listCash[index].total.toFixed(2).toString())
                };
                detailItems.push(e);
              }
              else
              {
                let e =  {
                  '№': i.toString(),
                  'Название': m.title,
                  'Платеж': Number(m.total.toFixed(2).toString().trim()),
                  'Способ начисления': PaymentTypeAliasDisplayName[m.paymentTypeAlias],
                  '':'',
                  'Выплаты': '',
                  'Дата': '',
                  'Сумма': ''
                };
                detailItems.push(e);
              }
            }
          });
          let det: any = {
            name: item[2],
            date: item[1][0].title.replace('Оклад ', ''),
            count: Number(this.getClassLength(item[1]).toString().trim()),    //Кол-во занятий:
            allAmount: Number(this.totalCoachAccrual(item[1]).toFixed(2)),   //Всего начислено тренеру:
            paid: Number(this.totalCoachPayout(this.getPayoutsByCoachId(item[0])).toFixed(2)),   //Выплачено тренеру:
            unpaid:  Number((this.totalCoachAccrual(item[1]) - this.totalCoachPayout(this.getPayoutsByCoachId(item[0]))).toFixed(2)), //Осталось выплатить тренеру:
            arrayDetails : detailItems
          };
          detailsArray.push(det);
        });

        this.excelService.exportAsExcelFileWithManyCoaches(
          `Реестр расчета ЗП (${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')})`,
          detailsArray
        );
      }
      catch (error)
      {
        console.log(error)
      }
    }
    else if (ext === RegistryExportType.ExcelFile) {
      const e: any[] = [];
      d[1].forEach(m => {
        if (m.status === RegistryElementStatus.published || m.status === RegistryElementStatus.hold) {
          e.push(m.amount / 100 * m.commission);
        }
      });
      const details = {
        name: d[2],
        date: d[1][0].title.replace('Оклад ', ''),
        count: Number(this.getClassLength(data).toString().trim()),    //Кол-во занятий:
        allAmount: Number(this.totalCoachAccrual(data).toFixed(2)),   //Всего начислено тренеру:
        paid: Number(this.totalCoachPayout(this.getPayoutsByCoachId(d[1][0].coachId)).toFixed(2)),   //Выплачено тренеру:
        unpaid: Number((this.totalCoachAccrual(data) - this.totalCoachPayout(this.getPayoutsByCoachId(d[1][0].coachId))).toFixed(2)), //Осталось выплатить тренеру:
      }
      this.excelService.exportAsExcelFileCoaches(
        exportA,
        `Расчет ЗП для ${details.name} (${details.date})`,
        details);
    } else if (ext === RegistryExportType.PaymentOrderZip || ext === RegistryExportType.PaymentOrderFile) {
      this.txtService.downloadFileApi(exportA);
    }
  }

  private paymentOrder(data: any) {
    const legalInfo = this.clubLegalInfo.find(x => x.id === data[0]);
    const payer = this.payer(data[3].bankName);
    this.numberOrderPayment++;
    return {
      PrefixFileName: 'ПП',
      ClubName: `${this.nameClub(data[0])}`,
      BankName: `${data[3].bankName}`,
      Date: `${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
      Details: [
        {
          'СекцияДокумент': 'Платежное поручение',
          'Номер': `${this.numberOrderPayment}`,
          'Дата': moment().format('DD.MM.YYYY'),
          'Сумма': this.regAmoComTotal(data[1], data[2].amount),
          'ПлательщикСчет': payer?.Account,
          'Плательщик': `ИНН ${payer?.Inn} ${payer?.Payer}`,
          'ПлательщикИНН': payer?.Inn,
          'Плательщик1': payer?.Payer,
          'ПлательщикРасчСчет': payer?.Account,
          'ПлательщикБанк1': payer?.Bank1,
          'ПлательщикБанк2': payer?.Bank2,
          'ПлательщикБИК': payer?.Bik,
          'ПлательщикКорсчет': payer?.CorrespondentAccount,
          'ПолучательСчет': legalInfo?.legalInfo?.CurrentAccountNumber,
          'Получатель': `${legalInfo?.legalInfo?.Inn ? 'ИНН ' + legalInfo?.legalInfo?.Inn + ' ' : null}${legalInfo?.legalInfo?.LegalName?.replace('«', '"').replace('»', '"')}`,
          'ПолучательИНН': legalInfo?.legalInfo?.Inn,
          'Получатель1': legalInfo?.legalInfo?.LegalName?.replace('«', '"').replace('»', '"'),
          'ПолучательРасчСчет': legalInfo?.legalInfo?.CurrentAccountNumber,
          'ПолучательБанк1': legalInfo?.legalInfo?.NameBank,
          'ПолучательБИК': legalInfo?.legalInfo?.BikBank,
          'ПолучательКорсчет': legalInfo?.legalInfo?.NumberBankCorrespondentAccount,
          'ВидОплаты': '01',
          'Очередность': '5',
          'НазначениеПлатежа': `Оплата услуг за период с ${moment(this.registry.dateFrom).format('DD.MM.YYYY')} до ${moment(this.registry.dateTo).format('DD.MM.YYYY')}. ${legalInfo?.legalInfo?.ContractBasisPayment} / ${this.nameClub(data[0]).replace('«', '"').replace('»', '"').replace('—', '-')}. НДС не облагается`, // Назначение платежа одной строкой
        }
      ]
    };
  }

  private payer(bank: Bank) {
    switch (bank) {
      case Bank.AlfaBank:
        return {
          CorrespondentAccount: '30101810600000000774',
          Account: '40702810623000004276',
          Bik: '045004774',
          Bank1: 'ФИЛИАЛ "НОВОСИБИРСКИЙ" АО "АЛЬФА-БАНК"',
          Bank2: 'г. Новосибирск',
          Inn: '5404052028',
          Kpp: '540401001',
          Payer: 'Общество с ограниченной ответственностью "Единый спортивный абонемент"',
        }
        break;
      case Bank.Tinkoff:
        return {
          CorrespondentAccount: '30101810145250000974',
          Account: '40702810310000894083',
          Bik: '044525974',
          Bank1: 'АО "Тинькофф Банк"',
          Bank2: 'г. Москва',
          Inn: '5404052028',
          Kpp: '540401001',
          Payer: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЕДИНЫЙ СПОРТИВНЫЙ АБОНЕМЕНТ"',
        }
        break;
    }
  }

  public nameClub(clubId: string): string {
    return this.registryElement.filter(x => x.clubId === clubId)[0]?.club;
  }

  public regAmoComTotal(d: any, amount: any): any {
    if (d === null) {
      return amount - this.commissionTotal;
    }
    const e: any[] = [];
    d.forEach(m => {
      if (m.status === RegistryElementStatus.published || m.status === RegistryElementStatus.hold) {
        e.push(m.amount / 100 * m.commission);
      }
    });
    return (amount - e.reduce((a, b) => a + b, 0)).toFixed(2);
  }
}

function payoutFunctions(that: CoachPayout) {
  return {
    edit: () => that.isEnableMode = true,
    cancel: () => that.isEnableMode = false,
    remove: () => console.debug(that),
  };
}

function sortIndIsSalary(alias: string) {
  return alias === 'salary' ? 0 : 1;
}
function sortIndIsClass(classId: string) {
  return classId !== null ? 0 : 1;
}
