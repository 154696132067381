import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import { IPayoutRatesModel, PaymentTypeAlias, PaymentTypeAliasDisplayName } from 'app/common/models/coach-rate.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { CoachesService } from '../services/coaches.service';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import * as moment from 'moment';

@Component({
  selector: 'app-rate-element-edit',
  templateUrl: './rate-element-edit.component.html',
  styleUrls: ['./rate-element-edit.component.scss']
})
export class RateElementEditComponent implements OnInit {

  @Input() rate: IPayoutRatesModel;
  @Output() result = new EventEmitter<IPayoutRatesModel>();
  public form: FormGroup;

  constructor(
    protected coachesService: CoachesService,
    protected alertsService: AlertsService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      paymentTypeAlias: new FormControl(this.rate.paymentTypeAlias, Validators.required),
      salary: new FormControl(this.rate.salary, Validators.required),
      personalClassRate: new FormControl(this.rate.personalClassRate, Validators.required),
      fixedClassRate: new FormControl(this.rate.fixedClassRate, Validators.required),
      personInGroupRate: new FormControl(this.rate.personInGroupRate, Validators.required),
      forUnlimitedRate: new FormControl(this.rate.forUnlimitedRate, Validators.required),
      subscriptionPercentRate: new FormControl(this.rate.subscriptionPercentRate, Validators.required),
      effectiveSinceDate: new FormControl(this.rate.effectiveSinceDate, [Validators.required])
    });

    // --- выключаем все контролы ---
    this.form.get('fixedClassRate').disable();
    this.form.get('personInGroupRate').disable();
    this.form.get('personalClassRate').disable();
    this.form.get('subscriptionPercentRate').disable();
    this.form.get('forUnlimitedRate').disable();

    if (this.form.get('paymentTypeAlias').value == PaymentTypeAlias.subscription)
    {
      this.form.get('subscriptionPercentRate').setValue(this.form.get('personInGroupRate').value);
      this.form.get('subscriptionPercentRate').enable();
      this.form.get('forUnlimitedRate').setValue(this.form.get('fixedClassRate').value);
      this.form.get('forUnlimitedRate').enable();
      this.form.get('personInGroupRate').setValue(0);
      this.form.get('personalClassRate').setValue(0);
      this.form.get('fixedClassRate').setValue(0);
    }
    if (this.form.get('paymentTypeAlias').value == PaymentTypeAlias.personInGroup)
    {
      this.form.get('personInGroupRate').enable();
      this.form.get('personalClassRate').enable();
    }
  }

  public async save(): Promise<any> {
    if (this.form.valid) {
      this.rate.paymentTypeAlias = this.form.get('paymentTypeAlias').value;
      this.rate.salary = this.form.get('salary').value;
      this.rate.personalClassRate = this.form.get('personalClassRate').value;
      this.rate.fixedClassRate = this.form.get('fixedClassRate').value;
      this.rate.personInGroupRate = this.form.get('personInGroupRate').value;
      this.rate.effectiveSinceDate = this.form.get('effectiveSinceDate').value;
      if (this.form.get('paymentTypeAlias').value == PaymentTypeAlias.subscription)
      {
        this.rate.personInGroupRate = this.form.get('subscriptionPercentRate').value;
        this.rate.fixedClassRate = this.form.get('forUnlimitedRate').value;
        //this.rate.personalClassRate = this.form.get('forUnlimitedRate').value;
      }
      await this.coachesService.saveRate(this.rate).then(async (e) => {
        if (e) {
          this.rate.isEnableMode = false;
          this.result.emit(this.rate);
          this.alertsService.alert.next({
            type: 'success',
            message: 'Транзакция сохранена',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }

  public dateFormat(date: Date): string {
    return moment(date).format('DD.MM.YYYY');
  }

  public changeAlias(alias){
    if (alias == PaymentTypeAlias.class)
    {
      this.form.get('fixedClassRate').enable();
    }
    else
    {
      this.form.get('fixedClassRate').disable();
    }

    if (alias == PaymentTypeAlias.personInGroup)
    {
      this.form.get('personInGroupRate').enable();
      //this.form.get('personalClassRate').enable();
    }
    else
    {
      this.form.get('personInGroupRate').disable();
      //this.form.get('personalClassRate').disable();
    }

    if (alias == PaymentTypeAlias.subscription)
    {
      this.form.get('subscriptionPercentRate').enable();
      this.form.get('forUnlimitedRate').enable();
    }
    else
    {
      this.form.get('subscriptionPercentRate').disable();
      this.form.get('forUnlimitedRate').disable();
    }
  }

  public readonly _PaymentTypeAliases = Object.keys(PaymentTypeAlias);
  public readonly PaymentTypeAliasDisplayName = PaymentTypeAliasDisplayName;
}
