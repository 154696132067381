import { FormControl } from '@angular/forms';

const d2 = '(\\d{2})';
const d4 = '(\\d{4})';

export const dateValidator = () => {
    return (c: FormControl) => {
        if (c.value == null || c.value === '') {
            return null;
        }
        let valid = true;
        const regex = new RegExp(`^${d2}.${d2}.${d4}$`);
        // дд.мм.гггг

        const match = regex.exec(c.value);

        if (!match) {
            valid = false;
        } else {
            const d = parseInt(match[1], 10);
            const m = parseInt(match[2], 10);
            const y = parseInt(match[3], 10);

            if (d < 1 || d > 31 || m < 1 || m > 12 || y < 1900 || y > 2100) {
                valid = false;
            }
        }

        return valid ? null : {
            dateValidator: {
                valid: false
            }
        };
    }
}
