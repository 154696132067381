import { environment } from 'environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { IChatMessage } from '../models/chat.models';
import { Connection, Proxy, hubConnection } from 'signalr-no-jquery';

@Injectable({
  providedIn: 'root'
})
export class ChatHub {

  public connectionEstablished: EventEmitter<Boolean>;
  public connectionExists: Boolean;
  public connectionId: string;
  public messageReceived: EventEmitter<IChatMessage>;
  private chatConnection: Connection;
  private chat: Proxy;

  constructor(
  ) {
    this.connectionEstablished = new EventEmitter<Boolean>();
    this.connectionExists = false;
    this.messageReceived = new EventEmitter<IChatMessage>();
    this.chatConnection = hubConnection(environment.api + "/signalr/", { useDefaultPath: false, logging: true });
    this.chat = this.chatConnection.createHubProxy('hubChatRoom');
    this.registerOnServerEvents();
  }

  public connectToChat(chatId?: string)
  {
    this.startConnection(chatId);
  }

  public disconnect() {
    this.chatConnection.stop();
  }


  private async startConnection(chatId?: string): Promise<void> {
    if (this.connectionExists) {
      this.chatConnection.stop();
    }
    try {
      const resp = await this.chatConnection.start({ transport: 'serverSentEvents' });
      this.connectionId = resp.id;
      await this.chat.invoke("joinChat", chatId);
      this.connectionEstablished.emit(true);
      this.connectionExists = true;
    }
    catch (error) {
      console.log('*** Could not connect ' + error);
      this.connectionEstablished.emit(false);
    }
  }

  private registerOnServerEvents(): void {
    this.chat.off('sendMessage', this.onMessageReceived);
    this.chat.on('sendMessage', this.onMessageReceived);
  }

  private onMessageReceived = (data: IChatMessage) => this.messageReceived.emit(data);
}
