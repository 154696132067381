<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="close()" *ngIf="parentId">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Вложенные мероприятия</span>
    </div>
    <span class="content__header-title-value content__header-title-value_default" *ngIf="!parentId">
      Мероприятия
    </span>
    <div *ngIf="parentId">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model">
    <div class="clubrooms-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
            <span class="key" app-key-help [key]="'search'"></span>
            <span class="key" app-key-help [key]="'searchHK'"></span>
            <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell">Наименование</td>
              <td class="table-cell">Начало</td>
              <td class="table-cell">
                <span class="ticketcount" *ngFor="let item of _ticketTypes">{{item.name}}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let clubEvent of Model' (mousedown)="onEditStart(clubEvent,$event)" (click)="onEditStart(clubEvent)">
              <td class="table-cell">
                <span class="table-cell__mobile-title">Наименование</span>
                {{clubEvent.name}}
              </td>
              <td class="table-cell">
                <span class="table-cell__mobile-title">Начало</span>
                {{clubEvent.timeFrom | time2string: currentCity.timeZone}}
              </td>
              <td class="table-cell">
                <span class="table-cell__mobile-title"></span>
                <span class="ticketcount" *ngFor="let item of _mapTicketCounts(clubEvent.ticketCounts)">{{item}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </ng-container>
</div>
