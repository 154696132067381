import { Component, OnInit } from '@angular/core';
import {IRoutingParams} from '../../../common/models/context.model';
import {Registry} from '../../../common/models/registry.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {ContextService} from '../../../common/services/context.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-finance-registry',
  templateUrl: './finance-registry.component.html',
  styleUrls: ['./finance-registry.component.scss']
})
export class FinanceRegistryComponent implements OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject();
  public context: IRoutingParams;
  public registry: Registry[] = [];

  constructor(
    private apiV2: ApiV2Service,
    private contextService: ContextService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.context = this.contextService.getRoutingParams();
    await this.apiV2.registry.getByClub(this.context.clubId).then(async (registry) => {
      this.registry = registry.sort((a, b) => { return b.createdAt - a.createdAt });
    });
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public data(data: string, field: string): any {
    return JSON.parse(data)[field];
  }

  public formatNumber(value: number): string {
    let point = '';
    let x = String(value).replace(/(\.|,)\d+/, (m) => { point = m; return ''; });
    x = x.split('').reverse().join('')
      .replace(/(\d{3})/g, '$1 ')
      .split('').reverse().join('');
    return x + point;
  }

  onRegistry(registry: Registry) {
    this.router.navigate([`/${this.context.cityId}/${this.context.clubNetId}/${this.context.clubId}/finance/registry/${registry.id}`], {relativeTo: this.route}).then();
  }
}
