import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Registry, RegistryElement} from '../../../common/models/registry.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {dateValidator} from '../../../common/validators/date.validator';

@Component({
  selector: 'app-registry-element-edit',
  templateUrl: './registry-element-edit.component.html',
  styleUrls: ['./registry-element-edit.component.scss']
})
export class RegistryElementEditComponent implements OnInit {

  @Input() element: RegistryElement;
  @Input() registry: Registry;
  @Output() result = new EventEmitter<RegistryElement>();
  public form: FormGroup;

  constructor(
    private apiV2: ApiV2Service,
    private alertsService: AlertsService
  ) { }

  async ngOnInit(): Promise<any> {
    this.form = new FormGroup({
      contact: new FormControl(this.element.contact.trim().toString().replace('&lt;', '<').replace('&gt;', '>'), Validators.required),
      fullName: new FormControl(this.element.fullName, Validators.required),
      comment: new FormControl(this.element.comment, Validators.required),
      date: new FormControl(this.element.date.substr(0, 10), [Validators.required, dateValidator()]),
      amount: new FormControl(this.element.amount, Validators.required),
      bonuses: new FormControl(this.element.bonuses, Validators.required),
      commission: new FormControl(this.element.commission, Validators.required),
      status: new FormControl(this.element.status, Validators.required)
    });
  }

  public async save(): Promise<any> {
    if (this.form.valid) {
      this.element.contact = this.form.get('contact').value;
      this.element.fullName = this.form.get('fullName').value;
      this.element.comment = this.form.get('comment').value;
      this.element.date = this.form.get('date').value + ' 0:00:00';
      this.element.amount = this.form.get('amount').value;
      this.element.bonuses = this.form.get('bonuses').value;
      this.element.commission = this.form.get('commission').value;
      this.element.status = this.form.get('status').value;
      this.element.contact = this.element.contact.trim().toString().replace('<', '&lt;').replace('>', '&gt;');

      await this.apiV2.registry.edit(this.element).then(async (e) => {
        if (e) {
          this.element.isEnableMode = false;
          this.result.emit(this.element);
          this.alertsService.alert.next({
            type: 'success',
            message: 'Транзакция сохранена',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }
}
