import { Component, OnInit } from '@angular/core';
import { Injector } from '@angular/core';
import { ChatService } from 'app/components/chats/chats.service';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { IChat } from 'app/common/models/chat.models';

@Component({
    selector: 'app-chats',
    templateUrl: './chats.component.html',
    styleUrls: ['./chats.component.scss']
})
export class ChatsComponent extends TableListComponent<IChat> implements OnInit {
    public static readonly componentName: string = 'ChatsComponent';

    constructor(
        injector: Injector,
        protected service: ChatService
    ) {
        super(injector, service);
        this.contextService.routingParams.subscribe(params => {
          this.isInClubContext = params.isInClubContext;
          this.title = params.isInClubContext ? 'Чаты клуба' : 'Список чатов';
        });
    }

    public isInClubContext: boolean;
    public title: string;

    private readonly pageSize = 50;
    public pageIndex: number = 0;
    public totalItemCount: number;

    onChatClick(event: IChat, mode: 'open' | 'edit' | 'read') {
      this.service.saveRedirectUrl();
      let route = this.routingParams.isInClubContext
        ? this.route.routeConfig.path
          .replace(':cityId', this.routingParams.cityId)
          .replace(':clubNetId', this.routingParams.clubNetId)
          .replace(':clubId', this.routingParams.clubId)
        : this.route.routeConfig.path
          .replace(':cityId', this.routingParams.cityId)
      if (event == null)
        route = `${route}/create`
      else if ((event.groupType == 'group' || event.groupType == 'mailing') && mode == 'edit')
        route = `${route}/edit/${event.id}`;
      else if (event.groupType == 'mailing' && mode == 'open')
        route = `${route}/mailing/${event.id}`;
      else
        route = `${route}/id/${event.id}`;
      this.router.navigateByUrl(route);
    }

    onPrimaryChatClick(item: IChat) {
      this.service.saveRedirectUrl();
      let route = this.routingParams.isInClubContext
        ? this.route.routeConfig.path
          .replace(':cityId', this.routingParams.cityId)
          .replace(':clubNetId', this.routingParams.clubNetId)
          .replace(':clubId', this.routingParams.clubId)
        : this.route.routeConfig.path
          .replace(':cityId', this.routingParams.cityId);
      route = `${route}/id/${item.entityId}`;
      this.router.navigate([route]);
    }

    updateModel() {
      console.log(this.isInClubContext);
      return this.service.getItemList(this.isInClubContext && this.currentCity, this.pageIndex, this.pageSize)
        .then(res => {
          if (this.pageIndex)
            this.Model = (this.Model as IChat[]).concat(res.items);
          else
            this.Model = res.items;
          this.totalItemCount = res.pagingInfo.totalItemCount;
          this.onModelLoadCallback();
        });
    }

    public showNextPage() {
      this.pageIndex += 1;
      this.updateModel();
    }
}
