import {Component, OnInit, Injector, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { ScheduleService } from 'app/components/schedule/services/schedule.service';
import { ApiService } from 'app/common/services/api.service';
import { ContextService } from 'app/common/services/context.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { DashboardLinkComponent } from 'app/components/dashboard/link/link.component';
import { IRoutingParams } from 'app/common/models/context.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { CityService } from 'app/common/services/city.service';
import { ChatService } from '../chats/chats.service';
import { IChat } from 'app/common/models/chat.models';
import { ChatsActiveHub } from 'app/common/services/chats-active.hub';
import { IChatGetActiveResponse } from 'app/common/models/chat.models';
import { SoundPlayService } from 'app/common/services/sound-play.service';
import {Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardHub } from 'app/common/services/dashboard.hub';
import { LocalPreloader, PreloaderService } from 'app/common/services/preloader.service';
import {ClubSetting} from '../../common/models/club-setting.model';
import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { FormBuilder } from '@angular/forms';
import {ScheduleHubService} from '../schedule/services/schedule.hub.service';

type Subscr = {
  id: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('shrinkOut', [
      state('inactive', style({
        height: 0
      })),
      state('active', style({
        height: '*'
      })),
      transition('inactive => active', animate('300ms ease-in-out')),
      transition('active => inactive', animate('300ms ease-in-out'))
    ])
  ]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  public static readonly componentName: string = 'DashboardComponent';

  protected ngUnsubscribe: Subject<void> = new Subject();

  public infoState: string;
  protected hotkeysService: HotkeysService;
  protected hkAdd: Hotkey | Hotkey[];
  public client = {
    primaryImageUrl: 'http://offline.sportpriority.com/ira.png'
  };
  public classVisits: any[];
  public visits: any[];
  public classes: any[];
  public schedule: any;
  public startDate: string;
  public pageIndex: number;
  public processedEvents = [];
  public eventsCount: number;
  public clubTimestamp: number;
  public baseDate: string;
  public context: IRoutingParams;
  public chats: any[];
  public totalChatsCount: number;
  public isRefresh: boolean = true;
  public errorEvents: boolean = false;
  public guidEmpty = '00000000000000000000000000000000';
  public isShowBtnElectronicQueue = false;
  public isResource = false;
  public isAutomaticBookingQueue = false;
  public isYouCanQueue = false;
  public clubSettings: ClubSetting[] = [];
  @ViewChild('eventsRef') public eventsRef: ElementRef;
  @ViewChild('chatsRef') public chatsRef: ElementRef;
  @ViewChild('classesRef') public classesRef: ElementRef;

  public visitsControls = this.fb.group({});

  private scheduleSignalRSubscription$?: Subscription;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private preloaderService: PreloaderService,
    private contextService: ContextService,
    private scheduleService: ScheduleService,
    private chatService: ChatService,
    private chatsHub: ChatsActiveHub,
    private soundPlayService: SoundPlayService,
    private cityService: CityService,
    private alertsService: AlertsService,
    private dashboardHub: DashboardHub,
    private fb: FormBuilder,
    private scheduleHubService: ScheduleHubService,
  ) {
    this.hotkeysService = injector.get(HotkeysService);
    this.hkAdd = this.hotkeysService.add(new Hotkey(['=', 'ins', 'plus'], this.hkAddPress(this), []));
  }

  /*async refreshDashboard() {
    this.dashboardHub.createNewRequest
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => setTimeout(() => this.signalRUpdate(), 1000));
    this.dashboardHub.connectToDashboard(this.context.clubId);
  }*/

  ngAfterViewInit(): void {
    this.getChats(3);
  }

  /*private tmUpdate;
  private signalRUpdate() {
    if (this.tmUpdate)
      clearTimeout(this.tmUpdate);
    this.tmUpdate = setTimeout(() => this.getEvents(), 0);
  }*/

  async ngOnInit(): Promise<any> {
    this.infoState = 'active';
    this.pageIndex = 0;
    this.clubTimestamp = this.cityService.getTimestampClub();
    this.baseDate = new Date(this.clubTimestamp).toLocaleDateString('ru');
    this.context = this.contextService.getRoutingParams();
    this.clubSettings = await this.apiService.clubSettings.getByClub(this.contextService.getRoutingParams().clubId);
    if (this.clubSettings.find(x => x.alias === 'IsShowBtnElectronicQueue'))
      this.isShowBtnElectronicQueue = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowBtnElectronicQueue')?.value) ?? false;
    if (this.clubSettings.find(x => x.alias === 'isResource'))
      this.isResource = JSON.parse(this.clubSettings.find(x => x.alias === 'isResource')?.value) ?? false;
    if (this.clubSettings.find(x => x.alias === 'IsAutomaticBookingQueue'))
      this.isAutomaticBookingQueue = JSON.parse(this.clubSettings.find(x => x.alias === 'IsAutomaticBookingQueue')?.value) ?? false;
    if (this.clubSettings.find(x => x.alias === 'IsYouCanQueue'))
      this.isYouCanQueue = JSON.parse(this.clubSettings.find(x => x.alias === 'IsYouCanQueue')?.value) ?? false;
    this.getVisits();
    this.getClasses();
    this.getEvents();

    this.scheduleHubService.start(this.contextService.getRoutingParams().clubId);
    this.startSignalRListener();
  }

  private startSignalRListener() {
    this.scheduleSignalRSubscription$ = this.scheduleHubService.scheduleUpdateEvent
      .subscribe((res: any) => setTimeout(async () => {
        const _clubId = res;
        if (this.contextService.getRoutingParams().clubId === _clubId) {
          this.getClasses();
          // this.getEvents();
        }
      }, 1000));
  }

  ngOnDestroy(): void {
    this.scheduleSignalRSubscription$.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.chatsHub.disconnect();
    this.dashboardHub.disconnect();
  }

  visitsControlsGenerate(visits): void {
    visits.forEach(v => {
      this.visitsControls.addControl(`v-${v.id}`, this.fb.control(this.guidEmpty));
      for(let sub of v.subscriptions) {
          this.visitsControls.get(`v-${v.id}`).setValue(sub.id);
      }
    });
  }

  public get soundsChatsOff(): boolean {
    return this.soundPlayService.loadSettings(values => values.ActiveChatsOff);
  }
  public set soundsChatsOff(value: boolean) {
    this.soundPlayService.saveSettings({ ActiveChatsOff: value });
  }

  toggleInfo() {
    this.infoState = (this.infoState === 'active') ? 'inactive' : 'active';
  }

  protected hkAddPress(that: this) {
    return () => {
      that.router.navigate(['../addclienttoclass'], { relativeTo: that.route });
      return true;
    };
  }

  @LocalPreloader<DashboardComponent>(x => x.preloaderService, a => a.chatsRef)
  async getChats(pageSize: number) {
    const res = await this.chatService.getActiveItemList(this.context.clubId, pageSize);
    this.onActiveChatsChanged(res, true);
    this.chatsHub.messageReceived.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(val => this.onActiveChatsChanged(val));
    this.chatsHub.connectToChatDashboard(this.context.clubId, pageSize);
  }

  private onActiveChatsChanged(res: IChatGetActiveResponse, initialCall?: boolean) {
    if (!initialCall && !this.soundsChatsOff) {
      if (res.items.some(x => !this.chats.some(y => y.id === x.id && y.updatedAt === x.updatedAt)))
        this.soundPlayService.beep();
    }
    this.chats = res.items.map((x) => {
      const updated = new Date(x.updatedAt);
      x['updated'] = updated.getDate() == new Date().getDate() ? updated.toLocaleTimeString().substr(0, 5) : updated.toLocaleDateString().substr(0, 5);
      return x;
    });
    this.totalChatsCount = res.items.length < res.pagingInfo.totalItemCount ? res.pagingInfo.totalItemCount : undefined;
  }

  onChatStart(chat: IChat) {
    this.chatService.saveRedirectUrl();
    this.router.navigate([`/${this.context.cityId}/${this.context.clubNetId}/${this.context.clubId}/chats/id/${chat.id}`]);
  }

  getVisits() {
    const tm = Date.now();
    const beforeNowJsTime = tm - (1 * 3600000); // 1 часа до настоящего момента
    const afterNowJsTime = tm + (3 * 3600000);  // 3 час после настоящего момента
    const context = this.contextService.getRoutingParams();

    this.apiService.visits.getClubVisits(context, beforeNowJsTime, afterNowJsTime)
      .then(res => {
        this.classVisits = res.classVisits
        this.visitsControlsGenerate(this.classVisits);
      });
  }

  confirmVisited(client) {
    this.scheduleService.confirmVisited(client, false)
      .then((resp) => {
        if (resp)
          this.classVisits = this.classVisits.filter(x => x.id !== client.id);
      });
  }

  confirmMissed(client) {
    this.scheduleService.confirmMissed(client)
      .then((resp) => {
        if (resp)
          this.classVisits = this.classVisits.filter(x => x.id !== client.id);
      });
  }

  cancelBooking(client) {
    this.scheduleService.cancelBooking(client)
      .then((resp) => {
        if (resp)
          this.classVisits = this.classVisits.filter(x => x.id !== client.id);
      });
  }

  async selectSubscriptionForClient(classVisitId: string, subscriptionId: string, subscriptions: Subscr[]) {
    console.debug(subscriptions);
    if (!classVisitId) return;

    const oldSubcsriptionId = subscriptions.find(m => m.isSelected)?.id ?? this.guidEmpty;
    const errorHandler = () => {
      subscriptions.forEach(s => s.isSelected = s.id === oldSubcsriptionId);
      this.visitsControls.get(`v-${classVisitId}`).setValue(oldSubcsriptionId);
    };

    try {
      const isValid = await this.scheduleService
        .validateSubscriptionNotSuitable({
          classVisitId: classVisitId,
          clientSubscriptionId: subscriptionId
        });
      if (isValid) {
        await this.scheduleService.changeSubscriptionPlan({
          classVisitId: classVisitId,
          clientSubscriptionId: subscriptionId
        });
        subscriptions.forEach(s => {
          s.isSelected = s.id === classVisitId;
          this.visitsControls.get(`v-${classVisitId}`).setValue(s.id);
        });
      } else if (await this.selectSubscriptionForClientAlert()) {
        await this.scheduleService.changeSubscriptionPlan({
          classVisitId: classVisitId,
          clientSubscriptionId: subscriptionId,
          isValidateSubscriptionNotSuitable: true
        });
        subscriptions.forEach(s => {
          s.isSelected = s.id === classVisitId;
          this.visitsControls.get(`v-${classVisitId}`).setValue(s.id);
        });
      } else
        errorHandler();
    } catch {
      errorHandler();
    }
  }

  private selectSubscriptionForClientAlert(): Promise<boolean> {
    return new Promise(resolve => this.alertsService.showConfirm({
      header: 'Абонемент клиента не распространяется на данное занятие.',
      message: 'Можем списать занятие с указанного абонемента в обход ограничений. Делаем?',
      buttons: [{
        title: 'Да',
        callback: () => resolve(true)
      }, {
        title: 'Нет',
        callback: () => resolve(false)
      }]
    }));
  }

  async getClasses() {
    return this.apiService.schedule.getList(this.context, this.baseDate, null, false)
      .then(res => {
        this.schedule = res;
        this.startDate = res.startDate;
        this.classes = res.classes.filter(item => {
          const [day, month, year] = item.startDate.split('.');
          const [hour, minute] = item.startTime.split(':');
          const timestamp = new Date(year, month - 1, day, hour, minute).getTime();
          return this.cityService.checkTime(-1, this.clubTimestamp, timestamp);
        }).filter(item => item.entityStatus === EntityStatus.published && item.classStatus === 'available');
      });
  }

  goToClient(client) {
    const url = this.contextService.makeContextUrl(`clubclients/${client.clientId}`);
    this.router.navigate([url]);
  }


  goToClass(classItem) {
    if (classItem.id === '00000000000000000000000000000000') {
      this.router.navigate([`../clubschedule/${this.startDate}/virtual/${classItem.prototypeId}/${classItem.startDate}`], { relativeTo: this.route })
    } else {
      this.router.navigate([`../clubschedule/${this.startDate}/${classItem.id}`], { relativeTo: this.route });
    }
  }

  @LocalPreloader<DashboardComponent>(x => x.preloaderService, a => a.eventsRef)
  async getEvents() {
    this.processedEvents = [];

    return this.apiService.events.getEvents(this.context, this.pageIndex, 10)
    .then(res => {
      this.eventsCount = res.pagingInfo.totalItemCount;
      res.items = res.items.map(item => {
        if (item.parameters['class'] && item.parameters['course']) {
          item.parameters['course'].id = 'class' + item.parameters['class'].id;
        }
        return item;
      })
      this.processEvents(res.items);
    }).catch(() => {
      this.errorEvents = true;
      });
  }

  public processEvents(events) {
    const context = this.context;
    const re = /\s*(\{.*?\})\s*/g;

    const setEventColor = (eventDateTime) => {
      const dateTimeParts = eventDateTime.split(' ');
      const [day, month, year] = dateTimeParts[0].split('.');
      const [hour, minute] = dateTimeParts[1].split(':');
      const eventDate: any = new Date(year, month - 1, day, hour, minute);
      const now = new Date();
      const nowUTCSec: any = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
      const nowUTCDate: any = new Date(nowUTCSec);
      const diffHours = Math.floor((nowUTCDate - eventDate) / 3.6e6);

            if(diffHours < 1) return '#FFCED7';
            if(diffHours > 1 &&  diffHours <= 2) return '#f9eaea';
            if(diffHours > 2 && diffHours <= 3) return '#f0f1f1';
            if(diffHours > 3) return '#f7f7f7';
    }

    for (const event of events) {
      const input = event.description;
      const params = event.parameters;
      const dateTime = event.dateTime;
      const tokens = input.split(re).filter(Boolean);

      const processedEvent = {
        title: event.title,
        params: params,
        dateTime: dateTime,
        color: setEventColor(dateTime),
        tokens: []
      };

      for (const token of tokens) {
        const isComponent = token.includes('{');
        //event.description без скобок
        const strippedToken = token.replace(/[{}\.]/g, '').trim();
        if (isComponent && (
          strippedToken === 'client' ||
          strippedToken === 'course' ||
          strippedToken === 'classVisit'
        )) {
          processedEvent.tokens.push({
            isComponent: true,
            component: DashboardLinkComponent,
            params: { ...params[strippedToken], context }
          });
        } else {
          processedEvent.tokens.push({
            isComponent: false,
            text: params[strippedToken] ? params[strippedToken].value : strippedToken
          });
        }
      }
      this.processedEvents.push(processedEvent);
    }
  }

  addEvents() {
    this.pageIndex += 1;

    this.apiService.events.getEvents(this.context, this.pageIndex, 10)
    .then(res => {
      this.eventsCount = res.pagingInfo.totalItemCount;
      res.items = res.items.map(item => {
        if (item.parameters['class'] && item.parameters['course']) {
          item.parameters['course'].id = 'class' + item.parameters['class'].id;
        }
        return item;
      })
      res.items = res.items.slice(this.processedEvents.length);

      this.processEvents(res.items);
    });
  }

  confirmEvent(event) {
    const context = this.contextService.getRoutingParams();
    this.apiService.events.confirmEvent(context, event.params.class.id, event.params.classVisit.id);
  }

  infinity(num: number) {
    return num > 1000 ? "∞" : num;
  }

  changeable(evt: any) {
    console.log('height-evt: ', evt);
    // evt.style.height = evt.scrollHeight + 'px';
  }

  scrollToCheckVisits(el: HTMLElement) {
    el.scrollIntoView();
  }

  goToQueue() {
    const url = this.contextService.makeContextUrl(`queue`);
    this.router.navigate([url]);
  }

  goToResourceQueue() {
    const url = this.contextService.makeContextUrl(`resourcequeue`);
    this.router.navigate([url]);
  }
}
