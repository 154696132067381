import { Route } from '@angular/router';
import { ClientsComponent } from 'app/components/clients/clients.component';
import { ClientsEditComponent } from 'app/components/clients/clients-edit/clients-edit.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import {VisitEditComponent} from '../../components/clients/visit-edit/visit-edit.component';

export const clientsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubclients',
  component: ClientsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Клиенты' }
};

export const clientRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubclients/:id',
  component: ClientsEditComponent,
  canActivate: [ AuthGuard ],
  pathMatch: 'full',
  data: { title: 'Редактирование клиента' }
};

export const visitEditRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubclients/:id/visit/:visitId',
  component: VisitEditComponent,
  canActivate: [ AuthGuard ],
  pathMatch: 'full',
  data: { title: 'Редактирование визита' }
};
