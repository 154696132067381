import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITrainingCategory } from 'app/common/models/training-category.model';
import { IIdName } from 'app/common/models/id-name.model';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategorySelectComponent),
      multi: true
    }
  ]
})
export class CategorySelectComponent implements ControlValueAccessor, OnChanges {

  public data: Array<ITrainingCategory> = [];
  @Input() options: Array<ITrainingCategory> = [];
  public isVisible = false;
  private columnCount = 2;
  private columns = [];
  constructor() { }

  private propagateChange = (_: any) => { };

  public isChecked(categoryId: string) {
    return !!this.data.find(x => x.id === categoryId);
  }

  public getColumns() {
    return this.columns;
  }

  ngOnChanges() {
    this.columns = [];
    let totalCount = 0;
    this.options.forEach(x => {
      totalCount++;
      totalCount += x.children.length;
    });

    let columnIndex = 1;
    let currCount = 0;
    let prevIndex = 0;
    let currIndex = 0;
    let prevCount = 0;

    this.options.forEach(x => {
      currIndex++;
      currCount++;
      currCount += x.children.length;

      if ((currCount >= Math.trunc(totalCount / this.columnCount) * columnIndex)
        &&
        (this.columns.length === 0
          ||
          ((currCount - prevCount) >= this.columns[columnIndex - 2].count)
          ||
          currCount >= totalCount)
      ) {

        columnIndex++;
        this.columns.push({
          start: prevIndex,
          end: currIndex + 1,
          count: currCount - prevCount,
          number: this.columns.length
        });

        prevCount = currCount;
        prevIndex = currIndex + 1;
      }

    });

    if (prevIndex < this.options.length) {
      this.columns.push({
        start: prevIndex,
        end: this.options.length
      });
    }
  }


  public onChange(event, category: ITrainingCategory, subcategory?: IIdName) {
    const state = event.target.checked;

    if (!subcategory) {
      if (state) {
        this.data.push({ id: category.id, name: category.name });
      } else {
        this.options
          .find(cat => cat.id === category.id)
          .children
          .forEach(children => {
            const childrenIndex = this.data
              .findIndex(x => x.id === children.id);
            if (childrenIndex !== -1) {
              this.data.splice(childrenIndex, 1);
            }
          });

        const categoryIndex = this.data
          .findIndex(x => x.id === category.id);

        if (categoryIndex !== -1) {
          this.data.splice(categoryIndex, 1);
        }
      }
    } else {
      if (state) {
        if (!this.data.find(x => x.id === category.id)
        ) {
          this.data.push({ id: category.id, name: category.name });
        }

        this.data.push({ id: subcategory.id, name: subcategory.name });
      } else {
        const subcategoryIndex = this.data
          .findIndex(x => x.id === subcategory.id);

        if (subcategoryIndex !== -1) {
          this.data.splice(subcategoryIndex, 1);
        }

        const childrenIds = this.options
          .find(cat => cat.id === category.id)
          .children
          .map(x => x.id);

        if (!this.data.find(x => childrenIds.includes(x.id))) {
          const categoryIndex = this.data
            .findIndex(x => x.id === category.id);
          this.data.splice(categoryIndex, 1);
        }
      }
    }
    this.propagateChange(this.stripCategories());
  }

  getCategoryName(id: string) {
    const cat = this.options.find(x => x.id === id);
    return (cat && cat.name) || '[не найдено]';
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.propagateChange(this.stripCategories());
    }
  }

  private stripCategories() {
    return this.data.map(x => ({ id: x.id }));
  }
}
