import { IIdName } from 'app/common/models/id-name.model';
import { IPlanVariant } from 'app/common/models/plan-variant.model';
import { IMedias } from './medias.model';

export enum IDurationUnit {
    day,
    week,
    month,
    year
}

export interface ISubscription {
    id: string;
    name: string;
    description: string;
    entityStatus: string;
    group: string,
    saleCampainStartDate?: number;
    saleCampainEndDate?: number;
    suspendNumberDays?: number;
    suspendNumberTimes?: number;
    sortOrder: string,
    courses: Array<IIdName>;
    variants: Array<IPlanVariant>;
    medias: IMedias;
    clientSubscriptionCount: number;
    isSoldSubscriptionsUpdate: boolean;
    progressiveDiscountEnabled: boolean | null;
    activationType: string;
    clientSubscriptionLimit: number;
    toDateCorrection: number;
    daysUntilActivation: number;
}

export interface IClientSubscriptionPlan {
    id: string;
    courses: any;
    suspendDate: any;
    reactivateDate: any;
    suspendNumberDays: number;
    suspendNumberTimes: number;
    clientId: string;
    clientName: string;
    orderId: string;
    spSubscriptionId?: string;
    description: string;
    fromDate: string;
    toDate: string;
    name: string;
    paymentSum: string;
    purchasedAt: number;
    purchasedAtLocal: string;
    status: string;
    visitCount: number;
    visitCounter: number;
    extendTheDate: string;
    addVisits: string;
    payFee: string;
    fromType: string;
}

export interface IClientSubscriptionSuspendHistory {
  id: string;
  fromDate: any,
  toDate: any,
  suspendDate: any;
  reactivateDate: any;
  clientSubscriptionId: string;
}

export interface ISellSubscriptionPlan {
    clientId: string;
    orderDiscount?: number;
    subscriptionPlanVariantId: string;
    startDate: string;
    amount?: number;
    source?: string;
}

export interface IOrderPayment {
    amount: number;
    paymentDate: number;
    source: string;
}

export interface IClientOrderInfo {
    id: string;
    subscriptionName: string;
    clientName: string;
    sum: number;
    paid: number;
    orderPayments: IOrderPayment[];
}
