import { Injectable, Injector } from '@angular/core';

import { TableListService } from 'app/common/table-list/table-list.service';
import { ICoachModel } from 'app/common/models/coach.model';
import { IPayoutRatesModel } from 'app/common/models/coach-rate.model';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class CoachesService extends TableListService<ICoachModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  setStatus(status: string, item: ICoachModel) {
    return this.apiService.coaches.setStatus(this.contextService.getRoutingParams(), status, item);
  }

  getItemList() {
    return this.apiService.coaches.getList(this.contextService.getRoutingParams());
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.coaches.getItem(req);
  }

  save(model: ICoachModel) {
    return this.apiService.coaches.saveItem(this.contextService.getRoutingParams(), model);
  }

  create(model: ICoachModel) {
    return this.save(model);
  }

  getRates(coachId: string) {
    return this.apiService.coaches.getRates(this.contextService.getRoutingParams(), coachId)
  }

  saveRate(model: IPayoutRatesModel) {
    return this.apiService.coaches.saveRate(this.contextService.getRoutingParams(), model);
  }

  deleteRate(rateId: string) {
    return this.apiService.coaches.removeRate(this.contextService.getRoutingParams(), rateId );
  }
}
