<div class="box-title">Ставки выплат</div>
<div class="tooltip">Заполните для тренера параметры для расчета зарплаты и укажите с какой даты они начинают действовать. <br><br>*При при изменении размера зарплаты, добавьте новую строку и укажите дату, с которой она начинает действовать.</div>

<div class="form-group">
    <table class="table" *ngIf="rates.length">
      <thead>
        <tr>
          <td class="table-cell table-title-cell">Способ начисления</td>
          <td class="table-cell table-title-cell">Ежемесячный оклад</td>
          <td class="table-cell table-title-cell">Ставка за занятие</td>
          <td class="table-cell table-title-cell">Ставка за занимающегося в группе</td>
          <td class="table-cell table-title-cell">Ставка за час индивидуального занятия</td>
          <td class="table-cell table-title-cell">Дата изменения ставок</td>
          <td class="table-cell table-title-cell"></td>
          <td class="table-cell table-title-cell">Удалить</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let r of rates'>
          <app-rate-element-edit [rate]="r" (result)="editElement()"></app-rate-element-edit>
          <td class="table-cell table-cell_ignore" style="text-align: center;">
            <button class="btn btn-clear active" (click)="edit(r)" *ngIf="!r?.isEnableMode">
              <span class="sp sp-pen"></span>
            </button>
            <button class="btn btn-clear" (click)="cancel(r)" *ngIf="r?.isEnableMode">
              <span class="sp icon-cross icon-transparent">
                <span class="icon-cross__left"></span>
                <span class="icon-cross__right"></span>
              </span>
            </button>
          </td>
          <td class="table-cell table-cell_ignore" style="text-align: center;">
            <button class="btn btn-clear active" (click)="remove(r.id)">
              <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="main-block-add-elements">
      <app-rate-element-add (result)="addElement($event)" [coachId]="coachId"></app-rate-element-add>
    </div>
</div>
