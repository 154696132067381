export interface Registry {
  id: string;
  dateFrom: Date;
  dateTo: Date;
  data: string;
  status: RegistryStatus;
  createdAt: Date;
}

export interface RegistryElement {
  id: string
  contact: string;
  fullName: string;
  comment: string;
  date: string;
  amount: number;
  bankName: string;
  bonuses: number;
  city: string;
  club: string;
  clubPaymentGateway: string;
  commission: number;
  registryId: string;
  cityId: string;
  clubId: string;
  createdAt: Date;
  operationId: string;
  status: RegistryElementStatus;

  isEnableMode: boolean;
}

export interface IRegistryParameter {
  dateFrom: Date;
  dateTo: Date;
  isDoubleReport: boolean,
  isEliminateDuplicates: boolean
}

export enum RegistryStatus {
  draft = 'draft',
  created = 'created',
  confirmed = 'confirmed',
  completed = 'completed'
}

export enum RegistryElementStatus {
  published = 'published',
  deleted = 'deleted',
  hold = 'hold'
}

export enum RegistryExportType {
  ExcelLists = 'ExcelLists',
  ExcelFile = 'ExcelFile',
  ExcelZip = 'ExcelZip',
  CSV = 'CSV',
  PaymentOrderFile = 'PaymentOrderFile',
  PaymentOrderZip = 'PaymentOrderZip'
}

export enum Bank {
  AlfaBank = 'AlfaBank',
  Tinkoff = 'Tinkoff',
  PSKB = 'ПСКБ'
}
