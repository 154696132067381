<div class="col-xs-12 col-sm-6 col-md-4">
  <div class="form-group">
    <div class="box-title">Клубы</div>
    <div class="row__field">
      <div>
        <input type="text" placeholder="Поиск" class="form-control input__text input__text--long input-search-block-list" [(ngModel)]="valueFilterClubs" (ngModelChange)="filterClubs()">
        <div class="block-list">
          <div class="block-list-scroll">
            <ul>
              <li *ngFor="let club of clubsFilter" (click)="setClub(club);">{{club.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12 col-sm-6 col-md-4" *ngIf="type != 'clubs'">
  <div class="form-group">
    <div class="box-title" *ngIf="type == 'courses'">Занятия</div>
    <div class="box-title" *ngIf="type == 'subscriptions'">Абонемент</div>
    <div class="row__field">
      <div>
        <input *ngIf="type == 'courses'" type="text" placeholder="Поиск" class="form-control input__text input__text--long input-search-block-list" [(ngModel)]="valueFilterCourses" (ngModelChange)="filterCourses()">
        <input *ngIf="type == 'subscriptions'" type="text" placeholder="Поиск" class="form-control input__text input__text--long input-search-block-list" [(ngModel)]="valueFilterSubscriptions" (ngModelChange)="filterSubscriptions()">
        <div class="block-list">
          <div class="block-list-scroll">
            <ul *ngIf="type == 'courses'">
              <li *ngFor="let cur of coursesFilter" (click)="setCurOrSub(cur);">{{cur.name}}</li>
            </ul>
            <ul *ngIf="type == 'subscriptions'">
              <li *ngFor="let sub of subscriptionsFilter">
                {{sub.name}}
                <ul *ngIf="sub.variants?.length > 0">
                  <li *ngFor="let v of sub.variants" (click)="setCurOrSub({ id: v.id, name: v.name });">{{v.name}}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12 col-sm-6 col-md-4">
  <div class="form-group">
    <div class="box-title">Подборка</div>
    <div class="row__field">
      <div>
        <div class="block-list block-list-result">
          <div class="block-list-scroll">
            <ul cdkDropList (cdkDropListDropped)="drop($event)">
              <li *ngFor="let r of data" cdkDrag>{{r.name}} <span class="sp sp-gallery_close" (click)="remove(r)"></span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
