import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CoachesService } from 'app/components/coaches/services/coaches.service';
import { IPayoutRatesModel, PaymentTypeAlias, PaymentTypeAliasDisplayName } from 'app/common/models/coach-rate.model';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';

@Component({
  selector: 'app-payout-rates',
  templateUrl: './payout-rates.component.html',
  styleUrls: ['./payout-rates.component.scss']
})
export class PayoutRatesComponent implements OnInit {

  public rates: IPayoutRatesModel[] = [];

  @Input() coachId: string;

  constructor(
    protected service: CoachesService,
    private alertsService: AlertsService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.service.getRates(this.coachId).then(async (rates) => {
      this.rates = rates;
    });
  }

  public readonly _PaymentTypeAliases = Object.keys(PaymentTypeAlias);
  public readonly PaymentTypeAliasDisplayName = PaymentTypeAliasDisplayName;

  public async remove(id: string): Promise<void> {
    this.alertsService.showConfirm({
      header: 'Удалить запись о ставках?',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.service.deleteRate(id).then(async (remove) => {
            if (remove) {
              this.rates = this.rates.filter(x => x.id !== id);
            } else {
              console.error(remove);
            }
          });
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public edit(rate: IPayoutRatesModel) {
    rate.salary = rate.salary.toString().replace('&lt;', '<').replace('&gt;', '>');
    rate.isEnableMode = true;
  }

  public cancel(rate: IPayoutRatesModel) {
    rate.salary = rate.salary.replace('<', '&lt;').replace('>', '&gt;');
    rate.isEnableMode = false;
  }

  public addElement(newRate: IPayoutRatesModel){
    console.log(JSON.stringify(newRate))
    this.rates.push(newRate);
  }
}
