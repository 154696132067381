import { Component, OnInit, Injector } from '@angular/core';
import * as _ from 'lodash';
import { IOnValueChanged, onValueChanged } from 'app/common/validators/on-value-changed.class';

import { ILocation } from 'app/common/models/location-model';
import { IClubContacts } from 'app/common/models/club-contacts.model';
import { IClubMetro } from 'app/common/models/club-metro.model';
import { ApiService } from 'app/common/services/api.service';
import { ContextService } from 'app/common/services/context.service';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';
import { debounceTime, startWith, take, filter } from 'rxjs/operators';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent extends ToggleEditComponent<IClubContacts> implements IOnValueChanged, OnInit {
  // TODO: здесь ничего не типизировано.
  public metroList: Array<ILocation> = null;
  public isSubmited: boolean;

  public currMetro = {
    id: null,
    name: null
  };

  fieldMessages = {
    address: {
      required: 'Это поле обязательно',
    },
    phones: {
      required: 'Это поле обязательно',
      phoneValidator: 'Введите корректный номер телефона'
    },
    pathDescription: {
      required: 'Это поле обязательно',
    },
    webSite: {
      required: 'Это поле обязательно',
    },
  };

  public availableMetroStations: ILocation[] = [];

  constructor(
    injector: Injector,
    private apiService: ApiService,
    private contextService: ContextService
  ) {
    super(injector);
  }

  modelTransformOut() {
    const out = _.cloneDeep(this.tempData);
    out.phones = [out.phones.toString()];
    return out;
  }

  getMetroText(item) {
    const text = item.name;
    const dist = item.distance == null || item.distance === 0 ? '' : `- ${item.distance} метров`;
    const time = item.time == null || item.time === 0 ? '' : `(${item.time} минут)`;
    return `${text} ${dist} ${time}`;
  }

  onSave() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    for (const i in this.form.value) {
      this.tempData[i] = this.form.value[i];
    }

    super.onSave();
  }

  removeMetro(index) {
    setTimeout(() => this.tempData.metro.splice(index, 1));
  }

  addMetro(time, distance) {
    if (this.currMetro.id != null) {
      this.tempData.metro.push(
        {
          name: this.currMetro.name,
          id: this.currMetro.id,
          distance: distance.value || null, // Расстояние в метрах (не обязательно)
          time: time.value || null // Расстояние в минутах пешком (не обязательно)
        }
      );

      time.value = '';
      distance.value = '';
    }
    this.updateAvailableStations();
    if (this.availableMetroStations.length > 0) {
      this.currMetro = this.availableMetroStations[0];
    } else {
      this.currMetro = {
        id: null,
        name: null
      };
    }
  }

  updateAvailableStations() {
    const assignedItems = (<Array<IClubMetro>>this.tempData.metro)
      .map(item => { return item.id; });

    this.availableMetroStations = this.metroList
      .filter(item => !assignedItems.includes(item.id));
  }

  isSelected(metro, item) {
    return metro.id === item.id;
  }

  onCancel() {
    super.onCancel();
    this.isSubmited = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.form = this.formBuilder.group({
      address: [this.tempData.address, []],
      phones: [this.tempData.phones.toString(), [ /*phoneValidator()*/]],
      pathDescription: [this.tempData.pathDescription, []],
      webSite: [this.tempData.webSite, []]
    });

    this.contextService.routingParams.pipe(
      filter(Boolean),
      debounceTime(100),
      take(1))
      .subscribe(params => {
        this.apiService.locations
          .getList(params)
          .then(res => {
            this.metroList = res;
            if (res.length > 0) {
              this.updateAvailableStations();
              this.currMetro = this.availableMetroStations[0];
            }
          });
      })

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }
}
