<ng-container *ngIf="data != null">

  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Цена</b></span>
    <input type="text" class="input__text input__text--long" [(ngModel)]="data.price" (change)="onChangeHandler($event)" [appRestrictInput]="{ min:0, max:100000000 }" placeholder="0,00">
  </td>

  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Название</b></span>
    <input type="text" class="input__text input__text--long" [(ngModel)]="data.title" (change)="onChangeHandler($event)">
  </td>

  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Описание</b></span>
    <input type="text" class="input__text input__text--long" [(ngModel)]="data.label" (change)="onChangeHandler($event)">
  </td>

  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Удалить</b></span>
    <button class="button-delete" (click)="onDeleteHandler()"></button>
  </td>

</ng-container>
