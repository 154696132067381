import { Component, OnInit, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { ChatService } from 'app/components/chats/chats.service';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { IChat } from 'app/common/models/chat.models';

@Component({
    selector: 'app-chat-mailing',
    templateUrl: './chat-mailing.component.html',
    styleUrls: ['./chat-mailing.component.scss']
})
export class ChatMailingComponent extends TableListComponent<IChat> implements OnInit {
  public static readonly componentName: string = 'ChatMailingComponent';
  
    constructor(
        injector: Injector,
        protected location: Location,
        protected service: ChatService
    ) {
        super(injector, service);
        this.contextService.routingParams.subscribe(params => {
          this.isInClubContext = params.isInClubContext;
          this.routeTemplate = this.isInClubContext
            ? this.route.routeConfig.path
              .replace(':cityId', this.routingParams.cityId)
              .replace(':clubNetId', this.routingParams.clubNetId)
              .replace(':clubId', this.routingParams.clubId)
            : this.route.routeConfig.path
              .replace(':cityId', this.routingParams.cityId);
          this.routeTemplate = this.routeTemplate.replace('/mailing/:id', '');
        });
    }

    public isInClubContext: boolean;
    public title: string;
    private routeTemplate: string;

    private readonly pageSize = 50;
    public pageIndex: number = 0;
    public totalItemCount: number;

    ngOnInit() {
      super.ngOnInit();
      this.service.getSingleItem(this.routingParams)
        .then(val => this.title = val.chat.title);
        console.log(this.routeTemplate);
    }

    onEdit() {
      let route = `${this.routeTemplate}/edit/${this.routingParams.id}`;
      this.router.navigateByUrl(route);
    }

    onRead() {
      let route = `${this.routeTemplate}/id/${this.routingParams.id}`;
      console.log(this.routeTemplate, route);
      this.router.navigateByUrl(route);
    }

    onChatClick(event: IChat) {
      let route = `${this.routeTemplate}/id/${event.id}`;
      this.router.navigateByUrl(route);
    }

    updateModel() {
      return this.service.getRelatedChatList(this.isInClubContext && this.currentCity, this.routingParams.id
        , this.pageIndex, this.pageSize)
        .then(res => {
          if (this.pageIndex)
            this.Model = (this.Model as IChat[]).concat(res.items);
          else
            this.Model = res.items;
          this.totalItemCount = res.pagingInfo.totalItemCount;
          this.onModelLoadCallback();
        });
    }

    public showNextPage() {
      this.pageIndex += 1;
      this.updateModel();
    }

    public close() {
      if (!this.persistenceService.goBack()) {
        this.location.back();
      }
    }
}
