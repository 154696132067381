<div class="text-edit-wr">
    <div class="title-wrap">
        <div class="box-title">
            {{title}}
            <span class="box-edit" (click)="onBeginEdit()" *ngIf="!isReadonly">
              <span class="sp sp-download"></span>
            </span>
        </div>
        <div class="clue-text">
            Загрузите файл в формате CSV.
            Данные должны быть представлены в виде таблицы, обязательный столбец «Имя» должен содержать имя и фамилию, указанные через пробел.
            Остальные данные содержатся в столбцах «Телефон», «Email», «Пол», «Комментарий».
        </div>
        <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
            <span class="row__save_save" (click)="onSave()">
              <span class="icon-check icon-check_color">
                  <span class="icon-check__left"></span>
                  <span class="icon-check__right"></span>
              </span>
              <span class="title">Импортировать</span>
            </span>
            <span class="row__save_cancel" (click)="onCancel()">
              <span class="icon-cross icon-cross_color">
                  <span class="icon-cross__left"></span>
                  <span class="icon-cross__right"></span>
              </span>
              <span class="title">Отменить</span>
            </span>
        </div>
    </div>

    <div class="row__field" [hidden]="!enableEdit">
        <div class="field wysiwyg">
            <span *ngFor="let file of tempData" class="dark-gray">{{file.name}}</span>
        </div>
        <input type="file" class="fileInput" (change)="onSelectFile($event)" style="display:none" />
    </div>
</div>
