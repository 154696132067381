import { RegistryStatus } from './../../../common/models/registry.model';
import {Component, OnInit } from '@angular/core';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import {ICoachRegistryModel} from 'app/common/models/coach-registry.model';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-coach-salary-calc',
  templateUrl: './coach-salary-calc.component.html',
  styleUrls: ['./coach-salary-calc.component.scss'],
  providers: [CoachRegistryService]
})

export class CoachSalaryCalcComponent implements OnInit{
  public static readonly componentName: string = 'CoachSalaryCalcComponent';

  public registries: ICoachRegistryModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected service: CoachRegistryService,
    private alertsService: AlertsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.service.getRegistries().then(async (registries) => {
      this.registries = registries
    });

    let drf = this.registries.filter(it => it.status.toLowerCase() === RegistryStatus.draft)[0]
    let arr = this.registries.sort((a,b) => this.srt(a,b))

    if (drf !== undefined)
    {
      this.registries.splice(this.registries.indexOf(drf), 1)
      this.registries.unshift(drf)
    }
  }

  public srt(a: ICoachRegistryModel, b: ICoachRegistryModel)
  {
    if (a.dateFrom > b.dateFrom)
      {
        return -1;
      }
    else if (a.dateFrom < b.dateFrom)
      {
        return 1;
      }
    else
    {
      if (a.dateTo > b.dateTo)
      {
        return -1;
      }
    else if (a.dateTo < b.dateTo)
      {
        return 1;
      }
    }
    /*
    if (a.dateTo > b.dateTo)
      {
        return -1;
      }
    else if (a.dateTo < b.dateTo)
      {
        return 1;
      }
    else
    {
      if (a.dateFrom > b.dateFrom)
      {
        return -1;
      }
    else if (a.dateFrom < b.dateFrom)
      {
        return 1;
      }
    }
    */
    return 0;
  }

  public create(): void {
    this.router.navigate([`create`], { relativeTo: this.route });
  }

  public async remove(id: string): Promise<void> {
    this.alertsService.showConfirm({
      header: 'Удалить реестр?',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          console.log(JSON.stringify(this.registries))

          this.service.delete(id).then(async (remove) => {
            if (remove) {
              this.registries = this.registries.filter(x => x.id !== id);
            } else {
              console.error(remove);
            }
          });

        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public onEditRegistry(registry: ICoachRegistryModel) {
    this.router.navigate(['edit'], {relativeTo: this.route, queryParams: { id: registry.id }});
  }

}
