import { AlertsService } from './../../../common/components/alerts/services/alerts.service';
import { IRoutingParams } from './../../../common/models/context.model';
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/common/services/api.service';
import { ContextService } from 'app/common/services/context.service';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { CoursesService } from 'app/components/courses/services/courses.service';
import * as moment from 'moment';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { ScheduleService } from 'app/components/schedule/services/schedule.service';
import { AccountStatus } from 'app/common/models/account-status.model';

@Component({
  selector: 'app-add-client-to-class',
  templateUrl: './add-client-to-class.component.html',
})
export class AddClientToClassComponent implements OnInit {
  public static readonly componentName: string = 'AddClientToClassComponent';

  public clientSearchList: any;
  public selectedCourse: any;
  public courses: any[];
  public client: any;
  public classes: any;
  public dates: string[];
  public times: any;
  public selectedDate: any;
  protected hotkeysService: HotkeysService;
  protected hkClose: Hotkey | Hotkey[];

  /* Доп. свойства для редактирования клиента */
  public isEdit: boolean = false;
  public context: IRoutingParams;
  public subscriptionId: string;
  public guidEmpty = '00000000000000000000000000000000';


  @ViewChild('searchBox') searchBox;

  constructor(
    private clientsService: ClientsService,
    private apiService: ApiService,
    private contextService: ContextService,
    public router: Router,
    public _location: Location,
    public injector: Injector,
    public route: ActivatedRoute,
    private coursesService: CoursesService,
    private apiV2Service: ApiV2Service,
    protected service: ScheduleService,
    private alertsService: AlertsService
  ) {
    this.hotkeysService = injector.get(HotkeysService);
    this.hkClose = this.hotkeysService.add(new Hotkey('esc', this.hkClosePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
  }

  ngOnInit() {
    this.coursesService.getItemList()
      .then(res => this.courses = res);

    this.getParams();
  }

  getParams() {
    this.route.queryParams.subscribe(params => {
      const id = params['requestId'];
      this.subscriptionId = params['subscriptionId'];

      if (id) {
        console.warn(`Request ID: ${id}`);
        this.isEdit = true;
        this.route.params.subscribe(params => {
          this.context = params;
          this.apiV2Service.classRequest.getByClub(this.context.clubId, false)
            .then(requests => {
              console.log(requests)
              this.client = requests.find(m => m.id == id);
              this.searchBox.nativeElement.value = this.client.firstName + ' ' + this.client.lastName;
              this.getSubscriptions();
            })
        })
      };
    });
  }

  getContext() {
    this.route.params.subscribe(params => {
      this.context = params;
      console.log(this.context)
    })
  }

  searchClients(pattern) {
    this.client = null;

    if (pattern.length < 2) {
      this.clientSearchList = [];
      return;
    } else {
      this.clientsService.searchClients(pattern)
        .then(res => {
          this.clientSearchList = [...res.clients, ...res.users];
        });
    }
  }

  choseClient(client) {
    this.searchBox.nativeElement.value = client.firstName + ' ' + client.lastName;
    this.client = client;
  }

  createClient() {
    let newClientName: string | string[] = this.searchBox.nativeElement.value as string;
    newClientName = newClientName
      .split(' ')
      .filter(Boolean);
    const newClient = {
      id: null,
      userId: null,
      firstName: newClientName[0],
      lastName: newClientName[1] ? newClientName[1] : null,
      fullName: null,
      gender: null,
      email: null,
      phone: null,
      birthday: null,
      entityStatus: EntityStatus.published,
      description: null,
      clubId: null,
      spUserId: null,
      primaryPhotoUrl: null,
      updatedAt: null,
      clubRegisteredAt: null,
      systemRegisteredAt: null,
      source: null,
      accountStatus: AccountStatus.created
    };
    this.clientsService
      .save(newClient)
      .then(res => {
        if (res.userId) {
          this.client = { userId: res.userId };
        } else {
          this.clientsService.searchClients(newClient.firstName)
            .then(response => {
              const client = response.clients[response.clients.findIndex(item => item.id === res.id)];
              this.client = client;
            });
        }
      });
  }

  courseSelect(id: string) {
    this.selectedCourse = this.courses.filter(item => item.id === id)[0];
    this.classes = null;
    if (!this.selectedCourse.isVoluntaryBookingAllowed) {
      this.getClasses();
    }
  }

  getClasses() {
    const date = new Date();
    const dateFrom = date.toLocaleDateString('ru');
    const dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7).toLocaleDateString('ru');
    const context = this.contextService.getRoutingParams();
    this.apiService.schedule.getCourseList(context, this.selectedCourse.id, dateFrom, dateTo)
      .then(res => {
        this.classes = res.classes.filter(item => {
          const [day, month, year] = item.startDate.split('.');
          const [hour, minute] = item.startTime.split(':');
          const timestamp = new Date(year, month - 1, day, hour, minute).getTime();
          return timestamp >= new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000);
        }).slice(0, 10);
      });
  }

  changeDate(index) {
    this.selectedDate = this.classes[index];
  }

  async bookingRegular() {
    const context = this.contextService.getRoutingParams();
    const model = {
      userId: this.client.userId || this.client.userInfo.userId,
      classId: this.selectedDate.id === this.guidEmpty ? null : this.selectedDate.id,
      prototypeId: this.selectedDate.id === this.guidEmpty ? this.selectedDate.prototypeId : null,
      startDateTime: Date.parse(this.selectedDate.startDate.split('.').reverse().join('-') + ' ' + this.selectedDate.startTime)
    };

    if (this.isEdit && this.isMaxCountVisits(this.subscriptionId) && this.subscriptionId != this.guidEmpty) {
      this.alertsService.alert.next({
        type: 'danger',
        message: 'Достигнут предел по количеству бронирований по абонементу.',
        header: 'Произошла ошибка',
        position: 'top',
        timeout: 2000
      });
      return;
    }

    if (!this.isEdit) this.apiService.schedule.book(context, model).then(() => { this.goBack() });

    // Если перешли на страницу, нажав "Не выбрано" в заявке на рабочем столе.
    if (this.isEdit) {
      // Создаем запись на занятие.
      this.apiService.schedule.book(context, model)
        .then((book: any) => {

          // Удаляем заявку в которой занятие "Не выбрано".
          // С этой заявки, перешли на страницу редактирования.
          this.apiV2Service.classRequest.delete(this.client.id, false, false).then();

          // Если есть абонемент, то устанавливаем его для ClassVisit.
          if (this.subscriptionId != this.guidEmpty) {
            this.service
              .changeSubscriptionPlan({
                classVisitId: book.id,
                clientSubscriptionId: this.subscriptionId
              })
              .then(() => {
                // Сразу подтверждаем заявку
                this.apiService.schedule.confirmVisited(context, book).then(() => {
                  // Получаем все заявки, чтобы найти заявку с classVisitId == book.id
                  this.apiV2Service.classRequest.getByClub(this.context.clubId, false).then(reqs => {
                    const reqId = reqs.find(m => m.classVisitId == book.id)?.id;
                    if (reqId) {
                      // Удаляем новую заявку с рабочего стола. т.к. она уже подтверждена клубом
                      this.apiV2Service.classRequest.delete(reqId, false, false);
                    }
                    this.goBack();
                  });
                });
              })
              .catch(() => {
                // Когда не подошел абонемент, а заявка уже создалась.
                this.alertsService.alert.next({
                  type: 'warning',
                  message: 'Заявка создана без абонемента! Для выбора абонемента, перейдите в занятие и заполните его вручную.',
                  header: 'Неподходящий абонемент',
                  position: 'top',
                  timeout: 10000
                });
                // Получаем все заявки, чтобы найти заявку с classVisitId == book.id
                this.apiV2Service.classRequest.getByClub(this.context.clubId, false).then(reqs => {
                  const reqId = reqs.find(m => m.classVisitId == book.id)?.id;
                  if (reqId) {
                    // Удаляем новую заявку с рабочего стола. т.к. она уже подтверждена клубом
                    this.apiV2Service.classRequest.delete(reqId, false, false);
                  }
                  this.goBack();
                });
              });
          } else {
            this.apiService.schedule.confirmVisited(context, book).then(() => {
              // Получаем все заявки, чтобы найти заявку с classVisitId == book.id
              this.apiV2Service.classRequest.getByClub(this.context.clubId, false).then(reqs => {
                const reqId = reqs.find(m => m.classVisitId == book.id)?.id;
                if (reqId) {
                  // Удаляем новую заявку с рабочего стола. т.к. она уже подтверждена клубом
                  this.apiV2Service.classRequest.delete(reqId, false, false);
                }
              });
              this.goBack();
            });
          }
        });
    }
  }

  isMaxCountVisits(id: string) {
    const sub = this.client.subscriptions.find(m => m.id == id);
    return sub.visitCounter == sub.visitCount ? true : false;
  }

  continue() {
    if (this.selectedCourse.isVoluntaryBookingAllowed) {
      const date = moment().format('DD.MM.YYYY');
      this.router.navigate(
        [`../clubschedule/${date}/create/${new Date().toLocaleDateString('ru')}/00.00`],
        { relativeTo: this.route, queryParams: { userId: this.client.userId, courseId: this.selectedCourse.id } }
      );
    }
  }

  public hkClosePress(that: any) {
    return (event: KeyboardEvent) => {
      event.stopPropagation();
      that._location.back();
      return true;
    };
  }

  private getSubscriptions() {
    let context: any = JSON.parse(JSON.stringify(this.context));
    context.id = this.client.userInfo.clientId;

    this.apiService.clients.getClientAbonements(context).then(
      subs => {
        this.client.subscriptions = subs.clientSubscriptions.filter(m => m.visitCounter < m.visitCount);
        this.client.subscriptions.unshift({ id: this.guidEmpty, name: 'Без абонемента' });
      });
  }

  infinity(num: number) {
    return num > 1000 ? "∞" : num;
  }

  goBack() {
    this._location.back();
  }
}
