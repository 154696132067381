import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { ContextService } from './context.service';
import { ApiV2Service } from './api-v2.service';
import { IImageParams } from '../models/image.model';

@Injectable()
export class MediaService {

  constructor(
    private apiV2Service: ApiV2Service,
    private contextService: ContextService,
  ) {
    this.contextService.pendingUrl.subscribe(url => this._pendingUrl = url);
  }

  get(id: string): Promise<IImageParams> {
    if (id === "0640740d2eb34ef1baaaf5bf42c8a5a4")
      return new Promise(resolve => resolve({
        id: "ea440d349f034dff9882e1de4c283a06",
        name: "test",
        url: "https://media.sportpriority.com/img/0/ea440d349f034dff9882e1de4c283a06",
        baseUrl: "https://media.sportpriority.com/",
        width: 1085,
        height: 821,
        centerX: 300,
        centerY: 700,
      }));
    return this.apiV2Service.image.get(id);
  }

  center(id: string, centerX?: number, centerY?: number): Promise<IImageParams> {
    return this.apiV2Service.image.center(id, centerX, centerY);
  }

  private _pendingUrl: UrlSegment[];
  get pendingUrl(): UrlSegment[] {
      return this._pendingUrl;
  }

  private _redirectUrl: string;
  get redirectUrl(): string {
      return this._redirectUrl;
  }

  public saveRedirectUrl() {
    this._redirectUrl = this.pendingUrl.reduce((x, y) => x + `/${y}`, '');
  }

  save(body: string): Promise<IImageParams>{
    return this.apiV2Service.image.save(body);
  }

  addToClient(clientId: string, imageId: string): Promise<IImageParams>{
    return this.apiV2Service.image.addToClient(clientId, imageId);
  }
}
