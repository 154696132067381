import {Component, ElementRef, HostListener, ViewChild, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from './common/services/auth.service';
import { ContextService } from 'app/common/services/context.service';
import { HotkeysService } from 'custom-modules/angular2-hotkeys';

import { Location } from '@angular/common';
import {Router, NavigationStart, Event as NavigationEvent, ActivatedRoute, NavigationEnd} from '@angular/router';

import { BrowserStateService } from './common/services/browser-state.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ClubSettingService} from './components/club-settings/club-settings.service';
import {ClubSetting} from './common/models/club-setting.model';
import {Title} from '@angular/platform-browser';
import {ApiService} from './common/services/api.service';
import {RoutingHistoryService} from './common/services/routing-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject();
  @ViewChild('asideBtn') asideBtn: ElementRef;
  public isOpen = false;
  public isResize = false;
  public isLogin = false;
  public isMobile: boolean;
  public isFeedbackVisible = true;
  public componentClass: string;
  public event$;
  public isThemePartner = false;
  public settings: ClubSetting[] = [];
  private club: any = null;

  constructor(
    public authService: AuthService,
    public contextService: ContextService,
    public hotkeysService: HotkeysService,
    public _location: Location,
    private router: Router,
    public routingHistoryService: RoutingHistoryService,
    private route: ActivatedRoute,
    public browserStateService: BrowserStateService,
    public settingsService: ClubSettingService,
    private titleService: Title,
    private api: ApiService
  ) {
    this.isMobile = this.browserStateService.isMobile();
    this.contextService.currentUrl.subscribe(url => {
      const elements = document.getElementsByClassName('app_container');
      if (elements.length > 0) {
        elements[0].scrollTop = 0;
      }
      this.isFeedbackVisible = false; // !( url && url.length > 1 && url[ 0 ].path === chatsRoute.path );
      this.componentClass = undefined;
      if (url && url.length) {
        // this.isWrapped = url.length > 1 && url[1].path === 'chats' || url.length > 3 && url[3].path === 'chats';
        if (url.length > 1 && url[1].path === 'chats' || url.length > 3 && url[3].path === 'chats')
          this.componentClass = "wrapped";
        if (url.length > 2 && url[url.length - 2].path === 'image')
          this.componentClass = "component_image";
      }
    });

    this.contextService.routingParams.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(async resp => {
        if (resp?.clubId) {
          let title = this.titleService.getTitle();
          this.club = await this.api.clubs.getItem(resp).then();
          if (resp?.clubId) {
            title += ' - ' + this.club.name;
            if (this.club?.clubNet) { title += ' - ' + this.club.clubNet.name; }
          }
          this.titleService.setTitle(title);
        }
      });

    this.contextService.routingParams.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(async resp => {
        if (resp?.clubId) {
          this.settings = await this.settingsService.getClubSettingsByClub(resp.clubId);
          this.isThemePartner = JSON.parse(this.settings.filter(x => x.alias === 'AvailableForPartner')[0].value);
        } else { this.settings = []; this.isThemePartner = false; }
      });

    this.event$ = this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if(event instanceof NavigationStart) {
            event.url === '/login' || event.url === '/restore-password' || event.url === '/reset-password' ? this.isLogin = true : this.isLogin = false;
          }
        });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      let navigations = this.routingHistoryService.navigations || 0;
      navigations++;
      this.routingHistoryService.navigations = navigations;
    });
  }

  async ngOnInit(): Promise<any> {
    // Временное решение по очистке кэша
    const keys = await window.caches.keys();
    await Promise.all(keys.map(key => caches.delete(key)));
    // END Временное решение по очистке кэша
  }

  @HostListener('window:keydown', ['$event'])
  onKeydownHandler(e) {
    // backspace
    if (true ||
      // isPaused() - проверка, что сейчас ничего не грузится.
      // неизвестно, нужно ли это, так что пока отключено, но пусть в коде останется.
      !this.hotkeysService.isPaused()
    ) {
      if (e.keyCode === 8 && (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA')) {
        this._location.back();
      }
    }
  }

  @HostListener("window:resize") onResize() {
    this.isResize = true;
    setTimeout(() => this.isResize = false, 300);
  }

  ngOnDestroy() {
    this.event$.unsubscribe();
  }

  showNav(event) {
    if (document.querySelector('.table-responsive .' + event.target.className) != null) {
      return;
    }

    if (window.matchMedia("(max-width: 991px)").matches) {
      this.isOpen = !this.isOpen;
    }
  }
}
