import {Injectable, Injector} from '@angular/core';
import { IRoutingParams } from 'app/common/models/context.model';
import {ICoachRegistryModel, ICoachRegistryParameter, CoachAccrual, CoachPayoutParameter} from 'app/common/models/coach-registry.model';
import {TableListService} from 'app/common/table-list/table-list.service';
// import {adminAnalyticsPath, adminReportPath} from 'app/routing/routes/_constants';

@Injectable()
export class CoachRegistryService extends TableListService<ICoachRegistryModel> {

  public context: IRoutingParams;
  constructor(injector: Injector) {
    super(injector);
    this.context = this.contextService.getRoutingParams();
  }

  getItemList() {
    return this.apiService.coachRegistries.getRegistries( this.contextService.getRoutingParams());
  }

  public getRegistries () {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.getRegistries( this.context );
  }

  getSingleItem(req: IRoutingParams) {
    console.log(req)
    return null;
  }

  save(model: ICoachRegistryModel) {
    return this.apiService.coachRegistries.setStatus(this.contextService.getRoutingParams(), model.id, model.status);
  }

  create(model: ICoachRegistryModel): Promise<any> {
    console.log(model)
    return null
  }

  getRegistryById(id: string) {
    const context = this.contextService.getRoutingParams();
    context.id = id;
    return this.apiService.coachRegistries.getRegistryById(context);
  }

  createRegistry(req: ICoachRegistryParameter): Promise<any> {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.addRegistry(this.context, req)
  }

  delete(registryId: string): Promise<any> {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.removeRegistry( this.context, registryId );
  }

  getAccrualsByRegistryId(registryId: string) {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.getAccrualsByRegistryId( this.context, registryId );
  }

  saveAccrual(model: CoachAccrual) {
    return this.apiService.coachRegistries.saveAccrual(this.contextService.getRoutingParams(), model);
  }

  saveAllAccruals(models: any[])
  {
    return this.apiService.coachRegistries.saveAllAccruals(this.contextService.getRoutingParams(), models);
  }

  addAccrual(model: any) {
    return this.apiService.coachRegistries.addAccrual(this.contextService.getRoutingParams(), model);
  }

  deleteAccrual(accrualId: string): Promise<any> {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.removeAccrualElement(this.context, accrualId);
  }

  deleteAccrualsByCoachId(registryId: string, coachId: string): Promise<any> {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.removeAccrualElements(this.context, registryId, coachId);
  }

  getPayoutsByRegistryId(registryId: string) {
    this.context = this.contextService.getRoutingParams();
    return this.apiService.coachRegistries.getPayoutsByRegistryId( this.context, registryId );
  }

  addPayout(model: CoachPayoutParameter) {
    return this.apiService.coachRegistries.addPayout(this.contextService.getRoutingParams(), model);
  }

  deletePayout(payoutId: string) {
    return this.apiService.coachRegistries.removePayout(this.contextService.getRoutingParams(), payoutId);
  }
}
