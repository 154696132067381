import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {Collections, CollectionsTypes, ViewTableCollections} from '../../common/models/collections.model';
import {ICityModel} from '../../common/models/city-model';
import {CityService} from '../../common/services/city.service';
import {AlertsService} from '../../common/components/alerts/services/alerts.service';
import {EntityStatus} from '../../common/models/entity-status.model';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {

  protected ngUnsubscribe: Subject<void> = new Subject();
  public collections: Collections[] = [];
  public allViewTableCollections: ViewTableCollections[] = [];
  public viewTableCollections: ViewTableCollections[] = [];
  public cities: Array<ICityModel> = [];
  public currentCity: ICityModel = null;
  public newSortIndex: any[] = [];
  public partnerListCollections: any[] = [];
  public readonly SportPriority = 'Sport Priority';
  public selectedPartner: any = this.SportPriority;
  constructor(
    private api: ApiV2Service,
    private alertsService: AlertsService,
    private cityService: CityService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<any> {
    await this.cityService.getItemList().then(async cities => {
      this.cities = cities;
      this.cityService.currentCity.pipe(
        takeUntil(this.ngUnsubscribe),
        filter(Boolean))
        .subscribe(async city => {
          this.currentCity = city as ICityModel;
          await this.api.collections.getByCityId(this.currentCity.id).then(collections => {
            this.collections = collections;
            this.viewTableCollections = [];
            this.partnerListCollections = [];
            this.collections.forEach(item => {
              if (!item.partnerAlias) {
                if (!this.partnerListCollections.find(x => x === this.SportPriority)) {
                  this.partnerListCollections.push(this.SportPriority);
                }
              } else {
                if (!this.partnerListCollections.find(x => x === item.partnerAlias)) {
                  this.partnerListCollections.push(item.partnerAlias);
                }
              }
              this.viewTableCollections.push({
                id: item.id,
                partnerAlias: !item.partnerAlias ? this.SportPriority : item.partnerAlias,
                cityName: this.getNameCity(item.cityId),
                entityStatus: item.entityStatus,
                type: item.type,
                name: item.name,
                countData: (item.type !== CollectionsTypes.conditionSearch && item.type !== CollectionsTypes.conditionImages && item.type !== CollectionsTypes.conditionButtons ? JSON.parse(item.data).length : null)
              })
            })
            this.allViewTableCollections = this.viewTableCollections;
            this.viewTableCollections = this.viewTableCollections.filter(x => x.partnerAlias === this.selectedPartner);
          });
        })
    });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getNameCity(cityId: string) {
    if (cityId != null)
      return this.cities.filter(x => x.id === cityId)[0].name
    return null;
  }

  public async remove(viewTableCollection: ViewTableCollections): Promise<void> {
    const collection = this.collections.filter(x => x.id === viewTableCollection.id)[0];
    this.alertsService.showConfirm({
      header: 'Удалить подборку?',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          collection.entityStatus = EntityStatus.deleted;
          this.api.collections.save(collection).then(() => {
            this.collections = this.collections.filter(x => x.id !== collection.id);
            this.viewTableCollections = this.viewTableCollections.filter(x => x.id !== collection.id);
            this.allViewTableCollections = this.allViewTableCollections.filter(x => x.id !== collection.id);
            if (this.viewTableCollections.length === 0) {
              this.partnerListCollections = this.partnerListCollections.filter(x => x !== this.selectedPartner);
              this.selectedPartner = this.SportPriority;
              this.filterCollectionByPartner();
            }
          });
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  create() {
    this.router.navigate([`create`], { relativeTo: this.route });
  }

  edit(collection: ViewTableCollections) {
    this.router.navigate([`${collection.id}`], { relativeTo: this.route });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.viewTableCollections, event.previousIndex, event.currentIndex);
    this.newSortIndex = [];
    this.viewTableCollections.forEach((item, index) => {
      this.newSortIndex.push({ id: item.id, index: index })
      this.allViewTableCollections = this.allViewTableCollections.filter(x => x.id !== item.id);
      this.allViewTableCollections.push(item);
    });
  }

  saveSort() {
    if (this.newSortIndex.length > 0) {
      this.api.collections.saveSorting(this.newSortIndex).then((resp: any) => {
        if (resp) {
          this.newSortIndex = [];
          this.alertsService.alert.next({
            type: 'success',
            message: 'Подборки сохранены',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        }
      });
    }
  }

  filterCollectionByPartner() {
    this.viewTableCollections = this.allViewTableCollections;
    this.viewTableCollections = this.viewTableCollections.filter(x => x.partnerAlias === this.selectedPartner);
  }
}
