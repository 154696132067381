import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import {FormControl} from '@angular/forms';
import {FilterItem} from '../../../../shared-components/dropdown/dropdown.component';
let idx = 1;

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-variant-edit]',
  templateUrl: './variant-edit.component.html',
  styleUrls: ['./variant-edit.component.scss']
})

export class VariantEditComponent implements OnInit {

  @Input() VisiblePriceOnSaleCampain: boolean;

  public duration;
  public i = idx++;
  @Input() data;
  @Input() isResource: boolean;
  @Output() onDelete = new EventEmitter();

  toppings = new FormControl();
  toppingList: FilterItem[] = [
    {
      id: '1',
      name: 'Test1',
      isEnabled: false
    },
    {
      id: '2',
      name: 'Test2',
      isEnabled: false
    },
    {
      id: '3',
      name: 'Test3',
      isEnabled: false
    },
    {
      id: '4',
      name: 'Test4',
      isEnabled: false
    },
    {
      id: '5',
      name: 'Test5',
      isEnabled: false
    },
    {
      id: '6',
      name: 'Test6',
      isEnabled: false
    },
  ];

  constructor(public alertsService: AlertsService) { }

  onChange(event) {
    const state = event.target.checked;
    if (state) {
      this.data.visitCount += 10000;
    } else {
      this.data.visitCount -= 10000;
      if (this.data.visitCount < 1) {
        this.data.visitCount = 1;
      }
    }
  }

  onDeleteClick() {
    this.onDelete.emit();
  }

  ngOnInit() {
    this.duration = this.splitDuration();
  }

  public splitDuration() {
    return {
      duration: this.data.duration,
      durationUnit: this.data.durationUnit
    };
  }

  public setDuration(event) {
    this.data.duration = event.duration;
    this.data.durationUnit = event.durationUnit;
  }
}
