import { Component, OnInit } from '@angular/core';
import { ContextService } from 'app/common/services/context.service';
import { ApiService } from 'app/common/services/api.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { CityService } from 'app/common/services/city.service';
import { ICityModel } from 'app/common/models/city-model';
import { filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  public ctx: IRoutingParams;
  public code: string;
  public id: string;
  public theme = 'purple';
  public roomList: IClubroomModel[];
  public room: string;
  public sourceUrl: string;
  public isBlock = true;

  public vkInstallLink: string;

  constructor(
    public contextService: ContextService,
    public api: ApiService,
    public cityService: CityService
  ) { }

  ngOnInit() {
    this.sourceUrl = 'https://wj.sportpriority.com/assets/widget.js?v=2';
    this.id = btoa((Math.random() * 100).toString()).slice(0, 10).toLowerCase();
    this.ctx = this.contextService.getRoutingParams();
    this.api.rooms.getList(this.ctx)
      .then(res => this.roomList = res);
    this.setCode();
    this.cityService.currentCity.pipe(
      filter<ICityModel>(Boolean),
      take(1))
      .subscribe(city =>
        this.vkInstallLink = `https://vk.com/add_community_app?aid=6277910&callback_url=https://api.sportpriority.com/v1/cities/${city.id}/nets/${this.ctx.clubNetId}/clubs/${this.ctx.clubId}/install-vk`
      )
  }

  setCode(): void {
    // TODO: зал должен фильтроваться не по имени, а по id. иначе на кириллических названиях падает, и вообще это криво.
    const isBlock = this.isBlock ? `isBlock:true,` : '';
    const theme = this.theme ? `theme:'${this.theme}',` : '';
    const room = this.room ? `room:'${this.room}',` : '';
    const scheduleId = `scheduleId:'${btoa(JSON.stringify(this.ctx))}'`;
    this.code = `<script>window.scheduleApp={${isBlock}${theme}${room}${scheduleId}, id:'${this.id}'};window.onload=function(){var a=document.createElement('script');a.type='text/javascript';a.async=!0;a.src='${this.sourceUrl}';document.head.appendChild(a);};</script>\n<div id="${this.id}"></div>`;
  }

  selectTheme(newTheme: string): void {
    this.theme = newTheme;
    this.setCode();
  }

  selectRoom(newRoom: string): void {
    this.room = newRoom;
    this.setCode();
  }

  changeBlockState(state: boolean) {
    this.isBlock = state;
    this.setCode();
  }

  copyCode(): void {
    const textarea: any = document.querySelector('#scheduleCode');
    window.getSelection();
    textarea.select();

    try {
      document.execCommand('copy');
    } catch { }

    window.getSelection().removeAllRanges();
  }

}
