<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Группы
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="groups-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (contextmenu)="onEditStart(null,$event)" (click)="onEditStart(null, $event)">Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <!-- <td class="table-cell table-title-cell">Активно</td> -->
            </tr>
          </thead>
          <tbody *ngIf="(Model && Model.length)">
            <tr *ngFor='let group of Model' (contextmenu)="onEditStart(group,$event)" (click)="onEditStart(group, $event)">
              <td class="table-cell">
                <div class="field desc">
                  <!-- <span class="color" [style.background]="coach.color"></span> -->
                  <span><b>{{ group.name }} ({{group.countClient}})</b></span>
                </div>
              </td>
              <!-- TODO: Доработать
                <td class="table-cell table-cell_publish" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
                <app-status-picker [title]="null" [ngModel]="group.entityStatus" (ngModelChange)="setStatus($event, group)"></app-status-picker>
              </td> -->
            </tr>
          </tbody>
          <tbody *ngIf="!(Model && Model.length)">
            <tr>
              <td colspan="5" class="table-cell">Групп пока что нет</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

</div>
