import { Injectable } from '@angular/core';

@Injectable()
export class CsvService {
  public downloadFile(data, filename = 'data', short = false): any {
    let csvData = this.convertToCSV(data, ['Контакт', 'ФИО', 'Коментарий', 'Дата', 'Сумма', 'Банк', 'Бонусы', 'Город', 'Клуб', 'Комиссия']);
    if (short) {
      csvData = this.convertToCSV(data, ['Контакт', 'ФИО', 'Коментарий', 'Дата', 'Сумма', 'Банк', 'Комиссия']);
    }
    const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // если Safari откроется новое окне, чтобы сохранить файл со случайным именем.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  private convertToCSV(objArray, headerList): any {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    console.log(array)
    let str = '';
    let row = '№;';
    for (const index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (const index in headerList) {
        const head = headerList[index];
        line += ';' + array[i][head];
      }
      str += line + '\r\n';
    }
    console.log(str)
    return str;
  }
}
