import { Component, OnInit, Input } from '@angular/core';
import { TipsService } from 'app/common/services/tips.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-key-help]',
  templateUrl: './key-help.component.html',
  styleUrls: ['./key-help.component.scss']
})
export class KeyHelpComponent implements OnInit {

  @Input() key;
  public text = '';

  ngOnInit() {
    this.text = this.service.getStringByKey(this.key);
  }

  constructor(
    private service: TipsService
  ) { }
}
