import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IReportParameter, ReportValueType} from 'app/common/models/reports.model';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import * as moment from 'moment';

@Component({
  selector: 'app-coachregistry-create',
  templateUrl: './coachregistry-create.component.html',
  styleUrls: ['./coachregistry-create.component.scss'],
  providers: [CoachRegistryService]
})
export class CoachRegistryCreateComponent implements OnInit {
  public static readonly componentName: string = 'CoachRegistryCreateComponent';

  public params: IReportParameter[] = [];
  public isValid: boolean;
  public validationStatus: string;
  public isDoubleReport = false;
  public isEliminateDuplicates = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected service: CoachRegistryService
  ) {

  }

  async ngOnInit(): Promise<any> {
    this.params.push({
      name: 'dateFrom',
      displayName: 'Дата начала периода (включительно)',
      valueType: ReportValueType.Date,
      editor: 'PeriodSelectorFrom',
      isRequired: true,
      isHidden: null,
      value: null
    },
    {
      name: 'dateTo',
      displayName: 'Дата конца периода (включительно)',
      valueType: ReportValueType.Date,
      editor: 'PeriodSelectorTo',
      isRequired: true,
      isHidden: null,
      value: null
    });
  }

  public onParamChange(): any {
    setTimeout(() => {
      this.isValid = true;
      this.validationStatus = '';
      this.params.reduce((accum, param) => {
        if (!param.value && param.isRequired) {
          this.isValid = false;
          this.validationStatus += `необходимо заполнить поле '${param.displayName}'<br>`;
        }
        if (param.editor === 'PeriodSelectorFrom' || param.editor === 'PeriodSelectorTo') {
          accum[param.editor] = param.value;
          if (accum['PeriodSelectorFrom'] && accum['PeriodSelectorTo']) {
            if (moment(accum['PeriodSelectorTo'], 'DD.MM.YYYY').valueOf() - moment(accum['PeriodSelectorFrom'], 'DD.MM.YYYY').valueOf() < 0) {
              this.isValid = false;
              this.validationStatus += 'Дата конца периода не может быть раньше даты начала периода<br>';
            }
          }
        }
        return accum;
      }, {});
    }, 1);
  }

  public close(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public async getReportResult(): Promise<any> {
    if (this.isValid) {
      const req = {
        dateFrom: this.params[0].value.toString(),
        dateTo: this.params[1].value.toString()
      };
      await this.service. createRegistry(req).then(async (res) => {
        if(res) {
          this.router.navigate([`../edit`], {relativeTo: this.route, queryParams: { id: res }});
        }
        else {
          console.error(res);
        }
      });
    }
  }

}
