import { Injectable } from '@angular/core';
import { PaymentTypeAlias } from "app/common/models/coach-rate.model";

@Injectable({
  providedIn: 'root'
})
export class CoachesRateCalculateService {
  public coachSalaryCalculators: {
    [ key in PaymentTypeAlias | 'salary' | 'other']: {
      aliasDisplayName: string,
      calc: (params: any) => number,
      edit: (params: any) => number,
      params: string[]
    }
  } = {
    "class": {
      aliasDisplayName:"За занятие",
      calc: this.calcSalaryClass,
      edit: this.calcSalaryClass,
      params: ["class"]
    },
    "personInGroup": {
      aliasDisplayName:"За количество занимающихся",
      calc: this.calcSalaryPersonInGroup,
      edit: this.calcSalaryPersonInGroup,
      params: ["personInGroupRate", "personCount"]
    },
    "subscription": {
      aliasDisplayName:"За абонемент",
      calc: this.calcAboniment,
      edit: this.calcAboniment,
      params: ["subscriptions"]
    },
    "salary": {
      aliasDisplayName:"Оклад",
      calc: this.calcSalary,
      edit: this.editSalary,
      params: ["salary"]
    },
    "other": {
      aliasDisplayName:"Прочее",
      calc: this.calcAdditional,
      edit: this.calcAdditional,
      params: ["other"]
    }
  };

  constructor() {
    this.coachSalaryCalculators = {
      "class": {
        aliasDisplayName:"За занятие",
        calc: this.calcSalaryClass,
        edit: this.calcSalaryClass,
        params: ["class"]
      },
      "personInGroup": {
        aliasDisplayName:"За количество занимающихся",
        calc: this.calcSalaryPersonInGroup,
        edit: this.calcSalaryPersonInGroup,
        params: ["personInGroupRate", "personCount"]
      },
      "subscription": {
        aliasDisplayName:"За абонемент",
        calc: this.calcAboniment,
        edit: this.calcAboniment,
        params: ["subscriptions"]
      },
      "salary": {
        aliasDisplayName:"Оклад",
        calc: this.calcSalary,
        edit: this.editSalary,
        params: ["salary"]
      },
      "other": {
        aliasDisplayName:"Прочее",
        calc: this.calcAdditional,
        edit: this.calcAdditional,
        params: ["other"]
      }
    };
  }

  public calcSalaryClass(params: any): number{
    return params['class'] || 0;
  }

  public calcSalaryPersonInGroup(params: any): number{
    return (params['personInGroupRate'] || 0) * (params['personCount'] || 0);
  }

  public calcAboniment(params: any): number{
    let res = 0
    let _subs = params['details'];
    let _percent = params['personInGroupRate'];  // subscriptionPercentRate
    let _unlimited = params['class']       // безлимит
    let _other = params['subscriptions'];
    if (_other > 0)
    {
      res = _other
    }
    else if (_subs)
    {
      let _summ = 0;
      for (let _item of _subs)
      {
        if (_item.VisitCount < 10000) // если меньше 10 тысяч, то считаем по формуле, иначе прибавляем безлимит
        {
          _summ += (_item.Price / _item.VisitCount) * (_percent / 100)
        }
        else
        {
          _summ += _unlimited
        }
      }
      res = _summ
    }
    params['subscriptions'] = res
    return res;
  }

  public calcSalary(params:any): number{
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let _sum = 0
    if (params && params.details)
    {
      params.details.forEach(item => {
        let dt = new Date(item.dateTo.split('.').reverse().join('-'))
        let df = new Date(item.dateFrom.split('.').reverse().join('-'))
        let _countDays = Math.round(Math.abs((dt.getTime() - df.getTime()) / oneDay)) + 1; // прибавляю 1 т.к. с 1 дек. по 31 дек. он выдает 30 дней, а не 31
        let _daysInMounth = new Date(df.getFullYear(), df.getMonth() + 1, 0).getDate()
        let _oklad = item.salary * _countDays / _daysInMounth
        _sum += _oklad
      })
    }

    params['salary'] = _sum;
    return _sum;
    //return params['salary'] || 0;
  }

  public editSalary(params: any):number {
    return params['salary'] || 0;
  }

  public calcAdditional(params: any): number{
    return params['other'] || 0;
  }
}
