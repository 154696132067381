import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RegistryElement} from '../../../common/models/registry.model';
import {ICityModel} from '../../../common/models/city-model';
import {CityService} from '../../../common/services/city.service';
import {ClubsService} from '../../clubs/services/clubs.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {dateValidator} from '../../../common/validators/date.validator';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';

@Component({
  selector: 'app-registry-element-add',
  templateUrl: './registry-element-add.component.html',
  styleUrls: ['./registry-element-add.component.scss']
})
export class RegistryElementAddComponent implements OnInit {

  @Input() table: any;
  @Output() result = new EventEmitter<RegistryElement>();

  public cities: Array<ICityModel> = [];
  public clubs: Array<any> = [];
  public currentCityId: string;
  public currentClubId: string;

  public form: FormGroup;

  constructor(
    private cityService: CityService,
    private clubsService: ClubsService,
    private alertsService: AlertsService,
    private apiV2: ApiV2Service
  ) { }

  async ngOnInit(): Promise<any> {
    await this.cityService.getItemList()
      .then(async cities => {
        this.cities = cities;
        this.cities = this.cities.sort((a, b) => {
          if (a.name > b.name) { return 1; }
          if (a.name < b.name) { return -1; }
        });
        this.currentCityId = this.table[1][0].cityId;
        await this.changeCity();
        this.currentClubId = this.table[1][0].clubId;
      });
    this.form = new FormGroup({
      contact: new FormControl(''),
      contactInfo: new FormControl('', Validators.required),
      fullName: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      date: new FormControl('', [Validators.required, dateValidator()]),
      amount: new FormControl('', Validators.required),
      bonuses: new FormControl('', Validators.required),
      commission: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required)
    });
  }

  public async changeCity(): Promise<any> {
    const city = this.cities.find((i) => {return i.id === this.currentCityId});
    await this.clubsService.getItemList(city).then(res => {
      this.currentClubId = null;
      this.clubs = res.items;
      this.clubs = this.clubs.sort((a, b) => {
        if (a.name > b.name) { return 1; }
        if (a.name < b.name) { return -1; }
      });
    })
  }

  public async changeClub(): Promise<any> {
    const club = this.clubs.filter(x => x.id === this.currentClubId);
    if (club) {
      console.log(club)
    }
  }


  public closeAdd(): any {
    this.table.isEnableAdd = false;
  }

  public async save(): Promise<any> {
    if (this.form.valid) {
      const req: any = {
        contact: this.form.get('contactInfo').value,
        fullName: this.form.get('fullName').value,
        comment: this.form.get('comment').value,
        date: this.form.get('date').value,
        amount: this.form.get('amount').value,
        bonuses: this.form.get('bonuses').value,
        city: this.cities.filter(x => x.id === this.currentCityId)[0].name,
        club: this.clubs.filter(x => x.id === this.currentClubId)[0].name,
        commission: this.form.get('commission').value,
        registryId: this.table[1][0].registryId,
        cityId: this.currentCityId,
        clubId: this.currentClubId,
        status: this.form.get('status').value
      }
      req.contact = req.contact.trim().toString().replace('<', '&lt;').replace('>', '&gt;');
      await this.apiV2.registry.addElement(req).then(async (e) => {
        if (e) {
          this.table.isEnableAdd = false;
          this.result.emit(e);
          this.alertsService.alert.next({
            type: 'success',
            message: 'Транзакция сохранена',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }

  onCityChange($event: any) {
    this.currentCityId = $event.value;
    this.changeCity();
  }

  onClubChange($event: any) {
    this.currentClubId = $event.value;
    this.changeClub()
  }
}
