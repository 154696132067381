<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="Back()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" style="white-space: unset">
        Списание занятий
      </span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="Back()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body">
  <div class="preloader-wrap" [ngClass]="{'loading': isLoading}">
    <div class="preloader-wr">
      <div class="preloader">
        <img class="preloader__road" src="assets/preloader/route.svg" alt="preloader">
        <img class="preloader__bike" src="assets/preloader/bike_2.svg" alt="preloader">
      </div>
    </div>
  </div>
  <form>
    <div class="row">
      <div class="col-xs-12 col-sm-6" style="height: 40px">
        <div class="form-group">
          <div class="box-title" style="justify-content: unset">
            Клиент: <span class="row__field" style="padding-left: 10px">{{userInfo?.firstName}} {{userInfo?.lastName}}</span>
          </div>
        </div>
      </div>

      <div class="col-xs-12" style="height: 30px">
        <div class="form-group">
          <div class="box-title" style="justify-content: unset">
            Абонемент: <span class="row__field" style="padding-left: 10px">{{extension && extension.name}}{{(extension.fromDate && extension.toDate ? ', с ' + extension.fromDate + ' до ' + extension.toDate : null)}}{{', ' + extension.visitCounter}}/{{extension.visitCount < 10000 ? extension.visitCount : '∞'}}</span>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title" style="justify-content: unset">
            Подходящие занятия,
            <div class="dates">
              <mat-form-field class="width-calendar">
                <input matInput [matDatepicker]="picker" (dateInput)="changeCalendar($event)" [(ngModel)]="pickDate">
                <mat-datepicker #picker (opened)="getMonthView()" (monthSelected)="getMonthView()">
                  <mat-datepicker-actions>
                    <button mat-raised-button color="primary" (click)="selectToday()">
                      Сегодня
                    </button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
              <label class="dates__value" (click)="picker.open()">{{ baseDate | date: 'd MMMM Y, E' }}</label>
            </div>
          </div>
          <div class="classes-box" #classesRef>
            <div class="classes-title" *ngIf="!classes.length">Подходящих занятий на выбранный день нет.</div>
            <div class="classes-list" *ngIf="classes.length">
              <div class="classes-item" [style.backgroundColor]="class.course?.color || 'gray'"
                   [style.color]="checkColor(class.course?.color)"
                   [style.--hover-bg-color]="class.course?.color + '99' || '#80808099'"
                   *ngFor="let class of classes; let i = index"
                   (click)="checkedClass(class)">
                <div class="badge" style="left: 0">
                  <span class="letter newGroup" *ngIf="class.isNewGroup">
                      Н
                      <span class="help">Новый набор</span>
                  </span>

                  <span class="letter draft" *ngIf="class.entityStatus === 'draft'">
                    Ч
                    <span class="help">Черновик</span>
                  </span>
                </div>
                <div class="badge-check">
                  <input [id]="'mc'+ i"
                         [name]="'mc'+ i" type="checkbox" class="{{'mc'+ i}}" [ngClass]="class.checked ? 'checked' : null">
                  <label [for]="'mc'+ i" class="check-box" [ngClass]="class.recorded ? 'recorded' : ''"></label>
                </div>

                <div class="classes-item__title">{{class.course?.name}}</div>
                <span class="classes-item__desc"><b>{{class.startTime}}</b></span>
                <span class="classes-item__desc">{{class.numberOfVisits - class.numberOfQueued}}/{{class.maxCapacity}} {{class.numberOfQueued > 0 ? '(+' + class.numberOfQueued + ')' : null}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title" style="justify-content: unset">
            Выбрано:
          </div>
          <div class="row__field">
            <div class="classes-list check">
              <div class="classes-item-check">
                <div class="classes-item__title">Без занятия</div>
                <div class="empty-item">
                  <a class="dates__link" (click)="addItem(-1)" [style.pointer-events]="emptyItem == 0 ? 'none' : 'unset'">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">remove</mat-icon>
                  </a>
                  <div style="color: black">{{emptyItem}}</div>
                  <a class="dates__link" (click)="addItem(1)">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
                  </a>
                </div>
              </div>
              <div class="classes-item-check" [style.backgroundColor]="item.course?.color || 'gray'"
                   [style.color]="checkColor(item.course?.color)"
                   *ngFor="let item of this.checkedListItem; let i = index">
                <div class="badge--clear" (click)="deletedItem(item)">
                  <button class="btn btn-clear class-requests_btn">
                    <span class="icon-cross">
                      <span class="icon-cross__left"></span>
                      <span class="icon-cross__right"></span></span>
                  </button>
                </div>
                <div class="classes-item__title">{{item.course?.name}}</div>
                <span class="classes-item__desc"><b>{{item.startTime}}</b></span>
                <span class="classes-item__desc">{{item.numberOfVisits - item.numberOfQueued}}/{{item.maxCapacity}} {{item.numberOfQueued > 0 ? '(+' + item.numberOfQueued + ')' : null}}</span>
                <br>
                <span class="classes-item__desc"><b>{{item.startDate}}</b></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">
            <input type="checkbox" class="checkbox__slide"
                   id="writeOffImmediately" (change)="writeOffImmediately = !writeOffImmediately">
            <label for="writeOffImmediately" class="checkbox__slide_label">Подтвердить посещение после записи</label>
          </div>
          <label class="field-validation-error" style="font-size: 1rem; margin-top: 0">*Записи без занятий всегда подтверждаются независимо от этой настройки</label>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <div class="box-title" *ngIf="writeOffImmediately || emptyItem > 0 && !checkedListItem.length || (!writeOffImmediately && !emptyItem && !checkedListItem.length)">
            Списываем посещений: {{checkedListItem.length + emptyItem}}
          </div>
          <div class="box-title" *ngIf="!writeOffImmediately && !emptyItem && checkedListItem.length > 0">
            Бронируем занятий: {{checkedListItem.length + emptyItem}}
          </div>
          <div class="box-title" style="flex-direction: column; align-items: start" *ngIf="!writeOffImmediately && emptyItem > 0 && checkedListItem.length > 0">
            <span style="margin-bottom: 7px">Бронируем занятий: {{checkedListItem.length}}</span>
            <span>Списываем посещений: {{emptyItem}}</span>
          </div>
          <div class="row__field" style="padding-left: 0">
            <button class="btn btn-primary" type="submit" (click)="writeOffSubscription(checkedListItem)"
                    [disabled]="((this.extension.visitCount < this.checkedListItem.length + this.extension.visitCounter + this.emptyItem) && this.extension.visitCount < 10000) || (this.emptyItem + this.checkedListItem.length <= 0)">
              <span class="btn-primary__desc" *ngIf="writeOffImmediately || emptyItem > 0 && !checkedListItem.length || (!writeOffImmediately && !emptyItem && !checkedListItem.length)">Списать</span>
              <span class="btn-primary__desc" *ngIf="!writeOffImmediately && !emptyItem && checkedListItem.length > 0">Забронировать</span>
              <span class="btn-primary__desc" *ngIf="!writeOffImmediately && emptyItem > 0 && checkedListItem.length > 0">Забронировать и списать</span>
            </button>
            <label *ngIf="((this.extension.visitCount < this.checkedListItem.length + this.extension.visitCounter + this.emptyItem) && this.extension.visitCount < 10000)"
                   class="field-validation-error" style="font-size: 1.1rem">Количество списаний превышает посещение по абонементу</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
