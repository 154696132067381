import { Component, Injector } from '@angular/core';

import { IExtendedClubModel } from 'app/common/models/club.model';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent extends ToggleEditComponent<IExtendedClubModel> {
  constructor(
    injector: Injector
  ) {
    super(injector)
  }
  onBeginEdit() {
    if (!this.isReadonly) {
      this.tempData = this.modelTransformIn();
      this.enableEdit = true;
    }
  }
}
