import { Injectable, Injector } from '@angular/core';
import { ClubNetsService } from 'app/components/clubnets/services/clubnets.service';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IAccount } from 'app/common/models/account.model';
import { ICityModel } from 'app/common/models/city-model';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class UsersService extends TableListService<IAccount> {

  constructor(
    protected injector: Injector,
    protected clubNetsService: ClubNetsService
  ) {
    super(injector);
  }

  public getSingleItem(req: IRoutingParams) {
    return this.apiService.accounts.getById(req.id);
  }

  public getItemList(city?: ICityModel) {
    if (this.routingParams.isInClubContext) {
      return this.apiService.accounts.getList(this.routingParams);
    } else {
      return this.apiV2Service.accounts.getAll(city).then(res => res.items);
    }
  }

  public getSystemUsers() {
    return this.apiService.accounts.getSystemUsers();
  }

  save(model: IAccount) {
    return this.apiService.accounts.save(model);
  }

  create(model: IAccount) {
    return this.save(model);
  }

  delete(model: IAccount) {
    return this.apiService.accounts.delete(model);
  }

}
