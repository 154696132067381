
<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default" class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Финансовый учет</span>
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="isLoading">
    <div class="clubnets-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" *ngIf="accounting">Кол-во записей: <b class="pd">{{accounting.length}}</b></span>
        </div>
      </div>
      <div class="table-responsive">
        <div class="main-block-add-elements">
          <div class="btn-add-element" *ngIf="!isEnableAdd" (click)="isEnableAdd = true">
            <span class="drop-zone__btn"><span class="sp-gallery_addphoto"></span></span>
          </div>
          <app-accounting-add @animation
                              (result)="addElement($event)"
                              *ngIf="isEnableAdd"
                              [clubId]="context.clubId"
                              [isShowColumnTargetInAccounting]="isShowColumnTargetInAccounting"
                              [listAccounting]="accounting"
          ></app-accounting-add>
        </div>
        <p *ngIf="accounting?.length == 0" style="text-align: center">Учет пустой</p>
        <table class="table" *ngIf="accounting?.length > 0">
          <thead>
          <tr>
            <td class="table-cell table-title-cell">Дата</td>
            <td class="table-cell table-title-cell" style="min-width: 160px;">Получено наличными</td>
            <td class="table-cell table-title-cell" style="min-width: 160px;">Получено безналичными</td>
            <td class="table-cell table-title-cell">Количество людей за день</td>
            <td class="table-cell table-title-cell" *ngIf="isShowColumnTargetInAccounting">Задание</td>
            <td class="table-cell table-title-cell"></td>
            <td class="table-cell table-title-cell"></td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let element of accounting' [class.isNewAccounting]="element?.IsNew" (click)="element?.IsNew ? element.IsNew = false : null">
            <app-accounting-element (resultRemove)="removeElement($event)"
                                    (resultEdit)="edit($event)"
                                    [element]="element"
                                    [listAccounting]="accounting"
                                    [isShowColumnTargetInAccounting]="isShowColumnTargetInAccounting"
                                    @animation
            ></app-accounting-element>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>



