import {Component, OnInit, OnDestroy, DoCheck, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';

import { ContextService } from 'app/common/services/context.service';
import { AuthService } from 'app/common/services/auth.service';
import { CityService } from 'app/common/services/city.service';

import {IUserInfo, isAdmin, isManager, isClubManager, isAdminOrManagerOrClubNetAdmin, isClubCoach, isClubCoachAssistant, isClubNetAdmin} from 'app/common/models/user-info.model';
import { IContext, IRoutingParams } from 'app/common/models/context.model';
import { IIdName } from 'app/common/models/id-name.model';
import { ICityModel } from 'app/common/models/city-model';
import { IExtendedClubModel } from 'app/common/models/club.model';
import { takeUntil, filter, debounceTime, take } from 'rxjs/operators';
import {ClubSettingService} from '../../../components/club-settings/club-settings.service';
import {ClientsService} from '../../../components/clients/services/clients.service';
import {PermissionKey} from '../../models/permission-key.model';
import {PermissionService} from '../../services/permission.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class HeaderComponent implements OnInit, OnDestroy, DoCheck {
  public static readonly componentName: string = 'HeaderComponent';
  public isMobile;
  public searchStart: boolean = false;
  protected ngUnsubscribe: Subject<void> = new Subject();
  public contextInfo: IContext = null;
  private routingParams: IRoutingParams = null;

  public showCitySelector = false;
  public showSettings = false;

  public componentReady = false;
  public currentClub: IIdName = null;
  public userInfo: IUserInfo = null;
  public clubNetName: string;

  public clubName1: string;
  public clubName2: string;
  public cityName1: string;
  public cityName2: string;

  public isClubDropdownOpen = false;
  public isProfileDropdownOpen = false;
  public isCityDropdownOpen = false;

  public currentCity: ICityModel = null;
  public cities: ICityModel[] = [];

  public offlineMode: string = null;
  @Input() isThemePartner: boolean;

  public clientSearchList = [];
  public cursor = 0;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    public contextService: ContextService,
    private permissionService: PermissionService,
    public settingsService: ClubSettingService,
    private clientsService: ClientsService,
    private cityService: CityService
  ) {
    this.isMobile = !window.matchMedia('only screen and (min-width: 700px)').matches;
  }

  onResize() {
    this.isMobile = !window.matchMedia('only screen and (min-width: 700px)').matches;
  }

  public updateHeaderInfo() {
    if (this.contextInfo && this.contextInfo.clubs.length > 0) {
      this.clubNetName = this.contextInfo.clubNet.name;
      this.currentClub = this.contextInfo.clubs.find(item => this.routingParams && item.id === this.routingParams.clubId);
      const parts = this.currentClub.name.split(" "); // Разделяем по пробелам
      this.clubName1 = parts.slice(0, parts.length / 2).join(" "); // Первая половина
      this.clubName2 = parts.slice(parts.length / 2).join(" "); // Вторая половина
    } else {
      this.clubNetName = null;
      this.currentClub = null;
    }
    this.showSettings = this.userInfo && !(isAdmin(this.userInfo) && this.routingParams && this.routingParams.isInClubContext);
    this.showCitySelector = this.userInfo && isAdmin(this.userInfo) &&
      (!this.route.firstChild ||
        this.route.firstChild.snapshot.url
          .some(x =>
            x.path === 'users' ||
            x.path === 'clubnets' ||
            x.path === 'clubanalytics' ||
            x.path === 'collections' ||
            x.path === 'clubs'
          ));
  }

  public updateCity() {
    if (!this.routingParams.cityId) {
      this.currentCity = null;
      this.cityService.currentCity.next(this.currentCity);
    } else if (!this.currentCity || (this.routingParams.cityId !== this.currentCity.id)) {
      this.cityService.getItemList()
        .then(cities => {
          this.cities = cities;
          this.currentCity = this.cities.find(city => city.id === this.routingParams.cityId) || null;
          this.cityService.currentCity.next(this.currentCity);
          const parts = this.currentCity.name.split(" "); // Разделяем по пробелам
          this.cityName1 = parts.slice(0, parts.length / 2).join(" "); // Первая половина
          this.cityName2 = parts.slice(parts.length / 2).join(" "); // Вторая половина
        });
    }
  }

  public ngDoCheck(): void {
    this.offlineMode = window.localStorage.getItem('offlineMode');
  }

  public async ngOnInit() {
    this.contextService.currentUrl.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateHeaderInfo();
      });

    this.contextService.routingParams.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(Boolean),
      debounceTime(100))
      .subscribe(async params => {
        this.routingParams = params;
        this.updateHeaderInfo();
        this.updateCity();
      })

    this.contextService.contextInfo.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(contextInfo => {
        if (contextInfo) {
          this.contextInfo = contextInfo;
          this.updateHeaderInfo();
        }
      });

    this.authService.userInfo.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.userInfo = res;
        this.updateHeaderInfo();
        this.componentReady = true;
      });
    await this.authService.getUserInfo();
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClickOutside(event, location) {
    if (!event.isInside) {
      switch (location) {
        case 'cityList': {
          this.isCityDropdownOpen = false;
          break;
        }
        case 'clublist': {
          this.isClubDropdownOpen = false;
          break;
        }
        case 'profile': {
          this.isProfileDropdownOpen = false;
          break;
        }
      }
    }
  }

  logout() {
    this.authService.logout()
      .then(() => this.router.navigate(['/login']));
  }

  moveToSettings() {
    if (this.userInfo) {
      if (isAdmin(this.userInfo)) {
        this.router.navigate([`${this.currentCity.id}/adminsettings`]);
      } else {
        this.router.navigate([this.contextService.makeContextUrl('clubsettings')]);
      }
    }
  }

  setCurrentCity(city: ICityModel) {
    this.currentCity = city;
    this.cityService.currentCity.next(city);
    const parts = this.currentCity.name.split(" "); // Разделяем по пробелам
    this.cityName1 = parts.slice(0, parts.length / 2).join(" "); // Первая половина
    this.cityName2 = parts.slice(parts.length / 2).join(" "); // Вторая половина
    this.route.children[0].url.pipe(
      take(1))
      .subscribe(path => {
        this.router.navigate([`${city.id}/${path[1].path}`]);
      })
  }

  setCurrentClub(item: IExtendedClubModel, event?: MouseEvent) {
    const ctx = Object.assign(this.routingParams, { clubId: item.id, cityId: item.cityId });

    if (event && (event.which === 2 || event.ctrlKey)) {
      event.preventDefault();

      const urlTree = this.router.createUrlTree([`${item.cityId}/${ctx.clubNetId}/${item.id}/${this.createUrl()}`]);
      window.open(urlTree.toString());

      return;
    }

    const urlAndContext = this.contextService.makeContextUrl(this.createUrl(), ctx);
    window.location.href = urlAndContext;
    this.router.navigate(['dummy'])
      .then(() => this.router.navigate([urlAndContext]));
  }

  createUrl() {
    let url = 'club';
    if (this.userInfo.startScreen) {
      if (this.userInfo.startScreen === 'clubschedule') {
        const baseDate = new Date(this.cityService.getTimestampClub()).toLocaleDateString('ru');
        url = `clubschedule/${baseDate}`;
      } else {
        url = this.userInfo.startScreen;
      }
    } else if (isClubCoach(this.userInfo) || isClubCoachAssistant(this.userInfo)) {
      const baseDate = new Date(this.cityService.getTimestampClub()).toLocaleDateString('ru');
      url = `clubschedule/${baseDate}`;
    } else if (isClubNetAdmin(this.userInfo)) {
      url = 'clubanalytics';
    } else { url = 'clubdashboard'; }
    return url;
  }

  searchClients(evt: any, input: any) {
    const pattern = input.trim();

    if (pattern.length < 2) {
      this.clientSearchList = [];
      return;
    } else {
      this.clientsService.searchClients(pattern)
        .then(res =>
          this.clientSearchList = [...res.clients, ...res.users]
        );
    }

    if(evt.key === "Enter" && this.cursor > 0 && this.cursor <= this.clientSearchList.length) {
      this.goClient(this.clientSearchList[this.cursor-1])
    }

    evt.key !== "ArrowDown" && evt.key !== "ArrowUp" && (this.cursor = 0);

    if(evt.key === "ArrowDown" && this.cursor < this.clientSearchList.length) {
      this.cursor++;
    }

    if(evt.key === "ArrowUp" && this.cursor > 0) {
      evt.preventDefault();
      this.cursor--;
    }
  }

  scannerSearch($event: any, value: any) {
    const pattern = parseInt(value.trim(), 10).toString(36).toUpperCase();
    if (pattern.length < 2) { return; }
    this.clientsService.searchClients(pattern)
      .then(res => {
          this.clientSearchList = [...res.clients, ...res.users];
          if (res.clients.length === 1) {
            this.goClient(res.clients[0]);
          }
        }
      );
  }

  goClient(client: any) {
    const url = this.contextService.makeContextUrl(`clubclients/${client.id}`);
    window.location.href = url;
  }

  public _isPermit: (alias: string) => boolean = alias => {
    return this.permissionService.isAvailable(this, alias as PermissionKey, this.userInfo?.role);
  }
}
