<div class="backdrop" (click)="dismissAlert(); dismissConfirm()" [ngClass]="{'active': confirm || alert?.type === 'danger'}"></div>
<div class="notify {{alert?.type}} {{alert?.position}}" [@enterAnimation] *ngIf="alert">
  <div class="alert__dismiss" (click)="dismissAlert()">
    <span class="icon-cross__left"></span>
    <span class="icon-cross__right"></span>
  </div>
  <h3 class="alert__header">{{alert.header}}</h3>
  <div class="alert__block">{{alert.message}}</div>
</div>

<div class="confirm" *ngIf="confirm">
  <div class="confirm__popup">
    <div class="confirm__header" *ngIf="confirm.header">
      <h3 [innerHTML]="confirm.header"></h3>
    </div>
    <div class="confirm__message" *ngIf="confirm.message">
      <div [innerHtml]="confirm.message"></div>
    </div>
    <div class="confirm__message" *ngIf="confirm.template">
      <ng-container *ngTemplateOutlet="confirm.template.ref; context: confirm.template.context"></ng-container>
    </div>
    <div class="confirm__inputs" *ngIf="confirm.inputs">
      <div *ngFor="let input of confirm.inputs; let i = index">
        <input type="text" class="input" placeholder="{{input.title}}" [(ngModel)]="itemCollection['input.name']">
      </div>
    </div>
    <div class="confirm__buttons">
      <div *ngFor="let button of confirm.buttons; let i = index" [ngClass]="'col-xs-' + (12 / confirm.buttons.length)">
        <button class="btn btn-small" [ngClass]="{'btn-primary': button.callback, 'btn-outline': !button.callback}" (click)="buttonClick(button)">
          <span [ngClass]="{'btn-primary__desc': button.callback, 'btn-outline__desc': !button.callback}" [innerHTML]="button.title"></span>
        </button>
        <div class="confirm__clue">
          <div *ngIf="button.callback && i === activeButtonIndex">Enter</div>
          <div *ngIf="button.callback && i !== activeButtonIndex">&nbsp;</div>
          <div *ngIf="!button.callback && i === activeButtonIndex">Esc/Enter</div>
          <div *ngIf="!button.callback && i !== activeButtonIndex">Esc</div>
        </div>
      </div>
    </div>
  </div>
</div>
