import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { WidgetsComponent } from 'app/components/widgets/widgets.component';
import { WidgetEditComponent } from 'app/components/widgets/widget-edit/widget-edit.component';
import { ThemeEditComponent } from 'app/components/widgets/theme-edit/theme-edit.component';
import { ClubEventsComponent } from '../../components/club-events/club-events.component';
import { ClubEventEditComponent } from '../../components/club-events/club-event-edit/club-event-edit.component';

export const widgetsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubwidgets',
  component: WidgetsComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Виджеты'}
};

export const clubEventsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubevents',
  component: ClubEventsComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Мероприятия'}
};

export const clubEventsEditRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubevents/:id',
  component: ClubEventEditComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Редактирование мероприятия'}
};

export const widgetsEditRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubwidgets/:id',
  component: WidgetEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование виджета' }
};

export const themesEditRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/themes/:id',
  component: ThemeEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование темы' }
};

export const themesNewRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/themes/create/:type',
  component: ThemeEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Создание темы' }
};
