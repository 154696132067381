import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnChanges, ViewChild, ElementRef} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-dropdown',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger('shrinkOut', [
      state('inactive', style({
        height: '0',
      })),
      state('active', style({
        zIndex: '101'
      })),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => inactive', animate('300ms ease-out'))
    ])
  ],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() items: FilterItem[];
  @Input() alias: string;
  @Input() style: string;
  @Input() mat_select = false;
  @Output() update = new EventEmitter();

  @ViewChild('dropdown_title') dropdown_title: ElementRef;

  public isMobile;
  public isEmpty = true;
  public idPrefix = `dropdown_${guid(null)}_`;
  public controlState: 'active' | 'inactive' = 'inactive';
  public title: string;
  public enabledItems = [];

  constructor() {
    this.isMobile = !window.matchMedia('only screen and (min-width: 768px)').matches;
  }

  onResize() {
    this.isMobile = !window.matchMedia('only screen and (min-width: 768px)').matches;
  }

  public ngOnInit() {
    this.title = this.alias;
    this.enabledItems = this.items.filter(x => x.isEnabled).map(i => i.name);
  }

  public onClickOutside(event: any) {
    setTimeout(() => !event.isInside && (this.controlState = 'inactive'));
  }

  public ngOnChanges() {
    this.onChange();
  }

  public changeState() {
    if(this.isMobile) return;
    this.controlState = (this.controlState === 'inactive') ? 'active' : 'inactive';
  }

  public onChecked() {
    this.onChange();
    this.update.emit();
  }

  public checkAll() {
    setTimeout(() => {
      this.items.forEach(item => item.isEnabled = false);
      this.isEmpty = true;
      this.title = this.alias;
      this.enabledItems = null;
      this.update.emit();
    });
  }

  private onChange() {
    const enabledItems = this.items
      .filter(item => item.isEnabled)
      .map(item => item.name);
    this.isEmpty = enabledItems.length === 0;
    this.title = enabledItems.join(', ') || this.alias;
  }

  public selectChange(selectedValue) {
    this.items.forEach(i => selectedValue.includes(i.name) ? i.isEnabled = true : i.isEnabled = false);

    this.onChange();
    this.update.emit();
  }
}

export interface FilterItem {
  id: string;
  name: string;
  isEnabled: boolean;
}

export function guid(a: any): any {
  return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) :
    (1e7.toString(16) + -1e3 + -4e3 + -8e3 + -1e11)
      .replace(/[018]/g, guid)
      .replace(/-/g, '');
}
