import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as helpers from 'app/common/helpers.functions';
import { IClassItemModel } from 'app/common/models/class-item.model';
import { IConflictType } from 'app/common/models/conflict-type.model';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { ColorService } from 'app/common/services/color.service';

@Component( {
  // tslint:disable-next-line:component-selector
  selector: '[app-class-item]',
  templateUrl: './class-item.component.html',
  styleUrls: [ './class-item.component.scss' ]
} )

export class ClassItemComponent implements OnChanges, OnInit {
  @Input() last;
  @Input() colorBy;
  @Input() showEdit;
  @Input() Model: IClassItemModel;
  private _isInCopyMode;
  endTime: any;

  @Input() set isInCopyMode(value: boolean) {
    this._isInCopyMode = value;
    if (!this.isInCopyMode)
      this.Model && (this.Model.isChecked = false);
  }

  get isInCopyMode(): boolean {
    return this._isInCopyMode || false;
  }

  @Output() onClick = new EventEmitter();

  componentReady: boolean;
  public currentColor: string;
  public borderColor: string;

  constructor( private colorService: ColorService ) { }

  onClickHandler () {
    // TODO: Чтобы выбросить клик наружу. Не факт, что так нужно
    this.onClick.emit( this.Model );
  }

  colorise () {
    switch ( this.colorBy ) {
      case 'coach': this.currentColor = this.Model.coach ? this.Model.coach.color : 'gray'; break;
      case 'course': this.currentColor = this.Model.course ? this.Model.course.color : 'gray'; break;
      case 'room': this.currentColor = this.Model.room ? this.Model.room.color : 'gray'; break;
    }
    this.borderColor = this.colorService.getMatchingSecondaryColor( this.currentColor );
  }

  isConflicted () {
    // return this.Model.conflictType != IConflictType.None;
    return false;
  }

  getConflicts () {
    let res = '';
    if ( ( this.Model.conflictType & IConflictType.ScheduleTime ) === IConflictType.ScheduleTime ) {
      res += 'Недопустимое время занятия\n';
    }
    if ( ( this.Model.conflictType & IConflictType.RequiredCoach ) === IConflictType.RequiredCoach ) {
      res += 'Обязательный тренер не задан\n';
    }
    if ( ( this.Model.conflictType & IConflictType.CoachLimitation ) === IConflictType.CoachLimitation ) {
      res += 'Недопустимый тренер\n';
    }
    if ( ( this.Model.conflictType & IConflictType.RoomLimitation ) === IConflictType.RoomLimitation ) {
      res += 'Недопустимый зал\n';
    }
    return res;
  }

  isDraft() {
    return this.Model.entityStatus === EntityStatus.draft;
  }

  isShowInSchedule() {
    return this.Model.isShowInSchedule && this.Model.classStatus === 'cancelled';
  }

  isVirtual() {
    return this.Model.id === '00000000000000000000000000000000';
  }

  ngOnChanges() {
    this.colorise();
  }

  ngOnInit() {
    this.colorise();
    this.componentReady = true;
    this.endTime = helpers.str2date(this.Model.startDate);
    this.endTime = this.makeClassEndTime(this.endTime).getTime() + (this.Model.duration * 60000);
  }

  makeClassEndTime(d: any) {
    return new Date(d.getFullYear(),
                    d.getMonth(),
                    d.getDate(),
                    Number(this.Model.startTime.split(':')[0]),
                    Number(this.Model.startTime.split(':')[1]));
  }
}
