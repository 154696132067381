import { ClubSetting, ClubSettingVariant } from './../models/club-setting.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

import * as _ from 'lodash';

import { ILoginData } from 'app/common/models/login-data.model';
import { IToken } from 'app/common/models/token.model';
import { HttpService } from './http.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { IClubModel, IExtendedClubModel } from 'app/common/models/club.model';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { ICourseModel } from 'app/common/models/course.model';
import { ICoachModel } from 'app/common/models/coach.model';
import { IPayoutRatesModel, ClassRate } from 'app/common/models/coach-rate.model';
import { ICoachRegistryModel, ICoachRegistryParameter, CoachAccrual, CoachPayout, CoachPayoutParameter } from 'app/common/models/coach-registry.model';
import { IClassItemModel } from 'app/common/models/class-item.model';
import { IClubNetModel } from 'app/common/models/clubnet.model';
import { ILocation } from 'app/common/models/location-model';
import { IFeedback } from 'app/common/models/feedback.model';
import { IPasswordChangeRequest } from 'app/common/models/password-change-request.model';
import { IAccount } from 'app/common/models/account.model';
import { IClubProfileModel } from 'app/common/models/clubprofile.model';
import { IEventListModel } from 'app/common/models/event.model';

import { ISubscription } from 'app/common/models/subscription.model';
import { ITrainingCategory } from 'app/common/models/training-category.model';
import { IMedias } from 'app/common/models/medias.model';
import { IReportInfo, IReportResult } from 'app/common/models/reports.model';
import { IPagedList } from 'app/common/models/common.models';
import { ApiV2Service } from './api-v2.service';
import { IPagedInfo } from '../models/paging-info.model';
import { IMergeClientModel } from '../models/client.model';
import { map } from 'rxjs/operators';
import { IClubDictionaryData } from '../models/club-dictionary-data.model';

@Injectable()
export class ApiService {
  // TODO: нужно унифицировать названия методов. Где-то save, а где-то saveItem.
  // TODO: докуметировать это всё.

  // TODO: полностью убрать использование cityUrlName в API и везде использовать cityId
  private _cities_: { [key: string]: string } = {};
  private requestCounter: BehaviorSubject<number>;

  public get isBusy(): Observable<boolean> {
    return this.requestCounter.pipe(map(res => res !== 0));
  }

  public feedback = ((api: this) => {
    return {
      submit(model: IFeedback): Promise<void> {
        return api.apiPost<void>('feedback', model);
      }
    };
  })(this);

  public clubSettings = ((api: this) => {
    return {
      get(id: string): Promise<ClubSetting> {
        return api.apiGet('../clubSettings-get', { id });
      },
      save(item: ClubSetting): Promise<any> {
        return api.apiPost('../clubSettings-save', item);
      },
      getByClub(id: string): Promise<ClubSetting[]> {
        return api.apiGet('../clubSettings-getByClub', { id });
      },
    };
  })(this);

  public clubSettingVariants = ((api: this) => {
    return {
      get(id: string): Promise<ClubSettingVariant> {
        return api.apiGet('../clubSettingVariants-get', { id });
      },
      save(item: ClubSetting): Promise<any> {
        return api.apiPost('../clubSettingVariants-save', item);
      },
      getAll(): Promise<ClubSettingVariant[]> {
        return api.apiGet('../clubSettingVariants-getAll');
      },
    };
  })(this);

  public trainingCategories = ((api: this) => {
    return {
      getList(): Promise<Array<ITrainingCategory>> {
        return api.apiGetProperty('training-categories', 'trainingCategories');
      }
    };
  })(this);

  public clubDictionaries = ((api: this) => {
    return {
      get(clubId: string, alias: string): Promise<IClubDictionaryData> {
        return api.apiPost('../../v1/clubDictionary-get', { clubId:clubId, alias:alias });
      },
      save(dic: IClubDictionaryData): Promise<IClubDictionaryData> {
        return api.apiPost('../../v1/clubDictionary-save', dic);
      },
      remove(dic: IClubDictionaryData): Promise<IClubDictionaryData> {
        return api.apiPost('../../v1/clubDictionary-remove', dic);
      },
      getByClub(clubId: string): Promise<Array<IClubDictionaryData>> {
        return api.apiPost(`../../v1/clubDictionaryItems-getByClub`, { clubId });
      },
      setIsDefault(dic: IClubDictionaryData): Promise<Array<IClubDictionaryData>> {
        return api.apiPost(`../../v1/clubDictionary-setIsDefault`, dic);
      },
      getIsDefault(clubId: string): Promise<IClubDictionaryData> {
        return api.apiPost(`../../v1/clubDictionary-getIsDefault`, { clubId });
      },
    };
  })(this);

  public subscriptions = ((api: this) => {
    return {
      getList(context: IRoutingParams, options: {}): Promise<Array<ISubscription>> {
        return api.apiGetPropertyInContext('subscription-plans', 'subscriptionPlans', context, options, true);
      },
      getItem(req: IRoutingParams): Promise<ISubscription> {
        return api.apiGetInContext(`subscription-plans/${req.id}`, req);
      },
      saveItem(context: IRoutingParams, model: ISubscription) {
        return api.apiPostInContext('subscription-plans', api.modelToFormData(model), context, true);
      },
      setStatus(context: IRoutingParams, entityStatus: string, item: ISubscription): Promise<any> {
        return api.apiPostInContext(`subscription-plans/${item.id}/entity-status`, { entityStatus }, context);
      },
      getCourseSubscriptions(context: IRoutingParams, id: string): Promise<any> {
        return api.apiGetInContext(`courses/${id}/subscription-plans?inClub=true`, context);
      }
    };
  })(this);

  public locations = ((api: this) => {
    return {
      async getList(context: IRoutingParams): Promise<Array<ILocation>> {
        const url = await api.makeContextUrl('/locations', { cityId: context.cityId });
        return api.apiGetProperty<Array<ILocation>>(url, 'locations');
      }
    };
  })(this);

  public visits = ((api: this) => {
    return {
      getClubVisits(context: IRoutingParams, timeFrom: number, timeTo: number): Promise<any> {
        return api.apiGetInContext(`visits`, context, { timeFrom, timeTo });
      },
      getClassVisitById(context: IRoutingParams, visitId: string){
        return api.apiGetInContext(`classes/0000000000000000000000000000000000/visits/${visitId}`, context);
      }
    };
  })(this);

  public schedule = ((api: this) => {
    return {
      getList(context: IRoutingParams, baseDate?: string, toDate?: string, incrementRequestCount: boolean = true): Promise<any> {
        return api.apiGetInContext(`schedule?withStatus=publishedOrDraft`, context, { baseDate, toDate }, incrementRequestCount);
      },

      getCourseList(context: IRoutingParams, courseId: string, dateFrom: string, dateTo: string): Promise<any> {
        return api.apiGetInContext(`courses/${courseId}/schedule`, context, { dateFrom, dateTo });
      },

      getItem(item: IClassItemModel, context: IRoutingParams): Promise<IClassItemModel> {
        if (context.id == null) {
          return api.apiGetInContext<IClassItemModel>(`classes/virtual/${item.prototypeId}`, context, { date: item.startDate })
            .then(res => {
              res.originalDate = res.startDate;
              return res;
            });
        } else {
          return api.apiGetInContext(`classes/${context.id}`, context);
        }
      },

      saveItem(context: IRoutingParams, model: IClassItemModel) {
        if (model.originalDate) {
          return api.apiPostInContext(`classes/virtual/${model.prototypeId}?date=${model.originalDate}`, model, context);
        } else {
          return api.apiPostInContext(`classes?notifyBookedUsers=${model.notifyBookedUsers}`, model, context);
        }
      },

      cancelClasses(context: IRoutingParams, model, isVirtual) {
        if (isVirtual) {
          return api.apiPostInContext(`classes/virtual/${model.classId}/cancel/?date=${model.date}`, model, context);
        } else {
          return api.apiPostInContext('classes/cancel', model, context);
        }
      },

      actualSchedule(context: IRoutingParams) {
        return api.apiPostInContext('schedule/set-actual-date', {}, context);
      },

      getVisits(context: IRoutingParams, classId: string, withGroupClassVisits: boolean): Promise<any> {
        return api.apiGetInContext(`classes/${classId}/visits?withGroupClassVisits=${withGroupClassVisits}`, context);
      },

      book(context: IRoutingParams, model: any) {
        return api.apiPostInContext('book-crm', model, context);
      },

      bookFree(context: IRoutingParams, model: any) {
        return api.apiPostInContext(`booking-free`, model, context);
      },

      changeSubscriptionPlan(context: IRoutingParams, model: any) {
        return api.apiPostInContext('book-crm/subscription-plan', model, context);
      },

      validateSubscriptionNotSuitable(context: IRoutingParams, model: any) {
        return api.apiPostInContext('book-crm/validate-subscription-not-suitable', model, context);
      },

      cancelBooking(context: IRoutingParams, model: any, withoutNotifications: boolean, incrementRequestCount: boolean = true): Promise<any> {
        let url = `classes/${model.classId}/visits/${model.id}/cancel-by-club`;
        if (withoutNotifications)
          url += '?withoutNotifications=true';
        return api.apiPostInContext(url, {}, context, incrementRequestCount);
      },

      confirmVisited(context: IRoutingParams, model, incrementRequestCount: boolean = true): Promise<any> {
        return api.apiPostInContext(`classes/${model.classId}/visits/${model.id}/confirm-visited-by-club`, {}, context, incrementRequestCount);
      },

      confirmQueued(context: IRoutingParams, model, incrementRequestCount: boolean = true): Promise<any> {
        return api.apiPostInContext(`classes/${model.classId}/visits/${model.id}/confirm-queued`, {}, context, incrementRequestCount);
      },

      confirmMissed(context: IRoutingParams, model, incrementRequestCount: boolean = true): Promise<any> {
        return api.apiPostInContext(`classes/${model.classId}/visits/${model.id}/confirm-missed-by-club`, {}, context, incrementRequestCount);
      },

      cancelAllClass(context: IRoutingParams, req) {
        return api.apiPostInContext('schedule/cancelAllClass', req, context);
      },
    };
  })(this);

  public coaches = ((api: this) => {
    return {
      getList(context: IRoutingParams): Promise<Array<ICoachModel>> {
        return api.apiGetPropertyInContext('coaches', 'coaches', context, { cropName: 'cropAvatar' }, true);
      },
      getItem(req: IRoutingParams): Promise<ICoachModel> {
        return api.apiGetInContext(`coaches/${req.id}`, req, api.getItemParams());
      },
      saveItem(context: IRoutingParams, model: ICoachModel): Promise<any> {
        return api.apiPostInContext('coaches', api.modelToFormData(model), context, true);
      },
      setStatus(context: IRoutingParams, entityStatus: string, item: ICoachModel): Promise<any> {
        return api.apiPostInContext(`coaches/${item.id}/entity-status`, { entityStatus }, context);
      },
      getRates(context: IRoutingParams, coachId: string): Promise<Array<IPayoutRatesModel>> {
        return api.apiGetPropertyInContext('rates', 'coachRates', context, { coachId }, true);
      },
      getRatesByDates(context: IRoutingParams, req: ICoachRegistryParameter): Promise<Array<ClassRate>> {
        return api.apiPostInContext('coachRatesByDates', req, context);
      },
      saveRate(context: IRoutingParams, model: IPayoutRatesModel): Promise<any> {
        return api.apiPostInContext('rates', model, context, true);
      },
      removeRate(context: IRoutingParams, id: string): Promise<boolean> {
        return api.apiPostInContext('rate-remove', { id }, context)
      }
    };
  })(this);

  public coachRegistries = ((api: this) => {
    return {
      getRegistries(context: IRoutingParams): Promise<Array<ICoachRegistryModel>> {
        return api.apiGetPropertyInContext('registries', 'coachRegistries', context, {}, false);
      },
      getRegistryById(req: IRoutingParams): Promise<ICoachRegistryModel>{
        return api.apiGetInContext(`registries/${req.id}`, req, api.getItemParams());
      },
      removeRegistry(context: IRoutingParams, id: string): Promise<boolean> {
        return api.apiPostInContext('registry-remove', { id }, context)
      },
      addRegistry(context: IRoutingParams, req: ICoachRegistryParameter): Promise<any> {
        return api.apiPostInContext(`registry-create`, { dateFrom: req.dateFrom.toString(), dateTo: req.dateTo.toString() }, context)
      },
      setStatus(context: IRoutingParams, id: string, status: string): Promise<boolean> {
        return api.apiPostInContext('registry-status', { id, status }, context)
      },
      updateAccrual(context: IRoutingParams, req: CoachAccrual): Promise<any> {
        return api.apiPostInContext('registry-edit-element', req, context)
      },
      getAccrualsByRegistryId(context: IRoutingParams, registryId: string): Promise<Array<CoachAccrual>> {
        return api.apiGetPropertyInContext('registry-getAccrualsByRegistryId', 'coachAccruals', context, { registryId }, false);
      },
      saveAccrual(context: IRoutingParams, model: CoachAccrual): Promise<any> {
        return api.apiPostInContext('accruals', model, context, true);
      },
      saveAllAccruals(context: IRoutingParams, models: any): Promise<any> {
        return api.apiPostInContext('accruals-all', models, context, true);
      },
      addAccrual(context: IRoutingParams, model: any): Promise<any> {
        return api.apiPostInContext('accrual-add-element', model, context, true);
      },
      removeAccrualElement(context: IRoutingParams, id: string): Promise<boolean> {
        return api.apiPostInContext('accrual-remove-element', { id }, context)
      },
      removeAccrualElements(context: IRoutingParams, registryId: string, coachId: string): Promise<boolean> {
        return api.apiPostInContext('accrual-remove-elements-byCoachId', { registryId, coachId }, context)
      },
      getPayoutsByRegistryId(context: IRoutingParams, registryId: string): Promise<Array<CoachPayout>> {
        return api.apiGetPropertyInContext('payout-getPayoutByRegistryId', 'coachPayouts', context, { registryId }, false);
      },
      removePayout(context: IRoutingParams, id: string): Promise<boolean> {
        return api.apiPostInContext(`payout-remove`, { id }, context, true);
      },
      addPayout(context: IRoutingParams, model: CoachPayoutParameter): Promise<CoachPayout> {
        return api.apiPostInContext(`payouts`, model, context, true)
      }
    };
  })(this);

  public courses = ((api: this) => {
    return {
      getList(context: IRoutingParams): Promise<Array<ICourseModel>> {
        return api.apiGetPropertyInContext('courses', 'courses', context, { cropName: 'cropAvatar' }, true);
      },
      getItem(req: IRoutingParams): Promise<ICourseModel> {
        return api.apiGetInContext(`courses/${req.id}?inClub=true`, req, api.getItemParams());
      },
      saveItem(context: IRoutingParams, model: ICourseModel): Promise<any> {
        return api.apiPostInContext('courses', api.modelToFormData(model), context, true);
      },
      setStatus(context: IRoutingParams, entityStatus: string, item: ICourseModel): Promise<any> {
        return api.apiPostInContext(`courses/${item.id}/entity-status`, { entityStatus }, context);
      }
    };
  })(this);

  public rooms = ((api: this) => {
    return {
      getList(context: IRoutingParams): Promise<Array<IClubroomModel>> {
        return api.apiGetPropertyInContext('rooms', 'rooms', context, { cropName: 'cropAvatar' }, true);
      },
      getItem(req: IRoutingParams): Promise<IClubroomModel> {
        return api.apiGetInContext(`rooms/${req.id}`, req, api.getItemParams());
      },
      saveItem(context: IRoutingParams, model: IClubroomModel): Promise<any> {
        return api.apiPostInContext('rooms', api.modelToFormData(model), context, true);
      },
      setStatus(context: IRoutingParams, entityStatus: string, item: IClubroomModel): Promise<any> {
        return api.apiPostInContext(`rooms/${item.id}/entity-status`, { entityStatus }, context);
      }
    };
  })(this);

  public clubnets = ((api: this) => {
    return {
      async getItem(req: IRoutingParams): Promise<IClubNetModel> {
        const url = await api.makeContextUrl('', { cityId: req.cityId, clubNetId: req.id });
        return api.apiGet<IClubNetModel>(url);
      },
      async saveItem(context: IRoutingParams, model: IClubNetModel): Promise<{}> {
        const url = await api.makeContextUrl('nets', { cityId: context.cityId });
        return api.apiPost(url, model);
      },
      async deleteItem(context: IRoutingParams, model: IClubNetModel): Promise<{}> {
        const url = await api.makeContextUrl('/delete', { cityId: context.cityId, clubNetId: model.id });
        return api.apiPost(url, {});
      }
    };
  })(this);

  public clubs = ((api: this) => {
    return {
      async getClubsByNet(context: IRoutingParams): Promise<Array<IClubModel>> {
        const url = await api.makeContextUrl('/clubs', { cityId: context.cityId, clubNetId: context.clubNetId });
        return api.apiGetProperty<Array<IClubModel>>(url, 'clubs', {}, true);
      },
      importClients(context: IRoutingParams, file: File): Promise<Object> {
        const formData: FormData = new FormData();
        formData.append("files", file);
        return api.apiPostInContext(`importclients`, formData, context, true);
      },
      getItem(req: IRoutingParams): Promise<IExtendedClubModel> {
        return api.apiGetInContext('', req, api.getItemParams());
      },
      saveItem(context: IRoutingParams, model: IExtendedClubModel): Promise<{ clubId: string, clubNetId: string, message: string }> {
        return api.apiPostInContext('clubs', api.modelToFormData(model), context, true);
      },
      setStatus(context: IRoutingParams, entityStatus: string, item: IExtendedClubModel): Promise<any> {
        return api.apiPostInContext(`clubs/${item.id}/entity-status`, { entityStatus }, context);
      },
      changeStatus(context: IRoutingParams, status: string): Promise<any> {
        return api.apiPostInContext(`entity-status`, { entityStatus: status }, context);
      }
    };
  })(this);

  public clubProfile = ((api: this) => {
    return {
      getData(context: IRoutingParams): Promise<any> {
        return api.apiGetInContext('profile', context, {});
      },
      postData(context: IRoutingParams, model: IClubProfileModel): Promise<any> {
        return api.apiPostInContext('profile', model, context, true);
      }
    };
  })(this);

  public search = ((api: this) => {
    return {
      users(query: string) {
        return api.apiPost(`/search/users`, { pattern: query, pageIndex: 0, pageSize: 24 });
      },

      clubs(query: string): Promise<IPagedList<IClubModel>> {
        return api.apiGet(`cities/moscow/search/clubs`, { searchText: query });
      },
    };
  })(this);

  public adminReports = ((api: this) => {
    return {
      getAll(): Promise<IReportInfo[]> {
        return api.apiPost('reports-getAll', { areaType: 'AdminArea' }, 'reports');
      },

      getByName(reportName: string): Promise<IReportInfo> {
        return api.apiPost('reports-getByName', { areaType: 'AdminArea', reportName }, 'report');
      },

      execute(reportName: string, parameters: {}) {
        return api.apiPost('reports-execute', { areaType: 'AdminArea', parameters, reportName });
      }
    };
  })(this);

  public reports = ((api: this) => {
    return {
      getReports(context: IRoutingParams): Promise<IReportInfo[]> {
        return api.apiGetPropertyInContext('reports', 'reports', context, {}, false);
      },

      getReportByName(context: IRoutingParams, reportName: string): Promise<IReportInfo> {
        return api.apiGetPropertyInContext('reports/' + reportName, 'report', context, {}, false);
      },

      getReportResult(context: IRoutingParams, reportName: string, params?: any): Promise<IReportResult> {
        return api.apiPostInContext(`reports/${reportName}/result`, params ? { parameters: params } : {}, context, true);
      },
    };
  })(this);

  public events = ((api: this) => {
    return {
      getEvents(context: IRoutingParams, pageIndex: number, pageSize: number): Promise<IEventListModel> {
        return api.apiGetInContext(`events-line?pageIndex=${pageIndex}&pageSize=${pageSize}`, context, {}, false);
      },

      confirmEvent(context: IRoutingParams, classId: string, visitId: string) {
        return api.apiPostInContext(`classes/${classId}/visits/${visitId}/book-by-club`, {}, context);
      }
    };
  })(this);

  public clients = ((api: this) => {
    return {
      getList(context): Promise<any> {
        return api.apiGetInContext('clients', context, {});
      },
      getClient(req: IRoutingParams): Promise<any> {
        return api.apiGetInContext(`clients/${req.id}`, req, api.getItemParams());
      },
      getClientAbonements(context): Promise<any> {
        return api.apiGetInContext(`clients/${context.id}/client-subscription-plans`, context, {});
      },
      getClientAbonement(context, id): Promise<any> {
        return api.apiGetInContext(`clients/${context.id}/client-subscription-plans/${id}`, context, {});
      },
      sellClientAbonement(context, model, clientId): Promise<any> {
        return api.apiPostInContext(`clients/${clientId}/sell-subscription-plan`, model, context, true);
      },
      suspendClientAbonement(context, model, planId): Promise<any> {
        return api.apiPostInContext(`clients/${context.id}/client-subscription-plans/${planId}/suspend`, model, context);
      },
      reactivateClientSubscription(context, subClientId, planId): Promise<any> {
        return api.apiPostInContext(`clients/${context.id}/client-subscription-plans/${planId}/reactivate/${subClientId}`, null, context);
      },
      getClientSubscriptionSuspendHistory(context, subClientId, planId): Promise<any> {
        return api.apiGetInContext(`clients/${context.id}/client-subscription-plans/${planId}/historySuspend/${subClientId}`, context, null);
      },
      saveClientAbonement(context, model, clientId): Promise<any> {
        return api.apiPostInContext(`clients/${clientId}/client-subscription-plans`, model, context, true);
      },
      cancelClientSubscription(context, planId): Promise<any> {
        return api.apiPostInContext(`clients/${context.id}/client-subscription-plans/${planId}/cancel`, {}, context);
      },
      deletedClientSubscription(context, planId): Promise<any> {
        return api.apiPostInContext(`clients/${context.id}/client-subscription-plans/${planId}/deleted`, {}, context);
      },
      saveItem(context: IRoutingParams, model: any): Promise<any> {
        return api.apiPostInContext('clients', api.modelToFormData(model), context, true);
      },
      async searchClients(context: IRoutingParams, pattern: string): Promise<any> {
        // TODO: зачем это тут в таком виде с явной обработкой ошибок?
        const url = await api.makeContextUrl('search/clients', context)
        return api.http.post(url, { pattern }, undefined, true)
          .pipe(map(x => {
            if (x.text().length > 0) { return x.json(); }
            return x.status;
          }))
          .toPromise()
          .then(r => r)
          .catch(e => { api.errorHandler(e); });
      },
      async filterAndSort(clubId: string, filterPattern: string, orderBy: string, sortMode: string, pageIndex: number, pageSize: number): Promise<IPagedInfo<any>> {
        api.increment()
        return api.http.post('clients-filterAndSort', { clubId, filterPattern, orderBy, sortMode, pageIndex, pageSize }, undefined, false, 'v2/crm/')
          .pipe(map(x => {
            if (x.text().length > 0) { return x.json(); }
            return x.status;
          }))
          .toPromise()
          .then(r => { api.decrement(); return r.result })
          // .then(res => (res.clients as IClientModel[]).map(x => (x.id = x.userId, x)))
          .catch(e => { api.decrement(); api.errorHandler(e); });
      },
      searchAbonements(context: IRoutingParams, clientId: string): Promise<any> {
        return api.apiGetInContext(`clients/${clientId}/subscriptions-by-class`, context, {});
      },
      async getMergeGroups(clubId: string): Promise<{ mergeList: IMergeClientModel[] }[]> {
        return api.http.post('clients-getMergeGroups', { clubId }, undefined, false, 'v2/crm/')
          .pipe(map(x => {
            if (x.text().length > 0) { return x.json(); }
            return x.status;
          }))
          .toPromise()
          .then(r => r.result)
          .catch(e => { api.errorHandler(e); });
      },
      merge(receiveClientId: string, sourceClientIds: string[]): Promise<any> {
        return api.http.post('clients-merge', { receiveClientId, sourceClientIds }, undefined, false, 'v2/crm/')
        .toPromise()
        .then(r => r)
        .catch(e => { api.errorHandler(e); });
      },
      ignoreMergeGroups(clientId: string): Promise<any> {
        return api.http.post('clients-ignoreMergeGroups', { clientId }, undefined, false, 'v2/crm/')
        .toPromise()
        .then(r => r)
        .catch(e => { api.errorHandler(e); });
      },
      history(context: IRoutingParams, clientId: string): Promise<any> {
        return api.apiGetInContext(`clients/${clientId}/history`, context, {});
      },
      clientGroup(context: IRoutingParams, clientId: string): Promise<any> {
        return api.apiGetInContext(`clients/${clientId}/client-group`, context, {});
      }
    };
  })(this);

  public accounts = ((api: this) => {
    return {
      getList(context: IRoutingParams): Promise<Array<IAccount>> {
        return api.apiGetPropertyInContext(`accounts`, 'accounts', context);
      },

      getSystemUsers(): Promise<Array<IAccount>> {
        return api.apiGetProperty('accounts/system', 'accounts');
      },

      getById(id: string): Promise<IAccount> {
        return api.apiGet(`accounts/${id}`);
      },

      save(model: IAccount): Promise<{}> {
        return api.apiPost(`accounts/`, model);
      },

      delete(model: IAccount): Promise<{}> {
        return api.apiPost(`accounts/${model.id}/delete`, {});
      },

      login(loginData: ILoginData): Promise<IToken> {
        return api.apiPost('account/login', loginData);
      },

      beginEmailRegistration(email: string): Promise<{ authToken: string }> {
        return api.apiPost(`account/begin-email-registration`, { email: email });
      },

      completeEmailRegistration(authToken: string, confirmationToken: string): Promise<IToken> {
        return api.apiPost(`account/complete-email-registration`, { authToken, confirmationToken });
      },

      logout(): Promise<void> {
        return api.apiPost<void>('account/logout', null);
      },

      completeRegister(Token: string, UserId: string): Promise<IToken> {
        return api.apiPost('account/complete-register', { Token, UserId });
      },

      resetPassword(NewPassword: string, Token: string, UserId: string): Promise<IToken> {
        return api.apiPost('account/complete-reset-password', { NewPassword, Token, UserId });
      },

      restorePassword(EmailOrPhone: string) {
        return api.apiPost('account/begin-reset-password', { EmailOrPhone });
      },

      changePassword(model: IPasswordChangeRequest): Promise<{}> {
        return api.apiPost('account/change-password', model);
      }
    };
  })(this);

  public widgets = ((api: this) => {
    return {
      vkBind(id: string, aid: string, gid: string[]): Promise<void> {
        let params = gid.reduce((p, x, i) => { p[`gid[${i}]`] = x; return p }, { aid });
        return api.apiGet(`vk-bind-group/${id}`, params, 'v2/wg/');
      },
    };
  })(this);

  constructor(
    private http: HttpService,
    private apiV2Service: ApiV2Service
  ) {
    this.requestCounter = <BehaviorSubject<number>>new BehaviorSubject(0);
  };

  private errorHandler(e) {
    throw new Error((`Запрос ${e.url} завершился неуспешно: ${e.status} ${e.statusText}`));
  }

  private increment() {
    this.requestCounter.next(this.requestCounter.value + 1);
  }

  private decrement() {
    this.requestCounter.next(Math.max(this.requestCounter.value - 1, 0));
  }

  private async makeContextUrl(url: string, context: IRoutingParams): Promise<string> {
    let prefix = '';
    if (context.cityId) {
      if (!this._cities_[context.cityId]) {
        const city = await this.apiV2Service.cities.get(context.cityId);
        this._cities_[city.id] = city.urlName;
      }
      prefix = `cities/${this._cities_[context.cityId]}`;
    }
    if (context.clubNetId) {
      prefix = `${prefix}/nets/${context.clubNetId}`;
    }
    if (context.clubId) {
      prefix = `${prefix}/clubs/${context.clubId}`;
    }
    return `${prefix}/${url}`;
  }

  /**
   * Преобразует json-представление модели в form data, помещая изображения из medias в отдельные поля
   */
  private modelToFormData<T extends { medias: IMedias }>(model: T) {
    const formData: FormData = new FormData();
    const data: T = _.cloneDeep(model);

    if (data.medias && data.medias.images) {
      data.medias.images
        .reduce((fd, image, index) => {
          image.body = null;
          image.filename = null;
          image.file = null;
          image.url = `image${index}`;
          if (model.medias.images[index].file) {
            fd.append(
              `image${index}`,
              model.medias.images[index].file,
              model.medias.images[index].file.name
            );
          }
          return fd;
        }, formData);
    }

    formData.append('data', JSON.stringify(data));
    return formData;
  }

  private getItemParams() {
    return { cropName: 'crmThumbnailBig' };
  }

  /**
  * получает значение key из get-запроса в контексте текущего клуба
  */
  private async apiGetPropertyInContext<T>(url: string, key: string, context: IRoutingParams, params?: any, publishedOrDraft?: boolean): Promise<T> {
    const ctxUrl = await this.makeContextUrl(url, context);
    return this.apiGetProperty<T>(ctxUrl, key, params, publishedOrDraft);
  }

  private async apiGetInContext<T>(url: string, context: IRoutingParams, params?: any, incrementRequestCount: boolean = true): Promise<T> {
    const ctxUrl = await this.makeContextUrl(url, context);
    return this.apiGet<T>(ctxUrl, params, undefined, incrementRequestCount);
  }

  private async apiPostInContext<T>(url: string, params: any, context: IRoutingParams, isForm = false, incrementRequestCount: boolean = true): Promise<T> {
    const ctxUrl = await this.makeContextUrl(url, context);
    return this.apiPost(ctxUrl, params, undefined, isForm, incrementRequestCount);
  }

  private apiGetProperty<T>(url: string, key: string, params?: any, publishedOrDraft?: boolean): Promise<T> {
    this.increment();

    if (publishedOrDraft) {
      params = { withStatus: 'publishedOrDraft', ...params };
    }

    return this.http.get(url, params)
      .pipe(map(x => x.json()[key]))
      .toPromise()
      .then((r) => {
        this.decrement();
        return r;
      })
      .catch(e => {
        this.decrement();
        this.errorHandler(e);
      });
  }

  private apiGet<T>(url: string, params?: any, urlPrefix?: string,
    incrementRequestCount: boolean = true): Promise<T> {
    incrementRequestCount && this.increment();
    return this.http.get(url, params, urlPrefix)
      .pipe(map(x => x.json()))
      .toPromise()
      .then((r) => {
        incrementRequestCount && this.decrement();
        return r;
      })
      .catch(e => {
        incrementRequestCount && this.decrement();
        this.errorHandler(e);
      });
  }

  private apiPost<T>(url: string, params: any, key?: string, isForm = false, incrementRequestCount: boolean = true): Promise<any> {
    incrementRequestCount && this.increment();
    return this.http.post(url, params, undefined, isForm)
      .pipe(map(x => {
        if (x.text().length > 0) {
          return (key ? x.json()[key] : x.json()) as T;
        };
        return x.status;
      }))
      .toPromise()
      .then((r) => {
        incrementRequestCount && this.decrement();
        return r;
      })
      .catch(e => {
        incrementRequestCount && this.decrement();
        this.errorHandler(e);
      });
  }
}
