import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICoachRegistryModel, CoachAccrual } from 'app/common/models/coach-registry.model';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-coachregistry-element-add',
  templateUrl: './coachregistry-element-add.component.html',
  styleUrls: ['./coachregistry-element-add.component.scss']
})
export class CoachRegistryElementAddComponent implements OnInit {

  @Input() registry: ICoachRegistryModel;
  @Input() table: any;
  @Output() result = new EventEmitter<CoachAccrual>();

  public form: FormGroup;
  public params: any = {};
  public aliasParams: any[] = [];
  public selectedAlias: any = {};

  public aliases: any[] = [];
  public coachSalaryCalculators = {
    "class": {
      aliasDisplayName:"За занятие",
      func: this.calcSalaryClass,
      params: ["class"]
    },
    "personInGroup": {
      aliasDisplayName:"За количество занимающихся",
      func: this.calcSalaryPersonInGroup ,
      params: ["personInGroupRate", "personCount"]
    },
    "subscription": {
      aliasDisplayName:"Процент от абонемента",
      func: this.calcSubscriptionPercent,
      params: ["subscriptions"]
    },
    "salary": {
      aliasDisplayName:"Оклад",
      func: this.calcSalary,
      params: ["salary"]
    },
    "other": {
      aliasDisplayName:"Прочее",
      func: this.calcAdditional,
      params: ["other"]
    }
  };

  private group: any = {};

  private basicParams: any = {
    "class": { paramDisplayName: "Ставка за занятие", paramType: "number" },
    "personInGroupRate": { paramDisplayName: "Ставка за занимающегося в группе", paramType: "number" },
    "personCount": { paramDisplayName: "Число занимающихся", paramType: "number" },
    "salary": { paramDisplayName: "Размер оклада", paramType: "number" },
    "duration": { paramDisplayName: "Продолжительность занятия", paramType: "number" },
    "other": { paramDisplayName: "Сумма", paramType: "number" },
    "subscriptions": { paramDisplayName: "Сумма", paramType: "number" }
  };


  constructor(
    private coachRegistryService: CoachRegistryService,
    private alertsService: AlertsService
  ) { }

  async ngOnInit(): Promise<any> {
    this.selectedAlias = "other";
    this.aliases = Object.keys(this.coachSalaryCalculators)

    this.params = {
      "class":0,
      "salary":0,
      "personInGroupRate":0,
      "personCount":0,
      "duration":0,
      "other":0,
      "subscriptions":0
    };

    this.aliasParams.push({
      name: "title",
      displayName: "Комментарий",
      type: "text",
      formControlName: "title",
      value: ""
    })
    for(let p of this.coachSalaryCalculators[this.selectedAlias].params) {
      this.aliasParams.push({
        name: p,
        displayName: this.basicParams[p].paramDisplayName,
        type: this.basicParams[p].paramType,
        formControlName: p,
        value: this.params[p]
      })
    }
    if(this.aliasParams.length) {
      this.group[this.aliasParams[0].name] = new FormControl(this.aliasParams[0].value.toString().replace('&lt;', '<').replace('&gt;', '>'), Validators.required)
      for(let i = 1; i < this.aliasParams.length; i++) {
        this.group[this.aliasParams[i].name] = new FormControl(this.aliasParams[i].value.toString(), Validators.required)
      }
      this.form = new FormGroup(this.group);
    }
  }

  public closeAdd(): any {
    this.table.isEnableAdd = false;
  }

  public async save(): Promise<any> {
    if (this.form.valid) {
      for (let p of this.coachSalaryCalculators[this.selectedAlias].params) {
        this.params[p] = this.form.get(p).value;
      }
      let _total = this.coachSalaryCalculators[this.selectedAlias].func(this.params);
      if (this.selectedAlias == 'subscription')
      {
        this.params['other'] = isNaN(this.form.get('subscriptions').value) ? 0 : Number.parseFloat(this.form.get('subscriptions').value);
        this.params['subscriptions'] = [];
      }

      const req: any = {
        coachId: this.table[0],
        coachRegistryId: this.registry.id,
        paymentTypeAlias: this.selectedAlias,
        total: _total,
        params: JSON.stringify(this.params),
        title: this.form.get('title').value,
      };

      await this.coachRegistryService.addAccrual(req).then(async (result) => {
        if (result) {
          this.table.isEnableAdd = false;
          this.result.emit(result);
          this.alertsService.alert.next({
            type: 'success',
            message: 'Транзакция сохранена',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(result);
        }
      });
    }
  }

  public changeAlias(alias){
    this.aliasParams = []
    this.aliasParams.push({
      name: "title",
      displayName: "Комментарий",
      type: "text",
      formControlName: "title",
      value: ""
    })
    for (let p of this.coachSalaryCalculators[alias].params) {
      this.aliasParams.push({
        name: p || "",
        displayName: this.basicParams[p].paramDisplayName || "",
        type: this.basicParams[p].paramType || "text",
        formControlName: p,
        value: this.params[p]
      })
    }
    this.group = {};
    if(this.aliasParams.length) {
      this.group[this.aliasParams[0].name] = new FormControl(this.aliasParams[0].value.toString().replace('&lt;', '<').replace('&gt;', '>'), Validators.required)
      for(let i = 1; i < this.aliasParams.length; i++) {
        this.group[this.aliasParams[i].name] = new FormControl(this.aliasParams[i].value.toString(), Validators.required)
      }
      this.form = new FormGroup(this.group);
    }
  }

  private calcSalaryClass(params: any): number{
    return params['class'] || 0;
  }

  private calcSalaryPersonInGroup(params: any): number{
    return (params['personInGroupRate'] || 0) * (params['personCount'] || 0);
  }

  private calcSubscriptionPercent(params: any): number {
      return params['subscriptions'] || 0;
  }

  private calcSalary(params:any): number{
    return params['salary'] || 0;
  }

  private calcAdditional(params: any): number{
    return params['other'] || 0;
  }
}
