<div class="content__body">

  <form [formGroup]="form">
    <div class="row">
      <div class="col-xs-12">
        <div class="alert-warning" *ngIf="IsDuplicate()" @animation>
          На выбранную дату уже есть отчет
        </div>
        <div class="form-group">
          <div class="box-title">Дата</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="displayDateAt" type="date" formControlName="displayDateAt" />
            <label class="field-validation-error" *ngIf="form.get('displayDateAt').touched && form.get('displayDateAt').invalid">Некорректное значение</label>
          </div>
        </div>
        <div class="form-group">
          <div class="box-title">Получено наличными</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="receivedCash" pattern="^\d*(\.\d{0,2})?$" type="number" formControlName="receivedCash" />
            <label class="field-validation-error" *ngIf="form.get('receivedCash').touched && form.get('receivedCash').invalid">Некорректное значение</label>
          </div>
        </div>
        <div class="form-group">
          <div class="box-title">Получено безналичными</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="receivedNonCash" pattern="^\d*(\.\d{0,2})?$" type="number" formControlName="receivedNonCash" />
            <label class="field-validation-error" *ngIf="form.get('receivedNonCash').touched && form.get('receivedNonCash').invalid">Некорректное значение</label>
          </div>
        </div>
        <div class="form-group">
          <div class="box-title">Количество людей за день</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="peoplePerDay" type="number" pattern="^\d+$" formControlName="peoplePerDay" />
            <label class="field-validation-error" *ngIf="form.get('peoplePerDay').touched && form.get('peoplePerDay').invalid">Некорректное значение</label>
          </div>
        </div>
        <div class="form-group" *ngIf="isShowColumnTargetInAccounting">
          <div class="box-title">Задание</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="target" type="number" formControlName="target" />
            <label class="field-validation-error" *ngIf="form.get('target').touched && form.get('target').invalid">Некорректное значение</label>
          </div>
        </div>
      </div>

     <!--<div class="col-xs-12 col-sm-6 col-md-2">
        <div class="form-group">
          <div class="box-title">Name</div>
          <div class="row__field">
            <input class="form-control input__text input__text&#45;&#45;long" name="name" type="text" formControlName="name" />
          </div>
        </div>
      </div>-->

      <div class="clearfix"></div>
      <div class="col-xs-12 col-sm-6 col-md-4">
        <br>
        <br>
        <div class="form-group btn-save">
          <button class="btn btn-primary btn-small" (click)="confirmSave()">
            <span class="btn-primary__desc">Сохранить</span>
          </button>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <br>
        <br>
        <div class="form-group btn-save">
          <a class="btn btn-link btn-small" (click)="closeAdd()">
            <span class="btn-primary__desc">Отмена</span>
          </a>
        </div>
      </div>
    </div>
  </form>

</div>
