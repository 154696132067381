import {Component, ElementRef, HostListener, Injector, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {IClientSubscriptionPlan} from 'app/common/models/subscription.model';
import * as moment from 'moment';
import {Location} from '@angular/common';
import {ClientsService} from '../services/clients.service';
import {ContextService} from '../../../common/services/context.service';
import {ActivatedRoute} from '@angular/router';
import {ClubSetting} from '../../../common/models/club-setting.model';
import {ClubSettingService} from '../../club-settings/club-settings.service';
import {LocalPreloader, PreloaderService} from '../../../common/services/preloader.service';
import {EntityStatus} from '../../../common/models/entity-status.model';
import {ApiService} from '../../../common/services/api.service';
import {IRoutingParams} from '../../../common/models/context.model';
import {CityService} from '../../../common/services/city.service';
import * as helpers from '../../../common/helpers.functions';
import {SubscriptionService} from '../../subscriptions/services/subscription.service';
import {IUserInfo} from '../../../common/models/user-info.model';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {ScheduleService} from '../../schedule/services/schedule.service';
import {ApiV2Service} from '../../../common/services/api-v2.service';

@Component({
  selector: 'app-quick-record',
  templateUrl: './quick-record.component.html',
  styleUrls: ['./quick-record.component.scss']
})
export class QuickRecordComponent implements OnInit {
  public static readonly componentName: string = 'QuickRecordComponent';

  public userInfo: IUserInfo;
  public clubSettings: ClubSetting[];
  public extension: IClientSubscriptionPlan;
  public baseDate: Date;
  public pickDate: any;
  public subDate: any;
  public classes: any[];
  public context: IRoutingParams;
  public schedule: any;
  public clubTimestamp: number;
  public availableCourse: any[] = [];
  public checkedListItem: any[] = [];
  public emptyItem = 0;
  public writeOffImmediately = false;
  public classTimeStamp: number;
  public isLoading = false;

  @ViewChild('picker') datePicker: any;
  @ViewChild('classesRef') public classesRef: ElementRef;

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', '-'];

  constructor(
    protected serviceSchedule: ScheduleService,
    private apiV2Service: ApiV2Service,
    public alertsService: AlertsService,
    public _location: Location,
    public service: ClientsService,
    public contextService: ContextService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private preloaderService: PreloaderService,
    private cityService: CityService,
  ) { }

  Back(): void {
    this._location.back();
  }

  async ngOnInit() {
    this.isLoading = true;
    this.checkedListItem = [];
    this.emptyItem = 0;
    this.clubTimestamp = this.cityService.getTimestampClub();
    this.baseDate = new Date(this.clubTimestamp);
    this.pickDate = moment(this.baseDate, 'DD.MM.YYYY');
    this.context = this.contextService.getRoutingParams();
    this.route.queryParams.subscribe(async params => {
      const abonementId: string = params['subsId'];
      this.service.getClientAbonement(abonementId)
        .then(res => {
          this.extension = res;
          for (const cours of this.extension?.courses) {
            this.availableCourse.push(cours.id)
          }
        });
      this.userInfo = await this.apiService.clients.getClient(this.context);
    });
    await this.getClasses().then(r => {
      this.isLoading = false;
    });
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(e) {
    if (e.keyCode === 27) {
      this._location.back();
    }
  }

  showAlert() {
    this.alertsService.alert.next({
      type: 'info',
      message: 'Клиент уже записан на это занятие.',
      header: 'Сообщение',
      position: 'top',
      timeout: 2500
    });
  }

  public async changeCalendar(event: any) {
    this.isLoading = true;
    let selectedDate = typeof event === 'string' ? event : event.value.format('YYYY-MM-DD').split('-').reverse().join('.');
    selectedDate = helpers.str2date(selectedDate);
    this.baseDate = new Date(selectedDate)
    this.schedule = null;
    this.classes = null;
    this.clubTimestamp = this.baseDate.getTime();
    await this.getClasses().then(r => {
      this.isLoading = false;
    });
  }

  @LocalPreloader<QuickRecordComponent>(x => x.preloaderService, a => a.classesRef)
  async getClasses() {
    return this.apiService.schedule.getList(this.context, this.baseDate.toLocaleDateString('ru'))
      .then(res => {
        this.schedule = res;
        this.classes = res.classes.filter(item => {
          const [day, month, year] = item.startDate.split('.');
          const [hour, minute] = item.startTime.split(':');
          const timestamp = new Date(year, month - 1, day, hour, minute).getTime();
          return this.cityService.checkTime( false, this.clubTimestamp, timestamp);
        }).filter(item => {
          item.checked = false;
          if (item.userIds?.includes(this.userInfo.userId)) {
            item.checked = true;
            item.recorded = true;
          }
          if (this.checkedListItem.length) {
            for (const i of this.checkedListItem) {
              if (item.course.id == i.course.id && item.startDate == i.startDate && item.startTime == i.startTime) {
                item.checked = true
                break
              }
            }
          }
          return item.entityStatus === EntityStatus.published && item.classStatus === 'available' && this.availableCourse.includes(item.course.id)
        });
      });
  }

  checkedClass(classe) {
    if (classe.recorded) {
      this.showAlert();
      return
    }
    if (classe.checked) {
      this.deletedItem(classe);
    } else {
      classe.checked = true;
      this.checkedListItem.push(classe);
      this.sortCheckedList();
    }
  }

  sortCheckedList() {
    this.checkedListItem.sort((a, b) => {
      const [dayA, monthA, yearA] = a.startDate.split('.');
      const [hourA, minuteA] = a.startTime.split(':');
      const timestampA = new Date(yearA, monthA - 1, dayA, hourA, minuteA).getTime();
      const [dayB, monthB, yearB] = b.startDate.split('.');
      const [hourB, minuteB] = b.startTime.split(':');
      const timestampB = new Date(yearB, monthB - 1, dayB, hourB, minuteB).getTime();
      return timestampA - timestampB
    })
  }

  addItem(_count) {
    if (_count == -1) {
      this.emptyItem += _count;
    }
    if (_count == 1) {
      this.emptyItem += _count;
    }
  }

  checkColor(bgColor) {
    if (!bgColor) {return '';}
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
  }

  async writeOffSubscription(listItem) {
    if ((this.extension.visitCount < this.checkedListItem.length + this.extension.visitCounter + this.emptyItem) && this.extension.visitCount < 10000) {
      return;
    }
    const req = {
      booking: [],
      bookingFree: [],
      isWriteOffImmediately: this.writeOffImmediately,
      clubId: this.context.clubId
    }
    if (listItem.length > 0) {
      for (const item of listItem) {
        this.classTimeStamp = Date.parse(item.startDate.split('.').reverse().join('-') + 'T' + item.startTime);
        req.booking.push({
          clientId: this.userInfo.id,
          userId: this.userInfo.userId ? this.userInfo.userId : null,
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          prototypeId: this.isVirtual(item.id) ? item.prototypeId : null,
          startDateTime: this.isVirtual(item.id) ? this.classTimeStamp - (new Date().getTimezoneOffset() * 60 * 1000) : null,
          classId: !this.isVirtual(item.id) ? item.id : null,
          subscriptionId: this.extension.id,
          coachId: item.coach?.id
        });
      }
    }
    if (this.emptyItem > 0) {
      for (let i = 1; i <= this.emptyItem; i++) {
        req.bookingFree.push({
          duration: 1,
          fromType: null,
          roomId: '00000000000000000000000000000000',
          startDateTime: (new Date().getTime()),
          subscriptionId: this.extension.id,
          clientId: this.userInfo.id,
          userId: this.userInfo.userId ? this.userInfo.userId : null,
        });
      }
    }
    if (req.booking.length > 0 || req.bookingFree.length > 0) {
      await this.apiV2Service.quickRecord.booking(req).finally(() => {
        this.Back();
      });
    }
  }

  isVirtual(id): boolean {
    return id === '00000000000000000000000000000000';
  }

  deletedItem(classe) {
    classe.checked = false;
    for (const i in this.checkedListItem) {
      if (classe.course.id == this.checkedListItem[i].course.id && classe.startDate == this.checkedListItem[i].startDate && classe.startTime == this.checkedListItem[i].startTime) {
        this.checkedListItem.splice(Number(i), 1);
        break;
      }
    }
  }

  selectToday() {
    this.datePicker.select(moment());
    this.datePicker.close();
  }

  getMonthView() {
    setTimeout(() => {
      this.subDate && this.subDate.unsubscribe()
      this.subDate = this.datePicker._componentRef?.instance._calendar.monthView.selectedChange.subscribe((res: any) => {
        this.datePicker.select(res);
        this.datePicker.close();
      })
    })
  }
}
