import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';

@Directive({
    selector: '[setHeight]'
})

export class MultiLineSelectHeightDirective implements OnInit{
    constructor(private elementRef: ElementRef){}

    ngOnInit(): void {
      setTimeout(() => {this.heightControl()});
    }

    @HostListener("change") onChange() {
      setTimeout(() => {this.heightControl()});
    }

    public heightControl() {
      const elem = this.elementRef.nativeElement;

      if(elem.offsetHeight < elem.scrollHeight) {
        elem.style.height = elem.scrollHeight + 16 + 'px';
      }
    }
}
