import { Pipe, PipeTransform } from '@angular/core';

const months = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июня', 'июля', 'авг.', 'сент.', 'окт.', 'ноя.', 'дек.'];
const monthsLong = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

@Pipe({ name: 'dateInterval2string' })
export class DateInterval2StringPipe implements PipeTransform {
    transform(value) {
        const d1 = value.d1.getDate();
        const m1 = value.d1.getMonth();
        const y1 = value.d1.getFullYear();
        const d2 = value.d2.getDate();
        const m2 = value.d2.getMonth();
        const y2 = value.d2.getFullYear();
        if (y1 !== y2) {
            return `${d1} ${months[m1]} ${y1} - ${d2} ${months[m2]} ${y2}`
        } else
            if (m1 !== m2) {
                return `${d1} ${months[m1]} - ${d2} ${months[m2]} ${y2}`
            } else {
                return `${d1} - ${d2} ${monthsLong[m2]} ${y2}`
            }
    }
}

@Pipe({ name: 'date2string' })
export class Date2StringPipe implements PipeTransform {
    transform(value: Date) {
        const day = value.getDate();
        const month = value.getMonth();
        return `${day} ${monthsLong[month]}`;
    }
}
