export interface IReportResult {
  reportInfo: IReportInfo;
  resultGroups: any[];
  // TODO: Какова точная структура resultGroups?
}

export interface IReportInfo {
  name: string;
  displayName: string;
  description: string;
  parameters: IReportParameter[];
  tableColumns: IReportTableColumn[];
  tableSorting: IReportTableSorting[];
  tableGrouping: IReportTableSorting[];
}

export interface IReportParameter {
  name: string;
  displayName: string;
  valueType: ReportValueType;
  editor: string;
  isRequired: boolean;
  isHidden?: boolean;
  value?: any;
}

export enum ReportValueType {
  Bool = 'Bool',
  String = 'String',
  Number = 'Number',
  Money = 'Money',
  Date = 'Date',
  Guid = 'Guid',
  EntUser = 'EntUser',
  EntClient = 'EntClient',
  EntCourse = 'EntCourse',
  EntCoach = 'EntCoach',
  EntRoom = 'EntRoom',
  EntCity = 'EntCity'
}

export interface IReportTableColumn {
  name: string;
  displayName: string;
  displayCollectionName: string;
  valueType: string;
  editor: string;
  isSortable: boolean;
  sort?: TypeSort
}

export interface IReportTableSorting {
  columnName: string;
  isDescending: boolean;
}

export interface IReportTableGrouping {
  columnName: string;
}

export enum TypeSort {
  asc = 0,
  desc = 1
}
