import { UserRole } from './roles.model';

export interface IUserInfo {
    email: string;
    emailConfirmed: boolean;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    phoneNumberConfirmed: string;
    position: string;
    registeredAt: number;
    role: string;
    timeZone: number;
    startScreen: string;
    userId: string;
}

export function isAdminOrClubNetAdmin(ui: IUserInfo): boolean {
    return isAdmin(ui) || isClubNetAdmin(ui);
};

export function isClubRole(ui: IUserInfo) {
    return isClubAdmin(ui) || isClubManager(ui) || isClubNetAdmin(ui) || isClubCoach(ui) || isClubCoachAssistant(ui);
}

export function isAdminOrManagerOrClubNetAdmin(ui: IUserInfo) {
    return isAdmin(ui) || isManager(ui) || isClubNetAdmin(ui);
}

export function isClubAdmin(ui: IUserInfo) {
    return ui.role === UserRole.ClubAdmin;
}

export function isAdmin(ui: IUserInfo): boolean {
    return ui.role === UserRole.Admin || ui.role === UserRole.Manager;
};

export function isManager(ui: IUserInfo): boolean {
    return ui.role === UserRole.Manager;
};

export function isClubManager(ui: IUserInfo): boolean {
    return ui.role === UserRole.ClubManager;
};

export function isClubNetAdmin(ui: IUserInfo): boolean {
    return ui.role === UserRole.ClubNetAdmin;
};

export function isClubCoach(ui: IUserInfo): boolean {
    return ui.role === UserRole.ClubCoach;
};

export function isClubCoachAssistant(ui: IUserInfo): boolean {
    return ui.role === UserRole.ClubCoachAssistant;
};

export interface IAnalyticsUser {
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
}
