<ng-container *ngIf="!rate?.isEnableMode">
  <td class="table-cell">
    <span class="table-cell__mobile-title"><b>Способ начисления</b></span>

      <span [innerHTML]="PaymentTypeAliasDisplayName[rate.paymentTypeAlias]"></span>

  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title"><b>Ежемесячный оклад</b></span>

      <span [innerHTML]="rate.salary"></span>

  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title"><b>Ставка за занятие</b></span>

      <span>{{rate.fixedClassRate}}</span>

  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title"><b>Ставка за занимающегося в группе</b></span>

      <span>{{rate.personInGroupRate}}</span>

  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title"><b>Ставка за час индивидуального занятия</b></span>

      <span>{{rate.personalClassRate}}</span>

  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title"><b>Дата изменения ставок</b></span>

      <span> {{rate.effectiveSinceDate}} </span>

  </td>
</ng-container>
<ng-container *ngIf="rate?.isEnableMode">
  <td class="table-cell" colspan="6">
    <form [formGroup]="form">
      <div class="row">
        <ng-container>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <div class="box-title small">Способ начисления*</div>
              <div class="row__field">
                <select class="select select__long" name="paymentTypeAlias" formControlName="paymentTypeAlias" (change)="changeAlias($event.target.value)"> 
                  <option *ngFor="let item of _PaymentTypeAliases" [value]="item">{{ PaymentTypeAliasDisplayName[item] }}</option>
                </select>
                <label class="field-validation-error" *ngIf="form.get('paymentTypeAlias').touched && form.get('paymentTypeAlias').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <div class="box-title small">Ежемесячный оклад</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="salary" type="number" formControlName="salary" />
                <label class="field-validation-error" *ngIf="form.get('salary').touched && form.get('salary').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="form.get('fixedClassRate').enabled">
            <div class="form-group">
              <div class="box-title small">Ставка за занятие</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="fixedClassRate" type="number" formControlName="fixedClassRate" />
                <label class="field-validation-error" *ngIf="form.get('fixedClassRate').touched && form.get('fixedClassRate').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="form.get('personInGroupRate').enabled">
            <div class="form-group">
              <div class="box-title small">Ставка за занимающегося в группе</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="personInGroupRate" type="number" formControlName="personInGroupRate" />
                <label class="field-validation-error" *ngIf="form.get('personInGroupRate').touched && form.get('personInGroupRate').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="form.get('personalClassRate').enabled">
            <div class="form-group">
              <div class="box-title small">Ставка за час индивидуального занятия</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="personalClassRate" type="number" formControlName="personalClassRate" />
                <label class="field-validation-error" *ngIf="form.get('personalClassRate').touched && form.get('personalClassRate').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-3" *ngIf="form.get('subscriptionPercentRate').enabled">
            <div class="form-group">
              <div class="box-title small">Процент за абонемент</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="subscriptionPercentRate" type="number" formControlName="subscriptionPercentRate" />
                <label class="field-validation-error" *ngIf="form.get('subscriptionPercentRate').touched && form.get('subscriptionPercentRate').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-3" *ngIf="form.get('forUnlimitedRate').enabled">
            <div class="form-group">
              <div class="box-title small">Ставка за безлимитный абонемент</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="forUnlimitedRate" type="number" formControlName="forUnlimitedRate" />
                <label class="field-validation-error" *ngIf="form.get('forUnlimitedRate').touched && form.get('forUnlimitedRate').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <div class="box-title small">Дата изменения ставок*</div>
              <div class="row__field">
                <app-date-picker-rates placeholder="" formControlName="effectiveSinceDate"></app-date-picker-rates>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="form-group btn-save">
              <button class="btn btn-primary btn-small" (click)="save()" [disabled]="form.invalid">
                <span class="btn-primary__desc">Сохранить</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </td>
</ng-container>
