import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {WebhookComponent} from '../../components/webhook/webhook.component';
import {WebhookEditComponent} from '../../components/webhook/webhook-edit/webhook-edit.component';

export const webhookRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/webhook',
  component: WebhookComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Webhook'}
};

export const webhookEditRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/webhook/:id',
  component: WebhookEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование вебхука' }
};

export const webhookCreateRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/webhook/create',
  component: WebhookEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование вебхука' }
};
