<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Реестры
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="!registry || registry?.length == 0">
    <div>
      <p style="text-align: center;">Здесь пока ничего нет.</p>
    </div>
  </ng-container>
  <ng-container *ngIf="registry && registry?.length != 0">
    <div class="clubnets-container">
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
          <tr>
            <td class="table-cell table-title-cell">Название</td>
            <td class="table-cell table-title-cell">Кол-во</td>
            <td class="table-cell table-title-cell" style="text-align: right">Сумма</td>
            <td class="table-cell table-title-cell" style="text-align: right">Итог</td>
            <td class="table-cell table-title-cell">Статус</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let r of registry' (click)="onRegistry(r)">
            <td class="table-cell">
              <span class="table-cell__mobile-title">Название</span>
              <span>Реестр от {{dateFormat(r.dateFrom)}} - {{dateFormat(r.dateTo)}}</span>
            </td>
            <td class="table-cell">
              <span class="table-cell__mobile-title">Кол-во транзакций</span>
              <span>{{data(r.data, 'TransactionCount')}}</span>
            </td>
            <td class="table-cell" style="text-align: right">
              <span>{{formatNumber(data(r.data, 'Amount').toFixed(2))}}</span>
            </td>
            <td class="table-cell" style="text-align: right">
              <span>{{formatNumber(data(r.data, 'AmountTotal').toFixed(2))}}</span>
            </td>
            <td class="table-cell">
              <span class="table-cell__mobile-title">Статус</span>
              <span *ngIf="r.status == 'confirmed'" style="color: yellowgreen;">Подтвержденный</span>
              <span *ngIf="r.status == 'completed'" style="color: #01e001;">Завершенный</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>
