import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ILoginData } from 'app/common/models/login-data.model';
import { IToken } from 'app/common/models/token.model';
import { ApiService } from './api.service';

import { IUserInfo } from 'app/common/models/user-info.model';
import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { ApiV2Service } from './api-v2.service';

@Injectable()
export class AuthService {
    private currentRequest: Promise<IUserInfo>;
    private isRedirecting = false;
    private _isBusy: BehaviorSubject<boolean>;
    private _isLoggedInSubject = new BehaviorSubject<boolean>(false);
    private _userInfo = new BehaviorSubject<IUserInfo>(null);

    constructor(
        private router: Router,
        private alertsService: AlertsService,
        private api: ApiService,
        private apiV2Service: ApiV2Service
    ) {
        this._isBusy = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    }

    public getUserInfo(): Promise<IUserInfo> {
        // TODO:сделать через observable как в приложении
        if (this.isLoggedIn) {
            const localUserInfo = localStorage.getItem('userInfo');

            if (localUserInfo) {
                const userInfo = JSON.parse(localUserInfo);
                this._userInfo.next(userInfo);
                return Promise.resolve(userInfo);
            } else {
                this.currentRequest = this.currentRequest || this.apiV2Service.context.getUserInfo();

                return this.currentRequest.then(userInfo => {
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    this.currentRequest = null;
                    this._userInfo.next(userInfo);
                    return userInfo;
                });
            }
        } else {
            return Promise.reject('Not authorised');
        }
    }

    public logIn(loginData: ILoginData) {
        this.clearLs();
        return this.api.accounts.login(loginData)
            .then(res => this.writeToken(res))
    }

    public logout(): Promise<void> {
        return this.api.accounts.logout()
            .then(() => this.clearLs());
    }

    public beginEmailRegistration(email: string): Promise<{ authToken: string }> {
      return this.api.accounts.beginEmailRegistration(email);
    }

    public completeEmailRegistration(authToken: string, confirmationToken: string) {
      return this.api.accounts.completeEmailRegistration(authToken, confirmationToken)
        .then(res => this.writeToken(res));
    }

    public completeRegister(token: string, uid: string) {
        return this.api.accounts.completeRegister(token, uid)
            .then(res => {
                this.clearLs();
                this.writeToken(res);
            });
    }

    public resetPassword(password: string, token: string, uid: string) {
        return this.api.accounts.resetPassword(password, token, uid)
            .then(res => {
                this.clearLs();
                this.writeToken(res);
            });
    }

    public restorePassword(emailOrPhone: string) {
        return this.api.accounts.restorePassword(emailOrPhone);
    }

    public get userInfo() {
        return this._userInfo.asObservable();
    }

    public get loggedIn() {
        return this._isLoggedInSubject.asObservable();
    }

    public get isLoggedIn(): boolean {
        const res = this.readToken() !== null
        this._isLoggedInSubject.next(res);
        return res;
    }

    public get isBusy() {
        return this._isBusy.asObservable();
    }

    private readToken() {
        let token: IToken = JSON.parse(window.localStorage.getItem('Token'));

        if (token !== null && token.expiresAt < (new Date().getTime())) {
            token = null;
            this.clearLs();

            if (
                this.router.url !== '/' &&
                this.router.url !== '/login' &&
                !this.router.isActive('reset-password', false) &&
                !this.isRedirecting
            ) {
                this.isRedirecting = true;

                this.router.navigate(['/login'])
                    .then(() => {
                        this.isRedirecting = false;
                        this.alertsService.alert.next({
                            position: 'top',
                            type: 'info',
                            message: 'Войдите снова',
                            header: 'Сессия истекла',
                            timeout: -1
                        });
                    });
            }
        }

        return token;
    }

    private writeToken(token: IToken) {
        window.localStorage.setItem('Token', JSON.stringify(token));
    }

    private clearLs() {
        window.localStorage.removeItem('Token');
        window.localStorage.removeItem('userInfo');
        this._userInfo.next(null);
    }
}
