import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import {QuickRecordComponent} from '../../components/clients/quick-record/quick-record.component';

export const quickRecordRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubclients/:id/quickrecord',
  component: QuickRecordComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Списание занятий' }
};

