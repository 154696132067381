import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from 'app/common/models/widgets.model';

@Pipe({
  name: 'widgetType'
})
export class WidgetTypePipe implements PipeTransform {
  transform(value: WidgetType): string {
    switch (value) {
      case WidgetType.schedule:
        return 'Расписание';
      case WidgetType.subscriptions:
        return 'Абонементы';
      case WidgetType.rooms:
        return 'Залы';
      case WidgetType.coaches:
        return 'Тренеры';
      case WidgetType.courses:
        return 'Виды занятий';
      case WidgetType.payment:
        return 'Онлайн оплата';
      case WidgetType.scheduleFilter:
        return 'Фильтр расписания';
      case WidgetType.certificates:
          return 'Сертификаты';
      case WidgetType.purchaseSubscription:
          return 'Покупка абонемента';
      case WidgetType.rent:
        return 'Аренда';
    }
  }
}
