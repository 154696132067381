import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { CoachSalaryCalcComponent } from 'app/components/coaches/coach-salary-calc/coach-salary-calc.component';
import { CoachRegistryCreateComponent } from 'app/components/coaches/coach-salary-calc/coachregistry-create/coachregistry-create.component';
import { CoachRegistryEditComponent } from 'app/components/coaches/coach-salary-calc/coachregistry-edit/coachregistry-edit.component';
export const coachregistriesRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubcoachsalarycalc',
  component: CoachSalaryCalcComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full',
  data: { title: 'Страница расчета ЗП' }
};

export const createCoachRegistryRoute: Route = {
  component: CoachRegistryCreateComponent,
  path: ':cityId/:clubNetId/:clubId/clubcoachsalarycalc/create',
  canActivate: [AuthGuard],
  data: { title: 'Создание реестра' },
};

export const editCoachRegistryRoute: Route = {
  component: CoachRegistryEditComponent,
  path: ':cityId/:clubNetId/:clubId/clubcoachsalarycalc/edit',
  canActivate: [AuthGuard],
  data: { title: 'Редактирование реестра' },
};
