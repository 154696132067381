<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание пользователя</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование пользователя</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>
<div class="content__body" *ngIf="Model">

  <form [formGroup]="form">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Имя</div>
          <div class="row__field">
            <input name="firstname" id="firstName" formControlName="firstName" class="form-control input__text input__text--long" [ngModel]="Model.firstName"
              maxlength="500" />
            <label for="firstName" class="field-validation-error" *ngIf="fieldErrors.firstName && isSubmited">
              {{fieldErrors.firstName}}
            </label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Фамилия</div>
          <div class="row__field">
            <input name="lastName" formControlName="lastName" id="lastName" class="form-control input__text input__text--long" [ngModel]="Model.lastName"
              maxlength="500" />
            <label for="lastName" class="field-validation-error" *ngIf="fieldErrors.lastName && isSubmited">
              {{fieldErrors.lastName}}
            </label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-sm-up"></div>
      <div class="clearfix hidden-lg-up"></div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Email</div>
          <input name="email" id="email" class="form-control input__text input__text--long" formControlName="email" [ngModel]="Model.email"
            maxlength="500" />
          <label for="email" class="field-validation-error" *ngIf="fieldErrors.email && isSubmited">
            {{fieldErrors.email}}
          </label>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Телефон</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" id="phoneNumber" name="phone" formControlName="phoneNumber" [ngModel]="Model.phoneNumber"
              maxlength="500" />
            <label for="phoneNumber" class="field-validation-error" *ngIf="fieldErrors.phoneNumber && isSubmited">
              {{fieldErrors.phoneNumber}}
            </label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-sm-up"></div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Должность</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" id="position" name="text" formControlName="position" [ngModel]="Model.position"
              maxlength="500" />
            <label for="role" class="field-validation-error" *ngIf="fieldErrors.position && isSubmited"></label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isAdmin">
        <div class="form-group" myScrollTo>
          <div class="box-title">Город</div>
          <div class="row__field">
            <select [ngModel]="Model.currentCityId" class="select select__long" id="currentCityId" name="currentCityId" formControlName="currentCityId">
              <option disabled value="null">Выберите город</option>
              <option *ngFor="let item of cities" value="{{item.id}}">
                {{item.name}}
              </option>
            </select>
            <label for="currentCityId" class="field-validation-error" *ngIf="fieldErrors.currentCityId && isSubmited">{{fieldErrors.currentCityId}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Роль</div>
          <div class="row__field">
            <select [ngModel]="Model.role" class="select select__long" id="role" name="role" formControlName="role" (change)="changeRole()">
              <option disabled value="null">Выберите роль</option>
              <option *ngFor="let item of (userInfo.role|roleinfo).canCreate" value="{{item}}">
                {{(item | roleinfo).title}}
              </option>
            </select>
            <label for="role" class="field-validation-error" *ngIf="fieldErrors.role && isSubmited">{{fieldErrors.role}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isShowClubNets() && isAdmin">
        <div class="form-group" myScrollTo>
          <div class="box-title">Сеть клубов</div>
          <div class="row__field">
            <select [ngModel]="Model.clubNetIds" class="select select__long" id="clubNet" name="clubNet" formControlName="clubNetIds"
              (change)="changeClubNet($event.target.value)" *ngIf="isShowClubNets()">
              <option disabled value="null">Выберите сеть</option>
              <option *ngFor="let item of clubnets" value="{{item.id}}">
                {{item.name}}
              </option>
            </select>
            <label for="clubNet" class="field-validation-error" *ngIf="fieldErrors.clubNetIds && isSubmited">{{fieldErrors.clubNetIds}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="form.get('role').valid">
        <div class="form-group" myScrollTo>
          <div class="box-title">Стартовый экран</div>
          <div class="row__field">
            <select [(ngModel)]="Model.startScreen" class="select select__long" id="startScreen" name="startScreen" formControlName="startScreen">
              <option disabled value="null">Выберите стартовый экран</option>
              <option *ngFor="let item of getModulesByRole()" value="{{item.name}}">
                {{item.title}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-sm-up"></div>
      <div class="clearfix hidden-lg-up"></div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-xs-12" *ngIf="isShowClubs()">
        <div class="form-group" *ngIf="isShowClubs()" myScrollTo>
          <div class="box-title">Клуб(ы)</div>
          <div class="row__field">
            <ng-container [ngSwitch]="isMultipleClubs()">
              <select *ngSwitchCase="false" [ngModel]="Model.clubIds" class="select select__long" name="club" id="club" formControlName="clubIds"
                (change)="changeClub($event.target.value)">
                <option disabled [value]="null">Выберите клуб(ы)</option>
                <option *ngFor="let item of clubs" value="{{item.id}}">{{item.name}}</option>
              </select>
              <select *ngSwitchCase="true" [ngModel]="Model.clubIds" class="select select__long" name="club" id="club" formControlName="clubIds"
                multiple>
                <option disabled [value]="null">Выберите клуб(ы)</option>
                <option *ngFor="let item of clubs" value="{{item.id}}">{{item.name}}</option>
              </select>
            </ng-container>
            <label for="club" class="field-validation-error" *ngIf="fieldErrors.clubIds && isSubmited">{{fieldErrors.clubIds}}</label>
          </div>
        </div>
      </div>


    </div>

    <div class="row" *ngIf="isChatShow">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="row__field">
            <a href="#" (click)="initChatWith($event)">Перейти к чату с пользователем</a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isEditAvailable">

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="row__field">
            <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
              <span class="btn-primary__desc">
                <span app-key-help [key]="'saveText'"></span>
                <span app-key-help mobile-hidden [key]="'saveHK'"></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6" *ngIf="Model.id != null">
        <div class="form-group">
          <div class="row__field">
            <ng-container *ngIf="Model.id != null">
              <button class="btn btn-link" (click)="delete()">
                <span app-key-help [key]="'deleteText'"></span>
                <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>

    </div>

    <div class="row" *ngIf="isEditAvailable">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="row__field">
            <button class="btn btn-link" (click)="sendInvite()">
              <span>Отправить пригласительное письмо</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </form>


</div>
