<div class="table-responsive">
  <table class="table" style="max-width:800px;">
    <thead>
      <tr>
        <td class="table-title-cell" style="width:12em;">Способ связи</td>
        <td class="table-title-cell" align="center" style="width:4em;">вкл.</td>
        <td class="table-title-cell">Номер телефона</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="table-cell">Чат</td>
        <td myScrollTo class="table-cell" align="center">
          <input type="checkbox" id="chk_contact_chat" name="chk_contact_chat" [(ngModel)]="flag.chat" (change)="onChangeHandler()">
          <label for="chk_contact_chat" class="check-box"></label>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="table-cell">Телефон</td>
        <td myScrollTo class="table-cell" align="center">
          <input type="checkbox" id="chk_contact_phone" name="chk_contact_phone" [(ngModel)]="flag.phone" (change)="onChangeHandler()">
          <label for="chk_contact_phone" class="check-box"></label>
        </td>
        <td myScrollTo class="table-cell">
          <input type="text" class="input__text input__text--long" [(ngModel)]="data.phone" [hidden]="!flag.phone" (change)="onChangeHandler()"
            pattern="\+?[\-\d\(\)]+" required>
        </td>
      </tr>
      <tr>
        <td class="table-cell">WhatsApp</td>
        <td myScrollTo class="table-cell" align="center">
          <input type="checkbox" id="chk_contact_whatsapp" name="chk_contact_whatsapp" [(ngModel)]="flag.whatsapp" (change)="onChangeHandler()">
          <label for="chk_contact_whatsapp" class="check-box"></label>
        </td>
        <td myScrollTo class="table-cell">
          <input type="text" class="input__text input__text--long" [(ngModel)]="data.whatsapp" [hidden]="!flag.whatsapp" (change)="onChangeHandler()"
            pattern="\+?[\-\d\(\)]+" required>
        </td>
      </tr>
      <tr>
        <td class="table-cell">Telegram</td>
        <td myScrollTo class="table-cell" align="center">
          <input type="checkbox" id="chk_contact_telegram" name="chk_contact_telegram" [(ngModel)]="flag.telegram" (change)="onChangeHandler()">
          <label for="chk_contact_telegram" class="check-box"></label>
        </td>
        <td myScrollTo class="table-cell">
          <input type="text" class="input__text input__text--long" [(ngModel)]="data.telegram" [hidden]="!flag.telegram" (change)="onChangeHandler()"
            pattern="\+?[\-\d\(\)]+" required>
        </td>
      </tr>
    </tbody>
  </table>
</div>
