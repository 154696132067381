<ng-container *ngIf="clubSettingVariants && clubSettingVariants.length && isReady">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Параметры
    </span>
  </div>
  <br>

  <div class="content__body">
    <div class="row" *ngIf="clubSettingVariants.some(_filterParametersBool)">
      <div *ngFor="let variant of clubSettingVariants.filter(_filterParametersBool)">
        <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
        <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
      </div>
    </div>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamWebhooks)">
      <span class="box-title">
      Веб-хуки
    </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamWebhooks)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamQueue)">
      <span class="box-title">
      Очередь на занятие
    </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamQueue)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamCertificates)">
      <span class="box-title">
      Подарочные сертификаты
    </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamCertificates)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
        <ng-container *ngFor="let variant of clubSettingVariants">
          <ng-container *ngIf="variant.type != types.Boolean && variant.category === 'certificates'">
            <app-text-edit
              [(data)]="variant.value"
              [title]="variant.title"
              [type]="'input'"
              (onFinishEdit)="saveClubSetting(variant)"
            >
            </app-text-edit>
            <div class="clearfix"></div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamAccounting)">
      <span class="box-title">
        Финансовый учет
      </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamAccounting)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamIsVisibleTabsSp)">
      <span class="box-title">
        Отображаемые вкладки в приложении SP
      </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamIsVisibleTabsSp)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamClubanalytics)">
      <span class="box-title">
        Аналитика
      </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamClubanalytics)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamAvailability)">
      <span class="box-title">
        Отображать
      </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamAvailability)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamNoticeOff)">
      <span class="box-title">
        Отключить отправку клиентам уведомлений по email
      </span>
      <div class="row">
        <div *ngFor="let variant of clubSettingVariants.filter(_filterParamNoticeOff)">
          <input [(ngModel)]="variant.value" type="checkbox" class="checkbox__slide" id="check-variant-{{variant.alias}}" (change)="saveClubSetting(variant)">
          <label for="check-variant-{{variant.alias}}" class="checkbox__slide_label">{{variant.title}}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParametersNotBool)">
      <ng-container *ngFor="let variant of clubSettingVariants.filter(_filterParametersNotBool)">
        <app-text-edit
          [(data)]="variant.value"
          [title]="variant.title"
          [type]="variant.alias == 'SeasonStart' ? 'dateTime' : 'input'"
          (onFinishEdit)="saveClubSetting(variant)"
          >
        </app-text-edit>
        <div class="clearfix"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="clubSettingVariants.some(_filterParamTariff)">
      <ng-container *ngFor="let variant of clubSettingVariants.filter(_filterParamTariff)">
        <app-text-edit
          [(data)]="variant.value"
          [title]="variant.title"
          [type]="'input'"
          (onFinishEdit)="saveClubSetting(variant)"
          >
        </app-text-edit>
        <div class="clearfix"></div>
      </ng-container>
    </ng-container>

  </div>
  <br>
</ng-container>
