<div class="table-responsive">
  <table class="table">
    <tbody>
      <tr><td colspan="2">Предустановленные поля</td></tr>
      <tr *ngFor="let item of presetFields">
        <td myScrollTo class="table-cell">
          <span class="table-cell__mobile-title"><b>Название</b></span>
          <input type="text" readonly class="input__text input__text--long" [value]="item.title">
        </td>
        <td myScrollTo class="table-cell">
          <span class="table-cell__mobile-title"><b>Удалить / восстановить</b></span>
          <ng-container *ngIf="item.isPresent; else tmplButtonRecover">
            <button class="button-delete" (click)="onRemove(item)"></button>
          </ng-container>
          <ng-template #tmplButtonRecover>
            <button class="button-recover" (click)="onRecover(item)"></button>
          </ng-template>
        </td>
      </tr>
      <tr><td colspan="2">Дополнительные поля</td></tr>
      <tr *ngFor="let item of customFields; let i = index">
        <td myScrollTo class="table-cell">
          <span class="table-cell__mobile-title"><b>Название</b></span>
          <input type="text" class="input__text input__text--long" [value]="item" (change)="onChange(i, $event)">
        </td>
        <td myScrollTo class="table-cell">
          <span class="table-cell__mobile-title"><b>Удалить</b></span>
          <button class="button-delete" (click)="onDelete(i)"></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
