import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable()
export class BrowserStateService {
    public clickObserver: Observable<Event>;
    public resizeObserver: Observable<Event>;

    private _isMobile = window.matchMedia('only screen and (max-width: 1024px)').matches ||
        (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

    constructor() {
        this.clickObserver = fromEvent(document, 'click');

        this.resizeObserver = fromEvent(window, 'resize');
    }

    public isMobile() {
        return this._isMobile;
    }
}
