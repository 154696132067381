import {Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './mechanic-select.component.html',
  styleUrls: ['./mechanic-select.component.scss']
})
export class MechanicSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() isDisabled = false;
  public entityCtrl: FormControl = new FormControl();
  public entityFilterCtrl: FormControl = new FormControl();
  public filteredEntities: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect') singleSelect: MatSelect;

  private _onDestroy = new Subject<void>();

  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  _data: any[];
  @Input() set data(data: any[]) {
    this._data = data;
    this.filteredEntities.next(this.data.slice());
  }
  get data(): any[] {
    return this._data;
  }

  _multiple: boolean;
  @Input() set multiple(multiple: boolean) { this._multiple = multiple; } get multiple(): boolean { return this._multiple; }

  _hintMessage: string;
  @Input() set hintMessage(hintMessage: string) { this._hintMessage = hintMessage; } get hintMessage(): string { return this._hintMessage; }

  _default: any[];
  @Input() set defaultValue(defaultValue: any[]) {
    this._default = defaultValue;
  }
  get defaultValue(): any[] {
    return this._default;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.entityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEntities();
      });

    setTimeout(() => {
      if (this.defaultValue) {
        this.entityCtrl.setValue(this.defaultValue);
      }
    }, 300);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data.currentValue.length) {
      this.ngOnInit();
    }
  }

  ngAfterViewInit(): void {
    // this.setInitialValue();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange($event) {
    this.onSelectionChange.emit($event);
  }

  // private setInitialValue() {
  //   this.filteredEntities
  //     .pipe(take(1), takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       this.singleSelect.compareWith = (a: any, b: any) => a.id === b.id;
  //     });
  // }

  private filterEntities() {
    if (!this.data) {
      return;
    }

    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredEntities.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEntities.next(
      this.data.filter(entity => entity.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
