import { AlertsService } from './../../../common/components/alerts/services/alerts.service';
import { IClubDictionaryData } from './../../../common/models/club-dictionary-data.model';
import { ActivatedRoute } from '@angular/router';
import { DictionaryDataService } from './../../../common/services/dictionary-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss']
})
export class DictionaryComponent implements OnInit {

  constructor(
    private dictionaryService: DictionaryDataService,
    private route: ActivatedRoute,
    private alertService: AlertsService,
  ) { }

  private clubId = this.route.snapshot.paramMap.get('clubId');
  protected dictionaries: IClubDictionaryData[];
  protected dictionaryDetails: IClubDictionaryData;
  public isEdit: boolean = false;

  async ngOnInit() {
    this.dictionaries = await this.dictionaryService.getDictionariesByClub(this.clubId);
    this.dictionaryDetailsClear();
  }

  async onEditStart(dictionary?: IClubDictionaryData) {
    this.isEdit = true;
    if (dictionary) this.dictionaryDetails = dictionary;
    else this.dictionaryDetailsClear();
  }

  async save() {
    if (!this.dictionaryDetails.alias || !this.dictionaryDetails.title || !this.dictionaryDetails.data) return;
    this.dictionaryDetails.clubId = this.clubId;
    const dictionary = await this.dictionaryService.saveDictionary(this.dictionaryDetails);
    if (!dictionary) return;
    this.dictionarySuccessUpdateMessage();
    this.dictionaryDetails = dictionary;
    if (!this.dictionaries.find(m => m.id == dictionary.id))
      this.dictionaries.unshift(dictionary);
  }

  async remove() {
    if (!(await this.dictionaryService.removeDictionary(this.dictionaryDetails))) return;
    this.dictionaryRemoveMessage();
    this.dictionaries = this.dictionaries.filter(m => m.id != this.dictionaryDetails.id);
    this.isEdit = false;
    this.dictionaryDetailsClear();
  }

  async selectDictionary() {
    if (!(await this.dictionaryService.setIsDefaultDictionary(this.dictionaryDetails))) return;
    this.dictionaryDetails.isDefault = true;
    this.dictionarySuccessDefaultMessage();
    this.dictionaries.forEach(m => {
      m.isDefault = false;
      if (m.id == this.dictionaryDetails.id){ m.isDefault = true};
    });
  }

  async clearDefault() {
    const dic = this.dictionaries.find(m => m.id == this.dictionaryDetails.id);
    dic.isDefault = false;
    if (!(await this.dictionaryService.saveDictionary(dic))) return;
    this.dictionaryDetails.isDefault = false;
    this.dictionaries.forEach(m => {
      if (m.id == this.dictionaryDetails.id) m.isDefault = false;
    });
  }

  backToList() {
    this.isEdit = false;
    this.dictionaryDetailsClear();
  }

  private dictionarySuccessUpdateMessage() {
    this.alertService.alert.next({
      type: 'success',
      message: this.dictionaryDetails.id ? 'Словарь обновлен' : 'Словарь добавлен',
      header: 'Успех',
      position: 'top',
      timeout: 2000
    });
  }

  private dictionarySuccessDefaultMessage() {
    this.alertService.alert.next({
      type: 'success',
      message: 'Словарь назначен',
      header: 'Успех',
      position: 'top',
      timeout: 2000
    });
  }

  private dictionaryRemoveMessage() {
    this.alertService.alert.next({
      type: 'success',
      message: 'Словарь удален',
      header: 'Успех',
      position: 'top',
      timeout: 2000
    });
  }

  private dictionaryDetailsClear() {
    this.dictionaryDetails = {
      id: null,
      alias: "",
      clubId: this.clubId,
      title: "",
      data: "",
      isDefault: false
    };
  }
}
