<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание группы</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование группы</span>
    </div>
    <div>
      <a class="content__header-link" (click)="close()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>

    <div class="row">

      <div class="col-xs-12 col-sm-6 group-name">
        <div class="form-group" myScrollTo>
          <div class="box-title">Название</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="name" formControlName="name"
              [ngModel]="Model.name" maxlength="500">
            <label for="name" class="field-validation-error"
              *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
          </div>
        </div>
      </div>







      <div>
        <div id="search-block" *ngIf="Model.id">
          <div class="box-title">Состав группы ({{clients.length}})</div>
          <div class="row">

            <div class="col-md-8 search-box">
              <div class="form-group">
                <input class="input__text input__text--long" autocomplete="off" #searchBox id="search-box"
                  (keyup)="searchClients(searchBox.value)" placeholder="Имя, телефон, email клиента" />
                <ul class="search-box__list" *ngIf="clientSearchList && clientSearchList.length" appClickOutside
                  (clickOutside)="clientSearchList = []">
                  <ng-container *ngFor="let client of clientSearchList">
                    <li class="search-box__list-item" *ngIf="client">
                      {{client.firstName}} {{client.lastName}}
                      <span *ngIf="client.phone">(тел.: {{client.phone}})</span>
                      <button class="btn btn-special" (click)="addToGroup(client)">Добавить в группу</button>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <button *ngIf="searchBox.value.length > 2" class="btn btn-primary btn-small" (click)="createAndAdd()">
                <span class="btn-primary__desc">
                  Создать нового клиента и добавить в группу
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-xs-12" [hidden]="isAdmin">
          <div class="info-text">
            <div>
              Добавление в группу завершено.
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="clients && clients.length">

          <div class="table-responsive">
            <table class="table rowHover">
              <thead>
                <tr>
                  <td class="table-cell table-title-cell">Клиент</td>
                  <td class="table-cell table-title-cell">Телефон</td>
                  <td class="table-cell table-title-cell">Абонементы</td>
                  <td class="table-cell table-title-cell">Исключить</td>
                </tr>
              </thead>
              <tbody *ngIf="clients && clients.length">
                <tr *ngFor="let client of clients">

                  <td class="table-cell">
                    <div class="table-cell__client_wrap">
                      <div class="table-cell__avatar">
                        <div class="table-cell__avatar-default" *ngIf="!client.primaryImageUrl">
                          <span class="sp sp-user"></span>
                        </div>
                        <div class="table-cell__avatar-img" *ngIf="client.primaryImageUrl"
                          [style.background]="'url('+client.primaryImageUrl+')'">
                        </div>
                      </div>
                      <div class="table-cell__avatar-desc" (click)="goToClient(client)">{{getFullName(client)}}
                      </div>
                    </div>
                  </td>

                  <td class="table-cell">
                    <div class="desc field">
                      <span *ngIf="client.phone; else noPhone">{{client.phone}}</span>
                      <ng-template #noPhone>
                        <span class="gray ">[не&nbsp;указано]</span>
                      </ng-template>
                    </div>
                  </td>

                  <td class="table-cell">
                    <div class="desc field">
                      <span class="table-cell__mobile-title">Абонементы</span>
                      <div>
                        <div *ngIf="client.subsciption && client.subsciption.length && getLastSubscription(client.subsciption) !== null">
                          <p>{{ getLastSubscription(client.subsciption).last }}</p>
                          <p class="abonement-dates">
                            <span>{{ getLastSubscription(client.subsciption).startDate }}</span>&nbsp;-&nbsp;
                            <span>{{ getLastSubscription(client.subsciption).endDate }}</span>
                          </p>
                          <p class="abonement-count">
                            <span>{{getLastSubscription(client.subsciption).subscriptionCount}} / {{getLastSubscription(client.subsciption).visitCount >=10000 ? '∞' : getLastSubscription(client.subsciption).visitCount}}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="table-cell table-cell_publish">
                    <button class="btn btn-clear active" (click)="removeUser(client)">
                      <span class="icon-cross">
                        <span class="icon-cross__left"></span>
                        <span class="icon-cross__right"></span>
                      </span>
                    </button>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>









      <!-- <div class="col-xs-12">
        <div class="form-group">
          <br>
          <br>
          <div class="info-text">

            <div [ngClass]="{'info-text__box' : !isNotSaved()}">
              <span class="icon icon-about"></span>
              <span app-key-help [key]="'draftText'"></span>
            </div>

            <div *ngIf="!isNotSaved()">
              <span class="icon icon-about"></span>
              Статус:
              <ng-container *ngIf="isDraft()">Черновик</ng-container>
              <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <ng-container>

          <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isEditAvailable">
            <div class="form-group">
              <div class="row__field">
                <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveText'"></span>
                    <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <!-- TODO: Доработать
              <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <div class="row__field">
                  <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                    <span class="btn-primary__desc">
                      <span app-key-help [key]="'saveDraft'"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div> -->

          <!-- <div class="clearfix hidden-md-up"></div>

            <div class="clearfix hidden-md-up"></div> -->

          <!-- TODO: Доработать
              <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <div class="row__field">
                  <ng-container *ngIf="Model.id != null">
                    <button class="btn btn-primary" (click)="copyEdit()" [disabled]="isSubmited && form.invalid">
                      <span class="btn-primary__desc">
                        <span app-key-help [key]="'saveNew'"></span>
                      </span>
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="clearfix hidden-md-up"></div> -->

          <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isRemoveAvailable && Model.id != null">
            <div class="form-group">
              <div class="row__field">
                <ng-container *ngIf="Model.id != null">
                  <button class="btn btn-link" (click)="delete()">
                    <span app-key-help [key]="'deleteText'"></span>
                    <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>

        </ng-container>

        <div class="clearfix hidden-md-up"></div>

      </div>

  </form>
</div>
