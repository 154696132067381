import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'app/common/services/auth.service';
import { isClubRole } from 'app/common/models/user-info.model';
import { PersistenceService } from 'app/common/services/persistence.service';
import { ApiV2Service } from 'app/common/services/api-v2.service';

export class User {
  name: string;
  pass: string;
  code: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  public _mode: 'pswd' | 'code' = 'code';
  private isCodeRequested = false;
  public get _isAuthStep() { return this._mode == 'pswd' || this.isCodeRequested; }

  public isSubmit: boolean;
  public attemptToSend: boolean;
  private authToken: string;
  user: User = new User();

  constructor(
    private authService: AuthService,
    private router: Router,
    private apiV2Service: ApiV2Service,
    private persistenceService: PersistenceService
  ) { }

  setMode(mode: 'pswd' | 'code') {
    this._mode = mode;
  }

  restore() {
    this.router.navigate(['/restore-password']);
  }

  public async redirectAfterLogin() {
    if (this.authService.isLoggedIn) {
      const userInfo = await this.authService.getUserInfo();

      if (isClubRole(userInfo)) {
        if (!this.persistenceService.checkSheduledRedirect()) {
          const ctx = await this.apiV2Service.context.get();
          const firstClub = ctx.clubs.find(x => x.cityId !== '00000000000000000000000000000000');
          if (firstClub) {
            this.router.navigate([`/home`]);
          } else {
            this.authService.logout();
          }
        }
      } else {
        if (!this.persistenceService.checkSheduledRedirect()) {
          this.router.navigate([`/`]);
        }
      }
    }
  }

  login() {
    if (this.attemptToSend) return;

    this.attemptToSend = true;

    if (this.user.name.length && this.user.pass.length) {
      this.authService.logIn({ emailOrPhone: this.user.name, password: this.user.pass })
      .then(() => {
        this.isSubmit = true;
        this.redirectAfterLogin();
      })
      .catch(() => {
        this.user.name = this.user.pass = '';
        this.attemptToSend = false;
      });
    }
  }

  public _beginEmailRegistration() {
    if (this.attemptToSend) return;

    this.attemptToSend = true;

    if (this.user.name.length) {
      this.authService.beginEmailRegistration(this.user.name)
        .then(({ authToken }) => {
          this.authToken = authToken;
          this.isCodeRequested = true;
        })
        .catch(() => {
          this.user.name = this.user.pass = '';
        })
        .finally(() => {
          this.attemptToSend = false;
        });
    }
  }

  public _completePhoneRegistration() {
    if (this.attemptToSend) return;

    this.attemptToSend = true;

    if (this.user.name.length && this.user.code.length) {
      this.authService.completeEmailRegistration(this.authToken, this.user.code)
        .then(() => {
          this.isSubmit = true;
          this.redirectAfterLogin();
        })
        .catch(() => {
          this.user.code = '';
        });
    }
  }
}
