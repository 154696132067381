<div [class.theme-partner]="isThemePartner" style="display: contents;">
  <app-global-preloader></app-global-preloader>
  <app-alerts></app-alerts>

  <app-header class="block desktop" *ngIf="authService.isLoggedIn" [isThemePartner]="isThemePartner"></app-header>
  <app-aside
    [isOpen]="isOpen"
    (updateOpen)="isOpen = false"
    (swipeleft)="showNav($event)"
    [class.open]="isOpen"
    *ngIf="authService.isLoggedIn"
    [style.transition]="isResize ? 'none' : ''"></app-aside>
  <div #asideBtn (click)="isOpen = !isOpen;" class="aside-btn" [class.burger-open]="isOpen" [class._hide]="isLogin">
    <div class="aside-btn__line"></div>
    <div class="aside-btn__line"></div>
    <div class="aside-btn__line"></div>
  </div>
  <div class="overlay"
       (click)="isOpen = !isOpen"
       (swipeleft)="showNav($event)"
       [class.showOverlay]="isOpen"></div>
  <div (swiperight)="showNav($event)"
       id="app_container"
       [class.app_container]="!!authService.isLoggedIn" [style.touch-action] = "!authService.isLoggedIn ? 'pan-y' : ''">
    <!-- TODO: если включить мобильный header, то запросы дублируются -->
    <!--  <app-header class="block mobile" *ngIf="authService.isLoggedIn"></app-header>-->
    <div [class.login-page]="!authService.isLoggedIn" class="app_container__content {{componentClass}} wrapper">
      <div class="wrap">
        <div class="stage">
          <router-outlet></router-outlet>
        </div>
        <div class="support" *ngIf="isFeedbackVisible && authService.isLoggedIn">
          <app-support></app-support>
        </div>
      </div>
    </div>
    <!-- <app-footer class="block mobile" *ngIf="authService.isLoggedIn"></app-footer> -->
  </div>
  <div class="clearfix"></div>
  <app-footer class="block" *ngIf="authService.isLoggedIn" [isThemePartner]="isThemePartner"></app-footer>
  <app-footer-login class="block" *ngIf="!authService.isLoggedIn"></app-footer-login>
</div>
