import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { EditItemComponent } from 'app/common/edit-item.component';
import { IGroupChat, MemberCategory } from 'app/common/models/chat.models';
import { GroupsService } from '../groups/services/groups.service';
import { ClientsService } from '../clients/services/clients.service';
import { ChatEditService } from './services/chat-edit.service';
import { ChatService } from './chats.service';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-chat-edit',
  animations: [
    trigger('enterTrigger', [
      state('moveLeft', style({ transform: 'translateX(0%)' })),
      transition('void => moveLeft', [ style({ transform: 'translateX(100%)' }), animate('200ms') ]),
      state('moveRight', style({ transform: 'translateX(0%)' })),
      transition('void => moveRight', [ style({ transform: 'translateX(-100%)' }), animate('200ms') ]),
      state('init', style({ })),
      transition('void => init', [ ]),
    ])
  ],
  templateUrl: './chat-edit.component.html',
  styleUrls: ['./chat-edit.component.scss']
})
export class ChatEditComponent extends EditItemComponent<IGroupChat> {
  public static readonly componentName: string = 'ChatEditComponent';

  public groupTypeOptions = { group: 'общий', mailing: 'рассылка' };
  searchBoxValue: string;
  public groups: GroupModel[];
  memberSelectMode: boolean = false;
  members: MemberModel[];
  private clients: { id: string, userId: string, memberName: string, phone: string, groups: string[] }[];
  public admins: { unchecked?: boolean, members: AdminModel[] } = { members: [] };

  @ViewChild('searchBox') private searchBoxRef: ElementRef;
  memberSearchList: MemberModel[] = [];
  memberSearchMode: boolean = false;

  private virtualGroups = {
    clientsClub: 'Все клиенты',
    clientsWithActiveSubscription: 'Клиенты с действующими абонентами',
    clientsWithExpiredSubscription: 'Клиенты с истекшими абонентами'
  };

  constructor(
    protected groupService: GroupsService,
    protected clientService: ClientsService,
    protected chatService: ChatService,
    protected service: ChatEditService,
    protected injector: Injector
  ) {
    super(injector, service);
  }

  public modelTemplate(): Promise<IGroupChat> {
    const result: IGroupChat = {
      chat: undefined,
      id: null,
      title: null,
      groupType: 'group',
      isReadOnly: false,
      groupsIds: [],
      groupsCategories: [],
      excludedUsersIds: [],
      fixedUsersIds: [],
      adminsIds: [],
      members: [],
    };

    return Promise.resolve(result);
  }

  public buildForm() {
    this.form = this.formBuilder.group({
      title: [this.Model.title, [NotEmpty()]],
      groupType: [this.Model.groupType, [NotEmpty()]],
      isReadOnly: [this.Model.isReadOnly],
    });
  }
  fieldMessages = {
    title: {
      NotEmpty: 'Это поле обязательно',
    },
  };

  public afterModelInit() {
    this.getEntities()
      .then(() => {
        if (this.Model && this.Model.chat) {
          this.Model.id = this.Model.chat.id;
          this.Model.title = this.Model.chat.title;
          this.Model.groupType = this.Model.chat.groupType === 'notGroup' ? undefined : this.Model.chat.groupType;
          this.Model.isReadOnly = this.Model.chat.isReadOnly;
          this.groups.forEach(a => {
            a.attached = this.Model.groupsIds.indexOf(a.id) >= 0 || this.Model.groupsCategories.indexOf(a.category) >= 0;
          });
          this.members = this.Model.members
            .map(a => {
              const client = this.clients.find(b => b.userId == a.userId);
              return {
                ...client,
                groups: this.getGroups(client.groups),
                attached: a.detachedAt == null, //this.groups.some(b => b.attached && client.groups.indexOf(b.id) >= 0),
                fixed: !a.isGroupMember,
              };
            })
            .sort((a, b) => a.memberName.localeCompare(b.memberName));
        }
      });
  }

  public finishEdit() {
    if (this.Model.groupType != 'mailing')
      this.Model.isReadOnly = false;
    this.Model.groupsIds = this.groups
      .filter(x => x.attached && !x.category).map(x => x.id);
    this.Model.groupsCategories = this.groups
      .filter(x => x.attached && x.category).map(x => x.category);
    this.Model.excludedUsersIds = this.members
      .filter(a => a.fixed && !a.attached)
      .map(a => a.userId);
    this.Model.fixedUsersIds = this.members
      .filter(a => a.fixed && a.attached)
      .map(a => a.userId);
    // this.Model.excludedUsersIds = this.groups
    //   .filter(x => x.attached && x.members)
    //   .map(x => x.members.map(x => x.userId))
    //   .reduce((p, c) => p.concat(c), [])
    //   .filter(x => this.members.some(y => y.userId === x && !y.attached));
    super.finishEdit();
  }

  public _close() {
    var url = this.chatService.redirectUrl || this.chatService.pendingUrl.slice(0, this.chatService.pendingUrl.length-2).reduce((x, y) => x + `/${y}`, '');
    this.router.navigate([url]);
  }

  enterTriggerState: string = 'init';

  selectedTabIndex: number = 0;
  selectedTabChanged(event: MatTabChangeEvent) {
    this.enterTriggerState = this.selectedTabIndex < event.index ? 'moveLeft' : 'moveRight';
    event.index === 2
      && this.members.forEach(x => {
        x.groups = this.getGroups(this.clients.find(a => a.userId == x.userId)?.groups);
        // x.fixed = !x.isGroupMember;
      });
    this.selectedTabIndex = event.index;
    // if (!this.members.length) {
    //   let testClient = {
    //     firstName: 'Гумберт Розенкранц',
    //     lastName: 'Лерой Максимилиан',
    //     phone: '+375 (000) 900 10 01',
    //     groups: 'чемпионы пятижорья, мастера котлетики',
    //     isAdmin: true,
    //   } as any;
    //   this.members.push(testClient);
    //   this.members.push(Object.assign({}, testClient, { attached: true }));
    //   this.members.push(Object.assign({}, testClient));
    // }
  }

  getAttachedMembers() {
    return this.members.filter(a => a.groups || a.attached);
  }

  isMemberAttached(userId: string) {
    return this.members.find(a => a.userId == userId)?.attached;
  }

  getAdmins() { return this.members.filter(x => x.isAdmin); }

  searchFormClickOutside(event) {
    if (this.searchBoxRef.nativeElement === event.target)
      this.memberSearchMode = !this.memberSearchMode;
    else if (event.isInside === false)
      this.memberSearchMode = false;
  }

  async searchMembers() {
    const pattern = this.searchBoxValue.trim();

    if (pattern.length < 2) {
      this.memberSearchList = [];
      return;
    } else {
      let resp = await this.clientService.searchClients(pattern);
      let members = [...resp.clients ];
      members.forEach(x => {
        x.groups = this.getGroups(x.userId);
        x.attached = this.members.find(y => y.userId === x.userId)?.attached;
      });
      this.memberSearchList = members;
      //this.memberSearchList.forEach(x => x.getGroups = x.getGroups.bind(undefined, this.groups))
    }
    this.memberSearchMode = true;
  }

  /*private searchEmploee(pattern: string): AccountModel[] {
    let employees: any[];
    if (pattern.match(/[а-я]+/i))
      employees = this.employees.filter(x => x.firstName.includes(pattern) || x.lastName.includes(pattern));
    else if (pattern.includes('@'))
      employees = this.employees.filter(x => x.email.includes(pattern));
    else {
      let hasNum = pattern.match(/[0-9]+/i);
      let hasLat = pattern.match(/[a-z]+/i);
      if (!hasNum && hasLat) {
        employees = this.employees.filter(x => x.firstName.includes(pattern) || x.lastName.includes(pattern));
        if (!employees.length)
          employees = this.employees.filter(x => x.email.includes(pattern));
      }
      else if (hasNum && !hasLat)
        employees = this.employees.filter(x => x.phoneNumber.includes(pattern));
      else
        employees = this.employees.filter(x => x.email.includes(pattern));
    }
    return employees.map(x => Object.assign(x, { userId: x.id, phone: x.phoneNumber }) as AccountModel);
  }*/

  attachMember(member: MemberModel) {
    // Сбрасываем все параметры поиска
    [ this.searchBoxRef.nativeElement.value, this.memberSearchMode, this.memberSearchList ] = [ '', false, [] ];

    let existed = this.members.find(x => x.userId === member.userId);
    if (existed) {
      existed.attached = true;
      existed.fixed = true;
    }
    else {
      const client = this.clients.find(a => a.userId == member.userId);
      member.attached = true;
      member.fixed = true;
      member.groups = this.getGroups(client?.groups);
      member.memberName = client.memberName;
      this.members.push(member);
      this.members = this.members.sort((a, b) => a.memberName.localeCompare(b.memberName));
    }
    // this.groups.forEach(x => x.members.forEach(this.switchClient.bind(client)));
    // this.clients.forEach(this.switchClient.bind(client));
  }

  detachMember(member: MemberModel) {
    // Сбрасываем все параметры поиска
    [ this.searchBoxRef.nativeElement.value, this.memberSearchMode, this.memberSearchList ] = [ '', false, [] ];

    member.attached = false;
    let ind = this.members.findIndex(x => x.userId === member.userId);
    if (ind < 0) {
      // member.groups = this.getGroups(member.userId);
      this.members.push(member);
    }
    else if (!this.members[ind].groups) {
      this.members.splice(ind, 1);
    }
  }

  detachChecked() {
    this.memberSelectMode = false;
    this.members = this.members.filter(a => !a.checked || a.groups);
    this.members.filter(x => x.checked).forEach(x => { x.checked = false; x.fixed = true; x.attached = false; });
  }

  restoreChecked() {
    this.memberSelectMode = false;
    this.members.filter(x => x.checked).forEach(x => { x.checked = false; x.groups && (x.fixed = false); x.attached = true; });
  }

  assignAdminChecked() {
    this.memberSelectMode = false;
    this.members.filter(x => x.checked).forEach(x => { x.checked = false; x.isAdmin = !x.isAdmin; });
  }

  onGroupChange(event: Event) {
    let id = event.target['id'].substr(2);
    let group = this.groups.find(x => x.id === id);
    group && (group.checked = event.target['checked']);
  }

  isAnyGroupChecked() { return this.groups.some(x => x.checked); }
  getCheckedGroupsCount() { return this.groups.filter(x => x.checked).length; }
  // getCheckedGroups() { return this.groups.filter(x => x.checked); }
  // getUncheckedGroups() { return this.groups.filter(x => !x.checked); }
  isAnyGroupCheckedOrAttached() { return this.groups.some(x => x.checked || x.attached) }
  isAnySpecialGroupCheckedOrAttached() { return this.groups.some(x => (x.checked || x.attached) && x.category); }
  checkGroup(group: GroupModel) { group.checked = !group.checked; }
  attachChecked(groups: GroupModel[]) {
    groups.filter(x => x.checked).forEach(x => {
      x.checked = false;
      x.attached = true;
      this.clients
        .filter(a => a.groups && a.groups.indexOf(x.id) >= 0)
        .forEach(a => {
          let existed = this.members.find(x => x.userId === a.userId);
          if (existed) {
            existed.attached = true;
          }
          else {
            let member = Object.assign({
              attached: true,
              fixed: false,
              groups: this.getGroups(a.groups),
            }, a);
            this.members.push(member);
            this.members = this.members.sort((a, b) => a.memberName.localeCompare(b.memberName));
          }
        });
    });
  }
  detachGroup(group: GroupModel) {
    group.attached = false;
    let excl = this.clients
      .filter(a => a.groups && a.groups.indexOf(group.id) >= 0)
      .map(a => {
        let existed = this.members.find(x => x.userId === a.userId);
        existed.groups = this.getGroups(a.groups);
        return existed.fixed || this.groups.some(b => a.groups.indexOf(b.id) >= 0 && b.attached) ? null : a.userId;
      });
    this.members = this.members.filter(a => excl.indexOf(a.userId) < 0);
  }

  getAttachedGroups() { return this.groups.filter(x => x.attached); }

  isAnyMemberChecked() { return this.members.some(x => x.checked); }
  // getCheckedMembersCount() { return this.members.filter(x => x.checked).length; }
  checkMember(member: MemberModel) { member.checked = !member.checked; }
  deleteRule(member: MemberModel) { this.members = this.members.filter(x => x.userId !== member.userId); }

  // changeClients() { this.clients.unchecked = !this.clients.unchecked; }
  // changeAdmins() { this.admins.unchecked = !this.admins.unchecked; }

  changeEmploye(employe: AccountModel) { employe.checked = !employe.checked; }

  // changeClient(client: { unchecked: boolean, id: string }, group: GroupModel)
  // {
  //   client.unchecked = !client.unchecked;
  //   this.groups.forEach(x => x.members.forEach(this.switchClient.bind(client)));
  //   if (!group || client.unchecked)
  //     this.clients.forEach(this.switchClient.bind(client));
  // }

  dismissAdmin(member: AdminModel) {
    member.isAdmin = !member.isAdmin;
  }

  // private switchClient(value: ClientModel) {
  //   const newValue = (this as any) as ClientModel;
  //   value.userId === newValue.userId && (value.unchecked = newValue.unchecked);
  // }

  updateGroupType(event) {
    console.log(event);
  }

  getGrouppedClients() {
    let grouppedClients = this.groups
      .filter(x => x.checked)
      .map(x => x.members);
    return grouppedClients.length ? grouppedClients.reduce((p, c) => [ ...p, ...c ]) : [];
  }

  goToClient(client) {
    const url = this.contextService.makeContextUrl(`clubclients/${client.id}`);
    this.router.navigate([url]);
  }

  goToEmploye(employe) {
    const url = this.contextService.makeContextUrl(`clubusers/${employe.id}`);
    this.router.navigate([url]);
  }

  private async getEntities() {
    let resp = await this.chatService.requestEntities();
    // let resp0 = await this.groupService.getItemList() as GroupModel[];
    // if (!resp0)
    //   return;
    // for (let i = 0; i < resp0.length; i++) {
    //   resp0[i].members = await this.clientService.getByGroup(resp0[i].id);
    //   if (!resp0[i].members)
    //     continue;
    //     resp0[i].members = resp0[i].members;
    //   // if (resp0[i].checked)
    //     // resp0[i].members.forEach(x => x.unchecked = this.Model.excludedUsersIds.indexOf(x.userId) > -1);
    // }
    // resp0.forEach(x => x.attached = this.Model.groupsIds.indexOf(x.id) > -1 || this.Model.groupsCategories.indexOf(x.memberCategory) > -1);
    resp.clientGroups
      .filter(a => !a.id)
      .forEach(a => { a.id = a.category[0].toUpperCase() + a.category.slice(1, a.category.length); a.name = this.virtualGroups[a.category] });
    this.groups = resp.clientGroups;
    this.clients = resp.clients;
    this.members = [];
    // this.members = this.Model.members && this.Model.members.length
    //   ? this.Model.members.map(x => ({ ...x, attached: x.detachedAt === null, isGroupMember: x.isGroupMember, primaryPhotoUrl: undefined }))
    //   : [];
  }

  private getGroups(groupsIds: string[]): string {
    if (!groupsIds || !groupsIds.length)
      return '';
    let ref = this.groups
      .filter(x => x.attached && groupsIds.indexOf(x.id) >= 0)
      .map(x => x.name);
    return ref.length ? ref.reduce((p, c) => p + ', ' + c) : '';
  }
}


class AccountModel {
  checked?: boolean;
  isAdmin?: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

class GroupModel {
  checked?: boolean;
  attached?: boolean;
  id: string;
  name: string;
  members?: MemberModel[];
  disabled?: boolean;
  category?: MemberCategory;
}

class MemberModel {
  checked?: boolean;
  isAdmin?: boolean;
  // id: string;
  // firstName: string;
  // lastName: string;
  memberName: string;
  phone: string;
  // email: string;
  // birthday: string;
  userId: string;
  systemRegisteredAt?: string;
  primaryPhotoUrl?: string;

  isGroupMember?: boolean;
  groups?: string;
  attached?: boolean;
  fixed?: boolean;

  // getGroups() {
  //   return this.id;
  // }
}

class AdminModel {
  isAdmin?: boolean;
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
}
