import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-option-selector-radio',
  templateUrl: './option-selector-radio.component.html',
  styleUrls: ['./option-selector-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionSelectorRadioComponent ),
      multi: true
    }
  ]
} )


export class OptionSelectorRadioComponent implements OnInit, ControlValueAccessor {
  @Input() options;
  private data = 'day';

  public _options = [];

  constructor() { }

  private propagateChange = ( _: any ) => { };

  public registerOnChange ( fn: any ) {
    this.propagateChange = fn;
  }

  public registerOnTouched () { }

  public writeValue ( obj: any ) {
    if ( obj ) {
      this.data = obj;
    }
  }

  public onChange ( event ) {
    this.writeValue( event );
    this.propagateChange( this.data );
  }

  ngOnInit () {
    for ( const key in this.options ) {
      this._options.push( { key: key, value: this.options[ key ] } );
    }
  }

}
