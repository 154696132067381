import { IIdName } from "./id-name.model";
import * as buffer from 'buffer';

export interface IWidgetModel {
    id: string;
    entityStatus: string;
    name: string;
    widgetType: string;
    settings: IScheduleSettings;
    clubId: string;
    widgetThemeId: string;
    useForVk: boolean;
    dependenceWidgetId?: string;
}

export interface IThemeModel {
    id: string;
    entityStatus: string;
    name: string;
    widgetType: string;
    style: string;
    settings: any;
    clubId: string;
    widgetsCount?: number;
}

export interface IScheduleSettings {
    showRoomFilter: boolean;
    showGroupFilter: boolean;
    showCoursesFilter: boolean;
    showCoachesFilter: boolean;
    showTrainingCategoryFilter: boolean;
    showLevelFilter: boolean;
    showClubNet: boolean;
    showComments: boolean;
    showOccupationLevel: boolean;
    roomId: string;
    disableSubscribeOnClass: boolean;
    showBookedFree: string;
    activeDisplayType: string;
    showBirthday: boolean;
    scheduleWidgetId?: string;
    dictionaryAlias?: string;
    culture?: string;
    disableRoom: boolean;
    surnameIsRequired: boolean;
    singleEntryForm: boolean;

    advancedFilter?: IAdvancedFilter;
    hidePaymentForm?: boolean;
    hideSbp?: boolean;
    showBranch?: boolean;
    description?: string;
    privacyPolicy?: string;
    isFillComment?: boolean;
    placeholderComment?: string;

    showAuthGoogle?: boolean;
    showAuthApple?: boolean;
    showAuthFacebook?: boolean;
    showAuthVK?: boolean;
    showAuthEmail?: boolean;
    showAuthPhone?: boolean;
    showAuthPush?: boolean;
    showAuthLoginAndPassword?: boolean;
    showInApp?: boolean;
    isYouCanQueue?: boolean;
    visibilityDescSub: VisibilityDescriptionSub;
    availableInfo: AvailableInfo

    courseId?: string;
    rooms?: any[];
    TimeMatrix?: any;
    split30Minutes?: boolean;
    isOnlyAuth?: boolean;
    minS?: number;
    maxS?: number;
    message: string;

    isProhibitChangingTheSubscriptionPrice?: boolean;
    isRemoveCustomPayment?: boolean;

    isHideCellsWithoutClasses: boolean;
}

export interface IAdvancedFilter {
  name: string;
  ids: string[];
  clubSchedules?: IIdName[];
}

export enum WidgetType {
    schedule = 'schedule',
    subscriptions = 'subscriptions',
    rooms = 'rooms',
    coaches = 'coaches',
    courses = 'courses',
    payment = 'payment',
    scheduleFilter = 'scheduleFilter',
    certificates = 'certificates',
    purchaseSubscription = 'purchaseSubscription',
    rent = 'rent'
}

export enum VisibilityDescriptionSub {
  HideEveryone,
  Desktop,
  Mobile,
  ShowEveryone
}

export enum AvailableInfo {
  Everyone,
  Registered,
  Anonymous
}
