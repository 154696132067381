<header class="content__header">
  <div class="content__header-title" [ngSwitch]="paramId">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="'create'">Создание подборки</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование подборки</span>
    </div>
  </div>
</header>
<form [formGroup]="form">
  <div class="content__body" [ngSwitch]="paramId">
    <div class="row">
      <ng-container>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="box-title">Название подборки</div>
            <div class="row__field">
              <ng-container>
                <mat-form-field>
                  <input class="form-control input__text input__text--long" matInput type="text" formControlName="name">
                  <mat-error *ngIf="form.get('name').hasError('required')">Это поле обязательно</mat-error>
                </mat-form-field>
              </ng-container>
            </div>
            <div class="row__field mt-4">
              <input type="checkbox" class="checkbox__slide" id="isTitleVisibility" formControlName="isTitleVisibility">
              <label for="isTitleVisibility" class="checkbox__slide_label">Показывать заголовок подборки в приложении</label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="box-title">Город</div>
            <div class="row__field">
              <app-select-search [data]="cities" [defaultValue]="form.get('cityId').value" (onSelectionChange)="onCityChange($event)" [multiple]="false" [hintMessage]="'Клубы будут отфильтрованы согласно выбранному городу'"></app-select-search>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="box-title">Партнёр</div>
            <div class="row__field">
              <ng-container>
                <mat-form-field>
                  <input class="form-control input__text input__text--long" matInput type="text" formControlName="partnerAlias">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <ng-container *ngIf="form.get('cityId').value != ''">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div *ngIf="collection" style="margin-bottom: 10px;">Тип подборки: <b>{{form.value.type|collectionsTypes}}</b></div>
            <div class="form-group" *ngIf="!collection">
              <div class="box-title">Тип подборки</div>
              <div class="row__field">
                <ng-container>
                  <mat-form-field>
                    <mat-select formControlName="type" (ngModelChange)="typeChange()">
                      <mat-option *ngFor="let type of types" [value]="type.value">
                        {{type.viewValue}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('type').hasError('required')">Это поле обязательно</mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
          <ng-container *ngIf="isShowFields(['clubs', 'courses', 'subscriptions'])">
            <div class="col-xs-12">
              <app-collection-data [type]="form.get('type').value" [clubs]="clubs" [city]="getCityObj()" (result)="setData($event)" [data]="data"></app-collection-data>
            </div>
          </ng-container>
          <ng-container *ngIf="isShowFields(['conditionSearch', 'conditionImages', 'conditionButtons'])">
            <div class="col-xs-12">
              <div class="form-group">
                <div class="box-title">Json</div>
                <div class="row__field">
                  <ng-container>
                    <textarea formControlName="dataJson" (ngModelChange)="changeDataJson($event)"></textarea>
                    <label *ngIf="isJsonValid != null && !isJsonValid" class="field-validation-error ng-star-inserted">Ошибка: невалидный json</label>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isShowFields(['slide', 'buttonCards'])">
            <div class="col-xs-12">
              <app-collections-slide (result)="setData($event)" [typeCollection]="collection?.type" [data]="data"></app-collections-slide>
            </div>
          </ng-container>
          <div class="clearfix"></div>
          <hr />
          <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="form.valid">
            <div class="form-group">
              <div class="row__field">
                <ng-container>
                  <br>
                  <br>
                  <button class="btn btn-primary btn-small" (click)="save(EntityStatus.published)">
                    <span class="btn-primary__desc">Сохранить</span>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="form.valid">
          <div class="form-group">
            <div class="row__field">
              <ng-container>
                <br>
                <br>
                <button class="btn btn-primary btn-small" (click)="save(EntityStatus.draft)">
                  <span class="btn-primary__desc">Сохранить как черновик</span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
