import { Injectable } from '@angular/core';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { IGroupChat } from 'app/common/models/chat.models';
import { IPagedList } from 'app/common/models/common.models';

@Injectable()
export class ChatEditService extends TableListService<IGroupChat> {
  getItemList(_?: any, __?: number, ___?: number): Promise<any[] | IPagedList<any>> {
    throw new Error("Method not implemented.");
  }

  getSingleItem(req: IRoutingParams): Promise<IGroupChat> {
    return this.apiV2Service.chats.getChat(req.id, this.routingParams.cityId, this.routingParams.clubId);
  }

  save(model: IGroupChat): Promise<any> {
    return this.apiV2Service.chats.save({
      id: model.id,
      groupType: model.groupType,
      isReadOnly: model.isReadOnly,
      title: model.title,
      groupsIds: model.groupsIds,
      groupsCategories: model.groupsCategories,
      excludedUsersIds: model.excludedUsersIds,
      usersIds: model.fixedUsersIds,
      adminsIds: model.adminsIds
    });
  }

  create(model: IGroupChat): Promise<any> {
    return this.apiV2Service.chats.save({
      clubId: this.routingParams.clubId,
      groupType: model.groupType,
      isReadOnly: model.isReadOnly,
      title: model.title,
      groupsIds: model.groupsIds,
      groupsCategories: model.groupsCategories,
      excludedUsersIds: model.excludedUsersIds,
      usersIds: model.fixedUsersIds,
      adminsIds: model.adminsIds
    });
  }

  delete(_: IGroupChat): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
