<ng-container style="display: block" *ngIf="!element?.isEnableMode">
  <td class="table-cell">
    <span class="table-cell__mobile-title">Контакт</span>
    <span [innerHTML]="element.contact" class="cell-value"></span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">ФИО</span>
    <span class="cell-value">{{element.fullName}}</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Коментарий</span>
    <span class="cell-value">{{element.comment}}</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Дата</span>
    <span class="cell-value">{{element.date}}</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Сумма</span>
    <span class="cell-value" [class.warning]="element.amount >= 5000 && element.amount < 10000" [class.warning2]="element.amount >= 10000">{{element.amount}} руб.</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Бонусы</span>
    <span class="cell-value">{{element.bonuses}} руб.</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Город</span>
    <span class="cell-value">{{element.city}}</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Клуб</span>
    <span class="cell-value">{{element.club}}</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Банк</span>
    <span class="cell-value">{{element.bankName}}</span>
  </td>
  <td class="table-cell">
    <span class="table-cell__mobile-title">Комиссия</span>
    <span class="cell-value">{{element.commission}}</span>
  </td>
</ng-container>
<ng-container style="display: block;" *ngIf="element?.isEnableMode">
  <td class="table-cell" colspan="9">
    <form [formGroup]="form">
      <div class="row">
        <ng-container>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Контакт</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="contact" type="text"
                  formControlName="contact" />
                <label class="field-validation-error"
                  *ngIf="form.get('contact').touched && form.get('contact').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">ФИО</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="fullName" type="text"
                  formControlName="fullName" />
                <label class="field-validation-error"
                  *ngIf="form.get('fullName').touched && form.get('fullName').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Комментарий</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="comment" type="text"
                  formControlName="comment" />
                <label class="field-validation-error"
                  *ngIf="form.get('comment').touched && form.get('comment').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Дата</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="date" type="text"
                  placeholder="В формате дд.мм.гггг" formControlName="date" />
                <label class="field-validation-error" *ngIf="form.get('date').touched && form.get('date').invalid">Это
                  поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Сумма</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="amount" type="number"
                  formControlName="amount" />
                <label class="field-validation-error"
                  *ngIf="form.get('amount').touched && form.get('amount').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Бонусы</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="bonuses" type="number"
                  formControlName="bonuses" />
                <label class="field-validation-error"
                  *ngIf="form.get('bonuses').touched && form.get('bonuses').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Комиссия</div>
              <div class="row__field">
                <input class="form-control input__text input__text--long" name="commission" type="number"
                  formControlName="commission" />
                <label class="field-validation-error"
                  *ngIf="form.get('commission').touched && form.get('commission').invalid">Это поле обязательно</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 param-item ng-star-inserted">
            <div class="form-group">
              <div class="box-title">Статус</div>
              <div class="row__field">
                <ng-container>
                  <select formControlName="status" class="select select__long" name="status">
                    <option [value]="'published'">Опубликовано</option>
                    <option [value]="'deleted'">Удалено</option>
                    <option [value]="'hold'">Корректировка</option>
                    <option [value]="'mutualSettlement'">Взаиморасчёты</option>
                    <option [value]="'refund'">Возврат</option>
                  </select>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="form-group btn-save">
              <button class="btn btn-primary btn-small" (click)="save()" [disabled]="form.invalid">
                <span class="btn-primary__desc">Сохранить</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </td>
</ng-container>
