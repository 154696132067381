<div class="" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit" *ngIf="!isReadonly">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>

  <div class="row__field">
    <div [hidden]="enableEdit">
      <div class="field" (click)="onBeginEdit()">
        <div class="gray" *ngIf="!tempData.payByCash && !tempData.payByCards">[не указано]</div>
        <div class="dark-gray" *ngIf="tempData.payByCash">Оплата наличными</div>
        <div class="dark-gray" *ngIf="tempData.payByCards">Оплата картой</div>
        <div class="dark-gray" *ngIf="tempData.payByBank">Оплата через банк</div>
      </div>
    </div>
    <div [hidden]="!enableEdit">
      <div class="js-scroll-target payment-box">
        <div class="payment-box__item">
          <input [(ngModel)]="tempData.payByCash" type="checkbox" class="checkbox__slide" id="payByCash">
          <label class="checkbox__slide_label" for="payByCash">Оплата наличными</label>
        </div>
        <div class="payment-box__item">
          <input [(ngModel)]="tempData.payByCards" type="checkbox" class="checkbox__slide" id="payByCards">
          <label class="checkbox__slide_label" for="payByCards">Оплата картой</label>
        </div>
        <div class="payment-box__item">
          <input [(ngModel)]="tempData.payByBank" type="checkbox" class="checkbox__slide" id="payByBank">
          <label class="checkbox__slide_label" for="payByBank">Оплата через банк</label>
        </div>
      </div>
    </div>
  </div>
</div>
