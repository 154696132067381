import { Component, Injector } from '@angular/core';
import { IClubEventModel } from '../../common/models/club-events.model';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { ClubEventService } from './club-events.service';

@Component({
  selector: 'app-club-events',
  templateUrl: './club-events.component.html',
  styleUrls: ['./club-events.component.scss']
})
export class ClubEventsComponent extends TableListComponent<IClubEventModel> {
  public static readonly componentName: string = 'ClubEventsComponent';

  constructor(injector: Injector, protected service: ClubEventService) {
    super(injector, service);
  };

  onModelLoadCallback() {
    this.Model = (this.Model as IClubEventModel[]).sort((a, b) => b.timeFrom - a.timeFrom);
  }

  _ticketTypes: { value: string, name: string }[] = [
    { value: 'participant', name: 'Участник' },
    { value: 'visitor', name: 'Зритель' },
  ];

  _getTicketCountsTypes(ticketCounts?: { [index: string]: number }) {
    return ticketCounts ? Object.keys(ticketCounts) : [];
  }

  _mapTicketCounts(ticketCounts?: { [index: string]: number }) {
    return this._ticketTypes.map(t => ticketCounts ? ticketCounts[t.value] ?? 0 : 0);
  }

  // protected hkAddPress(that: this) {
  //   if (this.isEditAvailable) {
  //     return super.hkAddPress(that);
  //   } else {
  //     return () => { return true };
  //   }
  // }
}
