<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Виды занятий
    </span>
  </div>
</header>


<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="courses-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">
              Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
          <tr>
            <td class="table-cell table-title-cell">Название</td>
            <td class="table-cell table-title-cell">Описание</td>
            <td class="table-cell table-title-cell">Направление</td>
            <td class="table-cell table-title-cell">Активно</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let course of Model' (click)="onEditStart(course)" (mousedown)="onEditStart(course,$event)">
            <td class="table-cell table-cell_name">
              <span class="table-cell__mobile-title">Название</span>
              <div class="course-name_wrap">
                <span class="course-name"><b>{{course.name}}</b></span>
                <span class="color" [style.background]="course.color"></span>
              </div>
            </td>
            <td class="table-cell">
              <span class="table-cell__mobile-title">Описание</span>
                <span *ngIf="course.shortDescription; else noDescription">{{course.shortDescription}}</span>
                <ng-template #noDescription><span class="gray ">[не&nbsp;указано]</span></ng-template>
            </td>
            <td class="table-cell">
              <span class="table-cell__mobile-title">Направление</span>
                <span *ngIf="course.group; else noGroup">{{course.group}}</span>
                <ng-template #noGroup><span class="gray ">[не&nbsp;указано]</span></ng-template>
            </td>
            <td class="table-cell table-cell_publish" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
              <span class="table-cell__mobile-title">Активно</span>
              <app-status-picker [title]="null" [ngModel]="course.entityStatus"
                                 (ngModelChange)="setStatus($event, course)"></app-status-picker>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </ng-container>

</div>
