import { Validators } from '@angular/forms';
import { Component, Injector, ViewChild } from '@angular/core';

import { GroupsService } from 'app/components/groups/services/groups.service';
import { IGroupModel } from 'app/common/models/group.model';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { EditItemComponent } from 'app/common/edit-item.component';
import { EntityStatus } from 'app/common/models/entity-status.model';

import { IClientModel } from 'app/common/models/client.model';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { AccountStatus } from 'app/common/models/account-status.model';
import * as moment from 'moment';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss'],
})
export class GroupEditComponent extends EditItemComponent<IGroupModel> {
  public static readonly componentName: string = 'GroupEditComponent';

  public clients: Array<IClientModel> = [];
  public selectedClient;
  public clientSearchList = [];

  @ViewChild('searchBox') searchBox;

  fieldMessages = {
    name: {
      NotEmpty: 'Это поле обязательно',
    }
  };

  constructor(
    injector: Injector,
    protected service: GroupsService,
    private clientsService: ClientsService
  ) {
    super(injector, service);
  }

  afterModelInit() {
    if (this.Model.id) {
      this.getClients(this.Model.id);
    }
  }

  getClients(groupId) {

    this.clientsService.getByGroup(groupId)
      .then(res => {
        this.clients = res;
      });
  }

  modelTemplate(): Promise<IGroupModel> {
    const res: IGroupModel = {
      name: null,
      entityStatus: EntityStatus.draft,
      id: null,
      clubId: this.contextService.getRoutingParams().clubId
    };
    return Promise.resolve(res);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      entityStatus: [this.Model.entityStatus, [Validators.required]]
    });
  };

  searchClients(input) {
    this.selectedClient = null;
    const pattern = input.trim();

    if (pattern.length < 2) {
      this.clientSearchList = [];
      return;
    } else {
      this.clientsService.searchClients(pattern)
        .then(res => {
          this.clientSearchList = [...res.clients, ...res.users];
        });
    }
  }

  createAndAdd() {
    const nameArr = this.searchBox.nativeElement.value.split(' ');

    if (!nameArr.length) {
      return;
    }

    const clientModel: IClientModel = {
      id: null,
      entityStatus: EntityStatus.published,
      firstName: null,
      lastName: null,
      fullName: null,
      gender: null,
      phone: null,
      email: null,
      primaryPhotoUrl: null,
      birthday: null,
      updatedAt: null,
      clubRegisteredAt: null,
      systemRegisteredAt: null,
      description: null,
      userId: null,
      accountStatus: AccountStatus.created
    };

    clientModel.firstName = nameArr[0];

    if (nameArr.length > 1) {
      clientModel.lastName = nameArr[1];
    }

    this.clientsService.create(clientModel)
      .then(res => {
        if (res.userId) {

          clientModel.userId = res.userId;
          clientModel.id = res.id;

          this.addToGroup(clientModel);
        }
      });
  }

  // Добавить пользователя в группу
  addToGroup(client) {
    this.selectedClient = client;
    var userId = this.selectedClient.userId ? this.selectedClient.userId : this.selectedClient.id;

    // Сбрасываем все показатели поиска
    this.searchBox.nativeElement.value = '';
    this.clientSearchList = [];

    this.service.addUser(this.Model.id, userId)
      .then(() => {
        this.getClients(this.Model.id);
      });
  }

  goToClient(client) {
    const url = this.contextService.makeContextUrl(`clubclients/${client.id}`);
    this.router.navigate([url]);
  }

  getFullName(client) {
    return (`${client.firstName || ''} ${client.lastName || ''}`).trim();
  }

  removeUser(client) {
    var userId = client.userId ? client.userId : client.id;

    this.service.removeUser(this.Model.id, userId)
      .then(() => {
        this.getClients(this.Model.id);
      });
  }

  getLastSubscription(subs: any) {
    const correctSubs = subs.filter((s) => s.status === 'created' || s.status === 'active');
    // s.fromDate !== null && s.toDate !== null &&

    if (correctSubs.length > 1) {
      const most_recent = correctSubs.reduce((mostRecent, s) =>
        mostRecent.fromDate !== null && mostRecent.toDate !== null || s.fromDate !== null && s.toDate !== null
          ? correctSubs.filter(sFilter => sFilter.fromDate !== null && sFilter.toDate !== null).reduce((mostRecentReduce, sReduce) =>
            (sReduce.toDate <= mostRecentReduce.toDate && sReduce.purchasedAt > mostRecentReduce.purchasedAt)
              ? sReduce
              : mostRecentReduce)
          : s.purchasedAt > mostRecent.purchasedAt ? s : mostRecent
      );
      const fromDate = most_recent.fromDate ? moment(most_recent.fromDate).utc().format('DD.MM.YYYY') : 'Н/Д'
      const toDate = most_recent.toDate ? moment(most_recent.toDate).utc().format('DD.MM.YYYY') : 'Н/Д'
      return {
        last: most_recent.name,
        startDate: fromDate,
        endDate: toDate,
        visitCount: most_recent.visitCount,
        subscriptionCount: most_recent.consumedCount,
      }
    } else {
      if (correctSubs && correctSubs.length) {
        const fromDate = correctSubs[0].fromDate ? moment(correctSubs[0].fromDate).utc().format('DD.MM.YYYY') : 'Н/Д'
        const toDate = correctSubs[0].toDate ? moment(correctSubs[0].toDate).utc().format('DD.MM.YYYY') : 'Н/Д'
        return {
          last: correctSubs[0].name,
          startDate: fromDate,
          endDate: toDate,
          visitCount:  correctSubs[0].visitCount,
          subscriptionCount:  correctSubs[0].consumedCount,
        }
      } else { return null; }
    }
  }
}
