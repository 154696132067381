import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {AccountingComponent} from '../../components/accounting/accounting.component';

export const accountingRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/accounting',
  component: AccountingComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Финансовый учет'}
};
