import { Component, AfterViewInit, ElementRef, Input, forwardRef, ViewChild, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';


@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextEditComponent),
    multi: true
  }]
})
export class TextEditComponent extends ToggleEditComponent<any> implements AfterViewInit, ControlValueAccessor {
  public controlData;
  public el;
  public date;
  public dateFormatted: string;
  public dateControl: FormControl;
  public dateTime: any;
  public datePickerOpened = false;
  @ViewChild('picker') datePicker: MatDatepicker<any>;
  @Input() placeholder;
  @Input() renderZero;
  @Input() customConfigForCkeditor;

  constructor(
    private elRef: ElementRef,
    injector: Injector
  ) {
    super(injector);
  }

  ngAfterViewInit() {
    this.checkRenderZero();

    this.el = this.elRef.nativeElement.querySelector('.inputField');
    let timer;
    if (this.datePicker) {
      this.datePicker.openedStream.subscribe(() => (this.datePickerOpened = true) && timer && (clearTimeout(timer)));
      this.datePicker.closedStream.subscribe(() => timer = setTimeout(() => this.datePickerOpened = false, 100));
    }
    if (this.type === 'dateTime' && this.tempData.length > 0) {
      this.dateTime = moment(this.tempData, 'DD.MM.YYYY')
    }
  }

  checkRenderZero(){
      this.renderZero = this.tempData != null && this.renderZero !== undefined ? true : false;
  }

  private propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    this.controlData = obj;
    this.propagateChange(this.controlData);

    if (this.type === 'date') {
      if (this.dateControl) {
        this.dateControl.setValue(obj ? moment.utc(obj) : null);
      } else {
        this.dateControl = new FormControl(obj ? moment.utc(obj) : null);
      }
      this.dateFormatted = this.dateControl.value ? (this.dateControl.value as moment.Moment).format('D.M.YYYY') : null;
    }
  }

  onClickOutside(e) {
    !e.target.className.startsWith('cke_') && !this.datePickerOpened && super.onClickOutside(e);
  }

  onBeginEdit() {
    if (!this.isReadonly) {
      super.onBeginEdit();
      setTimeout(() => this.el.focus(), 100);
    }
  }

  onReady(e) {
    e.editor.on('key', (e2) => {
      if (e2.data.keyCode === 1114195) {
        e2.cancel();  // <- Prevent Default
        this.onSave();
      }
      if (e2.data.keyCode === 27) {
        e2.cancel();  // <- Prevent Default
        this.onCancel();
      }
    });
  }

  onDateChange(event: MatDatepickerInputEvent<moment.Moment>) {
    if (event.value) {
      const rgx = new RegExp(/^\d{1,2}.\d{1,2}.\d{4}$/);
      const input = event.value.creationData().input;
      if (typeof input === 'string' && !rgx.exec(input)) {
        this.writeValue(this.controlData);
        return;
      }
      this.dateControl.setValue(event.value);
      this.datePickerOpened && this.onSave();
    } else {
      this.dateControl.setValue(null);
    }
  }

  onDateTimeChange(event: MatDatepickerInputEvent<moment.Moment>) {
    if (event.value) {
      this.dateTime = event.value;
    } else {
      this.dateTime = null;
    }
  }

  deleteDateTime() {
    this.dateTime = null;
    this.onSave();
  }

  onCancel() {
    if (this.type === 'date') {
      this.dateControl.setValue(this.controlData ? moment(this.controlData).clone().add(moment(this.controlData).utcOffset(), 'minutes') : null);
    }
    super.onCancel();
  }

  onSave() {
    if (this.tempData) {
      this.tempData = this.tempData.replace(/\r?\n/g, ' ').trim()
    }
    if (this.type === 'date') {
      if (this.dateControl.value) {
        this.controlData = (this.dateControl.value as moment.Moment)
          .clone()
          .add((this.dateControl.value as moment.Moment).utcOffset(), 'minutes')
          .valueOf();
        this.propagateChange(this.controlData);
        this.dateFormatted = (this.dateControl.value as moment.Moment).format('D.M.YYYY');
      } else {
        this.controlData = null;
        this.propagateChange(null);
        this.dateFormatted = null;
      }
    }
    if (this.type === 'dateTime') {
      this.tempData = this.dateTime ? this.dateTime.format('DD.MM.YYYY') : '';
    }
    super.onSave();
  }
}
