import { AuthService } from 'app/common/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {isAdmin, isClubCoach, isClubCoachAssistant, isClubNetAdmin, isClubRole} from 'app/common/models/user-info.model';
import { PersistenceService } from 'app/common/services/persistence.service';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { CityService } from 'app/common/services/city.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public static readonly componentName: string = 'HomeComponent';

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiV2Service: ApiV2Service,
    private persistenceService: PersistenceService,
    private cityService: CityService
  ) { }

  async ngOnInit() {
    const userInfo = await this.authService.getUserInfo();
    if (isAdmin(userInfo)) {
      this.cityService.getItemList()
        .then(cities => {
          const cityToNavigate = cities
            .find(x =>
              x.urlName === 'moscow' ||
              x.name === 'Москва'
            ) || cities[0];
          this.router.navigate([`${cityToNavigate.id}/clubs`])
        });
    } else if (isClubRole(userInfo)) {
      const ctx = await this.apiV2Service.context.get();
      if (!this.persistenceService.checkSheduledRedirect()) {
        if (userInfo.startScreen) {
          if (userInfo.startScreen === 'clubschedule') {
            const baseDate = new Date(this.cityService.getTimestampClub()).toLocaleDateString('ru');
            this.router.navigate([`/${ctx.clubs[0].cityId}/${ctx.clubNet.id}/${ctx.clubs[0].id}/clubschedule/${baseDate}`]);
          } else {
            this.router.navigate([`/${ctx.clubs[0].cityId}/${ctx.clubNet.id}/${ctx.clubs[0].id}/${userInfo.startScreen}`]);
          }
        } else if (isClubCoach(userInfo) || isClubCoachAssistant(userInfo)) {
          const baseDate = new Date(this.cityService.getTimestampClub()).toLocaleDateString('ru');
          this.router.navigate([`/${ctx.clubs[0].cityId}/${ctx.clubNet.id}/${ctx.clubs[0].id}/clubschedule/${baseDate}`]);
        } else if (isClubNetAdmin(userInfo)) {
          this.router.navigate([`/${ctx.clubs[0].cityId}/${ctx.clubNet.id}/${ctx.clubs[0].id}/clubanalytics`]);
        } else { this.router.navigate([`/${ctx.clubs[0].cityId}/${ctx.clubNet.id}/${ctx.clubs[0].id}/clubdashboard`]); }
      }
    } else {
      await this.authService.logout();
      window.location.reload();
    }
  }
}
