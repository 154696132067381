import {Injectable, Injector} from '@angular/core';
import { IRoutingParams } from 'app/common/models/context.model';
import {IClubAnalyticsModel} from '../../../common/models/club-analytics.model';
import {TableListService} from '../../../common/table-list/table-list.service';
import {ActivatedRoute} from '@angular/router';
import {adminAnalyticsPath, adminReportPath} from '../../../routing/routes/_constants';

@Injectable()
export class ClubAnalyticsService extends TableListService<IClubAnalyticsModel> {

  public context: IRoutingParams;
  constructor(injector: Injector,
              private route: ActivatedRoute) {
    super(injector);
    this.context = this.contextService.getRoutingParams();
  }

  getItemList() {
    this.context = this.contextService.getRoutingParams();
    if ( this.route.root.firstChild.routeConfig.path === adminAnalyticsPath ) {
      return this.apiService.adminReports.getAll();
    } else {
      return this.apiService.reports.getReports( this.context );
    }
  }

  public getReports () {
    this.context = this.contextService.getRoutingParams();
    if ( this.route.root.firstChild.routeConfig.path === adminAnalyticsPath ) {
      return this.apiService.adminReports.getAll();
    } else {
      return this.apiService.reports.getReports( this.context );
    }
  }

  public getReportByName ( reportName: string ) {
    this.context = this.contextService.getRoutingParams();
    if ( this.route.root.firstChild.routeConfig.path === adminReportPath ) {
      return this.apiService.adminReports.getByName( reportName );
    } else {
      return this.apiService.reports.getReportByName( this.context, reportName );
    }
  }

  public getReportResult ( reportName, params?: any ) {
    this.context = this.contextService.getRoutingParams();
    if ( this.route.root.firstChild.routeConfig.path === adminReportPath ) {
      return this.apiService.adminReports.execute( reportName, params );
    } else {
      return this.apiService.reports.getReportResult( this.context, reportName, params );
    }
  }

  getSingleItem(req: IRoutingParams) {
    console.log(req)
    return null;
  }
  save(model: IClubAnalyticsModel) {
    console.log(model);
    return null;
  }
  create(model: IClubAnalyticsModel) {
    console.log(model);
    return null;
  }
}
