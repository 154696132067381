export const tipsStrings = {
    schedule: {
        about: `
      Н – новый набор в группу. <br>
      Ч – черновик. Занятие сохранено, но недоступно для просмотра клиентами.
      `
    },
    userSearch: 'Начните писать, чтобы увидеть подсказки. Выберите пользователя, чтобы получить отчет по его действиям. Оставьте поле пустым, чтобы получить отчет по действиям всех пользователей.',
    search: `Найти:`,
    searchHK: ` (Ctrl+F)`,
    plus: `('+')`,
    close: ` (Esc)`,
    save: `Сохранить`,
    saveText: `Сохранить и опубликовать`,
    saveHK: ` (Ctrl+S)`,
    saveDraft: `Сохранить как черновик`,
    saveNew: `Сохранить как новый`,
    saveAdd: `Ctrl+пробел – <br> сохранить и добавить еще один элемент`,
    deleteText: `Удалить`,
    deleteHK: ` (Shift+Del)`,
    draftText: `В режиме "черновик" информация недоступна для отображения в системе.`
};
