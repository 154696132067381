import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntitySearchComponent } from 'app/shared-components/entity-search/entity-search.component';
import { IClientModel } from 'app/common/models/client.model';

@Component({
    selector: 'app-client-search',
    templateUrl: '../entity-search/entity-search.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ClientSearchComponent),
        multi: true
    }]
})
export class ClientSearchComponent extends EntitySearchComponent<IClientModel> {

    public placeholder = 'Телефон или ФИО';

    constructor(injector: Injector) {
        super(injector);
    }

    public niceItemName(item: IClientModel): string {
        return `${item.firstName} ${item.lastName}`;
    }

    public findItems(query: string): Promise<IClientModel[]> {
        // TODO: пока просто подменяем id клиента на id пользователя.
        // правильнее сделать отдельный параметр - ключ, и выбрасывать из компонента не всегда id, а свойство с этим ключем
        return this.api.clients.searchClients(this.routingParams, query)
            .then(res => (res.clients as IClientModel[]).map(x => (x.id = x.userId, x)));
    }
}
