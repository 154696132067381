export class ClubSetting {
  id?: string;
  alias: string;
  value: string;
  clubId?: string;
}

export class ClubSettingVariant {
  id?: string;
  title: string;
  alias: string;
  category: string;
  type: ClubSettingType;

  value?: string | boolean | number;
}

export enum ClubSettingType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Date = "date"
}

export enum ClubSettingAliases {
  /** Блокировать кнопку "Я в клубе". */
  IsHiddenImTheClubButton = "IsHiddenImTheClubButton",

  /** За сколько минут до начала занятия нельзя отменить запись. */
  NumberMinutesBeforeStartCannotCancelBooking = "NumberMinutesBeforeStartCannotCancelBooking"
}


