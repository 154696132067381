export function format2digits(number) {
    return ('0' + number).slice(-2);
}

export function date2str(d: Date) {
    return format2digits(d.getDate()) + '.' + format2digits(d.getMonth() + 1) + '.' + d.getFullYear();
}

export function str2date(s) {
    return new Date(
        parseInt(s.substr(6, 4), 10), // year
        parseInt(s.substr(3, 2), 10) - 1, // month
        parseInt(s.substr(0, 2), 10), // day
        1,
        1
    );
}

export function diffBetween2Dates(fromDate: string, toDate: string) {
  return Math.ceil(Math.abs(str2date(toDate).getTime() - str2date(fromDate).getTime()) / (1000 * 3600 * 24));
}

export function addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setTime(date.getTime() + days * 86400000);
    return newDate;
}

export function createRange(start, end) {
    const items = [];
    for (let i = start; i <= end; i++) {
        items.push(i);
    }
    return items;
}
