<ng-container *ngIf="data!=null">
  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Безлимит?</b></span>
    <input type="checkbox"
           [id]="'ve'+id + '_' +i"
           [name]="'ve'+id + '_' +i"
           [checked]="data.visitCount>=10000"
           (change)="onChange($event)"
    >
    <label [for]="'ve'+id + '_' +i"
           class="check-box"
    ></label>
  </td>
  <td app-visit-count-picker [(ngModel)]="data.visitCount" myScrollTo class="table-cell"></td>
  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Цена</b></span>
    <input [appRestrictInput]="{min:0, max:100000000}" placeholder="0,00" type="text" class="input__text input__text--long" [(ngModel)]="data.price">
  </td>
  <td *ngIf="VisiblePriceOnSaleCampain" myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Цена по скидке</b></span>
    <input [appRestrictInput]="{min:0, max:100000000}" placeholder="0,00" type="text" class="input__text input__text--long" [(ngModel)]="data.priceOnSaleCampain">
  </td>

  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Срок действия</b></span>
    <app-duration-picker [ngModel]="duration" (ngModelChange)="setDuration($event)"></app-duration-picker>
  </td>
  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Название</b></span>
    <input type="text" class="input__text input__text--long" [(ngModel)]="data.title">
  </td>
  <td myScrollTo class="table-cell td-resource-select-sub" *ngIf="false">
    <mat-form-field appearance="fill">
      <mat-label>Ресурсы</mat-label>
      <mat-select [formControl]="toppings" multiple>
        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </td>
  <td myScrollTo class="table-cell">
    <span class="table-cell__mobile-title"><b>Удалить</b></span>
    <button class="button-delete" (click)="onDeleteClick()"></button>
  </td>
</ng-container>
