<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Города
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="cities-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">Добавить
              <span class="key">
                <span app-key-help [key]="'plus'"></span>
              </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Опубликовано?</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let city of Model' (mousedown)="onEditStart(city,$event)" (click)="onEditStart(city)">
              <td class="table-cell">
                <span class="table-cell__mobile-title">Город</span>
                <span>{{city.name}}</span>
              </td>
              <!-- <td class="table-cell table-cell_publish" (click)="$event.stopPropagation()">
                <app-status-picker [title]="null" [ngModel]="city.entityStatus" (ngModelChange)="setStatus($event, city)"></app-status-picker>
              </td> -->
              <td class="table-cell table-cell_prod">
                <span class="table-cell__mobile-title">Опубликовано?</span>
                <span class="icon-check icon-check_color">
                  <ng-container *ngIf="city.entityStatus=='published'">
                    <span class="icon-check__left"></span>
                    <span class="icon-check__right"></span>
                  </ng-container>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

</div>
