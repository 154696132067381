import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { ClubsComponent } from 'app/components/clubs/clubs.component';
import { ClubEditComponent } from '../../components/clubs/club-edit/club-edit.component';

export const clubsRoute: Route = {
  path: ':cityId/clubs',
  component: ClubsComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full',
  data: { title: 'Клубы' }
};

export const clubCreationRoute: Route = {
  path: ':cityId/clubs/create',
  component: ClubEditComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full',
  data: { title: 'Создание клуба' }
};

export const clubRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/club',
  component: ClubEditComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full',
  data: { title: 'Редактирование клуба' }
};
