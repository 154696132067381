<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Сети клубов
    </span>
   </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="clubnets-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell">Название</td>
              <td class="table-cell">Опубликовано</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let net of Model' (mousedown)="onEditStart(net,$event)" (click)="onEditStart(net)">
              <td class="table-cell">
                <span class="table-cell__mobile-title">Название</span>
                {{net.name}}
              </td>
              <td class="table-cell table-cell_prod">
                <span class="table-cell__mobile-title">Опубликовано</span>
                <span class="icon-check icon-check_color">
                  <ng-container *ngIf="net.entityStatus=='published'">
                    <span class="icon-check__left"></span>
                    <span class="icon-check__right"></span>
                  </ng-container>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </ng-container>
</div>
