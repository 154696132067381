import { Injectable } from '@angular/core';

import { IClubNetModel } from 'app/common/models/clubnet.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { ICityModel } from 'app/common/models/city-model';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class ClubNetsService extends TableListService<IClubNetModel>  {

  getItemList(city?: ICityModel) {
    return this.apiV2Service.clubnets.getAll(city);
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.clubnets.getItem(req);
  }

  save(model: IClubNetModel) {
    return this.apiService.clubnets.saveItem(this.routingParams, model);
  }

  create(model: IClubNetModel) {
    return this.save(model);
  }

  delete(model: IClubNetModel) {
    return this.apiService.clubnets.deleteItem(this.routingParams, model)
  }

}
