<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Веб-хуки
    </span>
  </div>
</header>

<div class="content__body">
  <div class="clubrooms-container" *ngIf="Model">
    <div class="tableSideLinkWrap">
      <div class="clue-text">
        <span class="key" app-key-help [key]="'search'"></span>
        <span class="key" app-key-help [key]="'searchHK'"></span>
        <a class="box-link" (mousedown)="onEditStart(null ,$event)" (click)="onEditStart(null)">Добавить
          <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
        </a>
      </div>
    </div>
    <div class="table-responsive" *ngIf="Model.length > 0">
      <table class="table rowHover">
        <thead>
        <tr>
          <td class="table-cell table-title-cell">Название</td>
          <td class="table-cell table-title-cell">Включен/выключен</td>
          <td class="table-cell table-title-cell">Статус</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let webhook of Model' (mousedown)="onEditStart(webhook ,$event)" (click)="onEditStart(webhook)">
          <td class="table-cell">
            <span class="table-cell__mobile-title">Название</span>
            {{webhook.name}}
          </td>
          <td class="table-cell">
            <span class="table-cell__mobile-title">Включен/выключен</span>
            <span style="color: green;" *ngIf="webhook.isActive">Да</span>
            <span style="color: red;" *ngIf="!webhook.isActive">Нет</span>
          </td>
          <td class="table-cell">
            <span class="table-cell__mobile-title">Статус</span>
            <span style="color: green;" *ngIf="webhook.entityStatus == EntityStatus.published">Опубликовано</span>
            <span style="color: red;" *ngIf="webhook.entityStatus == EntityStatus.draft">Черновик</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="Model.length == 0">
      <p style="text-align: center; padding: 100px 0 0 0">Добавьте новый вебхук для интеграции с внешними системами.</p>
    </div>
  </div>
</div>
