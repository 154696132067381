<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Импорт MF
    </span>
  </div>
</header>

<div class="content__body">
  <div class="row">
    <div class="col-xs-12 col-sm-4">
      <app-text-edit [(data)]="token" [title]="'Токен'" [placeholder]="" [type]="'input'"></app-text-edit>
    </div>
    <div class="col-xs-12 col-sm-4">
      <app-text-edit [(data)]="clubId" [title]="'Номер клуба в MF'" [placeholder]="" [type]="'input'"></app-text-edit>
    </div>
    <div class="col-xs-12 col-sm-4">
      <app-text-edit [(data)]="weeksBackwards" [title]="'Сколько недель просматривать'" [placeholder]="" [type]="'input'"></app-text-edit>
    </div>
    <div class="col-xs-12">
      <br>
      <br>
      <button class="btn btn-primary" (click)="process()">
        <span class="btn-primary__desc">
          <span>тынч!</span>
        </span>
      </button>
    </div>
    <div class="col-xs-12">
      <br>
      <br>
      <div class="title-wrap">
        <div class="box-title">
          Лог (новые сообщения сверху)
        </div>
      </div>
      <div class="row__field">
        <div class="field wysiwyg" style="white-space:pre-line" [innerHtml]="log">
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6" *ngIf="false">
      <br>
      <br>
      <br>
      <br>
      <button class="btn btn-primary" (click)="clean()">
        <span class="btn-primary__desc">
          <span>удалить всё</span>
        </span>
      </button>
    </div>

  </div>
</div>