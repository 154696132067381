import { FormControl } from '@angular/forms';

export const NotEmpty = () => {
    return ( c: FormControl ) => {
        let valid = true;
        if ( c.value == null || c.value === '' ) {
            valid = false;
        } else
            if ( ( c.value ).trim().length < 1 ) {
                valid = false;
            }

        return valid ? null : {
            NotEmpty: {
                valid: false
            }
        };
    }
}
