import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { IOnValueChanged, onValueChanged, OnValueChangeBase } from 'app/common/validators/on-value-changed.class';
import { AuthService } from 'app/common/services/auth.service';
import { debounceTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-restore',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})

export class RestorePasswordComponent extends OnValueChangeBase implements OnInit, IOnValueChanged {
  // TODO: Копипаста. См. reset-password
  fieldMessages = {
    emailOrPhone: {
      required: 'Это поле обязательно'
    }
  };

  constructor(
    injector: Injector,
    private authService: AuthService,
    private router: Router
  ) {
    super(injector)
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'emailOrPhone': ['', [Validators.required]]
    });

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }

  redirect() {
    this.router.navigate(['/']);
  }

  restore() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    const eop = this.form.value.emailOrPhone;
    this.authService.restorePassword(eop);
  }
}
