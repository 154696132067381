import { ClientsService } from './../../clients/services/clients.service';
import { Component, Injector, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { EditItemComponent } from '../../../common/edit-item.component';
import { IClubEventModel, ClubEventType, IClubEventBooking } from '../../../common/models/club-events.model';
import { ClubEventService } from '../club-events.service';
import { EntityStatus } from '../../../common/models/entity-status.model';
import { Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { MediaService } from 'app/common/services/media.service';
import { ClubEventTicketsTableComponent } from '../club-event-tickets-table/club-event-tickets-table.component';
import { ClubEventReqfildsTableComponent } from '../club-event-reqfilds-table/club-event-reqfilds-table.component';
import { ClubEventContactsTableComponent } from '../club-event-contacts-table/club-event-contacts-table.component';
import { ExcelService } from 'app/common/services/excel.service';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {CityService} from '../../../common/services/city.service';

@Component({
  selector: 'app-club-event-edit',
  templateUrl: './club-event-edit.component.html',
  styleUrls: ['./club-event-edit.component.scss']
})
export class ClubEventEditComponent extends EditItemComponent<IClubEventModel> {
  public static readonly componentName: string = 'ClubEventEditComponent';

  eventTypes: { name: string, value: string }[] = [
    { name: 'Спорт', value: 'спорт' },
    { name: 'Культура', value: 'культура' },
    { name: 'Образование', value: 'образование' },
    { name: 'Активный отдых', value: 'активный отдых' }
  ];

  public _salePeriodOn = false;
  public tableColumns: {name: string, displayName: string}[];
  public tableColumnNames: string[];
  public selectedColumnFilter = '';
  public dataForTable: any;
  public searchString = '';
  public filteredValues =  {};
  public isShowDetails = true;
  public selectButtonFilter: {};
  public selectModel: any = {};
  public SaveMedias = true;
  public filterSelectObj = [];
  public selectFilter: any = {};

  clubEventBookings: IClubEventBooking[] = null;            // Все записи
  clubEventBookingsNotSet: IClubEventBooking[] = [];        // Тип: не выбрано
  clubEventBookingsVisitor: IClubEventBooking[] = [];       // Тип: зрители
  clubEventBookingsParticipant: IClubEventBooking[] = [];   // Тип: участники

  clubEventBookingsIsEmpty: boolean = true;
  clubEventBookingsNotSetIsEmpty: boolean = true;
  clubEventBookingsVisitorIsEmpty: boolean = true;
  clubEventBookingsParticipantIsEmpty: boolean = true;
  public dataSource: MatTableDataSource<any>;

  public allClients: any[] = []; // Все клиенты клуба.

  @ViewChild(ClubEventTicketsTableComponent) ticketsTable: ClubEventTicketsTableComponent;
  @ViewChild(ClubEventReqfildsTableComponent) reqfildsTable: ClubEventReqfildsTableComponent;
  @ViewChild(ClubEventContactsTableComponent) contactsTable: ClubEventContactsTableComponent;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  };

  constructor(
    injector: Injector,
    protected service: ClubEventService,
    protected clientService: ClientsService,
    protected mediaService: MediaService,
    private readonly excelService: ExcelService,
    private readonly cityService: CityService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(injector, service);
  }

  public async afterModelInit() {
    await this.getBookings();
    await this._forDataTable()
    this.tableColumnNames = [];
    this.tableColumns = [];
    for (const data in this.dataForTable[0]) {
      if (data !== '№') {
        this.tableColumnNames.push(data)
        this.tableColumns.push({name: data, displayName: data})
      }
    }
    this.dataSource.data = this.dataForTable;
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.filteredValues = JSON.parse(JSON.stringify(this.dataSource.data[0]));
    this.selectButtonFilter = JSON.parse(JSON.stringify(this.dataSource.data[0]));
    this.filterSelectObj = []
    for (const i of this.tableColumnNames) {
      this.filterSelectObj.push({name: i, options: []});
    }
    // переменные фильтрации
    this.filterSelectObj.filter((o) => {
      o.options = this.getFilterObject(this.dataSource.data, o.name);
      o.options.sort()
    });
    for (const i in this.filteredValues) {
      this.filteredValues[i] = [];
      this.selectButtonFilter[i] = null;
    }
    // this.updateThemes();
    // this.updateRooms();
    const _StorageIsShowDetails = localStorage.getItem('isShowDetails');
    if (!_StorageIsShowDetails) {
      localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
    } else {
      this.isShowDetails = JSON.parse(_StorageIsShowDetails);
    }

    if (!this.Model.id) {
      this.isShowDetails = true;
    }
  }

  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(String(obj[key])) && obj[key] != '') {
        uniqChk.push(String(obj[key]));
      }
      return obj;
    });
    return uniqChk;
  }

  modelTemplate(): Promise<IClubEventModel> {
    const result: IClubEventModel = {
      id: null,
      entityStatus: EntityStatus.published,
      clubId: this.contextService.getRoutingParams().clubId,
      parentId: this.parentId,
      name: '',
      timeFrom: Date.now(),
      timeTo: Date.now(),
      locationCityId: null,
      locationText: null,
      locationLat: null,
      locationLon: null,
      description: null,
      eventType: ClubEventType.sport,
      specialConditions: null,
      entryFee: null,
      customEntryFeeLabel: null,
      customEntryFeeTitle: null,
      ticketPrice: null,
      customTicketPriceLabel: null,
      customTicketPriceTitle: null,
      medias: {
        images: [],
        videos: []
      },
      isGlobal: false,
      shortDescription: null,
      eventReport: null,
      eventReportTitle: null,
      contacts: { chat: true },
      ticketVariants: null,
      saleTimeFrom: null,
      saleTimeTo: null,
      requiredTicketFields: null,
    };

    return Promise.resolve(result);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, Validators.required],
      // timeFrom: [this.Model.timeFrom, Validators.minLength(3)],
      // timeTo: [this.Model.timeTo],
      description: [this.Model.description],
      locationText: [this.Model.locationText],
      locationLat: [this.Model.locationLat],
      locationLon: [this.Model.locationLon],
      eventType: [this.Model.eventType],
      specialConditions: [this.Model.specialConditions],
      entryFee: [this.Model.entryFee],
      customEntryFeeLabel: [this.Model.customEntryFeeLabel],
      customEntryFeeTitle: [this.Model.customEntryFeeTitle],
      ticketPrice: [this.Model.ticketPrice],
      customTicketPriceLabel: [this.Model.customTicketPriceLabel],
      customTicketPriceTitle: [this.Model.customTicketPriceTitle],
      medias: [this.Model.medias],
      isGlobal: [this.Model.isGlobal],
      shortDescription: [this.Model.shortDescription, Validators.maxLength(400)],
      eventReport: [this.Model.eventReport],
      eventReportTitle: [this.Model.eventReportTitle],
      contacts: [this.Model.contacts],
      salePeriodOn: [this._salePeriodOn],
      ticketVariants: [this.Model.ticketVariants],
      requiredTicketFields: [this.Model.requiredTicketFields],
    });

    this._salePeriodOn = this.Model.saleTimeFrom != null && this.Model.saleTimeTo != null;
  }

  hkSaveAddPress(that: this) {
    if (this.isEditAvailable) {
      return super.hkSaveAddPress(that);
    } else {
      return (e) => { return e };
    }
  }

  hkSavePress(that: this) {
    if (this.isEditAvailable) {
      return super.hkSavePress(that);
    } else {
      return (e) => { return e };
    }
  }

  hkDeletePress(that: this) {
    if (this.isEditAvailable) {
      return super.hkDeletePress(that);
    } else {
      return (e) => { return e };
    }
  }

  childrenClick(): void {
    this.router.navigate(['../'], { relativeTo: this.route, queryParams: { parentId: this.Model.id } });
  }

  public _salePeriodOnChangeHandler() {
    if (this._salePeriodOn) {
      this.Model.saleTimeFrom = this.Model.timeFrom;
      this.Model.saleTimeTo = this.Model.timeTo;
    }
    else {
      this.Model.saleTimeFrom = null;
      this.Model.saleTimeTo = null;
    }
  }

  onChangeSelect(filterValue, filterColum) {
    this.selectFilter = filterValue[filterColum];
  }

  applyFilterInput(filterValue) {
    this.searchString = filterValue;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
  }
  clearFilterInput() {
    this.searchString = '';
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
  }
  applyFilterColum(filterValue, filterColum) {
    this.filteredValues[filterColum] = filterValue;
    // tslint:disable-next-line:triple-equals
    if (this.filteredValues[filterColum] == null) {
      this.selectButtonFilter[filterColum] = null
    } else {this.selectButtonFilter[filterColum] = filterValue}
    this.selectedColumnFilter = filterColum;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
  }
  clearColumn(filterColum) {
    this.filteredValues[filterColum] = [];
    this.selectModel[filterColum] = [];
    this.selectButtonFilter[filterColum] = null;
    this.selectFilter = [];
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
  }
  filterCheckBoxValue() {
    for (const selectValue in this.filteredValues) {
      if (this.filteredValues[selectValue].length === 0) {
        this.filterSelectObj.filter((o) => {
          if (o.name === selectValue) {
            o.options = this.getFilterObject(this.dataSource.filteredData, o.name);
            o.options.sort()
          }
        });
      }
    }
  }

  public _getTicketVariantTitle(ticketVariantId: string) {
    const ticketVariant = this.Model.ticketVariants.find(t => t.id == ticketVariantId);
    return ticketVariant ? `${ticketVariant.title}` : '';
  }

  public _getTicketVariantPrice(ticketVariantId: string) {
    const ticketVariant = this.Model.ticketVariants.find(t => t.id == ticketVariantId);
    return ticketVariant ? `${ticketVariant.price}.00` : '';
  }

  public _exportParticipants(): any {
    const exportA: any[] = [];
    this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort).forEach(item => {
      const e: any = new Object();
      this.tableColumns.forEach(f => {
        e[f.displayName] = item[f.name];
      });
      exportA.push(e)
    });

    if (exportA.length === 0) {
      this.alertsService.alert.next({
        type: 'danger',
        header: 'Ошибка экспорта',
        message: 'Отчет пустой',
        position: 'top',
        timeout: 3000
      });
      return;
    }

    this.excelService.exportAsExcelFile(
      exportA,
      `Участники «${this.Model.name}» ${moment().format('d.MM.YYYY-HH.mm')}`);
  }

  public async _forDataTable() {
    const keysMap = [ '№', 'Покупатель', 'Дата', 'Билет', 'Цена билета', 'Телефон' ]
      .reduce(_mapKeyToUnique.bind(undefined, this.Model.requiredTicketFields), {});
    this.dataForTable = [];
    this.clubEventBookingsParticipant.forEach(b => {
      b.tickets?.forEach(t => {
        if (t?.participants && t?.participants.length && t?.participants.length < t?.count) {
          for (let i = 1; i <= (t?.count - t?.participants.length); i++) {
            t?.participants.push({})
          }
        }
        if (!t?.participants) {
          t.participants = [];
          for (let i = 1; i <= t?.count; i++) {
            t?.participants.push({})
          }
        }
        t.participants?.forEach(p => {
          const row = keysMap['№'] ? { [keysMap['№']]: `${this.dataForTable.length + 1}` } : {};
          this.Model.requiredTicketFields.forEach(f => row[f] = p[f]);
          if (keysMap['Покупатель']) row[keysMap['Покупатель']] = b.name;
          if (keysMap['Телефон']) row[keysMap['Телефон']] = b.phone ? b.phone : 'Н/Д';
          if (keysMap['Дата']) row[keysMap['Дата']] = moment(b.bookingDate).format("D MMMM HH:mm");
          if (keysMap['Билет']) row[keysMap['Билет']] = this._getTicketVariantTitle(t.id);
          if (keysMap['Цена билета']) row[keysMap['Цена билета']] = this._getTicketVariantPrice(t.id);
          this.dataForTable.push(row);
        });
      });
    });
    return this.dataForTable
  }

  async getBookings() {
    this.dataSource = new MatTableDataSource([]);
    await this.clientService.getItemList().then(async e => {
      this.allClients = e.clients || [];
    });
    await this.service.getBookings(this.Model).then((data: IClubEventBooking[]) => {
      if (data.length > 0) {
        this.clubEventBookings = data;
        this.clubEventBookingsIsEmpty = false;
        this.separationBookings();
      }
      else {
        console.log(`Нет участников и зрителей мероприятия`);
        this.clubEventBookingsIsEmpty = true;
      }
    });
  }

  typeClubEvent(type: string) {
    switch (type) {
      case 'participant':
        return 'участник';
      case 'visitor':
        return 'зритель';
      case 'notSet':
        return 'не выбрано';
    }
  }

  setShowDetails() {
    this.SaveMedias = false;
    this.isShowDetails = !this.isShowDetails;
    localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
  }

  AlignRight(Element) {
    switch (Element) {
      case 'Цена билета':
        return true
    }
  }

  toRur(price: number) {
    if (price == null)
      price = 0;
    const decimalPipe = new DecimalPipe(this.locale);
    return decimalPipe.transform(price, '1.0-0') + ' ₽';
  }

  toDate(date: number) {
    const city = this.cityService.currentCity.value;
    const timeZone = city?.timeZone ? city.timeZone : 180;
    return moment(date).utcOffset(timeZone).format("D MMMM HH:mm");
  }

  separationBookings() {
    for (var booking of this.clubEventBookings)
      switch (booking.type) {
        case 'participant':
          this.clubEventBookingsParticipant.push(booking);
          this.clubEventBookingsParticipantIsEmpty = false;
          break;
        case 'visitor':
          this.clubEventBookingsVisitor.push(booking);
          this.clubEventBookingsVisitorIsEmpty = false;
          break;
        case 'notSet':
          this.clubEventBookingsNotSet.push(booking);
          this.clubEventBookingsNotSetIsEmpty = false;
          break;
      }
  }
  openImage(event: any) {
    this.mediaService.saveRedirectUrl();
    var url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);
  }

  goToEditParticipant(booking) {
    this.allClients.forEach(client => {
      if(client.userId === booking.userId) {
        const url = `${this.router.url.split('clubevents')[0]}clubclients/${client.id}`;
        this.router.navigate([url], { relativeTo: this.route });
      }
    });
  }

  customFilterPredicate() {
    return(data, columnFilter: string): boolean => {
      let rowDataAsString = '';
      let isPositionAvailable = true;
      const searchString = JSON.parse(columnFilter);
      for (const colName of this.tableColumnNames) {
        if (data[colName]) {
          rowDataAsString += data[colName] + '\n';
        }
      }
      rowDataAsString = rowDataAsString.toLowerCase();

      if (rowDataAsString.indexOf(this.searchString.toLowerCase()) === -1) {
        return false;
      }
      if (this.selectedColumnFilter === '') {
        return true;
      } else {
        for (const filterKey in searchString) {
          if (searchString[filterKey].length) {
            isPositionAvailable = isPositionAvailable && searchString[filterKey].includes(data[filterKey] && data[filterKey].toString()); /// .trim()
          }
        }
        return isPositionAvailable;
      }
    };
  }
}


function _mapKeyToUnique(reserved: string[], map: {}, key: string) {
  let span = '';
  do {
    const _key = key + span;
    if (reserved.includes(_key)) span = span + ' ';
    else return { ...map, [key]: _key };
  } while (span.length < 10)
  return map;
}
