import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { from, Subject ,  Subscription } from 'rxjs';
import { IClubModel, IExtendedClubModel } from 'app/common/models/club.model';
import { ClubsService } from 'app/components/clubs/services/clubs.service';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { CityService } from 'app/common/services/city.service';
import { ICityModel } from 'app/common/models/city-model';
import { takeUntil, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['clubs.component.scss']
})
export class ClubsComponent implements OnInit, OnDestroy {
  // TODO: это тоже должно наследоваться от tableList
  protected ngUnsubscribe: Subject<void> = new Subject();
  private searchSubscription: Subscription = null;
  protected hkAdd: Hotkey | Hotkey[];

  public cities: ICityModel[] = [];
  public currentCity: ICityModel = null;
  public onlyCurrentCity = false;

  public searchTerm = new FormControl();
  public clubsList: IClubModel[] = [];

  private readonly pageSize = 50;
  public totalItemCount: number;
  public pageIndex = 0;
  public isFullClubList = true;

  constructor(
    private service: ClubsService,
    private router: Router,
    private route: ActivatedRoute,
    protected hotkeysService: HotkeysService,
    private cityService: CityService
  ) {
    this.hkAdd = this.hotkeysService.add(new Hotkey(['=', 'ins', 'plus'], this.hkAddPress(this), []));
  }

  public ngOnInit() {
    this.pageIndex = 0;

    this.searchTerm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe))
      .subscribe((query: string) => this.search(query));

    this.cityService.getItemList()
      .then(cities => this.cities = cities);

    this.cityService.currentCity.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(Boolean))
      .subscribe(city => {
        this.currentCity = city as ICityModel;
        this.getFullClubList();
      })
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.hotkeysService.remove(this.hkAdd);
  }

  public search(input: string) {
    // TODO: пагинация поиска
    const pattern = input.trim();
    this.isFullClubList = false;

    if (pattern.length < 2) {
      if (pattern.length) {
        this.clubsList = [];
      } else {
        this.getFullClubList();
      }
    } else {
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
        this.searchSubscription = null;
      }
      this.searchSubscription = from(this.service.search(pattern, this.onlyCurrentCity && this.currentCity))
        .subscribe(res => this.clubsList = res.items);
    }
  }

  public async getFullClubList() {
    this.searchTerm && this.searchTerm.setValue(null, { emitEvent: false });
    this.pageIndex = 0;

    try {
      const clubList = await this.service.getItemList(this.onlyCurrentCity && this.currentCity, this.pageIndex, this.pageSize);
      this.isFullClubList = true;
      this.clubsList = clubList.items;
      this.totalItemCount = clubList.pagingInfo.totalItemCount;
    } catch {
      this.isFullClubList = true;
      this.clubsList = [];
      this.totalItemCount = 0;
    }
  }

  public showNextPage() {
    this.pageIndex += 1;
    this.service.getItemList(this.onlyCurrentCity && this.currentCity, this.pageIndex, this.pageSize)
      .then(res => this.clubsList = [...this.clubsList, ...res.items]);
  }

  protected hkAddPress = (that: this) => {
    return () => {
      that.onAddClick();
      return true;
    };
  }

  onAddClick(event?: MouseEvent) {
    if (event && (event.which === 2 || event.ctrlKey)) {
      event.preventDefault();

      const url = this.router.createUrlTree(['create'], { relativeTo: this.route });
      window.open(url.toString());

      return;
    }

    this.router.navigate(['create'], { relativeTo: this.route });
  }

  public onEditClick(item: IExtendedClubModel) {
    this.router.navigateByUrl(`${item.cityId}/${item.clubNetId}/${item.id}/clubdashboard`);
  }

  public setStatus(event: string, item: IExtendedClubModel) {
    this.service.setStatus(event, item);
  }
}
