import { Component } from '@angular/core';
import * as moment from 'moment';

import { ApiService } from 'app/common/services/api.service';
import { ICoachModel } from 'app/common/models/coach.model';
import { ColorService } from 'app/common/services/color.service';
import { ContextService } from 'app/common/services/context.service';
import { IImage } from 'app/common/models/image.model';
import { ClubConfirmationMethod, ICourseModel } from 'app/common/models/course.model';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { MFApiClient } from 'app/components/club-mf-import/mf-api-client.class';
import { MFTrainer, MFActivity, MFRoom, MFScheduleResponse } from 'app/components/club-mf-import/mf-models';
import { IClassItemModel } from 'app/common/models/class-item.model';
import { ScheduleService } from 'app/components/schedule/services/schedule.service';
import { EntityStatus } from 'app/common/models/entity-status.model';


@Component({
  selector: 'app-mf-import',
  templateUrl: './mf-import.component.html',
  providers: [MFApiClient]
})
export class ClubMFImportComponent {
  private coaches: MFTrainer[] = [];
  private courses: MFActivity[] = [];
  private rooms: MFRoom[] = [];

  private spCoaches: { mfId: number, model: ICoachModel }[] = [];
  private spCourses: { mfId: number, model: ICourseModel }[] = [];
  private spRooms: { mfId: number, model: IClubroomModel }[] = [];

  private colors = this.colorService.getPrimaryColors();
  private ctx = this.contextService.getRoutingParams();


  public log = `Здесь будут появляться сообщения по ходу импорта.
  Если процесс не завершился, но новых сообщений долго нет - что-то пошло не так, обратитесь к разработчику
  `;
  public token: string = null;
  public clubId: string = null;
  public weeksBackwards = 1;

  constructor(
    private mfApi: MFApiClient,
    private spApi: ApiService,
    private colorService: ColorService,
    private contextService: ContextService,
    private scheduleService: ScheduleService
  ) { }

  private appendLog(str: string) {
    this.log = str + '\n' + this.log;
  }

  public async process() {
    if (!this.token || !this.clubId) {
      this.appendLog('не указаны токен или номер клуба');
      return;
    }
    const weeks: MFScheduleResponse[] = [];
    try {
      const r = await this.processWeek();
      if (r) {
        weeks.push(r);
      } else {
        return;
      }

      for (let i = 1; i < this.weeksBackwards; i++) {
        const r2 = await this.processWeek(weeks[weeks.length - 1].prev);
        if (r2) {
          weeks.push(r2);
        } else {
          return;
        }
      }
    } catch (e) {
      this.appendLog(`${e}`);
      return;
    }


    this.spCoaches = [];
    this.spCourses = [];
    this.spRooms = [];

    // сохранить залы
    for (const room of this.rooms) {
      this.appendLog(`обрабатывается зал ${this.spRooms.length + 1} из ${this.rooms.length}`);
      await this.makeSpRoom(room);
      const newRoom = this.spRooms.find(item => {
        return item.mfId === room.id;
      });

      await this.spApi.rooms.saveItem(this.ctx, newRoom.model)
        .then(r => newRoom.model.id = r.id);
    }
    this.appendLog('залы готовы');
    this.appendLog('');


    // потом тренеров
    for (const coach of this.coaches) {
      this.appendLog(`обрабатывается тренер ${this.spCoaches.length + 1} из ${this.coaches.length}`);
      await this.makeSpCoach(coach);
      const newCoach = this.spCoaches.find(item => {
        return item.mfId === coach.id;
      });

      await this.spApi.coaches.saveItem(this.ctx, newCoach.model)
        .then(r => {
          newCoach.model.id = r.id;
        });
    }
    this.appendLog('тренеры готовы');
    this.appendLog('');


    // потом собрать курсы
    for (const activity of this.courses) {
      this.appendLog(`обрабатывается курс ${this.spCourses.length + 1} из ${this.courses.length}`);
      await this.makeSpCourse(activity);
    }


    // потом назначить комнаты и тренеров на курсы,
    // если за последние n недель тренер вел это занятие в этом зале
    let index = 1;
    for (const week of weeks) {
      this.appendLog(`обрабатывается неделя ${index} из ${weeks.length}`);
      let classIndex = 1;
      for (const item of week.schedule) {
        this.appendLog(`обрабатывается занятие ${classIndex} из ${week.schedule.length}`);

        if (!item.activity) {
          this.appendLog(`занятие ${index} без курса, пропускаем`);
          classIndex++;
          continue;
        }

        const spCourse = this.spCourses.find(course => {
          return course.mfId === item.activity.id;
        });

        if (item.trainers && item.trainers.length) {
          const assignedCoach = this.spCoaches.find(coach => {
            return coach.mfId === item.trainers[0].id;
          });

          if (!spCourse.model.coaches.find((coach) => {
            return coach.id === assignedCoach.model.id;
          })) {
            (spCourse.model.coaches as Array<{ id: string }>)
              .push({ id: assignedCoach.model.id });
          }
        }

        if (item.room) {
          const assignedRoom = this.spRooms.find(room => {
            return room.mfId === item.room.id;
          });

          if (!spCourse.model.rooms.find((room) => {
            return room.id === assignedRoom.model.id;
          })) {
            (spCourse.model.rooms as Array<{ id: string }>)
              .push({ id: assignedRoom.model.id });
          }
        }
        classIndex++;
      }
      index++;
    }
    this.appendLog('назначение для курсов готово');
    this.appendLog('');


    // и их тоже сохранить
    index = 1;
    for (const activity of this.courses) {
      this.appendLog(`сохраняется курс ${index} из ${this.spCourses.length}`);
      const newCourse = this.spCourses.find(item => {
        return item.mfId === activity.id;
      });

      await this.spApi.courses.saveItem(this.ctx, newCourse.model)
        .then(r => {
          newCourse.model.id = r.id;
        });
      index++;
    }
    this.appendLog('курсы готовы');
    this.appendLog('');

    // создать занятия
    index = 1;
    for (const _class of weeks[0].schedule) {
      this.appendLog(`сохраняется занятие ${index} из ${weeks[0].schedule.length}`);
      if (_class.change && _class.change.type === 'canceled') {
        this.appendLog(`занятие ${index} отменено, пропускаем`);
        index++;
        continue;
      }
      const mfTrainerId = _class.trainers && _class.trainers.length ? _class.trainers[0].id : null;
      const mfRoomId = _class.room ? _class.room.id : null;
      const mfActivityId = _class.activity ? _class.activity.id : null;
      const dateTime = moment.utc(_class.datetime).add(moment.parseZone(_class.datetime).utcOffset(), 'm');
      const comments: string[] = [];

      if (_class.commercial) {
        comments.push('Коммерческое занятие.');
      }

      if (_class.firstFree) {
        comments.push('Бесплатное пробное занятие.');
      }

      if (_class.popular) {
        comments.push('Популярное занятие.');
      }

      if (_class.preEntry) {
        comments.push('Требуется предварительная запись.');
      }

      let comment = '';
      let commIndex = 0;
      for (const c of comments) {
        comment = `${comment}${c}${commIndex < comments.length - 1 ? '\n' : ''}`;
        commIndex++;
      }

      const spClass: IClassItemModel = {
        id: null,
        entityStatus: EntityStatus.published,
        startDate: dateTime.format('DD.MM.YYYY'),
        startTime: dateTime.format('HH:mm'),
        duration: _class.length,
        level: 'any',
        isNewGroup: !!_class.new,
        normCapacity: 15,
        maxCapacity: 20,
        isNotRecurrent: false,
        onlineType: 'none',
        isOnline: false,
        isHideClassForClients: false,
        isOpenair: false,
        notifyBookedUsers: false,
        prototypeId: null,
        numberOfVisits: 0,
        numberOfQueued: 0,
        numberOfBookedVisits: 0,
        userIds: [],
        comment: comment,
        coach: mfTrainerId ? {
          id: this.spCoaches.find(coach => {
            return coach.mfId === mfTrainerId;
          }).model.id,
          color: null,
          name: null
        } : null,

        room: mfRoomId ? {
          id: this.spRooms.find(room => {
            return room.mfId === mfRoomId;
          }).model.id,
          color: null,
          name: null
        } : null,

        course: mfActivityId ? {
          id: this.spCourses.find(course => {
            return course.mfId === mfActivityId;
          }).model.id,
          color: null,
          name: null
        } : null,
        classStatus: 'available',
        isDenyRecording: false,
        messageDenyRecording: null,
        isShowInSchedule: false
      };

      await this.scheduleService.create(spClass);
      index++;
    }

    this.appendLog('расписание готово');
    this.appendLog('');
  }


  public async processWeek(url?: string): Promise<MFScheduleResponse> {
    const res = await this.mfApi.getSchedule(this.token, url, this.clubId);
    if (!res || !res.schedule) {
      throw new Error('Ошибка при запросе к API MF');
    }

    let index = 1;
    for (const item of res.schedule) {
      this.appendLog(`обрабатывается ${index} занятие  из ${res.schedule.length}`);

      const firstCoach = item.trainers && item.trainers.length ? { id: item.trainers[0].id, title: item.trainers[0].title } : null;

      if (firstCoach) {
        if (!this.coaches.find(coach => {
          return coach.id === firstCoach.id;
        })) {
          const trainer = await this.mfApi.getTrainer(firstCoach.id, this.clubId, this.token);
          this.coaches.push(trainer);
        }
      }

      if (item.activity) {
        if (!this.courses.find(course => {
          return course.id === item.activity.id;
        })) {
          const _class = await this.mfApi.getClass(item.id, this.token, this.clubId, );
          this.courses.push(_class.activity);
        }
      }

      if (item.room) {
        if (!this.rooms.find(room => {
          return room.id === item.room.id;
        })) {
          this.rooms.push(item.room);
        }
      }

      index++;
    }

    return res;
  }

  private async makeSpRoom(room: MFRoom) {
    const index = this.spRooms.length;
    const spRoom: IClubroomModel = {
      id: null,
      name: room.title,
      entityStatus: EntityStatus.published,
      color: this.colors[index % this.colors.length],
      imageUrl: null,
      length: null,
      medias: {
        videos: [],
        images: []
      },
      square: null,
      width: null,
      address: null,
      geoLat: null,
      geoLon: null
    };

    this.spRooms.push(
      {
        mfId: room.id,
        model: spRoom
      }
    );
  }

  private async makeSpCourse(activity: MFActivity) {
    const index = this.spCourses.length;

    const spCourse: ICourseModel = {
      description: activity.description,
      id: null,
      entityStatus: EntityStatus.published,
      coaches: [],
      color: this.colors[index % this.colors.length],
      group: activity.type,
      sortOrder: null,
      imageUrl: null,
      shortDescription: null,
      medias: {
        videos: [],
        images: []
      },
      name: activity.title,
      clubConfirmationMethod: ClubConfirmationMethod.NotRequired,
      isCoachForClassRequired: false,
      isPopular: false,
      isVoluntaryBookingAllowed: false, // как понять, что это true?
      rooms: [],
      subscriptionPlans: [],
      trainingCategories: [],
      workingTime: {
        mon: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        tue: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        wed: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        thu: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        fri: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        sat: { 'hourFrom': '00:00', 'hourTo': '23:59' },
        sun: { 'hourFrom': '00:00', 'hourTo': '23:59' },
      }
    };

    this.spCourses.push({
      mfId: activity.id,
      model: spCourse
    });
  }


  private async makeSpCoach(coach: MFTrainer) {
    const index = this.spCoaches.length;
    const name = coach.title.split(' ');
    let img: IImage = null;

    if (coach.photo) {
      try {
        this.appendLog(`загружается фото тренера ${coach.title}`);
        const buffer: ArrayBuffer = await this.mfApi.fetch(coach.photo, 'blob');
        const file = new File([buffer], `coach_${coach.id}_photo.jpg`);
        this.appendLog(`фото тренера ${coach.title} загружено`);

        img = {
          id: null,
          file: file,
          isDefault: true,
          url: null,
          filename: null,
          body: null,
        };
      } catch (e) {
        this.appendLog(`не удалось загрузить фото тренера ${coach.title}, он будет импортирован без фото`);
      }
    };

    const spCoach: ICoachModel = {
      description: coach.description,
      entityStatus: EntityStatus.published,
      position: coach.position,
      firstName: name[0],
      lastName: name.length > 1 ? name[1] : null,
      group: coach.activityTypes
        .map(activity => {
          return activity.title;
        })
        .join(', '),
      sortOrder: null,
      phone: null,
      birthday: null,
      color: this.colors[index % this.colors.length],
      experience: null,
      quote: null,
      primaryImageUrl: null,
      workApproach: null,
      id: null,
      imageUrl: null,
      medias: {
        images: img ? [img] : [],
        videos: []
      }
    };

    this.spCoaches.push(
      {
        mfId: coach.id,
        model: spCoach
      }
    );
  }

  public async clean() {
    const ctx = this.contextService.getRoutingParams();
    const coaches = await this.spApi.coaches.getList(ctx);
    const courses = await this.spApi.courses.getList(ctx);
    const rooms = await this.spApi.rooms.getList(ctx);
    const classes: Array<IClassItemModel> = (await this.spApi.schedule.getList(ctx)).classes;

    for (const coach of coaches) {
      await this.spApi.coaches.setStatus(ctx, EntityStatus.deleted, coach);
    }

    for (const course of courses) {
      await this.spApi.courses.setStatus(ctx, EntityStatus.deleted, course);
    }

    for (const room of rooms) {
      await this.spApi.rooms.setStatus(ctx, EntityStatus.deleted, room);
    }


    for (const _class of classes) {
      _class.entityStatus = EntityStatus.deleted;
      await this.spApi.schedule.saveItem(ctx, _class);
    }
  }
}
