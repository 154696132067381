
<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Электронная очередь</span>
    </div>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="isLoading">
    <div @animation *ngIf="electronicQueuesFilter && electronicQueuesFilter.length" class="form-group">
      <div class="row">
        <div class="col-xs-12">
          <div class="info-text icons-prompt">
            <p>
                <span class="icon-check icons-prompt">
                        <span class="icon-check__left"></span>
                        <span class="icon-check__right"></span>
                      </span>
              <span class="icons-description">&mdash; отмечает посещение занятия и списывает одно посещение с
                  абонемента</span>
            </p>
            <p>
                <span class="icon-cross icons-prompt">
                        <span class="icon-cross__minus"></span>
                      </span>
              <span class="icons-description">&mdash; отмечает прогул занятия клиентом и списывает одно посещение с
                  абонемента</span>
            </p>
            <p>
                      <span class="icon-cross icon-delete">
                        <span class="icon-cross__left"></span>
                        <span class="icon-cross__right"></span>
                      </span>
              <span class="icons-description"> ("Отмена посещения") &mdash; отменяет запись клиента на занятие, посещение с абонемента не
                  списывается</span>
            </p>
          </div>
        </div>
      </div>

      <div class="table rowHover check-queue">
        <div class="table-row_head">
          <div class="table-cell table-title-cell">Номер записи</div>
          <div class="table-cell table-title-cell">Пришел/Пропустил</div>
          <div class="table-cell table-title-cell">Клиент</div>
          <div class="table-cell table-title-cell">Оплачено/Не оплачено</div>
          <div class="table-cell table-title-cell">Занятие</div>
          <div class="table-cell table-title-cell">...</div>
        </div>

        <div @animation *ngFor="let queue of electronicQueuesFilter; index as i" class="table-row" [class.canceled-visit]="queue.isCanceled" [class.missed-visit]="queue.isMissed">
          <div class="table-cell flex-center"><span class="queue-value">{{queue.queue}}</span></div>
          <div class="table-cell table-cell_action">
            <button class="btn btn-clear active check-visit_btn" (click)="confirmVisited(queue)">
                <span class="icon-check">
                  <span class="icon-check__left"></span>
                  <span class="icon-check__right"></span>
                </span>
            </button>
            <span class="mobile__label">Пришел</span>
            <button class="btn btn-clear active check-visit_btn" (click)="confirmMissed(queue)">
                <span class="icon-cross">
                  <span class="icon-cross__minus"></span>
                </span>
            </button>
            <span class="mobile__label">Пропустил</span>
            <button class="btn btn-clear active btn__delete check-visit_btn" (click)="cancelBooking(queue)">
                <span class="icon-cross icon-delete">
                  <span class="icon-cross__left"></span>
                  <span class="icon-cross__right"></span>
                </span>
            </button>
            <span class="mobile__label">Отмена посещения</span>
          </div>

          <div class="table-cell">
            <div class="table-cell__client_wrap">
              <div class="table-cell__avatar">
                <div class="table-cell__avatar-default" *ngIf="!queue.primaryPhotoUrl">
                  <span class="sp sp-user"></span>
                </div>
                <div class="table-cell__avatar-default" *ngIf="queue.primaryPhotoUrl" [style.background]="'url(' + queue.primaryPhotoUrl + ')'"></div>
              </div>

              <div class="table-cell__client__desc">
                <div class="client__desc">
                  <span class="table-cell__client__name">{{queue.fullName}}</span>
                  <span class="table-cell__client__phone" *ngIf="queue.phone">{{ queue.phone}}</span>
                  <span class="table-cell__client__email" *ngIf="queue.email">{{ queue.email}}</span>
                  <ng-container *ngIf="!queue.phone && !queue.email">
                    <span class="table-cell__client__phone gray">[не&nbsp;указано]</span>
                  </ng-container>
                  <span class="table-cell__client__visit-status" [ngClass]="{canceled: !queue.visitStatus === 'booked'}">{{ queue.currentVisitStatusText }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-cell flex-center">
            <span [class.color-red]="!(queue.sPClientSubscriptionId || queue.clubClientSubscriptionId)"
                  [class.color-green]="(queue.sPClientSubscriptionId || queue.clubClientSubscriptionId)">{{(queue.sPClientSubscriptionId || queue.clubClientSubscriptionId) ? 'Оплачено' : 'Не оплачено'}}</span>
          </div>

          <div class="table-cell flex-center">
            <span>{{queue.classTitle}} в {{queue.startTime | date: 'dd.MM.yyyy HH:mm' : 'UTC'}}</span>
          </div>

          <div class="table-cell flex-center">
            <button type="button" class="btn-special" (click)="cancelBooking(queue)">Отмена посещения</button>
          </div>
        </div>
      </div>
    </div>
    <p class="empty-queue" @animation *ngIf="!electronicQueuesFilter || electronicQueuesFilter?.length == 0" style="text-align: center;">
      На данный момент записи для подтверждения отсутствуют.<br /><a (click)="reload()">Нажмите для обновления вручную</a>
    </p>
  </ng-container>
</div>



