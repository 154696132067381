export interface IPayoutRatesModel {
  id: string;
  coachId: string;
  clubId: string;
  paymentTypeAlias: PaymentTypeAlias;
  salary: string;
  personalClassRate: string;
  fixedClassRate: string;
  personInGroupRate: string;
  subscriptionPercentRate: number;
  forUnlimitedRate: number;
  effectiveSinceDate: string;
  isEnableMode: boolean;
}

export interface IClassRateParam {
  coachId: string;
  classIds: string[];
}

export interface ClassRate {
  id: string;
  coachId: string;
  clubId: string;
  salary: number;
  personalClassRate: number;
  fixedClassRate: number;
  personInGroupRate: number;
  subscriptionPercentRate: number;
  forUnlimitedRate: number;
  effectiveSinceDate: Date;
}

export enum PaymentTypeAlias {
  class = 'class',
  personInGroup = 'personInGroup',
  subscription = 'subscription'
}

export const PaymentTypeAliasDisplayName: { [ key in PaymentTypeAlias | 'salary' | 'other' ]: string } = {
  class: 'За занятие',
  personInGroup: 'За количество занимающихся',
  salary: 'Оклад',
  other: 'Прочее',
  subscription: 'За абонемент'
};
