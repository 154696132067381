import { Component } from '@angular/core';
import { GroupsService } from './services/groups.service';
import { IGroupModel } from 'app/common/models/group.model';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { Injector } from '@angular/core';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})

export class GroupsComponent extends TableListComponent<IGroupModel> {
    public static readonly componentName: string = 'GroupsComponent';

    constructor(
        injector: Injector,
        protected service: GroupsService
    ) {
        super(injector, service);
    }

    public isActive(item: IGroupModel) {
        return item.entityStatus === EntityStatus.published;
    }
}
