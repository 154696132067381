
<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default" class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Нерабочее время клуба</span>
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="isLoading">
    <div class="clubnets-container">
      <div class="table-responsive" style="overflow: initial;">
        <div class="main-block-add-elements">
          <div class="btn-add-element" *ngIf="!isEnableAdd" (click)="isEnableAdd = true">
            <span class="drop-zone__btn"><span class="sp-gallery_addphoto"></span></span>
          </div>
          <app-club-down-time-add @animation
                              (result)="addElement($event)"
                              *ngIf="isEnableAdd"
                              [clubId]="context.clubId"
                              [rooms]="rooms"
          ></app-club-down-time-add>
        </div>
        <p *ngIf="clubDownTime?.length == 0" style="text-align: center">Данных о нерабочем времени клуба нет</p>
        <table class="table" *ngIf="clubDownTime?.length > 0">
          <thead>
          <tr>
            <td class="table-cell table-title-cell" colspan="2">Нерабочие время</td>
            <td class="table-cell table-title-cell">Залы</td>
            <td class="table-cell table-title-cell">Комментарий</td>
            <td class="table-cell table-title-cell"></td>
            <td class="table-cell table-title-cell"></td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let element of clubDownTime'>
            <app-club-down-time-element (resultRemove)="removeElement($event)"
                                    (resultEdit)="edit($event)"
                                    [element]="element"
                                    @animation
                                    [rooms]="rooms"
            ></app-club-down-time-element>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>
