import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IReportParameter, ReportValueType} from '../../../common/models/reports.model';
import * as moment from 'moment';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {ContextService} from '../../../common/services/context.service';
import {ExcelService} from '../../../common/services/excel.service';

@Component({
  selector: 'app-create',
  templateUrl: './registry-create.component.html',
  styleUrls: ['./registry-create.component.scss']
})
export class RegistryCreateComponent implements OnInit {
  public params: IReportParameter[] = [];
  public isValid: boolean;
  public validationStatus: string;
  public isDoubleReport = false;
  public isEliminateDuplicates = true;
  public title = 'Создать реестр';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiV2: ApiV2Service,
              public context: ContextService,
              private excelService: ExcelService) { }

  async ngOnInit(): Promise<any> {
    if (this.context.getRoutingParams()?.type === 'detailedRegister') {
      this.title = 'Скачать детальный реестр';
    } else if (this.context.getRoutingParams()?.type === 'summaryReport') {
      this.title = 'Скачать сводный отчет';
    }

    this.params.push({
      name: 'dateFrom',
      displayName: 'Дата начала периода (включительно)',
      valueType: ReportValueType.Date,
      editor: 'PeriodSelectorFrom',
      isRequired: true,
      isHidden: null,
      value: null
    },
    {
      name: 'dateTo',
      displayName: 'Дата конца периода (включительно)',
      valueType: ReportValueType.Date,
      editor: 'PeriodSelectorTo',
      isRequired: true,
      isHidden: null,
      value: null
    });
  }

  public onParamChange(): any {
    setTimeout(() => {
      this.isValid = true;
      this.validationStatus = '';
      this.params.reduce((accum, param) => {
        if (!param.value && param.isRequired) {
          this.isValid = false;
          this.validationStatus += `необходимо заполнить поле '${param.displayName}'<br>`;
        }
        if (param.editor === 'PeriodSelectorFrom' || param.editor === 'PeriodSelectorTo') {
          accum[param.editor] = param.value;
          if (accum['PeriodSelectorFrom'] && accum['PeriodSelectorTo']) {
            if (moment(accum['PeriodSelectorTo'], 'DD.MM.YYYY').valueOf() - moment(accum['PeriodSelectorFrom'], 'DD.MM.YYYY').valueOf() < 0) {
              this.isValid = false;
              this.validationStatus += 'Дата конца периода не может быть раньше даты начала периода<br>';
            }
          }
        }
        return accum;
      }, {});
    }, 1);
  }

  public close(): void {
    if (this.context.getRoutingParams().type)
      this.router.navigate(['../../'], { relativeTo: this.route });
    else
      this.router.navigate(['../'], { relativeTo: this.route });
  }

  async getReportResult(): Promise<any> {
    if (this.isValid) {
      const dateFrom = moment(this.params[0].value, 'DD.MM.YYYY').add('1', 'day').add(-17, 'hour').toDate();
      const dateTo = moment(this.params[1].value, 'DD.MM.YYYY').add('1', 'day').add(-17, 'hour').toDate();
      if (!this.context.getRoutingParams().type) {
        const registry = await this.apiV2.registry.add({
          dateFrom: dateFrom,
          dateTo: dateTo,
          isDoubleReport: this.isDoubleReport,
          isEliminateDuplicates: this.isEliminateDuplicates
        });
        if (registry && registry.id) {
          this.router.navigate([`../edit`], {relativeTo: this.route, queryParams: { id: registry.id }});
        }
      } else {
        if (this.context.getRoutingParams().type === 'summaryReport') {
          const registry = await this.apiV2.registry.summaryReport({
            dateFrom: dateFrom,
            dateTo: dateTo
          });
          const registryExport: any[] = [];
          registry.forEach(m => {
            const element: any =  {
              'ИНН': m.inn,
              'Наименование': m.legalName,
              'Договор-основание оплаты': m.contractBasisPayment,
              'Сумма': m.published,
              'Удержано': m.hold,
              'Взаиморасчёты': m.mutualSettlement,
              'Возврат': m.refund,
              'Комиссия': m.commission,
              'Название клуба': m.clubName,
            };
            registryExport.push(element);
          })
          this.excelService.exportAsExcelFile(registryExport, `Сводный отчет-${moment(dateFrom).format('DD.MM.YYYY')}-${moment(dateTo).format('DD.MM.YYYY')}`).then(() => {
            setTimeout(() => {
              //this.router.navigate(['../../'], { relativeTo: this.route });
            }, 1000)
          });
        } else if (this.context.getRoutingParams().type === 'detailedRegister') {
          const registry = await this.apiV2.registry.detailedRegister({
            dateFrom: dateFrom,
            dateTo: dateTo
          });
          const registryExport: any[] = [];
          registry.forEach(m => {
            const element: any =  {
              'Название клуба': m.club,
              'Покупатель': m.fullName ?? m.contact,
              'Комментарий': m.comment,
              'Сумма': m.amount,
              'Бонусы': m.bonuses,
              'Город': m.city,
              'Банк': m.bankName,
              'Комиссия': m.commission
            };
            registryExport.push(element);
          })
          this.excelService.exportAsExcelFile(registryExport, `Детальный реестр-${moment(dateFrom).format('DD.MM.YYYY')}-${moment(dateTo).format('DD.MM.YYYY')}`).then(() => {
            setTimeout(() => {
              this.router.navigate(['../../'], { relativeTo: this.route });
            }, 1000)
          });
        }
      }
    }
  }
}
