import { Injectable } from '@angular/core';
import { MFScheduleResponse, MFTrainer, MFClass } from 'app/components/club-mf-import/mf-models';

@Injectable()
export class MFApiClient {

    private readonly mfBase = this.decodeString(
        `0,19,31,4,6,92,69,73,26,3,16,6,0,2,0,26,12,10,25,72,25,25`
            .split(',')
            .map(item => {
                return parseInt(item, 10);
            }),
        `hgktufjfwlrofkttiyjfkl`
    );
    private readonly apiBase = `${this.mfBase}/api/v6`;

    private decodeString(input: number[], code: string) {
        const res = [];
        for (let i = 0; i < input.length; i++) {
            res.push(input[i] ^ code.charCodeAt(i));
        };
        return String.fromCharCode(...res);
    }

    public fetch(url: string, mode: 'blob' | 'json', token?: string): Promise<any> {
        const init: RequestInit = {
            method: 'GET',
            cache: 'default',
            mode: 'cors'
        };

        if (token) {
            const headers = new Headers({ 'Authorization': `Bearer ${token}` });
            init.headers = headers;
        }

        try {
            if (mode === 'json') {
                return fetch(url, init)
                    .then(function (response) {
                        if (response.status === 401) {
                            return Promise.reject('Ошибка авторизации при запросе к API MF. Проверьте правильность указания токена и номера клуба.');
                        }
                        return response.blob();
                    })
                    .then(function (blob) {
                        return new Promise(resolve => {
                            const reader = new FileReader();
                            reader.onload = () => {
                                resolve(JSON.parse(reader.result as string)); //TODO: as string??
                            };
                            reader.readAsText(blob);
                        });
                    });
            } else {
                return fetch(url, init)
                    .then(response => response.arrayBuffer());
            }
        } catch (e) {
            throw new Error('Ошибка при запросе к API MF');
        }
    }

    public getSchedule(token: string, url?: string, club?: string, year?: number, week?: number): Promise<MFScheduleResponse> {
        const _url = url !== undefined ? `${this.mfBase}${url}` :
            `${this.apiBase}/club/${club}/schedule.json` + ((year !== undefined && week !== undefined) ? `?year=${year}&week=${week}` : '');

        return this.fetch(_url, 'json', token);
    }

    public getTrainer(id: number, club: number | string, token: string): Promise<MFTrainer> {
        return this.fetch(`${this.apiBase}/trainer/${id}.json?clubId=${club}`, 'json', token);
    }

    public getClass(id: number, token: string, club: number | string): Promise<MFClass> {
        return this.fetch(`${this.apiBase}/schedule/${id}/item.json?clubId=${club}`, 'json', token);
    }
}
