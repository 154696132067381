<div appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>

  <div class="row__field" [hidden]="enableEdit" *ngIf="isDefault()" (click)="onBeginEdit()">
    <table class="table">
      <thead>
        <tr>
          <td class="field">[Не задано]</td>
        </tr>
      </thead>
    </table>
  </div>


  <div class="row__field" [hidden]="enableEdit" *ngIf="!isDefault() || enableEdit" (click)="onBeginEdit()">
    <table class="table preview">
      <thead>
        <tr>
          <td class="table-cell">День недели</td>
          <td class="table-cell">Время работы</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let day of days">
          <td class="table-cell">
            <span class="long">{{day.long}}</span>
            <span class="short">{{day.shortRu}}</span>
          </td>
          <td class="table-cell">
            <span>{{tempData[day.short].hourFrom|day2string:0}}</span>
            <span *ngIf="tempData[day.short].hourFrom!='all' && tempData[day.short].hourFrom!='none'">
              {{tempData[day.short].hourTo|day2string:1}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row__field" [hidden]="!enableEdit" *ngIf="!isDefault() || enableEdit">
    <table class="table">
      <thead>
        <tr>
          <td class="table-cell">День недели</td>
          <td class="table-cell">Время работы</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let day of days">
          <td class="table-cell">
            <span class="long">{{day.long}}</span>
            <span class="short">{{day.shortRu}}</span>
          </td>
          <td class="table-cell">
            <select class="select select__long" [(ngModel)]="tempData[day.short].hourFrom" (change)="onChangeCVA(day)">
              <option [value]="hour" *ngFor="let hour of hours|slice:0:2">{{hour|day2string:0}}</option>
              <option [value]="hour" *ngFor="let hour of hours|slice:2">{{hour|day2string:0}}</option>
            </select>
            <select class="select select__long" [(ngModel)]="tempData[day.short].hourTo" *ngIf="tempData[day.short].hourFrom!='all' && tempData[day.short].hourFrom!='none'"
              (change)="onChangeCVA(day)">
              <option [value]="hour" *ngFor="let hour of hours | slice:2">{{hour|day2string:1}}</option>
          </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
