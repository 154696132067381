import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';

import { ContextService } from 'app/common/services/context.service';
import { AuthService } from 'app/common/services/auth.service';
import { BrowserStateService } from 'app/common/services/browser-state.service';
import { debounceTime } from 'rxjs/operators';
import { IModuleRoute } from 'app/common/services/data/module-routes';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styles: [`
    .navigation__nav_li.active {cursor: pointer}
  `]
})

export class AsideComponent implements OnInit {
  @Input('isOpen') isOpen: boolean;
  @Output() updateOpen = new EventEmitter<boolean>();
  public navigations: Array<IModuleRoute> = [];
  public isMobile;
  public componentReady = false;
  public isInClubContext: boolean;
  public currentUrl: UrlSegment[] = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public contextService: ContextService,
    public route: ActivatedRoute,
    public browserStateService: BrowserStateService
  ) {
    this.isMobile = this.browserStateService.isMobile();
  }

  public isActive(item: IModuleRoute) {
    return this.currentUrl.some(x => x.path === item.url);
  }

  public updateNavigations() {
    this.navigations = this.contextService.navigations;
    this.componentReady = true;
  }

  public click (event: MouseEvent) {
    console.log(event.which);
    event.preventDefault();
  }

  ngOnInit() {
    this.contextService.contextInfo.pipe(
      debounceTime(100))
      .subscribe(ctx => {
        if (ctx) {
          setTimeout(() => this.updateNavigations(), 0)
        }
      });

    this.contextService.currentUrl.pipe(
      debounceTime(100))
      .subscribe(() => this.updateNavigations());

    this.contextService.currentUrl.subscribe(url => this.currentUrl = url);
  }

  close() {
    this.isOpen = false;
    this.updateOpen.emit();
  }
}
