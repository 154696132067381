import { Pipe, PipeTransform } from '@angular/core';
import {CollectionsTypes} from '../models/collections.model';

@Pipe({
  name: 'collectionsTypes'
})
export class CollectionsTypesPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch ( value ) {
      case CollectionsTypes.clubs: {
        return 'Подборка клубов';
      }
      case CollectionsTypes.subscriptions: {
        return 'Подборка абонементов';
      }
      case CollectionsTypes.courses: {
        return 'Подборка занятий';
      }
      case CollectionsTypes.conditionSearch: {
        return 'Результаты поиска';
      }
      case CollectionsTypes.conditionImages: {
        return 'Подборка фильтров (с картинкой)';
      }
      case CollectionsTypes.conditionButtons: {
        return 'Подборка фильтров (без картинки)';
      }
      case CollectionsTypes.slide: {
        return 'Слайдер';
      }
      case CollectionsTypes.buttonCards: {
        return 'Кнопки карточками';
      }
    }
    return value;
  }
}
