import { Injectable, Injector } from '@angular/core';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { IWebhookModel } from '../../common/models/webhook.model';

@Injectable()
export class WebhookService extends TableListService<IWebhookModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  getItemList(): Promise<IWebhookModel[]> {
    return this.apiV2Service.webhook.findByClubId(this.routingParams.clubId);
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiV2Service.webhook.getById(req.id);
  }

  save(model: IWebhookModel) {
    return this.apiV2Service.webhook.save(model);
  }

  create(model: IWebhookModel) {
    return this.save(model);
  }
}
