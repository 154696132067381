import { Component, Injector } from '@angular/core';
import { TableListComponent } from 'app/common/table-list/table-list.component';
import { SubscriptionService } from 'app/components/subscriptions/services/subscription.service';
import { ISubscription } from 'app/common/models/subscription.model';
import { EntityStatus } from 'app/common/models/entity-status.model';
@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent extends TableListComponent<ISubscription> {
  public static readonly componentName: string = 'SubscriptionsComponent';

  constructor(
    injector: Injector,
    protected service: SubscriptionService
  ) {
    super(injector, service);
  }

  public isActive(item: ISubscription) {
    return item.entityStatus === EntityStatus.published;
  }
}
