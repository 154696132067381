import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {ClubDownTimeComponent} from '../../components/club-down-time/club-down-time.component';

export const clubDownTime: Route = {
  path: ':cityId/:clubNetId/:clubId/downtime',
  component: ClubDownTimeComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Время простоя клуба'}
};
