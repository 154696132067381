export interface IWebhookModel {
  id: string;
  entityStatus: string;
  clubId: string;
  isActive: boolean;
  createdAt: Date;
  updateAt: Date;
  url: string;
  eventType: any;
  token: string;
  name: string;
  description: string;
}

export enum EventType {
  /*Unknown = 0,

  /// <summary>
  /// Целевая сущность создана (время события равно времени создания сущности).
  /// </summary>
  Created = 100,

  /// <summary>
  /// Статус целевой сущности изменён на "Черновик".
  /// </summary>
  Draft = 120,

  /// <summary>
  /// Статус целевой сущности изменён на "Опубликовано".
  /// </summary>
  Published = 130,

  /// <summary>
  /// Статус целевой сущности изменён на "Удалено".
  /// </summary>
  Deleted = 140,

  /// <summary>
  /// Состояние целевой сущности изменено.
  /// </summary>
  Updated = 150,

  /// <summary>
  /// Увеличение счётчика просмотров для целевой сущности.
  /// </summary>
  PageView = 160,

  /// <summary>
  /// Пользователь поставил лайк для целевой сущности.
  /// </summary>
  Like = 170,

  /// <summary>
  /// Пользователь отменил поставленный лайк для целевой сущности.
  /// </summary>
  LikeCancel = 180,

  /// <summary>
  /// Пользователь поставил дизлайк для целевой сущности.
  /// </summary>
  Dislike = 190,

  /// <summary>
  /// Пользователь отменил поставленный дизлайк для целевой сущности.
  /// </summary>
  DislikeCancel = 200,

  /// <summary>
  /// Пользователь добавил целевую сущность в избранное.
  /// </summary>
  AddToFavorites = 210,

  /// <summary>
  /// Пользователь удалил целевую сущность из избранного.
  /// </summary>
  RemoveFromFavorites = 220,

  /// <summary>
  /// Чем-то поделились
  /// </summary>
  Shared = 230,*/



  /*/// <summary>
  /// Создана учётная запись пользователя.
  /// </summary>
  AccountCreated = 500,

  /// <summary>
  /// Обновлен аккаунт пользователя или его профиль.
  /// </summary>
  AccountUpdated = 550,

  /// <summary>
  /// Объединение учётных записей пользователя.
  /// </summary>
  AccountsMerged = 560,

  /// <summary>
  /// Для аккаунта был активирован реферальный код.
  /// </summary>
  AccountReferalCodeActivated = 570,

  /// <summary>
  /// Обновлен город пользователя.
  /// </summary>
  AccountCityUpdated = 580,

  /// <summary>
  /// Запрос на слияние клинта клуба с аккаунтом SP
  /// </summary>
  AccountsBindRequest = 590,

  /// <summary>
  /// Абонемент создан.
  /// </summary>
  SubscriptionCreated = 1000,

  /// <summary>
  /// Абонемент изменен.
  /// </summary>
  SubscriptionUpdated = 1005,

  /// <summary>
  /// Заморозка абонемента (при этом проставляется дата заморозки у абонемента).
  /// </summary>
  SubscriptionSuspended = 1010,

  /// <summary>
  /// Разморозка абонемента (при этом меняется дата окончания абонемента).
  /// </summary>
  SubscriptionReactivated = 1015,

  /// <summary>
  /// Абонемент активирован (проставлена или изменена дата начала или конца абонемента при первом бронировании по абонементу).
  /// </summary>
  SubscriptionActivated = 1020,

  /// <summary>
  /// Абонемент отменен (при этом проставляется дата завершения абонемента).
  /// </summary>
  SubscriptionCancelled = 1030,

  /// <summary>
  /// Абонемент завершился по естественным причинам:
  /// - кончился срок действия
  /// - кончились посещения
  /// </summary>
  SubscriptionCompleted = 1040,

  /// <summary>
  /// Переход абонемента из активного состояния, в состояние ожидания повторной активации
  /// </summary>
  SubscriptionDeactivated = 1050,

  /// <summary>
  /// Сертификат клиента клуба создан.
  /// </summary>
  ClientCertificateCreated = 9000,

  /// <summary>
  /// Абонемент клиента клуба создан.
  /// </summary>
  ClientSubscriptionCreated = 1100,

  /// <summary>
  /// Заморозка абонемента клиента клуба (при этом проставляется дата заморозки у абонемента).
  /// </summary>
  ClientSubscriptionSuspended = 1110,

  /// <summary>
  /// Разморозка абонемента клиента клуба (при этом меняется дата окончания абонемента).
  /// </summary>
  ClientSubscriptionReactivated = 1115,

  /// <summary>
  /// Абонемент клиента клуба активирован (проставлена или изменена дата начала или конца абонемента при первом бронировании по абонементу).
  /// </summary>
  ClientSubscriptionActivated = 1120,

  /// <summary>
  /// Абонемент клиента клуба отменен (при этом проставляется дата завершения абонемента).
  /// </summary>
  ClientSubscriptionCancelled = 1130,

  /// <summary>
  /// Абонемент завершился по естественным причинам:
  /// - кончился срок действия
  /// - кончились посещения
  /// </summary>
  ClientSubscriptionCompleted = 1140,*/



  /*/// <summary>
  /// Занятие отменено.
  /// </summary>
  ClassCancelled = 2200,

  /// <summary>
  /// Занятие перенесено.
  /// </summary>
  ClassMoved = 2201,

  /// <summary>
  /// Резервирование занятия отклонено.
  /// </summary>
  ClassVisitRejected = 2000,

  /// <summary>
  /// Пользоватль записан на занятие.
  /// </summary>
  ClassVisitBooked = 2010,

  /// <summary>
  /// Посещение занятия отменено пользователем.
  /// </summary>
  ClassVisitCancelledByUser = 2020,

  /// <summary>
  /// Посещение занятия отменено клубом.
  /// </summary>
  ClassVisitCancelledByClub = 2030,*/

  /// <summary>
  /// Посещение занятия подтверждено клубом.
  /// </summary>
  ClassVisitVisitedByClub = 2040,

  /// <summary>
  /// Клуб отметил пропуск занятия.
  /// </summary>
  ClassVisitMissedByClub = 2120,

  /*/// <summary>
  /// Посещение занятия подтверждено пользователем.
  /// </summary>
  ClassVisitVisitedByUser = 2050,

  /// <summary>
  /// Пользователь отметил пропуск занятия.
  /// </summary>
  ClassVisitMissedByUser = 2060,

  /// <summary>
  /// Клуб подтвердил посещение, а пользователь нет.
  /// </summary>
  ClassVisitConflict = 2070,

  /// <summary>
  /// Посещение подтверждено клубом и клиентом.
  /// </summary>
  ClassVisitVisited = 2080,

  /// <summary>
  /// Автоматическое подтверждение посещения в статусе VisitedByClub job-ом до статуса Visited
  /// </summary>
  ClassVisitConfirmedBySystem = 2090,

  /// <summary>
  /// Пользователь забронировал занятие и ожидает подтверждение бронирования.
  /// </summary>
  ClassVisitWaitingBookingConfirmation = 2100,

  /// <summary>
  /// Клуб подтвердил бронирование занятия (переход занятия в статус Booked)
  /// </summary>
  ClassVisitBookingConfirmed = 2110,

  /// <summary>
  /// В скором времени (задается в настройках историй) начнется групповое занятие.
  /// </summary>
  GroupVirtualClassVisitStartSoon = 2300,

  /// <summary>
  /// Инфокарточка удалена пользователем (больше не показывать)
  /// </summary>
  CardClosedByUser = 3000,

  /// <summary>
  /// Инфокарточка перешла в состояние "неактивная" (Draft). Это событие выстреливается при деактивации карточки любым способом.
  /// В контексте события приходит содержимое контекста карточки.
  /// </summary>
  CardDeactivated = 3010,

  /// <summary>
  /// Для инфокарточек VisitRate. Пользователь был на занятии и ему понравилось.
  /// </summary>
  CardVisitedAndLiked = 3020,

  /// <summary>
  /// Для инфокарточек VisitRate. Пользователь был на занятии и ему НЕ понравилось.
  /// </summary>
  CardVisitedAndDisliked = 3030,

  /// <summary>
  /// Для инфокарточек VisitRate. Пользователь НЕ был на занятии.
  /// </summary>
  CardNotVisited = 3040,

  /// <summary>
  /// Для инфокарточек. Пользователь выполнил кастомное действие.
  /// </summary>
  CardCustomAction = 3050,*/


  /*/// <summary>
  /// Пользователь зашёл в приложение.
  /// </summary>
  ApplicationStarted = 4000, // TODO: Георгий, необходимо выстреливть это событие.*/



  /*/// <summary>
  /// В корзину добавлен новый элемент.
  /// </summary>
  CartItemAdded = 5000,


  /// <summary>
  /// Предпринята попытка поиска клубов. В данные помещается все критерии поиска и общее количество полученных результатов.
  /// </summary>
  SearchClubs = 6000,

  /// <summary>
  /// Предпринята попытка поиска занятий. В данные помещается все критерии поиска и общее количество полученных результатов.
  /// </summary>
  SearchCourses = 6500,


  PaymentWired = 7000,*/
}

// @ts-ignore
export const EventTypeList: {
  key: string;
  value: string;
}[] = Object.entries(EventType)
  .filter((value) => typeof value[1] === 'number')
  .map(([key, value]) => ({ key, value }));
