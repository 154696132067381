import { Injector, Injectable } from '@angular/core';

import { IClubroomModel } from 'app/common/models/clubroom.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class ClubroomsService extends TableListService<IClubroomModel> {
  setStatus(status: string, item: IClubroomModel) {
    return this.apiService.rooms.setStatus(this.contextService.getRoutingParams(), status, item);
  }

  constructor(injector: Injector) {
    super(injector);
  }

  getItemList() {
    return this.apiService.rooms.getList(this.contextService.getRoutingParams());
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.rooms.getItem(req);
  }

  save(model: IClubroomModel) {
    return this.apiService.rooms.saveItem(this.contextService.getRoutingParams(), model);
  }

  create(model: IClubroomModel) {
    return this.save(model);
  }
}
