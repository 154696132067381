import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IRoutingParams } from 'app/common/models/context.model';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
})
export class DashboardLinkComponent implements OnChanges {
    @Input() params: {
        id: string,
        value: string,
        context: IRoutingParams
    };
    public url: string = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params) {
            const data = changes.params.currentValue;
            const ctx: IRoutingParams = data.context;
            const baseUrl = `${ctx.cityId}/${ctx.clubNetId}/${ctx.clubId}`;
            let suffix: string;

            switch (data.type) {
                case 'Client': {
                    suffix = 'clubclients';
                    break;
                }
                case 'Course': {
                    if (data.id.indexOf('class') !== -1) {
                        data.id = data.id.replace('class', '');
                        suffix = 'clubschedule/' + new Date().toLocaleDateString('ru');
                    } else {
                        suffix = 'clubcourses';
                    }
                    break;
                }
            }
            this.url = `/${baseUrl}/${suffix}/${data.id}`;
        }
    }
}
