import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from 'app/common/models/roles.model';

@Pipe({ name: 'roleinfo' })
export class RoleInfoPipe implements PipeTransform {
    transform(value: string): { title: string; canCreate: UserRole[] } {
        switch (value) {
            case UserRole.Admin: {
                return {
                    title: 'Суперпользователь',
                    canCreate: [UserRole.Admin, UserRole.Manager, UserRole.ClubNetAdmin, UserRole.ClubAdmin, UserRole.ClubManager, UserRole.ClubCoach, UserRole.ClubCoachAssistant]
                };
            };
            case UserRole.Manager: {
                return {
                    title: 'Менеджер системы',
                    canCreate: [UserRole.ClubNetAdmin, UserRole.ClubAdmin, UserRole.ClubManager, UserRole.ClubCoach, UserRole.ClubCoachAssistant]
                };
            };
            case UserRole.ClubNetAdmin: {
                return {
                    title: 'Руководитель сети клубов',
                    canCreate: [UserRole.ClubNetAdmin, UserRole.ClubAdmin, UserRole.ClubManager, UserRole.ClubCoach, UserRole.ClubCoachAssistant]
                };
            };
            case UserRole.ClubAdmin: {
                return {
                    title: 'Руководитель клуба',
                    canCreate: [UserRole.ClubAdmin, UserRole.ClubManager, UserRole.ClubCoach, UserRole.ClubCoachAssistant]
                };
            };
            case UserRole.ClubManager: {
                return {
                    title: 'Администратор клуба',
                    canCreate: []
                };
            };
            case UserRole.ClubCoach: {
                return {
                    title: 'Тренер клуба',
                    canCreate: []
                };
            };
            case UserRole.ClubCoachAssistant: {
                return {
                    title: 'Тренер-стажёр клуба',
                    canCreate: []
                };
            };


            default:
            case UserRole.DummyRole: {
                return {
                    title: 'не указано',
                    canCreate: []
                }
            };
        }
    }
}
