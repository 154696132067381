import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cashAndCard'
})
export class CashAndCardPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case "card":
        return "Карта";
      case "cash":
        return "Наличные";
      case "app":
        return "Приложение";
      case "receipt":
        return "Оплата по квитанции";
      default:
        return null;
    }
  }

}
