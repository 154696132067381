import { ExtensionSubscriptionComponent } from 'app/components/extension-subscription/extension-subscription.component';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const extensionSubscriptionRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubclients/:id/extension',
  component: ExtensionSubscriptionComponent,
  canActivate: [AuthGuard],
  data: { title: 'Продление абонемента' }
};
