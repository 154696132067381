import {Component, Input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ContextService} from '../../common/services/context.service';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {IRoutingParams} from '../../common/models/context.model';
import {ClubDownTime} from '../../common/models/club-down-time.model';
import {ClubroomsService} from '../clubrooms/services/clubrooms.service';
import {EntityStatus} from '../../common/models/entity-status.model';
import {Obj2StringPipe} from '../../common/pipes/object-name.pipe';
import {Location} from '@angular/common';

@Component({
  selector: 'app-club-down-time',
  templateUrl: './club-down-time.component.html',
  styleUrls: ['./club-down-time.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class ClubDownTimeComponent implements OnInit {
  public static readonly componentName: string = 'ClubDownTimeComponent';

  public context: IRoutingParams;
  public clubDownTime: ClubDownTime[] = [];
  public rooms: any[] = [];
  public isLoading = false;
  public isEnableAdd = false;

  constructor(
    private contextService: ContextService,
    private clubroomsService: ClubroomsService,
    public _location: Location,
    private apiV2: ApiV2Service
  ) { }

 async ngOnInit(): Promise<any> {
    this.context = await this.contextService.getRoutingParams();
    if (this.context) {
      await this.apiV2.clubDownTime.getByClubId({ clubId: this.context.clubId, startAt: null, endAt: null }).then(async (clubDownTime) => {
        this.clubDownTime = clubDownTime;
        this.sortingClubDownTime();
        await this.clubroomsService.getItemList().then(rooms => {
          this.rooms = rooms;
          this.isLoading = true;
        });
      });
    }
  }

  async addElement(newElement: ClubDownTime[]): Promise<any> {
    if (newElement == null) {
      this.isEnableAdd = false;
    } else {
      this.clubDownTime = this.clubDownTime.concat(newElement);
      this.sortingClubDownTime();
    }
  }

  async removeElement(oldElement: ClubDownTime): Promise<any> {
    if (oldElement) {
      this.clubDownTime = this.clubDownTime.filter(x => x.id !== oldElement.id);
      this.sortingClubDownTime();
    }
  }

  async edit(editElement: ClubDownTime): Promise<any> {
    if (editElement) {
      for (const element of this.clubDownTime.filter(x => x.id === editElement.id)) {
        element.entityStatus = editElement.entityStatus;
        element.createdAt = editElement.createdAt;
        element.updateAt = editElement.updateAt;
        element.clubId = editElement.clubId;
        element.roomId = editElement.roomId;
        element.startAt = editElement.startAt;
        element.endAt = editElement.endAt;
        element.comment = editElement.comment;
      }
      this.sortingClubDownTime();
    }
  }

  private sortingClubDownTime() {
    this.clubDownTime = this.clubDownTime.sort((a, b) => {
      if (a.startAt < b.startAt) { return 1; }
      if (a.startAt > b.startAt) { return -1; }
    });
  }

  public close() { this._location.back(); }
}
