import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {CertificatesComponent} from '../../components/certificates/certificates.component';

export const certificatesRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/certificates',
  component: CertificatesComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Подарочные сертификаты'}
};
