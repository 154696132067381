import { Component, Input, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { Mixin } from 'app/common/decorators/mixin.decorator';

import { IOnValueChanged } from 'app/common/validators/on-value-changed.class';
import { IImage } from 'app/common/models/image.model';
import { IMedias } from 'app/common/models/medias.model';
import { GalleryComponentCommon } from 'app/common/components/gallery/common/gallery.component';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { moveItemInArray } from "@angular/cdk/drag-drop";
import {
  CdkDrag,
  CdkDropList, CdkDropListGroup, CdkDragMove
} from "@angular/cdk/drag-drop";
import { ViewportRuler } from "@angular/cdk/overlay";
import { GlobalPreloaderComponent } from '../../global-preloader/global-preloader.component';
import { AlertsService } from '../../alerts/services/alerts.service';

@Component({
  selector: 'app-gallery-toggle',
  templateUrl: './gallery.component.html',
  styleUrls: ['../common/gallery.component.scss', './gallery.component.scss'],
})
@Mixin([GalleryComponentCommon])
export class GalleryToggleComponent extends ToggleEditComponent<IMedias> implements IOnValueChanged {
  @Input() text: string;
  @Input() singleImage = false;
  @ViewChild('fileInput')
  fileInput: any;

  @Output() onItemOpen = new EventEmitter<string>();

  _addPhoto: ($e) => void;
  _setDefaultImage: (item: IImage) => void;
  _deleteImage: (item: IImage) => void;
  addClick: () => any;
  isEmpty: () => boolean;

  constructor(
    injector: Injector, private viewportRuler: ViewportRuler, private alertsService: AlertsService
  ) {
    super(injector);
    this.target = null;
    this.source = null;
  }

  addPhoto($e) {
    this.onBeginEdit();
    this._addPhoto($e);
  }

  public activeImage: IImage;

  openImage(item: IImage) {
    if (!item.id)
      return;
    this.onItemOpen.emit(item.id);
  }

  deleteImage(item: IImage) {
    this.onBeginEdit();
    this._deleteImage(item);
  }

  setDefaultImage(item: IImage) {
    this.onBeginEdit();
    this._setDefaultImage(item);
  }


  loadingFile: boolean = false;
  loadingString: string = ' Загрузка файлов...';
  files: NgxFileDropEntry[] = [];
  errorFiles: string[] = [];

  interval;
  startTimerUploadFile() {
    let i = 3;
    this.interval = setInterval(() => {
      if (i == 3) {
        while (this.loadingString.indexOf(".") > -1) {
          this.loadingString = this.loadingString.replace('.', '');
        }
        i = 0;
      }
      else {
        this.loadingString = this.loadingString + '.'
        i++;
      }
    }, 500)
  }

  stopTimerUploadFile() {
    clearInterval(this.interval);
  }

  uploadFiles(files: NgxFileDropEntry[]): Promise<any> {
    this.onBeginEdit();
    this.files = files;

    return new Promise(resolve => {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const reader = new FileReader();
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

          fileEntry.file((file: File) => {
            if (file.type.indexOf('image') < 0) {
              this.errorFiles.push(file.name.toString());
              if (this.errorFiles.length == this.files.length)
                resolve(console.log('all files error'))
              return;
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
              const image: IImage = {
                id: null,
                url: null,
                body: reader.result as string,
                filename: file.name,
                isDefault: this.tempData.images.length === 0,
                file: file
              };
              resolve(reader.result)
              this.tempData.images.push(image);
            };
          });
        }
      }
    });
  }

  droppedImages(files: NgxFileDropEntry[]) {
    this.loadingFile = true;
    this.isLoading = true;
    this.errorFiles = [];
    this.startTimerUploadFile();

    this.uploadFiles(files).then(() => {
      let listErrorFiles = '';
      if (this.errorFiles.length > 0) {
        for (let i = 0; i < this.errorFiles.length; i++) {
          listErrorFiles += this.errorFiles[i];
          if (i == this.errorFiles.length - 1)
            break;
          listErrorFiles += ', ';
        }

        this.alertsService.alert.next({
          type: 'danger',
          message: 'Файлы которые не были загружены: ' + listErrorFiles,
          header: 'Ошибка при загрузке файла!',
          position: 'bottom',
          timeout: 5000
        });
        this.stopTimerUploadFile();
      }
      this.isLoading = false;
      this.loadingFile = false;
      console.log('loading false')
    })
  }

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  public isLoading = false;
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;
  preload: GlobalPreloaderComponent;
  mainPage;
  ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;

    let elements = document.querySelectorAll('div');

    elements.forEach(m => {
      if (window.getComputedStyle(m, null).getPropertyValue("overflow") == 'auto' && m.hasChildNodes())
        this.mainPage = m;
    })

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);

    this.startTimerEnterPredicate();
  }

  scrollStep;
  bottomZoneScroll;
  topZoneScroll;

  dragStarted() {
    this.scrollStep = 0.5 * window.innerHeight;
    this.bottomZoneScroll = 0.9 * window.innerHeight;
    this.topZoneScroll = 100;
  }

  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        if (point.y < this.topZoneScroll) {
          this.mainPage.scrollTo({ top: this.mainPage.scrollTop - this.scrollStep, behavior: 'smooth' });
        }
        if (point.y > this.bottomZoneScroll) {
          this.mainPage.scrollTo({ top: this.mainPage.scrollTop + this.scrollStep, behavior: 'smooth' });
        }
        return;
      }
    });
  }

  dropListDropped() {
    if (!this.target)
      return;

    this.onBeginEdit();

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex)
      moveItemInArray(this.tempData.images, this.sourceIndex, this.targetIndex);
  }

  startTimerEnterPredicate(){
    this.dropTime = true;
    setTimeout(() => {
        this.dropTime = false;
      }, 200)
  }

  dropTime: boolean = true;

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    if (this.dropTime) return;

    this.startTimerEnterPredicate();

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
