import { Injectable, Injector } from '@angular/core';
import { TableListService } from 'app/common/table-list/table-list.service';
import { ISubscription } from 'app/common/models/subscription.model';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class SubscriptionService extends TableListService<ISubscription> {

  constructor(injector: Injector) {
    super(injector);
  }

  setStatus(status: string, item: ISubscription) {
    return this.apiService.subscriptions.setStatus(this.contextService.getRoutingParams(), status, item);
  }

  getItemList() {
    return this.apiService.subscriptions.getList(this.contextService.getRoutingParams(), {});
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.subscriptions.getItem(req);
  }

  save(model: ISubscription) {
    return this.apiService.subscriptions.saveItem(this.contextService.getRoutingParams(), model);
  }

  create(model: ISubscription) {
    return this.save(model);
  }
}
