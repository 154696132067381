import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundPlayService {

  constructor() { }

  public beep(): void {
    let audio = new Audio();
    audio.src = "../../../assets/audio/goes-without-saying.ogg";
    audio.load();
    audio.play();
  }

  public loadSettings<T>(getter: (values: Partial<SoundsSettings>) => T): T {
    let oldValues = JSON.parse(window.localStorage.getItem(localStorageNotificationSoundsOffKey)) || {};
    return getter(oldValues);
  }

  public saveSettings(values: Partial<SoundsSettings>) {
    let oldValues = JSON.parse(window.localStorage.getItem(localStorageNotificationSoundsOffKey)) || {};
    let newValues = {};
    Object.keys(new SoundsSettings()).forEach(x => newValues[x] = values[x] !== undefined ? values[x] : oldValues[x]);
    window.localStorage.setItem(localStorageNotificationSoundsOffKey, JSON.stringify(newValues));
  }
}

const localStorageNotificationSoundsOffKey = 'notificationSoundsOff';

class SoundsSettings {
  ActiveChatsOff: boolean = false;
}
