import {Component, Injector} from '@angular/core';

import {TableListComponent} from 'app/common/table-list/table-list.component';
import {ClubroomsService} from './services/clubrooms.service';
import {IClubroomModel} from 'app/common/models/clubroom.model';


@Component({
  selector: 'app-clubrooms',
  templateUrl: './clubrooms.component.html',
  styleUrls: ['./clubrooms.component.scss']
})

export class ClubroomsComponent extends TableListComponent<IClubroomModel> {
  public static readonly componentName: string = 'ClubroomsComponent';

  constructor(injector: Injector, protected service: ClubroomsService) {
    super(injector, service);
  };
}
