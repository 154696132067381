<div class="row">
  <div class="col-xs-12 col-sm-6" *ngIf="form">
    <form [formGroup]="form">
      <div class="form-group">
        <div class="box-title">Картинка</div>
        <div class="row__field">
          <div class="preview">
            <ng-container *ngIf="!imageBase64Src && !form.value.media">
              <p>[Нет картинки]</p>
            </ng-container>
            <ng-container *ngIf="imageBase64Src || form.value.media">
              <p><img id="blah" [src]="imageBase64Src ?? form.value.media" /></p>
            </ng-container>
          </div>
          <input hidden #fileInput type="file" (change)="readURL($event)" [multiple]="false">
          <button type="button" mat-raised-button (click)="fileInput.click()">Выбрать картинку</button>
          <mat-error *ngIf="form.get('media').hasError('required')">Это поле обязательно</mat-error>
        </div>
        <div class="box-title">Полный url куда будет вести подборка</div>
        <div class="row__field">
          <ng-container>
            <mat-form-field>
              <input class="form-control input__text input__text--long" matInput type="text" formControlName="url">
              <mat-hint>Пример: https://sportpriority.com/</mat-hint>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="row__field mt-5">
          <input type="checkbox" class="checkbox__slide" id="isTargetBlank" formControlName="isTargetBlank">
          <label for="isTargetBlank" class="checkbox__slide_label">Открыть подборку в новой вкладке/окне</label>
        </div>
        <div class="box-title">Название</div>
        <div class="row__field">
          <ng-container>
            <mat-form-field>
              <input class="form-control input__text input__text--long" matInput type="text" formControlName="title">
              <mat-error *ngIf="form.get('title').hasError('required')">Это поле обязательно</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
        <ng-container *ngIf="typeCollection == CollectionsTypes.slide">
          <div class="box-title">Описание</div>
          <div class="row__field">
            <textarea formControlName="description"></textarea>
          </div>
        </ng-container>
        <div class="box-title">Название кнопки</div>
        <div class="row__field">
          <ng-container>
            <mat-form-field>
              <input class="form-control input__text input__text--long" matInput type="text" formControlName="btnValue">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="form-group">
        <div class="row__field mt-5">
          <ng-container>
            <button class="btn btn-primary btn-small" (click)="save()">
              <span class="btn-primary__desc">Сохранить элемент в подборке</span>
            </button>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="result">
      <p *ngIf="data.length == 0" class="empty">
        Еще не добавлено ни одного элемента
      </p>
      <ng-container *ngIf="data.length > 0">
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div cdkDrag class="item" *ngFor="let item of data">
            <div>Url: <b>{{item.url}}</b></div>
            <div>Название: <b>{{item.title}}</b></div>
            <div style="margin-top: 10px;">
              <a (click)="editStart(item)">Редактировать</a> |
              <a style="color: red;" (click)="remove(item)">Удалить</a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
