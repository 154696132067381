// Основной и добавочный цвет
export const predefinedColorPairs = [
    ['#ff7272', '#d86060'],
    ['#ebab2e', '#d39a28'],
    ['#ffa34e', '#e29043'],
    ['#ffce20', '#e0b824'],
    ['#fee92b', '#ddc917'],
    ['#cccc66', '#b5b559'],
    ['#cccc00', '#b6b619'],
    ['#ccff33', '#bee647'],
    ['#c4e661', '#b9dd4e'],
    ['#77d077', '#5fc05f'],
    ['#74dab6', '#61c09f'],
    ['#99ffcc', '#86e9b8'],
    ['#3edcd3', '#38c9c1'],
    ['#72d5ff', '#70c7ec'],
    ['#9ad0f8', '#94c2e6'],
    ['#8cb8f9', '#8baee2'],
    ['#65a3ff', '#5c96ec'],
    ['#7d88ed', '#767fd3'],
    ['#b699f3', '#a98ee2'],
    ['#bd6be6', '#b269d6'],
    ['#cc66ff', '#b759e6'],
    ['#d7b7ff', '#cbacf0'],
    ['#e473e7', '#cbacf0'],
    ['#ff7eea', '#e972d5'],
    ['#ccccff', '#c2c2ee'],
    ['#c0c0c0', '#b4b3b3'],
    ['#93a4ad', '#8e9ea7'],
    ['#c9aea5', '#bba39b'],
];
