import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {MediaService} from '../../../common/services/media.service';
import {ContextService} from '../../../common/services/context.service';
import {IImageParams} from '../../../common/models/image.model';
import {CollectionsTypes} from '../../../common/models/collections.model';

@Component({
  selector: 'app-collections-slide',
  templateUrl: './collections-slide.component.html',
  styleUrls: ['./collections-slide.component.scss']
})
export class CollectionsSlideComponent implements OnInit, OnDestroy {
  @Output() result = new EventEmitter<any[]>();
  @Input() data: any[];
  @Input() typeCollection: CollectionsTypes;
  public readonly CollectionsTypes = CollectionsTypes;
  form: FormGroup;
  imageBase64Src: string;

  constructor(
    private readonly alertsService: AlertsService,
    private readonly mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(this.uuIdv4(), Validators.required),
      url: new FormControl(null),
      isTargetBlank: new FormControl(false),
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
      btnValue: new FormControl(null),
      media: new FormControl(null, Validators.required),
    });
  }

  ngOnDestroy() {
    this.result.unsubscribe();
  }

  remove(item: any) {
    this.alertsService.showConfirm({
      header: 'Удалить из подборки?',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.data = this.data.filter(x => x.id !== item.id);
          this.result.emit(this.data);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  editStart(item: any) {
    this.form.get('id').setValue(item.id);
    this.form.get('url').setValue(item.url);
    this.form.get('isTargetBlank').setValue(item.isTargetBlank);
    this.form.get('title').setValue(item.title);
    this.form.get('description').setValue(item.description);
    this.form.get('btnValue').setValue(item.btnValue);
    this.imageBase64Src = null;
    this.form.get('media').setValue(item.image ? item.image.url : null);
  }

  save() {
    this.form.markAllAsTouched();
    let _message = '';

    if (this.form.valid && this.imageBase64Src) {
      const data = this.data.find(x => x.id === this.form.value.id);
      let _base64 = this.imageBase64Src;
      _base64 = _base64.substr(_base64.indexOf(',') + 1, _base64.length);
      this.mediaService.save(_base64).then((image: IImageParams) => {
        if (!data) {
          this.data.push({...this.form.value, image});
          _message = 'Элемент успешно добавлен в подборку';
        } else {
          data.url = this.form.value.url;
          data.isTargetBlank = this.form.value.isTargetBlank;
          data.title = this.form.value.title;
          data.description = this.form.value.description;
          data.btnValue = this.form.value.btnValue;
          data.image = image;
          _message = 'Элемент в подборке успешно изменен';
        }
        this.saveGoal(_message);
      })
    } else if (this.form.valid && !this.imageBase64Src) {
      const data = this.data.find(x => x.id === this.form.value.id);
      data.url = this.form.value.url;
      data.isTargetBlank = this.form.value.isTargetBlank;
      data.title = this.form.value.title;
      data.description = this.form.value.description;
      data.btnValue = this.form.value.btnValue;
      _message = 'Элемент в подборке успешно изменен';
      this.saveGoal(_message);
    }
  }

  private saveGoal(_message: string) {
    this.alertsService.alert.next({
      type: 'success',
      message: _message,
      header: 'Успех',
      position: 'top',
      timeout: 2000
    });
    this.form.reset();
    this.imageBase64Src = null;
    this.form.get('id').setValue(this.uuIdv4());
    this.result.emit(this.data);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

  readURL(event: Event): void {
    // @ts-ignore
    if (event.target.files && event.target.files[0]) {
      // @ts-ignore
      const file = event.target.files[0];

      const reader = new FileReader();
      // @ts-ignore
      reader.onload = e => this.imageBase64Src = reader.result;

      this.form.get('media').setValue(file.name);

      reader.readAsDataURL(file);
    }
  }

  private uuIdv4() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
}
