import { Route } from '@angular/router';
import { PricesComponent } from 'app/components/prices/prices.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const pricesRoute: Route = {
  path: 'prices',
  component: PricesComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Цены' }
};
