<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание тренера</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование тренера</span>
    </div>
    <div>
      <a class="content__header-link" (click)="close()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>
    <div class="row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" myScrollTo>
              <div class="box-title">Телефон</div>
              <div class="clue-text">Не показывается клиентам</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" id="phone" formControlName="phone" [ngModel]="Model.phone">
                <label for="phone" class="field-validation-error" *ngIf="fieldErrors.phone && isSubmited">{{fieldErrors.phone}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Имя</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="firstName" formControlName="firstName" [ngModel]="Model.firstName"
              maxlength="500">
            <label for="firstName" class="field-validation-error" *ngIf="fieldErrors.firstName && isSubmited">{{fieldErrors.firstName}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Фамилия</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="lastName" formControlName="lastName" [ngModel]="Model.lastName"
              maxlength="500">
            <label for="lastName" class="field-validation-error" *ngIf="fieldErrors.lastName && isSubmited">{{fieldErrors.lastName}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" myScrollTo>
              <div class="box-title">Должность</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" id="position" formControlName="position" [ngModel]="Model.position"
                  maxlength="500">
                <label for="position" class="field-validation-error" *ngIf="fieldErrors.position && isSubmited">{{fieldErrors.position}}</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" myScrollTo>
              <div class="box-title">Специализация</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" maxlength="500" id="role"
                  formControlName="group" [ngModel]="Model.group">
                <label for="birthday" class="field-validation-error" *ngIf="fieldErrors.group && isSubmited">{{fieldErrors.group}}</label>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" myScrollTo>
              <div class="box-title">Порядок сортировки</div>
              <div class="row__field">
                <input type="number" class="input__text input__text--long" id="sortOrder" formControlName="sortOrder" [ngModel]="Model.sortOrder" maxlength="500">
                <label for="sortOrder" class="field-validation-error" *ngIf="fieldErrors.sortOrder && isSubmited">{{fieldErrors.sortOrder}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Образование, опыт, достижения</div>
          <div class="row__field pd">

            <ckeditor placeholder="Заслуги, регалии, отзывы клиентов, специальное обучение и т.д." [(ngModel)]="Model.description" formControlName="description"
              id="description" maxlength="10000">
            </ckeditor>
            <label for="description" class="field-validation-error" *ngIf="fieldErrors.description && isSubmited">{{fieldErrors.description}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Подход к работе и методики</div>
          <div class="row__field pd">
            <textarea type="text" maxlength="10000" placeholder="Подход к работе и методики" formControlName="workApproach" id="workApproach"
              [ngModel]="Model.workApproach"></textarea>
            <label for="workApproach" class="field-validation-error" *ngIf="fieldErrors.workApproach && isSubmited">{{fieldErrors.workApproach}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Цитата</div>
          <div class="row__field pd">
            <div class="row__field-tooltip">Текст вводится без кавычек (!) В приложении они появятся автоматически.</div>
            <textarea type="text" maxlength="10000" placeholder="Цитата" formControlName="quote" id="quote" [ngModel]="Model.quote"></textarea>
            <label for="quote" class="field-validation-error" *ngIf="fieldErrors.quote && isSubmited">{{fieldErrors.quote}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">День рождения</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="birthday" formControlName="birthday" [ngModel]="Model.birthday"
              placeholder="В формате дд.мм.гггг">
            <label for="birthday" class="field-validation-error" *ngIf="fieldErrors.birthday && isSubmited">{{fieldErrors.birthday}}</label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-down"></div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="row__field">
            <app-gallery-cva [ngModel]="Model.medias" [multipleUpload]="true" (ngModelChange)="fastSaveMedias()" formControlName="medias" [title]="'Фотогалерея'" (onItemOpen)="openImage($event)"></app-gallery-cva>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">Цвет в расписании</div>
          <div class="clue-text">Выберите любой или введите свой в формате #abc или #abcdef</div>
          <div class="row__field">
            <app-color-picker formControlName="color" [ngModel]="Model.color"></app-color-picker>
            <label for="color" class="field-validation-error" *ngIf="fieldErrors.color && isSubmited">
              {{fieldErrors.color}}
            </label>
          </div>
        </div>
      </div>

      <div *ngIf="Model.id && isSalaryCalculationAvailable && isCoachRatesAvailable" class="col-xs-12">
        <app-payout-rates [coachId]="Model.id"></app-payout-rates>
      </div>
 
      <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isSalaryCalculationAvailable">
        <div class="form-group" myScrollTo>
          <div class="box-title">Дата увольнения</div>
          <div class="row__field">
            <app-date-picker-rates id="chargeRateUntilDate" formControlName="chargeRateUntilDate" [(ngModel)]="Model.chargeRateUntilDate" placeholder=""></app-date-picker-rates>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <br>
          <br>
          <div class="info-text">

            <div [ngClass]="{'info-text__box' : !isNotSaved()}">
              <span class="icon icon-about"></span>
              <span app-key-help [key]="'draftText'"></span>
            </div>

            <div *ngIf="!isNotSaved()">
              <span class="icon icon-about"></span>
              Статус:
              <ng-container *ngIf="isDraft()">Черновик</ng-container>
              <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isEditAvailable">

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveDraft'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-primary" (click)="copyEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveNew'"></span>
                  </span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="Model.id != null">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>

      <div class="clearfix hidden-md-up"></div>

    </div>

  </form>
</div>
