import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';

let optIndex = 0;

@Component({
  selector: 'app-multiple-checkbox',
  templateUrl: './multiple-checkbox.component.html',
  styleUrls: ['./multiple-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultipleCheckboxComponent),
    multi: true
  }
  ]
})

export class MultipleCheckboxComponent implements ControlValueAccessor {
  // FIXME: Это то же самое, что и category-picker, разница только во вложенных списках. Видимо, нужно обобщить

  public isVisible = false;

  public id = optIndex++;

  @Input() options;
  private data: Array<any> = [];

  constructor() { }

  onChange(event, option) {
    let _data: Array<any> = _.cloneDeep(this.data);
    if (event.target.checked) {
      if (!_data.includes({ id: option.id, name: option.name })) {
        _data.push({ id: option.id, name: option.name });
      }
    } else {
      _data = _data.filter(x => { return x.id !== option.id; })
    }
    this.writeValue(_data);
    this.propagateChange(_data);
  }

  isChecked(option) {
    return this.data
      .find(x => { return x.id === option.id; }) ? true : false;
  }

  chooseAll() {
    let _data: Array<any> = _.cloneDeep(this.data);
    _data = this.options;
    this.writeValue(_data);
    this.propagateChange(_data);
  }

  public getFullModel(option) {
    const res = this.options.filter(x => {
      return x.id === option.id;
    });
    return res.length > 0 ? res[0] : {
      id: null,
      name: '[не найдено]'
    };
  }

  private propagateChange: (any?: any) => void = () => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
    }
  }
}
