import { OnInit, Input, Output, EventEmitter, HostListener, Injector, Directive } from '@angular/core';
import * as _ from 'lodash';
import { OnValueChangeBase } from '../../validators/on-value-changed.class';

@Directive()
export class ToggleEditComponent<T> extends OnValueChangeBase implements OnInit {
  @Input() tooltip;
  @Input() isReadonly;
  @Input() title;
  @Input() type;
  @Input() validationMessage;
  @Output() onFinishEdit = new EventEmitter();

  // то, на что биндится компонент.
  @Input() data: T;
  @Output() dataChange = new EventEmitter();

  // текущее состояние. Сбрасывается на data при отмене редактирования.
  public tempData: T;
  public enableEdit: Boolean = false;

  public modelTransformIn() {
    return _.cloneDeep(this.data);
  }

  public modelTransformOut() {
    return _.cloneDeep(this.tempData);
  }

  constructor(
    injector: Injector
  ) {
    super(injector)
  }

  onSave() {
    this.dataChange.emit(this.modelTransformOut());
    this.onFinishEdit.emit();
    this.enableEdit = false;
  }

  onCancel() {
    this.tempData = this.modelTransformIn();
    this.enableEdit = false;
  }

  onBeginEdit() {
    if (!this.isReadonly) { this.enableEdit = true; }
  }

  onClickOutside($e) {
    if (!this.isReadonly) {
      if (this.enableEdit) {
        if (!$e.isInside) {
          this.onSave();
        }
      }
    }
  }

  ngOnInit() {
    this.tempData = this.modelTransformIn();
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(e) {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target.tagName === 'SELECT') {
      if (e.keyCode === 27 /* Esc*/) {
        this.onCancel();
        return;
      }

      if (e.keyCode === 83 && e.ctrlKey) {
        this.enableEdit && this.onSave();
        return;
      }
    }
  }
}
