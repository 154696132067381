import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Accounting} from '../../../common/models/accounting.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClubDownTime} from '../../../common/models/club-down-time.model';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {EntityStatus} from '../../../common/models/entity-status.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import * as moment from 'moment';
import {ClubroomsService} from '../../clubrooms/services/clubrooms.service';
import {IClubroomModel} from '../../../common/models/clubroom.model';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-club-down-time-add',
  templateUrl: './club-down-time-add.component.html',
  styleUrls: ['./club-down-time-add.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class ClubDownTimeAddComponent implements OnInit {
  public isLoading = false;
  @Output() result = new EventEmitter<ClubDownTime>();
  @Input() rooms: any[] = [];
  @Input() clubId = null;
  public form: FormGroup;
  public roomsSelected: any[] = [];

  constructor(
    private apiV2: ApiV2Service,
    private alertsService: AlertsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      start: new FormControl(null, [Validators.required]),
      end: new FormControl(null, [Validators.required]),
      roomIds: new FormControl(null),
      comment: new FormControl(null),
    });
    this.isLoading = true;
  }

  async confirmSave(): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    this.alertsService.showConfirm({
      header: 'Что делать с занятиями, которые попадают в указанный период?',
      buttons: [{
        title: 'Отменить',
        callback: () => {
          this.alertsService.showConfirm({
            header: 'Уведомить записанных клиентов об изменении (Push, E-mail)?',
            buttons: [{
              title: 'Уведомлять',
              callback: async () => {
                await this.save({ isCancelClasses: true, isClientNotices: true });
              }
            }, {
              title: 'Не&nbsp;уведомлять',
              callback: async () => {
                await this.save({ isCancelClasses: true, isClientNotices: false });
              }
            }, {
              title: 'Отменить'
            }]
          });
        }
      }, {
        title: 'Не&nbsp;отменять',
        callback: async () => {
          await this.save({ isCancelClasses: false, isClientNotices: false });
        }
      }, {
        title: 'Закрыть'
      }]
    });
  }

  public async save(settings: any): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    if (this.form.valid) {
      const req: ClubDownTime = {
        entityStatus: EntityStatus.published,
        clubId: this.clubId,
        roomId: this.form.get('roomIds').value,
        startAt: moment(this.form.get('start').value).format('YYYY.MM.DD HH:mm'),
        endAt: moment(this.form.get('end').value).format('YYYY.MM.DD HH:mm'),
        comment: this.form.get('comment').value,
        isCancelClasses: settings.isCancelClasses,
        isClientNotices: settings.isClientNotices
      }
      await this.apiV2.clubDownTime.save(req).then(async (e) => {
        if (e) {
          this.result.emit(e);
          this.closeAdd();
          this.alertsService.alert.next({
            type: 'success',
            message: 'Нерабочие время сохранено',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }

  public closeAdd(): any {
    return this.result.emit(null);
  }

  public changeDate(): void {
    if (this.form.get('start').value > this.form.get('end').value) {
      this.form.get('end').reset();
    }
  }

  public onRoomsChange(): void {
    this.roomsSelected = [];
    for (const room of this.rooms) {
      if (room?.isEnabled) {
        this.roomsSelected.push(room.id);
      }
    }
    this.form.get('roomIds').setValue(this.roomsSelected);
  }
}
