import { Component, OnInit, OnDestroy, Injector, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { ApiService } from 'app/common/services/api.service';
import { ContextService } from 'app/common/services/context.service';
import { takeUntil, filter, take } from 'rxjs/operators';
import { IRoutingParams } from 'app/common/models/context.model';

@Component({
  selector: 'app-coach-select',
  templateUrl: './coach-select.component.html',
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoachSelectComponent),
      multi: true
  }]
})
export class CoachSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject();
  public routingParams: IRoutingParams = null;
  public isEditing = false;
  protected api: ApiService;
  protected contextService: ContextService;
  public data: string;
  public options: { value: any, name: any } [];

  constructor(
    injector: Injector
  ) {
    this.api = injector.get(ApiService);
    this.contextService = injector.get(ContextService);
  }

  private propagateChange: (any: any) => void = () => { };

  writeValue(obj: any): void {
    this.data = obj;
    this.propagateChange(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(_: any): void { }
  setDisabledState?(_: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
    this.contextService.routingParams.pipe(
        takeUntil(this.ngUnsubscribe),
        filter(Boolean),
        take(1))
      .subscribe(async params => {
        this.routingParams = params;
        let res = await this.api.coaches.getList(this.routingParams);
        this.options = res.map(x => ({ value: x.id, name: `${x.firstName} ${x.lastName}` }));
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
