import {Component, Injector, Input, OnInit} from '@angular/core';
import {ToggleEditComponent} from '../../../../common/components/toggle-edit/toggle-edit.component';
import {IOnValueChanged, onValueChanged} from '../../../../common/validators/on-value-changed.class';
import {ILegalInformation} from '../../../../common/models/legal-information.model';
import {debounceTime, startWith} from 'rxjs/operators';
import * as _ from 'lodash';
import { PermissionService } from 'app/common/services/permission.service';
import { PermissionKey } from 'app/common/models/permission-key.model';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-legal-info',
  templateUrl: './legal-info.component.html',
  styleUrls: ['./legal-info.component.scss']
})
export class LegalInfoComponent extends ToggleEditComponent<ILegalInformation> implements IOnValueChanged, OnInit {
  public static readonly componentName: string = 'LegalInfoComponent';
  public isSubmited: boolean;
  public Infinity = Infinity;
  fieldMessages = {
    inn: {
      required: 'Это поле обязательно',
      pattern: 'Только цифры',
    },
    legalName: {
      required: 'Это поле обязательно',
    },
    bikBank: {
      required: 'Это поле обязательно',
      pattern: 'Только цифры',
    },
    nameBank: {
      required: 'Это поле обязательно',
    },
    currentAccountNumber: {
      required: 'Это поле обязательно',
      pattern: 'Только цифры',
    },
    numberBankCorrespondentAccount: {
      required: 'Это поле обязательно',
    },
    contractBasisPayment: {
      required: 'Это поле обязательно',
    }
  };

  private permissionService: PermissionService;

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.permissionService = injector.get(PermissionService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.form = this.formBuilder.group({
      inn: [this.tempData.inn, [Validators.pattern(/^\d*$/)]],
      legalName: [this.tempData.legalName, []],
      bikBank: [this.tempData.bikBank, [Validators.pattern(/^\d*$/)]],
      nameBank: [this.tempData.nameBank, []],
      currentAccountNumber: [this.tempData.currentAccountNumber, [Validators.pattern(/^\d*$/)]],
      numberBankCorrespondentAccount: [this.tempData.numberBankCorrespondentAccount, [Validators.pattern(/^\d{20}$/)]],
      contractBasisPayment: [this.tempData.contractBasisPayment, []],
      documentLink: [this.tempData.documentLink, []],
    });
    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }
  onSave() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    for (const i in this.form.value) {
      this.tempData[i] = this.form.value[i];
    }

    super.onSave();
  }

  onCancel() {
    super.onCancel();
    this.isSubmited = false;
  }

  public _isPermit: (alias: string) => boolean = alias => {
    return this.permissionService.isAvailable(this, `clubEdit_legalInfo_${alias}` as PermissionKey);
  }
}
