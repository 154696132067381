import { Pipe, PipeTransform } from '@angular/core';

const _dayWeek = {
  'mon': 'Понедельник',
  'tue': 'Вторник',
  'wed': 'Среда',
  'thu': 'Четверг',
  'fri': 'Пятница',
  'sat': 'Суббота',
  'sun': 'Воскресенье'
};

@Pipe({ name: 'DayWeek' })
export class DayWeekPipe implements PipeTransform {
  transform(value) {
    return _dayWeek[value];
  }
}
