<table class="table rowHover merge-table">

  <thead>
    <tr>
      <ng-container *ngIf="mode !== undefined">
      <td class="table-cell table-title-cell">Главный</td>
      </ng-container>
      <td class="table-cell table-title-cell">Имя Фамилия</td>
      <td class="table-cell table-title-cell">Номер телефона</td>
      <td class="table-cell table-title-cell">Email</td>
      <ng-container *ngIf="mode === undefined">
      <td class="table-cell table-title-cell">Абоненты / посещения</td>
      <td class="table-cell table-title-cell">Выбрать главный</td>
      <td class="table-cell table-title-cell">Исключить</td>
      </ng-container>
      <ng-container *ngIf="mode !== undefined">
      <td class="table-cell table-title-cell">Дата регистрации</td>
      </ng-container>
    </tr>
  </thead>

  <tbody *ngFor='let group of mergeGroups' class="merge-table-body" [class.selected]="mode === undefined && group.isSelected"
    (mouseenter)="group.isSelected=true" (mouseleave)="group.isSelected=false">

    <tr *ngFor='let client of group.mergeList; let i = index'>

      <td class="table-cell table-cell_ignore" *ngIf="mode !== undefined">
        <span class="icon-check" *ngIf="client.id === clientId"><span class="icon-target__1"></span><span class="icon-target__2"></span></span>
      </td>

      <td class="table-cell table-cell_fullname" (contextmenu)="editStart(client,$event)" (click)="editStart(client,$event)">
        <span class="table-cell__mobile-title">Имя Фамилия</span>
        <div>
          <span class="color" [style.background]="client.color"></span>
          <span *ngIf="client.firstName" [class]="GetClassForValue(group.values, 'firstName', client.firstName)">{{client.firstName != null && client.firstName != '' && client.lastName != null && client.lastName != '' ? client.firstName + ' ' : client.firstName}}</span>
          <span class="color" [style.background]="client.color"></span>
          <span *ngIf="client.lastName" [class]="GetClassForValue(group.values, 'lastName', client.lastName)">{{client.lastName}}</span>
          <span class="sp sp-sp pull-right" *ngIf="client.isRelatedSpAccount" title="Пользователь приложения SP"></span>
        </div>
      </td>

      <td class="table-cell table-cell_phone" (contextmenu)="editStart(client,$event)" (click)="editStart(client,$event)">
        <span class="table-cell__mobile-title">Номер телефона</span>
        <div>
          <span *ngIf="client.phone; else noPhone" [class]="GetClassForValue(group.values, 'phone', client.phone)" style="white-space: nowrap;">{{client.phone}}</span>
          <ng-template #noPhone><span class="gray ">[не&nbsp;указано]</span></ng-template>
        </div>
      </td>

      <td class="table-cell" (contextmenu)="editStart(client,$event)" (click)="editStart(client,$event)">
        <span class="table-cell__mobile-title">Email</span>
        <div>
          <span *ngIf="client.email; else noEmail" [class]="GetClassForValue(group.values, 'email', client.email)">{{client.email}}</span>
          <ng-template #noEmail><span class="gray ">[не&nbsp;указано]</span></ng-template>
        </div>
      </td>

      <td class="table-cell table-cell_counts" *ngIf="mode === undefined" (contextmenu)="editStart(client,$event)" (click)="editStart(client,$event)">
        <span class="table-cell__mobile-title">Абоненты / посещения</span>
        <span>{{client.subscriptionCount}} / {{client.classVisitCount}}</span>
      </td>

      <td class="table-cell table-cell_merge" *ngIf="mode === undefined">
        <span class="table-cell__mobile-title">Выбрать главный</span>
        <button class="btn btn-special" [disabled]="group.merging" (click)="itemSelect(client.id, group.mergeList, group)">Объединить сюда</button>
      </td>

      <td class="table-cell table-cell_ignore" *ngIf="mode === undefined">
        <span class="table-cell__mobile-title">Исключить</span>
        <button class="btn btn-clear active" [disabled]="group.merging" (click)="addIgnore(client.id)">
          <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
        </button>
      </td>

      <td class="table-cell table-cell_ignore" *ngIf="mode !== undefined">
        <span class="table-cell__mobile-title">Дата регистрации</span>
        <span>{{client.clubRegisteredAt}}</span>
      </td>

    </tr>

  </tbody>

</table>
