import { FormGroup } from '@angular/forms';

export const matchingPasswordsValidator = (passwordKey: string, confirmPasswordKey: string) => {
    return (group: FormGroup): { [key: string]: any } => {
        const password = group.controls[passwordKey];
        const confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
            return {
                'passwordMatch': true
            };
        }
    };
};
