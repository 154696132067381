import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityStatus } from 'app/common/models/entity-status.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-club-event-tickets-table',
  templateUrl: './club-event-tickets-table.component.html',
  //styleUrls: ['./club-event-tickets-table.component.scss']
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ClubEventTicketsTableComponent),
    multi: true
  }]
})
export class ClubEventTicketsTableComponent implements ControlValueAccessor, OnInit {

  public data: Array<{ id: string, price: number, title: string, label: string, entityStatus: EntityStatus }>;

  public readonly predicatePublished = (item: { entityStatus: EntityStatus }) => item.entityStatus === EntityStatus.published;

  constructor() {
  }

  ngOnInit(): void {
  }

  private modelTemplate = () => {
    const model = {
      entityStatus: EntityStatus.published,
      price: 0,
      id: null,
      title: null,
      label: null,
    };
    return model;
  }

  public addTicket() {
    const item = this.modelTemplate();
    if (!this.data)
      this.data = [ item ];
    else
      this.data.push(item);
  }

  public onChange(event: any) {
    this.writeValue(this.data);
  }

  public onDelete(event: any) {
    const index = this.data.indexOf(this.data.find(x => { return x === event; }));
    const _data = _.cloneDeep(this.data);
    if (event.id == null) {
      _data.splice(index, 1);
    } else {
      _data[index].entityStatus = EntityStatus.deleted;
    }
    this.writeValue(_data);
  }

  public isNotDeleted(item: { entityStatus: EntityStatus }) {
    return item.entityStatus !== EntityStatus.deleted;
  }

  private propagateChange: (any?: any) => void = () => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.data = this.data.sort((a, b) => a.price - b.price);
      this.propagateChange(obj);
    }
  }

}
