<header class="content__header" @animation>
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default" class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Подарочные сертификаты</span>
    </span>
  </div>
</header>

<div class="content__body" *ngIf="isLoading" @animation>
  <ng-container>
    <div class="row">
      <div class="col-md-5 col-md-offset-7" style="margin-bottom: 30px;">
        <form>
          <input class="input__text input__text--long" [formControl]="searchTerm" (ngModelChange)="uploadList()" autocomplete="off" placeholder="Введите код сертификата" />
        </form>
      </div>
    </div>
    <div class="clubnets-container">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <td class="table-cell table-title-cell" style="width: 50px;">№</td>
            <td class="table-cell table-title-cell">Информация</td>
            <td class="table-cell table-title-cell" style="width: 150px;">Код</td>
            <td class="table-cell table-title-cell" style="width: 150px;">Оформлен</td>
            <td class="table-cell table-title-cell" style="width: 150px;">Заканчивается</td>
            <td class="table-cell table-title-cell" style="width: 120px;">Номинал</td>
            <td class="table-cell table-title-cell" style="width: 120px;">Статус</td>
            <td class="table-cell table-title-cell" style="width: 150px;"></td>
          </tr>
          </thead>
          <tbody>
          <tr [class.isTouch]="cert.isTouch" *ngFor='let cert of certificates.result; let i = index' @animation>
            <td class="table-cell">
              <span><b>{{i+1}}</b></span>
            </td>
            <td class="table-cell">
              <span>{{cert.type == 'certificateOnSubscription' ? cert.subscriptionName : cert.type == 'arbitraryPriceOnClub' ? 'На сумму' : cert.type == 'arbitraryPriceSportPriority' ? 'SportPriority' : cert.type}}</span>
              <div *ngIf="cert.userInfo" class="cert-userinfo">
                <span [class.sp-sp]="cert.userInfo.userId" [title]="cert.userInfo.userId ? 'Пользователь приложения SP' : ''"> Данные покупателя:</span>
                <span *ngIf="cert.userInfo.name">ФИО: {{cert.userInfo.name}}</span>
                <span *ngIf="cert.userInfo.phone">Телефон: {{cert.userInfo.phone}}</span>
                <span *ngIf="cert.userInfo.email">Email: {{cert.userInfo.email}}</span>
              </div>
            </td>
            <td class="table-cell">
              <span><b class="certificateNumber" (click)="copyCertificateNumber(cert.certificateNumber)">{{cert.certificateNumber}}</b></span>
            </td>
            <td class="table-cell">
              <span [title]="cert.createdAt">{{cert.createdAt.slice(0, -5).trim()}}</span>
            </td>
            <td class="table-cell">
              <span [title]="cert.createdAt">{{cert.endAt.slice(0, -5).trim()}}</span>
            </td>
            <td class="table-cell">
              <span>{{cert.price}} руб.</span>
            </td>
            <td class="table-cell">
              <span [class.suspended]="cert.status === 'suspended'" [class.expired]="cert.status === 'expired'" [class.active]="cert.status === 'active'">{{cert.status | certificateStatus}}</span>
            </td>
            <td class="table-cell">
              <button class="btn btn-primary" [disabled]="cert.status === 'active'" (click)="activateCertificate(cert)">
                <span class="btn-primary__desc">Активировать</span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="certificates.result.length < certificates.totalCount">
      <div class="col-md-12">
        <button class="btn btn-outline btn-nextpage" (click)="showNextPage()">
          показать еще {{certificates.take}} из {{certificates.totalCount}}
        </button>
      </div>
    </div>
  </ng-container>
</div>
