import { Pipe, ChangeDetectorRef, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { startWith ,  map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import * as moment from 'moment';

@Pipe( {
    name: 'time2words',
    pure: false
} )
export class Time2wordsPipe extends AsyncPipe implements PipeTransform {
    value: number;
    timer: Observable<string>;

    constructor( ref: ChangeDetectorRef ) {
        super( ref );
    }

    public transform ( obj: any ): any {
        if ( typeof obj === 'number' ) {
            this.value = obj;
            this.timer = this.timer || this.getObservable();
            return super.transform( this.timer );
        }
        return super.transform( obj );
    }

    private getObservable () {
        return interval( 5000 )
            .pipe(
            startWith( 0 ),
            map( () => {
                const value = new Date( this.value );
                return moment.utc( value ).locale( 'ru' ).fromNow();
            } ) );
    };
}
