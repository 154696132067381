<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <td class="table-title-cell" align="center">
          Безлимит?
        </td>
        <td class="table-title-cell">Кол-во занятий</td>
        <td class="table-title-cell">Цена</td>
        <td *ngIf="VisiblePriceOnSaleCampain" class="table-title-cell">Цена по скидке</td>
        <td class="table-title-cell">Срок действия</td>
        <td class="table-title-cell">Название</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of data">
        <tr app-variant-edit [isResource]="isResource" [data]="item" (onDelete)="onDelete(item)" (onChange)="onChange($event)" *ngIf="isNotDeleted(item)" [VisiblePriceOnSaleCampain]="VisiblePriceOnSaleCampain"></tr>
      </ng-container>
    </tbody>
  </table>
</div>
