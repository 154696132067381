<header class="content__header">
    <div class="content__header-title">
        <div class="content__back">
            <span class="left-arrow" (click)="close()">
                <span class="left-arrow__top"></span>
                <span class="left-arrow__bottom"></span>
            </span>
            <span class="content__header-title-value">{{title}}</span>
        </div>
        <div class="">
            <a class="content__header-link" (click)="close()">Закрыть
                <span class="key">
                    <span app-key-help [key]="'close'"></span>
                </span>
            </a>
        </div>
    </div>
</header>

<div class="content__body">
    <ng-container *ngIf="Model!=null">
        <div class="users-container">
            <div class="tableSideLinkWrap">
                <div class="clue-text">
                    <ng-container *ngIf="isEditAvailable">
                        <a class="box-link" (mousedown)="onEdit()" (click)="onEdit()">Редактировать</a>
                    </ng-container>
                    <a class="box-link" (mousedown)="onRead()" (click)="onRead()">Написать всем</a>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table rowHover">
                    <thead>
                        <td *ngIf="!isInClubContext"></td>
                        <td class="table-cell table-title-cell">Название</td>
                        <td class="table-cell table-title-cell" style="width:200px">Обновлено</td>
                        <td class="table-cell table-title-cell" style="width:200px">Всего сообщений</td>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of Model; index as i" [class.table-row-notfinalized]="item.isFinalized == false" (click)="onChatClick(item)">
                            <td *ngIf="!isInClubContext">{{i+1}}</td>
                            <td class="table-cell">
                                <span class="img" *ngIf="item.groupType != 'notGroup'">
                                    <span class="sp" [class.sp-client]="item.groupType == 'group'" [class.sp-chats]="item.groupType == 'mailing'"></span>
                                </span>
                                {{item.title}}
                            </td>
                            <td class="table-cell">{{item.updatedAt | time2words}}</td>
                            <td class="table-cell">{{item.messagesCount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="load-nextpage" *ngIf="Model && Model.length && Model.length < totalItemCount">
                <button class="btn btn-special" (click)="showNextPage()">
                    показать еще
                </button>
            </div>
        </div>
    </ng-container>
</div>
