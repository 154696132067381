import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import 'mousetrap';

import { IHotkeyOptions } from './hotkey.options';
import { HotkeyOptions } from './hotkey.options.class';
import { Hotkey } from './hotkey.model';

@Injectable()
export class HotkeysService {
    hotkeys: Hotkey[] = [];
    pausedHotkeys: Hotkey[] = [];
    mousetrap: MousetrapInstance;
    cheatSheetToggle: Subject<any> = new Subject();

    private _preventIn = ['INPUT', 'SELECT', 'TEXTAREA'];

    constructor(@Inject(HotkeyOptions) private options: IHotkeyOptions) {

        Mousetrap.prototype.pause = function () {
            const self = this;
            self.paused = true;
        };

        Mousetrap.prototype.unpause = function () {
            const self = this;
            self.paused = false;
        };

        Mousetrap.prototype.isPaused = function () {
            const self = this;
            return self.paused;
        };

        Mousetrap.prototype.stopCallback = function (_event: KeyboardEvent, element: HTMLElement, _combo: string, _callback: Function) {
            const self = this;
            if (self.paused) {
                return true;
            }

            // if the element has the class "mousetrap" then no need to stop
            if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
                return false;
            }
            return (element.contentEditable && element.contentEditable === 'true');
        };

        this.mousetrap = new (<any>Mousetrap)();
        if (!this.options.disableCheatSheet) {
            this.add(new Hotkey(
                this.options.cheatSheetHotkey || '?',
                function (_event: KeyboardEvent) {
                    this.cheatSheetToggle.next({});
                }.bind(this),
                [],
                this.options.cheatSheetDescription || 'Show / hide this help menu',
            ));
        }
    }

    isPaused(): boolean {
        return (<any>this.mousetrap).isPaused();
    }

    pauseAll(): void {
        (<any>this.mousetrap).pause();
    }

    unpauseAll(): void {
        (<any>this.mousetrap).unpause();
    }

    add(hotkey: Hotkey | Hotkey[], specificEvent?: string): Hotkey | Hotkey[] {
        if (Array.isArray(hotkey)) {
            const temp: Hotkey[] = [];
            for (const key of hotkey) {
                temp.push(<Hotkey>this.add(key, specificEvent));
            }
            return temp;
        }
        this.remove(hotkey);
        this.hotkeys.push(<Hotkey>hotkey);
        this.mousetrap.bind((<Hotkey>hotkey).combo, (event: KeyboardEvent, combo: string) => {
            let shouldExecute = true;

            // if the callback is executed directly `hotkey.get('w').callback()`
            // there will be no event, so just execute the callback.
            if (event) {
                const target: HTMLElement = <HTMLElement>(event.target || event.srcElement); // srcElement is IE only
                const nodeName: string = target.nodeName.toUpperCase();

                // check if the input has a mousetrap class, and skip checking preventIn if so
                if ((' ' + target.className + ' ').indexOf(' mousetrap ') > -1) {
                    shouldExecute = true;
                } else if (this._preventIn.indexOf(nodeName) > -1 && (<Hotkey>hotkey).allowIn.map(allow => allow.toUpperCase()).indexOf(nodeName) === -1) {
                    // don't execute callback if the event was fired from inside an element listed in preventIn but not in allowIn
                    shouldExecute = false;
                }
            }

            if (shouldExecute) {
                return (<Hotkey>hotkey).callback.apply(this, [event, combo]);
            }
        }, specificEvent);
        return hotkey;
    }

    remove(hotkey?: Hotkey | Hotkey[]): Hotkey | Hotkey[] {
        const temp: Hotkey[] = [];
        if (!hotkey) {
            for (const key of this.hotkeys) {
                temp.push(<Hotkey>this.remove(key));
            }
            return temp;
        }
        if (Array.isArray(hotkey)) {
            for (const key of hotkey) {
                temp.push(<Hotkey>this.remove(key));
            }
            return temp;
        }
        const index = this.findHotkey(<Hotkey>hotkey);
        if (index > -1) {
            this.hotkeys.splice(index, 1);
            this.mousetrap.unbind((<Hotkey>hotkey).combo);
            return hotkey;
        }
        return null;
    }

    get(combo?: string | string[]): Hotkey | Hotkey[] {
        if (!combo) {
            return this.hotkeys;
        }
        if (Array.isArray(combo)) {
            const temp: Hotkey[] = [];
            for (const key of combo) {
                temp.push(<Hotkey>this.get(key));
            }
            return temp;
        }
        for (let i = 0; i < this.hotkeys.length; i++) {
            if (this.hotkeys[i].combo.indexOf(<string>combo) > -1) {
                return this.hotkeys[i];
            }
        }
        return null;
    }

    getAll(combo?: string | string[]): Hotkey | Hotkey[] {
        const hk: Hotkey[] = [];
        if (!combo) {
            return this.hotkeys;
        }
        if (Array.isArray(combo)) {
            const t: Hotkey[] = [];
            for (const key of combo) {
                t.push(<Hotkey>this.get(key));
            }
            return t;
        }

        for (let i = 0; i < this.hotkeys.length; i++) {
            if (this.hotkeys[i].combo.indexOf(<string>combo) > -1) {
                hk.push(this.hotkeys[i]);
            }
        }
        return hk.length ? hk : null;
    }

    pause(hotkey?: Hotkey | Hotkey[]): Hotkey | Hotkey[] {
        if (!hotkey) {
            return this.pause(this.hotkeys);
        }
        if (Array.isArray(hotkey)) {
            const temp: Hotkey[] = [];
            for (const key of hotkey) {
                temp.push(<Hotkey>this.pause(key));
            }
            return temp;
        }
        this.remove(hotkey);
        this.pausedHotkeys.push(<Hotkey>hotkey);
        return hotkey;
    }

    unpause(hotkey?: Hotkey | Hotkey[]): Hotkey | Hotkey[] {
        if (!hotkey) {
            return this.unpause(this.pausedHotkeys);
        }
        if (Array.isArray(hotkey)) {
            const temp: Hotkey[] = [];
            for (const key of hotkey) {
                temp.push(<Hotkey>this.unpause(key));
            }
            return temp;
        }
        const index: number = this.pausedHotkeys.indexOf(<Hotkey>hotkey);
        if (index > -1) {
            this.add(hotkey);
            return this.pausedHotkeys.splice(index, 1);
        }
        return null;
    }

    reset() {
        this.mousetrap.reset();
    }

    private findHotkey(hotkey: Hotkey): number {
        return this.hotkeys.indexOf(hotkey);
    }
}
