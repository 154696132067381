import { Injectable, Injector } from '@angular/core';

import { TableListService } from 'app/common/table-list/table-list.service';
import { IGroupModel } from 'app/common/models/group.model';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class GroupsService extends TableListService<IGroupModel> {
    constructor(injector: Injector) {
        super(injector);
    }

    getItemList(): Promise<IGroupModel[]> {
        return this.apiV2Service.groups.getByClub(this.routingParams.clubId);
    }

    getSingleItem(req: IRoutingParams) {
        return this.apiV2Service.groups.get(req.id);
    }

    save(model: IGroupModel) {
        return this.apiV2Service.groups.save(model);
    }

    create(model: IGroupModel) {
        return this.save(model);
    }

    addUser(groupId: string, userId: string) {
        return this.apiV2Service.groups.addUser(groupId, userId);
    }

    addClass(groupId: string, classId: string) {
        return this.apiV2Service.groups.addClass(groupId, classId);
    }

    addSingleClass(groupId: string, classPrototypeId: string, startDate: number): Promise<any> {
      return this.apiV2Service.groups.addSingleClass(groupId, classPrototypeId, startDate);
    }

    addClassPrototype(groupId: string, classPrototypeId: string, startDate: number) {
        return this.apiV2Service.groups.addClassPrototype(groupId, classPrototypeId, startDate);
    }

    removeUser(groupId: string, userId: string) {
        return this.apiV2Service.groups.removeUser(groupId, userId);
    }

    removeClass(groupId: string, classId: string, classPrototypeId: string, startDate: number, removeClassPrototype: boolean) {
        return this.apiV2Service.groups.removeClass(groupId, classId, classPrototypeId, startDate, removeClassPrototype);
    }
}
