import { Component, forwardRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

import { IReportParameter } from 'app/common/models/reports.model';

@Component({
    selector: 'app-date-picker-reports',
    templateUrl: 'date-picker-reports.component.html',
    styleUrls: ['date-picker-reports.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatePickerReportsComponent),
        multi: true
    }]
})
export class DatePickerReportsComponent implements ControlValueAccessor, OnChanges {
    private controlValue: any;

    @Input() param: IReportParameter;
    public control: FormControl;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['param']) {
            if (this.param.editor === 'PeriodSelectorFrom') {
                this.control = new FormControl(moment().add(-1, 'month'));
            } else {
                this.control = new FormControl(moment());
            }
            setTimeout(() => this.writeValue(this.control.value.format('DD.MM.YYYY')), 1);
        }
    }
    public onBlur() {
        this.control.setValue(moment(this.controlValue, 'DD.MM.YYYY'), { emitEvent: false });
    }

    public dateChange(event: MatDatepickerInputEvent<moment.Moment>) {
        if (event.value) {
            const rgx = new RegExp(/^\d{1,2}.\d{1,2}.\d{4}$/);
            const input = event.value.creationData().input;
            if (typeof input === 'object' || (typeof input === 'string' && rgx.exec(input))) {
                this.writeValue(event.value.format('DD.MM.YYYY'));
                return;
            }
        } else {
            this.writeValue(null);
        }
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    public writeValue(obj: any) {
        this.controlValue = obj;
        this.propagateChange(this.controlValue);
    }

    private propagateChange = (_: any) => { };
    public registerOnTouched() { }
}
