<form [formGroup]="form">
  <td class="table-cell" style="max-width: 180px;">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.displayDateAt | date:'dd.MM.yyyy':'UTC'}}</span>
    </div>
    <div class="form-group" *ngIf="IsEdit" @animation>
      <div class="box-title">Дата</div>
      <div class="row__field">
        <input class="form-control input__text input__text--long" name="displayDateAt" type="date" formControlName="displayDateAt" />
        <label class="field-validation-error" *ngIf="form.get('displayDateAt').touched && form.get('displayDateAt').invalid">Некорректное значение</label>
      </div>
    </div>
  </td>
  <td class="table-cell">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.receivedCash}} руб.</span>
    </div>
      <div class="form-group" *ngIf="IsEdit" @animation>
        <div class="box-title">Получено наличными</div>
        <div class="row__field">
          <input class="form-control input__text input__text--long" name="receivedCash" type="number" pattern="^\d*(\.\d{0,2})?$" formControlName="receivedCash" />
          <label class="field-validation-error" *ngIf="form.get('receivedCash').touched && form.get('receivedCash').invalid">Некорректное значение</label>
      </div>
    </div>
  </td>
  <td class="table-cell">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.receivedNonCash}} руб.</span>
    </div>
      <div class="form-group" *ngIf="IsEdit" @animation>
        <div class="box-title">Получено безналичными</div>
        <div class="row__field">
          <input class="form-control input__text input__text--long" name="receivedNonCash" type="number" pattern="^\d*(\.\d{0,2})?$" formControlName="receivedNonCash" />
          <label class="field-validation-error" *ngIf="form.get('receivedNonCash').touched && form.get('receivedNonCash').invalid">Некорректное значение</label>
        </div>
    </div>
  </td>
  <td class="table-cell">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.peoplePerDay}}</span>
    </div>
    <div class="form-group" *ngIf="IsEdit" @animation>
      <div class="box-title">Количество людей за день</div>
      <div class="row__field">
        <input class="form-control input__text input__text--long" name="peoplePerDay" type="number" pattern="^\d+$" formControlName="peoplePerDay" />
        <label class="field-validation-error" *ngIf="form.get('peoplePerDay').touched && form.get('peoplePerDay').invalid">Некорректное значение</label>
      </div>
    </div>
  </td>
  <td class="table-cell" *ngIf="isShowColumnTargetInAccounting">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.target}}</span>
    </div>
    <div class="form-group" *ngIf="IsEdit" @animation>
      <div class="box-title">Задание</div>
      <div class="row__field">
        <input class="form-control input__text input__text--long" name="target" type="number" formControlName="target" />
        <label class="field-validation-error" *ngIf="form.get('target').touched && form.get('target').invalid">Некорректное значение</label>
      </div>
    </div>
  </td>
  <td class="table-cell table-cell_ignore box-action">
    <button class="btn btn-clear active" (click)="edit()" *ngIf="!IsEdit" @animation>
      <span class="sp sp-pen"></span>
    </button>
    <a class="btn btn-link btn-small" (click)="cancel()" *ngIf="IsEdit" @animation>
      <span class="btn-primary__desc">Отмена</span>
    </a>
  </td>
  <td class="table-cell table-cell_ignore box-action">
    <button class="btn btn-clear active" (click)="remove(element)" *ngIf="!IsEdit" @animation>
      <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
    </button>
    <button class="btn btn-primary btn-small" (click)="confirmSave(element)" *ngIf="IsEdit" @animation>
      <span class="btn-primary__desc">Сохранить</span>
    </button>
  </td>
</form>
