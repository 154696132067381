import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartConfiguration, ChartData, ChartType, registerables, TitleOptions} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-graphs-general',
  templateUrl: './graphs-general.component.html',
  styleUrls: ['./graphs-general.component.scss']
})
export class GraphsGeneralComponent implements OnInit, OnChanges {
  public isLoading = false;
  private chart: Chart;
  @ViewChild('canvas', { static: true })
  public canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;

  @Input() baseData: any[] = [];

  private colors: string[] = [];
  private labels: string[] = [];
  private data: number[] = [];

  @Input() type: ChartType;
  private _type: ChartType;
  @Input() height: number = null;
  @Input() width: number = null;

  @Input() labelInput: string = null;
  private label: any;
  @Input() indexAxis: 'x' | 'y';
  @Input() datalabelsInput: string = null;
  private datalabels: any;
  @Input() radiusInput: number = null;
  private radius: any;

  @Input() datalabelsTypeInput: 'percent' | 'any' = 'percent'


  constructor() {
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
  }

  async ngOnInit(): Promise<any> {
    this._type = this.type;
    if (this.baseData?.length !== 3) { return; }
    for (let i = 0; i < this.baseData[2].length; i++) {
      if (this.baseData[2][i] < 0 || this.baseData[2][i] > 0) {
        this.colors.push(this.baseData[0][i]);
      }
    }
    this.data = this.baseData[2].filter(x => x !== 0);
    for (const i in this.data) {this.data[i] = Math.abs(this.data[i])}
    for (const i in this.baseData[2]) {
      if (this.baseData[2][i] !== 0) {
        this.labels.push(this.baseData[1][i])
      }
    }
    this.ctx = this.canvas.nativeElement.getContext('2d');
    if (this.height) { this.canvas.nativeElement.height = this.height; }
    if (this.width) { this.canvas.nativeElement.width = this.width; }
    if (this.labelInput) {
      this.label = {
        label: this.labelInput
      }
    }
    if (this.radiusInput) {
      this.radius = {
        radius: this.radiusInput
      }
    }
    // if (this.legendPositionInput) {
    //   this.legendPosition = {
    //     position: this.legendPositionInput
    //   }
    // }
    // if (this.legendInput) {
    //   this.legend = {
    //     ...this.legendPosition,
    //     labels: {
    //       padding: 10,
    //       pointStyle: 'circle',
    //       usePointStyle: true
    //     },
    //     align: 'start'
    //   }
    // } else { this.legend = { display: false } }
    if (this.datalabelsInput) {
      this.datalabels = {
        color: '#fff',
        display: 'auto',
        align: this.datalabelsInput,
        offset: 10,
        padding: { bottom: 1 },
        font: {
          size: 18
        },
        // backgroundColor: 'rgba(0, 0,0, 70%)',
        // borderRadius: 3
      }
    }
    await this.graphStart();
  }

  ngOnChanges(): void {
    if (typeof this._type === 'undefined') { return; }
    this.colors = [];
    this.labels = [];
    for (let i = 0; i < this.baseData[2].length; i++) {
      if (this.baseData[2][i] < 0 || this.baseData[2][i] > 0) {
        this.colors.push(this.baseData[0][i]);
      }
    }
    for (const i in this.baseData[2]) {
      if (this.baseData[2][i] !== 0) {
        this.labels.push(this.baseData[1][i])
      }
    }
    this.chart.data.labels = this.labels;
    this.chart.data.datasets.forEach(dataset => {
      dataset.backgroundColor = this.colors;
      dataset.data = this.baseData[2].filter(x => x !== 0);
    });
    this.data = this.baseData[2].filter(x => x !== 0);
    this.chart.update();
    if (this.type !== this._type) {
      this._type = this.type;
      this.chart.config.type = this._type;
      if (this._type === 'doughnut' || this._type === 'bar') {
        this.chart.options.plugins.datalabels.offset = 0;
        this.chart.options.plugins.datalabels.align = 'center';
      } else if (this._type === 'pie') {
        this.chart.options.plugins.datalabels.offset = 10;
        this.chart.options.plugins.datalabels.align = 'end';
      }
      if (this._type !== 'bar') {
        this.chart.options.scales = null;
      }
      this.chart.update();
    }
  }

  graphInit() {
    setTimeout(async () => this.graphStart());
  }

  graphStart() {
    const dataDiagram = this.baseData[2].filter(x => x !== 0);
    try {
      const _data: ChartData = {
        labels: this.labels,
        datasets: [{
          ...this.label,
          backgroundColor: this.colors,
          borderWidth: 1,
          hoverBorderWidth: 0,
          borderColor: '#f0f1f1',
          data: dataDiagram,
          // hoverOffset: 10,
          ...this.radius,
          fill: 'start'
        }],
      };

      const tooltipLabel = (tooltipItems) => {
        if (this.datalabelsTypeInput === 'any') {
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          const total = this.data.reduce(reducer);
          return `${tooltipItems.label}: ${((Math.abs(tooltipItems.parsed * 100)) / total).toFixed(2)}%`;
        }
        return `${tooltipItems.label}: ${tooltipItems.formattedValue}`;
      };

      Chart.defaults.font.family = "ComfortaaRegular";

      const config: ChartConfiguration = {
        type: this._type,
        data: _data,
        options: {
          responsive: true,
          indexAxis: this.indexAxis,
          plugins: {
            tooltip: {
              callbacks: {
                label: tooltipLabel,
              }
            },
            legend: {
              display: false
            },
            datalabels: {
              ...this.datalabels,
              formatter: (value, ctx) => {
                const reducer = (accumulator, currentValue) => accumulator + currentValue;
                for (const i in this.data) {
                  this.data[i] = Math.abs(this.data[i])
                }
                const total = this.data.reduce(reducer);
                return total > 0 ? this.datalabelsTypeInput === 'percent' ? ((value * 100) / total).toFixed(2) + '%' : value.toFixed(2) : null;
              }
            },
          },
          layout: {
            padding: 10,
          },
        }
      };
      this.chart = new Chart(this.ctx, config);
    } catch (er) {
      console.error(er);
    }
  }
}
