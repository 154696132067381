import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, ViewEncapsulation } from '@angular/core';

@Component( {
  selector: 'app-option-selector',
  templateUrl: './option-selector.component.html',
  styleUrls: [ './option-selector.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionSelectorComponent ),
      multi: true
    }
  ]
} )


export class OptionSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() options;
  @Input() opicker__text = true;
  private data = 'day';

  public _options = [];

  constructor() { }

  private propagateChange = ( _: any ) => { };

  public registerOnChange ( fn: any ) {
    this.propagateChange = fn;
  }

  public registerOnTouched () { }

  public writeValue ( obj: any ) {
    if ( obj ) {
      this.data = obj;
    }
  }

  public onChange ( event ) {
    this.writeValue( event );
    this.propagateChange( this.data );
  }

  ngOnInit () {
    for ( const key in this.options ) {
      this._options.push( { key: key, value: this.options[ key ] } );
    }
  }

}
