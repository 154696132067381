<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="Back()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Доплата абонемента
      </span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="Back()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<!-- *ngIf="formsSurcharge && surcharge" -->

<div class="content__body">
  <form [formGroup]="formsSurcharge" (ngSubmit)="onSubmit()">
    <div class="row">
    <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
            Абонемент:
          </div>
          <div class="row__field">
            {{extension.subscriptionName}}
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
            Клиент:
          </div>
          <div class="row__field">
            {{extension.clientName}}
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">
            История оплат:
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="table-responsive">
            <table class="table rowHover">
            <thead>
              <tr>
                <td class="table-cell table-title-cell">Сумма</td>
                <td class="table-cell table-title-cell">Дата оплаты</td>
                <td class="table-cell table-title-cell">Способ оплаты</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let payment of extension.orderPayments">
                <td class="table-cell">{{payment.amount}}</td>
                <td class="table-cell">{{toDate(payment.paymentDate)}}</td>
                <td class="table-cell">{{payment.source | cashAndCard}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
            Полная сумма абонемента:
          </div>
          <div class="row__field">
            {{extension.sum}}
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
           Уже оплачено:
          </div>
          <div class="row__field">
            {{extension.paid}}
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
              Сумма доплаты:
          </div>
          <div class="row__field">
            <input type="text" placeholder="0,00" [(ngModel)]="surcharge" formControlName="surcharge" id="surcharge" class="input__text input__text--long" required (keydown)="Mask($event)">
            <label for="surcharge" class="field-validation-error" *ngIf="isControlInvalid('surcharge')">
              {{fieldMessages.surcharge.numberValidator}}
            </label>
      </div>
        </div>
      </div>
<!--
      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <div class="box-title">Дата оплаты</div>
          <app-date-picker [(ngModel)]="paymentDate" placeholder="" format="DD.MM.YYYY" formControlName="paymentDate"></app-date-picker>
        </div>
      </div>
-->
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">
            Способ оплаты:
          </div>
          <div class="row__field">
            <select class="select select__long" name="source" id="source" (change)="selectSource($event.target.value)">
              <option value="Card" selected>Карта</option>
              <option value="Cash">Наличные</option>
              <option value="Receipt" *ngIf="isPaymentReceipt">Оплата по квитанции</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-md-12">
        <div class="form-group">
          <div class="box-title">
          </div>
          <div class="row__field">
            <button class="btn btn-primary" type="submit" [disabled]="!formsSurcharge.valid">
              <span class="btn-primary__desc">Сохранить</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
