import {Observable, throwError as observableThrowError} from 'rxjs';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ConnectionBackend, Headers, Http, RequestOptions, RequestOptionsArgs, Response, ResponseContentType, URLSearchParams} from '@angular/http';

import {catchError, share} from 'rxjs/operators';
import {environment} from 'environments/environment';

@Injectable()
export class HttpService extends Http {

  baseApiUrl: string = environment.api;

  constructor(
    backend: ConnectionBackend,
    defaultOptions: RequestOptions,
    private alertsService: AlertsService,
    private router: Router,
  ) {
    super(backend, defaultOptions);
  }

  private successHandler(res: any) {
    const header = 'Успех';
    let message = null;

    try {
      const respJson = res.json();
      message = respJson.message || respJson.desc;
    } catch (e) { }

    if (message) {
      this.alertsService.alert.next({
        type: 'success',
        message: message,
        header: header,
        position: 'top',
        timeout: 3000
      });
    }
  }

  private errorHandler(err: any) {
    // Для использования PWA офлайн
    if (err.status === 504 || err.status === 0) {
      const offlineMode = window.localStorage.getItem('offlineMode');

      if(offlineMode === null) {
        this.alertsService.showConfirm({
          header: 'Нет подключения к сети',
          buttons: [
            {
              title: 'Повторить подключение к сети',
              callback: () => {
                window.location.reload();
              }
            },
            {
              title: 'Продолжить в режиме офлайн',
              callback: () => {
                window.localStorage.setItem('offlineMode', 'true');
              }
            }
          ]
        });
      }

      return;
    }

    if (err.status === 401) {
      window.localStorage.removeItem('Token');
      window.localStorage.removeItem('userInfo');
      this.router.navigate(['/login']);
      return;
    }
    let message = `При запросе ${err.url} произошла ошибка: ${err.status}: ${err.statusText}`;
    const header = 'Произошла ошибка';
    let isSupress = true;

    try {
      const data = err.json();
      let exceptionMessage = null;
      if (data.exceptionMessage) {
        exceptionMessage = JSON.parse(data.exceptionMessage).ExceptionMessage;
        if (exceptionMessage) {
          message = exceptionMessage;
        }
      }
      if ((data.message || data.desc) && !exceptionMessage) {
        message = data.message || data.desc;
        isSupress = false;
      }
    } catch (e) { }

    if (err.status === 403) {
      message = null;
      try {
        message = err.json()?.message;
      } catch (e) { }
      if (!message)
        message = 'Отсутствуют необходимые права.';
      this.alertsService.alert.next({
        type: 'danger',
        message: message,
        header: header,
        position: 'top',
        timeout: -1
      });
    } else if (isSupress) {
      console.log(message);
      message = `Пожалуйста, сообщите нам о ней.`;
      this.alertsService.showConfirm({
        header: header,
        message: message,
        buttons: [{
          title: 'Сообщить',
          callback: () => {
            this.alertsService.exception.next();
          }
        }, {
          title: 'Не сообщать'
        }]
      });
    } else {
      this.alertsService.alert.next({
        type: 'danger',
        message: message,
        header: header,
        position: 'top',
        timeout: -1
      });
    }
  }

  public getRequestOptionArgs(isForm = false, responseType: ResponseContentType = null): RequestOptionsArgs {
    const headers = isForm ? new Headers({ 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.getUserToken().accessToken }) :
      new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getUserToken().accessToken });
    const options = new RequestOptions({ headers: headers, responseType: responseType });
    return options;
  }

  private getUrl(url: string): string {
    return this.baseApiUrl + url;
  }

  getUserToken() {
    const lstoken = window.localStorage.getItem('Token');
    if (lstoken != null) {
      return JSON.parse(window.localStorage.getItem('Token'));
    } else {
      return '';
    }
  }

  get(url: string, urlParams?: any, urlPrefix = 'v1/crm/'): Observable<Response> {
    const option = this.getRequestOptionArgs();

    if (urlParams) {
      const urlOption = new URLSearchParams();
      for (const i in urlParams) {
        urlOption.set(i, urlParams[i]);
      }
      option.search = urlOption;
    }

    return super
      .get(this.getUrl(`${urlPrefix}${url}`), option)
      .pipe(catchError(err => {
        if (url.indexOf('events-line') !== -1) {
          console.error('error events-line');
          return observableThrowError(err);
        }
        this.errorHandler(err);
        return observableThrowError(err);
      }));
  }

  post(url: string, body: Object, _options?: RequestOptionsArgs, isForm = false, urlPrefix = 'v1/crm/',
    handleError: boolean = true, handleResult: boolean = true, responseType: ResponseContentType = null
  ): Observable<Response> {
    var result = super.post(this.getUrl(`${urlPrefix}${url}`), isForm ? body : JSON.stringify(body), this.getRequestOptionArgs(isForm, responseType))
    if (handleError)
    result = (handleError
      ? result
      .pipe(catchError(err => {
        this.errorHandler(err);
        return observableThrowError(err);
      }))
      : result)
      .pipe(share());

    result.subscribe(
      res => {
        handleResult && this.successHandler(res);
        window.localStorage.removeItem('offlineMode');
      },
      err => {
        return err;
      });
    return result;
  }
}
