import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'certificateStatus'
})
export class CertificateStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch ( value ) {
      case 'created': {
        return 'Создан';
      }
      case 'suspended': {
        return 'Оплачен';
      }
      case 'active': {
        return 'Активирован';
      }
      case 'expired': {
        return 'Просрочен';
      }
    }
    return value;
  }
}
