<header class="content__header">
  <div class="content__header_title content__header_title--short">
    <span class="content__header-title-value content__header-title-value_default">
      Получить код для вставки виджета
    </span>
  </div>
  <div class="content__header_navigation">
  </div>
</header>

<div class="content__body">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label class="box-title" for="them">Выбрать тему:</label>
        <select class="select select__long" name="them" id="them" (change)="selectTheme($event.target.value)">
          <option value="purple">Красная (по умолчанию)</option>
          <option value="green">Зелёная</option>
          <option value="blue">Синяя</option>
          <option value="parkcitydance">Parkcitydance</option>
        </select>
      </div>
    </div>

    <!-- TODO: выбиралка залов отключена, т.к. виджет падает, если указать конкретный зал -->
    <!-- <div class="col-md-4 col-xs-12">
      <div class="form-group">
        <label class="box-title" for="room">Выбрать зал:</label>
        <select class="select select__long" name="room" id="room" (change)="selectRoom($event.target.value)">
          <option value="">Все залы</option>
          <ng-container *ngIf="roomList && roomList.length">
            <option [value]="roomItem.name" *ngFor="let roomItem of roomList">{{roomItem.name}}</option>
          </ng-container>
        </select>
      </div>
    </div> -->

    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label class="box-title" for="room">Блочный вид</label>
        <input type="checkbox" class="checkbox__slide" id="isBlock" [ngModel]="isBlock" (ngModelChange)="changeBlockState($event)">
        <label for="isBlock" class="checkbox__slide_label"></label>
        <div class="clue-text" *ngIf="isBlock">Виджет занимает всё доступное пространство родителя</div>
        <div class="clue-text" *ngIf="!isBlock">Виджет ограничен по ширине в 1100px и имеет небольшой отступ</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <textarea id="scheduleCode" [(ngModel)]="code"></textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <button class="btn btn-primary" (click)="copyCode()">
          <span class="btn-primary__desc">Скопировать в буфер</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="vkInstallLink">
    <div class="col-md-12">
      <div class="form-group">
        <a [href]="vkInstallLink" class="btn btn-primary" target="_blank">
          <span class="btn-primary__desc">Добавить расписание в группу VK</span>
        </a>
      </div>
    </div>
  </div>
</div>