import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityStatus } from 'app/common/models/entity-status.model';
let idx = 1;

@Component({
  selector: '[app-club-event-ticket-edit]',
  templateUrl: './club-event-ticket-edit.component.html',
  styleUrls: ['./club-event-ticket-edit.component.scss']
})
export class ClubEventTicketEditComponent implements OnInit {

  public readonly i = idx++;

  @Input() data: { id: string, price: number, title: string, label: string, entityStatus: EntityStatus };
  @Output() onChange = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onChangeHandler(event) {
    this.onChange.emit();
  }

  onDeleteHandler() {
    this.onDelete.emit();
  }

}
