<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="goBack()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Продажа абонементов
      </span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="goBack()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body">

  <div class="row">

    <div class="col-md-8">
      <div class="form-group" *ngIf="clients && clients.length">
        <label class="box-title" for="client">Выбрать клиента:</label>
        <select [(ngModel)]="selectedClient" class="select select__long" name="client" id="client">
          <option [ngValue]="client"
                  [attr.selected]="selectedClient && client.id === selectedClient.id"
                  *ngFor="let client of clients">
            {{client.firstName}} {{client.lastName}}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-8">
      <div class="form-group" *ngIf="subscriptions && subscriptions.length">
        <label class="box-title" for="subscription">Выбрать абонемент:</label>
        <select class="select select__long" name="client" id="subscription" (change)="selectSubscription($event.target.value)">
          <option value="" disabled selected>Абонемент не выбран</option>
          <optgroup label="Активные" *ngIf="subscriptions.some(_predicateSubPubl)">
            <option *ngFor="let subscription of subscriptions.filter(_predicateSubPubl)" [value]="subscription.id">{{subscription.name}}</option>
          </optgroup>
          <optgroup label="Черновики" *ngIf="subscriptions.some(_predicateSubDrft)">
            <option *ngFor="let subscription of subscriptions.filter(_predicateSubDrft)" [value]="subscription.id">{{subscription.name}}</option>
          </optgroup>
        </select>
      </div>
    </div>

    <div class="col-xs-12" *ngIf="!subscriptions || !subscriptions.length">
      <div class="info-text">
        <div>
          Данный вид занятия не входит ни в один из абонементов
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="form-group" *ngIf="selectedSubscription && selectedSubscription.variants">
        <label class="box-title" for="variants">Выбрать приемлемый вариант:</label>
        <select [(ngModel)]="selectedVariant" class="select select__long" name="variants" id="variants">
          <ng-container *ngFor="let variant of selectedSubscription.variants">
              <option [ngValue]="variant">
                Продолжительность: {{variant.name}}
              </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-md-8" *ngIf="selectedVariant">
      <div class="form-group">
        <label class="box-title" for="source">Выбрать способ оплаты:</label>
        <select class="select select__long" name="source" id="source" (change)="selectSource($event.target.value)">
          <option value="Card" selected>Карта</option>
          <option value="Cash">Наличные</option>
          <option value="Receipt" *ngIf="isPaymentReceipt">Оплата по квитанции</option>
        </select>
      </div>
    </div>

    <div class="col-md-8">
      <div class="form-group" *ngIf="selectedSubscription">
        <div class="box-title">
          Установить дату начала:
        </div>
        <div class="row__field">
          <app-date-picker #startDatePicker placeholder="" format="DD.MM.YYYY"></app-date-picker>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="form-group" *ngIf="selectedVariant">
        <div class="box-title">Сумма оплаты сразу:</div>
        <div>
          <input type="text" [appRestrictInput]="{min:0, max:100000000}" placeholder="0,00" [(ngModel)]="sum" class="input__text input__text--long input-field" id="sum">
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="selectedVariant">
    <div class="col-xs-12">
      <div [class.form-group]="!isOrderDiscount">
        <input type="checkbox" class="checkbox__slide" id="disableRoom" [(ngModel)]="isOrderDiscount">
        <label for="disableRoom" class="checkbox__slide_label">Применить скидку</label>
      </div>
    </div>
    <div class="row" *ngIf="isOrderDiscount">
      <div class="col-xs-12 col-md-3">
        <div class="form-group">
          <div class="box-title">Размер скидки:</div>
          <div>
            <input class="input__text input__text--long input-field" type="number" min="0" id="sumOrderDiscount" [(ngModel)]="sumOrderDiscount" (keydown)="changeOrderDiscount()">
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-1">
        <div class="form-group">
          <label class="box-title">&nbsp;</label>
          <select class="select select__long" name="typeDiscount" id="typeDiscount" [(ngModel)]="typeDiscount" (change)="changeOrderDiscount()">
            <option value="1">₽</option>
            <option value="2">%</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div>
        <div class="box-title" *ngIf="selectedVariant">Cумма к оплате:&nbsp;<strong>{{isOrderDiscount ? changeOrderDiscount() : selectedVariant.price}}</strong></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-primary"
              *ngIf="subscriptions && subscriptions.length"
              [disabled]="!selectedVariant"
              (click)="addSubscription(selectedClient, selectedSubscription, selectedVariant, selectedSource)">
        <span class="btn-primary__desc">Сохранить</span>
      </button>

      <button class="btn btn-primary"
              [routerLink]="['../clubplans']"
              *ngIf="!subscriptions || !subscriptions.length">
        <span class="btn-primary__desc">Перейти к абонементам</span>
      </button>
    </div>

  </div>

</div>
