<div class="content__body">
  <div class="">
    <a class="content__header-link cancel-add" (click)="closeAdd()">
      Отмена
    </a>
  </div>

  <div>
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="form-group">
        <div class="box-title">Город</div>
        <div class="row__field">
          <app-select-search [data]="cities" [defaultValue]="currentCityId" (onSelectionChange)="onCityChange($event)" [multiple]="false" [isDisabled]="true"></app-select-search>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="clubs?.length > 0">
      <div class="form-group">
        <div class="box-title">Клуб</div>
        <div class="row__field">
          <app-select-search [data]="clubs" [defaultValue]="currentClubId" (onSelectionChange)="onClubChange($event)" [multiple]="false" [isDisabled]="true"></app-select-search>
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <form [formGroup]="form">
    <div *ngIf="currentClubId">
      <div class="col-xs-12 col-sm-6 col-md-4" style="display: none;"> <!--Это нужно удалить весь этот блок нафиг его-->
        <div class="form-group">
          <div class="box-title">Контакт</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="contact" type="text" formControlName="contact" />
            <label class="field-validation-error" *ngIf="form.get('contact').touched && form.get('contact').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Контакт</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="contactInfo" type="text" formControlName="contactInfo" />
            <label class="field-validation-error" *ngIf="form.get('contactInfo').touched && form.get('contactInfo').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">ФИО</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="fullName" type="text" formControlName="fullName" />
            <label class="field-validation-error" *ngIf="form.get('fullName').touched && form.get('fullName').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Коментарий</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="comment" type="text" formControlName="comment" />
            <label class="field-validation-error" *ngIf="form.get('comment').touched && form.get('comment').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Дата</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="date" type="text" placeholder="В формате дд.мм.гггг" formControlName="date" />
            <label class="field-validation-error" *ngIf="form.get('date').touched && form.get('date').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Сумма</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="amount" type="number" formControlName="amount" />
            <label class="field-validation-error" *ngIf="form.get('amount').touched && form.get('amount').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Бонусы</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="bonuses" type="number" formControlName="bonuses" />
            <label class="field-validation-error" *ngIf="form.get('bonuses').touched && form.get('bonuses').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Комиссия</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="commission" type="number" formControlName="commission" />
            <label class="field-validation-error" *ngIf="form.get('commission').touched && form.get('commission').invalid">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Статус</div>
          <div class="row__field">
            <ng-container>
              <select formControlName="status" class="select select__long" name="status">
                <option [value]="'published'">Опубликовано</option>
                <option [value]="'deleted'">Удалено</option>
                <option [value]="'hold'">Корректировка</option>
                <option [value]="'mutualSettlement'">Взаиморасчет</option>
                <option [value]="'refund'">Возврат</option>
              </select>
              <label class="field-validation-error" *ngIf="form.get('status').touched && form.get('status').invalid">Это поле обязательно</label>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <div class="col-xs-12 col-sm-6 col-md-4">
        <br>
        <br>
        <div class="form-group btn-save">
          <button class="btn btn-primary btn-small" (click)="save()" [disabled]="form.invalid">
            <span class="btn-primary__desc">Сохранить</span>
          </button>
        </div>
      </div>
    </div>
  </form>

</div>
