<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Тренеры
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="coaches-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Фото</td>
              <td class="table-cell table-title-cell">ФИО</td>
              <td class="table-cell table-title-cell">Должность</td>
              <td class="table-cell table-title-cell">Номер телефона</td>
              <td class="table-cell table-title-cell">Направление</td>
              <td class="table-cell table-title-cell">Активно</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let coach of Model' (mousedown)="onEditStart(coach,$event)" (click)="onEditStart(coach)">
              <td class="table-cell table-cell_avatar">
                <span class="table-cell__mobile-title">Фото</span>
                <div class="avatar">
                  <div class="avatar__default">
                    <span class="sp sp-user"></span>
                  </div>
                  <div class="avatar__img" *ngIf="coach.primaryImageUrl" [style.background]="'url('+coach.primaryImageUrl+')'">
                  </div>
                </div>
              </td>
              <td class="table-cell table-cell_name">
                <span class="table-cell__mobile-title">ФИО</span>
                <div class="field desc">
                  <span class="color" [style.background]="coach.color"></span>
                  <span class="coach-name"><b>{{ coach.firstName }} {{ coach.lastName }}</b></span>
                </div>
              </td>
              <td class="table-cell table-cell_post">
                <span class="table-cell__mobile-title">Должность</span>
                <div class="desc field">
                  <span *ngIf="coach.position; else noPosition">{{coach.position}}</span>
                  <ng-template #noPosition><span class="gray ">[не&nbsp;указано]</span></ng-template>
                </div>
              </td>
              <td class="table-cell table-cell_phone">
                <span class="table-cell__mobile-title">Номер телефона</span>
                <div class="desc field">
                  <span *ngIf="coach.phone; else noPhone">{{coach.phone}}</span>
                  <ng-template #noPhone><span class="gray ">[не&nbsp;указано]</span></ng-template>
                </div>
              </td>
              <td class="table-cell">
                <span class="table-cell__mobile-title">Направление</span>
                <div class="desc field">
                  <span *ngIf="coach.group; else noGroup">{{coach.group}}</span>
                  <ng-template #noGroup><span class="gray ">[не&nbsp;указано]</span></ng-template>
                </div>
              </td>
              <td class="table-cell table-cell_publish" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
                <span class="table-cell__mobile-title">Активно</span>
                <app-status-picker [title]="null" [ngModel]="coach.entityStatus" (ngModelChange)="setStatus($event, coach)"></app-status-picker>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

</div>
