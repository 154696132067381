import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {RegistryComponent} from '../../components/registry/registry.component';
import {RegistryCreateComponent} from '../../components/registry/create/registry-create.component';
import {RegistryEditComponent} from '../../components/registry/edit/registry-edit.component';

export const registryRoute: Route = {
  component: RegistryComponent,
  path: ':cityId/registry',
  canActivate: [AuthGuard],
  data: { title: 'Реестры' }
};

export const registryCreateRoute: Route = {
  component: RegistryCreateComponent,
  path: ':cityId/registry/create',
  canActivate: [AuthGuard],
  data: { title: 'Реестры' }
};

export const registryCreateTypeRoute: Route = {
  component: RegistryCreateComponent,
  path: ':cityId/registry/create/:type',
  canActivate: [AuthGuard],
  data: { title: 'Реестры' }
};

export const registryEditRoute: Route = {
  component: RegistryEditComponent,
  path: ':cityId/registry/edit',
  canActivate: [AuthGuard],
  data: { title: 'Реестры' }
};
