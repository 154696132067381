import { Route } from '@angular/router';
import { SettingsComponent } from 'app/components/settings/settings.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const adminSettingsRoute: Route = {
  path: ':cityId/adminsettings',
  component: SettingsComponent,
  canActivate: [AuthGuard],
  data: { title: 'Настройки - администратор' }
};


export const clubSettingsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubsettings',
  component: SettingsComponent,
  canActivate: [AuthGuard],
  data: { title: 'Настройки' }
};
