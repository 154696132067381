import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoachPayout } from 'app/common/models/coach-registry.model';
import { CoachRegistryService } from 'app/components/coaches/services/coach-registry.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertsService} from 'app/common/components/alerts/services/alerts.service';
import {dateValidator} from 'app/common/validators/date.validator';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
  providers: [CoachRegistryService]
})

export class PayoutComponent implements OnInit{

  @Input() coachRegistryId: string;
  @Input() coachId: string;
  @Output() result = new EventEmitter<CoachPayout>();

  public form: FormGroup;
  public isNotEmptyValue : boolean = true;

  constructor(
    private service: CoachRegistryService,
    private alertsService: AlertsService
  ) {}

  async ngOnInit(): Promise<any> {
    const date = new Date();
    const formDate = {
      day: date.getDate().toString(),
      month: (date.getMonth() + 1).toString(),
      year: (date.getFullYear()).toString()
    };
    const stringdate = (formDate.day.length > 1 ? formDate.day : "0" + formDate.day) + "." + (formDate.month.length > 1 ? formDate.month : "0" + formDate.month) + "." + (formDate.year);
    this.form = new FormGroup({
      date: new FormControl(stringdate, [Validators.required, dateValidator()]),
      payout: new FormControl(0, Validators.required)
    });
  }

  public async save(): Promise<any> {
    if (this.form.valid) {
      const req = {
        coachId: this.coachId,
        total: this.form.get('payout').value,
        coachRegistryId: this.coachRegistryId,
        date: this.form.get('date').value,
      }
      await this.service.addPayout(req).then(async (e) => {
        if (e) {
          // this.isAddPayoutMode = false;
          this.result.emit(e);
          this.alertsService.alert.next({
            type: 'success',
            message: 'Транзакция сохранена',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }

  public setTwoNumberDecimal(evt: Event) {
    const input = evt.target as HTMLInputElement;
    const regex = new RegExp(/^\d*(\.\d{0,2})?$/, 'g');

    let newVal = input.value;
    if(!regex.test(newVal)){
      this.form.patchValue({ payout: input.value.slice(0, -1) });
    }

    let floatVal = parseFloat(newVal);
    this.isNotEmptyValue = !(floatVal > 0.0); // проверка, что в поле значение больше нуля
  }
}
