import { DictionaryDataService } from './dictionary-data.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DicService {

  constructor(
    private dictionaryDataService: DictionaryDataService,
  ) { }

  public data: any;

  public async setData(clubId: string) {
    const dictionary = await this.dictionaryDataService.getDefaultDictionary(clubId);

    if (!dictionary || !dictionary.data) {
      delete this.data;
      return;
    }

    this.data = JSON.parse(dictionary.data);
  }
}
