import {ApiV2Service} from 'app/common/services/api-v2.service';
import {DictionaryDataService} from './../../../common/services/dictionary-data.service';
import {IClubDictionaryData} from './../../../common/models/club-dictionary-data.model';
import {Component, Injector} from '@angular/core';
import {Validators} from '@angular/forms';

import {NotEmpty} from 'app/common/validators/required-not-empty.validator';
import {EditItemComponent} from 'app/common/edit-item.component';
import {environment} from 'environments/environment';
import {AvailableInfo, IScheduleSettings, IThemeModel, IWidgetModel, VisibilityDescriptionSub, WidgetType} from 'app/common/models/widgets.model';
import {EntityStatus} from 'app/common/models/entity-status.model';
import {WidgetsService} from 'app/components/widgets/widgets.service';
import {IClubroomModel} from '../../../common/models/clubroom.model';
import {ApiService} from 'app/common/services/api.service';
import {IClassItemModel} from 'app/common/models/class-item.model';
import {CoursesService} from '../../courses/services/courses.service';
import {ICourseModel} from '../../../common/models/course.model';
import {ContextService} from '../../../common/services/context.service';
import {IExtendedClubModel} from '../../../common/models/club.model';
import {RentMatrix, RentTime} from '../../../common/models/rent-time.model';
import * as moment from 'moment';
import {SubscriptionService} from '../../subscriptions/services/subscription.service';
import {ISubscription} from '../../../common/models/subscription.model';

@Component({
    selector: 'app-widget-edit',
    templateUrl: './widget-edit.component.html',
    styleUrls: ['./widget-edit.component.scss']
})
export class WidgetEditComponent extends EditItemComponent<IWidgetModel> {
  public static readonly componentName: string = 'WidgetEditComponent';

    fieldMessages = {
        name: {
            NotEmpty: 'Это поле обязательно'
        },
        widgetType: {
            required: 'Необходимо указать тип виджета'
        }
    };
    club: IExtendedClubModel;
    courses: ICourseModel[] = [];
    widgetTypes = { ...WidgetType };
    themes: IThemeModel[] = [];
    rooms: IClubroomModel[] = [];
    currentTheme: IWidgetModel;
    code = '';
    vkInstallUrl = '';
    dictionaries: IClubDictionaryData[];
    selectFilter: string;
    filters = {
      rooms: [],
      groups: [],
      courses: [],
      coaches: [],
      trainingCategories: [],
      clubs: []
    }
    newThemeState: { id: string, entityStatus: string };


    showTime: string[] = [];
    rentMatrix: RentMatrix[] = [];
    allSubscription: Array<ISubscription>;
    private setRentMatrix() {
      for (const key in this.club.workingTime) {
        const hourFrom = moment(this.club.workingTime[key].hourFrom, 'HH:mm');
        if (hourFrom.get('minute') !== 0)
          hourFrom.add(-30, 'minute');
        const hourTo = moment(this.club.workingTime[key].hourTo, 'HH:mm');
        const _rentMatrix = new RentMatrix();
        _rentMatrix.dayWeek = key;
        const _temp = hourFrom;
        for (let i = hourFrom.get('hour'); i <= hourTo.get('hour'); i++) {
          const _time = _temp.format('HH:mm');
          _rentMatrix.time.push(new RentTime(_time, null));
          if (this.showTime.filter(x => x === _time).length === 0)
            this.showTime.push(_time);

          _temp.add(1, 'hour');
        }
        if (!this.Model.settings.TimeMatrix)
          this.rentMatrix.push(_rentMatrix);
      }
      this.showTime.sort();
    }
    private async clearOfDebris() {
      this.club = await this.apiService.clubs.getItem(this.context.getRoutingParams());
      for (const key in this.club.workingTime) {
        const hourFrom = moment(this.club.workingTime[key].hourFrom, 'HH:mm');
        if (hourFrom.get('minute') !== 0)
          hourFrom.add(-30, 'minute');
        const hourTo = moment(this.club.workingTime[key].hourTo, 'HH:mm');
        const _rentMatrix = new RentMatrix();
        _rentMatrix.dayWeek = key;
        const _temp = hourFrom;
        for (let i = hourFrom.get('hour'); i <= hourTo.get('hour'); i++) {
          const _time = _temp.format('HH:mm');
          _rentMatrix.time.push(new RentTime(_time, null));
          if (this.showTime.filter(x => x === _time).length === 0)
            this.showTime.push(_time);

          _temp.add(1, 'hour');
        }

        for (let i = 0; i < this.Model.settings.TimeMatrix.length; i++) {
          if (this.Model.settings.TimeMatrix[i].dayWeek === _rentMatrix.dayWeek) {
            for (let y = 0; y < this.Model.settings.TimeMatrix[i].time.length; y++) {
              for (let q = 0; q < _rentMatrix.time.length; q++) {
                if (this.Model.settings.TimeMatrix[i].time[y].time === _rentMatrix.time[q].time) {
                  _rentMatrix.time[q].subId = this.Model.settings.TimeMatrix[i].time[y].subId;
                }
              }
            }
            this.Model.settings.TimeMatrix[i].time = _rentMatrix.time;
          }
        }
      }
    }
    isShowDay(h: string, d: RentMatrix) { return d.time.filter(x => x.time === h).length >= 1; }
    changeMatrix() {
      this.Model.settings.TimeMatrix = this.rentMatrix;
    }

    constructor(
        injector: Injector,
        protected apiService: ApiService,
        protected apiV2Service: ApiV2Service,
        protected service: WidgetsService,
        protected coursesService: CoursesService,
        private subscriptionService: SubscriptionService,
        protected context: ContextService,
        private dictionaryDataService: DictionaryDataService,
    ) {
        super(injector, service);
        this.onAddtoGroupMessage = this.onAddtoGroupMessage.bind(this);
        this.newThemeState = this.router.getCurrentNavigation().previousNavigation?.extras?.state?.newThemeState;
    }

    public async ngOnInit() {
        super.ngOnInit();
        window.addEventListener("message", this.onAddtoGroupMessage);
        this.dictionaries = await this.dictionaryDataService.getDictionariesByClub(this.route.snapshot.paramMap.get('clubId'));
        this.courses = await this.coursesService.getItemList();
        this.courses = this.courses.filter(x => x.entityStatus === EntityStatus.published);
        this.club = await this.apiService.clubs.getItem(this.context.getRoutingParams());
        this.allSubscription = await this.subscriptionService.getItemList();
        this.allSubscription = this.allSubscription.filter(x => x.entityStatus === EntityStatus.published && x.variants.filter(y => y.title != null && y.title !== '').length > 0);
        this.sortVariant();
        this.setRentMatrix();
    }

    public sortVariant() {
      this.allSubscription.forEach(s => {
        s.variants = s.variants.sort((a, b) => {
          return a.visitCount - b.visitCount || a.price - b.price;
        });
      });
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        window.removeEventListener("message", this.onAddtoGroupMessage);
    }

    public afterModelInit() {
        this.updateThemes();
        this.updateRooms();
        this.code = this.getWidgetCode();
        this.vkInstallUrl = this.getVkInstallUrl();
        this.Model.settings.showBookedFree = this.Model.settings.showBookedFree || 'dontShow';
        this.Model.settings.activeDisplayType = this.Model.settings.activeDisplayType || 'default';
        this.Model.settings.dictionaryAlias = this.Model.settings.dictionaryAlias || null ;
        this.Model.settings.culture = this.Model.settings.culture || null ;
        if (this.Model.settings.TimeMatrix) {
          this.rentMatrix = [];
          this.rentMatrix = this.Model.settings.TimeMatrix;
          this.clearOfDebris();
        }

        if (this.Model.widgetType !== WidgetType.payment &&
            this.Model.widgetType !== WidgetType.purchaseSubscription &&
            this.Model.widgetType !== WidgetType.certificates) {
          this.initAdvancedFilters();
        }

        if (this.newThemeState?.entityStatus === EntityStatus.published)
          this.Model.widgetThemeId = this.newThemeState.id;

        if (typeof this.Model.settings.visibilityDescSub === 'undefined')
          this.Model.settings.visibilityDescSub = VisibilityDescriptionSub.HideEveryone;

      if (typeof this.Model.settings.availableInfo === 'undefined') {
        this.Model.settings.availableInfo = AvailableInfo.Everyone;
      }
    }

    modelTemplate(): Promise<IWidgetModel> {
        const result: IWidgetModel = {
            id: null,
            clubId: this.contextService.getRoutingParams().clubId,
            widgetThemeId: '',
            entityStatus: EntityStatus.published,
            widgetType: WidgetType.schedule,
            name: '',
            useForVk: false,
            settings: {
                activeDisplayType: 'default',
                showCoachesFilter: true,
                showGroupFilter: true,
                showCoursesFilter: true,
                showRoomFilter: true,
                showTrainingCategoryFilter: false,
                showLevelFilter: false,
                showClubNet: false,
                roomId: null,
                disableSubscribeOnClass: false,
                showBirthday: false,
                showComments: false,
                showOccupationLevel: false,
                showBookedFree: 'dontShow',
                disableRoom: false,
                surnameIsRequired: false,
                singleEntryForm: false,
                showAuthGoogle: true,
                showAuthApple: true,
                showAuthFacebook: true,
                showAuthVK: true,
                showAuthPhone: true,
                showAuthEmail: true,
                showAuthPush: true,
                showAuthLoginAndPassword: true,
                visibilityDescSub: VisibilityDescriptionSub.HideEveryone,
                availableInfo: AvailableInfo.Everyone,
                isFillComment: false,
                placeholderComment: null,
                message: null,
                isYouCanQueue: false,
                isHideCellsWithoutClasses: false
            }
        };
        return Promise.resolve(result);
    }

    buildForm() {
        this.form = this.formBuilder.group({
            name: [this.Model.name, [NotEmpty()]],
            widgetType: [this.Model.widgetType, [Validators.required]],
            entityStatus: [this.Model.entityStatus, []],
            widgetThemeId: [this.Model.widgetThemeId, []],
            useForVk: [this.Model.useForVk, []]
        });
    }

    onWidgetTypeChange(e) {
      if (e == 'payment' && !this.Model.id) {
        this.Model.settings.showAuthLoginAndPassword = true;
        this.Model.settings.showAuthGoogle = false;
        this.Model.settings.showAuthFacebook = false;
        this.Model.settings.showAuthVK = false;
        this.Model.settings.showAuthPhone = false;
        this.Model.settings.showAuthEmail = false;
        this.Model.settings.showAuthApple = false;
        this.Model.settings.showAuthPush = false;
      }
        // При смене типа виджета
        // Если выбран виджет "Фильтр расписания", то ставим "Видов занятий" в TRUE, остальное в FALSE
        // Если выбран тип "Расписание" тип, возвращаем фильтры "Тренеров", "Залов", "Направлений", "Видов занятий" в TRUE
        if(!this.Model.id) {
          if (e == 'scheduleFilter') this.changeFilterScheduleFilter('showCoursesFilter');
          else if (e == 'schedule') this.revertFilters();
        }

        this.Model.widgetType = e;
        this.updateThemes();
    }

    isThemeNotSuitable(widgetThemeId: string) {
        return this.themes.every(t => t.id !== widgetThemeId);
    }

    onEditThemeClick(widgetThemeId: string, event: Event) {
        event.preventDefault();
        if (!this.isThemeNotSuitable(widgetThemeId)) {
            const url = this.contextService.makeContextUrl(`/themes/${widgetThemeId}`);
            this.router.navigate([url]);
        }
    }

    onNewThemeClick(event: Event) {
        event.preventDefault();
        const url = this.contextService.makeContextUrl(`/themes/create/${this.Model.widgetType}`);
        this.router.navigate([url], { state: { newThemeState: {} } });
    }

    onCopyCodeClick(event: Event): void {
        event.preventDefault();
        if (this.copyTextToClipboard(this.code)) {
            this.alertsService.alert.next({
                type: 'success',
                message: '',
                header: 'Код вставки виджета скопирован в буффер',
                position: 'top',
                timeout: 3000
            });
        }
    }

    onInstallVkClick(): void {
        if (this.vkInstallUrl) {
            window.open(this.vkInstallUrl, '_blank');
        }
    }

    private onAddtoGroupMessage(ev) {
        if (ev.origin === 'https://vk.com'
            && ev.data.method === "app.addToGroup"
            && ev.data.app_id == environment.vkAppIds[this.Model.widgetType]
            && ev.data.group_ids.length)
        {
            this.apiService.widgets.vkBind(this.Model.id, ev.data.app_id, ev.data.group_ids);
        }
    }

    public onThemeChages(value) {
        this.currentTheme.widgetThemeId = this.currentTheme.widgetThemeId === value ? value : this.currentTheme.widgetThemeId;
    }

    private updateThemes(): void {
        this.service.apiV2Service.themes.find(this.routingParams.clubId, this.Model.widgetType)
            .then(resp => {
                this.themes = resp.sort((a,b) => b.name?.localeCompare(a.name) ?? 0);
                if (!this.Model.widgetThemeId && this.themes && this.themes.length) {
                    this.Model.widgetThemeId = this.themes[0].id;
                }
            });
    }

    private updateRooms(): void {
        this.service.apiService.rooms.getList(this.service.contextService.getRoutingParams())
            .then(resp => {
                this.rooms = resp;
                this.rooms.splice(0, 0, {
                    id: '',
                    entityStatus: EntityStatus.published,
                    name: 'Все залы',
                    square: 0,
                    length: 0,
                    width: 0,
                    color: '',
                    imageUrl: '',
                    medias: null,
                    address: '',
                    geoLat: 0,
                    geoLon: 0
                });
                const scheduleSettings: IScheduleSettings = this.Model.settings;
                if (!scheduleSettings.roomId && this.rooms && this.rooms.length) {
                    scheduleSettings.roomId = this.rooms[0].id;
                }
            });
    }

    private getVkInstallUrl(): string {
        const VKtype = this.Model.widgetType;
        const VKaid = environment.vkAppIds[VKtype];
        if (this.Model.id) {
            return `https://vk.com/add_community_app?aid=${VKaid}`;
        }
        return '';
    }

    private getWidgetCode(): string {
        const loaderUrl = `${environment.widgetsHost}/loader.js`;

        if (this.Model.id) {
            return `
<script async defer src="${loaderUrl}"></script>
<div data-sportpriority-widget-id="${ this.Model.id}"></div>`
                .trim();
        };
    }
    private copyTextToClipboard(text: string): boolean {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const isSuccessful = document.execCommand('copy');
            document.body.removeChild(textArea);
            return isSuccessful;
        } catch (err) {
            console.error('Oops, unable to copy', err);
            document.body.removeChild(textArea);
            return false;
        }
    }
    public hkSaveAddPress(that: this) {
        if (this.isEditAvailable) {
            return super.hkSaveAddPress(that);
        } else {
            return (e) => { return e };
        }
    }

    public hkSavePress(that: this) {
        if (this.isEditAvailable) {
            return super.hkSavePress(that);
        } else {
            return (e) => { return e };
        }
    }

    public hkDeletePress(that: this) {
        if (this.isEditAvailable) {
            return super.hkDeletePress(that);
        } else {
            return (e) => { return e };
        }
    }

    onFilterClick(e){
      if (!this.Model.settings[e.target?.htmlFor])
        return;

      e.preventDefault();
      e.stopPropagation();
    }

    changeFilterScheduleFilter(nameFilter: string){
      this.Model.settings.showCoachesFilter = false;
      this.Model.settings.showRoomFilter = false;
      this.Model.settings.showGroupFilter = false;
      this.Model.settings.showCoursesFilter = false;
      this.Model.settings.showTrainingCategoryFilter = false;
      this.Model.settings.showLevelFilter = false;
      this.Model.settings.showClubNet = false;

      this.Model.settings[nameFilter] = true;
      this.selectFilter = this.modelFlagToFilter(nameFilter);
      this.changeAdvancedFilter();
    }

    revertFilters(){
        this.Model.settings.showCoachesFilter = true;
        this.Model.settings.showRoomFilter = true;
        this.Model.settings.showGroupFilter = true;
        this.Model.settings.showCoursesFilter = true;
        this.Model.settings.showTrainingCategoryFilter = false;
        this.Model.settings.showLevelFilter = false;
        this.Model.settings.showClubNet = false;
        this.Model.settings.hidePaymentForm = false;
    }


    async initAdvancedFilters() {
      const ctx = this.contextService.getRoutingParams();
      const classes: Array<IClassItemModel> = (await this.apiService.schedule.getList(ctx)).classes;
      const clubs = await this.apiService.clubs.getClubsByNet(ctx);

      clubs.forEach(async (club) => {
        const wgs = await this.apiV2Service.widgets.getByClub(club.id);
        const wg = wgs.find(w => w.widgetType == WidgetType.schedule)
        if (!wg) return;

        this.filters.clubs.push({id: wg.id, name: club.name, check: this.Model.settings.advancedFilter?.ids.includes(wg.id)});
      });

      classes.forEach(cls => {
        if (cls.room?.id)
          if (!this.filters.rooms.find(r => r.id == cls.room?.id))
            this.filters.rooms.push(cls.room);
        if (cls.group?.id)
          if (!this.filters.groups.find(r => r.id == cls.group?.id))
          this.filters.groups.push(cls.group);
        if (cls.course?.id)
          if (!this.filters.courses.find(r => r.id == cls.course?.id))
            this.filters.courses.push(cls.course);
        if (cls.coach?.id)
          if (!this.filters.coaches.find(r => r.id == cls.coach?.id))
            this.filters.coaches.push(cls.coach);
        if (cls.trainingCategories?.length){
          cls.trainingCategories.forEach(t => {
            if (t.id)
              if (!this.filters.trainingCategories.find(r => r.id == t.id))
                this.filters.trainingCategories.push(t);
          });
        }
      });

      if (this.Model.settings.showCoachesFilter)
        this.selectFilter = this.modelFlagToFilter("showCoachesFilter");
      else if (this.Model.settings.showGroupFilter)
        this.selectFilter = this.modelFlagToFilter("showGroupFilter");
      else if (this.Model.settings.showCoursesFilter)
        this.selectFilter = this.modelFlagToFilter("showCoursesFilter");
      else if (this.Model.settings.showRoomFilter)
        this.selectFilter = this.modelFlagToFilter("showRoomFilter");
      else if (this.Model.settings.showTrainingCategoryFilter)
        this.selectFilter = this.modelFlagToFilter("showTrainingCategoryFilter");
      else if (this.Model.settings.showClubNet)
        this.selectFilter = this.modelFlagToFilter("showClubNet");

      if (this.Model.settings.advancedFilter){
        if (this.Model.settings.advancedFilter.ids?.length){
          this.filters[this.Model.settings.advancedFilter.name].forEach(f =>
            f.check = this.Model.settings.advancedFilter.ids.includes(f.id));
        };
      };
    }

    changeAdvancedFilter(f?: any){
      if (this.Model.settings.advancedFilter?.name != this.selectFilter){
        this.Model.settings.advancedFilter =  {
          name: this.selectFilter,
          ids: [],
          clubSchedules: []
        };
      }

      if (this.selectFilter == "clubs"){
        this.Model.settings.advancedFilter.clubSchedules = this.filters.clubs;
      }

      if (!f) return;
      f.check = !f.check;


      this.Model.settings.advancedFilter.ids.includes(f.id) ?
        this.Model.settings.advancedFilter.ids = this.Model.settings.advancedFilter.ids.filter(i => i != f.id) :
        this.Model.settings.advancedFilter.ids.push(f.id);
    }

    modelFlagToFilter(showFilter: string){
      switch (showFilter) {
        case "showCoachesFilter" : return "coaches"
        case "showGroupFilter" : return "groups"
        case "showCoursesFilter" : return "courses"
        case "showRoomFilter" : return "rooms"
        case "showTrainingCategoryFilter" : return "trainingCategories"
        case "showClubNet" : return "clubs"
      };
    }

    public finishEdit() {
      if (this.Model.widgetType !== WidgetType.schedule &&
          this.Model.widgetType !== WidgetType.payment &&
          this.Model.widgetType !== WidgetType.certificates &&
          this.Model.widgetType !== WidgetType.purchaseSubscription) {
        delete this.Model.settings.showAuthGoogle;
        delete this.Model.settings.showAuthFacebook;
        delete this.Model.settings.showAuthVK;
        delete this.Model.settings.showAuthPhone;
        delete this.Model.settings.showAuthEmail;
        delete this.Model.settings.showAuthApple;
        delete this.Model.settings.showAuthPush;
        delete this.Model.settings.showAuthLoginAndPassword;
      }
      super.finishEdit();
    }
}
