import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';

import { IOnValueChanged, onValueChanged, OnValueChangeBase } from 'app/common/validators/on-value-changed.class';
import { matchingPasswordsValidator } from 'app/common/validators/matching-password.validator';
import { SettingsService } from 'app/components/settings/services/settings.service';
import { debounceTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})

export class PasswordComponent extends OnValueChangeBase implements OnInit, IOnValueChanged {

  fieldMessages = {
    currentPassword: {
      required: 'Это поле обязательно',
    },
    newPassword: {
      required: 'Это поле обязательно',
      minlength: 'Пароль должен быть минимум 3 символа'
    },
    confirm: {
      required: 'Это поле обязательно',
      minlength: 'Пароль должен быть минимум 3 символа',
    },
  };

  formMessages = {
    passwordMatch: 'Пароли должны совпадать'
  };

  constructor(
    injector: Injector,
    private service: SettingsService
  ) {
    super(injector)
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(3)]],
      confirm: ['', [Validators.required, Validators.minLength(3)]]
    },
      {
        validator: matchingPasswordsValidator('newPassword', 'confirm')
      });

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }

  onSave() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    this.service.changePassword({ newPassword: this.form.value.newPassword, currentPassword: this.form.value.currentPassword })
      .then(() => { this.form.reset(); this.isSubmited = false; })
      .catch(() => { this.form.reset(); this.isSubmited = false; });
  }
}
