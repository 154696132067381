import { Component, OnInit, Injector } from '@angular/core';

import { Mixin } from 'app/common/decorators/mixin.decorator';
import { IWorkingHours } from 'app/common/models/working-hours.model';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';
import { WorkingTimeComponentCommon } from 'app/common/components/working-time/common/working-time.component';

import { WorktimeService } from 'app/common/components/working-time/services/worktime.service';

@Component({
  selector: 'app-working-time-toggle',
  templateUrl: './working-time.component.html',
  styleUrls: ['../common/working-time.component.scss'],
  providers: [WorktimeService]
})

@Mixin([WorkingTimeComponentCommon])
export class WorkingTimeToggleComponent extends ToggleEditComponent<IWorkingHours> implements OnInit {

  ngOnInitCommon: () => void;
  public days: Array<any>;
  public hours: Array<any>;

  constructor(
    public service: WorktimeService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.ngOnInitCommon();
    super.ngOnInit();
  }
}
