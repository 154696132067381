import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../form-controls-core/value-acessor-base';

@Component({
    selector: 'app-form-text-box',
    templateUrl: 'form-text-box.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormTextBoxComponent),
        multi: true
    }]
})
export class FormTextBoxComponent extends ValueAccessorBase<string> {
  @Input()
  type: string;

    constructor() {
        super();
    }

    onInit(){
      if (!this.type) this.type= "text";
    }
}
