<div class="input-wrap">
  <input class="input__text input__text--long form-control"
    [min]="minDate"
    [max]="maxDate"
    matInput
    [matDatepicker]="picker"
    [value]="dateValue"
    (dateInput)="addEvent($event)"
    [placeholder]="placeholder"
    required
  />
  <mat-datepicker-toggle class="datepicker" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>

