import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { AlertsService } from './services/alerts.service';
import { IAlert, IConfirm, IConfirmButton, IinputItem } from 'app/common/models/alerts.model';
import {ContextService} from '../../services/context.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateX(-50%) translateY(-100%)' }),
          animate('300ms', style({ transform: 'translateX(-50%) translateY(0)' }))
        ]),
        transition(':leave', [
          style({ transform: 'translateX(-50%) translateY(0)' }),
          animate('300ms', style({ transform: 'translateX(-50%) translateY(-100%)' }))
        ])
      ]
    )],
  encapsulation: ViewEncapsulation.None
})
export class AlertsComponent implements OnInit {
  public alert: IAlert = null;
  public exception: any = null;
  public confirm: IConfirm = null;
  public itemCollection: Array<IinputItem> = [];

  public activeButtonIndex = 0;
  private buttonsRef: Array<HTMLButtonElement> = [];
  private timeoutRef: number;

  private prevKeys: Hotkey | Hotkey[];
  private hkFocusPrev: Hotkey | Hotkey[];
  private hkFocusNext: Hotkey | Hotkey[];
  private hkEsc: Hotkey | Hotkey[];

  constructor(
    private service: AlertsService,
    protected hotkeysService: HotkeysService,
    public contextService: ContextService,
    public router: Router,
    private el: ElementRef
  ) { }

  public ngOnInit() {
    this.hkFocusPrev = this.hotkeysService.add(new Hotkey(['left', 'up'], this.hkFocusPress(this, 'left')), 'keyup');
    this.hkFocusNext = this.hotkeysService.add(new Hotkey(['right', 'down'], this.hkFocusPress(this, 'right')), 'keyup');

    this.service.alert.subscribe(alert => {
      this.alert = alert;
      if (alert) {
        const timeout = alert.timeout || 3000;
        clearTimeout(this.timeoutRef);
        (timeout > 0) && (this.timeoutRef = window.setTimeout(() => this.dismissAlert(), timeout));
      }
    });

    this.service.exception.subscribe(() => {
      const url = this.contextService.makeContextUrl('support');
      console.log(url);
      this.router.navigateByUrl(url);
    });

    this.service.confirm.subscribe(confirm => {
      this.confirm = confirm;

      if (confirm) {
        this.activeButtonIndex = 0;
        setTimeout(() => {
          this.buttonsRef = Array.from((this.el.nativeElement as HTMLElement).querySelectorAll<HTMLButtonElement>('.confirm__buttons button'));
          this.buttonsRef[0].focus();
          this.buttonsRef.forEach(button => button.addEventListener('blur', (e) => {
              if (this.buttonsRef.includes(e.relatedTarget as HTMLInputElement))
                setTimeout(() => this.buttonsRef[this.activeButtonIndex].focus(), 1)
            }));
          if (confirm.inputs) {
            this.confirm.inputs.map((item: IinputItem) => {
              this.itemCollection[item.name] = item.value || '';
            });
          }
        });
      }

      this.setHotkeyState(!!confirm);
    });

  }

  public buttonClick(button: IConfirmButton) {
    const item = this.itemCollection;
    this.dismissConfirm(button);
    button.callback && setTimeout(() => button.callback(item), 1);
  }

  private hkFocusPress(that: this, key: string) {
    return (event: KeyboardEvent) => {
      if (event.type !== 'keyup') { return ; }
      that.activeButtonIndex = ((that.activeButtonIndex > 0 ? this.activeButtonIndex : this.buttonsRef.length) + (key === 'left' ? -1 : 1)) % this.buttonsRef.length;
      this.buttonsRef[that.activeButtonIndex].focus();
      event.preventDefault();
      return event;
    };
  }

  private hkEscPress(that: this) {
    return (event: KeyboardEvent) => {
      that.dismissConfirm();
      return event;
    };
  }

  public dismissAlert() {
    clearTimeout(this.timeoutRef);
    this.service.alert.next(null);
  }

  public dismissConfirm(button?: IConfirmButton) {
    let callbackEsc = !button && this.confirm.callbackEsc;
    this.buttonsRef = [];
    this.service.confirm.next(null);
    callbackEsc && setTimeout(() => callbackEsc(), 1);
  }

  private setHotkeyState(enabled: boolean) {
    if (enabled) {
      this.prevKeys = this.hotkeysService.getAll('esc');
      this.hotkeysService.pause(this.prevKeys);
      this.hkEsc = this.hotkeysService.add(new Hotkey('esc', this.hkEscPress(this)));
      this.hotkeysService.unpause(this.hkFocusNext);
      this.hotkeysService.unpause(this.hkFocusPrev);
    } else {
      this.hotkeysService.remove(this.hkEsc);
      this.hotkeysService.unpause(this.prevKeys);
      this.hotkeysService.pause(this.hkFocusNext);
      this.hotkeysService.pause(this.hkFocusPrev);
    }
  }
}
