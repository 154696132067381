<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание зала</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование зала</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>

    <div class="row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <div class="form-group" myScrollTo>
              <div class="box-title">Название</div>
              <div class="row__field">
                <input type="text" class="input__text input__text--long" formControlName="name" id="name" [ngModel]="Model.name" maxlength="500">
                <label for="name" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Площадь (м<sup>2</sup>)</div>
          <div class="row__field">
            <input type="number" min="1" max="100000" class="input__text input__text--long" id="square" formControlName="square" [ngModel]="Model.square">
            <label for="square" class="field-validation-error" *ngIf="fieldErrors.square && isSubmited">{{fieldErrors.square}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Длина (м)</div>
          <div class="row__field">
            <input type="number" min="1" max="100000" class="input__text input__text--long" id="length" formControlName="length" [ngModel]="Model.length">
            <label for="length" class="field-validation-error" *ngIf="fieldErrors.length && isSubmited">{{fieldErrors.length}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Ширина (м)</div>
          <div class="row__field">
            <input type="number" min="1" max="100000" class="input__text input__text--long" id="width" formControlName="width" [ngModel]="Model.width">
            <label for="width" class="field-validation-error" *ngIf="fieldErrors.width && isSubmited">{{fieldErrors.width}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <div class="form-group" myScrollTo>
          <div class="box-title">Адрес</div>
          <div class="clue-text">Впишите, если у зала есть собственный адрес (для отображения в карточке клуба)</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" formControlName="address" id="address" maxlength="500" [ngModel]="Model.address">
            <label for="address" class="field-validation-error" *ngIf="fieldErrors.address && isSubmited">{{fieldErrors.address}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-lg-12">
        <a href="https://yandex.ru/map-constructor/location-tool/" target="_blank">
          <span class="btn-primary__desc">Найти координаты на карте</span>
        </a>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Широта</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" formControlName="geoLat" id="geoLat" maxlength="500">
            <label for="geoLat" class="field-validation-error" *ngIf="fieldErrors.geoLat && isSubmited">{{fieldErrors.geoLat}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Долгота</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" formControlName="geoLon" id="geoLon" maxlength="500">
            <label for="geoLon" class="field-validation-error" *ngIf="fieldErrors.geoLon && isSubmited">{{fieldErrors.geoLon}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="">
          <div class="box-title">Цвет в расписании</div>
          <div class="clue-text">Выберите любой или введите свой в формате #abc или #abcdef</div>
          <div class="row__field">
            <app-color-picker formControlName="color" [ngModel]="Model.color"></app-color-picker>
            <label for="color" class="field-validation-error" *ngIf="fieldErrors.color && isSubmited">
              {{fieldErrors.color}}
            </label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="row__field">
            <app-gallery-cva [ngModel]="Model.medias" [multipleUpload]="true" (ngModelChange)="fastSaveMedias()" formControlName="medias" [multipleUpload]="true" [title]="'Фотогалерея'" (onItemOpen)="openImage($event)"></app-gallery-cva>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <br>
          <br>
          <div class="info-text">
            <div [ngClass]="{'info-text__box' : !isNotSaved()}">
              <span class="icon icon-about"></span>
              <span app-key-help [key]="'draftText'"></span>
            </div>

            <div *ngIf="!isNotSaved()">
              <span class="icon icon-about"></span>
              Статус:
              <ng-container *ngIf="isDraft()">Черновик</ng-container>
              <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isEditAvailable">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveDraft'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-primary" (click)="copyEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveNew'"></span>
                  </span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </form>
</div>
