import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {ContextService} from '../../common/services/context.service';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {Location} from '@angular/common';
import {AlertsService} from '../../common/components/alerts/services/alerts.service';
import * as moment from 'moment';

@Component({
  selector: 'app-resource-queue',
  templateUrl: './resource-queue.component.html',
  styleUrls: ['./resource-queue.component.scss']
})
export class ResourceQueueComponent implements OnInit {
  public static readonly componentName: string = 'ResourceQueueComponent';
  protected ngUnsubscribe: Subject<void> = new Subject();
  public isLoading = false;
  public  issueOrReturn: any[] = [];
  public issued: any[] = [];

  constructor(
    private contextService: ContextService,
    private apiV2: ApiV2Service,
    private alertsService: AlertsService,
    public _location: Location
  ) { }

  async ngOnInit(): Promise<any> {
    await this.init();
  }

  private async init(): Promise<any> {
    await this.apiV2.ResourceQueue.getIssueOrReturn(this.contextService.getRoutingParams().clubId).then(async resp => {
      this.issueOrReturn = resp['result'];
      await this.getIssued();
      this.isLoading = true;
    })
  }

  getIssue() {
    return this.issueOrReturn.filter(x => x.classRequest.fromType === 'issue');
  }

  getReturn() {
    return this.issueOrReturn.filter(x => x.classRequest.fromType === 'return');
  }

  async getIssued(): Promise<any> {
    await this.apiV2.ResourceQueue.getIssued(this.contextService.getRoutingParams().clubId).then(resp => {
      this.issued = resp['result'];
    })
  }

  close() { this._location.back(); }

  getSubCreated(clientSubscriptions: any) {
    return clientSubscriptions.filter(x => x.status === 'created');
  }

  getSubActive(clientSubscriptions: any) {
    return clientSubscriptions.filter(x => x.status === 'active');
  }

  isValidSelectedSubscriptions(item): boolean {
    if (item?.selected?.length > 0) { return true;
    } else { this.alertsService.alert.next({
      type: 'error',
      header: 'Необходимо выбрать абонементы для активации.',
      message: 'Абонементы с выдачей инвентаря вы после этого сможете отследить на вкладке выданных.',
      position: 'top',
      timeout: 4000
    }); return false; }
  }

  async setIssue(item: any): Promise<void> {
    if (!this.isValidSelectedSubscriptions(item)) { return ; }
    if (item?.selected?.length > 0) {
      await this.apiV2.ResourceQueue.setIssue(item.selected, item.classRequest.id).then(async resp => {
        if (resp) {
          await this.init();
        }
      })
    }
  }

  async setReturn(item: any, confirm: boolean = false): Promise<void> {
    if (confirm) {
      this.alertsService.showConfirm({
        header: 'Подтвердить возврат',
        message: 'Закрыть все текущие выдачи клиента и удалить заявку',
        buttons: [{
          title: 'Подтвердить',
          callback: async () => {
            await this.apiV2.ResourceQueue.setReturnByClientId(item.client.id).then(async resp => {
              if (resp) {
                await this.init();
              }
            })
          }
        }, {
          title: 'Отмена'
        }]
      });
      return ;
    }
    if (!this.isValidSelectedSubscriptions(item)) { return ; }
    if (item?.selected?.length > 0 && !confirm) {
      if (!confirm) {
        await this.apiV2.ResourceQueue.setReturn(item.selected, item.classRequest.id).then(async resp => {
          if (resp) {
            await this.init();
          }
        })
      }
    }
  }

  async removeRequest(item: any): Promise<void> {
    this.alertsService.showConfirm({
      header: 'Удалить заявку?',
      message: 'Внесенные изменения не сохранятся',
      buttons: [{
        title: 'Удалить',
        callback: async () => {
          await this.apiV2.classRequest.delete(item.classRequest.id).then(async () => {
            await this.init();
          })
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  async reload(): Promise<any> {
    await this.init();
  }

  dateFormat(toDate: any) {
    return moment(toDate).add(-30, 'days').add(-7, 'hours').format('DD.MM.YYYY HH:mm');
  }

  isMiss(toDate: any) {
    return Number(moment(toDate).add(-30, 'days').add(-7, 'hours').format('YYYYMMDD')) < Number(moment().format('YYYYMMDD'));
  }
}
