<header class="content__header" *ngIf="isLoading">
  <div class="content__header-title">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Реестр от {{dateFormat(registry.dateFrom)}} - {{dateFormat(registry.dateTo)}}
      </span>
    </div>
    <div class="block-btn-export-table">
      <button class="btn btn-primary btn-sm" type="submit" (click)="export(registryElement, 'ExcelZip', null, registryElementFilter)">
        <span class="btn-primary__desc">Весь реестр в Excel</span>
      </button>
      <button class="btn btn-primary btn-sm" type="submit" (click)="export(registryElement, 'PaymentOrderZip', null, registryElementFilter)">
        <span class="btn-primary__desc">Все платежные поручения</span>
      </button>
    </div>
  </div>
  <div class="clubnets-container">
    <div class="tableSideLinkWrap">
      <div class="clue-text">
        <span class="key">Кол-во клубов: <b class="pd">{{clubCountTotal}}</b></span>
        <span class="key">Сумма: <b class="pd">{{amountTotal.toFixed(4)}} руб.</b></span>
        <span class="key">Бонусы: <b class="pd">{{bonusesTotal.toFixed(4)}} руб.</b></span>
        <span class="key">Комиссия: <b class="pd">{{commissionTotal}} руб.</b></span>
        <span class="key">К выплате: <b class="pd">{{regAmoComTotal(null, amountTotal)}} руб.</b></span>
        <span class="key">
          Статус:
          <span *ngIf="registry.status == 'draft'" class="status-label pd" style="color: red;">Черновик</span>
          <span *ngIf="registry.status == 'created'" class="status-label pd" style="color: #dede00;">Создан</span>
          <span *ngIf="registry.status == 'confirmed'" class="status-label pd" style="color: yellowgreen;">Подтвержденный</span>
          <span *ngIf="registry.status == 'completed'" class="status-label pd" style="color: #01e001;">Завершенный</span>
        </span>
      </div>
      <a class="box-link" (click)="export(registryElement, 'CSV')" style="margin: 0;float: left;">
        <span>Экспорт в CSV</span>
      </a>
      <div style="float: right;">
        <label for="InitialPaymentNumber" class="">Начальный номер платежа:</label>
        <input type="number" min="1" class="input__text input__text--long" id="InitialPaymentNumber" [(ngModel)]="initialNumber">
      </div>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="isLoading">
  <div class="row">
    <ng-container>
      <div *ngFor='let c of registryElementFilter' class="col-12 col-xs-12">
        <h1 class="h1-link">
          <span (click)="openPageClub(c)">
            {{nameClub(c[0]) + typeClubPayGateway(c[0])}}
            <span class="legalName">{{legalInfoValue(c[0], 'LegalName')}}</span>
          </span>
          <span *ngIf="documentLink(c[0])"><a href="{{documentLink(c[0])}}" target="_blank">Документы</a></span>
          <span (click)="actionIsVisibility(c)">{{c[3].isVisibility ? '[Скрыть]' : '[Показать]'}}</span></h1>
        <div class="clearfix"></div>
        <div class="clubnets-container">
          <div class="tableSideLinkWrap">
            <div class="clue-text">
              <span class="key">Кол-во транзакций: <b class="pd">{{c[1].length}}</b></span>
              <span class="key">Сумма: <b class="pd">{{c[2].amount.toFixed(4)}} руб.</b></span>
              <span class="key">Бонусы: <b class="pd">{{c[2].bonuses.toFixed(4)}} руб.</b></span>
              <span class="key">Комиссия: <b class="pd">{{(c[2].amount - (regAmoComTotal(c[1], c[2].amount))).toFixed(4)}} руб.</b></span>
              <span class="key">К выплате: <b class="pd">{{regAmoComTotal(c[1], c[2].amount)}} руб.</b></span>
            </div>
          </div>
            <div class="content__header-title form-group">
              <ng-container>
                <input type="checkbox" class="checkbox__slide" id="check-variant-{{c[0]}}" [(ngModel)]="c[3].isExcludeTable" (ngModelChange)="excludeTable(c)">
                <label for="check-variant-{{c[0]}}" class="checkbox__slide_label">Исключить таблицу</label>
              </ng-container>
              <ng-container>
                <select [(ngModel)]="c[3].bankName" (ngModelChange)="changeBankName(c)" class="select_bank_name">
                  <option *ngFor='let bankName of bankNames' [value]="bankName">{{bankName}}</option>
                </select>
              </ng-container>
            <div class="ms-auto block-btn-export-table">
              <button class="btn btn-primary btn-sm" type="submit" (click)="export(c[1], 'ExcelFile', c)">
                <span class="btn-primary__desc">В Excel</span>
              </button>
              <button class="btn btn-primary btn-sm" type="submit" (click)="export(c, 'PaymentOrderFile', c)">
                <span class="btn-primary__desc">Платежное поручение</span>
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="c[3].isVisibility">
          <div class="table-responsive">
            <table class="table rowHover">
              <thead>
              <tr>
                <td class="table-cell table-title-cell">Контакт</td>
                <td class="table-cell table-title-cell">ФИО</td>
                <td class="table-cell table-title-cell">Коментарий</td>
                <td class="table-cell table-title-cell">Дата</td>
                <td class="table-cell table-title-cell" style="min-width: 160px;">Сумма</td>
                <td class="table-cell table-title-cell">Бонусы</td>
                <td class="table-cell table-title-cell">Город</td>
                <td class="table-cell table-title-cell">Клуб</td>
                <td class="table-cell table-title-cell">Банк</td>
                <td class="table-cell table-title-cell">Комиссия</td>
                <td class="table-cell table-title-cell"></td>
                <td class="table-cell table-title-cell" style="text-align: right;">
                  <button class="btn btn-clear active" (click)="removeTable(c)">
                    <span class="icon-cross icon-delete"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
                  </button>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let e of c[1]' [ngClass]="e.status == 'deleted' ? 'remove-f' : e.status == 'hold' ? 'hold-f' : e.status == 'mutualSettlement' ? 'mutualSettlement-f' : e.status == 'refund' ? 'refund-f' : null">
                <app-registry-element-edit [element]="e" (result)="editElement()" [registry]="registry"></app-registry-element-edit>

                <td class="table-cell table-cell_ignore" style="text-align: center; vertical-align: middle;">
                  <span class="table-cell__mobile-title">{{!e?.isEnableMode ? "Редактирование" : "Закрыть"}}</span>
                  <button class="btn btn-clear active" (click)="edit(e)" *ngIf="!e?.isEnableMode">
                    <span class="sp sp-pen"></span>
                  </button>
                  <button class="btn btn-clear" (click)="cancel(e)" *ngIf="e?.isEnableMode">
                    <span class="sp icon-cross icon-transparent">
                      <span class="icon-cross__left"></span>
                      <span class="icon-cross__right"></span>
                    </span>
                  </button>
                </td>
                <td class="table-cell table-cell_ignore" style="text-align: center;vertical-align: middle;">
                  <span class="table-cell__mobile-title">Удалить</span>
                  <button class="btn btn-clear active" (click)="remove(e.id, c)">
                    <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="main-block-add-elements">
              <app-registry-element-add [table]="c" (result)="addElement(c, $event)" *ngIf="c?.isEnableAdd"></app-registry-element-add>
              <div class="btn-add-registry-element" *ngIf="!c?.isEnableAdd">
                <span class="drop-zone__btn"><span class="sp-gallery_addphoto" (click)="addElem(c)"></span></span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="col-12 col-xs-12 col-md-4">
          <div class="form-group" myScrollTo>
            <div class="box-title">Статус</div>
            <div class="row__field">
              <select class="select select__long" id="status" [(ngModel)]="registry.status">
                <option value="draft">Черновик</option>
                <option value="created">Создан</option>
                <option value="confirmed">Подтвержденный</option>
                <option value="completed">Завершенный</option>
              </select>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      <div class="col-12 col-xs-12 col-md-4">
          <div class="form-group">
            <br>
            <br>
            <button class="btn btn-primary btn-small" (click)="save()">
              <span class="btn-primary__desc">Сохранить</span>
            </button>
          </div>
        </div>
    </ng-container>
  </div>
</div>


