import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { SupportComponent } from 'app/common/components/support/support.component';

export const supportRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/support',
  component: SupportComponent,
  canActivate: [AuthGuard],
  data: { title: 'Помогите стать лучше' }
};
