import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-visit-count-picker]',
  templateUrl: './visit-count-picker.component.html',
  styleUrls: ['./visit-count-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VisitCountPickerComponent),
    multi: true
  }
  ]
})
export class VisitCountPickerComponent {

  public data: number = null;
  constructor() { }


  private propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public isUnlimited() {
    return this.data >= 10000;
  }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.propagateChange(this.data);
    }
  }

  onChange(event) {
    this.writeValue(Math.min(9999, parseInt(event, 10)));
  }
}
