import {ICityModel} from './../../../common/models/city-model';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {Bank, Registry, RegistryElement, RegistryElementStatus, RegistryExportType} from '../../../common/models/registry.model';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {ExcelService} from './../../../common/services/excel.service';
import {CityService} from 'app/common/services/city.service';
import * as moment from 'moment';
import {CsvService} from 'app/common/services/csv.service';
import {TxtService} from '../../../common/services/txt.service';

@Component({
  selector: 'app-edit',
  templateUrl: './registry-edit.component.html',
  styleUrls: ['./registry-edit.component.scss']
})
export class RegistryEditComponent implements OnInit {
  public registryElement: RegistryElement[] = [];
  public registryElementFilter: any = [];
  public registry: Registry;
  public amountTotal: number;
  public commissionTotal: any = 0;
  public bonusesTotal: number;
  public clubCountTotal: number;
  public cities: ICityModel[] = [];
  public isLoading = false;
  public clubLegalInfo: any[] = [];
  public bankNames: any[] = [Bank.AlfaBank, Bank.Tinkoff, Bank.PSKB];
  public numberOrderPayment: number = 1;
  private localSettings = 'registryTableSettings';
  private initialNumber: number = 1;
  private dictIndData = {}
  private dictIndExclise = {}

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiV2: ApiV2Service,
    private alertsService: AlertsService,
    private excelService: ExcelService,
    private csvService: CsvService,
    private txtService: TxtService,
    private cityService: CityService
    ) { }

  async ngOnInit(): Promise<any> {
    this.route.queryParams.subscribe(async params => {
      if (params?.id) {
        this.localSettings = this.localSettings + '_' + params?.id;
        await this.cityService.getItemList().then(c => {
          this.cities = c;
        });
        await this.apiV2.registry.getElementByRegistryId(params.id).then(async e => {
          this.registryElement = e;
          for (let i = 0; i < this.registryElement.length; i++) {
            this.registryElement[i].city = this.cityName(this.registryElement[i].cityId);
            // this.registryElement[i].date = this.dateFormat(this.registryElement[i].date);
          }

          this.registryElement = this.registryElement.sort((a, b) => (a.date < b.date ? 1 : -1))
            .sort((a, b) => (a.status < b.status ? 1 : -1));

          const _clubIds = [];
          for (const r of this.registryElement) {
            if (!_clubIds.find(x => x === r?.clubId)) { _clubIds.push(r?.clubId); }
          }
          if (_clubIds.length > 0) {
            await this.apiV2.registry.getLegalInfoByClubIds(_clubIds).then(legalInfo => {
              if (legalInfo) {
                for (const _info of legalInfo) {
                  this.clubLegalInfo.push({
                    id: _info.clubId,
                    legalInfo: JSON.parse(_info.legalInfo)
                  });
                }
              }
            })
          }

          const filterR = this.registryElement.reduce(function (r, a) {
            r[a.clubId] = r[a.clubId] || [];
            r[a.clubId].push(a);
            return r;
          }, Object.create(null));
          this.registryElementFilter = Object.entries(filterR);
        });
        await this.apiV2.registry.getById(params.id).then(e => {
          this.registry = e;
        });

        for (let i = 0; i < this.registryElementFilter.length; i++) {
          const t = {
            amount: 0,
            bonuses: 0
          };

          t.amount = this.dataAmountClub(this.registryElementFilter[i][0], 'amount', false);
          t.bonuses = this.dataAmountClub(this.registryElementFilter[i][0], 'bonuses', false);

          this.registryElementFilter[i].push(t);
        }

        this.createLocalRegistryTableSettings();

        this.amountTotal = this.dataAmountClub(null, 'amount', true);
        this.bonusesTotal = this.dataAmountClub(null, 'bonuses', true);
        this.clubCountTotal = this.dataAmountClub(null, 'clubCount', true);
        this.isLoading = true;
      }
    });
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public data(data: string, field: string): string {
    return JSON.parse(data)[field];
  }

  public nameClub(clubId: string): string {
    return this.registryElement.filter(x => x.clubId === clubId)[0]?.club;
  }

  public legalInfoValue(clubId: string, alias: string) {
    const legalInfo = this.clubLegalInfo.find(x => x.id === clubId);
    if (legalInfo)
      return legalInfo.legalInfo[alias]
    return null;
  }

  public typeClubPayGateway(clubId: string): string {
    const result = this.registryElement.filter(x => x.clubId === clubId && x.clubPaymentGateway)[0]?.clubPaymentGateway;
    return result ? ` (${result})` : '';
  }

  documentLink(clubId: string) {
    return this.clubLegalInfo.filter(x => x.id === clubId)[0]?.legalInfo?.DocumentLink;
  }

  public cityName(cityId: string): string {
    return this.cities.filter(x => x.id === cityId)[0]?.name;
  }

  private dataAmountClub(clubId: string, type: string, total: boolean): any {
    let f =  this.registryElement.filter(x => x.clubId === clubId);
    if (total) {
      f = this.registryElement;
      for (let i = 0; i < this.registryElementFilter.length; i++) {
        if (this.registryElementFilter[i][3].isExcludeTable) {
          f = f.filter(x => x.clubId !== this.registryElementFilter[i][0])
        }
      }
    }
    if (f.length === 0) {
      this.commissionTotal = 0;
      return 0;
    }
    this.commissionTotal = (f.filter(x => x.status !== RegistryElementStatus.deleted).reduce((prev, curr) => { return prev + curr.amount }, 0) -
      this.regAmoComTotal(f, f.filter(x => x.status !== RegistryElementStatus.deleted).reduce((prev, curr) => { return prev + curr.amount }, 0))).toFixed(4);
    if (type === 'amount') {
      return f.filter(x => x.status !== RegistryElementStatus.deleted).reduce((prev, curr) => { return prev + curr.amount }, 0);
    } else if (type === 'bonuses') {
      return f.filter(x => x.status !== RegistryElementStatus.deleted).reduce((prev, curr) => { return prev + curr.bonuses }, 0);
    } else if (type === 'clubCount') {
        return this.registryElementFilter.filter(x => !x[3].isExcludeTable).length;
      }
  }

  public async remove(elementId: string, elemGroup: any): Promise<void> {
    this.alertsService.showConfirm({
      header: 'Удалить транзакцию?',
      message: 'Эта операция изменит статус транзакции на "Удалено"',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.delete(elementId, elemGroup);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public async save(): Promise<any> {
    await this.apiV2.registry.save(this.registry).then(async (e) => {
      if (e) {
        this.alertsService.alert.next({
          type: 'success',
          message: 'Реестр сохранен',
          header: 'Успех',
          position: 'top',
          timeout: 2000
        });
        this.close();
      } else {
        console.error(e);
      }
    });
  }

  public close(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private async delete(elementId: string, elemGroup: any): Promise<any> {
    await this.apiV2.registry.removeElement(elementId).then(async (remove) => {
      if (remove) {
        elemGroup[1].filter(x => x.id === elementId)[0].status = RegistryElementStatus.deleted;

        if (elemGroup[1].length === 0) {
          this.registryElementFilter = this.registryElementFilter.filter(x => x[0] !== elemGroup[0]);
        }

        // this.registryElement = this.registryElement.filter(x => x.id !== elementId);

        elemGroup[2].amount = this.dataAmountClub(elemGroup[0], 'amount', false);
        elemGroup[2].bonuses = this.dataAmountClub(elemGroup[0], 'bonuses', false);

        this.amountTotal = this.dataAmountClub(null, 'amount', true);
        this.bonusesTotal = this.dataAmountClub(null, 'bonuses', true);
        this.clubCountTotal = this.dataAmountClub(null, 'clubCount', true);
        this.registry.data = JSON.stringify({
          Amount: this.amountTotal,
          ClubCount: this.clubCountTotal,
          Settings: this.dictIndExclise
        });
      } else {
        console.error(remove);
      }
    });
  }

  public export(data: any[], ext: RegistryExportType, d?: any, ds?: any[]): any {
    if (ext !== RegistryExportType.PaymentOrderFile && ext !== RegistryExportType.PaymentOrderZip) {
      for (let i = 0; i < this.registryElementFilter.length; i++) {
        if (this.registryElementFilter[i][3].isExcludeTable) {
          data = data.filter(x => x.clubId !== this.registryElementFilter[i][0])
        }
      }
    }

    // формирование словаря для ПП
    for (let ind = 0; ind < this.registryElementFilter.length; ind++)
    {
      this.dictIndData[this.registryElementFilter[ind][0]] = ind;
    }

    const exportA: any[] = [];
    data.filter(x => x.status !== RegistryElementStatus.deleted).forEach((m, index) => {
      const city = this.cities.filter(x => x.id === m.cityId)[0]?.name;
      const i = ++index;
      if (ext === RegistryExportType.CSV) {
        const e: any =  {
          'Контакт': m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
          'ФИО': m.fullName?.replace(',', '').replace(';', '').trim() ?? '',
          'Коментарий': m.comment?.replace(',', '').replace(';', '').trim(),
          'Дата': m.date.slice(0, -8),
          'Сумма': m.amount.toString().trim(),
          'Банк': m.bankName,
          'Бонусы': m.bonuses.toString().trim(),
          'Город': city?.trim(),
          'Клуб': m.club.replace(',', '').replace(';', '').trim(),
          'Комиссия': m.commission.toString().replace('.', ',').trim() + '%',
        };
        exportA.push(e);
      } else if (ext === RegistryExportType.ExcelFile || ext === RegistryExportType.ExcelZip) {
        const e: any =  {
          number: i.toString(),
          contact: m.contact?.replace('&lt;', '<').replace('&gt;', '>').replace(',', '').replace(';', '').trim(),
          fio: m.fullName?.replace(',', '').replace(';', '').trim() ?? '',
          comment: m.comment?.replace(',', '').replace(';', '').trim(),
          date: m.date.slice(0, -8),
          amount: m.amount.toString().trim() + ' руб.',
          bank: m.bankName,
          city: city?.trim(),
          comm: m.commission.toString().replace('.', ',').trim() + '%',
        };
        exportA.push(e);
      }
    });

    if (ext === RegistryExportType.PaymentOrderFile || ext === RegistryExportType.PaymentOrderZip) {
      if (ext === RegistryExportType.PaymentOrderFile) {
        if (!data[3].isExcludeTable) {
          exportA.push(this.paymentOrder(data));
        }
      } else if (ext === RegistryExportType.PaymentOrderZip) {
        data = this.registryElementFilter;
        for (const _dE of data) {
          if (!_dE[3].isExcludeTable) {
            exportA.push(this.paymentOrder(_dE));
          }
        }
      }
    }
    if (exportA.length === 0) {
      this.alertsService.alert.next({
        type: 'danger',
        header: 'Реестр пустой',
        message: 'В выгрузку не попадает не один клуб',
        position: 'top',
        timeout: 3000
      });
      return;
    }
    if (ext === RegistryExportType.CSV) {
      this.csvService.downloadFile(exportA, `Реестр от ${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`);
    } else if (ext === RegistryExportType.ExcelZip) {
      this.excelService.exportAsExcelFileInZip(
        exportA,
        `${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
        ds,
        this.registry,
        this.cities
      );
    } else if (ext === RegistryExportType.ExcelFile) {
      const e: any[] = [];
      d[1].forEach(m => {
        if (m.status === RegistryElementStatus.published || m.status === RegistryElementStatus.hold) {
          e.push(m.amount / 100 * m.commission);
        }
      });
      const registerAmountTotal = d[2].amount - e.reduce((a, b) => a + b, 0);
      const details = {
        clubName: d[1][0].club,
        registerAmount: d[2].amount,
        records: d[1].filter(x => x.status === RegistryElementStatus.published || x.status === RegistryElementStatus.hold).length.toString(),
        registryDate: moment().format('DD.MM.YYYY'),
        paymentDates: `${moment(this.registry.dateFrom).format('DD.MM.YYYY')} - ${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
        registerAmountTotal: registerAmountTotal,
        payer: this.getPayer(d),
        sityExists: true
      }
      this.excelService.exportAsExcelFileReestr(
        exportA,
        `Реестр клуба ${details.clubName} (${this.cities.filter(x => x.id === data[0]?.cityId)[0]?.name}) от ${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
        details);
    } else if (ext === RegistryExportType.PaymentOrderZip || ext === RegistryExportType.PaymentOrderFile) {
      this.txtService.downloadFileApi(exportA);
    }
  }

  private paymentOrder(data: any) {
    const legalInfo = this.clubLegalInfo.find(x => x.id === data[0]);
    const payer = this.payer(data[3].bankName);
    this.numberOrderPayment = this.initialNumber;
    this.numberOrderPayment += this.dictIndData[data[0]];
    return {
      PrefixFileName: 'ПП',
      ClubName: `${this.nameClub(data[0])}`,
      BankName: `${data[3].bankName}`,
      Date: `${moment(this.registry.dateFrom).format('DD.MM.YYYY')}-${moment(this.registry.dateTo).format('DD.MM.YYYY')}`,
      Details: [
        {
          'СекцияДокумент': 'Платежное поручение',
          'Номер': `${this.numberOrderPayment}`,
          'Дата': moment().format('DD.MM.YYYY'),
          'Сумма': this.regAmoComTotal(data[1], data[2].amount, 2),
          'ПлательщикСчет': payer?.Account,
          'Плательщик': `ИНН ${payer?.Inn} ${payer?.Payer}`,
          'ПлательщикИНН': payer?.Inn,
          'Плательщик1': payer?.Payer,
          'ПлательщикРасчСчет': payer?.Account,
          'ПлательщикБанк1': payer?.Bank1,
          'ПлательщикБанк2': payer?.Bank2,
          'ПлательщикБИК': payer?.Bik,
          'ПлательщикКорсчет': payer?.CorrespondentAccount,
          'ПолучательСчет': legalInfo?.legalInfo?.CurrentAccountNumber,
          'Получатель': `${legalInfo?.legalInfo?.Inn ? 'ИНН ' + legalInfo?.legalInfo?.Inn + ' ' : null}${legalInfo?.legalInfo?.LegalName?.replace(/«|»/g, '"')}`,
          'ПолучательИНН': legalInfo?.legalInfo?.Inn,
          'Получатель1': legalInfo?.legalInfo?.LegalName?.replace(/«|»/g, '"'),
          'ПолучательРасчСчет': legalInfo?.legalInfo?.CurrentAccountNumber,
          'ПолучательБанк1': legalInfo?.legalInfo?.NameBank,
          'ПолучательБИК': legalInfo?.legalInfo?.BikBank,
          'ПолучательКорсчет': legalInfo?.legalInfo?.NumberBankCorrespondentAccount,
          'ВидОплаты': '01',
          'Очередность': '5',
          'НазначениеПлатежа': `Оплата услуг за период с ${moment(this.registry.dateFrom).format('DD.MM.YYYY')} до ${moment(this.registry.dateTo).format('DD.MM.YYYY')}. ${legalInfo?.legalInfo?.ContractBasisPayment} / ${this.nameClub(data[0]).replace(/«|»/g, '"').replace(/—/g, '-')}. НДС не облагается`, // Назначение платежа одной строкой
        }
      ]
    };
  }

  private getPayer(data: any) {
    const legalInfo = this.clubLegalInfo.find(x => x.id === data[0]);
    return legalInfo?.legalInfo?.LegalName?.replace(/«|»/g, '"');
  }

  private payer(bank: Bank) {
    switch (bank) {
      case Bank.AlfaBank:
        return {
          CorrespondentAccount: '30101810600000000774',
          Account: '40702810623000004276',
          Bik: '045004774',
          Bank1: 'ФИЛИАЛ "НОВОСИБИРСКИЙ" АО "АЛЬФА-БАНК"',
          Bank2: 'г. Новосибирск',
          Inn: '5404052028',
          Kpp: '540401001',
          Payer: 'Общество с ограниченной ответственностью "Единый спортивный абонемент"',
        }
        break;
      case Bank.Tinkoff:
        return {
          CorrespondentAccount: '30101810145250000974',
          Account: '40702810310000894083',
          Bik: '044525974',
          Bank1: 'АО "Тинькофф Банк"',
          Bank2: 'г. Москва',
          Inn: '5404052028',
          Kpp: '540401001',
          Payer: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЕДИНЫЙ СПОРТИВНЫЙ АБОНЕМЕНТ"',
        }
        break;
      case Bank.PSKB:
        return {
          CorrespondentAccount: '30101810345250000606',
          Account: '40702810401000900118',
          Bik: '044525606',
          Bank1: 'Московский филиал Акционерного общества «Петербургский социальный коммерческий банк»',
          Bank2: 'г. Москва',
          Inn: '5404052028',
          Kpp: '540401001',
          Payer: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЕДИНЫЙ СПОРТИВНЫЙ АБОНЕМЕНТ"',
        }
        break;
    }
  }

  public async edit(element: RegistryElement): Promise<void> {
    element.contact = element.contact.replace('&lt;', '<').replace('&gt;', '>');
    element.isEnableMode = true;
  }

  public addElem(table: any): void {
    table.isEnableAdd = true;
  }

  public async cancel(element: RegistryElement): Promise<void> {
    element.contact = element.contact.replace('<', '&lt;').replace('>', '&gt;');
    element.isEnableMode = false;
  }

  public async removeTable(table: any): Promise<any> {
    this.alertsService.showConfirm({
      header: 'Удалить таблицу из реестра?',
      message: 'Эта операция полностью "Удалит" таблицу из реестра',
      buttons: [{
        title: 'Удалить',
        callback: () => {
          this.deleteRemoveTable(table);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  public async deleteRemoveTable(table: any): Promise<any> {
    await this.apiV2.registry.removeElements(this.registry.id, table[0]).then(async (remove) => {
      if (remove) {
        this.registryElement = this.registryElement.filter(x => x.clubId !== table[0]);
        this.registryElementFilter = this.registryElementFilter.filter(x => x[0] !== table[0]);
        this.amountTotal = this.dataAmountClub(null, 'amount', true);
        this.bonusesTotal = this.dataAmountClub(null, 'bonuses', true);
        this.clubCountTotal = this.dataAmountClub(null, 'clubCount', true);
        this.registry.data = JSON.stringify({
          Amount: this.amountTotal,
          ClubCount: this.clubCountTotal,
          Settings: this.dictIndExclise
        });
      } else {
        console.error(remove);
      }
    });
  }

  public async editElement(): Promise<any> {
    this.amountTotal = this.dataAmountClub(null, 'amount', true);
    this.bonusesTotal = this.dataAmountClub(null, 'bonuses', true);
    this.clubCountTotal = this.dataAmountClub(null, 'clubCount', true);
    this.registry.data = JSON.stringify({
      Amount: this.amountTotal,
      ClubCount: this.clubCountTotal,
      Settings: this.dictIndExclise
    });

    for (let i = 0; i < this.registryElementFilter.length; i++) {
      this.registryElementFilter[i][2].amount = this.dataAmountClub(this.registryElementFilter[i][0], 'amount', false);
      this.registryElementFilter[i][2].bonuses = this.dataAmountClub(this.registryElementFilter[i][0], 'bonuses', false);
    }
  }

  public async addElement(table: any, newElement: RegistryElement): Promise<any> {
    this.registryElement.push(newElement);
    table[1].push(newElement);
    for (let i = 0; i < this.registryElementFilter.length; i++) {
      this.registryElementFilter[i][2].amount = this.dataAmountClub(this.registryElementFilter[i][0], 'amount', false);
      this.registryElementFilter[i][2].bonuses = this.dataAmountClub(this.registryElementFilter[i][0], 'bonuses', false);
    }
    this.amountTotal = this.dataAmountClub(null, 'amount', true);
    this.bonusesTotal = this.dataAmountClub(null, 'bonuses', true);
    this.clubCountTotal = this.dataAmountClub(null, 'clubCount', true);
    this.registry.data = JSON.stringify({
      Amount: this.amountTotal,
      ClubCount: this.clubCountTotal,
      Settings: this.dictIndExclise
    });
  }

  public regAmoComTotal(d: any, amount: any, toFixed = 4): any {
    if (d === null) {
      return (amount - this.commissionTotal).toFixed(toFixed);
    }
    const e: any[] = [];
    d.forEach(m => {
      if (m.status === RegistryElementStatus.published || m.status === RegistryElementStatus.hold) {
        e.push(m.amount / 100 * m.commission);
      }
    });
    return (amount - e.reduce((a, b) => a + b, 0)).toFixed(toFixed);
  }

  openPageClub(c: any) {
    this.apiV2.context.get(c[0]).then(context => {
      const club = context.clubs.filter(x => x.id === c[0])[0];
      window.open(`${club.cityId}/${club.clubNetId}/${club.id}/club`);
    })
  }

  actionIsVisibility(c: any): any {
    c[3].isVisibility = !c[3].isVisibility;
    this.createLocalRegistryTableSettings(c);
}

  excludeTable(c: any) {
    // c[3].isExcludeTable = !c[3].isExcludeTable;

    this.amountTotal = this.dataAmountClub(null, 'amount', true);
    this.bonusesTotal = this.dataAmountClub(null, 'bonuses', true);
    this.clubCountTotal = this.dataAmountClub(null, 'clubCount', true);
    this.createLocalRegistryTableSettings(c);
  }

  changeBankName(c: any) {
    this.createLocalRegistryTableSettings(c);
  }

  createLocalRegistryTableSettings(c: any = null) {
    let _arrayClubsNameBanks = JSON.parse(localStorage.getItem("lasSelectedBank"));
    if (_arrayClubsNameBanks === null)
      _arrayClubsNameBanks = []

    if (c == null) {
      const _localBase = localStorage.getItem(this.localSettings);
      if (!_localBase) {
        let _data = JSON.parse(this.registry.data);
        if (_data.Settings) {
          const setLocalRegistryTableSettings = [];
          this.registryElementFilter.forEach(item => {
            let _d = _data.Settings[item[0]]
            if (_d)
            {
              setLocalRegistryTableSettings.push({ id: this.registry.id, clubId: item[0], isVisibility: _d?.isVisibility, isExcludeTable: _d?.isExcludeTable })
              item.push({ isVisibility: _d?.isVisibility, isExcludeTable: _d?.isExcludeTable, bankName: _d?.bankName })
              this.dictIndExclise[item[0]] = { "isExcludeTable": item[3]?.isExcludeTable, "isVisibility" : item[3]?.isVisibility, "bankName" : item[3]?.bankName }
            }
            else
            {
              setLocalRegistryTableSettings.push({ id: this.registry.id, clubId: item[0], isVisibility: true, isExcludeTable: false })
              item.push({ isVisibility: true, isExcludeTable: false, bankName: _d?.bankName })
              this.dictIndExclise[item[0]] = { "isExcludeTable": item[3]?.isExcludeTable, "isVisibility" : item[3]?.isVisibility, "bankName" : item[3]?.bankName }
            }
          })
        }
        else {
          const setLocalRegistryTableSettings = [];
          this.registryElementFilter.forEach(item => {
            if (_arrayClubsNameBanks.filter(x => x.clubId === item[0]).length > 0)
            {
              let _lastBank = _arrayClubsNameBanks.filter(x => x.clubId === item[0])[0].bankName
              setLocalRegistryTableSettings.push({ id: this.registry.id, clubId: item[0], isVisibility: true, isExcludeTable: false })
              item.push({ isVisibility: true, isExcludeTable: false, bankName: _lastBank })
              this.dictIndExclise[item[0]] = { "isExcludeTable": item[3].isExcludeTable, "isVisibility" : item[3].isVisibility, "bankName" : _lastBank }
            }
            else
            {
              setLocalRegistryTableSettings.push({ id: this.registry.id, clubId: item[0], isVisibility: true, isExcludeTable: false })
              item.push({ isVisibility: true, isExcludeTable: false, bankName: undefined })
              this.dictIndExclise[item[0]] = { "isExcludeTable": item[3].isExcludeTable, "isVisibility" : item[3].isVisibility, "bankName" : undefined }
            }
          })
          localStorage.setItem(this.localSettings, JSON.stringify(setLocalRegistryTableSettings));
            this.registry.data = JSON.stringify({
              Amount: this.dataAmountClub(null, 'amount', true),
              ClubCount: this.dataAmountClub(null, 'clubCount', true),
              Settings: this.dictIndExclise
          });
        }
        return;
      } else {
        const _localTemp = JSON.parse(_localBase);
        let _data = JSON.parse(this.registry.data);
        this.registryElementFilter.forEach(item => {
          let _lastBank = undefined;
          if (_arrayClubsNameBanks.filter(x => x.clubId === item[0]).length > 0)
            _lastBank = _arrayClubsNameBanks.filter(x => x.clubId === item[0])[0].bankName
          if (_data && _data.Settings)
          {
            let _d = _data?.Settings[item[0]]
            item.push({
              isVisibility: _d?.isVisibility,
              isExcludeTable: _d?.isExcludeTable,
              bankName: _d?.bankName
            });
          }
          else
          {
            item.push({
              isVisibility: _localTemp.filter(x => x.clubId === item[0])[0].isVisibility,
              isExcludeTable: _localTemp.filter(x => x.clubId === item[0])[0].isExcludeTable,
              bankName: _localTemp.filter(x => x.clubId === item[0])[0].bankName ?? _lastBank
            });
          }
          this.dictIndExclise[item[0]] = { "isExcludeTable": item[3].isExcludeTable, "isVisibility" : item[3].isVisibility, "bankName" : item[3].bankName }
        });
        this.registry.data = JSON.stringify({
          Amount: this.dataAmountClub(null, 'amount', true),
          ClubCount: this.dataAmountClub(null, 'clubCount', true),
          Settings: this.dictIndExclise
        });
      }
    } else {

      const _localTemp = JSON.parse(localStorage.getItem(this.localSettings));
      _localTemp.filter(x => x.id === this.registry.id && x.clubId === c[0])[0].isVisibility = c[3].isVisibility;
      _localTemp.filter(x => x.id === this.registry.id && x.clubId === c[0])[0].isExcludeTable = c[3].isExcludeTable;
      _localTemp.filter(x => x.id === this.registry.id && x.clubId === c[0])[0].bankName = c[3].bankName;
      localStorage.setItem(this.localSettings, JSON.stringify(_localTemp));

      let _rrr = { clubId: c[0], bankName: c[3].bankName }
      if (_arrayClubsNameBanks.filter(x => x.clubId === c[0]).length > 0)
        _arrayClubsNameBanks.filter(x => x.clubId === c[0])[0].bankName = c[3].bankName;
      else
        _arrayClubsNameBanks.push(_rrr)
      localStorage.setItem("lasSelectedBank", JSON.stringify(_arrayClubsNameBanks))

      let _idTable = c[0]
      this.dictIndExclise[_idTable] = { "isExcludeTable": c[3].isExcludeTable, "isVisibility" : c[3].isVisibility, "bankName" : c[3].bankName }
      this.registry.data = JSON.stringify({
        Amount: this.amountTotal,
        ClubCount: this.clubCountTotal,
        Settings: this.dictIndExclise
      });
    }
  }
}
