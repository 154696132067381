<div class="text-edit-wr" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="title-wrap">
    <div class="box-title">
      {{title}}
      <span class="box-edit" (click)="onBeginEdit()" [hidden]="enableEdit" *ngIf="!isReadonly">
        <span class="sp sp-pen"></span>
      </span>
    </div>
    <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
      <span class="row__save_save" (click)="onSave()">
        <span class="icon-check icon-check_color">
          <span class="icon-check__left"></span>
          <span class="icon-check__right"></span>
        </span>
        <span class="title">Сохранить</span>
      </span>
      <span class="row__save_cancel" (click)="onCancel()">
        <span class="icon-cross icon-cross_color">
          <span class="icon-cross__left"></span>
          <span class="icon-cross__right"></span>
        </span>
        <span class="title">Отменить</span>
      </span>
    </div>
  </div>
  <div class="row__field" [hidden]="enableEdit">
    <div class="field wysiwyg" (click)="onBeginEdit()" *ngIf="tempData && tempData !== null && type!='date' || renderZero" [innerHtml]=" '<span class=\'dark-gray\'>'+tempData+'</span>' ">
    </div>

    <div class="field wysiwyg" (click)="onBeginEdit()" *ngIf="((tempData && tempData != null)||(controlData && controlData!= null)) && type=='date'"
      [innerHtml]=" '<span class=\'dark-gray\'>'+dateFormatted+'</span>' ">
    </div>

    <div class="field wysiwyg" (click)="onBeginEdit()" *ngIf="(!tempData && !renderZero || tempData == null ) && (!controlData || controlData == null)"
      [innerHtml]=" '<span class=\'gray\'>[не указано]</span>' ">
    </div>
  </div>


  <div class="row__field pd" [hidden]="!enableEdit" *ngIf="!isReadonly">

    <div class="row__field-tooltip" *ngIf="tooltip && tooltip.length">{{tooltip}}</div>

    <ckeditor [config]="customConfigForCkeditor" (ready)="onReady($event)" [(ngModel)]="tempData" *ngIf="type=='ckeditor'"></ckeditor>

    <textarea [disabled]="!enableEdit" [(ngModel)]="tempData" class="inputField" *ngIf="type=='textarea'" maxlength="10000"></textarea>
    <input type="text" class="input__text input__text--long inputField" [appRestrictInput]="{min:0, max:100000000}" [disabled]="!enableEdit" [(ngModel)]="tempData" *ngIf="type=='input'"
      maxlength="500">

    <ng-container *ngIf="type=='date'">
      <input type="text" class="input__text input__text--long form-control" [formControl]="dateControl" [matDatepicker]="picker"
        placeholder="ДД.ММ.ГГГГ" (dateChange)="onDateChange($event)">

      <mat-datepicker-toggle class="datepicker" matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker touchUi="true"></mat-datepicker>
    </ng-container>
    <ng-container *ngIf="type=='dateTime'">
      <input type="text" class="input__text input__text--long form-control" [(ngModel)]="dateTime" [matDatepicker]="picker"
             placeholder="ДД.ММ.ГГГГ" readonly (dateChange)="onDateTimeChange($event)" (click)="buttonDatePick._open($event)">
      <button type="button" (click)="deleteDateTime()">Удалить</button>

      <mat-datepicker-toggle class="datepicker" matSuffix [for]="picker" #buttonDatePick></mat-datepicker-toggle>
      <mat-datepicker #picker touchUi="true"></mat-datepicker>
    </ng-container>
  </div>
  <label class="field-validation-error" *ngIf="validationMessage">{{validationMessage}}</label>
</div>
