<mat-form-field>
  <mat-select [formControl]="entityCtrl" #singleSelect (selectionChange)="onChange($event)" multiple="{{_multiple}}" [disabled]="isDisabled">
    <ngx-mat-select-search
      [formControl]="entityFilterCtrl"
      [placeholderLabel]="'Поиск'"
      [noEntriesFoundLabel]="'Ничего не найдена'">
    </ngx-mat-select-search>
    <mat-option *ngFor="let entity of filteredEntities  | async" [value]="entity.id">{{entity.name}}</mat-option>
  </mat-select>
  <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
</mat-form-field>
