<div class="row">
  <ul class="col-xs-12 tab-group">
    <li class="tab-group__item"  [class.active]="selectedTabIndex === 0" (click)="selectedTabChanged({index: 0})">
      Абонементы <span>({{Model.length}})</span>
    </li>
    <li class="tab-group__item"  [class.active]="selectedTabIndex === 1" (click)="selectedTabChanged({index: 1})">
      Архив абонементов <span>({{ModelArchive.length}})</span>
    </li>
  </ul>
</div>

<ng-container [ngSwitch]="selectedTabIndex">
  <div *ngSwitchDefault [@enterTrigger]="enterTriggerState">
    <div class="listEmpty" *ngIf="Model && Model.length == 0">
      <span>Список пуст</span>
    </div>
    <div class="table-responsive" *ngIf="Model && Model.length">
      <table class="table rowHover">
        <thead>
        <tr>
          <td class="table-cell table-title-cell">Название</td>
          <td class="table-cell table-title-cell">Дата начала</td>
          <td class="table-cell table-title-cell">Дата окончания</td>
          <td class="table-cell table-title-cell">Сумма</td>
          <td class="table-cell table-title-cell">Дата покупки</td>
          <td class="table-cell table-title-cell">Посещения</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let subs of Model' [ngClass]="{'backgroundSuspend': subs.status == 'Suspended'}">
          <td class="table-cell" (click)="showExtension($event, subs)">
            <span class="thead">Название:</span>
            <ng-container *ngIf="!subs.suspendDate; else suspend">
              {{subs.name}}
            </ng-container>
            <ng-template #suspend>
              <ng-container *ngIf="subs.status == 'Suspended'">
                {{subs.name}}<br> Заморожено до {{subs.reactivateDate}}
                <svg class="suspend" width="19" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m4.582 1 4.445 5.333L13.47 1m0 16-4.444-5.333L4.583 17M17.054 4.604l-5.36 4.412L17 13.493m-16-.097 5.36-4.412-5.306-4.477" stroke="#01A4E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </ng-container>
              <ng-container *ngIf="subs.status == 'Active' && subs.suspendDate && subs.reactivateDate">
                {{subs.name}}<br> Будет заморожен с {{subs.suspendDate}}
              </ng-container>
            </ng-template>
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата начала:</span>{{subs.fromDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата окончания:</span>{{subs.toDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Сумма:</span>
            <span *ngIf="subs.paymentSum; else noSum">{{subs.paymentSum}}</span>
            <ng-template #noSum>
              <div class="desc field">
                <span class="gray ">[не&nbsp;указано]</span>
              </div>
            </ng-template>
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Оплачено:</span>{{subs.purchasedAtLocal}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Посещения:</span>{{subs.visitCounter}}/{{subs.visitCount < 10000 ? subs.visitCount : '∞'}}</td>
          <td class="table-cell" style="text-align: end">
            <button class="btn btn-special" (click)="showExtension($event, subs)">Изменить</button>
            <button class="btn btn-special" (click)="showSurcharge($event, subs)" style="margin-left: 5px">Доплата</button>
            <button class="btn btn-special" (click)="showQuickRecord($event, subs)" style="margin-left: 5px"
                    [disabled]="subs.visitCount < 10000 && subs.visitCounter >= subs.visitCount">Списать
            </button>
            <button class="btn btn-special bnt-suspend" *ngIf="subs.status != 'Suspended' && !(subs.suspendDate && subs.reactivateDate)" (click)="showSuspend($event, subs)" style="margin-left: 5px">Заморозить</button>
            <button class="btn btn-special bnt-suspend" *ngIf="subs.status == 'Suspended' || (subs.status == 'Active' && subs.suspendDate && subs.reactivateDate)" (click)="unfreeze($event, subs)" style="margin-left: 5px">Разморозить</button>
            <button class="btn btn-special btn-remove" (click)="cancel($event, subs.id)" *ngIf="isEditAvailable" style="margin-left: 5px">В архив</button>
          </td>
<!--          <td class="table-cell">-->
<!--            <button class="btn btn-clear active" (click)="cancel($event, subs.id)" *ngIf="isEditAvailable">-->
<!--            <span class="icon-cross icon-cross_color">-->
<!--              <span class="icon-cross__left"></span>-->
<!--              <span class="icon-cross__right"></span>-->
<!--            </span>-->
<!--            </button>-->
<!--          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngSwitchCase="1" [@enterTrigger]="enterTriggerState">
    <div class="listEmpty" *ngIf="ModelArchive && ModelArchive.length == 0">
      <span>Список пуст</span>
    </div>
    <div class="table-responsive" *ngIf="ModelArchive && ModelArchive.length">
      <table class="table rowHover">
        <thead>
        <tr>
          <td class="table-cell table-title-cell">Название</td>
          <td class="table-cell table-title-cell">Дата начала</td>
          <td class="table-cell table-title-cell">Дата окончания</td>
          <td class="table-cell table-title-cell">Сумма</td>
          <td class="table-cell table-title-cell">Оплачено</td>
          <td class="table-cell table-title-cell">Посещения</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let subs of ModelArchive'>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Название:</span>
            {{subs.name}}
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата начала:</span>{{subs.fromDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата окончания:</span>{{subs.toDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Сумма:</span>
            <span *ngIf="subs.paymentSum; else noSum">{{subs.paymentSum}}</span>
            <ng-template #noSum>
              <div class="desc field">
                <span class="gray ">[не&nbsp;указано]</span>
              </div>
            </ng-template>
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Оплачено:</span>{{subs.purchasedAtLocal}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Посещения:</span>{{subs.visitCounter}}/{{subs.visitCount}}</td>
          <td class="table-cell" style="text-align: end">
            <button class="btn btn-special btn-archive" (click)="returnFromArchive($event, subs)" *ngIf="isEditAvailable">Вернуть из архива</button>
            <button class="btn btn-special btn-remove" (click)="deletedSub($event, subs)" style="margin-left: 5px" *ngIf="isEditAvailable">Удалить абонемент</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
