import { Input, Directive } from '@angular/core';
import { IImage } from 'app/common/models/image.model';
import { IMedias } from 'app/common/models/medias.model';

@Directive()
export class GalleryComponentCommon {
  @Input() data: IMedias;
  public tempData: IMedias;
  fileInput: any;

  _addPhoto($e) {
    const fileList: FileList = $e.target.files;

    Array.from((<FileList>$e.target.files)).forEach((item, index) => {
      const file = fileList[index];
      item.convertToBase64(res => {
        const image: IImage = {
          id: null,
          url: null,
          body: res,
          filename: file.name,
          isDefault: this.tempData.images.length === 0,
          file: file
        };

        this.tempData.images.push(image);
      });
    });
    this.fileInput.nativeElement.value = '';
  }

  addClick() {
    const event = new MouseEvent('click', { bubbles: true });
    this.fileInput.nativeElement.dispatchEvent(event);
  }

  _setDefaultImage(item: IImage) {
    const index = this.tempData.images.indexOf(item);
    if (index === -1) {
      return;
    } else {
      this.tempData.images.forEach(i => i.isDefault = false);
      setTimeout(() => this.tempData.images[index].isDefault = true, 1);
    }
  }

  _deleteImage(item: IImage) {
    const index = this.tempData.images.indexOf(item);

    if (item.isDefault == true && this.tempData.images.length > 1)
      this.tempData.images.find(m => m != item).isDefault = true;

    if (index === -1) {
      return;
    } else {
      setTimeout(() => this.tempData.images.splice(index, 1));
    }
  }

  isEmpty() {
    return this.tempData.images.length === 0;
  }
}
