<div class="dropdown" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="dropdown-title" [ngClass]="!isEmpty ? 'select-filter' : 'no-select-filter'" [class.active]="(controlState == 'active')" (click)="changeState()" *ngIf="!mat_select" style="{{style}}" #dropdown_title>
    {{title}}
    <i class="sp sp-arrow-small"></i>
  </div>
  <div class="dropdown-options" [@shrinkOut]="controlState" *ngIf="!mat_select; else nativeSelect">
    <div class="dropdown-option">
      <input type="checkbox" [id]="idPrefix + 'all'" disabled [checked]="isEmpty">
      <label (click)="checkAll()" [for]="idPrefix + 'all'">Все {{alias | lowercase}}</label>
    </div>
    <ng-container *ngFor="let item of items; let i = index">
      <div class="dropdown-option" *ngIf="item">
        <input type="checkbox" [(ngModel)]="item.isEnabled" (ngModelChange)="onChecked()" [id]="idPrefix + i">
        <label [for]="idPrefix + i">{{item.name}}
          <i class="fa fa-check" aria-hidden="true"></i>
        </label>
      </div>
    </ng-container>
  </div>

  <ng-template #nativeSelect>
    <div class="auto-width">
      <mat-icon class="clear-filter" *ngIf="!isEmpty" (click)="checkAll()">clear</mat-icon>
      <mat-form-field appearance="fill" [ngClass]="!isEmpty ? 'select-filter' : 'no-select-filter'">
        <mat-label *ngIf="title === alias">{{title}}</mat-label>
        <mat-select [(ngModel)]="enabledItems" (ngModelChange)="selectChange(enabledItems)" multiple >
          <!-- <mat-option (click)="checkAll()" disabled >Все {{alias | lowercase}}</mat-option> -->
          <mat-option *ngFor="let item of items" [value]="item.name"> {{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>
</div>
