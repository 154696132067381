import { ClubSetting } from './../../common/models/club-setting.model';
import { Component, OnInit, OnDestroy, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { IClientSubscriptionPlan } from 'app/common/models/subscription.model';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { ClubSettingService } from 'app/components/club-settings/club-settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { numberValidator } from 'app/common/validators/number.validator';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {ContextService} from '../../common/services/context.service';
import {IRoutingParams} from '../../common/models/context.model';

@Component({
  selector: 'app-extension-subscription',
  templateUrl: './extension-subscription.component.html',
  styleUrls: ['./extension-subscription.component.scss'],
  providers: [ClientsService]
})

export class ExtensionSubscriptionComponent implements OnInit, OnDestroy {
  public static readonly componentName: string = 'ExtensionSubscriptionComponent';

  public extension: IClientSubscriptionPlan;
  protected ngUnsubscribe: Subject<void> = new Subject();
  public formsExtension: FormGroup = null;
  public isValid: boolean;
  public validationStatus: string;
  @Input() param: IClientSubscriptionPlan;
  public control: FormControl;
  public abonementHistory: any = [];
  public routingParams: IRoutingParams;
  public userInfo: any;
  public clubSettings: ClubSetting[];
  public isChangePurchaseSubscrDate: boolean = false;
  public isPickDateTo: boolean = false;
  public isPickDateFrom: boolean = false;
  public isMoreDateFrom: boolean = false;

  fieldMessages = {
    fromDate: {
      dateValidator: 'Введите в формате дд.мм.гггг',
      checkFromDate: 'Необходимо указать дату начала'
    },
    toDate: {
      dateValidator: 'Введите в формате дд.мм.гггг',
      checkToDate: 'Необходимо указать дату, до которой будет действовать абонемент'
    },
    dateBuy: {
      dateValidator: 'Введите в формате дд.мм.гггг чч:мм'
    },
    visit: {
      numberValidator: 'Это поле должно быть числом от 1 до 100000'
    }
  }
  constructor(
    public service: ClientsService,
    public _location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private contextService: ContextService,
    private settingsService: ClubSettingService,
    public router: Router
  ) {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  Back(): void {
    this._location.back();
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(e) {
    if (e.keyCode === 27) {
      this._location.back();
    }
  }

  buildForm() {
    this.formsExtension = this.formBuilder.group ({
      fromDate: [this.fromDate, [Validators.required]],
      toDate: [this.extension.toDate, [Validators.required]],
      purchasedAt: [this.extension.purchasedAt, [Validators.required]],
      visitCount: [this.extension.visitCount, [Validators.required, numberValidator(1, 100000)]]
    });
  }

  private get fromDate(): string { return this.extension.fromDate; }
  private set fromDate(val: string) {
    if (this.extension.fromDate == val)
      return;
    console.log('prolong', val);
    let diff = moment(val, "DD.MM.YYYY").diff(moment(this.extension.fromDate, "DD.MM.YYYY"));
    this.extension.fromDate = val;
    if (!Number.isNaN(diff))
      this.extension.toDate = moment(this.extension.toDate, "DD.MM.YYYY").add(diff).format("DD.MM.YYYY");
  }

  async ngOnInit () {
    this.route.queryParams.subscribe(params => {
      const abonementId: string = params['subsId'];
      this.service.getClientAbonement(abonementId)
      .then(res => {
        this.extension = res;
        this.service.history(this.extension.clientId).then(async histories => {
          await histories.history.forEach(history => {
            if (history.subscription != null) {
              if (history.subscription.id === abonementId) {
                this.abonementHistory.push(history);
              }
            }
          });
        });
        this.userInfo = res.userCreated;
        this.buildForm();
      });
    });

    this.clubSettings = await this.settingsService.getClubSettingsByClub();
    const alias = this.clubSettings.find(s => s.alias === 'IsSubscriptionPurchaseDateChangeable');
    this.isChangePurchaseSubscrDate = alias.value === 'true';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formsExtension.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  checkDate() {
    if (this.extension.toDate && this.extension.fromDate) {
      this.isPickDateFrom = false;
      this.isPickDateTo = false;
      const toDate = moment(this.extension.toDate, "DD/MM/YYYY");
      const fromDate = moment(this.extension.fromDate, "DD/MM/YYYY");
      if (toDate < fromDate) {
        this.isMoreDateFrom = true;
        return true
      }
      this.isMoreDateFrom = false;
      return false;
    }
    if (this.extension.fromDate) {
      this.isPickDateFrom = true;
      return true;
    }
    if (this.extension.toDate) {
      this.isPickDateTo = true;
      return true;
    }
  }

  onSubmit() {
    const controls = this.formsExtension.controls;
    if (this.formsExtension.invalid) {
      Object.keys(controls)
      .forEach(controlName => controls[controlName].markAsTouched());
      return;
    }
    this.service.prolong(this.extension);
    if (this.formsExtension.valid) {
      this._location.back();
    }
  }

  goToClubSchedule(e: MouseEvent, id: string, date: string, visitId: string) {
    e.preventDefault();
    if (id === '00000000000000000000000000000000') {
      return this.router.navigate([`../visit/${visitId}`], { relativeTo: this.route});
    }
    const url2 = '/' + this.contextService.makeContextUrl(`clubschedule/${moment.utc(date).format('DD.MM.YYYY')}/${id}`);
    this.router.navigate([url2]);
  }
}

