import { Route } from '@angular/router';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { ClubMFImportComponent } from 'app/components/club-mf-import/mf-import.component';


export const clubMFImportRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubmfimport',
  component: ClubMFImportComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Импорт' }
};
