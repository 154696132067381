import { Component, OnInit } from '@angular/core';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {ContextService} from '../../common/services/context.service';
import {IRoutingParams} from '../../common/models/context.model';
import {ElectronicQueue} from '../../common/models/electronic-queue.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {ScheduleService} from '../schedule/services/schedule.service';
import {AlertsService} from '../../common/components/alerts/services/alerts.service';
import * as moment from 'moment';
import {QueueHubService} from './services/queue.hub.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-electronic-queue',
  templateUrl: './electronic-queue.component.html',
  styleUrls: ['./electronic-queue.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, height: '0px' }))
      ])
    ])
  ],
})
export class ElectronicQueueComponent implements OnInit {
  public static readonly componentName: string = 'ElectronicQueueComponent';
  protected ngUnsubscribe: Subject<void> = new Subject();
  private timeout = 300000; // Через сколько ms обновить список
  private minutes = 20; // За сколько минут до начала занятие показывать очередь
  private clubsCtx: any[] = [];
  public context: IRoutingParams;
  public electronicQueues: ElectronicQueue[] = [];
  public electronicQueuesFilter: ElectronicQueue[] = [];
  public isLoading = false;

  constructor(
    private contextService: ContextService,
    public router: Router,
    public route: ActivatedRoute,
    public _location: Location,
    private apiV2: ApiV2Service,
    public alertsService: AlertsService,
    protected scheduleService: ScheduleService,
    private queueHubService: QueueHubService
  ) { }

  private async getElectronicQueue() {
    await this.apiV2.electronicQueue.getByClubId(this.context.clubId).then(async (electronicQueues) => {
      this.electronicQueues = electronicQueues;
      this.electronicQueues.forEach(queue => {
        if (queue.visitStatus?.startsWith('cancelled')) { queue.isCanceled = true; }
        if (queue.visitStatus?.startsWith('missed')) { queue.isMissed = true; }
      });
      this.setElectronicQueuesFilter();
      this.isLoading = true;
    });
  }

  private setElectronicQueuesFilter() {
    const _temp = this.electronicQueues.filter(this.comparer(this.electronicQueuesFilter));
    const timeZone = this.clubsCtx.filter(x => x.id === this.context.clubId)[0].timeZone;
    for (const t of _temp) {
      console.log(moment().utc().add(180, 'minutes').format('YYYY-MM-DD HH:mm'))
      if (Number(moment(t.startTime).utc().format('YYYYMMDDHHmm')) <=
        Number(moment().utc().add(timeZone, 'minutes').add(this.minutes, 'minutes')
          .format('YYYYMMDDHHmm'))) {
        this.electronicQueuesFilter.push(t);
      }
    }
  }

  private comparer(otherArray) {
    return (current) => {
      return otherArray.filter(function(other) {
        return other.id === current.id
      }).length === 0;
    }
  }

  private startSignalRListener() {
    this.queueHubService.queueUpdateEvent
      .subscribe((res: any) => setTimeout(async () => {
        const _clubId = res;
        if (this.contextService.getRoutingParams().clubId === _clubId) {
          await this.getElectronicQueue();
        }
      }, 1000));
  }

  private removeItem(queue: any) {
    this.electronicQueues = this.electronicQueues.filter(x => x.id !== queue.id);
    this.electronicQueuesFilter = this.electronicQueuesFilter.filter(x => x.id !== queue.id);
  }

  private checkTime() {
    this.getElectronicQueue();
    setTimeout(() => { this.checkTime(); }, this.timeout);
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.contextService.getRoutingParams();
    this.queueHubService.start(this.contextService.getRoutingParams().clubId);
    await this.contextService.contextInfo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ctx => {
        this.clubsCtx = ctx.clubs;
        this.startSignalRListener();
        await this.getElectronicQueue();
        this.checkTime();
      });
  }

  goToClient(queue: ElectronicQueue) {
    const url = this.contextService.makeContextUrl(`clubclients/${queue.clientId}`);
    this.router.navigate([url]);
  }

  close() { this._location.back(); }

  async reload(): Promise<any> { await this.getElectronicQueue(); }

  cancelBooking(queue) {
    this.scheduleService.cancelBooking({ classId: queue.classId, id: queue.classVisitId })
      .then(async () => {
        await this.getElectronicQueue();
        this.removeItem(queue);
      });
  }

  confirmVisited(queue) {
    this.scheduleService.confirmVisited({ classId: queue.classId, id: queue.classVisitId })
      .then(async () => {
        await this.getElectronicQueue();
        this.removeItem(queue);
      });
  }

  confirmMissed(queue) {
    this.scheduleService.confirmMissed({ classId: queue.classId, id: queue.classVisitId })
      .then(async () => {
        await this.getElectronicQueue();
        this.removeItem(queue);
        this.apiV2.classRequest.getByClub(this.route.snapshot.paramMap.get('clubId'), false).then(reqs => {
          const reqId = reqs.find(m => m.classVisitId === queue.clientId)?.id;
          if (reqId) {
            // Удаляем новую заявку с рабочего стола. т.к. она уже подтверждена клубом
            this.apiV2.classRequest.delete(reqId, false, false);
          }
        });
      });
  }
}
