<div *ngIf="options">
  <div [hidden]="isVisible">
    <ul class="category-list">
      <li *ngFor="let option of data" >
        {{getFullModel(option) | obj2string}}
      </li>

      <li *ngIf="data.length == 0" class="field">
        [не указано]
      </li>
    </ul>
    <a class="toggle" (click)="isVisible=true">Редактировать</a> 
    <a class="toggle" (click)="chooseAll()">Выбрать все</a>
  </div>

  <div [hidden]="!isVisible">
    <div class="form-group">
      <a class="toggle" (click)="isVisible=false">Закрыть</a>
    </div>
    <ul class="checkbox-list">
      <li class="checkbox-list__item" *ngFor="let option of options">
        <input type="checkbox"
               [id]="'mc'+id + '_' +option.id"
               [name]="'mc'+id + '_' +option.id"
               [checked]="isChecked(option)"
               (change)="onChange($event, option)">
        <label [for]="'mc'+id + '_' +option.id" class="check-box">
          <span class="check-box__desc">{{getFullModel(option) | obj2string}}</span>
        </label>
      </li>
    </ul>
  </div>
</div>
