<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default" class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">Очередь на выдачу/прием ресурсов</span>
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="isLoading">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary btn-small" style="float: right; width: 200px;" (click)="reload()">
          <span class="btn-primary__desc">Обновить</span>
        </button>
      </div>
    </div>
    <mat-tab-group>
      <mat-tab label="Выдача">
        <div class="col-12" *ngFor="let item of getIssue()">
          <mat-card class="mat-card-resourcequeue">
            <mat-card-title>{{item.client.firstName}} {{item.client.lastName}}</mat-card-title>
            <mat-card-subtitle>{{item.client.email}} {{item.client.phone}}</mat-card-subtitle>
            <mat-card-content>
              <mat-selection-list [(ngModel)]="item.selected">
                <mat-list-option *ngFor="let sub of getSubCreated(item.clientSubscriptions)" [value]="sub.id">
                  {{sub.name}}
                </mat-list-option>
              </mat-selection-list>
              <div *ngIf="getSubCreated(item.clientSubscriptions)?.length == 0">Нет подходящих абонементов.</div>
            </mat-card-content>
            <mat-card-actions>
              <div class="table-cell table-cell_action table-cell_publish">
                <button class="btn btn-clear active class-requests_btn" (click)="setIssue(item)">
                  <span class="icon-check">
                    <span class="icon-check__left"></span>
                    <span class="icon-check__right"></span>
                  </span>
                </button>
                <span class="mobile__label">Выдать</span>
                <button class="btn btn-clear active class-requests_btn" (click)="removeRequest(item)">
                  <span class="icon-cross">
                    <span class="icon-cross__left"></span>
                    <span class="icon-cross__right"></span>
                  </span>
                </button>
                <span class="mobile__label">Удалить</span>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Возврат">
        <div class="col-12 col-md-4" *ngFor="let item of getReturn()">
          <mat-card class="mat-card-resourcequeue">
            <mat-card-title>{{item.client.firstName}} {{item.client.lastName}}</mat-card-title>
            <mat-card-subtitle>{{item.client.email}} {{item.client.phone}}</mat-card-subtitle>
            <mat-card-content>
              <mat-selection-list [(ngModel)]="item.selected">
                <mat-list-option *ngFor="let sub of getSubActive(item.clientSubscriptions)" [value]="sub.id">
                  {{sub.name}} ({{dateFormat(sub.toDate)}})
                </mat-list-option>
              </mat-selection-list>
            </mat-card-content>
            <mat-card-actions>
              <div class="table-cell table-cell_action table-cell_publish">
                <button class="btn btn-clear active class-requests_btn" (click)="setReturn(item, false)">
                  <span class="icon-check">
                    <span class="icon-check__left"></span>
                    <span class="icon-check__right"></span>
                  </span>
                </button>
                <span class="mobile__label">Вернуть</span>
                <button class="btn btn-clear active class-requests_btn" (click)="removeRequest(item)">
                  <span class="icon-cross">
                    <span class="icon-cross__left"></span>
                    <span class="icon-cross__right"></span>
                  </span>
                </button>
                <span class="mobile__label">Удалить</span>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Выданные">
        <div class="col-12" *ngFor="let item of issued">
          <mat-card class="mat-card-resourcequeue">
            <mat-card-title>{{item.client.firstName}} {{item.client.lastName}}</mat-card-title>
            <mat-card-subtitle>{{item.client.email}} {{item.client.phone}}</mat-card-subtitle>
            <mat-card-content>
              <mat-selection-list>
                <mat-list *ngFor="let sub of getSubActive(item.clientSubscriptions)" [class.miss]="isMiss(sub.toDate)">
                  {{sub.name}} ({{dateFormat(sub.toDate)}})
                </mat-list>
              </mat-selection-list>
            </mat-card-content>
            <mat-card-actions>
              <div class="table-cell table-cell_action table-cell_publish">
                <button class="btn btn-clear active class-requests_btn" (click)="setReturn(item, true)">
                  <span class="icon-check">
                    <span class="icon-check__left"></span>
                    <span class="icon-check__right"></span>
                  </span>
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
