import { Pipe, PipeTransform } from '@angular/core';
import { ICityModel } from 'app/common/models/city-model';


@Pipe({ name: 'cityname' })
export class CityNamePipe implements PipeTransform {
    transform(value: { cityId: string }, cities: ICityModel[]) {
        const city = (cities || []).find(x => x.id === value.cityId);
        if (city) {
            return `<div class='field desc'><span>${city.name}</span></div>`
        } else {
            return `<div class='field desc'><span class="gray ">[не&nbsp;указано]</span></div>`
        }
    }
}
