import { aliasTransformFactory } from "@angular/compiler-cli/src/ngtsc/transform";
import { Injectable, Injector } from "@angular/core";
import { HomeComponent } from "app/components/home/home.component";
import { isAvailableKey, isEditAvailableKey, isRemoveAvailableKey, PermissionKey } from "../models/permission-key.model";
import { UserRole } from "../models/roles.model";
import { IUserInfo } from "../models/user-info.model";
import { AuthService } from "./auth.service";

@Injectable()
export class PermissionService {

  private authService: AuthService;
  private userInfo: IUserInfo;
  constructor (injector: Injector) {
    this.authService = injector.get(AuthService);
    this.authService.userInfo.subscribe(ui => this.userInfo = ui);
  }

  public getPermissions() {
    return Permissions;
  }

  public isAvailable(component: any, key: PermissionKey, role: string = undefined, isSysRolesGranted = false): boolean | undefined {
    if (isSysRolesGranted && SystemRoles.includes((role ?? this.userInfo?.role) as UserRole)) return true;
    const className = component?.constructor?.componentName as string;
    const perm = Permissions[className] ?? Permissions['*'];
    const roles = perm ? perm[key] : undefined;
    return roles ? roles.includes(role ?? this.userInfo?.role) : undefined;
  }

  public isRouteAvailable(component: any): boolean {
    const className = component?.componentName as string;
    if (component === HomeComponent) return true;
    const perm = Permissions[className] ?? Permissions['*'];
    const roles = perm ? perm[isAvailableKey] : undefined;
    return roles ? roles.includes(this.userInfo?.role) : false;
  }
}

const SystemRoles = [ UserRole.Admin, UserRole.Manager ];
const ClubRoles = [ UserRole.ClubNetAdmin, UserRole.ClubAdmin, UserRole.ClubManager, UserRole.ClubCoach ];
const ClubRolesEdit = [ UserRole.ClubNetAdmin, UserRole.ClubAdmin ];
const ClubRolesMain = [ UserRole.ClubNetAdmin, UserRole.ClubAdmin, UserRole.ClubManager ];
const ClubRolesClubEdit = [ UserRole.ClubNetAdmin, UserRole.ClubAdmin, UserRole.ClubManager ];

const Permissions: PermissionsStorage = {

  '*': {
    [isAvailableKey]: [ ...SystemRoles ],
    [isEditAvailableKey]: [ ...SystemRoles ],
    [isRemoveAvailableKey]: [ ...SystemRoles ],
  },

  ['ClubEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_name']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_clubNet']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_workingTime']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_description']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_descriptionExtented']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_note']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_geo']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_medias']: [ ...SystemRoles, ...ClubRolesClubEdit ],
  },

  ['LegalInfoComponent']: {
    ['clubEdit_legalInfo_inn']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_legalInfo_legalName']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_legalInfo_bikBank']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_legalInfo_nameBank']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_legalInfo_currentAccountNumber']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_legalInfo_numberBankCorrespondentAccount']: [ ...SystemRoles, ...ClubRolesClubEdit ],
    ['clubEdit_legalInfo_contractBasisPayment']: [ ...SystemRoles ],
    ['clubEdit_legalInfo_documentLink']: [ ...SystemRoles ],
  },

  ['ClubProfileComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_isClubChatRequestDenied']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_isBookingScheduleAllowed']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_settingsBlock']: [ ...SystemRoles, ...ClubRolesEdit ],

    ['clubProfile_actualScheduleDate']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_signingTreatyDate']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_responsibleToRelevanceInfoUserId']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_responsibleToWorkWithClientUserId']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_currentTariff']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_currentTariffSbp']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfile_paymentCardBonusPercent']: [ ...SystemRoles, ...ClubRolesEdit ],
  },

  ['ClubSettingsComponent']: {
    ['clubProfileSettings_requiredEmailNumberForRegistration']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_disableSignUpWithoutSubscription']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_requiredFullNameForRegistration']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_showTextAboutTrainer']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_disableConfirmSignUpWithoutSubscription']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_requiredPhoneNumberForRegistration']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_IsSubscriptionPurchaseDateChangeable']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_IsHiddenImTheClubButton']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_IsSetSubscriptionWhenBookFromCrm']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_IsSetSubscriptionForGroupAutoCreatedVisits']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_NumberMinutesBeforeStartCannotCancelBooking']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_isNotVisibleTabsSpPrice']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_isNotVisibleTabsSpCourse']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_isNotVisibleTabsSpSchedule']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_isNotVisibleTabsSpCoach']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_NumberMinutesBeforeStartCannotBooking']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_ClassStartDateTooLatePeriodMinutes']: [ ...SystemRoles, ...ClubRolesEdit ],
    ['clubProfileSettings_IsYouCanQueue']: [ ...SystemRoles, ...ClubRolesEdit ], // Queue
    ['clubProfileSettings_IsAutomaticBookingQueue']: [ ...SystemRoles, ...ClubRolesEdit ], // Queue
  },

  ['EditImageComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },

  ['DashboardComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['SettingsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles, UserRole.ClubCoachAssistant ],
  },
  ['ScheduleComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles, UserRole.ClubCoachAssistant ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    ['isBtnAccountingAvailable']: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['CertificatesComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['CompactScheduleModeComponent']: {
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ClassItemEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles, UserRole.ClubCoachAssistant ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    ['isClassVisitClientAvailable']: [ ...SystemRoles, ...ClubRoles ],
  },
  ['ClientsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
    ['isExcelExportAvailable']: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ClientsEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    ['isSubscriptionAddAvailable']: [ ...SystemRoles, ...ClubRolesMain ],
    ['isRequestChatAvailable']: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['AbonementListComponent']: {
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['SubscriptionSaleComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ExtensionSubscriptionComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['SurchargeComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['QuickRecordComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
  },
  ['SuspendComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['CoursesComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['CourseEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['GroupsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['GroupEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['WidgetsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles ],
  },
  ['WidgetEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles ],
  },
  ['ThemeEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles ],
    [isEditAvailableKey]: [ ...SystemRoles ],
  },
  ['UsersComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['UserEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ClubEventsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ClubEventEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['CoachesComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['CoachEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    ['isCoachRatesAvailable']: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ClubroomsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ClubroomEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['SubscriptionsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['SubscriptionEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ChatsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ChatComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ChatEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ChatMailingComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['FinanceComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['FinanceRegistryElementsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ClubAnalyticsComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ClubAnalyticsDetailComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['AccountingComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['SupportComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles, UserRole.ClubCoachAssistant ],
  },
  ['AddClientToClassComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['VisitEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ElectronicQueueComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['ClubDownTimeComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRoles ],
  },
  ['CoachSalaryCalcComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['CoachRegistryEditComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['CoachRegistryCreateComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
  ['ResourceQueueComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['HeaderComponent']: {
    ['isHeaderSearchBarAvailable']: [ ...SystemRoles, ...ClubRolesMain ],
  },
  ['WebhookComponent']: {
    [isAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isEditAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
    [isRemoveAvailableKey]: [ ...SystemRoles, ...ClubRolesEdit ],
  },
};

type PermissionsStorage = {
  [component: string]: {
    [key: string]: string[];
  }
};
