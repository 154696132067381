export class RentMatrix {
  dayWeek: string;
  time: RentTime[] = [];
}

export class RentTime {
  time: string;
  subId: string;
  constructor(_time: string, _subId: string) {
    this.time = _time;
    this.subId = _subId;
  }
}
