import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiV2Service} from './api-v2.service';
import {AuthService} from './auth.service';
import {Title} from '@angular/platform-browser';

@Injectable()
export class TxtService {
  private field = [
    'СекцияДокумент',
    'Номер',
    'Дата',
    'Сумма',
    'ПлательщикСчет',
    'Плательщик',
    'ПлательщикИНН',
    'Плательщик1',
    'ПлательщикРасчСчет',
    'ПлательщикБанк1',
    'ПлательщикБанк2',
    'ПлательщикБИК',
    'ПлательщикКорсчет',
    'ПолучательСчет',
    'Получатель',
    'ПолучательИНН',
    'Получатель1',
    'ПолучательРасчСчет',
    'ПолучательБанк1',
    'ПолучательБИК',
    'ПолучательКорсчет',
    'ВидОплаты',
    'Очередность',
    'НазначениеПлатежа'
  ];

  constructor(
    private apiV2Service: ApiV2Service
  ) { }

  public downloadFileApi(data): any {
    const req = [];
    for (const _d of data) {
      req.push({
        PrefixFileName: _d?.PrefixFileName,
        BankName: _d?.BankName,
        ClubName: _d?.ClubName,
        Date: _d?.Date,
        Text: this.convertToTxt(_d?.Details, this.field)
      })
    }
    const filename = req.length > 1 ? `${req[0].PrefixFileName} от ${req[0].Date}.zip` : `${req[0].PrefixFileName} ${req[0].BankName} ${req[0].ClubName} от ${req[0].Date}.txt`;
    this.apiV2Service.registry.registryPaymentOrder({ Files: req }).then(res => {
      this.downloadBlobFile(res['_body'], this.sanitizeFilename(filename));
    });
  }

  public sanitizeFilename(filename: string): string {
    // Регулярное выражение для разрешенных символов
    const allowedCharacters = /[^a-zA-Zа-яА-Я0-9,._()\- ]/g;
    // Замена всех неразрешенных символов на пустую строку
    return filename.replace(allowedCharacters, '');
  }

  private convertToTxt(objArray, headerList): any {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    str += '1CClientBankExchange\r\n';
    str += 'ВерсияФормата=1.02\r\n';
    str += 'Кодировка=Windows\r\n'
    for (const index in headerList) {
      if (objArray[0][headerList[index]]) {
        str += headerList[index] + '=';
        str += objArray[0][headerList[index]];
        str += '\r\n';
      }
    }
    str += 'КонецДокумента\r\n';
    str += 'КонецФайла\r\n';
    return str;
  }

  private downloadBlobFile(blob: Blob, fileName: any) {
    const fileURL = URL.createObjectURL(blob);
    const dwldLink = document.createElement('a');
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // если Safari откроется новое окне, чтобы сохранить файл со случайным именем.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', fileURL);
    dwldLink.setAttribute('download', fileName);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
