import { Component, Injector } from '@angular/core';

import { EditItemComponent } from 'app/common/edit-item.component';
import { ClubsService } from 'app/components/clubs/services/clubs.service';
import { IExtendedClubModel } from 'app/common/models/club.model';
import { ClubNetsService } from 'app/components/clubnets/services/clubnets.service';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { CityService } from 'app/common/services/city.service';
import { ICityModel } from 'app/common/models/city-model';
import { MediaService } from 'app/common/services/media.service';
import { takeUntil, take, filter } from 'rxjs/operators';
import { PermissionKey } from 'app/common/models/permission-key.model';

@Component({
  selector: 'app-club-edit',
  templateUrl: './club-edit.component.html',
  styleUrls: ['./club-edit.component.scss'],
})

export class ClubEditComponent extends EditItemComponent<IExtendedClubModel>  {
  public static readonly componentName: string = 'ClubEditComponent';

  public configDetailedDescription = {
    toolbar: [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
      { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      { name: 'others', items: [ '-' ] },
      { name: 'about', items: [ 'About' ] }
    ]
  };

  fieldMessages = {
    name: {
      NotEmpty: 'Необходимо указать название клуба',
    }
  };

  constructor(
    injector: Injector,
    protected mediaService: MediaService,
    protected service: ClubsService,
    private clubNetsService: ClubNetsService,
    private cityService: CityService
  ) {
    super(injector, service);
  }

  public finishEditIfPublished() {
    this.Model.id && this.finishEdit();
    if (this.Model.name !== null) {
      this.Model.name = this.Model.name.replace(/\r?\n/g, ' ').trim()
    }
  }

  public modelTemplate(): Promise<IExtendedClubModel> {
    return new Promise(resolve => {
      this.cityService.currentCity.pipe(
        takeUntil(this.ngUnsubscribe),
        filter<ICityModel>(Boolean),
        take(1))
        .subscribe(city => {
          this.clubNetsService.getItemList(city)
            .then(list => {
              const template: IExtendedClubModel = {
                id: null,
                cityId: city.id,
                entityStatus: EntityStatus.draft,
                name: null,
                urlName: '',
                geoLat: null,
                geoLon: null,
                isApproved: false,
                isShortViewOnly: false,
                rate: null,
                workingTime: {
                  mon: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                  tue: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                  wed: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                  thu: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                  fri: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                  sat: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                  sun: { 'hourFrom': '00:00', 'hourTo': '23:59' },
                },
                payByCash: false,
                payByCards: false,
                payByBank: false,
                description: null,
                descriptionExtented: null,
                features: [],
                contacts: {
                  address: null,
                  phones: [],
                  metro: [],
                  pathDescription: '',
                  webSite: ''
                },
                legalInfo: {
                  inn: '',
                  legalName: '',
                  bikBank: '',
                  nameBank: '',
                  currentAccountNumber: '',
                  numberBankCorrespondentAccount: '',
                  contractBasisPayment: '',
                  documentLink: ''
                },
                medias: {
                  images: [],
                  videos: []
                },
                clubNet: list[0],
                isSubscriptionSellAllowed: false,
                isBookingScheduleAllowed: true,
                note: null,
                instagramMarker: null,
                instagramMarkerType: 'hashtag',
                offerRulesClub: null,
                timeZone: null
              };
              resolve(template);
            });
        });
    });
  }

  openImage(event: any) {
    this.mediaService.saveRedirectUrl();
    const url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);
  }

  public close() { }

  public hkSaveAddPress(_that: this) {
    return (event: KeyboardEvent) => {
      // Клубы нельзя сохранять с копированием,
      // поэтому переопределяем метод базового класса чтобы он ничего не делал;
      event.preventDefault();
      return true;
    };
  }

  public hkClosePress(that: this) {
    return () => {
      if (that.Model.id == null) {
        that.router.navigate([`../`], { relativeTo: that.route });
      }
      return true;
    };
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: ['', [NotEmpty()]]
    });
    if (this.Model.name !== null) {
      this.Model.name = this.Model.name.replace(/\r?\n/g, ' ').trim()
    }
  }

  public _isPermit: (alias: string) => boolean = alias => {
    return this.permissionService.isAvailable(this, `clubEdit_${alias}` as PermissionKey, super.userInfo?.role, true);
  }
}
