import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {CollectionsComponent} from '../../components/collections/collections.component';
import {CollectionEditComponent} from '../../components/collections/collection-edit/collection-edit.component';

export const collectionsRoute: Route = {
  component: CollectionsComponent,
  path: ':cityId/collections',
  canActivate: [AuthGuard],
  data: { title: 'Подборки' }
};

export const collectionsEditRoute: Route = {
  path: ':cityId/collections/:id',
  component: CollectionEditComponent,
  canActivate: [AuthGuard],
  data: { title: 'Редактирование подборки' }
};

export const collectionsCreateRoute: Route = {
  path: ':cityId/collections/create',
  component: CollectionEditComponent,
  canActivate: [AuthGuard],
  data: { title: 'Редактирование подборки' }
};
