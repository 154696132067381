import { Route } from '@angular/router';

import { AuthGuard } from 'app/common/services/auth-guard.service';
import { CoachEditComponent } from 'app/components/coaches/coach-edit/coach-edit.component';
import { CoachesComponent } from 'app/components/coaches/coaches.component';

export const coachesRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubcoaches',
  component: CoachesComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full',
  data: { title: 'Тренеры' }
};

export const coachRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubcoaches/:id',
  component: CoachEditComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full',
  data: { title: 'Редактирование тренера' }
};
