import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { AuthService } from 'app/common/services/auth.service';
import { ContextService } from 'app/common/services/context.service';
import { ApiService } from 'app/common/services/api.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { ApiV2Service } from 'app/common/services/api-v2.service';
import { IPagedList } from 'app/common/models/common.models';
import { ICityModel } from 'app/common/models/city-model';

export interface ITableListService {
    getItemList(city?: ICityModel, pageIndex?: number, pageSize?: number, parentId?: string): Promise<Array<any> | IPagedList<any>>;
    getSingleItem(req: IRoutingParams, parentId?: string): Promise<any>;
    delete(model: any);
    save(model: any): Promise<any>;
    create(model: any): Promise<any>;
    setStatus?(status: string, item: any): Promise<any>;
};

@Injectable()
export abstract class TableListService<T extends { id: string }> implements ITableListService {
    public authService: AuthService;
    public contextService: ContextService;
    public apiService: ApiService;
    public apiV2Service: ApiV2Service;
    public routingParams: IRoutingParams;

    constructor(
        injector: Injector
    ) {
        this.authService = injector.get(AuthService);
        this.contextService = injector.get(ContextService);
        this.apiService = injector.get(ApiService);
        this.apiV2Service = injector.get(ApiV2Service);
        this.contextService.routingParams.subscribe(params => {
            this.routingParams = params;
        });
    }

    protected errorHandler(e) {
        throw new Error((`Запрос ${e.url} завершился неуспешно: ${e.status} ${e.statusText}`));
    }

    abstract getItemList(city?: ICityModel, pageIndex?: number, pageSize?: number, parentId?: string);
    abstract getSingleItem(req: IRoutingParams): Promise<T>;
    abstract save(model: T): Promise<any>;
    abstract create(model: T): Promise<any>;
    setStatus?(status: string, item: T): Promise<any>;

    delete(model) {
        model.entityStatus = EntityStatus.deleted;
        return this.save(model);
    }
}


