import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClubDownTime, ClubDownTimeSaveReq} from '../../../common/models/club-down-time.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {Accounting} from '../../../common/models/accounting.model';
import * as moment from 'moment';
import {EntityStatus} from '../../../common/models/entity-status.model';

@Component({
  selector: 'app-club-down-time-element',
  templateUrl: './club-down-time-element.component.html',
  styleUrls: ['./club-down-time-element.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class ClubDownTimeElementComponent implements OnInit {
  @Input() element: ClubDownTime;
  @Input() rooms: any[] = [];
  @Output() resultRemove = new EventEmitter<ClubDownTime>();
  @Output() resultEdit = new EventEmitter<ClubDownTime>();
  public form: FormGroup;
  public IsEdit = false;

  constructor(
    private apiV2: ApiV2Service,
    private alertsService: AlertsService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      start: new FormControl(new Date(this.element.startAt).toISOString().substring(0, 16), [Validators.required]),
      end: new FormControl(new Date(this.element.endAt).toISOString().substring(0, 16), [Validators.required]),
      roomId: new FormControl(this.element.roomId),
      comment: new FormControl(this.element.comment),
    });
  }

  edit() {
    this.IsEdit = true;
  }

  cancel() {
    this.IsEdit = false;
  }

  getRoomInfo(roomId: string): any {
    if (roomId) { return this.rooms.filter(x => x.id === roomId)[0]; }
  }

  changeDate(): void {
    if (this.form.get('start').value > this.form.get('end').value) {
      this.form.get('end').reset();
    }
  }

  remove(element: ClubDownTime) {
    this.alertsService.showConfirm({
      header: 'Удалить время работы клуба?',
      message: '',
      buttons: [{
        title: 'Удалить',
        callback: async () => {
          await this.delete(element);
        }
      }, {
        title: 'Не удалять'
      }]
    });
  }

  private async delete(element: ClubDownTime): Promise<any> {
    await this.apiV2.clubDownTime.remove(element.id).then(async (remove) => {
      if (remove) {
        this.resultRemove.emit(element);
      } else {
        console.error('Ошибка удаление: ', remove);
      }
    });
  }

  async confirmSave(element: ClubDownTime): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    this.alertsService.showConfirm({
      header: 'Что делать с занятиями, которые попадают в указанный период?',
      buttons: [{
        title: 'Отменить',
        callback: () => {
          this.alertsService.showConfirm({
            header: 'Уведомить записанных клиентов об изменении (Push, E-mail)?',
            buttons: [{
              title: 'Уведомлять',
              callback: async () => {
                await this.save(element, { isCancelClasses: true, isClientNotices: true });
              }
            }, {
              title: 'Не&nbsp;уведомлять',
              callback: async () => {
                await this.save(element, { isCancelClasses: true, isClientNotices: false });
              }
            }, {
              title: 'Отменить'
            }]
          });
        }
      }, {
        title: 'Не&nbsp;отменять',
        callback: async () => {
          await this.save(element, { isCancelClasses: false, isClientNotices: false });
        }
      }, {
          title: 'Закрыть'
        }]
    });
  }

  async save(element: ClubDownTime, settings: any): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    if (this.form.valid) {
      await this.apiV2.clubDownTime.save({
        id: element.id,
        entityStatus: EntityStatus.published,
        startAt: moment(this.form.get('start').value).format('YYYY.MM.DD HH:mm'),
        endAt: moment(this.form.get('end').value).format('YYYY.MM.DD HH:mm'),
        clubId: element.clubId,
        roomId: this.form.get('roomId').value ? [this.form.get('roomId').value] : null,
        comment: this.form.get('comment').value,
        isCancelClasses: settings.isCancelClasses,
        isClientNotices: settings.isClientNotices
      }).then(async (e) => {
        if (e) {
          this.resultEdit.emit(e[0]);
          this.IsEdit = false;
          this.alertsService.alert.next({
            type: 'success',
            message: 'Время работы клуба обновлено',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }
}
