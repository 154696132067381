import { Component, OnInit, Injector } from '@angular/core';
import { IOnValueChanged, onValueChanged } from 'app/common/validators/on-value-changed.class';
import { IExtendedClubModel } from 'app/common/models/club.model';
import { ToggleEditComponent } from 'app/common/components/toggle-edit/toggle-edit.component';
import { debounceTime } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-coords-edit',
  templateUrl: './coords-edit.component.html',
  styleUrls: ['./coords-edit.component.scss']
})
export class CoordsEditComponent extends ToggleEditComponent<IExtendedClubModel> implements IOnValueChanged, OnInit {
  fieldMessages = {
    geoLat: {},
    geoLon: {}
  };

  constructor(
    injector: Injector
  ) {
    super(injector)
  }

  onBeginEdit() {
    if (!this.isReadonly) {
      this.tempData = this.modelTransformIn();
      this.enableEdit = true;
    }
  }

  onSave() {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    this.tempData.geoLat = this.form.value.geoLat;
    this.tempData.geoLon = this.form.value.geoLon;
    super.onSave();
  }

  onCancel() {
    super.onCancel();
    this.isSubmited = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.form = this.formBuilder.group({
      geoLat: [this.tempData.geoLat, []],
      geoLon: [this.tempData.geoLon, []]
    });

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith(null))
      .subscribe(() => onValueChanged(this));
  }
}
