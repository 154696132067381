import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Collections, CollectionsTypes} from '../../../common/models/collections.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ICityModel} from '../../../common/models/city-model';
import {CityService} from '../../../common/services/city.service';
import {ClubsService} from '../../clubs/services/clubs.service';
import {EntityStatus} from '../../../common/models/entity-status.model';

@Component({
  selector: 'app-collection-edit',
  templateUrl: './collection-edit.component.html',
  styleUrls: ['./collection-edit.component.scss']
})
export class CollectionEditComponent implements OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject();
  public readonly EntityStatus = EntityStatus;
  paramId = null;
  dataJson: string;
  public collection: Collections;
  form: FormGroup;
  cities: Array<ICityModel> = [];
  clubs: Array<any> = [];
  data: any[] = [];
  result: any[] = [];
  isJsonValid?: boolean = null;
  id = '00000000000000000000000000000000';

  types: any[] = [
    {value: CollectionsTypes.clubs, viewValue: 'Подборка клубов'},
    {value: CollectionsTypes.courses, viewValue: 'Подборка занятий'},
    {value: CollectionsTypes.subscriptions, viewValue: 'Подборка абонементов'},
    {value: CollectionsTypes.conditionSearch, viewValue: 'Результаты поиска - JSON'},
    {value: CollectionsTypes.conditionImages, viewValue: 'Подборка фильтров (с картинкой) - JSON'},
    {value: CollectionsTypes.conditionButtons, viewValue: 'Подборка фильтров (без картинки) - JSON'},
    {value: CollectionsTypes.slide, viewValue: 'Слайдер'},
    {value: CollectionsTypes.buttonCards, viewValue: 'Карусель'}
  ];

  statuses: any[] = [
    {value: EntityStatus.draft, viewValue: 'Черновик'},
    {value: EntityStatus.published, viewValue: 'Опубликовано'}
  ];

  constructor(
    private apiV2: ApiV2Service,
    private router: Router,
    private route: ActivatedRoute,
    private clubsService: ClubsService,
    private cityService: CityService) { }

  async ngOnInit(): Promise<any> {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      partnerAlias: new FormControl(''),
      cityId: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      dataJson: new FormControl(''),
      data: new FormControl('', Validators.required),
      isTitleVisibility: new FormControl(true)
    });
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(async (params: { id: string }) => {
        this.paramId = params.id;
        await this.cityService.getItemList()
          .then(async cities => {
            this.cities = cities
            if (this.paramId !== 'create') {
              await this.apiV2.collections.getById(params.id).then(async resp => {
                this.collection = resp;
                this.id = this.collection.id;
                this.form.get('cityId').setValue(this.collection.cityId);
                this.form.get('isTitleVisibility').setValue(this.collection.isTitleVisibility);
                await this.changeCity();
                this.form.get('name').setValue(this.collection.name);
                this.form.get('partnerAlias').setValue(this.collection.partnerAlias);
                this.form.get('type').setValue(this.collection.type);
                this.form.get('data').setValue(this.collection.data);
                if (this.collection.type === CollectionsTypes.slide || this.collection.type === CollectionsTypes.buttonCards) {
                  this.data = JSON.parse(this.collection.data);
                }
                await this.collectionList();
              });
            } else {
              await this.changeCity();
            }
          });
    });
  }

  public async collectionList(): Promise<any> {
    const Ids = [];
    if (this.paramId !== 'create') {
      if (this.form.get('type').value !== CollectionsTypes.conditionSearch && this.form.get('type').value !== CollectionsTypes.conditionButtons && this.form.get('type').value !== CollectionsTypes.conditionImages) {
        await JSON.parse(this.form.get('data').value).forEach(async i => {
          Ids.push(i.id);
          if (this.form.get('type').value === CollectionsTypes.clubs) {
            const c = this.clubs.filter(x => x.id === i.id)[0]
            if (c)
              this.data.push({ id: c.id, name: c.name });
          }
        });
      }

      if (this.form.get('type').value === CollectionsTypes.subscriptions) {
        Ids.forEach(async s => {
          this.data.push({ id: s, name: s });
        })
      } else if (this.form.get('type').value === CollectionsTypes.courses) {
        await this.apiV2.collections.collectionsGetCourses(Ids).then(resp => {
          resp.forEach(s => {
            this.data.push({ id: s.id, name: s.name });
          })
        })
      } else if (this.form.get('type').value === CollectionsTypes.conditionSearch) {
        this.isJsonValid = true;
        this.form.get('dataJson').setValue(this.form.get('data').value);
      }  else if (this.form.get('type').value === CollectionsTypes.conditionImages) {
        this.isJsonValid = true;
        this.form.get('dataJson').setValue(this.form.get('data').value);
      } else if (this.form.get('type').value === CollectionsTypes.conditionButtons) {
        this.isJsonValid = true;
        this.form.get('dataJson').setValue(this.form.get('data').value);
      }
    }
  }

  public async changeCity(): Promise<any> {
    if (this.form.get('cityId').value !== '') {
      await this.cityService.getSingleItem({id: this.form.get('cityId').value}).then(async city => {
        await this.clubsService.getItemList(city).then(res => {
          this.clubs = res.items;
        })
      });
    }
  }

  public close(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  async save(entityStatus: EntityStatus): Promise<any> {
    if (this.form.valid) {
      let data = this.form.get('data').value;
      if (typeof this.form.get('data').value !== 'string') {
        data = JSON.stringify(this.form.get('data').value)
      }
      this.apiV2.collections.save({
        id: this.id,
        partnerAlias: this.form.get('partnerAlias').value,
        cityId: this.form.get('cityId').value,
        entityStatus: entityStatus,
        type: this.form.get('type').value,
        name: this.form.get('name').value,
        data: data,
        isTitleVisibility: this.form.value.isTitleVisibility
      }).then(resp => {
        if (resp) {
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
    }
  }

  async onCityChange(event: any) {
    this.form.get('cityId').setValue(event.value);
    this.form.get('type').setValue('');
    this.form.get('data').setValue('');
    await this.changeCity();
  }

  async typeChange() {
    this.form.get('data').setValue('');
    this.data = [];
  }

  getCityObj(): any {
    return this.cities.filter(x => x.id === this.form.get('cityId').value)[0];
  }

  setData(event: any[]) {
    this.result = [];
    this.data = event;
    this.data.forEach(item => {
      this.result.push(item);
      // this.result.push({ id: item.id });
    });
    this.form.get('data').setValue(this.result);
  }

  changeDataJson(event: any) {
    try {
      const jsonR = JSON.parse(this.form.get('dataJson').value);
      if (jsonR) {
        this.isJsonValid = true;
        this.form.get('data').setValue(jsonR);
      }
    } catch {
      this.isJsonValid = false;
    }
  }

  isShowFields(types: string[]) {
    if (this.form.get('type').valid) {
      return types.includes(this.form.value.type);
    }
  }
}
