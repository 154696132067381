import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IAnalyticsUser } from 'app/common/models/user-info.model';
import { EntitySearchComponent } from 'app/shared-components/entity-search/entity-search.component';


@Component( {
    selector: 'app-user-search',
    templateUrl: '../entity-search/entity-search.component.html',
    providers: [ {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef( () => UserSearchComponent ),
        multi: true
    }]
} )
export class UserSearchComponent extends EntitySearchComponent<IAnalyticsUser> {

    public placeholder = 'Телефон или ФИО';

    constructor( injector: Injector ) {
        super( injector );
    }

    public niceItemName ( item: IAnalyticsUser ): string {
        return `${ item.firstName } ${ item.lastName }${ item.phoneNumber ? ' (****' + item.phoneNumber + ')' : '' }`;
    }

    public findItems ( query: string ): Promise<IAnalyticsUser[]> {
        return this.api.search.users( query ).then( res => res.users );
    }

}
