import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntitySearchComponent } from 'app/shared-components/entity-search/entity-search.component';
import { IClubModel } from 'app/common/models/club.model';


@Component({
    selector: 'app-club-search',
    templateUrl: '../entity-search/entity-search.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ClubSearchComponent),
        multi: true
    }]
})
export class ClubSearchComponent extends EntitySearchComponent<IClubModel> {

    constructor(injector: Injector) {
        super(injector);
    }

    public niceItemName(item: IClubModel): string {
        return `${item.name}`;
    }

    public findItems(query: string): Promise<IClubModel[]> {
        return this.apiV2Service.search
            .clubs(query, this.currentCity)
            .then(res => res.items);
    }
}
