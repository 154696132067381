<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание вида занятий</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование вида занятий</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Название</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" placeholder="Вид занятий" name="name" id="name" formControlName="name" [ngModel]="Model.name"
              maxlength="500">
            <label for="name" class="field-validation-error" *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Направление</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" maxlength="500" placeholder="Танцы, Кардио, Детский фитнес и т.д." name="group" id="group" formControlName="group"
              [ngModel]="Model.group">
            <label for="group" class="field-validation-error" *ngIf="fieldErrors.group && isSubmited">{{fieldErrors.group}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Порядок сортировки</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="sortOrder" formControlName="sortOrder" [ngModel]="Model.sortOrder" maxlength="500">
            <label for="sortOrder" class="field-validation-error" *ngIf="fieldErrors.sortOrder && isSubmited">{{fieldErrors.sortOrder}}</label>
          </div>
        </div>
      </div>

      <div class="clearfix "></div>
      <div class="clearfix hidden-lg-up"></div>
      <div class="clearfix hidden-md-up"></div>

<!--      <div class="col-xs-12 col-sm-6 col-md-4">-->
<!--        <div class="form-group">-->
<!--          <div class="box-title">Популярно?</div>-->
<!--          <div class="row__field">-->
<!--            <input type="checkbox" [ngModel]="Model.isPopular" formControlName="isPopular" [checked]="Model.isPopular" class="checkbox__slide"-->
<!--              name="isPopular" id="isPopular-{{Model.id}}">-->
<!--            <label for="isPopular-{{Model.id}}" class="checkbox__slide_label"></label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-xs-12">-->
<!--        <div class="form-group">-->
<!--          <div class="box-title">Произвольная запись</div>-->
<!--          <div class="info-text">-->
<!--            <div class="info-text__box">-->
<!--              Для бассейна, тренажерного зала или индивидуального занятия! Клиент может произвольно выбирать время в расписании для своих тренировок.-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row__field">-->
<!--            <input type="checkbox" [(ngModel)]="Model.isVoluntaryBookingAllowed" formControlName="isVoluntaryBookingAllowed" [checked]="Model.isVoluntaryBookingAllowed"-->
<!--              class="checkbox__slide" name="isVoluntaryBookingAllowed" id="isVoluntaryBookingAllowed-{{Model.id}}">-->
<!--            <label for="isVoluntaryBookingAllowed-{{Model.id}}" class="checkbox__slide_label"></label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="clearfix hidden-md-up"></div>

      <div class="col-12 col-xs-12 col-sm-12 col-md-12" *ngIf="Model.isVoluntaryBookingAllowed">
        <div class="form-group">
          <div class="row__field">
            <app-working-time-cva myScrollTo [title]="'Доступное время'" [ngModel]="Model.workingTime" formControlName="workingTime">
            </app-working-time-cva>
          </div>
        </div>
      </div>
      <div class="clearfix hidden-md-up"></div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">Подтверждение заявки на занятие</div>
          <div class="info-text">
            <div class="info-text__box">
              Должны ли заявки попадать сразу в занятие или администратору необходимо их предварительно подтверждать на рабочем столе
            </div>
          </div>
          <div class="row__field">
            <select class="select select__long" ([ngModel])="Model.clubConfirmationMethod" formControlName="clubConfirmationMethod">
              <option value="notRequired">Подтверждение не требуется</option>
              <option value="subscription">Требовать подтверждения при записи без абонемента</option>
              <option value="manual">Любая запись требует подтверждения</option>
            </select>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-up"></div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="box-title">Требовать указывать тренера</div>
          <div class="row__field">
            <input type="checkbox" [ngModel]="Model.isCoachForClassRequired" formControlName="isCoachForClassRequired" [checked]="Model.isCoachForClassRequired"
              class="checkbox__slide" name="isCoachForClassRequired" id="isCoachForClassRequired-{{Model.id}}">
            <label for="isCoachForClassRequired-{{Model.id}}" class="checkbox__slide_label"></label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-up"></div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-xs-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Связанные тренеры</div>
          <div class="row__field">
            <app-multiple-checkbox [options]="clubCoaches" [(ngModel)]="Model.coaches" formControlName="coaches"></app-multiple-checkbox>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-up"></div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-xs-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Подходящие залы</div>
          <div class="clue-text" style="color: #e60000">67% пользователей оценивают атмосферу клуба по фото залов. Заполните раздел "Залы".</div>
          <div class="row__field">
            <app-multiple-checkbox [options]="clubRooms" [(ngModel)]="Model.rooms" formControlName="rooms"></app-multiple-checkbox>
          </div>
        </div>
      </div>

      <div class="col-xs-12" *ngIf="subscriptionPlans && subscriptionPlans.length">
        <div class="form-group" myScrollTo>
          <div class="box-title">Подходящие aбонементы</div>
          <div class="row__field">
            <ul class="category-list">
              <li *ngFor="let plan of subscriptionPlans">
                {{plan.name}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-up"></div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-12 col-xs-12 col-sm-12 col-md-12">
        <div class="form-group">
          <div class="box-title">Виды спорта</div>
          <div class="clue-text" style="color: #e60000">Важная настройка! Ищите вид вашего занятия с помощью "горячих клавиш" CTRL + F</div>
          <div class="row__field">
            <app-category-select [ngModel]="Model.trainingCategories" [options]="trainingCategories" formControlName="trainingCategories"></app-category-select>
            <label for="description" class="field-validation-error" *ngIf="fieldErrors.trainingCategories && isSubmited">{{fieldErrors.trainingCategories}}</label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-up"></div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-xs-12 col-sm-6 col-md-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Краткое описание</div>
          <div class="row__field pd">

            <div class="row__field-tooltip">Самое важное и интересное о занятии</div>

            <textarea [(ngModel)]="Model.shortDescription" class="inputField" formControlName="shortDescription" id="shortDescription"
              maxlength="250"></textarea>

            <label *ngIf="Model.shortDescription && Model.shortDescription.length" for="shortDescription" [style.color]=" ( Model.shortDescription.length < 240 ) ? '#337ab7' : '#d62445' ">
              Осталось символов: {{ 250 - Model.shortDescription.length}}
            </label>

            <label for="shortDescription" class="field-validation-error" *ngIf="fieldErrors.shortDescription && isSubmited">
              {{fieldErrors.shortDescription}}
            </label>
          </div>
        </div>
      </div>
      <div class="clearfix hidden-md-up"></div>

      <div class="col-xs-12 col-sm-6 col-md-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Описание</div>
          <div class="row__field pd">

            <div class="row__field-tooltip">Не повторяет краткое описание (!) Опишите процесс, уровень подготовки и нагрузки, форму одежды, результат…</div>

            <ckeditor [(ngModel)]="Model.description" formControlName="description" id="description" maxlength="10000"></ckeditor>

            <label for="description" class="field-validation-error" *ngIf="fieldErrors.description && isSubmited">
              {{fieldErrors.description}}
            </label>
          </div>
        </div>
      </div>
      <div class="clearfix hidden-md-up"></div>

<!-- !!!!!!!!!!!!!!!!!! -->

      <div class="col-xs-12">
        <div class="form-group">
          <div class="row__field">
            <app-gallery-cva [ngModel]="Model.medias" formControlName="medias" (ngModelChange)="fastSaveMedias()" [multipleUpload]="true" [title]="'Фотогалерея'" (onItemOpen)="openImage($event)"></app-gallery-cva>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-md-up"></div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-12 col-xs-12 col-sm-12 col-md-12">
        <div class="form-group">
          <div class="box-title">Цвет в расписании</div>
          <div class="clue-text">Выберите любой или введите свой в формате #abc или #abcdef</div>
          <div class="row__field">
            <app-color-picker formControlName="color" id="color" [ngModel]="Model.color"></app-color-picker>
            <label for="color" class="field-validation-error" *ngIf="fieldErrors.color && isSubmited">
              {{fieldErrors.color}}
            </label>
          </div>
        </div>
      </div>
      <div class="clearfix hidden-md-up"></div>

      <div class="col-xs-12">
        <div class="form-group">
          <br>
          <br>
          <div class="info-text">
            <div [ngClass]="{'info-text__box' : !isNotSaved()}">
              <span class="icon icon-about"></span>
              <span app-key-help [key]="'draftText'"></span>
            </div>

            <div *ngIf="!isNotSaved()">
              <span class="icon icon-about"></span>
              Статус:
              <ng-container *ngIf="isDraft()">Черновик</ng-container>
              <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isEditAvailable">

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveDraft'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-primary" (click)="copyEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveNew'"></span>
                  </span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>

    </div>
  </form>
</div>
