import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Accounting} from '../../../common/models/accounting.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {dateValidator} from '../../../common/validators/date.validator';
import {EntityStatus} from '../../../common/models/entity-status.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import * as moment from 'moment';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-accounting-add',
  templateUrl: './accounting-add.component.html',
  styleUrls: ['./accounting-add.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class AccountingAddComponent implements OnInit {
  @Output() result = new EventEmitter<Accounting>();
  @Input() isShowColumnTargetInAccounting: boolean;
  @Input() clubId = null;
  @Input() listAccounting: Accounting[] = [];
  public form: FormGroup;

  constructor(
    private apiV2: ApiV2Service,
    private alertsService: AlertsService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      displayDateAt: new FormControl(new Date().toISOString().split('T')[0], Validators.required),
      name: new FormControl(''),
      receivedCash: new FormControl(null, [Validators.required, Validators.min(0)]),
      receivedNonCash: new FormControl(null, [Validators.required, Validators.min(0)]),
      peoplePerDay: new FormControl(null, [Validators.required, Validators.min(0)]),
      target: new FormControl(null, this.isShowColumnTargetInAccounting ? [Validators.required, Validators.min(0)] : [])
    });
  }

  public IsDuplicate(): boolean {
    if (this.form.get('displayDateAt').invalid) { return false; } else {
      return this.listAccounting.filter(x => moment(x.displayDateAt).format('YYYYMMDD') === moment(this.form.get('displayDateAt').value).format('YYYYMMDD')).length > 0;
    }
  }

  async confirmSave(): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    if (this.IsDuplicate()) {
      this.alertsService.showConfirm({
        header: 'Дубликат',
        message: 'На выбранную дату уже внесены данные.<br/>Всё равно сохранить?',
        buttons: [{
          title: 'Сохранить',
          callback: async () => {
            await this.save();
          }
        }, {
          title: 'Отменить'
        }]
      });
    } else {
      await this.save();
    }
  }

  public async save(): Promise<any> {
    if (this.form.invalid) { this.form.markAllAsTouched(); return; }
    if (this.form.valid) {
      const req: any = {
        clubId: this.clubId,
        displayDateAt: new Date(this.form.get('displayDateAt').value),
        entityStatus: EntityStatus.published,
        name: this.form.get('name').value,
        peoplePerDay: Math.round(this.form.get('peoplePerDay').value),
        receivedCash: this.form.get('receivedCash').value,
        receivedNonCash: this.form.get('receivedNonCash').value,
        target: this.form.get('target').value
      }
      await this.apiV2.accounting.save(req).then(async (e) => {
        if (e) {
          e.IsNew = true;
          this.result.emit(e);
          this.closeAdd();
          this.alertsService.alert.next({
            type: 'success',
            message: 'Учет сохранен',
            header: 'Успех',
            position: 'top',
            timeout: 2000
          });
        } else {
          console.error(e);
        }
      });
    }
  }

  public closeAdd(): any {
    return this.result.emit(null);
  }
}
