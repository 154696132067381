import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd, RouterState, UrlSegment } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { merge, filter, map,distinctUntilChanged, debounceTime  } from 'rxjs/operators';

import * as _ from 'lodash';
import * as helpers from 'app/common/helpers.functions';

import { AuthService } from 'app/common/services/auth.service';
import { IRoutingParams, IContext } from 'app/common/models/context.model';
import { moduleRoutes, IModuleRoute, ModuleVisibility } from 'app/common/services/data/module-routes';
import { isAdmin, IUserInfo } from 'app/common/models/user-info.model';
import { ApiV2Service } from './api-v2.service';
import {UserRole} from '../models/roles.model';

@Injectable()
export class ContextService {
  private userInfo: IUserInfo;
  private _currentUrl = new BehaviorSubject<UrlSegment[]>(null);
  private _pendingUrl = new BehaviorSubject<UrlSegment[]>(null);
  private _contextInfo = new BehaviorSubject<IContext>(null);
  private _routingParams = new BehaviorSubject<IRoutingParams>({});
  public _updateContext = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiV2Service: ApiV2Service,
    private authService: AuthService,
    public titleService: Title
  ) {

    const routingStream$ =  this.routingParams.pipe(
      filter(Boolean),
      map(() => params => params.isInClubContext),
      distinctUntilChanged());

    const authStream2$ = this.authService.loggedIn.pipe(
      distinctUntilChanged(),
      map(() => this._routingParams.value && this._routingParams.value.isInClubContext))

    routingStream$.pipe(merge(authStream2$)).pipe(debounceTime(100)).subscribe(isInClub => this.updateContext(isInClub as boolean));

    this.authService.userInfo
      .subscribe(userInfo => this.userInfo = userInfo);

    this.router.events
      .subscribe(event => {
        if (event instanceof RoutesRecognized || event instanceof NavigationEnd) {
          const root = this.router.parseUrl(event.urlAfterRedirects).root;
          let segments: UrlSegment[] = [];
          if (root.numberOfChildren > 0) {
            segments = root.children.primary.segments;
          }

          if (event instanceof RoutesRecognized) {
            this._pendingUrl.next(segments);
          } else if (event instanceof NavigationEnd) {
            const title = this.getRootStateTitle(router.routerState, router.routerState.root).pop();
            titleService.setTitle(title);
            this._currentUrl.next(segments);
            const params = this.route.firstChild.snapshot.params;
            this.setRoutingParams(params);
          }
        }
      });

    this._updateContext.pipe(
      debounceTime(100))
      .subscribe(() => this.updateContext(true));
  }

  private setRoutingParams(params: IRoutingParams) {
    this._routingParams.next({
      ...params,
      isInClubContext: !!(params.cityId && params.clubNetId && params.clubId)
    });
  }

  private updateContext(isInClub: boolean) {
    if (this.authService.isLoggedIn) {
      this.apiV2Service.context
        .get(isInClub ? this._routingParams.value.clubId : undefined)
        .then(res => this._contextInfo.next(res));
    } else {
      this._contextInfo.next(null);
    }
  }

  private getRootStateTitle(state: RouterState, parent: ActivatedRoute) {
    // TODO:Здесь как-то хитро, надо от этого избавиться.
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      let title: string = parent.snapshot.data.title;
      if( parent.snapshot.params.id === 'create' ) {
          title = title.replace('Редактирование', 'Создание');
      }
      data.push(title);
    }

    if (state && parent) {
      data.push(... this.getRootStateTitle(state, (<any>state).firstChild(parent)));
    }
    return data;
  }

  private setWeekContext(dir: number) {
    const now = new Date();
    let base = helpers.addDays(now, 0);
    base = helpers.addDays(base, 7 * dir);

    return helpers.date2str(base);
  }

  private setClubContext(input: IModuleRoute): IModuleRoute {
    const output = _.cloneDeep(input);
    output.urlPrefix = this.makeContextUrl('');
    if (input.url === 'clubschedule') {
      output.urlSuffix = `/${this.setWeekContext(0)}`;
    }
    if (input.url === 'clubchats') {
      output.url = 'chats';
    }

    return output;
  }

  private setCityContext(input: IModuleRoute): IModuleRoute {
    const output = _.cloneDeep(input);
    output.urlPrefix = `${this._routingParams.value.cityId}/`;
    return output;
  }

  public makeContextUrl(url: string, ctx?: IRoutingParams): string {
    // TODO: использовать этот метод везде, где нужны контекстные урлы. сейчас много где это каждый раз заново написано.
    const params = ctx || this._routingParams.value;
    if (params.isInClubContext) {
      return `${params.cityId}/${params.clubNetId}/${params.clubId}/${url}`;
    } else {
      return url;
    }
  }

  public getRoutingParams(): IRoutingParams {
    // TODO: избавиться от этого и везде использовать подписку
    return this._routingParams.value;
  }

  public get navigations(): Array<IModuleRoute> {
    const contextInfo = this._contextInfo.value;
    const params = this._routingParams.value;

    if (!params.cityId) {
      return [];
    }
    const navigations = moduleRoutes
      .filter(item =>
        contextInfo.modules.includes(item.url) && (
          (params.isInClubContext && item.visibility.includes(ModuleVisibility.club)) ||
          (!params.isInClubContext && item.visibility.includes(ModuleVisibility.admin))
        )
      )
      .map(item => !params.isInClubContext ?
        this.setCityContext(item) :
        this.setClubContext(item));

    if (this.userInfo && isAdmin(this.userInfo) && params.isInClubContext) {
      // TODO: возможно, стоит хранить это в moduleRoutes? Но тогда как-то нужно обновлять
      navigations.push({
        title: 'Все клубы',
        url: `${params.cityId}/clubs`,
        class: 'sp-clubs',
        visibility: [ModuleVisibility.club]
      });
    }

    if (this.userInfo && isAdmin(this.userInfo) && !params.isInClubContext) {
      // Для системной роли Чаты перемецаем в конец меню
      var chats = navigations.splice(navigations.indexOf(navigations.find(x => x.url == 'chats')), 1);
      chats.length && navigations.push(chats[0]);
    }

    return navigations;
  }

  public async availableModulesList(): Promise<Array<any>> {
    let resp = [];
    await this.apiV2Service.context.getModulesForRoleIsInsideClub().then(roles => {
      for (const role of roles) {
        const _tempModules = [];
        for (const module of role.modules) {
          _tempModules.push({ name: module.toLowerCase(), title: moduleRoutes.filter(item => item.url.toLowerCase() === module.toLowerCase())[0].title });
        }
        if (
          role.role === UserRole.Admin ||
          role.role === UserRole.Manager ||
          role.role === UserRole.ClubNetAdmin ||
          role.role === UserRole.ClubAdmin ||
          role.role === UserRole.ClubManager)
        {
          _tempModules.push({ name: 'queue', title: 'Электронная очередь' });
        }
        role.modules = _tempModules;
      }
      resp = roles;
    });
    return resp;
  }

  public get routingParams() {
    return this._routingParams.asObservable();
  }

  public get contextInfo() {
    return this._contextInfo.asObservable();
  }

  public get currentUrl() {
    return this._currentUrl.asObservable();
  }

  public get pendingUrl() {
    return this._pendingUrl.asObservable();
  }
}
