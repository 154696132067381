import { Route } from '@angular/router';
import { LoginComponent } from 'app/components/login/login.component';
import { LoginGuard } from 'app/components/login/services/login.guard.service';

export const loginRoute: Route = {
    path: 'login',
    component: LoginComponent,
    canActivate: [ LoginGuard ],
    data: { title: 'Вход в CRM для фитнес-клуба' }
};
