import { Route } from '@angular/router';
import { EventsComponent } from 'app/components/events/events.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const eventsRoute: Route = {
  path: 'events',
  component: EventsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'События' }
};
