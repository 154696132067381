<header class="settings__header">
  <span class="title">Сменить пароль</span>
</header>
<form [formGroup]="form" novalidate>
  <div class="form-group" myScrollTo>
    <input class="form-control" type="password" name="name" formControlName="currentPassword" placeholder="Текущий пароль" class="input__text input__text--long"
    />
    <label class="field-validation-error" *ngIf="fieldErrors.currentPassword && isSubmited">{{ fieldErrors.currentPassword }}</label>
  </div>


  <div class="form-group" myScrollTo>
    <input class="form-control" type="password" name="name" formControlName="newPassword" placeholder="Новый пароль" class="input__text input__text--long"
      [class.input__text--error]="form.controls['newPassword'].invalid && form.controls['newPassword'].touched" />
    <label class="field-validation-error" *ngIf="fieldErrors.newPassword && isSubmited">{{ fieldErrors.newPassword }}</label>
    <label class="field-validation-error" *ngIf="formErrors.passwordMatch && !fieldErrors.newPassword && isSubmited">{{ formErrors.passwordMatch }}</label>
  </div>

  <div class="form-group" myScrollTo>
    <input class="form-control" type="password" name="name" formControlName="confirm" placeholder="Повторите пароль" class="input__text input__text--long"
    />
    <label class="field-validation-error" *ngIf="fieldErrors.confirm && isSubmited">{{ fieldErrors.confirm }}</label>
    <label class="field-validation-error" *ngIf="formErrors.passwordMatch && !fieldErrors.confirm && isSubmited">{{ formErrors.passwordMatch }}</label>
  </div>

  <div class="row">
    <div class="form-group col-12 col-xs-12 col-sm-6 col-lg-4">
      <button class="btn btn-primary" (click)="onSave()" [disabled]="(isSubmited && form.invalid)">
        <span class="btn-primary__desc">Сменить пароль</span>
      </button>
    </div>
  </div>

</form>
