import { Route } from '@angular/router';
import { ClubroomsComponent } from 'app/components/clubrooms/clubrooms.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';
import { ClubroomEditComponent } from 'app/components/clubrooms/clubroom-edit/clubroom-edit.component';

export const clubroomsRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubrooms',
  component: ClubroomsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Залы' }
};


export const clubroomRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/clubrooms/:id',
  component: ClubroomEditComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Редактирование зала' }
};
