<div class="resetWrap">
  <div class="resetForm">

    <header class="settings__header">
      <span class="title">Восстановление пароля</span>
    </header>
    <form [formGroup]="form">
      <div>
        <div class="form-group">
          <p>Адрес электронной почты, на который зарегистрирован аккаунт</p>
          <br>
          <input type="text" class="form-control" name="emailOrPhone" formControlName="Model.emailOrPhone" formControlName="emailOrPhone"
            placeholder="Email" class="input__text input__text--long" />
          <label class="field-validation-error" for="emailOrPhone" *ngIf="fieldErrors.emailOrPhone && isSubmited">{{ fieldErrors.emailOrPhone }}</label>
        </div>
        <div class="form-group">
          <button [disabled]="form.invalid && isSubmited" class="btn btn-primary" (click)="restore()">
            <span class="btn-primary__desc">Продолжить</span>
        </button>
        </div>
      </div>
      <div class="form-group back-link">
        <a class="form-control" (click)="redirect()">
          Вернуться на главную
        </a>
      </div>
    </form>
  </div>
</div>
