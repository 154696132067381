import {
  Directive, ElementRef, HostListener, Injectable
} from '@angular/core';
import { BrowserStateService } from 'app/common/services/browser-state.service';
import scrollIntoViewIfNeeded from 'custom-modules/scroll-into-view-if-needed';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ScrollToService {
  private activeElem: ElementRef;
  private lastHeight = 99999;

  public setActiveElem(elem: ElementRef) {
    this.activeElem = elem;
  }

  constructor(private browserStateService: BrowserStateService) {
    this.browserStateService.resizeObserver.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(event => {
        const height = (<Window>event.target).innerHeight;
        if (height < this.lastHeight) {
          if (this.activeElem) {
            const inputs = (<HTMLElement>this.activeElem.nativeElement).querySelectorAll('.js-scroll-target, input, textarea');
            scrollIntoViewIfNeeded(inputs.length > 0 ? inputs[0] : this.activeElem.nativeElement, true);
            this.setActiveElem(null);
          }
        }
        this.lastHeight = height;
      });
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[myScrollTo]'
})
export class ScrollToDirective {
  @HostListener('click', ['$event']) onClick() {
    this.service.setActiveElem(this.el);
  }

  constructor(private el: ElementRef, private service: ScrollToService) {
  }
}
