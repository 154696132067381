<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Виджеты
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model">
    <div class="clubrooms-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
            <span class="key" app-key-help [key]="'search'"></span>
            <span class="key" app-key-help [key]="'searchHK'"></span>
            <ng-container *ngIf="isEditAvailable && isSystemRole">
            <a class="box-link" (mousedown)="onEditStart(null,$event)" (click)="onEditStart(null)">Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Тип виджета</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let widget of Model' (mousedown)="onEditStart(widget,$event)" (click)="onEditStart(widget)">
              <td class="table-cell">
                <span class="table-cell__mobile-title">Название</span>
                {{widget.name}}
              </td>
              <td class="table-cell">
                <span class="table-cell__mobile-title">Тип виджета</span>
                {{widget.widgetType | widgetType}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </ng-container>
</div>
