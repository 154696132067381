<aside class="navigation">
  <nav class="navigation__nav" *ngIf="componentReady">
    <ul class="navigation__nav_list">
      <ng-container *ngFor="let item of navigations">
        <li class="navigation__nav_li active" (click)="close()" [class.active]="isActive(item)">
          <a routerLink="{{(item.urlPrefix || '') + item.url + (item.urlSuffix || '')}}" href="">
            <span class="sp {{item.class}}"></span>
            <span class="title">{{item.title}}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
  <!-- <div class="navigation__logo">
    <span class="logo"></span>
  </div> -->
</aside>
