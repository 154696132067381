import { Route } from '@angular/router';
import { NewsComponent } from 'app/components/news/news.component';
import { AuthGuard } from 'app/common/services/auth-guard.service';

export const newsRoute: Route = {
  path: 'news',
  component: NewsComponent,
  canActivate: [ AuthGuard ],
  data: { title: 'Новости' }
};
