<div class="input-wrap">
  <mat-datepicker-toggle matPrefix [for]="picker" class="icon__picker"></mat-datepicker-toggle>
  <input class="input__text input__text--long form-control"
    matInput
    [matDatepicker]="picker"
    [value]="dateValue"
    (dateInput)="addEvent($event)"
    [placeholder]="placeholder"
    required
  />
  <mat-datepicker touchUi #picker></mat-datepicker>
</div>

