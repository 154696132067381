import { Injectable, Injector } from '@angular/core';

import { ICourseModel } from 'app/common/models/course.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';

@Injectable()
export class CoursesService extends TableListService<ICourseModel> {
  constructor(injector: Injector) {
    super(injector);
  }

  setStatus(status: string, item: ICourseModel) {
    return this.apiService.courses.setStatus(this.contextService.getRoutingParams(), status, item);
  }

  getSubscriptions(courseId: string) {
    return this.apiService.subscriptions.getCourseSubscriptions(this.contextService.getRoutingParams(), courseId);
  }

  getItemList() {
    return this.apiService.courses.getList(this.contextService.getRoutingParams());
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.courses.getItem(req);
  }

  save(model: ICourseModel) {
    return this.apiService.courses.saveItem(this.contextService.getRoutingParams(), model);
  }

  create(model: ICourseModel) {
    return this.save(model);
  }

}
