<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Реестр от {{dateFormat(registry.dateFrom)}} - {{dateFormat(registry.dateTo)}}
      </span>
    </div>
  </div>
  <div class="clubnets-container">
    <div class="tableSideLinkWrap">
      <div class="clue-text col-lg-8">
        <span class="key">Кол-во тренеров: <b class="pd">{{ registryElementFilter.length }}</b></span>
        <span class="key">Сумма начислений: <b class="pd">{{ totalAccrual().toFixed(2) }}</b></span>
        <span class="key">Выданная сумма: <b class="pd">{{ totalPayout().toFixed(2) }}</b></span>
        <span class="key">Осталось выплатить: <b class="pd">{{ (totalAccrual() - totalPayout()).toFixed(2) }}</b></span>
        <span class="key">
          Статус:
          <span *ngIf="registry.status.toLowerCase() == 'draft'" class="status-label pd"
            style="color: red;">Черновик</span>
          <span *ngIf="registry.status.toLowerCase() == 'created'" class="status-label pd"
            style="color: #dede00;">Создан</span>
          <span *ngIf="registry.status.toLowerCase() == 'confirmed'" class="status-label pd"
            style="color: yellowgreen;">Подтвержденный</span>
          <span *ngIf="registry.status.toLowerCase() == 'completed'" class="status-label pd"
            style="color: #01e001;">Завершенный</span>
        </span>
      </div>
      <!-- Excell, all coaches -->
      <div class="block-btn-export-table">
        <button class="btn btn-primary btn-sm" type="submit" (click)="export(registryElement, RegistryExportType.ExcelLists , null, registryElementFilter)">
          <span class="btn-primary__desc">Весь реестр в Excel</span>
        </button>
      </div>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="isLoading">
  <ng-container>
    <div *ngFor='let c of registryElementFilter'>
      <div class="h1-link mb-2">
        <span (click)="openPageCoach(c)">{{c[2]}}</span>
        <span (click)="actionIsVisibility(c)">{{c[3].isVisibility ? '[Свернуть]' : '[Показать]'}}</span>
      </div>
      <div class="clearfix"></div>
      <div class="clubnets-container">
        <div class="tableSideLinkWrap">
          <div class="clue-text">
            <span class="key">Кол-во занятий: <b class="pd">{{getClassLength(c[1])}}</b></span>
            <span class="key">Всего начислено тренеру: <b class="pd">{{ totalCoachAccrual(c[1]).toFixed(2) }}</b></span>
            <span class="key">Выплачено тренеру: <b class="pd">{{ totalCoachPayout(getPayoutsByCoachId(c[0])).toFixed(2) }}</b></span>
            <span class="key">Осталось выплатить тренеру: <b class="pd">{{ (totalCoachAccrual(c[1]) - totalCoachPayout(getPayoutsByCoachId(c[0]))).toFixed(2) }}</b></span>
          </div>
        </div>
      </div>
      <div class="" *ngIf="c[3].isVisibility">

        <div class="col-xs-6 col-md-3 payouts-tab" [class.active]="c[3].showPayoutsDetails">
          <a class="btn-link cancel-add" (click)="editPayout(c)" *ngIf="!c[3].showPayoutsDetails">
            Выплата
          </a>
          <a class="btn-link cancel-add" (click)="closeAdd(c)" *ngIf="c[3].showPayoutsDetails">
            Отмена
          </a>
        </div>

        <div class="col-xs-6 col-md-3 payouts-tab" [class.active]="c[3].showPayout">
          <a *ngIf="!c[3].showPayout" class="clubnets-container btn-link"
            (click)="getPayoutsByCoachId(c[0]).length && showPayoutsFormatChanged(c)">
            Детали выплат ( {{getPayoutsByCoachId(c[0]).length}} )
          </a>

          <a *ngIf="c[3].showPayout" class="btn-link cancel-add" (click)="showPayoutsFormatChanged(c)">
            Скрыть выплаты
          </a>
        </div>

        <!-- Excell -->
        <div class="col-xs-6 col-md-3 payouts-tab">
        </div>
        <div class="col-xs-6 col-md-3 payouts-tab block-btn-export-table">
          <button class="btn btn-primary btn-sm" type="submit" (click)="export(c[1], 'ExcelFile', c)">
            <span class="btn-primary__desc">В Excel</span>
          </button>
        </div>

        <div class="payouts-container col-xs-12" *ngIf="c[3].showPayout">
          <table class="payouts-table">
            <thead>
              <tr>
                <td class="table-cell">Дата</td>
                <td class="table-cell">Сумма</td>
                <td class="table-cell">Отменить</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let p of getPayoutsByCoachId(c[0])'>
                <td class="table-cell table-cell_ignore {{p.status.toLowerCase()}}" style="text-align: center;">
                  {{dateFormat(p.date)}}
                </td>
                <td class="table-cell table-cell_ignore {{p.status.toLowerCase()}}" style="text-align: center;">
                  {{p.total.toFixed(2)}}
                </td>
                <td class="table-cell table-cell_ignore {{p?.status.toLowerCase()}}" style="text-align: center; padding: 0;">
                  <ng-container *ngIf="p.status.toLowerCase() !== 'deleted'">
                    <span class="table-cell__mobile-title">Удалить</span>
                    <button class="btn btn-clear active" (click)="deletePayout(p)">
                      <span class="icon-cross" style="vertical-align: middle;"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
                    </button>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="payouts-container col-xs-12" *ngIf="c[3].showPayoutsDetails">
          <app-payout [coachId]="c[0]" (result)="updatePayout($event, c)" [coachRegistryId]="registry.id"></app-payout>
        </div>

      </div>

      <div *ngIf="c[3].isVisibility">
        <table class="table">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
              <td class="table-cell table-title-cell">Начислено</td>
              <td class="table-cell table-title-cell">Способ начисления</td>
              <td class="table-cell table-title-cell"></td>
              <td class="table-cell table-title-cell" style="text-align: center;">
                <button class="btn btn-clear active" (click)="removeTable(c)">
                  <span class="icon-cross icon-delete"><span class="icon-cross__left"></span><span
                      class="icon-cross__right"></span></span>
                </button>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor='let e of c[1]'>
              <app-coachregistry-element-edit [element]="e" (result)="editElement()" [registry]="registry">
              </app-coachregistry-element-edit>
              <td
                class="table-cell table-cell_ignore {{e?.status.toLowerCase()}}"
                style="text-align: center;">
                <ng-container *ngIf="!e?.isEnableMode">
                  <span class="table-cell__mobile-title">Редактировать</span>
                  <button class="btn btn-clear active" (click)="edit(e)">
                    <span class="sp sp-pen"></span>
                  </button>
                </ng-container>

                <ng-container *ngIf="e?.isEnableMode">
                  <span class="table-cell__mobile-title">Закрыть</span>
                  <button class="btn btn-clear" (click)="cancel(e)">
                    <span class="sp icon-cross icon-transparent">
                      <span class="icon-cross__left"></span>
                      <span class="icon-cross__right"></span>
                    </span>
                  </button>
                </ng-container>
              </td>
              <td *ngIf="e.status.toLowerCase() !== 'deleted'" class="table-cell table-cell_ignore {{e.status.toLowerCase()}}"
                style="text-align: center;">
                <span class="table-cell__mobile-title">Удалить</span>
                <button class="btn btn-clear active" (click)="remove(e.id, c)">
                  <span class="icon-cross"><span class="icon-cross__left"></span><span
                      class="icon-cross__right"></span></span>
                </button>
              </td>
              <td *ngIf="e.status.toLowerCase() === 'deleted'" class="table-cell table-cell_ignore deleted">
              </td>
            </tr>
          </tbody>

        </table>

        <div class="main-block-add-elements">
          <app-coachregistry-element-add [registry]="registry" [table]="c" (result)="addElement(c, $event)"
            *ngIf="c?.isEnableAdd"></app-coachregistry-element-add>
          <div class="btn-add-registry-element" *ngIf="!c?.isEnableAdd">
            <div class="drop-zone__btn"><span class="sp-gallery_addphoto" (click)="addElem(c)"></span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group" myScrollTo>
          <div class="box-title">Статус</div>
          <div class="row__field">
            <select class="select select__long" id="status" [(ngModel)]="registry.status">
              <option value="Draft">Черновик</option>
              <option value="Created">Создан</option>
              <option value="Confirmed">Подтвержденный</option>
              <option value="Completed">Завершенный</option>
            </select>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-3">
        <div class="form-group">
          <br>
          <br>
          <button class="btn btn-primary btn-small" (click)="save()">
            <span class="btn-primary__desc">Сохранить</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

</div>
