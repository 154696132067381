import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IDurationUnit } from 'app/common/models/subscription.model';

@Component({
  selector: 'app-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DurationPickerComponent),
    multi: true
  }
  ]
})
export class DurationPickerComponent implements ControlValueAccessor {
  public currentAlias = null;
  public currentRange = null;

  public data: {
    duration: number;
    durationUnit: string
  };

  public predefinedRanges = [{
    duration: 1,
    durationUnit: IDurationUnit[IDurationUnit.week],
    title: 'неделя'
  }, {
    duration: 1,
    durationUnit: IDurationUnit[IDurationUnit.month],
    title: 'месяц'
  }, {
    duration: 3,
    durationUnit: IDurationUnit[IDurationUnit.month],
    title: '3 месяца'
  }, {
    duration: 6,
    durationUnit: IDurationUnit[IDurationUnit.month],
    title: '6 месяцев'
  }, {
    duration: 1,
    durationUnit: IDurationUnit[IDurationUnit.year],
    title: 'год'
  }, {
    title: 'другое',
    duration: 0,
    durationUnit: null
  }];

  public predifinedAliases = [{
    title: 'дней',
    durationUnit: IDurationUnit[IDurationUnit.day],
  }, {
    title: 'недель',
    durationUnit: IDurationUnit[IDurationUnit.week],
  }, {
    title: 'месяцев',
    durationUnit: IDurationUnit[IDurationUnit.month],
  }, {
    title: 'лет',
    durationUnit: IDurationUnit[IDurationUnit.year],
  }];

  constructor() { }

  public registerOnTouched() { }

  private propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public getClosestRange() {
    return this.predefinedRanges
      .filter(x => x.duration === this.data.duration &&
        x.durationUnit === this.data.durationUnit)
      .concat([this.predefinedRanges[this.predefinedRanges.length - 1]])[0];
  }

  public getAliasByName(name: string) {
    return this.predifinedAliases
      .filter(x => x.durationUnit === name)[0];
  }

  public setAlias(event) {
    this.currentAlias = this.getAliasByName(event);
    this.updateValue();
  }

  public setRange(event) {
    this.currentRange = event;
    if (event.duration !== 0) {
      this.data.durationUnit = event.durationUnit;
      this.data.duration = event.duration;
      this.propagateChange(this.data);
    } else {
      this.currentAlias = this.getAliasByName(this.currentAlias);
      this.updateValue();
    }
  }

  public setAliasedValue(event) {
    this.data.duration = event;
    this.updateValue();
  }

  public updateValue() {
    if (this.currentAlias != null && this.currentAlias.durationUnit != null) {
      this.data.durationUnit = this.currentAlias.durationUnit;
    }
    this.propagateChange(this.data);
  }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.currentAlias = this.data.durationUnit;
      this.currentRange = this.getClosestRange();
    }
  }
}
