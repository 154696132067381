<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="goBack()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Запись клиента на занятие
      </span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="goBack()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body">

  <div>

    <div class="search-box" style="margin-bottom: 20px" id="search-block">
      <div class="box-title">Найти или создать клиента</div>
      <div class="row">
        <div class="col-md-8">
          <input class="input__text input__text--long" autocomplete="off" #searchBox id="search-box"
            placeholder="Имя, телефон, email клиента" (keyup)="searchClients(searchBox.value)" />
          <ul class="search-box__list" *ngIf="clientSearchList && clientSearchList.length" appClickOutside
            (clickOutside)="clientSearchList = []">
            <ng-container *ngFor="let client of clientSearchList">
              <li class="search-box__list-item" *ngIf="client" (click)="choseClient(client)">
                {{client.firstName}} {{client.lastName}}
                <span *ngIf="client.phone">(тел.: {{client.phone}})</span>
              </li>
            </ng-container>

          </ul>
        </div>

        <div class="col-md-4">
          <button *ngIf="searchBox.value.length > 2 && !client" class="btn btn-primary btn-small"
            (click)="createClient()">
            <span class="btn-primary__desc">
              Создать нового клиента
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isEdit">
    <div class="col-md-8">
      <div class="form-group" *ngIf="courses && courses.length">
        <div class="box-title">Абонемент</div>
        <select id="client-subscriptions" [(ngModel)]="subscriptionId" class="input__text input__text--long"
          name="sub" id="sub">
          <option [value]="sub.id" *ngFor="let sub of client.subscriptions">{{sub.name }} <ng-container
              *ngIf="sub.visitCount>0">{{sub.visitCounter}}/{{infinity(sub.visitCount)}}</ng-container>
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8">
      <div class="form-group" *ngIf="courses && courses.length">
        <div class="box-title">Выбрать вид занятия</div>
        <select class="input__text input__text--long" name="class" id="class"
          (change)="courseSelect($event.target.value)">
          <option [value]="null" selected disabled>Не выбрано</option>
          <option [value]="course.id" *ngFor="let course of courses">{{course.name}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="(classes && classes.length)">
    <div class="col-md-8">
      <div class="form-group">
        <div class="box-title">Выбрать дату и время занятия</div>
        <select class="input__text input__text--long" name="class" id="class"
          (change)="changeDate($event.target.value)">
          <option [value]="null" selected disabled>Не выбрано</option>
          <option [value]="idx" *ngFor="let class of classes; let idx = index">{{class.startDate}} {{class.startTime}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="col-xs-12" *ngIf="selectedCourse && classes && classes.length === 0">
    <div class="info-text">
      <div>
        Занятия выбранного вида отсутствуют в расписании
      </div>
    </div>
  </div>

  <div>
    <button *ngIf="selectedCourse && selectedCourse.isVoluntaryBookingAllowed && client" class="btn btn-primary"
      (click)="continue()">
      <span class="btn-primary__desc">Дальше</span>
    </button>

    <button *ngIf="(selectedCourse && !selectedCourse.isVoluntaryBookingAllowed && client) || isEdit" [disabled]="!selectedDate"
      (click)="bookingRegular()" class="btn btn-primary">
      <span class="btn-primary__desc">Записать</span>
    </button>
  </div>

</div>
