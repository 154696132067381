import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Status2BoolPipe, Bool2StatusPipe } from 'app/common/pipes/status.pipe';

let idgen = 0;

@Component({
  selector: 'app-status-picker',
  templateUrl: './status-picker.component.html',
  styleUrls: ['./status-picker.component.scss'],
  providers: [
    Status2BoolPipe,
    Bool2StatusPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusPickerComponent),
      multi: true
    }
  ]
})

export class StatusPickerComponent implements OnInit, ControlValueAccessor {


  @Input() isReadonly;
  @Input() title;
  @Input() type;
  public _title;

  public id = idgen++;
  public data;
  public state;

  private propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
      this.state = this.s2b.transform(obj);
    }
  }

  public onChange(event) {
    if (!this.isReadonly) {
      this.writeValue(this.b2s.transform(event));
      this.propagateChange(this.data);
    }
  }

  constructor(public s2b: Status2BoolPipe, public b2s: Bool2StatusPipe) { }

  ngOnInit() {
    this._title = this.title || '';
  }
}
