export enum UserRole {
  Admin = 'Admin',
  Manager = 'Manager',
  ClubNetAdmin = 'ClubNetAdmin',
  ClubAdmin = 'ClubAdmin',
  ClubManager = 'ClubManager',
  ClubCoach = 'ClubCoach',
  ClubCoachAssistant = 'ClubCoachAssistant',
  DummyRole = 'DummyRole'
}
