import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { IClassRequest } from '../models/class-request.model';
import { ContextService } from '../services/context.service';

@Pipe({
  name: 'classLink'
})
export class RequestClassLinkPipe implements PipeTransform {
  transform(request: IClassRequest) {
    const startDateTime = moment.utc(request.startDateTime);
    const mondayDateTime = moment.utc(request.startDateTime).startOf('isoWeek');
    let path = '';
    const isVirtual = !request.classId || request.classId === '00000000000000000000000000000000';
    if (isVirtual) {
      path = 'virtual/' + request.prototypeId + '/' + startDateTime.format('DD.MM.YYYY');
    } else {
      path = request.classId;
    }
    return this.contextService.makeContextUrl('clubschedule') + '/' + mondayDateTime.format('DD.MM.YYYY') + '/' + path;
  }

  constructor(private contextService: ContextService) {}
}
